import {Auth} from 'aws-amplify';
import {host} from '../data.js';
import {checkAuthorizationException} from '../helpers/checkAuthorizationException.js';

const LOCATION_ERROR_MESSAGE =
    'Error in user information service! Provide, correct data to the service!';

let url_config ={
    api: "users/settings",
    apiStatusReport: "users/statusreports",
    apiAcceptTerms: "users/terms/accept"
};

export const users_inf ={
    getUserData: async(userId, abortController={signal: undefined}) => {
        try{
            if (!userId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url = 
                host + url_config.api.replace("{userId}",userId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',                   
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });
            
            const data = await response.json();
            
            const { error, user_settings_dict } = data;
            return {status: response.status, 
                    error: error,
                    userSettings: user_settings_dict,
                };

        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    updateUserTerms: async() => {
        try{
            const url = 
                host + url_config.apiAcceptTerms;
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({}),
            });
            const data = await response.json();

            const { error,  user_settings_dict} = data;
            return {status: response.status, 
                    error: error,
                    userSettings: user_settings_dict,
                };

        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    editUserData: async(userId, given, family, daylight, phone='', language='en', preferred_units='us') => {
        try{
            if (!userId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url = 
                host + url_config.api.replace("{userId}",userId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    given_name: given,
                    family_name: family,
                    phone_number: phone,
                    daylight: daylight,
                    language: language,
                    preferred_units: preferred_units
                }),
            });
            const data = await response.json();

            const { error } = data;
            return {status: response.status, 
                    error: error,
                };

        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getUserStatusReports: async(userId, abortController={signal: undefined}) => {
        try{
            if (!userId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url = 
                host + url_config.apiStatusReport;
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',                   
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });
            
            const data = await response.json();

            const { error, status_report_list } = data;
            return {status: response.status, 
                    error: error,
                    statusReportList: status_report_list,
                };

        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
            
        }
    },
    updateUserStatusReports: async(userId, accountId, byEmail, byText, 
                                    day, hour, tz_offset) => {
        try{
            if (!userId || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url = 
                host + url_config.apiStatusReport;
            const user = await Auth.currentAuthenticatedUser();

            let body = {
                accountId: accountId,
                byEmail: 0,
                byText: 0,
            };
            if (byEmail){
                body.byEmail = 1;
            }
            if (byText){
                body.byText = 1;
            }
            if (day){
                body.day=String(day)
            }
            if (hour){
                body.hour = String(hour)
            }
            if (tz_offset){
                body.tz_offset = String(tz_offset)
            }
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',                   
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(body)
            });
            
            const data = await response.json();

            const { error } = data;
            return {status: response.status, 
                    error: error,
                };

        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
}

export default users_inf;