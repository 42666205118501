import React, { useState, useEffect } from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  Stack,
  Tab,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { ArrowBack } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AddDevice from '../../MainPage/Components/AddDevice';
import { accounts } from '../../services/accounts';
import { enableBilling } from "../../data";
import AccountInvite from '../../MainPage/Components/AccountInvite'
import { billing } from '../../services/billing.js';
import { groups_inf } from '../../services/groups_inf';
import UserCard from './Components/UserCard.js'
import DeviceCard from './Components/DeviceCard';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalPrompt from '../../CommonComponents/ModalPrompt';

export default function AccountManage({ accountInfo, fetchAccountInformation }) {
  const { t } = useTranslation();
  const theme=useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [groupInviteAccess, setGroupInviteAccess] = useState(false);
  const [value, setValue] = React.useState("1");
  const [userList, setUserList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [myData, setMyData] = useState();
  const [myRole, setMyRole] = useState();
  const [accountGroupsObj, setAccountGroupsObj] = useState([]);
  const [delAccountDialog, setDelAccountDialog] = useState(false);
  const [orgBilling, setOrgBilling] = useState({});
  const [billingData, setBillingData] = useState({});

  useEffect(() => {
    const abortController = new AbortController();
    fetchAccountInfo(abortController);
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    if(enableBilling){
      fetchBillingInformation(abortController);
    }

    return () => abortController.abort();
  }, [deviceList]);
  
  const fetchAccountInfo = async (abortController={signal: undefined}) => {
    try{
      console.log("FETCHING ACCOUNT INFO");
      const {status, error, accountGroups, accountUsers, accountDevices} = await groups_inf.getGroups(
        accountInfo.accountId,
        abortController
        );
      if (status === 404) {
        return;
      }
      
      if (error !== 'OK' && status !== 200) {
        if (error.includes("unable to connect to database")){
          alert(t("failedToConnectToDatabase"));
        }
        else if(error.includes("Access Denied, no permissions to modify")){
          alert(t("accessDeniedNoPermissions"));
        }
        else {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
        }
        //alert(t(error));
        throw new Error(error);
      }
      let accountUsers_temp = accountUsers;
      accountUsers_temp.sort((a, b) => a.given_name === "" ? (b.given_name === "" ? (a.user_id.toLowerCase() < b.user_id.toLowerCase() ? -1:1) : 1) : (a.given_name.toLowerCase() < b.given_name.toLowerCase() ? -1 : 1))
      let l_accountGroupsObj = {};
      for (const [name, value] of Object.entries(accountGroups))
      {
        l_accountGroupsObj[name] = {
          'group_id': value.id,
          'name': name,
          'write_access': 1,  //Admin Access
          'invite_access': 1, //Admin Access
          'read_access': 1    //Admin Access
        }
      }
      setGroupInviteAccess(true);
      setAccountGroupsObj(l_accountGroupsObj);
      setUserList(accountUsers_temp);
      setDeviceList(accountDevices);
      if(!enableBilling){
        setDataReady(true);
      } else {
        setTimeout(()=>{setDataReady(true)}, 10000);
      }

      let temp1;
      const response = await Auth.currentAuthenticatedUser();
      temp1 = response.attributes.email;
      
      for (const [key1, value1] of Object.entries(accountUsers)) {
        let temp2;
        temp2 = accountUsers[key1].user_id;
        if(temp1.toString().toLowerCase() === temp2.toString().toLowerCase()){
          setMyData(accountUsers[key1]);
          setMyRole(accountUsers[key1].access_type);
        }
      }

    }
    catch(error){
      console.log(error)
    }
  };
  const fetchBillingInformation = async (abortController={signal: undefined}) => {
    try {
        const { status, data } = await billing.getBillingData(
            accountInfo.accountId,
            abortController
        );

        const { error, billing_account, org_billing } = data;

        if (status === 404) {
            // console.log(status);
        }

        if (error !== 'OK' && status !== 200) {
            throw new Error(error);
        }

        setBillingData(billing_account);
        setDataReady(true);
        // if (org_billing.billing_curr == null) {
        //     org_billing.billing_curr = BillingCurrency[0];
        // }
        // if (org_billing.billing_plan == null) {
        //     org_billing.billing_plan = BillingPlans[0].plan_code;
        // }
        setOrgBilling(org_billing);
    } catch (error) {
        console.error(error);
    }    
  };

  const removeUser = async (user_id) => {
    console.log("Delete User");
    try {
      const { status, data } = await accounts.deleteUser(accountInfo.accountId, user_id);

      const { error } = data;

      if (status === 404) {
          return;
      }
      
      if (error !== 'OK' && status !== 200) {
        if (error.includes("unable to connect to database")){
          alert(t("failedToConnectToDatabase"));
        }
        else if(error.includes("Access Denied, no permission to delete user")){
          alert(t("accessDeniedDeleteUser"));
        }
        else if(error.includes("Access Denied, no permissions to modify")){
          alert(t("accessDeniedNoPermissions"));
        }
        else if(error.includes("at least one owner")){
          alert(t("failedToRemoveUserAtLeastOneOwner"))
        }
        else if(error.includes("Unable to delete User, remove user from all groups")){
          alert(t("failedToRemoveUserStillInGroup"));
        }
        else if(error.includes("Unable to delete User")){
          alert(t("unableToDeleteUser"));
        }
        //alert(t("sorryUnableToRetrieve"));
        else {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
        }
        throw new Error(error);
      }
      setDataReady(false);
      fetchAccountInfo();

    } catch (error) {
      console.error(error);
      // alert(t("error"));
    }
  }

  const removeDevice = async (uid) => {
    console.log("Delete Device");
    try{
      const {status, data} = await accounts.deleteDevice(accountInfo.accountId, uid);
      const {error} = data;
      
      if (status === 404) {
        return;
    }
    
    if (error !== 'OK' && status !== 200) {
      if (error.includes("unable to connect to database")){
        alert(t("failedToConnectToDatabase"));
      }
      else if(error.includes("Access Denied, no permission to delete devcie")){
        alert(t("accessDeniedDeleteDevice"));
      }
      else if(error.includes("Access Denied, no permissions to modify")){
        alert(t("accessDeniedNoPermissions"));
      }
      else if(error.includes("Failed to remove device, still assigned to a group")){
        alert(t("devcieStillAssignedToGroup"));
      }
      else if(error.includes("Failed to remove device, it is still active")){
        alert(t("deviceIsStillActive"));
      }
      else if(error.includes("Unable to find device")){
        alert(t("unableToFindDevice"));
      }
      else if(error.includes("Unable to delete device")){
        alert(t("unableToDeleteDevice"));
      }
        // alert(t("sorryUnableToRetrieve"));
      else {
        alert(t("sorryUnableToRetrieve"));
        throw new Error(error);
      }
      throw new Error(error);
    }
    fetchAccountInfo();

    } catch (error) {
      console.error(error);
    }
  };

  const setUserRole = async (user_id, newRole) => {
    console.log("Update user to " + newRole);
    try {
      const { status, error } = await accounts.updateRole(accountInfo.accountId, user_id, newRole);

      if (status === 404) {
          return;
      }
      
      if (error !== 'OK' && status !== 200) {
        if(error.includes("Unable to connect to database")){
          alert(t("failedToConnectToDatabase"))                     
        }
        else if(error.includes("Access Denied, no permissions to modify")){
          alert(t("accessDeniedNoPermissions"));
        }
        else if(error.includes("You do not have permission to set role")){
          alert(t("noPermissionToChangeRole"))                         
        }
          //alert(t("sorryUnableToRetrieve"));
        else {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
        }
        throw new Error(error);
      }
      console.log("user permission changed");

    } catch (error) {
      //alert(t(error));
      console.error(error);
    }
    fetchAccountInfo();
  };

  function inviteComplete(){
    console.log("user invited to account")
    fetchAccountInfo();
  };

  function openDelDialog(){
    setDelAccountDialog(true);
  };

  function closeDelDialog(){
    setDelAccountDialog(false);
  }

  const handleRemoveAccount = async () => {
    try{
      const {status, data} = await accounts.deleteAccount(accountInfo.accountId);
      const {error} = data;
      
      if (status === 404) {
        return;
      }
    
      if (error !== 'OK' && status !== 200) {
        if(error.includes("Unable to connect to database")){
          alert(t("failedToConnectToDatabse"));                          
        }
        else if(error.includes("Access Denied, no permission to delete account")){
          alert(t("accessDeniedDeleteAccount"));                          
        }
        else if(error.includes("Access Denied, no permissions to modify")){
          alert(t("accessDeniedNoPermissions"));
        }
        else if(error.includes("Account does not exist")){
          alert(t("accountDoesNotExist"));                          
        }
        else if(error.includes("Unable to delete. Account still has Groups.")){
          alert(t("failedToDeleteAccountGroup"));                          
        }
        else if(error.includes("Unable to delete. Account still has Devices")){
          alert(t("failedToDeleteAccountDevices"));                          
        }
        else {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
        }
          throw new Error(error);
      }
    
      // Refresh page to navigate back to default account
      fetchAccountInformation('default', undefined, undefined, undefined);

    }catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = (event, newTabIndex) => {
    setValue(newTabIndex);
  };

  const getSubscriptionCount = () => {
    let subscription_count = 0;
    for (let i=0; i < deviceList.length; i++)
    {
      if (deviceList[i].activation_status === 1)
      {
        subscription_count += 1;
      }
    }

    return subscription_count;
  }
  
  return (
    <>
      {<Grid container item direction='column'>
          <Toolbar sx={{height: '64px'}}>
            <Tooltip title={t("goHome")}>
                <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                    <ArrowBack />
                </IconButton>
            </Tooltip>
            <Typography variant='h5'
                color='textPrimary'>
                {t("accountManagement")}
            </Typography>
          </Toolbar>
          <Stack direction='row' spacing={0} sx={{height: '32px', minHeight: '32px'}}>
            <Box flexGrow={1}/>
            {myRole === "owner" && <IconButton
                  edge="start"
                  color='inherit'
                  onClick={(openDelDialog)}
                  >
                    <DeleteIcon/>
                    <Typography>{t('deleteAccount')}</Typography>
                </IconButton>  }
            <AccountInvite 
              accountInfo={accountInfo} 
              groups={accountGroupsObj} 
              access={groupInviteAccess} 
              inviteComplete={inviteComplete}/>
            {!fullScreen && <AddDevice 
              accountInfo={accountInfo} 
              updateDevices={fetchAccountInfo} 
              groups={accountGroupsObj} 
              altTitle={true}
              isFAB={false}/>}
          </Stack>
        <TabContext value={value}>
          <Box sx={{ 
              borderBottom: 1,
              borderColor:'divier',
              height: '49px'
              }}>
              <TabList
                onChange={handleTabChange}
                justifyContent="center"
                alignItem="center"
                >
                <Tab label={t("Users")} value="1" />
                <Tab label={t("Devices")} value="2"/>
                <Box flexGrow={1}/>
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0 }}>
              {
                dataReady ? 
                  <Grid container item xs='auto' direction='column'>
                    <Box id="UserBox" height="calc(100vh - 128px - 49px - 32px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                        {userList.length ? <List sx={{padding: theme.spacing(0), maxWidth: 450 }} > {userList.map((user) => (
                                  <UserCard data={myData} user={user} accountInfo={accountInfo} setUserRole={setUserRole} removeUser={removeUser} fetchAccountInfo={fetchAccountInfo}/> 
                              ))} 
                            </List>
                        :<Grid item xs container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress sx={{margin: theme.spacing(4)}}/>
                          </Grid>}
                    </Box>
                  </Grid>
                  :<Grid item xs container
                      direction="row"
                      justifyContent="center"
                      alignItems="center" 
                  >
                    <CircularProgress sx={{margin: theme.spacing(4)}}/>
                  </Grid>
              
              }
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
              {
                dataReady ? 
                  <Grid container item xs='auto' direction='column'>
                    <Box id="UserBox" height="calc(100vh - 128px - 49px - 32px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                        {
                          deviceList.length ? 
                            <List sx={{padding: theme.spacing(0), maxWidth: 500 }} > {deviceList.map((device) => (
                                  <DeviceCard 
                                    device={device} 
                                    accountInfo={accountInfo} 
                                    removeDevice={removeDevice} 
                                    billingData={billingData}
                                    setBillingData={setBillingData}
                                    orgBilling={orgBilling}
                                    getSubscriptionCount={getSubscriptionCount}
                                    fetchAccountInfo={fetchAccountInfo}/> 
                              ))} 
                            </List>
                            :<Grid item xs container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" >
                              <Typography>{t("noDevices")}</Typography>
                            </Grid>
                        }
                    </Box>
                    {fullScreen && <AddDevice 
                      accountInfo={accountInfo} 
                      updateDevices={fetchAccountInfo} 
                      groups={accountGroupsObj} 
                      altTitle={true}
                      isFAB={true}/>}
                  </Grid>
                  :<Grid item xs container
                      direction="row"
                      justifyContent="center"
                      alignItems="center" >
                    <CircularProgress sx={{margin: theme.spacing(4)}}/>
                  </Grid>
              }
            </TabPanel>
        </TabContext>
      </Grid>}   
      {delAccountDialog && <ModalPrompt
        title={t('deleteAccount') + " : {accountName}".replace("{accountName}", accountInfo.accountName)}
        content={(userList.length > 1 || deviceList.length > 0 || billingData.id ? true:false) ? t("howToDeleteAccountDialog")
          : t("deleteAccountDialog")}
        action={t('delete')}
        handleAction={handleRemoveAccount} 
        handleCancel={closeDelDialog} 
        openModal={delAccountDialog}
        disableAction={userList.length !== 1 || deviceList.length !== 0 || billingData.id ? true:false}
        useConfirm={!(userList.length !== 1 || deviceList.length !== 0 || billingData.id ? true:false)}
        fullScreen={fullScreen}
      />}
    </>
  )
}