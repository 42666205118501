import {Auth} from 'aws-amplify';
import {host} from '../data.js';
import {checkAuthorizationException} from '../helpers/checkAuthorizationException.js';

const LOCATION_ERROR_MESSAGE =
    'Error in account information service! Provide, correct data to the service!';

let urlConfig = {
    api: 'accounts/{accountId}/devices/',
    apimydevices: 'accounts/{accountId}/mydevices/',
    apiUidGet: 'accounts/{accountId}/devices/{deviceId}',
    apiName: 'accounts/{accountId}/devices/{deviceId}/name',
    apiUidGetDetails: 'accounts/{accountId}/devices/{deviceId}/details',
    apiUidGetDetailsInfo: 'accounts/{accountId}/devices/{deviceId}/details/info',
    apiConfig: 'accounts/{accountId}/devices/{deviceId}/config',
    apiMetrics: 'accounts/{accountId}/devices/{deviceId}/metrics',
    apiEvents: 'accounts/{accountId}/devices/{deviceId}/events',
    apiCmd: 'accounts/{accountId}/devices/{deviceId}/cmd',
    //proxyPort: 5000
};

export const devices_inf = {
    addDevices: async (deviceList, accountId, groupList, hasBillingInfo) => {
        try {
            if (deviceList.length === 0 || groupList.length === 0 || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            for (var i; i<deviceList.length; i++){
                if(deviceList[i].uid === undefined){
                    return console.error(LOCATION_ERROR_MESSAGE);
                }
            }
            let l_groupList = groupList;
            
            const url =
                host + urlConfig.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    devices: deviceList,
                    group: l_groupList,
                    action: "ADD",
                    hasBillingInfo: hasBillingInfo
                }),
            });

            const data = await response.json();
            const { error, errorList } = data;
            return {
                status: response.status, 
                error: error, 
                errorList: errorList, 
            };
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    
    activateDevices: async (deviceList, accountId, hasBillingInfo) => {
        try {
            if (deviceList.length === 0 || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            for (var i; i<deviceList.length; i++){
                if(deviceList[i].uid === undefined){
                    return console.error(LOCATION_ERROR_MESSAGE);
                }
            }
            
            const url =
                host + urlConfig.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    devices: deviceList,
                    action: "ACTIVATE",
                    hasBillingInfo: hasBillingInfo
                }),
            });

            const data = await response.json();
            const { error, errorList } = data;
            return {
                status: response.status, 
                error: error, 
                errorList: errorList, 
            };
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },

    deactivateDevices: async (deviceList, accountId, hasBillingInfo) => {
        try {
            if (deviceList.length === 0 || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            for (var i; i<deviceList.length; i++){
                if(deviceList[i].uid === undefined){
                    return console.error(LOCATION_ERROR_MESSAGE);
                }
            }
            
            const url =
                host + urlConfig.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    devices: deviceList,
                    action: "DEACTIVATE",
                    hasBillingInfo: hasBillingInfo
                }),
            });

            const data = await response.json();
            const { error, errorList } = data;
            return {
                status: response.status, 
                error: error, 
                errorList: errorList, 
            };
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },

    removeDevices: async (deviceList, accountId, hasBillingInfo) => {
        try {
            if (deviceList.length === 0 || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            for (var i; i<deviceList.length; i++){
                if(deviceList[i].uid === undefined){
                    return console.error(LOCATION_ERROR_MESSAGE);
                }
            }
            const url =
                host + urlConfig.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    devices: deviceList,
                    Action: "REMOVE",
                    hasBillingInfo: hasBillingInfo
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateDevices: async (deviceList, accountId, hasBillingInfo) => {
        try {
            if (deviceList.length === 0 || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            for (var i; i<deviceList.length; i++){
                if(deviceList[i].uid === undefined){
                    return console.error(LOCATION_ERROR_MESSAGE);
                }
            }
            const url =
                host + urlConfig.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    devices: deviceList,
                    Action: "UPDATE",
                    hasBillingInfo: hasBillingInfo
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateDeviceName: async (accountId, deviceId, newName) => {
        try {
            if (!deviceId || !accountId || !newName) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiName.replace("{accountId}",accountId).replace("{deviceId}",deviceId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    name: newName,
                }),
            });

            const data = await response.json();
            const {error} = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getMyDevices: async (accountId, abortController={signal:undefined}) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apimydevices.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });
            const data = await response.json();
            const { error, deviceList, deviceInfo, groupsList, groupsInfo } = data;
            return {status: response === undefined ? 0 : response.status, 
                    error: error,
                    myDeviceList: deviceList,
                    myDeviceInfo: deviceInfo,
                    myGroupsList: groupsList,
                    myGroupsInfo: groupsInfo
                };
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },

    getDevice: async (deviceId, accountId) => {
        try {
            if (!deviceId || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiUidGet.replace("{accountId}",accountId).replace("{deviceId}",deviceId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, device_info } = data;
            return {status: response.status, error: error, device_info: device_info};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    getDeviceDetails: async (accountId, deviceId, abortController={signal: undefined}) => {
        try {
            if (!deviceId || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiUidGetDetails.replace("{accountId}",accountId).replace("{deviceId}",deviceId);
            const user = await Auth.currentAuthenticatedUser();
            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, userPermissions, deviceInfo, groupsList } = data;
            return {status: response.status, 
                    error: error,
                    deviceDetailsPermissions: userPermissions,
                    deviceDetailsInfo: deviceInfo,
                    groupsDetailsList: groupsList,
                };
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    getDeviceInfo: async (accountId, deviceId, abortController={signal: undefined}) => {
        try {
            if (!deviceId || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiUidGetDetailsInfo.replace("{accountId}",accountId).replace("{deviceId}",deviceId);
            const user = await Auth.currentAuthenticatedUser();
            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, deviceInfo } = data;
            return {status: response.status, 
                    error: error,
                    deviceDetailsInfo: deviceInfo,
                };
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    getDevices: async (accountId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.api.replace("{accountId}", accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, deviceList } = data;
            return {status: response.status, error: error, deviceList: deviceList};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    updateDeviceConfiguration: async (accountId, deviceId, params) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiConfig.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    params: params,
                }),
            });

            const data = await response.json();
            const { error, device_info } = data;
            return {status: response.status, error: error, deviceDetailsInfo: device_info};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deviceStartLubrication: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'start'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deviceStopLubrication: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'stop'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deviceClearAlarms: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'clear_alarms'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deviceAddWatcherSingle: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'add_watcher'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deviceRemoveWatcherSingle: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'remove_watcher'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deviceUpdateFwMain: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'update_main'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deviceUpdateFwComm: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'update_comm'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateDeviceMetrics: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'metrics'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    resetDeviceMetrics: async (accountId, deviceId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiCmd.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            let params = {'cmd': 'reset_metrics'}
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error: error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getDeviceMetrics: async (accountId, deviceId, abortController={signal: undefined}) => {
        try{
            if (!accountId || !deviceId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiMetrics.replace("{accountId}", accountId).replace("{deviceId}", deviceId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, deviceInfo, metricsInfo } = data;
            return {status: response.status, error: error, deviceInfoMetrics: deviceInfo, metricsInfo: metricsInfo};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    getDeviceEvents: async (accountId, deviceId, lookupDays=14, abortController={signal: undefined}) => {
        try{
            if (!accountId || !deviceId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiEvents.replace("{accountId}", accountId).replace("{deviceId}", deviceId) 
                + "?lookback={days}".replace("{days}",lookupDays);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, deviceInfo, deviceEvents } = data;
            return {status: response.status, error: error, deviceInfoEvents: deviceInfo, deviceEvents: deviceEvents};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    
};

export default devices_inf;
