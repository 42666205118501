
export function sanitizeObjectName_40char (input) {
  const regex = /^([ a-zA-Z0-9\-]){0,40}$/i;
  let update = false;
  if (regex.test(input)) {
      update = true;
  }
  return update;
}

export function sanitizeObjectName_30char (input) {
  const regex = /^([ a-zA-Z0-9\-]){0,30}$/i;
  let update = false;
  if (regex.test(input)) {
      update = true;
  }
  return update;
}

export function sanitizeObjectNameNotUrl_64char (input) {
  const regex = /^([ a-zA-Z0-9,.+_/\-'"()]){0,64}$/i;
  let update = false;
  if (regex.test(input)) {
    update = true;
  }
  return update;
}