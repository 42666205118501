import { useDev
 } from "./data";

export const awsIotConfiguration_dev = {
    endpoint: 'wss://d06489613rwtcrf8fv26x-ats.iot.us-east-2.amazonaws.com/mqtt?token=allow', 
    region: 'us-east-2',
    poolId: 'us-east-2:bf52e8cf-2a9d-410b-ab6b-3da084908fa7',
    host: 'd06489613rwtcrf8fv26x-ats.iot.us-east-2.amazonaws.com'
  };

  export const awsIotConfiguration_prod = {
    endpoint: 'wss://d08011401ceaweat6ztjn-ats.iot.us-east-2.amazonaws.com/mqtt?token=allow', 
    region: 'us-east-2',
    poolId: 'us-east-2:ba0958dd-7119-4ba2-a0da-964ab8458b1b',
    host: 'd08011401ceaweat6ztjn-ats.iot.us-east-2.amazonaws.com'
  };

  export const awsIotConfiguration = useDev ? awsIotConfiguration_dev : awsIotConfiguration_prod;