import React, { useState, useRef } from 'react';
import {useTheme} from '@emotion/react';
import { 
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Typography, 
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { 
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';

const DataEntryListItemHeader = ({ label, collapsableState=false, collapsable=false, onClick=()=>{}}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
    {collapsable ? 
        <ListItem 
            divider 
            key={label} 
            disablePadding
            sx={{
                backgroundColor: theme.palette.primary.dark,
            }}
        >  
            <ListItemButton onClick={onClick}>
                <ListItemText  primaryTypographyProps={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                        marginLeft: theme.spacing(-1),
                    }}
                    primary={t(label)}
                />
                {collapsable && (collapsableState ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
        </ListItem>
        :<ListItem 
            divider 
            key={label} 
            disablePadding
            sx={{
                backgroundColor: theme.palette.primary.dark,
            }}
        >  
            <ListItemText  primaryTypographyProps={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    marginLeft: theme.spacing(1),
                }}
                primary={t(label)}
            />
        </ListItem>
    }
    </>
  );
};

export default DataEntryListItemHeader;
