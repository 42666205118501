import React, { useState, useRef } from 'react';

import {
    AppBar,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography
} from '@mui/material';
import Slide from '@mui/material/Slide';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const DataEntryDialogueList = ({ title, param_id, instructions, dialogueOpen, handleSave, handleCancel, editing=false, listIn=[{id: "1", displayLabel:"example", name:"example"}] }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(dialogueOpen ? true : false);
  const invertEmphasis=true;
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const handleClick = (key_in) => {
    setOpen(false);
    handleSave(param_id, key_in);
  };
  const cancel = () => {
    setOpen(false);
    handleCancel();
  };
  React.useEffect(() => { 
    setOpen(dialogueOpen);
  }, [dialogueOpen]);

  return (
    <Dialog
        fullScreen={fullScreen}
        open={dialogueOpen ? true : false}
        onClose={cancel}
        TransitionComponent={Transition}
        BackdropProps={{style: {backgroundColor: 'black'}}}
        fullWidth={!fullScreen}
      >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={cancel}
                    aria-label='close'
                    >
                        <ArrowBackIcon/>
                </IconButton>
                <Typography align="center" sx={{ m1: 2, flex: 1}} component="div">
                    {t(title)}
                </Typography>
            </Toolbar>
        </AppBar>
        <Grid container
            alignItems='stretch'
            direction='column'
            justifyContent='flex-start'
            sx={{
                padding: theme.spacing(2),
            }}
        >
            <Grid item xs >
                <Typography>
                    {t(instructions)}
                </Typography>
            </Grid>
            <List>
                <ListItem key={param_id+'-divider'} divider disablePadding/>
                {listIn.map(({key, id, displayLabel}) => (
                    <ListItem divider key={key} disablePadding>
                    <ListItemButton 
                        disabled={!editing} 
                        onClick={() => {handleClick(id)}}
                    >
                        <ListItemText  primaryTypographyProps={{
                                fontWeight: invertEmphasis ? undefined : "bold",
                                color: invertEmphasis ? theme.palette.primary.main : undefined,
                            }}
                            primary={t(displayLabel)}
                        />
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
        </Grid>
    </Dialog>
  );
};

export default DataEntryDialogueList;
