/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useParams
} from 'react-router-dom'
//Amplify
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import { AmplifyProvider, Authenticator, View, Image } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { AWSIoTProvider } from '@aws-amplify/pubsub';
import awsIotConfiguration from './aws-iot-configuration';

import { useDev } from './data';
//import awsExports from "./aws-exports";
import awsExportsPrd from "./aws-exports-prd.js"
import awsExportsDev from "./aws-exports-dev.js"
import "./styles.css";

// Material UI
import { CssBaseline, Typography } from '@mui/material';
import { ThemeProvider, useTheme } from '@emotion/react'

// Graco dependencies
import HomePage from './HomePage';
import { changeLanguage } from './helpers/changeLanguage';
import {appTitle, logo} from './data';
import HeaderLogo from './Header/assets/Graco_Logo_Horiz_White.png';

import Theme from './Theme.js';
//import logoPulse from './Header/assets/new_logo_black.png';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import {AuthHeader} from './GAuth/Header';
import {AuthFooter} from './GAuth/Footer';
import { SignInHeader } from './GAuth/SignInHeader';

const awsExportsConfig = useDev ? awsExportsDev : awsExportsPrd;
//Amplify.configure(awsExports);
Amplify.configure(awsExportsConfig);


// The main App. Loaded by index.js, the whole app trees from here.
function App () {
  const { t } = useTranslation();
  const params = useParams();
  const cookies = new Cookies();
  
  return (
    
        <ThemeProvider theme={Theme}>
            <CssBaseline />
              <>
              <BrowserRouter>
                <HomePage />
              </BrowserRouter>
              </>
        </ThemeProvider>
    
  );
}

//export default withAuthenticator(App);
export default withAuthenticator(App, {
  components: {
    Header: AuthHeader,
    //SignIn:{
    //  Header: SignInHeader,
    //},
    Footer: AuthFooter
  }
});
//export default App;