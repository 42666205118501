import React, { useState, useRef } from 'react';
import {useTheme} from '@emotion/react';
import { 
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Typography, 
} from "@mui/material";
import { useTranslation } from 'react-i18next';

const InfoListItem = ({ label, value, icon=undefined, divider=true }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <ListItem divider={divider} key={label}>
        <ListItemText  primaryTypographyProps={{
                color: theme.palette.primary.main,
            }}
            primary={t(label)}
        />
        <ListItemIcon>
            {icon ? icon :
            <Typography sx={{fontWeight: "bold",}}>
                {value}
            </Typography>}
        </ListItemIcon>
    </ListItem>
  );
};

export default InfoListItem;
