/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import {
  useNavigate,
  useLocation
} from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  FormControl,
  Typography,
} from "@mui/material";
import { 
  ArrowBack,
} from '@mui/icons-material';

import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

//Graco Components

// import EditFriendlyName from '../../CommonComponents/EditFriendlyName.js';
import EditIcon from '@mui/icons-material/Edit';
import configurations_inf from '../../services/configurations_inf';
import { 
  advanced_parameter_obj,
  interval_parameters_obj,
  low_level_parameter_obj,
  lubrication_parameters_obj,
  outputs_parameter_obj,
  config_obj_type,
  hw_config_obj
   } from '../../DeviceInfo/g3_Config.js';
import LubricationEndSettings from '../../MainPage/DevicePage/Components/LubricationEndSettings';
import LubricationIntervalSettings from '../../MainPage/DevicePage/Components/LubricationIntervalSettings';
import LowLevelSettings from '../../MainPage/DevicePage/Components/LowLevelSettings.jsx';
import OutputSettings from '../../MainPage/DevicePage/Components/OutputSettings.jsx';
import AdvancedSettings from '../../MainPage/DevicePage/Components/AdvancedSettings.jsx';
import {DEVICE_TYPES} from '../../DeviceInfo/deviceTypes'
import InfoListItem from '../../CommonComponents/InfoListItem';
import { sanitizeObjectNameNotUrl_64char, sanitizeObjectName_30char } from '../../helpers/sanitizeObjectName.js';

export default function TemplateDetails({accountInfo}){
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    const [dataReady, setDataReady] = useState(false);
    const [templateName, setName] = useState("");
    //const [newName, setNewName] = useState();
    const rTemplateName = useRef();
    const [desc, setDescription] = useState("");
    //const [newDesc, setNewDesc] = useState();
    const rDescription = useRef();
    const [hw_config, setHwConfig] = useState([]);
    const [type, setType] = useState();
    const [editing, setEditing] = useState(false);
    const [configData, setConfigData]= useState();
    const [newConfig, setnewConfig] = useState();
    const [reRender, setreRender] =useState(false);
    const location = useLocation();
    const [template, setTemplate] = useState(location.state);
    const [templateOriginal, setTemplateOriginal] = useState(JSON.parse(JSON.stringify(location.state)));
    
    useEffect(() => {
      if (template === null)
      {
        alert(t("templateNotFound"));
        navigation('/configurations'); 
      }
      else {
        filterdata(template);
        confirmNonEmpty();
      }
    },[template]);

    function filterdata(template){
      setName(template["template"].name);
      setDescription(template["template"].desc);
      let temp = template["template"].type;
      for (const [key, value] of Object.entries(DEVICE_TYPES)) {
          if(key === temp){
              setType(value.name)
              setHwConfig(config_obj_type[key]);
          }
      }
      
      setConfigData(template["template"].param);
    
      setDataReady(true);
    }

    const confirmNonEmpty  = (hw_config) => {
      function defaults_by_hw_config(param_obj, hwConfig){
        let useDisabledDefault = false;
        let output = String(param_obj.default);
        Object.keys(hw_config_obj).forEach(key => {
                if(hw_config_obj[key].affected_config_id.includes(String(param_obj.id))){
                    if (!hwConfig.includes(hw_config_obj[key].id)){
                        useDisabledDefault = true;
                    }
                }
        });
        if (useDisabledDefault){
            output = String(param_obj.disabled_default);
        }
        return (output);
    }
      if(!template){
        if (true){
            let t_config = template.param ? template.param : {};
            Object.keys(interval_parameters_obj).forEach(key => {
                if(!template.param[key]){
                    t_config[key] = template.param[key] ?  template.param[key] : defaults_by_hw_config(interval_parameters_obj[key], hw_config);
                } else{
                    if(interval_parameters_obj[key].type === "float"){
                        t_config[key] = String(parseFloat(template.param[key]));
                    } else{
                        t_config[key] = template.param[key];
                    }
                }
            });
            Object.keys(lubrication_parameters_obj).forEach(key => {
                if(!template.param[key]){
                    t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(lubrication_parameters_obj[key], hw_config);
                } else{
                    if(lubrication_parameters_obj[key].type === "float"){
                        t_config[key] = String(parseFloat(template.param[key]));
                    } else{
                        t_config[key] = template.param[key];
                    }
                }
            });
            Object.keys(low_level_parameter_obj).forEach(key => {
                if(!template.param[key]){
                    t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(low_level_parameter_obj[key], hw_config);
                } else{
                    if(low_level_parameter_obj[key].type === "float"){
                        t_config[key] = String(parseFloat(template.param[key]));
                    } else{
                        t_config[key] = template.param[key];
                    }
                }
            });
            Object.keys(outputs_parameter_obj).forEach(key => {
                if(!template.param[key]){
                    t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(outputs_parameter_obj[key], hw_config);
                } else{
                    if(outputs_parameter_obj[key].type === "float"){
                        t_config[key] = String(parseFloat(template.param[key]));
                    } else{
                        t_config[key] = template.param[key];
                    }
                }
            });
            Object.keys(advanced_parameter_obj).forEach(key => {
                if(!template.param[key]){
                    t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(advanced_parameter_obj[key], hw_config);
                } else{
                    if(advanced_parameter_obj[key].type === "float"){
                        t_config[key] = String(parseFloat(template.param[key]));
                    } else{
                        t_config[key] = template.param[key];
                    }
                }
            });
            // Object.keys(pin_code_parameters_obj).forEach(key => {
            //     if(!template.param[key]){
            //         t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(pin_code_parameters_obj[key], hw_config);
            //     } else{
            //         if(pin_code_parameters_obj[key].type === "float"){
            //             t_config[key] = String(parseFloat(template.param[key]));
            //         } else{
            //             t_config[key] = template.param[key];
            //         }
            //     }
            // });
           
          }
        }
      }

    function isEditing(){
      setEditing(true);
    }
    function cancelEdit(){
      //setName(originalName);
      //setConfigData(configDataOriginal);
      setTemplate(JSON.parse(JSON.stringify(templateOriginal)));
      setEditing(false);
    }

    const saveEdit = async() =>{
      try{
        const {status, error} = await configurations_inf.UpdateTemplate(
        accountInfo.accountInfo.accountId, template.template.id, templateName, desc, newConfig
        );
        if (status === 404) {
          return;
        }
        if (error !== 'OK' || status !== 200) {
            if(error.includes("Unable to connect to database")){
                alert(t("failedToConnectToDatabase"));                
            }
            if(error.includes("Template name already exists")){
                alert(t("nameAlreadyExists"));
            }
        }
        else{
            navigation(-1);
        } 

      }catch (error) {
        console.log(error);
      }
      
    }

    const handleUpdatConfig = (newSettings) => {
      let data = configData;
      Object.keys(newSettings).forEach(key => {
          data[key] = String(newSettings[key]);
      });
      setnewConfig(data);
      setreRender(!reRender);
  };

  return (
      <>
      <Grid container item direction='column'>
        <Grid height='64px' item xs>
          <Toolbar>
            {editing ? <Button onClick={cancelEdit}>
              {t("cancel")}
            </Button>
            : <IconButton
                edge='start'
                color='inherit'
                onClick={() => { navigation(-1); } }
                aria-label='close'
              >
                <ArrowBack />
              </IconButton>}
            <Box flexGrow={1} />
            {editing ? <Button 
              disabled={templateName.length > 0 ? false : true}
              onClick={saveEdit} 
              sx={{ color: theme.palette.primary.contrastText }}>
                {t("save")}
            </Button> :
              <Tooltip title={t("editTemplate")}>
                <IconButton
                  edge='end'
                  color='inherit'
                  aria-label='close'
                  onClick={isEditing}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              }
            </Toolbar>
          </Grid>
          <Box id="contentBox" height="calc(100vh - 128px)" overflow='auto' >
            {dataReady ? <Grid sx={{
                    minWidth: 300,
                    maxWidth: 500,
                }}
                item 
                container 
                direction='column'>
              <Divider/>
              <Grid container 
                        direction="column"
                        
                        sx={{padding: theme.spacing(1)}}>
                {editing ? <FormControl>
                <Stack spacing={1}>
                  <TextField type="test" label={t("name")}
                    error={templateName.length > 0 ? false : true}
                    inputRef={rTemplateName}
                    value={templateName}
                    onChange={(e) => {
                      if (sanitizeObjectName_30char(e.target.value)) {
                        setName(e.target.value);
                      }
                    } }/>
                </Stack>
              </FormControl> : <Typography fontWeight="bold" marginleft={1}> <h2>{t(templateName)}</h2></Typography>}
              </Grid>
              <Grid container direction="row" sx={{ padding: theme.spacing(1) }}>
              {editing ? <FormControl>
                  <Stack spacing={2}>
                    <TextField type="test" label={t("templateDescription")}
                      multiline={true}
                      rows={2}
                      style={{ width: "calc(100vh-500px)", height:"calc(100vh-75px)" }}
                      inputRef={rDescription}
                      value={desc}
                      onChange={(e) => {
                        if(sanitizeObjectNameNotUrl_64char(e.target.value)){
                          setDescription(e.target.value);
                        }
                      } }
                      marginTop={1}
                      marginBottom={1} />
                  </Stack>
                </FormControl> :
                  <Box sx={{width:1}} height={75} border={1} marginTop={1} marginBottom={1} >
                    <Grid container direction="row">
                      <Typography marginLeft={1}>{t(desc)}</Typography>
                    </Grid>
                  </Box>}
            </Grid> 
            <InfoListItem label={t('templateDeviceType')} value={t(type)}/>

            <LubricationIntervalSettings editing={editing} currentSettings={configData} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
            <LubricationEndSettings editing={editing} currentSettings={configData} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
            <LowLevelSettings editing={editing} currentSettings={configData} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
            <OutputSettings editing={editing} currentSettings={configData} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
            <AdvancedSettings editing={editing} currentSettings={configData} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
          </Grid>:
              <Grid item xs container
                  direction="row"
                  justifyContent="center"
                  alignItems="center" >
                <CircularProgress sx={{margin: theme.spacing(4)}} />
            </Grid> }
          </Box>
      </Grid>
     </>
    );
}