import React, { useState, useEffect} from 'react';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    FormControlLabel,
    Grid,
    ListItem,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Paper,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { accounts } from '../../services/accounts';
import devices_inf from '../../services/devices_inf';
import { StyledSwitch } from '../../styledComponentsCommon';

const AccountSettingsCard = ({ account, accountInfo, number, fetchAccounts}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [accountName, setAccountName] = useState(account.name);
    const [targetAccountId, setTargetAccountId] = useState(account.id);
    const [alarmEmail, setAlarmEmail] = useState(0);
    const [alertEmail, setAlertEmail] = useState(0);
    const [alarmText, setAlarmText] = useState(0);
    const [alertText, setAlertText] = useState(0);
    const [oldAlarmEmail, setOldAlarmEmail] = useState(0);
    const [oldAlertEmail, setOldAlertEmail] = useState(0);
    const [oldDiscEmail, setOldDiscEmail] = useState(0);
    const [oldAlarmText, setOldAlarmText] = useState(0);
    const [OldAlertText, setOldAlertText] = useState(0);
    const [oldDiscText, setOldDiscText] = useState(0);
    const [disEmail, setDisEmail] = useState(0);
    const [disText, setDisText] = useState(0);
    const [timeout, setTimeoutValue] = useState(1);
    const [oldTime, setOldTime] = useState();
    const [times, setTime] = useState([1,4,6,12,24,48,72]);
    const [timeDialog, setTimeDialog] = useState(false);
    const [save, setSave] = useState(false);
    const [groups, setGroups] = useState([]);
    const [oldGroups, setOldGroups] = useState([]);
    const [editGroupsDialog, setEditGroupsDialog] = useState(false);
    const [alarmCount, setAlarmCount] = useState(0);
    const [alertCount, setAlertCount] = useState(0);
    const [discCount, setDiscCount] = useState(0);

    const fetchNotificationData = async (abortController={signal: undefined}) =>{
        const {status, error, settings} = await accounts.getNotificationSettings(targetAccountId, abortController);
        if (error !== 'OK' && status !== 200) {
            if (error.includes("unable to connect to database")){
                alert(t("failedToConnectToDatabase"));
            }
            //alert(t(error));
            throw new Error(error);
        }
        else{
            if(settings !== null){
                if(settings.alarm_by_email !== null){
                    setAlarmEmail(settings.alarm_by_email); 
                    setOldAlarmEmail(settings.alarm_by_email); 
                }
                if(settings.alert_by_email !== null){
                    setAlertEmail(settings.alert_by_email); 
                    setOldAlertEmail(settings.alert_by_email); 
                }
                if(settings.alarm_by_text !== null){
                    setAlarmText(settings.alarm_by_text); 
                    setOldAlarmText(settings.alarm_by_text); 
                }
                if(settings.alert_by_text !== null){
                    setAlertText(settings.alert_by_text);
                    setOldAlertText(settings.alert_by_text);
                }
                if(settings.disconnect_by_email !== null){
                    setDisEmail(settings.disconnect_by_email);
                    setOldDiscEmail(settings.disconnect_by_email);
                }
                if(settings.disconnect_by_text !== null){
                    setDisText(settings.disconnect_by_text);
                    setOldDiscText(settings.disconnect_by_text);
                }
                if(settings.timout !== null){
                    setTimeoutValue(settings.timout);
                    setOldTime(settings.timout);
                }
            }
        }
        
    }

    const fetchUserGroups = async (abortController={signal: undefined}) =>{
        try {
            const { status, error, myDeviceList, myDeviceInfo, myGroupsList, myGroupsInfo } = await devices_inf.getMyDevices(
                targetAccountId,
                abortController
              );
              if (status === 404) {
                  return;
              }
        
              if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                  alert(t("failedToConnectToDatabase"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                  alert(t("accessDeniedNoView"));
                }
                else {
                  alert(t("sorryUnableToRetrieve"));
                  throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
              }
            else{
              console.log('Group Fetched');
            }
            let temp = [];
            let temp2 = [];
            let count1 = 0;
            let count2 = 0
            let count3 = 0
            for (const [key, value] of Object.entries(myGroupsInfo)) {
                let alert = 0;
                let alarm = 0;
                let disc = 0;
                if(value.notify_alert){alert = value.notify_alert};
                if(value.notify_alarm){alarm = value.notify_alarm};
                if(value.notify_disconnect)(disc = value.notify_disconnect)
                temp.push({"name": key, "group_id": value.group_id, "alert": alert, "alarm": alarm, "disconnect": disc})
                temp2.push({"name_old": key, "group_id_old": value.group_id, "alert_old": alert, "alarm_old": alarm, "disconnect_old": disc})

                if(alert === 1){
                    count1++;
                }
                if(alarm === 1){
                    count2++;
                }
                if(disc === 1){
                    count3++;
                }

            }
            setAlertCount(count1);
            setAlarmCount(count2);
            setDiscCount(count3);
            setGroups(temp);
            setOldGroups(temp2);
            }catch (error) {
                console.log('Failed to Fetch group')
                console.error(error);
            }
    }

    function cancelSaveDialog(){
        setAlarmEmail(oldAlarmEmail);
        setAlarmText(oldAlarmText);
        setAlertEmail(oldAlertEmail);
        setAlertText(OldAlertText);
        setDisEmail(oldDiscEmail);
        setDisText(oldDiscText);
        setTimeoutValue(oldTime);
        resetGroup();
        setSave(false);
    }

    function openTimeoutDialog(){
        setTimeDialog(true);
    }

    function closeTimeoutDialog(){
        setTimeDialog(false);
    }

    function cancelTimeout(){
        setTimeoutValue(oldTime);
        closeTimeoutDialog();
    }

    function handleSaveTimeout(){
        setTimeoutValue(timeout);
        closeTimeoutDialog();
    }


    function filterAccount(account){
        setTargetAccountId(account.id);
        setAccountName(account.name);
    }

    const changeAlarmEmail = (event) =>{
        if(event.target.checked){
            setAlarmEmail(1); 
            enableAllAlarm();
        }
        else{
            setAlarmEmail(0);
            if(alarmText === 0){
                disableAllAlarm();
            }
        }
        setSave(true);
    }
    const changeAlarmText = (event) =>{
        if(event.target.checked){
            setAlarmText(1);
            enableAllAlarm();
        }
        else{
            setAlarmText(0);
            if(alarmEmail === 0){
                disableAllAlarm();
            }
        }
        
        setSave(true);
    }
    const changeAlertEmail = (event) =>{
        if(event.target.checked){
            setAlertEmail(1); 
            enableAllAlert()
        }
        else{
            setAlertEmail(0);
            if(alertText === 0){
                disableAllAlert()
            }
        }
        setSave(true);
    }
    const changeAlertText = (event) =>{
        if(event.target.checked){
            setAlertText(1); 
            enableAllAlert();
        }
        else{
            setAlertText(0);
            if(alertEmail === 0){
                disableAllAlert();
            }
        }
        setSave(true);
    }
    const changeDisEmail = (event) =>{
        if(event.target.checked){
            setDisEmail(1); 
            enableAllDisc();
        }
        else{
            setDisEmail(0);
            if(disText === 0){
                disableAllDisc();
            }
        }
        setSave(true);
    }
    const changeDisText = (event) =>{
        if(event.target.checked){
            setDisText(1); 
            enableAllDisc();
        }
        else{
            setDisText(0);
            if(disEmail === 0){
                disableAllDisc();
            }
        }
        setSave(true);
    }

    const handleSetTimeout = (event) =>{
        setTimeoutValue(event.target.value);
        setSave(true);
    }

    function enableAllAlert(){
        let temp = [];
        for (const [key, value] of Object.entries(groups)){
            value.alert = 1;
            temp.push(value);
        }
        setGroups(temp);
        setAlertCount(groups.length)
    }
    function enableAllAlarm(){
        let temp = [];
        for (const [key, value] of Object.entries(groups)){
            value.alarm = 1;
            temp.push(value);
        }
        setGroups(temp);
        setAlarmCount(groups.length)
    }
    function enableAllDisc(){
        let temp = [];
        for (const [key, value] of Object.entries(groups)){
            value.disconnect = 1;
            temp.push(value)
        }
        setGroups(temp);
        setDiscCount(groups.length);
    }
    function disableAllAlert(){
        let temp = [];
        for (const [key, value] of Object.entries(groups)){
            value.alert = 0;
            temp.push(value);
        }
        setGroups(temp);
        setAlertCount(0)
    }
    function disableAllAlarm(){
        let temp = [];
        for (const [key, value] of Object.entries(groups)){
            value.alarm = 0;
            temp.push(value);
        }
        setGroups(temp);
        setAlarmCount(0)
    }
    function disableAllDisc(){
        let temp = [];
        for (const [key, value] of Object.entries(groups)){
            value.disconnect = 0;
            temp.push(value)
        }
        setGroups(temp);
        setDiscCount(0);
    }

    function resetGroup(){
        let temp =[];
        let count1 =0;
        let count2 =0;
        let count3 =0;
        for (const [key, value] of Object.entries(groups)){
            for (const [key2, value2] of Object.entries(oldGroups)){
                if(value.name === value2.name_old){
                    temp.push({"name": value2.name_old, "group_id": value2.group_id_old, "alert": value2.alert_old, "alarm": value2.alarm_old, "disconnect": value2.disconnect_old})
                }
            }
        }
        for (const [key3,value3] of Object.entries(temp)){
            if(value3.alert === 1){
                count1++;
            }
            if(value3.alarm === 1){
                count2++;
            }
            if(value3.disconnect === 1){
                count3++;
            }
        }
        setAlertCount(count1);
        setAlarmCount(count2);
        setDiscCount(count3);
        setGroups(temp);
        
    }


    function openGroupsDialog(){
        setEditGroupsDialog(true);
    }
    function closeGroupDialog(){
        resetGroup();
        setEditGroupsDialog(false);
        setSave(false);
    }
    function groupsSelected(){
        let count1=0;
        let count2=0;
        let count3=0;
        for (const [key, value] of Object.entries(groups)){
            if(value.alert || value.alarm || value.disconnect === 1){
                if(value.alert === 1){
                    count1++;
                }
                if(value.alarm === 1){
                    count2++;
                }
                if(value.disconnect === 1){
                    count3++;
                }
            }
        }
        setAlertCount(count1);
        setAlarmCount(count2);
        setDiscCount(count3);
        setEditGroupsDialog(false);
        setSave(true);
    }
    
    const enableAlert = (group, event) =>{
        let temp = [];
        if(event.target.checked){
            for (const [key, value] of Object.entries(groups)){
                if (value.name === group.name){
                    value.alert = 1;
                }
                temp.push(value);
            }
        }
        else{
            for (const [key, value] of Object.entries(groups)){
                if (value.name === group.name){
                    value.alert = 0;
                }
                temp.push(value);
            }
        }
        setGroups(temp);
    }
    const enableAlarm = (group, event) =>{
        let temp = [];
        if(event.target.checked){
            for (const [key, value] of Object.entries(groups)){
                if (value.name === group.name){
                    value.alarm = 1;
                }
                temp.push(value);
            }
        }
        else{
            for (const [key, value] of Object.entries(groups)){
                if (value.name === group.name){
                    value.alarm = 0;
                }
                temp.push(value);
            }
        }
        setGroups(temp);
    }
    const enableDisconnect = (group, event) =>{
        let temp =[];
        if(event.target.checked){
            for (const [key, value] of Object.entries(groups)){
                if (value.name === group.name){
                    value.disconnect = 1;
                }
                temp.push(value);
            }
        }
        else{
            for (const [key, value] of Object.entries(groups)){
                if (value.name === group.name){
                    value.disconnect = 0;
                }
                temp.push(value);
            }
        }
        setGroups(temp);
    }

    const updateSettings = async () =>{
        const {status, error} = await accounts.updateNotificationSettions(targetAccountId, alarmEmail, alarmText, alertEmail, alertText, disEmail, disText, timeout, groups);
        if (error !== 'OK' && status !== 200) {
            if (error.includes("unable to connect to database")){
                alert(t("failedToConnectToDatabase"));
            }
            //alert(t(error));
            throw new Error(error);
        }
        else{
            fetchNotificationData();
        }
        
        fetchNotificationData();
        fetchUserGroups();
        setSave(false);
    }

    useEffect(() => {
        const abortController = new AbortController();
        fetchNotificationData(abortController);
        filterAccount(account)
        fetchUserGroups(abortController)
        return () => abortController.abort();
    },[accountInfo, account])

    return(
        <>
        <ListItem key={account} 
                sx={{paddingLeft: theme.spacing(1), 
                    minWidth: 250,
                    maxWidth: 350
                }}
                alignItems='left'>
            <Grid container 
                direction='column' 
                justifyContent="flex-start" 
                alignItems="flex-start" 
                sx={{
                    backgroundColor: theme.palette.grey.dark,
                    borderRadius: '4px',
                    paddingLeft: theme.spacing(1)
                    //maxWidth: 550,
                }}>
                    <Grid item xs>
                        <Typography noWrap gutterBottom variant="body1" fontWeight="bold">{t(accountName)}</Typography>                
                    </Grid>
                    <Grid item container 
                        direction="row"
                        justifyContent="flex-start" 
                        alignItems="center" >
                            <Grid item xs>
                                <Grid item container direction="column" 
                                justifyContent="left" 
                                alignItems="left"
                                spacing={2}>
                                    <Grid item xs sx={{marginTop: theme.spacing(3)}}>
                                        <Typography fontSize={15}>{t("notifyAlert")}</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography fontSize={15}>{t("notifyAlarm")}</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography fontSize={13}>{t("notifyDisconnect")}</Typography>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        <Grid item xs>
                            <Grid item container 
                                direction="column"
                                justifyContent="flex-start" 
                                alignItems="end" >
                                <Grid item xs sx={{marginRight: theme.spacing(2)}}>
                                    <Typography> {t("notifyEmail")}</Typography>
                                </Grid>
                                <Grid item xs>
                                    <FormControlLabel control={<StyledSwitch checked={alertEmail ? true : false} onChange={changeAlertEmail} />}/> 
                                </Grid>
                                <Grid item xs> 
                                    <FormControlLabel control={<StyledSwitch checked={alarmEmail ? true : false} onChange={changeAlarmEmail} />}/> 
                                </Grid>
                                <Grid item xs>
                                    <FormControlLabel control={<StyledSwitch checked={disEmail ? true : false} onChange={changeDisEmail}/>}/> 
                                </Grid> 
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid item container direction="column"
                                justifyContent="flex-start" 
                                alignItems="end" >
                                <Grid item xs sx={{marginRight: theme.spacing(3)}}>
                                    <Typography> {t("notfiyText")}</Typography>
                                </Grid>
                                <Grid item xs >
                                    <FormControlLabel disabled={!number} control={<StyledSwitch checked={alertText ? true : false} onChange={changeAlertText} />}/>
                                </Grid>   
                                <Grid item xs>
                                    <FormControlLabel disabled={!number} control={<StyledSwitch checked={alarmText ? true : false} onChange={changeAlarmText} />}/>
                                </Grid>
                                <Grid item xs>
                                    <FormControlLabel disabled={!number} control={<StyledSwitch checked={disText ? true : false} onChange={changeDisText} />}/>
                                </Grid>   
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid item container direction="column"
                            justifyContent="flex-start"
                            alignItems="center">
                                <Grid item xs>
                                    <IconButton size="small" onClick={openGroupsDialog} sx={{color: theme.palette.secondary.light}}>
                                        <Typography fontSize={14}>{t("notifyGroup")}</Typography>  
                                        {/* <EditIcon/> */}
                                    </IconButton>
                                </Grid>
                                <Grid item xs sx={{marginBottom: theme.spacing(1)}}>
                                    {alertCount !== groups.length ? <Typography>{t(alertCount)}</Typography>: <Typography>{t("notifyGroupsAll")}</Typography>}
                                </Grid>
                                <Grid item xs sx={{marginBottom: theme.spacing(2)}}>
                                    {alarmCount !== groups.length ? <Typography>{t(alarmCount)}</Typography>: <Typography>{t("notifyGroupsAll")}</Typography>}
                                </Grid>
                                <Grid item xs sx={{marginBottom: theme.spacing(2)}}>
                                    {discCount !== groups.length ? <Typography>{t(discCount)}</Typography>: <Typography>{t("notifyGroupsAll")}</Typography>}
                                </Grid>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <Divider orientation="horizontal" flexItem 
                        sx={{
                            marginTop: theme.spacing(1), 
                            marginBottom: theme.spacing(1),
                            minWidth: 250,
                        }}>

                     </Divider>
                    <Grid item xs sx={{minWidth: 250, paddingBottom: theme.spacing(1)}} >
                        <Grid item xs container 
                            direction="row"
                            justifyContent="flex-start" 
                            alignItems="center"
                            spacing={1}>
                            <Grid item xs>
                                <Typography fontSize={13} nowrap>{t("disconnectTimeout")}</Typography>
                            </Grid>
                            <Grid item xs>
                                <Tooltip title={t("timeoutchange")}>
                                    <IconButton onClick={openTimeoutDialog} sx={{color: theme.palette.secondary.light}}>
                                        <Typography fontSize={13} nowrap >{t(timeout)} {t("hours")}</Typography>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    {save && <Grid item container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems='center'
                            sx={{
                                    paddingBottom: theme.spacing(1)
                                }}>
                        <Grid item xs>
                            <Tooltip title={t("notificationsSave")}>
                                <Button 
                                    variant='contained'
                                    color='secondary'
                                    onClick={updateSettings}>
                                    <Typography>{t("notificationsSave")}</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs>
                            <Tooltip title={t("notificationsCancel")}>
                                <Button 
                                    variant='outlined'
                                    color='primary'
                                    onClick={cancelSaveDialog}>
                                    <Typography>{t("notificationsCancel")}</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>}
            </Grid> 
        </ListItem>
        {editGroupsDialog && <Dialog
            disableEscapeKeyDown
            open={openGroupsDialog}
            onClose={closeGroupDialog}
            fullScreen={fullScreen}>
                <DialogContent>
                    <Grid item container>
                        <Grid item>
                            <Typography>{t("notificationsGroupDialog")}</Typography>
                        </Grid>
                    </Grid>
                    {fullScreen && <Grid align="end">
                            <Button onClick={closeGroupDialog} color="primary">
                                {t("notificationsCancel")}
                            </Button>
                            <Button onClick={groupsSelected} variant='contained' color="secondary">
                                {t("notificationsSave")}
                            </Button>
                        </Grid>}
                    <TableContainer component={Paper}>
                        <Table sx={{minwidth: 250}} aria-label="groups table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography fontSize={12} color={theme.palette.blue.main}>{t("notifyGroup")}</Typography></TableCell>
                                    <TableCell><Typography fontSize={12} color={theme.palette.blue.main}>{t("notifyAlert")}</Typography></TableCell>
                                    <TableCell><Typography fontSize={12} color={theme.palette.blue.main}>{t("notifyAlarm")}</Typography></TableCell>
                                    <TableCell><Typography fontSize={12} color={theme.palette.blue.main}>{t("notifyDisconnect")}</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groups.map((group) =>(
                                    <TableRow key={group.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="middle"> <Typography fontSize={13}> {t(group.name)}</Typography></TableCell>
                                        <TableCell align="left"> <Checkbox color="blue" checked={group.alert} onChange={e => enableAlert(group, e)}/></TableCell>
                                        <TableCell align="middle"> <Checkbox color="blue" checked={group.alarm} onChange={e => enableAlarm(group, e)}/></TableCell>
                                        <TableCell align="middle"> <Checkbox color="blue" checked={group.disconnect} onChange={e =>enableDisconnect(group, e)}/></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    {!fullScreen && <Grid>
                        <Button onClick={closeGroupDialog} color="primary">
                            {t("notificationsCancel")}
                        </Button>
                        <Button onClick={groupsSelected} variant='contained' color="secondary">
                            {t("notificationsSave")}
                        </Button>
                    </Grid>
                        }
                </DialogActions>
            </Dialog>}
        
        {timeDialog && <Dialog
            disableEscapeKeyDown
            open={openTimeoutDialog}
            onClose={closeTimeoutDialog}
            fullScreen={fullScreen}>
            <DialogContent>
                <Grid container item xs 
                        justifycontent='flex-start'
                        alignItems='center'
                        direction='column'>
                    <Grid item >
                        <Typography>{t("disconnectTimeoutDialog")}</Typography>
                            <Select 
                                size="small"
                                labelId='select timeout'
                                value={timeout}
                                onChange={handleSetTimeout}
                                style={{left: '40%', top:'30%'}}>
                                    {times.map(time => {
                                        return(
                                            <MenuItem value={time}>
                                                <Typography>{t(time)}</Typography>
                                            </MenuItem> 
                                        );  
                                    })} 
                            </Select>
                        
                    </Grid>
                    {fullScreen && <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth autoFocus variant='contained' color='secondary' onClick={handleSaveTimeout} >
                            {t('notificationsSave')}
                        </Button>
                    </Grid>}
                    {fullScreen && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth 
                            autoFocus
                            color='inherit'
                            onClick={cancelTimeout}
                        >
                            {t('notificationsCancel')}
                        </Button>
                    </Grid>}
                </Grid>
            </DialogContent>
            {!fullScreen && <DialogActions>
                <Button onClick={cancelTimeout}>
                    {t("notificationsCancel")}
                </Button>
                <Button onClick={handleSaveTimeout} variant='contained' color="secondary">
                    {t("notificationsSave")}
                </Button>
            </DialogActions>}
        </Dialog>}
        </>
    )
}

export default AccountSettingsCard;