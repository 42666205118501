import React from 'react';
import {PubSub, Auth } from 'aws-amplify';
import { MqttOverWSProvider } from "@aws-amplify/pubsub/lib/Providers";
import { awsIotConfiguration } from '../aws-iot-configuration';

import devices_inf from './devices_inf';

const mqtt_host = awsIotConfiguration.host;

var subscriptions = [];


const MqttDevice = ({accountId, deviceList=[], updateInfo=()=>{}, updateConnection=()=>{}, cleanupInfo=()=>{}, scanData=false}) => {
    //const event_subscription =  "ale01/prd/{account}/{thing}/event";
    //const status_subscription = "ale01/prd/{account}/{thing}/status";
    const scan_subscription =   "ale01/prd/{account}/{thing}/sc";
    const disconnect_subscription = "ale01/prd/{account}/{thing}/disconnected";
    const connect_subscription =    "ale01/prd/{account}/{thing}/connected";
    const latest_status_subscription = "ale01/prd/{account}/{thing}/latest";
    
    React.useEffect(() => {
      if (accountId){
        console.log('Connecting');
        //Attempt to create a connection and provide token + account context for authoriation.
        Auth.currentAuthenticatedUser().then ((user) => {
          const ampAuthToken = user.signInUserSession.idToken.jwtToken;
          PubSub.addPluggable(new MqttOverWSProvider({
            //here you would include your token as the query string parameter use to initialize the connection
            aws_pubsub_endpoint: `wss://${mqtt_host}/mqtt?account={account}&token={allow}`.replace("{allow}", ampAuthToken).replace("{account}", accountId), 
          }));
          subscribe();
        })
      }
      
      return () => {
        console.log("Leaving MqttDevice");
        unSubscribe();
        cleanupInfo();
        PubSub.removePluggable('MqttOverWSProvider');  // Seems to close current connections
        //noConnection = true;
      };
    }, [deviceList, accountId]);

    const callAddWatcher = async (deviceId, abortController={signal: undefined}) => {
      try{
        const response = devices_inf.deviceAddWatcherSingle(accountId, deviceId);
      } catch(error){
        console.error(error);
      }
    };

    function subscribe() {
        console.log("Create Subscriptions");
        let sub_id;
        let key_list = [];
        if (false){
          var testSub = PubSub.subscribe('#').subscribe({
              next: data => {
                data.value.client_received_at = new Date()
              },
              error: error => console.error(error),
              close: () => console.log('Done'),
          })
          subscriptions.push(testSub);
        }
        deviceList.forEach((thing, i) => {
          
          key_list = [];
          for (let i=0; i<subscriptions.length; i++){
            key_list.push(subscriptions[i].id);
          }
          let temp_obj = {};
          //Latest Status Subscription
          temp_obj = {};
          sub_id = "event_{thing}".replace("{thing}",thing);
          if(!key_list.includes(sub_id)){
            let event_sub = PubSub.subscribe(latest_status_subscription.replace("{thing}",thing).replace("{account}", accountId)).subscribe({
                next: data => {
                    data.value.client_received_at = new Date()
                    updateInfo(data.value);
                },
                error: error => console.error(error),
                close: () => console.log('Done'),
            })
            temp_obj["id"] = sub_id;
            temp_obj["sub"] = event_sub;
            subscriptions.push(temp_obj);
          }

          // //Event Subscription
          // temp_obj = {};
          // sub_id = "event_{thing}".replace("{thing}",thing);
          // if(!key_list.includes(sub_id)){
          //   let event_sub = PubSub.subscribe(event_subscription.replace("{thing}",thing).replace("{account}", accountId)).subscribe({
          //       next: data => {
          //           data.value.client_received_at = new Date()
          //           updateInfo(data.value);
          //       },
          //       error: error => console.error(error),
          //       close: () => console.log('Done'),
          //   })
          //   temp_obj["id"] = sub_id;
          //   temp_obj["sub"] = event_sub;
          //   subscriptions.push(temp_obj);
          // }

          // //Status Subscription
          // temp_obj = {};
          // sub_id = "status_{thing}".replace("{thing}",thing);
          // if(!key_list.includes(sub_id)){
          //   let status_sub = PubSub.subscribe(status_subscription.replace("{thing}",thing).replace("{account}", accountId)).subscribe({
          //       next: data => {
          //           data.value.client_received_at = new Date()
          //           updateInfo(data.value);
          //       },
          //       error: error => console.error(error),
          //       close: () => console.log('Done'),
          //   })
          //   temp_obj["id"] = sub_id;
          //   temp_obj["sub"] = status_sub;
          //   subscriptions.push(temp_obj);
          // }
        
          temp_obj = {}
          sub_id = "disconnect_{thing}".replace("{thing}",thing);
          if(!key_list.includes(sub_id)){
            let disconnection_sub = PubSub.subscribe(disconnect_subscription.replace("{thing}",thing).replace("{account}", accountId)).subscribe({
                next: data => {
                    data.value.client_received_at = new Date()
                    data.value.connection=0;
                    updateConnection(data.value);
                },
                error: error => console.error(error),
                close: () => console.log('Done'),
            })
            temp_obj["id"] = sub_id;
            temp_obj["sub"] = disconnection_sub;
            subscriptions.push(temp_obj);
          }
          
          temp_obj = {}
          sub_id = "connect_{thing}".replace("{thing}",thing);
          if(!key_list.includes(sub_id)){
            let connection_sub = PubSub.subscribe(connect_subscription.replace("{thing}",thing).replace("{account}", accountId)).subscribe({
              next: data => {
                  data.value.client_received_at = new Date()
                  data.value.connection=1;
                  updateConnection(data.value);
              },
              error: error => console.error(error),
              close: () => console.log('Done'),
            })
            //subscriptions.push(connection_sub);
            temp_obj["id"] = sub_id;
            temp_obj["sub"] = connection_sub;
            subscriptions.push(temp_obj);
          }

          if (scanData){
            temp_obj = {};
            sub_id = "scan_{thing}".replace("{thing}",thing);
            if(!key_list.includes(sub_id)){
              console.log("Subscribe to Scan");
              let scan_sub = PubSub.subscribe(scan_subscription.replace("{thing}",thing).replace("{account}", accountId)).subscribe({
                next: data => {
                  //Triggers back end that you are interested in getting a higher rate of date
                },
                error: error => console.error(error),
                close: () => console.log('Done'),
              })
              temp_obj["id"] = sub_id;
              temp_obj["sub"] = scan_sub;
              subscriptions.push(temp_obj);
              
              //Call to add watcher
              callAddWatcher(thing);
            }
          }
        })    
    }

    function unSubscribe() {
        console.log("unsubscribing");
        while (subscriptions.length) {
          let sub = subscriptions.pop();
          if (sub.sub !== null) {
            sub.sub.unsubscribe();
          }
        }
    }
    return (
        <>
        </>
    );
};

export default MqttDevice;
