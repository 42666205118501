/* eslint-disable react-hooks/exhaustive-deps */
import {Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import useSessionstorage from '@rooks/use-sessionstorage';
import {
    Box,
    Button,
    CircularProgress,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Link,
    List,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Toolbar,
    Tooltip,
    FormControl,
    Typography,
  } from "@mui/material";

import {
    ArrowBack,
    Edit
} from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { accounts } from '../services/accounts';
import AccountSettingsCard from './Components/AccountSettingsCard';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import i18n from '../i18n';
import LanguageIcon from '@mui/icons-material/Language';
import users_inf from '../services/users_inf';
import { GrowDiv } from '../styledComponentsCommon';
import StatusReportSettingsCard from './Components/StatusReportSettingsCard';


export default function NotificationsPage(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [accountList, setAccountList] = useState([]);
    const [accountInfo, setAccountInfo] = useState(props.accountInfo);
    const [value, setValue] = React.useState("1");
    const [number, setUserNumber] = useState();
    const [dataReady, setDataReady] = useState(false);
    const [hasNumber, setHasNumber] = useState(true);
    const [SRSettings, setSRSettings] = useState([]);

    const fetchAccounts = async (abortController={signal: undefined}) =>{
        try{
            const {status, error, userAccounts} = await accounts.getAccounts(abortController);
            
            if (error !== 'OK' && status !== 200) {
                if (error.includes("unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else {
                alert(t("sorryUnableToRetrieve"));
                alert(t("sorryUnableToRetrieve"));
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
            }
            else{
                //setAccountList(userAccounts.accountsList);
                filterData(userAccounts);
                fetchStatusReportSettings(abortController);
                setDataReady(true);
            }
        }
        catch(error){
            console.log(error);
        }
    };

    const fetchUserSettings = async(abortController) =>{
        try{
            const user = await Auth.currentAuthenticatedUser();

            const{status, error, userSettings} = await users_inf.getUserData(
                user,
                abortController
            );
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                //alert(t(error));
            throw new Error(error);
            }
            let temp = " ";
            if(userSettings.phone_number){
                temp = userSettings.phone_number;
            }
            else{
                temp = " ";
                setHasNumber(false);
            }
        }
        catch(error){
            console.log(error);
        }
    }

    const fetchStatusReportSettings = async(abortController) =>{
        const user = await Auth.currentAuthenticatedUser();
        const{status, error, statusReportList} = await users_inf.getUserStatusReports(
            user,
            abortController
        )
        if (status === 404) {
            return;
        }

        if (error !== 'OK' && status !== 200) {
            if(error.includes("Unable to connect to database")){
                alert(t("failedToConnectToDatabase"));
            }
            //alert(t(error));
        throw new Error(error);
        }
        setSRSettings(statusReportList);
    }

    function filterData(accountData){
        let data = [];
        let dict = accountData.accountsNavDict

        Object.keys(dict).forEach((key, index) => {
            let temp = {"name": key, "id":dict[key][0]};
            data.push(temp);
          });

        setAccountList(data);
    }

    const handleTabChange = (event, newTabIndex) => {
        setValue(newTabIndex);
      };

    useEffect(() => {
        const abortController = new AbortController();
        fetchAccounts(abortController);
        fetchUserSettings(abortController);
        return () => abortController.abort();
    },[accountInfo])


    return (
        <Grid container
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            //sx={{padding: theme.spacing(1),}}
        >
            <Grid item xs>
                <Toolbar sx={{height: '64px'}}>
                    <Tooltip title={t("goHome")}>
                        <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h5">
                        {t("notifications")}
                    </Typography>
                </Toolbar>
            </Grid>
            <TabContext value={value}>
            <Box sx={{ 
                borderBottom: 1, 
                borderColor: "divider",
                height: '49px'
                }}
            >
                <TabList
                    onChange={handleTabChange}
                    justifyContent="center"
                    alignItems="center" 
                    >
                    <Tab label={t("notificationAccountList")} value="1" />
                    <Tab label={t("notificationDetails")} value="2"/>
                </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0 }}> 
            {dataReady ? <Grid container item xs='auto' direction='column' >
                <Box id="notificationsBox" height="calc(100vh - 128px - 60px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}} >
                {!hasNumber && <Link sx={{color: theme.palette.blue.main}} component={RouterLink} to={'/profile'}>{t("noPhoneNumber")}</Link>}
                    <Grid container item xs='auto' direction='column' alignItems='left'>
                    {accountList.length ? <List sx={{padding: theme.spacing(0) ,maxWidth: 450, }} > {accountList.map((account) => (
                                <AccountSettingsCard account={account} accountInfo={accountInfo} number={hasNumber} fetchAccounts={fetchAccounts}/> 
                            ))}  
                        </List>: <Typography align="center">{t('noAccounts')}</Typography>
                        // <Grid item xs container
                        //     direction="row"
                        //     justifyContent="center"
                        //     alignItems="center" >
                        //     <CircularProgress sx={{margin: theme.spacing(4)}} />
                        // </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>:<Grid item xs container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" >
                            <CircularProgress sx={{margin: theme.spacing(4)}} />
                        </Grid>
                    }
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
                {dataReady ? <Grid container item xs='auto' direction='column'>
                    <Box id="statusBox" height="calc(100vh - 128px - 60px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                    {!hasNumber && <Link sx={{color: theme.palette.blue.main}} component={RouterLink} to={'/profile'}>{t("noPhoneNumber")}</Link>}
                        <Grid container item xs='auto' direction='column' alignItems='left'>
                        {SRSettings.length ? <List sx={{padding: theme.spacing(0), maxWidth: 450, }} > {SRSettings.map((account) => (
                                    <StatusReportSettingsCard account={account} accountInfo={accountInfo} number={hasNumber} fetchAccounts={fetchAccounts}/>
                                ))}  
                            </List>: <Typography align="center">{t('noAccounts')}</Typography>
                        // <Grid item xs container
                        //     direction="row"
                        //     justifyContent="center"
                        //     alignItems="center" >
                        //     <CircularProgress sx={{margin: theme.spacing(4)}} />
                        // </Grid>
                        }
                        </Grid>
                    </Box>
                </Grid>:<Grid item xs container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" >
                            <CircularProgress sx={{margin: theme.spacing(4)}} />
                        </Grid>
                    }
            </TabPanel>
        </TabContext>
            
        </Grid>
    );
}