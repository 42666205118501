import React, { useRef, useState } from 'react';
import { CardElement, useRecurly } from '@recurly/react-recurly';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import useSessionstorage from '@rooks/use-sessionstorage';
import { billing } from '../../../services/billing';
import { countries, BillingCurrency, BillingPlans } from '../../../data';
import { isValidPhoneNumber } from '../../../helpers/isValidPhoneNumber';
import { isValidEmail } from '../../../helpers/isValidEmail';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';


const FormControlBillingStyled = styled(FormControl)(({ theme }) => ({
        minWidth: 120,
        marginBottom: theme.spacing(1),
        '& .MuiInputLabel-asterisk': {
            display: 'none',
        },
      }));


export const BillingForm = ({ handleHideEditClick, setBillingData, setVisibleEditBilling, setOrgBilling, isData, hasSubscription, username }) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const theme = useTheme();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [plan, setPlan] = useState(BillingPlans[0]);
    const [currency, setCurrency] = useState(BillingCurrency[0]);
    const [isValidForm, setIsValidForm] = useState(false);
    const [isThinking, setIsThinking] = useState(false);

    const { value: sessionstorageValue } = useSessionstorage('accountInfo', {});
    const recurly = useRecurly();
    const handleSetFirstName = ({ target }) => setFirstName(target.value);
    const handleSetLastName = ({ target }) => setLastName(target.value);
    const handleSetEmail = ({ target }) => setEmail(target.value);
    const handleSetAddress1 = ({ target }) => setAddress1(target.value);
    const handleSetAddress2 = ({ target }) => setAddress2(target.value);
    const handleSetCity = ({ target }) => setCity(target.value);
    const handleSetCountry = ({ target }) => {
        setCountry(target.value);
        setState('');
    };
    const handleSetState = ({ target }) => setState(target.value);
    const handleSetZip = ({ target }) => setZip(target.value);
    const handleSetPhone = ({ target }) => setPhone(target.value);

    const handleChangeCard = ({ valid }) => {
        if (valid) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    };

    const handlePlanSelect = (target) => {
        setPlan(target);
    };

    const handleCurrencySelect = (target) => {
        setCurrency(target);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const orgId = sessionstorageValue.accountId;

        if (phone && !isValidPhoneNumber(phone)) {
            return alert(t("youHaveEnteredAnInvalidPhone"));
        }

        if (email && !isValidEmail(email)) {
            return alert(t("youHaveEnteredAnInvalidEmail"));
        }

        setIsThinking(true);
        recurly.token(formRef.current, (err, token) => {
            if (err) {
                alert(err.message);
                return console.error(err);
            }
            (async () => {
                try {
                    let resp = null;
                    const plan_label = hasSubscription === true ? 0 : plan.label;
                    if (isData) {
                        resp = await billing.updateAccount({
                            orgId,
                            tokenId: token.id,
                            email,
                            currency,
                            plan_label,
                            username
                        });
                    } else {
                        console.log("Create account");
                        resp = await billing.createAccount({
                            orgId,
                            tokenId: token.id,
                            email,
                            currency,
                            plan_label,
                            username
                        });
                    }

                    const { error, billing_account, org_billing } = resp.data;

                    if (error !== 'OK' && resp.status !== 200) {
                        throw new Error(error);
                    }

                    setOrgBilling(org_billing);
                    setBillingData(billing_account);
                    setVisibleEditBilling(false);
                    setIsThinking(false);
                } catch (error) {
                    console.error(error);
                    if(error.message.includes("Your expiration date is invalid or does not match"))
                    {
                        alert(t("billingErrorExpirationDate"));
                    } else if(error.message.includes("The security code you entered does not match")){
                        alert(t("billingErrorSecurityCode"))
                    }else{
                        alert(error);
                    }
                    setIsThinking(false);
                }
            })();
        });
        
    };
    console.log(isThinking);
    return (
        <>
            <form onSubmit={handleSubmit} ref={formRef}>
                <CardElement
                    style={{
                        fontColor: theme.palette.primary.contrastText,
                        // '& .recurly-element-card': {
                        //     height: '45px'
                        // },
                        '& .recurly-element': {
                            // '& .recurly-element-card': {
                            //     height: '45px'
                            // },
                            marginBottom: theme.spacing(1),
                            borderBottom: `2px solid ${theme.palette.primary.constrastText}`,
                            transition: 'border 0.3s',
                            color: theme.palette.primary.contrastText
                        },
                        '& > .recurly-element-focus, .recurly-element:hover': {
                            borderBottomColor: theme.palette.primary.light,
                        },
                        '&.invalid .recurly-element': {
                            borderBottomColor: theme.palette.error.main,
                        }
                    }}
                    onChange={handleChangeCard}
                />
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    required
                    >
                    <InputLabel id="username">{t("accountName")}</InputLabel>
                    <Input
                        disabled
                        labelId="username"
                        inputProps={{
                            'data-recurly': 'username',
                        }}
                        value={username}
                    />
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    required
                    >
                    <InputLabel id="first-name">{t("firstName")}</InputLabel>
                    <Input
                        labelId="first-name"
                        inputProps={{
                            'data-recurly': 'first_name',
                        }}
                        value={firstName}
                        onChange={handleSetFirstName}
                    />
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    required
                    >
                    <InputLabel id="last-name">{t("lastName")}</InputLabel>
                    <Input
                        labelId="last-name"
                        inputProps={{
                            'data-recurly': 'last_name',
                        }}
                        value={lastName}
                        onChange={handleSetLastName}
                    />
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    error={email && !isValidEmail(email)}
                    required
                >
                    <InputLabel id="email">{t("email")}</InputLabel>
                    <Input
                        labelId="email"
                        value={email}
                        onChange={handleSetEmail}
                    />
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    required
                >
                    <InputLabel id="address1">{t("addressLine1")}</InputLabel>
                    <Input
                        labelId="address1"
                        inputProps={{
                            'data-recurly': 'address1',
                        }}
                        value={address1}
                        onChange={handleSetAddress1}
                    />
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                >
                    <InputLabel id="address2">{t("addressLine2")}</InputLabel>
                    <Input
                        labelId="address2"
                        inputProps={{
                            'data-recurly': 'address2',
                        }}
                        value={address2}
                        onChange={handleSetAddress2}
                    />
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    required
                >
                    <InputLabel id="city">{t("city")}</InputLabel>
                    <Input
                        labelId="city"
                        inputProps={{
                            'data-recurly': 'city',
                        }}
                        value={city}
                        onChange={handleSetCity}
                    />
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    error={false}
                    fullWidth={true}
                    required
                >
                    <InputLabel id="country">{t("country")}</InputLabel>
                    <Select
                        labelId="country"
                        label={t("country")}
                        id="country-select"
                        value={country}
                        onChange={handleSetCountry}>
                        {!!countries.length &&
                            countries.map(({ countryName, countryShortCode, translationKey }) => (
                                <MenuItem
                                    key={countryShortCode}
                                    value={countryName}>
                                    {t(translationKey)}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    required
                >
                    <InputLabel id="state">{t("stateProvinceRegion")}</InputLabel>
                    <Select label={t("stateProvinceRegion")} labelId="state" value={state} onChange={handleSetState}>
                        {!!countries.length &&
                            country &&
                            countries
                                .find(({ countryName }) => countryName === country)
                                .regions.map(({ name, shortCode, translationKey }) => (
                                    <MenuItem key={shortCode} value={name}>
                                        {t(translationKey)}
                                    </MenuItem>
                                ))}
                    </Select>
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    required
                >
                    <InputLabel id="zip">{t("zipCode")}</InputLabel>
                    <Input
                        labelId="zip"
                        inputProps={{
                            'data-recurly': 'postal_code',
                        }}
                        value={zip}
                        onChange={handleSetZip}
                    />
                </FormControlBillingStyled>
                <FormControlBillingStyled
                    size="small"
                    fullWidth={true}
                    error={(phone.length > 0) && !isValidPhoneNumber(phone)}
                    required
                >
                    <InputLabel id="phone">{t("billingPhone")}</InputLabel>
                    <Input
                        labelId="phone"
                        inputProps={{
                            'data-recurly': 'phone',
                        }}
                        value={phone}
                        onChange={handleSetPhone}
                    />
                </FormControlBillingStyled>
                <input type="hidden" data-recurly="country" value={country} />
                <input type="hidden" data-recurly="state" value={state} />
                <Box 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        //paddingBottom: theme.spacing(2)
                    }}
                >
                    {!hasSubscription &&
                        <TextField select
                            sx={{marginBottom: theme.spacing(1)}}
                            label={t("preferredCurrency")}
                            value={currency}
                            onChange={e => {
                                for (var i = 0; i < BillingCurrency.length; i++) {
                                    if (e.target.value === BillingCurrency[i]) {
                                        handleCurrencySelect(BillingCurrency[i]);
                                        break;
                                    }
                                }
                            }}>
                            {BillingCurrency.map(billingCurrency => <MenuItem key={billingCurrency} value={billingCurrency}>
                                {billingCurrency}
                            </MenuItem>)}
                        </TextField>}
                    {!hasSubscription &&
                        <TextField select
                            label={t("billingSubscription")}
                            value={plan.label}
                            onChange={e => {
                                for (var i = 0; i < BillingPlans.length; i++) {
                                    if (e.target.value === BillingPlans[i].label) {
                                        handlePlanSelect(BillingPlans[i]);
                                        break;
                                    }
                                }
                            }}>
                            {BillingPlans.map(billingPlan => <MenuItem key={billingPlan.label} value={billingPlan.label}>
                                {t(billingPlan.label)}
                            </MenuItem>)}
                        </TextField>}
                    {hasSubscription && <Typography>{t("pulseTraceSetSubAndCurrencyNote")}</Typography>}
                </Box>
                <Box 
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1),
                        '& button:first-child': {
                            marginLeft: theme.spacing(2),
                        },
                    }}
                >
                    <Button
                        sx={{fontSize: '1.1rem',}}
                        disabled={!isValidForm}
                        type="submit"
                        color="secondary"
                        variant="contained"
                    >
                        {isThinking ? <CircularProgress/> : t("save")}
                    </Button>
                    <Button
                        sx={{fontSize: '1.1rem',}}
                        color="primary"
                        onClick={handleHideEditClick}>
                        {t("cancel")}
                    </Button>
                </Box>
            </form>
        </>
    );
};