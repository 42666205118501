/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';
import {
  Box,
  CircularProgress,
  IconButton,
  Grid,
  List,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';

import { useTheme } from '@emotion/react';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
//Graco Components
import { groups_inf } from '../../services/groups_inf';
import GroupStatusCard from './GroupStatusCard';
import AddGroup from './AddGroup';

// Group Page
export default function GroupList({accountInfo}) {
  const { t } = useTranslation();
  const theme=useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [dataReady, setDataReady] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [update, setUpdate] = useState(false); //SAVE - used to trigger state updates
  
  const fetchGroups = async (abortController={signal: undefined}) => {
    try {
        const { status, error, accountGroups, accountUsers, accountDevices, groupInfo } = await groups_inf.getGroups(
            accountInfo.accountId,
            abortController
        );
        
        if (status === 404) {
            return;
        }

        if (error !== 'OK' && status !== 200) {
          if(error.includes("Unable to connect to database")){
            alert(t("failedToConnectToDatabase"))            
          }
          else if(error.includes("Access Denied, no permissions to modify")){
            alert(t("accessDeniedNoPermissions"));
          }
          //alert(t(error));
          else {
            alert(t("sorryUnableToRetrieve"));
            throw new Error(error);
          }
          //alert(t(error));
          throw new Error(error);
        }
        else{
          console.log('Group Fetched');
          filterData(accountGroups, accountDevices);
        }

    } catch (error) {
        console.log('Failed to Fetch group')
        console.error(error);
        setTimeout(() => {setDataReady(true)}, 2000);
    }
    setUpdate(false);
  };


  const deleteGroup = async (groupId) => {
    //Need to make backend delete function
    try{
        const { status, error, accountGroups, accountUsers, accountDevices } = await groups_inf.deleteGroup(
            accountInfo.accountId, groupId
        );
        console.log(error);
        if (status === 404) {
            return;
        }
        if (error !== 'OK' && status === 200) {
          if(error.includes("Unable to connect to database")){
            alert(t("failedToConnectToDatabase"));
          }
          else if(error.includes("Access Denied, no permissions to modify")){
            alert(t("accessDeniedNoPermissions"));
          }
          else if(error.includes("groupNotEmpty")){
            alert(t("groupNotEmpty"));
          }
            // alert(t(error));
        else {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
        }
          //alert(t(error));
        throw new Error(error);
        }
        console.log('done deleting group');

        }catch (error) {
            console.error(error);
        }
    setDataReady(false);
    setUpdate(true);
    fetchGroups();
  };

  function filterData(myGroupInfo, accountDevices){
    let data = [];
    let temp;
    //{id, name, num_devices, num_users}

    for (const [key, value] of Object.entries(myGroupInfo)) {
      temp = {"name": null, "id": null, "Devices": [], "Users": []}
      temp.name=key;
      temp.id = value.id
      temp.Devices = value.devices;
      temp.Users = value.users;
      data.push(temp);
    }
    

    setDisplayData(data);
    setDataReady(true);
  };


  // Hook to Fetch Groups when accountInfo changes and page load
  useEffect(() => {
    const abortController = new AbortController();
    fetchGroups(abortController);
    return () => abortController.abort();
  },[accountInfo]);

  //Header consumes 64px of viewable area
  return (
    <>
      <Grid height='64px' item xs>
        <Toolbar>            
        <Tooltip title={t("goHome")}>
            <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                <ArrowBack />
            </IconButton>
        </Tooltip>
          <Typography variant='h5'
              color='textPrimary'>
              {t("groups")}
          </Typography>
          <Box flexGrow={1}/>
          {isFullScreen && <AddGroup accountInfo={accountInfo} fetchGroups={fetchGroups} isFAB={false}/>}
        </Toolbar>
      </Grid>
      {dataReady ? <Grid container item direction='column'>      
          <Box id="groupBox" height="calc(100vh - 128px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                    {displayData.length ? <List sx={{padding: theme.spacing(0), maxWidth: 450, }} > {displayData.map((dev) => (
                              <GroupStatusCard groupStatus={dev} deleteGroup={deleteGroup} accountInfo={accountInfo}/> 
                          ))} 
                        </List>: <Typography align="center">{t('noGroups')}</Typography>
                }
                  {!isFullScreen && <AddGroup accountInfo={accountInfo} fetchGroups={fetchGroups}/>}
                
          </Box>
        </Grid>
        : <Grid item xs container
                direction="row"
                justifyContent="center"
                alignItems="center" >
            <CircularProgress sx={{margin: theme.spacing(4)}}/>
          </Grid>
      }
    </>
  )
}
