import React, { useState } from 'react';

import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilterListIcon from '@mui/icons-material/FilterList';

import DataEntryListItemHeader from '../../CommonComponents/DataEntryListItemHeader';

const DeviceFilter = ({ groups, filtGroups, filtStatus, updateFilter=()=>{}}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [openFilterDialogue, setOpenFilterDialogue] = useState(false);
    const [typeChecked, setTypeChecked] = React.useState([]);
    const [groupList, setGroupList] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const statusTypes = [
        {key: 0, displayName: 'statusTypeOK', name: 'OK'},
        {key: 1, displayName: 'statusTypeAlarm', name: 'alarm'}, 
        {key: 2, displayName: 'statusTypeAlert', name: 'alert'},
        {key: 3, displayName: 'statusTypeDisconnected', name: 'disconnected'},
    ];

    const handle_openFilter = () => {
        var l_groupList = []
        Object.keys(groups).forEach((g) => {
            l_groupList.push({name: g, id: groups[g].group_id, key:groups[g].group_id.toString() })
        })
        setGroupList(l_groupList);
        setSelectedGroups(filtGroups);
        setOpenFilterDialogue(true);
        var preChecked = [];
        filtStatus.forEach((entry)=> {
            statusTypes.forEach((stype) => {
                if (entry === stype.name){
                    preChecked.push(stype.key);
                }
            })
        })
        setTypeChecked(preChecked);
    };

    const handle_closeFilter = () => {
        setOpenFilterDialogue(false);
    };

    const handleFilter = () => {
        var newFilter = {status: [], groups: []};
        statusTypes.forEach(etype => {
            if (typeChecked.includes(etype.key)){
                newFilter.status.push(etype.name);
            }
        })
        selectedGroups.forEach(gtype => {
            newFilter.groups.push(gtype);
        })
        updateFilter(newFilter);
        handle_closeFilter();
    };

    const handleTypeToggle = (value) => () => {
        const currentIndex = typeChecked.indexOf(value);
        const newChecked = [...typeChecked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setTypeChecked(newChecked);
      };
    
      const handleGroupSelect = (event) => {
        const { target: { value }, } = event;
        setSelectedGroups(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
      
    return(
    <>
        <Tooltip title={t("filterButton")}>
            {/* <IconButton sx={{color: theme.palette.secondary.light}} onClick={handle_openFilter}> */}
            <IconButton onClick={handle_openFilter}>
                <FilterListIcon />
                <Typography>{t("filterButton")}</Typography>
            </IconButton>
        </Tooltip>
        {openFilterDialogue && <Dialog
            fullWidth={false}
            maxWidth={'xs'}
            disableEscapeKeyDown
            open={openFilterDialogue}
            fullScreen={fullScreen}
            onClose={handle_closeFilter}>
            
            <DialogTitle>{t("deviceFilter")}</DialogTitle>
            <DialogContent >
            <Grid container item xs 
                    justifycontent='flex-start'
                    alignItems='center'
                    direction='column'>
                    <Grid item xs>
                        <FormControl sx={{
                                margin: theme.spacing(1),
                                width: 350,
                                marginBottom: 20
                            }}>
                            <Stack 
                                sx={{ m: 1, width: 300 }}
                                spacing={2} 
                                justifyContent="center"
                                alignItems="center">
                                <DataEntryListItemHeader label={"groupsFilter"}/>
                                <Select
                                    sx={{width:300}}
                                    labelId="groups-multiple-checkbox-label"
                                    id="groups-multiple-checkbox"
                                    multiple
                                    value={selectedGroups}
                                    onChange={handleGroupSelect}
                                    renderValue={(selected) => selected.length > 2 ? t('multipleGroupsSelected') : selected.join(', ')}
                                    >
                                    {groupList.map((grp) => (
                                        <MenuItem key={grp.key} value={grp.name}>
                                            <Checkbox checked={selectedGroups.indexOf(grp.name) > -1} />
                                            <ListItemText primary={grp.name === "default" ? t('default'):grp.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <DataEntryListItemHeader label={"statusFilterTypes"}/>
                                {statusTypes.map((value) => {
                                    const labelId = `checkbox-list-secondary-label-${value.key}`;
                                    return (
                                    <ListItem
                                        key={value.key}
                                        secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={handleTypeToggle(value.key)}
                                            checked={typeChecked.indexOf(value.key) !== -1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemText id={labelId} primary={t(value.displayName)} />
                                        </ListItemButton>
                                    </ListItem>
                                    );
                                })}
                            </Stack>
                        </FormControl>
                    </Grid>
                    {fullScreen && <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth autoFocus variant='contained' color='secondary' onClick={handleFilter} >
                            {t('filter')}
                        </Button>
                    </Grid>}
                    {fullScreen && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth 
                            autoFocus
                            color='inherit'
                            onClick={handle_closeFilter}
                        >
                            {t('cancel')}
                        </Button>
                    </Grid>}
                </Grid>
            </DialogContent>
            {!fullScreen && <DialogActions>
                <Button onClick={handle_closeFilter} color="primary">
                    {t("cancel")}
                </Button>
                <Button
                    variant="contained" onClick={handleFilter} color="secondary">
                    {t("filter")}
                </Button>
            </DialogActions>}
        </Dialog>}
    </>
    );
};

export default DeviceFilter;