import React from 'react';
import {SvgIcon, styled} from '@mui/material';

const LubeOnIcon = (props) => {
    return(
        <SvgIcon {...props}>
            <svg fill="currentColor" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m2.46,14.35c.01-2.46.76-4.03,2.17-5.42l5.22-5.33,5.22,5.4c1.4,1.38,2.16,2.89,2.17,5.36H2.46Zm14.36-7.39L9.85,0,2.88,6.96c-1.92,1.92-2.88,4.48-2.88,6.95s.96,5.06,2.88,6.98c1.92,1.92,4.45,2.89,6.97,2.89s5.05-.97,6.97-2.89c1.92-1.92,2.88-4.52,2.88-6.98s-.96-5.03-2.88-6.95Z"/></svg>
        </SvgIcon>
    )
}

export default LubeOnIcon;