import {useDev} from "../data";

export const DEVICE_TYPES = useDev ? {
    //0:{"name": "Unknown"},
    1:{"name": "G3 TEST"},
    2:{"name": "G3 MAX"},
    //3:{"name": "G3 MAX FT"} Commented out for now until HW config defined
}
: {
    2:{"name": "G3 MAX"},
};

export const CONFIG_TYPES ={
    1:{"name": "G3", "DEVICE_TYPES":[1,2,3]}
};

export const low_level_source_list = [
    {key: 1,id: 1, name: 'low level paddle', displayLabel: 'lowLevelPaddle'},
    {key: 2,id: 2, name: 'low level sensor', displayLabel: 'lowLevelSensor'},
    {key: 3,id: 3, name: 'low level switch', displayLabel: 'lowLevelSwitch'},
];

export const lubrication_low_level_analog_types = [
    {key: 1, id: 1, name: "analog_0V_5V", displayLabel: "0V - 5V"},
    {key: 2, id: 2, name: "analog_0V_10V", displayLabel: "0V - 10V"},
    {key: 6, id: 6, name: "analog_1V_5V", displayLabel: "1V - 5V"},
    {key: 7, id: 7, name: "analog_0d5V_4d5V", displayLabel: "0.5V - 4.5V"},
];

export const low_level_switch_action_list = [
    {key: 0, id: 0, name: "alarm", displayLabel: 'lowLevelSwitchActionAlarm'},
    {key: 1, id: 1, name: "alert", displayLabel: 'lowLevelSwitchActionAlert'},
];