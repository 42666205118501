import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LubeOffIcon = (props) => {
    return(
        <SvgIcon {...props}>
            <svg fill="currentColor" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="m7.09,21.67l7.23-7.23h5.73c0-1.91-.48-3.23-1.36-4.37l1.82-1.82c1.29,1.71,1.94,3.77,1.94,5.76,0,2.39-.93,4.92-2.8,6.79-1.87,1.87-4.32,2.81-6.78,2.81-2.04,0-4.08-.65-5.78-1.94Z"/><path d="m4.12,17.9c-.55-1.25-.82-2.59-.82-3.89,0-2.39.93-4.89,2.8-6.75L12.87.49l4.33,4.32L22.01,0l1.69,1.69L1.69,23.71l-1.69-1.69,4.12-4.12Z"/></g><path d="m7.52,14.47l7.93-7.93-2.57-2.65-5.14,5.25c-1.38,1.37-2.12,2.91-2.13,5.33h1.91Z"/></svg>
        </SvgIcon>
    );
}

export default LubeOffIcon;