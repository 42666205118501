import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';

//import WiFiActivationCodeImg from '../../../OrgPage/OrgBilling/Components/Assets/WiFi_ActivationCode_ti40040.png';
import { useTranslation } from 'react-i18next';
import { groups_inf } from '../../services/groups_inf';

const AddDeviceToGroup = ({ accountInfo, group, fetchGroup=()=>{}, isFAB=true }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [devices, setDevices] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [curDev, setCurDev] = useState([]);
    const [groupID, setGroupID] = useState();
    const [groupName, setGroupName] = useState();
    const [openEnterName, setOpenEnterName] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const rgroupName = useRef();

    useEffect(() => {
        if(openEnterName){
            let people_list = []
            let temp_obj = {}
        }
        filterGroup(group);
        const abortController = new AbortController();
        //fetchDevs(abortController);
        return () => abortController.abort();
      },[openEnterName, group]);

    function filterGroup(groupData){
        setCurDev(groupData.Devices);
        setGroupID(groupData.id);
        setGroupName(groupData.name);

    };

    const handleOpenEnterName = () => {
    setOpenEnterName(true);
    fetchDevs();
    };

    const handleCloseEnterName = () => {

        setOpenEnterName(false);
        setDevices([]);
        setDeviceList([]);
    };

    const fetchDevs = async (abortController={signal: undefined}) => {
        try {
            const { status, error, accountGroups, accountDevices } = await groups_inf.getGroups(
                accountInfo.accountId,
                abortController
            );
    
            if (status === 404) {
                return;
            }
    
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"))                
                }
                else if(error.includes("Access Denied, no permissions to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
            }
            else{
                console.log('Group Fetched');
                var data = [];
                for (let i = 0; i < accountDevices.length; i++)
                {
                    data.push(accountDevices[i]);
                    for (let j = 0; j < curDev.length; j++)
                    {
                        if (curDev[j].uid === accountDevices[i].uid)
                        {
                            data.pop();
                            break;
                        }
                    }
                }
            }
          
            setDeviceList(data);
        }catch (error) {
            console.log('Failed to Fetch group')
            console.error(error);
        }
      };

      const handleSelectDevice = (event) => {
        setDevices(event.target.value)
      };

      const updateGroup = async () => {
        let AddDeviceList = [];
        for (let i=0; i<devices.length; i++){
            AddDeviceList.push(devices[i]);
        }
        
        try{
            const { status, error } = await groups_inf.updateGroup(
                accountInfo.accountId, groupID, groupName, [], AddDeviceList, []
            );
            if (status === 404) {
                return;
            }
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"))                
                }
                else if(error.includes("Access Denied, no permissions to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else if(error.includes("Group name alreadery exists")){
                    alert(t("groupNameAlreadyExists"))                
                }
                //alert(t(error));
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
            }
            console.log('done updating group'); 
        }catch (error) {
            console.error(error);
        } 
        
        fetchGroup();
        handleCloseEnterName();  
    };

    return <>
      {accountInfo.isAccountEditor === 1 && (isFAB ?
          <Fab sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2)
          }} 
          color="secondary" aria-label="addD" onClick={handleOpenEnterName}>
              <AddIcon />
          </Fab> 
          : 
          <Tooltip title={t("addDevice")}>
            <IconButton onClick={handleOpenEnterName}>
              <AddIcon/>
              <Typography sx={{marginLeft: '5px'}}>{t("device")}</Typography>
            </IconButton>
          </Tooltip>
          

          )}
        {openEnterName && <Dialog
            disableEscapeKeyDown
            open={openEnterName}
            onClose={handleCloseEnterName}
            fullScreen={fullScreen}>
            
            <DialogTitle>{t("selectDevicesForGroup")}</DialogTitle>
            <DialogContent >
                <Grid container item xs 
                    justifycontent='flex-start'
                    alignItems='center'
                    direction='column'>
                        <Grid item xs>
                        <FormControl sx={{
                                margin: theme.spacing(1),
                                minWidth: 300,
                                width: 300,
                                marginBottom: 20
                            }}>
                            <Stack spacing={2}>
                                <InputLabel id="select Device">{t("device")}</InputLabel>
                                <Select
                                    value={devices ? devices: " "}
                                    renderValue={(selected) => selected.join(', ')}
                                    multiple
                                    onChange={handleSelectDevice}
                                    >
                                    {deviceList.map(dev => {
                                        return(
                                            <MenuItem value={dev.uid}>
                                                <Checkbox checked={devices.indexOf(dev.uid) > -1}/>
                                                <ListItemText>{dev.friendly_name ? dev.friendly_name : dev.uid}</ListItemText>
                                            </MenuItem> 
                                        );  
                                    })} 
                                </Select>
                            </Stack>
                        </FormControl>
                        </Grid>
                        {true && <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                            <Button fullWidth autoFocus variant='contained' color='secondary' onClick={updateGroup} >
                                {t('update')}
                            </Button>
                        </Grid>}
                        {true && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                            <Button fullWidth 
                                autoFocus
                                color='inherit'
                                onClick={handleCloseEnterName}
                            >
                                {t('cancel')}
                            </Button>
                        </Grid>}
                    </Grid>
                {false && <DialogActions>
                    <Button onClick={handleCloseEnterName} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button variant="contained" onClick={updateGroup} color="secondary">
                            {t("update")}
                    </Button>
                </DialogActions>}
            </DialogContent>

        </Dialog>
        
        }

        </>
    };
export default AddDeviceToGroup;