/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import {
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import TroubleShootIcon from '@mui/icons-material/Troubleshoot';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';


const DetailsActionButtons = ({connection, device_state, disableButton=false, 
                                manualRunAction=()=>{}, handleDiagnostics=()=>{}, handleConfiguration=()=>{}, hideClearAlarms=false}) => {
    const { t } = useTranslation();
    const theme=useTheme();
    
    const handleManualRun = () => {
        if (device_state.isAlarm){
            //Clear alarms
            manualRunAction("clear_alarms");
        }
        else if (device_state.state.includes('idle')){
            //Run
            manualRunAction("start");
        }
        else {
            //Stop
            manualRunAction("stop");
        }
    };
    
    return(
        <Grid id={'details-action-buttons'} item xs
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
        >       
            <Grid item xs
                container 
                direction='column' 
                justifyContent="center"
                alignItems="center"
                sx={{
                    
                }}>
                <IconButton onClick={handleDiagnostics} size='large'
                    sx={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                        backgroundColor: theme.palette.grey.dark
                }}>
                    <TroubleShootIcon color="success" fontSize="inherit"/>
            
                </IconButton>
                <Typography variant="body2">
                    {t('diagnostics')}
                </Typography>
            </Grid>
            {false && <Grid item xs
                container 
                direction='column' 
                justifyContent="center"
                alignItems="center"
                sx={{
                    
                }}>
                <IconButton onClick={handleManualRun} size='large'
                    disabled={disableButton ? true : (connection > 0 ? false : true) }
                    sx={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                        backgroundColor: theme.palette.grey.dark,
                }}>
                    {device_state ? (device_state.state.includes('idle') ? <AlarmOnIcon color="warning" fontSize="inherit"/> 
                        : <AlarmOffIcon color="error" fontSize="inherit"/>)
                        : <AlarmOffIcon color="error" fontSize="inherit"/>}
                </IconButton>
                <Typography variant="body2">
                    {device_state ? device_state.isAlarm ? t('detailsClearAlarm') : (device_state.state.includes('idle') ? t('detailsManualRun') 
                        : t('detailsStopLubricating'))
                        : t('detailsManualRun')}
                </Typography>
                
            </Grid>}
            {(device_state.isAlarm === 1 && !hideClearAlarms) ? <Grid item xs
                container 
                direction='column' 
                justifyContent="center"
                alignItems="center"
                sx={{
                    
                }}>
                <IconButton onClick={handleManualRun} size='large'
                    disabled={disableButton ? true : (connection > 0 ? false : true) }
                    sx={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                        backgroundColor: theme.palette.grey.dark,
                }}>
                    {<AlarmOffIcon color="error" fontSize="inherit"/>}
                </IconButton>
                <Typography variant="body2">
                    {t('detailsClearAlarm')}
                </Typography>
                
            </Grid> 
            : <></>}
            <Grid item xs
                container 
                direction='column' 
                justifyContent="center"
                alignItems="center"
                sx={{
                    
                }}>
                <IconButton onClick={handleConfiguration} size='large'
                    sx={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                        backgroundColor: theme.palette.grey.dark
                }}>
                    <SettingsApplicationsIcon color="warning" fontSize="inherit"/>
            
                </IconButton>
                <Typography variant="body2">
                    {t('deviceConfiguration')}
                </Typography>
            </Grid>
        </Grid>);
}

export default DetailsActionButtons;