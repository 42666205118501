export function timeToTimeString (hrs, min, sec, hour_digits=4) {
    const hours = hrs.toString().padStart(hour_digits,"0");
    const minutes = min.toString().padStart(2,"0");
    const seconds = sec.toString().padStart(2,"0")

    if (hour_digits === 0){
      return minutes+":"+seconds;
    }
    else{
      return hours+":"+minutes+":"+seconds;
    }
  }