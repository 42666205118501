/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useParams
} from 'react-router-dom'
import { Auth } from 'aws-amplify';

import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';
import useSessionstorage from '@rooks/use-sessionstorage';

import {
  Grid,
} from '@mui/material';


// Graco dependencies
import Header from './Header/Header.jsx';
import HelpPage from './HelpPage/HelpPage.jsx';
import AccountCreate from './AccountPage/AccountCreate/AccountCreate';
import NoAccountPage from './AccountPage/NoAccountPage/NoAccountPage';
import ProfilePage from './ProfilePage/ProfilePage.jsx';
import AccountPage from './AccountPage/AccountPage.jsx';
import GroupPage from './GroupPage/GroupPage.jsx';
import DevicePage from './MainPage/DevicePage/DevicePage.jsx';
import NotificationsPage from './NotificationsPage/NotificationsPage.jsx';
import ConfigPage from './ConfigPage/ConfigPage.jsx';
import { GrowDivMax35 } from './styledComponentsCommon.js';
import TermsOfService from './CommonComponents/TermsOfService.js';

import { changeLanguage } from './helpers/changeLanguage.js';

import MainPage from './MainPage/MainPage.jsx';
import SessionTimeout from './SessionTimeout/SessionTimeout.js';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { accounts } from './services/accounts';
import { users_inf } from './services/users_inf.js';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';

//KEEP RootDiv this sets the vertical window in the background by some means
const RootDiv = styled.div(props => ({
  height: '100vh', display: 'block'
}))

const ContentDiv = styled.div(props => ({
  minHeight: 'calc(100vh - 64px)'
}));



// Page router directs the app to the correct page and defines path parameters.
const PageRouter = ({ accountInfo, fetchAccountInformation, changeLanguage }) => {
  return (
    <Routes>
      <Route path='devices/*' element={<DevicePage accountInfo={accountInfo}/>} />
      <Route path='configurations/*' element={<ConfigPage accountInfo={accountInfo}/>}/>
      <Route path='groups/*' element={<GroupPage accountInfo={accountInfo}/>} />
      <Route path='notifications/*' element={<NotificationsPage accountInfo={accountInfo}/>}/>
      <Route exact path='accounts/new' element={<AccountCreate firstAccount={accountInfo.AccountId === "" ? true : false} accountEdit={false} 
                                                        fetchAccountInformation={fetchAccountInformation} />} />
      <Route path='accounts/*' element={<AccountPage accountInfo={accountInfo} fetchAccountInformation={fetchAccountInformation}/>} />
      <Route path='profile/*' element={<ProfilePage accountInfo={accountInfo} changeLanguage={changeLanguage} />} />
      <Route path='help/*' element={<HelpPage changeLanguage={changeLanguage}/>}  />
      <Route path='*' element={accountInfo.accountName ?  <MainPage accountInfo={accountInfo}/>  : <NoAccountPage/>} />
    </Routes>
  );
}

// The main App. Loaded by index.js, the whole app trees from here.
export default function  HomePage() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const params = useParams();
  const cookies = new Cookies();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { value: lastSessionStorageAccountInfo, set: setSessionstorageAccountInfo } = useSessionstorage("accountInfo", {});
  const { value: userPreferredUnits, set: setUserPreferredUnits } = useSessionstorage("preferredUnits", "uscs");
  // Create state for the current account and account list.
  const [account, setAccount] = React.useState({
    accountName: '',
    isAccountEditor: 1,
    isAccountTech: 1,
    isAccountOwner: 1,
    accountId: 0
  });
  const [accountsList, setAccountsList] = React.useState([]);
  const [accountsNavDict, setAccountsNavDict] = React.useState({});
  const [accountsFetched, setAccountsfetched] = React.useState(false);
  const authTimeoutMinutes = 30; //auto sign out timeout
  const authWarningMinutes = 5; //Minutes remaining that popup occurs.

  const [userInfoFetched, setUserInfoFetched] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
    

  // Handle account change event. Passed to the nav menu in Header.
  const handleAccountChange = (event) => {
    setAccount({
      accountName: event,
      isAccountEditor: accountsNavDict[event][1],
      isAccountOwner: accountsNavDict[event][2],
      isAccountTech: accountsNavDict[event][3],
      accountId: accountsNavDict[event][0]
    });
    let url = '/';
    navigation(url);
  };
  
  // Get the account info. Runs once when the component mounts.
  const fetchAccountInformation = async (targetAccount, navigate, navUrl, abortController) => {
    try {

      console.log("Fetching accounts...")

      var { status, error, userAccounts } = await accounts.getAccounts(abortController);
      
      if (error !== 'OK' && (status !== 200 || status !== 404)) {
        if (error.includes("unable to connect to database")){
          alert(t("failedToConnectToDatabase"));
        }
        else {
            alert(t("sorryUnableToRetrieve"));
            throw new Error(error);
        }
        //alert(t(error));
        throw new Error(error);
      }
      if(userAccounts.default.accountName.trim() === "" && userAccounts.accountsList.length === 0) {

      }
      else{
        setAccountsList(userAccounts.accountsList);
        setAccountsNavDict(userAccounts.accountsNavDict);

        if (targetAccount === "default") {
          setAccount({
            accountName: userAccounts.default.accountName,
            isAccountEditor: userAccounts.default.isAccountEditor,
            isAccountTech: userAccounts.default.isAccountTech,
            isAccountOwner: userAccounts.default.isAccountOwner,
            accountId: userAccounts.default.accountId
          });
          let url = "/";
          navigation(url);
        }
        else if (targetAccount !== undefined && userAccounts.accountsNavDict[targetAccount] !== undefined){
          setAccount({
              accountName: targetAccount,
              isAccountOwner: userAccounts.accountsNavDict[targetAccount][2],
              isAccountEditor: userAccounts.accountsNavDict[targetAccount][1],
              isAccountTech: userAccounts.accountsNavDict[targetAccount][3],
              accountId: userAccounts.accountsNavDict[targetAccount][0]
            });

            if (navigate === true || navigate === undefined) {
              let url = "/";
              if (navUrl !== undefined) {
                url = navUrl;
              }
              navigation(url);
          }
        }
        else if (params.accountName !== undefined &&
          params.accountName !== userAccounts.default.accountName &&
          userAccounts.accountsNavDict[params.accountName] !== undefined) {
          // Todo: Redirect to a "page does not exist or no access page" on an improper accountName in the url.
          setAccount({
            accountName: params.accountName,
            isAccountEditor: userAccounts.accountsNavDict[params.accountName][1],
            isAccountOwner : userAccounts.accountsNavDict[params.accountName][2],
            isAccountTech : userAccounts.accountsNavDict[params.accountName][3],
            accountId: userAccounts.accountsNavDict[params.accountName][0]
          });
        }
        else {
          if (userAccounts.default.accountId === "") {
            var lAccountName = userAccounts.accountList[0];
            setAccount({
              accountName: lAccountName,
              isAccountEditor: userAccounts.accountsNavDict[lAccountName][1],
              isAccountOwner: userAccounts.accountsNavDict[lAccountName][2],
              isAccountTech: userAccounts.accountsNavDict[lAccountName][3],
              accountId: userAccounts.accountsNavDict[lAccountName][0]
            });
          }
          else {
            try{
              let lAccountName = lastSessionStorageAccountInfo.accountName;
              if(!userAccounts.accountsList.includes(lAccountName)){
                throw("invalidAccountStored");
              }
              if(lastSessionStorageAccountInfo.accountId){
                setAccount({
                  accountName: lAccountName,
                  isAccountEditor: userAccounts.accountsNavDict[lAccountName][1],
                  isAccountOwner: userAccounts.accountsNavDict[lAccountName][2],
                  isAccountTech: userAccounts.accountsNavDict[lAccountName][3],
                  accountId: userAccounts.accountsNavDict[lAccountName][0]
                })
              }
            } catch(error){

              console.log("Use Default Account Info")
              setAccount({
                accountName: userAccounts.default.accountName,
                isAccountEditor: userAccounts.default.isAccountEditor,
                isAccountOwner: userAccounts.default.isAccountOwner,
                isAccountTech: userAccounts.default.isAccountTech,
                accountId: userAccounts.default.accountId
              });
            }
          }
        }
      }
      setAccountsfetched(true);
      console.log("Accounts fetched.")     
    } catch (e) {
      console.log(e);
      //setAccountsfetched(true);
    }
  };

  
  const fetchUserLanguageSetting = async (abortController) => {
    try {
      let user = await Auth.currentUserInfo();
      const{status, error, userSettings} = await users_inf.getUserData(
          user.attributes.email,
          abortController
      );
      if (status === 404) {
          return;
      }

      if (error !== 'OK' && status !== 200) {
          if(error.includes("Unable to connect to database")){
              alert(t("failedToConnectToDatabase"));
          }
          //alert(t(error));
          throw new Error(error);
      }
      if (userSettings.language){
        changeLanguage(userSettings.language);
      } else{
        const browserLanguage = navigator.language || navigator.userLanguage;
        if (browserLanguage.includes("es")){
          changeLanguage("es");
        } else if (browserLanguage.includes("fr")){
          changeLanguage("fr");
        } else if (browserLanguage.includes("de")){
          changeLanguage("de");
        } else {
          changeLanguage("en");
        }
      }
      if (userSettings.terms_accepted === '1'){
        setTermsAccepted(true);
      }
      if (userSettings.preferred_units === 'metric'){
        setUserPreferredUnits(userSettings.preferred_units);
      }
      setTimeout(() => {setUserInfoFetched(true)}, 500);
    }
    catch (error) {
      console.log(error.message);
    }
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    fetchUserLanguageSetting(abortController);
    fetchAccountInformation(undefined,undefined,undefined,abortController);
    
    return () => abortController.abort();
  }, []);

  React.useEffect(() => {
    setSessionstorageAccountInfo(account);    
  }, [account])

  //DISABLE MOUSE WHEEL FROM EFFECTING NUMBER TYPE
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
  
  // Setup the header and space for the page content through page router.
  return (
          <Grid container 
            direction="row"
            >
              {fullScreen && <GrowDivMax35/>}
              <Grid item xs
                sx={{
                  maxWidth: 1000
                }}>
                <Header accountInfo={account} accounts={accountsList} accountNavigationHandler={(event) => handleAccountChange(event)}></Header>
                <ContentDiv>
                  {(userInfoFetched && accountsFetched) ?
                    (termsAccepted) ? <PageRouter accountInfo={account} fetchAccountInformation={fetchAccountInformation} changeLanguage={changeLanguage} /> 
                      : <TermsOfService openTerms={true} handleCloseTerms={()=>{}} reviewOnly={false}/>
                    :<CircularProgress sx={{margin: theme.spacing(4)}}/>
                  }
                </ContentDiv>
                {true && <div>
                  <SessionTimeout signedIn={true} maxTimeoutMinutes={authTimeoutMinutes} warningTimeoutMinutes={authWarningMinutes} />
                </div>}
              </Grid>
              {fullScreen && <GrowDivMax35/>}
          </Grid>
        
  );
}

