import React, { useState, useRef } from 'react';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import {useTheme} from '@emotion/react';
import { Typography, TextField, IconButton } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { secondsToTime } from '../helpers/secondsToTime';
import { timeToSeconds } from '../helpers/timeToSeconds';
import { timeToTimeString } from '../helpers/timeToTimeString';;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const DataEntryDialogueNumber = ({ title, param_id, value, instructions, dialogueOpen, handleSave, handleCancel, 
                                    maxDigits=5, valueRange=[1, 10000], fieldLabel="value" }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [localNumber, setLocalNumber] = useState(value === undefined ? "1000" : value);
  const [open, setOpen] = useState(dialogueOpen ? true : false);
  const [inError, setInError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const handle = () => {
    setOpen(false);  
    let key = param_id;
    if (key === undefined){
        key = title;
    }
    let value = parseInt(localNumber, 10).toString();
    handleSave(key, value);
  };
  const cancel = () => {
    setOpen(false);
    handleCancel();
  };
  React.useEffect(() => { 
    setOpen(dialogueOpen);
  }, [dialogueOpen]);

  

  const handleErrorCheckUpdate = (newValue, type ) => {
        let localNewValue = newValue;
        let inError = false;
        let l_errorText = "";
        
        if (localNewValue.length > maxDigits){
            inError = true;
        }
        if (!inError){
            setLocalNumber(localNewValue);
        }
        let inError2 = false;
        if (!inError){
            if (localNewValue.length < 1){
                inError2 = true;
            }
            const l_errorText = t("valueErrorCheck2Params").replace('{value1}',valueRange[0]).replace('{value2}', valueRange[1]);
            if (parseInt(localNewValue) > valueRange[1]){
                inError2 = true;
                setErrorText(l_errorText);
            }
            else if (parseInt(localNewValue) < valueRange[0]){
                inError2 = true;
                setErrorText(l_errorText);
            }
            
            setInError(inError2);
            if (inError2){
                setErrorText(l_errorText);
            } else {
                setErrorText("");
            }
        }
  }

  return (
    <Dialog
        fullScreen={fullScreen}
        open={dialogueOpen ? true : false}
        onClose={cancel}
        TransitionComponent={Transition}
        BackdropProps={{style: {backgroundColor: 'black'}}}
        fullWidth={!fullScreen}
      >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={cancel}
                    aria-label='close'
                    >
                        <ArrowBackIcon/>
                </IconButton>
                <Typography align="center" sx={{ m1: 2, flex: 1}} component="div">
                    {t(title)}
                </Typography>
                <Button autoFocus color="inherit" onClick={handle} disabled={inError}>
                    {t('save')}
                </Button>
            </Toolbar>
        </AppBar>
        <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                padding: theme.spacing(2),
            }}
        >
            <Grid item xs >
                <Typography>
                    {t(instructions)}
                </Typography>
            </Grid>
            <Grid item xs container 
                direction='row'
                sx={{
                    width:22*maxDigits
                }}>
                <Grid item xs>
                    <TextField 
                        
                        sx={{
                            m: 1,
                        }}
                        id={"value"}
                        label={t(fieldLabel)}
                        onChange={e => handleErrorCheckUpdate( e.target.value)}
                        value={localNumber}
                        variant='standard'
                        type="number"
                        error={inError}
                            />
                </Grid>
            </Grid>
            {inError && <Grid item xs>
                <Typography color="error">{t(errorText)}</Typography>
            </Grid>}
            {true && <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                <Button fullWidth autoFocus variant='contained' color='secondary' onClick={handle} disabled={inError}>
                    {t('save')}
                </Button>
            </Grid>}
            {true && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                <Button fullWidth 
                    autoFocus
                    color='inherit'
                    onClick={cancel}
                >
                    {t('cancel')}
                </Button>
            </Grid>}
        </Grid>
    </Dialog>
  );
};

export default DataEntryDialogueNumber;
