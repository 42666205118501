import React, { useEffect, useState } from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';
import {
  Box,
  CircularProgress,
  IconButton,
  Grid,
  List,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';

import { useTheme } from '@emotion/react';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import configurations_inf from '../../services/configurations_inf';
import AddTemplate from './AddTemplate';
import TemplateCard from './TemplateCard'


export default function TemplateList(accountInfo) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [dataReady, setDataReady] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const isFullScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [update, setUpdate] = useState(false); //SAVE used to trigger state updates?

    const fetchTemplates = async (abortController={signal: undefined}) => {
        try{
            const {status, error, templates} = await configurations_inf.getTemplates(
                accountInfo.accountInfo.accountId,
                abortController
            );
            
            if (status === 404) {
                return;
            }
    
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                    throw new Error(error);
                }
                if(error.includes("There are no templates in this account")){
                    setDataReady(true);
                }
              //alert(t(error));
            }
            else{
              console.log('templates Fetched');
              setDataReady(true);
              setTemplateList(templates.sort());
            }
        }catch(error){
            console.log(error);
        }
    };

    const deleteTemplate = async (templateId) =>{
        try{
            const {status, error, templates} = await configurations_inf.deleteTemplate(
                accountInfo.accountInfo.accountId, templateId
            )
            if (error !== 'OK' || status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));                
                }
            }
            else{
                console.log("delete template");
                setTemplateList(templates.sort());
            }
            
        }catch (error) {
            console.log(error);
        }
        fetchTemplates();
        setUpdate(true);
    }

    useEffect(() => {
        const abortController = new AbortController();
        fetchTemplates(abortController);
        return () => abortController.abort();
    },[accountInfo]);

    return (
        <>                  
        <Grid item xs>
            <Toolbar height='64px'>
                <Tooltip title={t("goHome")}>
                    <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                        <ArrowBack />
                    </IconButton>
                </Tooltip>
                <Typography variant="h5">
                    {t("configurationTemplates")}
                </Typography>
            </Toolbar>
            <Grid container justifyContent="flex-end" sx={{'height': '56px'}}>
                {isFullScreen && <AddTemplate accountInfo={accountInfo} isFAB={false}/>}
            </Grid>
            
        </Grid>   
        {dataReady ? <Grid container item direction='column'>          
                <Box id="templateBox" height="calc(100vh - 128px - 56px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                    {templateList.length ? <List sx={{padding: theme.spacing(0), maxWidth: 450, }} > {templateList.map((template) => (
                        <TemplateCard key={'cardKey-'+template.name} template={template} fetchTemplates={fetchTemplates} deleteTemplate={deleteTemplate} accountInfo={accountInfo.accountInfo}/> 
                            ))} 
                        </List>:
                        <Typography align="center">{t("noConfigurationTemplatesFound")}</Typography>
                }
                    {!isFullScreen && <AddTemplate accountInfo={accountInfo}></AddTemplate>} 
                
                </Box>
            </Grid>:
            // <Typography align="center">{t('noConfigurationTemplatesFound')}</Typography>
            <Grid item xs container
                        direction="row"
                        justifyContent="center"
                        alignItems="center" >
                    <CircularProgress sx={{margin: theme.spacing(4)}}/>
                    </Grid>
            }    
        </>
    )
}