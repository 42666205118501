/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
    Grid,
    Typography
} from '@mui/material';

import { 
        BarChart, 
        Bar, 
        XAxis, 
        YAxis, 
        CartesianGrid, 
        Tooltip, 
        Legend, 
        ResponsiveContainer } from 'recharts';

import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';

export default function MetricsHistogram({title, userMetricsData, lifeMetricsData, binNames, xLabel='', yLabel='', dataSelect=''}) {
    const { t } = useTranslation();
    const theme=useTheme();

    const createBinData = (userMetrics, lifeMetrics, bins) => {        
        var binnedData = []
        try{
            if (bins){
                let bin_keys = Object.keys(bins);
                let bin_keys_integers = bin_keys.map(Number);
                bin_keys_integers.sort((a,b) => a-b);
                let maxIndex = bin_keys_integers[bin_keys_integers.length-1];
                for (let i = 0; i < bin_keys_integers.length; i++){
                    let data={}
                    if (bin_keys_integers[i] === 0) {
                        data.name = "<{bin}".replace("{bin}", binNames[bin_keys_integers[i].toString()]);
                    } else if (bin_keys_integers[i] === maxIndex) {  
                        data.name = "> {bin}".replace("{bin}", binNames[bin_keys_integers[i-1].toString()])
                    } else{
                        data.name = "{lower}".replace("{lower}", binNames[bin_keys_integers[i-1].toString()]).replace(
                            "{upper}", binNames[bin_keys_integers[i].toString()]
                        );
                    }
                    data[t('userMetrics')] = (parseFloat(userMetrics[bin_keys_integers[i].toString()]));
                    data[t('lifeMetrics')] = (parseFloat(lifeMetrics[bin_keys_integers[i].toString()]));
                    binnedData.push(data);
                }
            }
        }catch (error) {
            console.log("Unable to bin histogram data");
            console.log({bins: bins, user: userMetrics, life: lifeMetrics});
        }
        return binnedData;
    }

    const DisplayMetricsHistogram = ({userMetrics, lifeMetrics, bins}) => {
      const data = createBinData(userMetrics, lifeMetrics, bins);
      
      return(
        <Grid container item direction='column' alignItems='center'>
            <Grid item xs>
                <Typography>{t(title)}</Typography>
            </Grid>
            <ResponsiveContainer width="99%" height={300}>
                <BarChart
                barCategoryGap = {"10%"}
                barGap = {0}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis label={{value: t(xLabel), position: 'insideBottomRight', offset: 0, fill: 'white'}} dataKey="name" style={{ fill: 'white' }}/>
                <YAxis label={{value: t(yLabel), angle: -90, position: 'insideLeft', fill: 'white' }} style={{ fill: 'white' }}/>
                <Tooltip />
                <Legend />
                {dataSelect !== "life" && <Bar dataKey={t("userMetrics")} fill={theme.palette.secondary.dark} />}
                {dataSelect !== "user" && <Bar dataKey={t("lifeMetrics")} fill={theme.palette.green.main} />}
                </BarChart>
            </ResponsiveContainer>
        </Grid>
      );
    };


    return (
        <>
          {binNames ? Object.keys(binNames).length && <DisplayMetricsHistogram userMetrics={userMetricsData} lifeMetrics={lifeMetricsData} bins={binNames}/>
          : <></>}
        </>
    )
}
