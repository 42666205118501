import React, { useState, useRef } from 'react';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import {useTheme} from '@emotion/react';
import { Typography, TextField, IconButton } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { secondsToTime } from '../helpers/secondsToTime';
import { timeToSeconds } from '../helpers/timeToSeconds';
import { timeToTimeString } from '../helpers/timeToTimeString';
import { GrowDiv } from '../styledComponentsCommon';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const DataEntryDialogueTime = ({ title, param_id, value, instructions, dialogueOpen, handleSave, handleCancel, 
    errorCheck=(key_in, value_in)=>{return {value: value_in, errorText: ""}}, showHour=true, fourDigitHour=true, timeRange=[1,35999999] }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const [localHours, setLocalHours] = useState(value === undefined ? secondsToTime(parseInt("3600")).hours : secondsToTime(parseInt(value)).hours);
  const [localMinutes, setLocalMinutes] = useState(value === undefined ? secondsToTime(parseInt("3600")).minutes : secondsToTime(parseInt(value)).minutes);
  const [localSeconds, setLocalSeconds] = useState(value === undefined ? secondsToTime(parseInt("3600")).seconds : secondsToTime(parseInt(value)).seconds);
  const [open, setOpen] = useState(dialogueOpen ? true : false);
  const [inError, setInError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handle = () => {
    setOpen(false);  
    let key = param_id;
    if (key === undefined){
        key = title;
    }
    handleSave(key, timeToSeconds(localHours, localMinutes, localSeconds));
  };
  const cancel = () => {
    setOpen(false);
    handleCancel();
  };
  React.useEffect(() => { 
    setOpen(dialogueOpen);
  }, [dialogueOpen]);

  

  const handleErrorCheckUpdate = (newValue, type ) => {
        let localNewValue = newValue;
        let inError = false;
        let l_errorText = "";
        let hours = localHours;
        let minutes = localMinutes;
        let seconds = localSeconds;
        switch (type)
        {
            case "seconds":
                if (localNewValue.length > 2){
                    inError = true;
                }
                else if (parseInt(localNewValue) > 59){
                    inError = true;
                }
                else if (parseInt(localNewValue) < 0){
                    inError = true;
                }
                if (!inError){
                    setLocalSeconds(localNewValue);
                    seconds = localNewValue;
                }
                break;
            case "minutes":
                if (localNewValue.length > 2){
                    inError = true;
                }
                else if (parseInt(localNewValue) > 59){
                    inError = true;
                }
                else if (parseInt(localNewValue) < 0){
                    inError = true;
                }
                if (!inError){
                    setLocalMinutes(localNewValue);
                    minutes = localNewValue;
                }
                
                break;
            case "hours": 
                if (fourDigitHour && localNewValue.length > 4){
                    inError = true;
                }
                else if (!fourDigitHour && localNewValue.length >2){
                    inError = true;
                }
                else if (parseInt(localNewValue) > 9999){
                    inError = true;
                }
                else if (parseInt(localNewValue) < 0){
                    inError = true;
                }
                if (!inError){
                    setLocalHours(localNewValue);
                    hours = localNewValue;
                }
                break;
            default: 
                console.log('Error: Default case found');
        }
        let inError2 = false;
        if (!inError){
            if (hours.length < 1){
                hours = "0";
                inError2 = true;
            }
            if (minutes.length < 1){
                minutes = "0";
                inError2 = true;
            }
            if (seconds.length < 1){
                seconds = "0";
                inError2 = true;
            }
            let hour_digits = showHour ? (fourDigitHour ? 4 : 2) : 0;
            let total_seconds = timeToSeconds(hours, minutes, seconds);
            const minimumTime = secondsToTime(timeRange[0]);
            const minimumTimeString = timeToTimeString(minimumTime.hours, minimumTime.minutes, minimumTime.seconds, hour_digits);
            const maximumTime = secondsToTime(timeRange[1]);
            const maximumTimeString = timeToTimeString(maximumTime.hours, maximumTime.minutes, maximumTime.seconds, hour_digits);
            const l_errorText = t("timeErrorCheck2Params").replace('{time1}',minimumTimeString).replace('{time2}', maximumTimeString);
            if (total_seconds > timeRange[1]){
                inError2 = true;
                setErrorText(l_errorText);
            }
            else if (total_seconds < timeRange[0]){
                inError2 = true;
                setErrorText(l_errorText);
            }
            
            setInError(inError2);
            if (inError2){
                setErrorText(l_errorText);
            } else {
                let l_check = errorCheck(param_id, total_seconds);
                if (l_check.errorText.length){
                    setErrorText(l_check.errorText);
                    setInError(true);
                }else{
                    setErrorText("");
                    setInError(inError2);
                }

            }
        }
  }

  return (
    <Dialog
        fullScreen={fullScreen}
        open={dialogueOpen ? true : false}
        onClose={cancel}
        TransitionComponent={Transition}
        BackdropProps={{style: {backgroundColor: 'black'}}}
        fullWidth={!fullScreen}
      >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={cancel}
                    aria-label='close'
                    >
                        <ArrowBackIcon/>
                </IconButton>
                <Typography align="center" sx={{ m1: 2, flex: 1}} component="div">
                    {t(title)}
                </Typography>
                <Button autoFocus color="inherit" onClick={handle} disabled={inError}>
                    {t('save')}
                </Button>
            </Toolbar>
        </AppBar>
        <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                padding: theme.spacing(2),
            }}
        >
            <Grid item xs >
                <Typography>
                    {t(instructions)}
                </Typography>
            </Grid>
            <Grid item xs container 
                direction='row'
                sx={{
                    width:'50%',
                    minWidth:250
                }}>
                {showHour ? <Grid item xs>
                    <TextField 
                        
                        sx={{
                            m: 1,
                        }}
                        id={"hours"}
                        label={t("hours")}
                        onChange={e => handleErrorCheckUpdate( e.target.value, 'hours' )}
                        value={localHours}
                        variant='standard'
                        type="number"
                        error={inError}
                        
                            />
                </Grid>
                : <GrowDiv/>}
                <Grid item xs>
                    <TextField 
                        
                        sx={{
                            m: 1,
                        }}
                        id={"minutes"}
                        label={t("minutes")}
                        onChange={e => handleErrorCheckUpdate( e.target.value, 'minutes' )}
                        value={localMinutes}
                        variant='standard'
                        type="number"
                        error={inError}
                        
                            />
                </Grid>
                <Grid item xs>
                    <TextField 
                        
                        sx={{
                            m: 1,
                        }}
                        id={"seconds"}
                        label={t("seconds")}
                        onChange={e => handleErrorCheckUpdate( e.target.value, 'seconds' )}
                        value={localSeconds}
                        variant='standard'
                        type="number"
                        error={inError}
                        
                            />
                </Grid>
                {!showHour && <GrowDiv/>}
            </Grid>
            {inError && <Grid item xs>
                <Typography color="error">{t(errorText)}</Typography>
            </Grid>}
            {true && <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                <Button fullWidth autoFocus variant='contained' color='secondary' onClick={handle} disabled={inError}>
                    {t('save')}
                </Button>
            </Grid>}
            {true && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                <Button fullWidth 
                    autoFocus
                    color='inherit'
                    onClick={cancel}
                >
                    {t('cancel')}
                </Button>
            </Grid>}
        </Grid>
    </Dialog>
  );
};

export default DataEntryDialogueTime;
