import { useTranslation } from 'react-i18next';

// const { t } = useTranslation();

export const billingConfig = {
    
    futureChargesTable: {
        title: 'futureCharges',
        subtitle: 'estimatedChargesDoNotInclude',
        columnTitles: {
            plan_name: 'billingPlan',
            quantity: 'billingQuantity',
            estimated_total_display: 'estimatedTotal',
            next_invoice_date: 'nextInvoiceDate',
        },
    },
    invoicesTable: {
        title: 'invoices',
        columnTitles: {
            number: 'numberAbbr',
            closed_at: 'closedOn',
            state: 'status',
            total_display: 'total',
            id: 'download',
        },
    },
};
