import React, { useState } from 'react';
import {
    useNavigate,
  } from 'react-router-dom'
import {
    Box,
    Chip,
    IconButton,
    Grid,
    ListItem,
    ListItemButton,
    Tooltip,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalPrompt from '../../CommonComponents/ModalPrompt';

const GroupStatusCard = ({groupStatus, deleteGroup, accountInfo }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [deleteDialogue, setdeleteDialogue] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const handleSelection = ()=>{
        let url = "/groups/{groupName}".replace("{groupName}",groupStatus.name);
        navigate(url, {state: {groupStatus}});
    }

    const opendelDialog = () => {
        setdeleteDialogue(true);
    };

    const closedelDialog = () => {
        setdeleteDialogue(false);
    };

    const handleRemoveGroup =() => {
        console.log("start deleting group");

        deleteGroup(groupStatus.id);

        closedelDialog();
    };

    return <>{
        <ListItem key={groupStatus.name} 
            sx={{paddingLeft: 
                theme.spacing(1), 
                paddingRight: theme.spacing(1),
                maxWidth: 500,
                minWidth: 200,
        }}>
            <ListItemButton onClick={handleSelection} 
                sx={{ 
                    maxWidth: 500, 
                    paddingLeft: theme.spacing(0), 
                    paddingRight: theme.spacing(0) 
            }}>
        <Grid container 
            direction='row' 
            justifyContent="flex-start" 
            alignItems="center" 
            sx={{
                backgroundColor: theme.palette.grey.dark,
                borderRadius: '4px',
                //maxWidth: 550,
        }}>  
                    <Grid item xs>
                    {groupStatus.name === "default" ? <Typography sx={{
                            paddingLeft:theme.spacing(1),
                            marginTop: theme.spacing(1)
                            }} 
                            noWrap gutterBottom variant="body1" fontWeight="bold">
                            {t(groupStatus.name)}
                        </Typography>: <Typography sx={{
                            paddingLeft:theme.spacing(1),
                            marginTop: theme.spacing(1)
                            }} 
                            noWrap gutterBottom variant="body1" fontWeight="bold">
                            {groupStatus.name}
                        </Typography>}
                        <Grid container justify="space-between" paddingLeft={1} paddingBottom={1}> 
                            <Chip sx={{backgroundColor:theme.palette.secondary.dark}} 
                                label={<Typography inline variant="body2">{t("Users")}: {t(groupStatus.Users.length)}</Typography>}/>
                            <Box flexGrow={0.25}/>
                            <Chip sx={{backgroundColor:theme.palette.secondary.dark}} 
                                label={<Typography inline variant="body2">{t("Devices")}: {t(groupStatus.Devices.length)}</Typography> }/>
                        </Grid>
                    </Grid>
        </Grid> 
            </ListItemButton>
        {<Tooltip title={t("deleteGroup")}>  
            <IconButton
                color='primary'
                size='large'
                onClick={opendelDialog}
                disabled={groupStatus.name === "default" ? true : false}
                >
                <DeleteIcon/>
            </IconButton>
        </Tooltip> }      
        </ListItem>
    }
    {deleteDialogue && <ModalPrompt
        title={t('group') + ' ' + groupStatus.name}
        content={t('confirmDeleteGroup').replace("{userCount}", groupStatus.Users.length ? groupStatus.Users.length : t("ok")).replace("{deviceCount}", groupStatus.Devices.length ? groupStatus.Devices.length : t("ok"))}
        action={t('delete')}
        handleAction={handleRemoveGroup} 
        handleCancel={closedelDialog} 
        openModal={deleteDialogue}
        useConfirm={true}
        fullScreen={fullScreen}
        disableAction={false && (groupStatus.Users.length > 0 || groupStatus.Devices.length)}
      />}
    </>
};

export default GroupStatusCard;
