import {Auth} from 'aws-amplify';
import {host} from '../data.js'
import { isValidEmail } from '../helpers/isValidEmail.js';
import {checkAuthorizationException} from '../helpers/checkAuthorizationException.js';

const LOCATION_ERROR_MESSAGE =
    'Error in account information service! Provide, correct data to the service!';

let urlConfig = {
    apiCreate: 'accounts',
    apiGet: 'accounts/',
    api: 'accounts/{accountId}/',
    apiInvite: 'accounts/{accountId}/invite',
    apiRole: 'accounts/{accountId}/role',
    apiManage: 'accounts/{accountId}/manage',
    apiManageUser:'accounts/{accountId}/manage/user',
    apiManageDeivce: 'accounts/{accountId}/manage/device',
    apiInvoices: 'orgs/{orgId}/invoices/{invoiceId}/pdf',
    apiPreview: 'orgs/{orgId}/billing/preview',
    apiDevice: '/accounts/{accountid}/devices',
    apiProfile: '/accounts/{accountId}/profile',
    apiNotification: '/accounts/{accountId}/manage/notifications/',
    apiReportsStatus: '/accounts/{accountId}/reports/status',
    apiManageNotifications: '',
    apiHasBilling: 'accounts/{accountId}/hasbilling',
    //proxyPort: 5000
}

export const accounts = {
    createAccount: async (name, contactName, email, contactPhone, addressLine, country, city, region, postalCode, isDefault) => {
        try {
            if (!name || !country || !region  ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            let localParams = {
                Name: name,
                ContactName: contactName,
                Email: email,
                ContactPhone: contactPhone,
                AddressLine1: addressLine,
                Country: country,
                City: city,
                State: region,
                PostalCode: postalCode,
                isDefault: isDefault
            }
            
            if (!localParams.ContactName){
                localParams.ContactName = "";
            }
            if (!localParams.Email){
                localParams.Email = "";
            }
            if (!localParams.ContactPhone){
                localParams.ContactPhone = "";
            }
            if (!localParams.AddressLine1){
                localParams.AddressLine1 = "";
            }
            if (!localParams.City){
                localParams.City = "";
            }
            if (!localParams.PostalCode){
                localParams.PostalCode = "";
            }
            
            const url =
                host + urlConfig.apiCreate;
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(localParams),
            });
            
            let output = await response.json();
            
            if (output.error.includes("name already exists")){
                output.error="accountNameAlreadyExists"
            }
            
            const data = output;
            const { error, accountDetails } = data;
            return {status: response.status, error, accountDetails};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateAccount: async (accountId, name, contactName, email, contactPhone, addressLine, country, city, region, postalCode, isDefault) => {
        try {
            if (!accountId || !name || !country || !region ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            let localParams = {
                Name: name,
                ContactName: contactName,
                Email: email,
                ContactPhone: contactPhone,
                AddressLine1: addressLine,
                Country: country,
                City: city,
                State: region,
                PostalCode: postalCode,
                isDefault: isDefault
            }

            if (!localParams.ContactName){
                localParams.ContactName = "";
            }
            if (!localParams.Email){
                localParams.Email = "";
            }
            if (!localParams.ContactPhone){
                localParams.ContactPhone = "";
            }
            if (!localParams.AddressLine1){
                localParams.AddressLine1 = "";
            }
            if (!localParams.City){
                localParams.City = "";
            }
            if (!localParams.PostalCode){
                localParams.PostalCode = "";
            }
            
            const url =
                host + urlConfig.api.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(localParams),
            });

            let output = await response.json();
            
            if (output.error.includes("name already exists")){
                output.error="accountNameAlreadyExists"
            }
            
            const data = output;
            const { error, accountDetails } = data;
            return {status: response.status, error, accountDetails};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteAccount: async (accountId) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getAccounts: async (abortController={signal: undefined}) => {
        try {
            //if (!accountId) {
            //    return console.error(LOCATION_ERROR_MESSAGE);
            //}
            
            const url = host + urlConfig.apiGet;
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, userAccounts } = data;
            return {status: response.status,
                 error, userAccounts};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);

            await checkAuthorizationException(error);            
        }
    },
    getNotificationSettings: async (accountId, abortController={signal: undefined}) => {
        try {
            if (!accountId) {
               return console.error(LOCATION_ERROR_MESSAGE);
            }
            console.log("getting notification data");
            const url = host + urlConfig.apiNotification.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, settings } = data;
            return {status: response.status,
                 error, settings};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    updateNotificationSettions: async (targetAccountId, alarmEmail, alarmText, alertEmail, alertText, disEmail, disText, timeout, groups) =>{
        try {
            if (!targetAccountId) {
               return console.error(LOCATION_ERROR_MESSAGE);
            }
            console.log("getting notification data");
            const url = host + urlConfig.apiNotification.replace('{accountId}', targetAccountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    targetAccountId: targetAccountId,
                    alarmEmail: alarmEmail,
                    alarmText: alarmText,
                    alertEmail: alertEmail,
                    alertText: alertText,
                    disconnectEmail: disEmail,
                    diconnectText: disText,
                    timout: timeout,
                    groups: groups
                }),
            });

            const data = await response.json();
            const { error} = data;
            return {status: response.status,
                 error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getAccountInfo: async (accountId, abortController={signal: undefined}) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.api.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, accountData } = data;
            return {status: response.status, error, accountData};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    getAccountHasBilling: async (accountId, abortController={signal: undefined}) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiHasBilling.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });
            
            const data = await response.json();
            const { error, accountHasBilling } = data;
            return {status: response.status, error, accountHasBilling};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    inviteUser: async (accountId, email, role) => {
        try {
            if (!accountId || !email || !role ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            if (!isValidEmail(email)){
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url =
                host + urlConfig.apiInvite.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    Email: email,
                    Role: role                    
                }),
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateRole: async (accountId, email, role) => {
        try {
            if (!accountId || !email || !role ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiRole.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    user: email,
                    role: role                    
                }),
            });

            const data = await response.json();
            const { error } = data;
            
            return {status: response.status, error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteRole: async (accountId, email) => {
        try {
            if (!accountId || !email) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiRole.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    user: email,
                }),
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteUser: async (accountId, email) => {
        try {
            if (!accountId || !email) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiManageUser.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    user: email,
                }),
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteDevice: async (accountId, device) => {
        try{
            if(!accountId || !device){
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url =
                host + urlConfig.apiManageDeivce.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    device: device,
                }),
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    manageAccount: async (accountId) => {
        try {
            if (!accountId ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiManage.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    leaveAccount: async (accountId) =>{
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiProfile.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({}),
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    sendStatusReport: async (accountId) =>{
        try {
            if (!accountId){
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            let tzOffset = 0;

            var date = new Date();
            tzOffset = date.getTimezoneOffset() / 60;

            const url =
                host +urlConfig.apiReportsStatus.replace("{accountId}", accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
               method: "POST",
               headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
               },
               body: JSON.stringify({
                    tz_offset: tzOffset,
               }) 
            });

            const data = await response.json();

            const { error } = data;
            return {status: response.status, 
                error: error,
            };
        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    }
};
