import React, { useState, useRef , useEffect} from 'react';
import {
    IconButton,
    Grid,
    ListItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ModalPrompt from '../../../CommonComponents/ModalPrompt';
import {DEVICE_TYPES} from '../../../DeviceInfo/deviceTypes'
import ChangeDeviceStatusButton from './ChangeDeviceStatusButton';

const DeviceCard = ({device, accountInfo, removeDevice, fetchAccountInfo, 
                billingData={}, setBillingData=()=>{}, orgBilling={}, getSubscriptionCount=()=>{return 0}}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [displayUID, setUID]= useState();
    const [displayName, setDisplayName] = useState();
    const [removeDialog, setRemoveDialog] = useState(false);
    const [type, setType] = useState();
    const [cardWidth, setCardWidth] = useState(350);
    let rCard = useRef();

    
    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            setCardWidth(entries[0].contentRect.width)
        })
        observer.observe(rCard.current)
        return () => rCard.current && observer.unobserve(rCard.current)
    }, [])

    useEffect(() => {
      filterData(device);
    },[accountInfo, device]);

    function filterData(device){
        setUID(device.uid);
        setDisplayName(device.friendly_name);
        
        let temp = device.type;
        for (const [key, value] of Object.entries(DEVICE_TYPES)) {
            if(key === temp){
                setType(value.name)
            }
        }
    }

    function openRemoveDialog(){
        setRemoveDialog(true);
    };

    function closeRemoveDialog(){
        setRemoveDialog(false);
    }

    const handleRemoveDevice = async () => {
        removeDevice(device.uid);
        closeRemoveDialog();
    };

    const renderDeviceName = () => {
        let name = displayName ? displayName : device.uid;
        if (cardWidth < 375)
        {
            if (name.length > 20) 
            {
                name = name.substr(0,20) + "...";
            }   
        }
        else if (cardWidth < 450)
        {
            if (name.length > 30) 
            {
                name = name.substr(0,30) + "...";
            }  
        }
        return name;
    }


    return(
    <>
        {<ListItem ref={rCard} key={device.uid} 
        sx={{paddingLeft: 
            theme.spacing(1), 
            paddingRight: theme.spacing(1),
            maxWidth: 500,
            minWidth: 250,
        }}>
            <Grid container 
                direction='row' 
                justifyContent="flex-start" 
                alignItems="center" 
                sx={{
                    backgroundColor: theme.palette.grey.dark,
                    borderRadius: '4px',
                    maxWidth: 500,
                    minWidth: 250,
                }}>  
                <Grid item xs sx={{paddingLeft:theme.spacing(1)}} direction="column" container justifyContent="flex-start" >
                    <Typography 
                        sx={{
                            marginTop: theme.spacing(1),
                        }}
                        noWrap gutterBottom variant="body1" component="span" >
                        {renderDeviceName()}
                    </Typography>
                    <Grid item xs direction="row" container justifyContent="space-between">
                        <Stack direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            useFlexGap
                            flexWrap="wrap"
                            >
                            <Typography noWrap gutterBottom variant="body1" component="span" >
                                {t("deviceUid")}{t(displayUID)}
                            </Typography>
                            <Typography noWrap gutterBottom variant="body1" component="span" >
                                {t("activeStatus")} {device.activation_status === 1 ? t("deviceActive") : t("deviceNotActive")}
                            </Typography>
                        </Stack>
                        <Stack direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            useFlexGap
                            flexWrap="wrap">
                        {device.activation_status === 0 && <Tooltip title={t("removeDeviceFromAccount")}>
                                <IconButton
                                    edge="start"
                                    color='primary'
                                    onClick={openRemoveDialog}
                                    sx={{paddingRight: theme.spacing(2)}}
                                    >
                                    <RemoveCircleIcon/>
                                </IconButton>
                            </Tooltip>}
                        </Stack>
                    </Grid>
                    <Typography noWrap gutterBottom variant="body1" component="span" >
                            {t("deviceType")} {t(type)}
                    </Typography> 
                    <ChangeDeviceStatusButton 
                        accountInfo={accountInfo}
                        device_info={device}
                        updateDevices = {fetchAccountInfo}
                        billingData={billingData}
                        setBillingData={setBillingData}
                        orgBilling={orgBilling}
                        getSubscriptionCount={getSubscriptionCount}
                    />
                </Grid>
                
            </Grid>
        </ListItem>}
        {removeDialog && <ModalPrompt
            title={''}
            content={t('removeDeviceFromAccountDialog')}
            action={t('removeDeviceFromAccount')}
            handleAction={handleRemoveDevice} 
            handleCancel={closeRemoveDialog} 
            openModal={removeDialog}
            fullScreen={fullScreen}
            useConfirm={false}
        />}
    </>
    )
};

export default DeviceCard;