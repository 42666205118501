/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
    Divider,
    Grid,
} from '@mui/material';

import { outputs_parameter_obj } from '../../../DeviceInfo/g3_Config.js';
import { hw_config_obj } from '../../../DeviceInfo/g3_Config.js';
import DataEntryListItemHeader from '../../../CommonComponents/DataEntryListItemHeader.js';
import GenerateConfigItems from './GenerateConfigItems.jsx';

// Device Page
export default function OutputSettings({editing, currentSettings, hwConfig, updateSettings=()=>{}}) {
    const [editParam, setEditParam] = useState(false); //CI:  Keep
    const [openParams, setOpenParams] = useState ({
        "105": false,
        "106": false,
        "104": false,
        "9": false,
        "5": false,
        "103": false,
        "102": false,
        "30": false,
        "6": false,
        "39": false,
        "40": false,
    });

    const handleToggle = (key_in) => {
        if (parseInt(currentSettings[key_in])) {
            handleUpdateParams(key_in,0);
        }
        else{
            handleUpdateParams(key_in,1);
        }
    };

    const handleOpenParam = (key_in) => {
        let l_openParams = openParams;
        l_openParams[key_in] = true;
        setOpenParams(l_openParams);
        setEditParam(true);
    };
    const handleCloseParams = () => {
        let l_openParams = openParams;
        Object.keys(l_openParams).forEach(key => {
            l_openParams[key] = false;
        })
        setOpenParams(l_openParams);
        setEditParam(false);
    };
    const handleUpdateParams = (key_in, value_in)  => {
        
        var temp_params = Object.assign({}, currentSettings);
        if (temp_params[key_in] !== String(value_in)){
            temp_params[key_in] = String(value_in);
            //Check for Signal Output 1 Source Change
            if (key_in === "113"){
                if (value_in === 2) {
                    temp_params["114"] = String(0);
                }
                else{
                    temp_params["114"] = String(1);
                }
            }
            //Check for Signal Output 2 Source Change
            if (key_in === "117"){
                if (value_in === 2) {
                    temp_params["118"] = String(0);
                }
                else{
                    temp_params["118"] = String(1);
                }
            }
            //Handle Pulsed Output for Relay Output Type Change
            if (key_in === "110"){
                if (value_in === 3) {
                    temp_params["112"] = String(1);
                }
                else{
                    temp_params["112"] = String(0);
                }
            }
            updateSettings(temp_params);
        }
        handleCloseParams();
    };

    const handleErrorCheckParams = (key_in, value_in) => {
        let value_out=undefined;
        let errorText = ""
        value_out = value_in;
        return {value: value_out, errorText: errorText}
    };

    
    const relay_output_type_list = [
        //{key: 0, id: 0, name: "unused", displayLabel: "relayOutputOff"},
        {key: 1, id: 1, name: "alarm", displayLabel: "relayOutputAlarm"},
        {key: 2, id: 2, name: "alert", displayLabel: "relayOutputAlert"},
        {key: 3, id: 3, name: "alarm and alert", displayLabel: "relayOutputAlarmAndAlert"},
        //{key: 4, id: 4, name: "vent valve", displayLabel: "relayOutputVentValve"},
    ];

    const output_active_open_close_list = [
        {key: 0, id: 0, name: "active open", displayLabel: "activeOpen"},
        {key: 1, id: 1, name: "active closed", displayLabel: "activeClosed"},
    ];

    const output_relay_closed_open_list = [
        {key: 0, id: 0, name: "normally closed", displayLabel: "normallyClosed"},
        {key: 1, id: 1, name: "normally open", displayLabel: "normallyOpen"},
    ];

    const signal_output_source_list = [
        {key: 0, id: 0, name: "system", displayLabel: "signalSourceSystem"},
        {key: 1, id: 1, name: "level", displayLabel: "signalSourceLevel"},
        {key: 2, id: 2, name: "motor", displayLabel: "signalSourceMotor"},
    ];

    const signal_output_type_list = [
        {key: 1, id: 1, name: "alarm", displayLabel: "signalTypeAlarm"},
        {key: 2, id: 2, name: "alert", displayLabel: "signalTypeAlert"},
        {key: 3, id: 3, name: "alarm and alert", displayLabel: "signalTypeAlarmAndAlert"},
    ];

    const DisplayOutputsRelay = () => {
        const parameter_list = [
            {
                key: "110",
                'id': 'relay_out_type_id_110', 
                'config_id': "110",
                'type': "list",
                "list": relay_output_type_list
            },
            // {
            //     key: "111",
            //     'id': 'relay_out_active_high_id_111', 
            //     'config_id': "111",
            //     'type': "list",
            //     "list": output_relay_closed_open_list
            // },
            // {   
            //     key: "112",
            //     'id': 'relay_out_pulse_alarm_id_112', 
            //     'config_id': "112",
            //     'type': "toggle",
            // },
        ];
        const relay_pulsed_list = [
            {   
                key: "112",
                'id': 'relay_out_pulse_alarm_id_112', 
                'config_id': "112",
                'type': "toggle",
            },
        ];
        return(
            <Grid>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
                {(currentSettings[parameter_list[0].config_id] === "3") && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={relay_pulsed_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>
        );
    };

    const DisplayOutputsVentValve = () => {
        const parameter_list = [
            // {
            //     key:"121",
            //     'id': 'vent_valve_enable_id_121', 
            //     'config_id': "121",
            //     'type': "toggle",
            // },
            {
                key:"123",
                'id': 'vent_valve_dwell_time_id_123', 
                'config_id': "123",
                'type': "number",
                'fieldLabel': "seconds"
            },
        ];
        return(
            <Grid>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
            </Grid>
        );
    };

    const DisplayOutputsSignal = () => {
        
        return(
            <Grid>
                {hwConfig.includes(hw_config_obj['signal_output_1'].id) &&
                    <>
                    <Divider/>
                        <DataEntryListItemHeader label={"settingsOutputsSignal1"}/>
                    <Divider/>
                    <DisplayOutputSignal1/>
                    </>
                }
                {hwConfig.includes(hw_config_obj['signal_output_2'].id) &&
                    <>
                    <Divider/>
                        <DataEntryListItemHeader label={"settingsOutputsSignal2"}/>
                    <Divider/>
                    <DisplayOutputSignal2/>
                    </>
                }
            </Grid>
        );
    };

    const DisplayOutputSignal1 = () => {
        const parameter_list = [
            {
                key:"113",
                'id': 'signal_out1_source', 
                'config_id': "113",
                'type': "list",
                'list': signal_output_source_list,
            },
            {
                key:"115",
                'id': 'signal_out1_active_high_id_115', 
                'config_id': "115",
                'type': "list",
                'list': output_active_open_close_list
            },

        ];
        const type_list = [
            {
                key:"114",
                'id': 'signal_out1_type_id_114', 
                'config_id': "114",
                'type': "list",
                'list': signal_output_type_list,
            },
        ];
        const signal_pulsed_list = [
            {
                key:"116",
                'id': 'signal_out1_pulsed_id_116', 
                'config_id': "116",
                'type': "toggle",
            },
        ];
        return (
            <Grid>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
                {(currentSettings[parameter_list[0].config_id] === "0" || currentSettings[parameter_list[0].config_id] === "1") && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={type_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
                {(currentSettings[parameter_list[0].config_id] === "0" || currentSettings[parameter_list[0].config_id] === "1") 
                    && (currentSettings[type_list[0].config_id] === "3") && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={signal_pulsed_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>
        );
    };

    const DisplayOutputSignal2 = () => {
        const parameter_list = [
            {
                key:"117",
                'id': 'signal_out2_source', 
                'config_id': "117",
                'type': "list",
                'list': signal_output_source_list,
            },
            {
                key:"119",
                'id': 'signal_out2_active_high_id_119', 
                'config_id': "119",
                'type': "list",
                'list': output_active_open_close_list
            },

        ];
        const type_list = [
            {
                key:"118",
                'id': 'signal_out2_type_id_118', 
                'config_id': "118",
                'type': "list",
                'list': signal_output_type_list
            },
        ];
        const signal_pulsed_list = [
            {
                key:"120",
                'id': 'signal_out2_pulsed_id_120', 
                'config_id': "120",
                'type': "toggle",
            },
        ];
        return (
            <Grid>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
                {(currentSettings[parameter_list[0].config_id] === "0" || currentSettings[parameter_list[0].config_id] === "1") && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={type_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
                {(currentSettings[parameter_list[0].config_id] === "0" || currentSettings[parameter_list[0].config_id] === "1") 
                    && (currentSettings[type_list[0].config_id] === "3") && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={signal_pulsed_list}
                    parameter_obj={outputs_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>
        );
    };

    //Header consumes 64px of viewable area
    return (
        <Grid container item direction='column'>
            <Divider/>
            {hwConfig.includes(hw_config_obj['vent_valve_output'].id) && 
                <>
                    <Divider/>
                    <DataEntryListItemHeader label={"settingsOutputsVentValve"}/>
                    <Divider/>
                    <DisplayOutputsVentValve/>
                </>}
            {hwConfig.includes(hw_config_obj['alarm_relay_output'].id) && 
            <>
                <DataEntryListItemHeader label={"settingsOutputsRelay"}/>
                <Divider/>
                <DisplayOutputsRelay/>
            </>}
            <DisplayOutputsSignal/>
        </Grid>
    )
}
