
export const g3_meta_metrics = [
    {key: 0, displayName: "metricMetricsDate", metric_id: "report_time", type:"date_time"},
    {key: 1, displayName: "metricLastConfigurationChange", metric_id: undefined, type: "config_time"},
    {key: 2, displayName: "metricUserMetricsStartDate", metric_id: 'user_reset_request', type: "date_time"}
]

export const g3_metrics = [
        {key: 0, displayName: 'metricLubricationEvents', metric_id: '28', type: 'count'},
        {key: 1, displayName: 'metricLubricationRunTime', metric_id: '10', type: 'time'},
        {key: 2, displayName: 'metricCycleAlarms', metric_id: '1', type: 'count'},
        {key: 3, displayName: 'metricPressureAlarms', metric_id: '4', type: 'count'},
        {key: 4, displayName: 'metricLowLevelAlarms', metric_id: '2', type: 'count'},
        {key: 5, displayName: 'metricLowLevelAlerts', metric_id: '12', type: 'count'},
        {key: 6, displayName: 'metricOtherAlarms', metric_id: '3', type: 'count'},
        {key: 7, displayName: 'metricAlarmTime', metric_id: '18', type: 'time'},
        {key: 8, displayName: 'metricPoweredUpCount', metric_id: '14', type: 'count'},
        {key: 9, displayName: 'metricPumpActivated', metric_id: '7', type: 'count'},
        {key: 10, displayName: 'metricManualLubricationEventsUI', metric_id: '8', type: 'count'},
        {key: 11, displayName: 'metricManualLubricationEventsRemote', metric_id: '9', type: 'count'},
        {key: 12, displayName: 'metricAverageLubricationTime', metric_id: '23', type: 'time'},
        {key: 13, displayName: 'metricAverageDutyCyclePercent', metric_id: '24', type: 'percentage'},
        {key: 14, displayName: 'metricMaxDutyCyclePercent', metric_id: '25', type: 'percentage'},
        {key: 15, displayName: 'metricPumpOnCurrent', metric_id: '6', type: 'float'},
        {key: 16, displayName: 'metricMotorCurrentAverage', metric_id: '21', type: 'float'},
        {key: 17, displayName: 'metricMotorCurrentMax', metric_id: '15', type: 'float'},
        {key: 18, displayName: 'metricLastMeasuredVoltage', metric_id: '29', type: 'float'},
        {key: 19, displayName: 'metricTemperature', metric_id: '11', type: 'temperature'},
        {key: 20, displayName: 'metricTemperatureMin', metric_id: '16', type: 'temperature'},
        {key: 21, displayName: 'metricTemperatureMax', metric_id: '17', type: 'temperature'},
];