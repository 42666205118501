import React, { useState, useRef } from 'react';
import {useTheme} from '@emotion/react';
import { 
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Typography, 
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const DataEntryListItem = ({ label, value, config_id, handleClick=(key)=>{}, 
                            clickDisabled=false, invertEmphasis=false, inError=false
                         }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <ListItem divider key={label} disablePadding>
        <ListItemButton 
            onClick={() => {if(!clickDisabled) {handleClick(config_id ? config_id : label)}}}
        >
            <ListItemText  primaryTypographyProps={{
                    fontWeight: invertEmphasis ? undefined : "bold",
                    color: inError ? theme.palette.error.main
                        : invertEmphasis ? theme.palette.primary.main : undefined,
                }}
                primary={t(label)}
            />
            <ListItemIcon>
                <Typography sx={{
                        fontWeight: invertEmphasis ? "bold" : undefined,
                        color: inError ? theme.palette.error.main 
                            : invertEmphasis ? undefined : theme.palette.primary.main
                        }}>
                    {value}
                </Typography>
                    {!clickDisabled && <ChevronRightIcon sx={{marginLeft: theme.spacing(1)}}/>}
            </ListItemIcon>
        </ListItemButton>
    </ListItem>
  );
};

export default DataEntryListItem;
