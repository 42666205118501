import {useState, useEffect} from 'react';

export const useScript = (url) => {
    const [scriptStatus, setStatus] = useState(false);
    const statusListener = () => setStatus(true);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
        script.addEventListener('load', statusListener, false);
        document.body.appendChild(script);

        return () => {
            script.removeEventListener('load', statusListener, false);
            document.body.removeChild(script);
        };
    }, [url]);

    return scriptStatus;
};
