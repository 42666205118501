export function timeToSeconds (hours, minutes, seconds) {
    let l_hours = 0;
    let input = {hours: parseInt(hours), minutes: parseInt(minutes), seconds: parseInt(seconds)};
    if (input.hours === undefined){
        l_hours = 0;
    }
    else if (input.hours > 9999){
        l_hours = 9999;
    }
    else {
        l_hours = input.hours;
    }
    let l_minutes = 0;
    if (input.minutes === undefined){
        l_minutes = 0;
    }
    else if (input.minutes > 59){
        l_minutes = 59;
    }
    else {
        l_minutes = input.minutes;
    }
    let l_seconds = 0;
    if (input.seconds === undefined){
        l_seconds = 0;
    }
    else if (input.seconds > 59) {
        l_seconds = 59;
    }
    else{
        l_seconds = input.seconds;
    }
    const timeInSeconds = l_hours*60*60 + l_minutes*60 + l_seconds;

    return timeInSeconds;
  }