import {Auth} from 'aws-amplify';
import {host} from '../data.js';
import {checkAuthorizationException} from '../helpers/checkAuthorizationException.js';

const LOCATION_ERROR_MESSAGE =
    'Error in account information service! Provide, correct data to the service!';

let url_config ={
    api: 'accounts/{accountId}/configurations',
    apiConfig: 'accounts/{accountId}/configurations/{configId}',
};

export const configurations_inf ={
    getTemplates: async(accountId, abortController={signal: undefined}) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url = 
                host + url_config.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });
            const data = await response.json();

            const { error, templates } = data[0];
            return {status: response.status, 
                    error: error,
                    templates: templates,
                };

        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    getConfigData: async(accountId, templateId) => {
        try{
            if( !accountId || !templateId){
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url = 
                host + url_config.apiConfig.replace("{accountId}",accountId).replace("{configId}", templateId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });
            let output = await response.json();
            const { error , template, params} = output;
            return {status: response.status, 
                    error: error,
                    template: template,
                    params: params
                };
        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
            
            await checkAuthorizationException(error);
        }
    },
    createTemplate: async(accountId, name, des, type, param) => {
        try{
            if( !accountId || !name){
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url = 
                host + url_config.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    TemplateName: name,
                    Description: des,
                    DeviceType: type,
                    Params: param
                }),
            });
            let output = await response.json();
            const { error } = output;
            return {status: response.status, 
                    error: error
                };
        }
        catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    UpdateTemplate: async(accountId, templateId, name, desc, param) => {
        try{
            if( !accountId || !templateId){
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url = 
                host + url_config.apiConfig.replace("{accountId}",accountId).replace("{configId}", templateId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    TemplateName: name,
                    Description: desc,
                    Params: param
                }),
            });
            let output = await response.json();
            const { error } = output;
            return {status: response.status, 
                    error: error
                };

        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteTemplate: async(accountId, templateId) =>{
        try{
            if( !accountId || !templateId){
                return console.error(LOCATION_ERROR_MESSAGE);
            }
                const url = 
                host + url_config.apiConfig.replace("{accountId}",accountId).replace("{configId}", templateId);
            const user = await Auth.currentAuthenticatedUser();
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });
            let output = await response.json();
            const { error, templates } = output;
            return {status: response.status, 
                    error: error,
                    templates: templates
                };
        }catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    }
}

export default configurations_inf;