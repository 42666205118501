import React, { useState } from 'react';
import {
    IconButton,
    Grid,
    ListItem,
    Typography,
} from '@mui/material';

import HelpIcon from '@mui/icons-material/Help';

import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';

//import TankImage from "./assets/img/tank_al.png";
import { G3_STATE_ID } from '../../DeviceInfo/g3_Info';
import {interval_parameters_obj, lubrication_parameters_obj} from '../../DeviceInfo/g3_Config';
import { GrowDiv } from '../../styledComponentsCommon';

import useFormatDateIsoString from '../../helpers/useFormatDateIsoString';
import {FormatDateIsoString} from '../../helpers/useFormatDateIsoString';
import InstructionsDialogue from './InstructionsDialogue';

const DetailsStatusCard = ({ connection, deviceStatus }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [device_state, setDeviceState] = useState( G3_STATE_ID[deviceStatus.state])
    const [openAlarmInfo, setOpenAlarmInfo] = useState(false);
    
    var formattedDate = useFormatDateIsoString(deviceStatus.last_connected);
    //let device_state = G3_STATE_ID[deviceStatus.state];
    React.useEffect(() => { 
        formattedDate = FormatDateIsoString(deviceStatus.last_connected);
        const l_device_state = G3_STATE_ID[deviceStatus.state];
        setDeviceState(l_device_state);
    }, [deviceStatus]);
    
    function FindConfigKey(object, match="", matchName="name", ){
        let output_key = 0;
        Object.keys(object).forEach(key => {
            if (object[key][matchName] === match){
                output_key = object[key]['id']
                return output_key;
            }
        });
        return output_key;
    }

    const SimpleProgressBar = ({completed}) => {
        const containerStyles = {
            height: 5,
            width: '100%',
            backgroundColor: theme.palette.grey52.main,
            borderRadius: 4,
            margin: '-5px 0px 0px'
            //margin: 50
          }
        
          const fillerStyles = {
            height: '100%',
            width: `${completed}%`,
            backgroundColor: theme.palette.accentblue.main,
            borderRadius: 'inherit',
            textAlign: 'right'
          }
          return(
            <div style={containerStyles}>
                <div style={fillerStyles}>
                </div>
            </div>
          );
    }

    const DisplayState = () => {
        let timeSettingString = undefined;
        if (connection) {
            if (device_state.isAlarm){
                return(<DisplayAlarm />);
            }
            else{
                switch (device_state.mode) {
                    case "mode_timer":
                        switch(device_state.state){
                            case "state_idle":
                                timeSettingString = deviceStatus.config_reported[
                                    FindConfigKey(interval_parameters_obj, 'lubrication_start_time_target')
                                ];
                                break;
                            case "state_running":
                                timeSettingString = deviceStatus.config_reported[
                                    FindConfigKey(lubrication_parameters_obj, 'lubrication_end_timer_target')
                                ];
                                break;
                            default:
                                break;
                        }
                        return(<DisplayTimer  timeRemainingString={deviceStatus.state_value_1} timeSettingString={timeSettingString}/>)
                        //break;
                    case "mode_pressure_switch":
                        timeSettingString = timeSettingString = deviceStatus.config_reported[
                            FindConfigKey(lubrication_parameters_obj, 'lubrication_end_pressure_timeout_target')
                        ]; 
                        return(<DisplayTimer  timeRemainingString={deviceStatus.state_value_1} timeSettingString={timeSettingString}/>)
                        //break;
                    case "mode_machineCount":
                        let machineCountSettingString = deviceStatus.config_reported[
                            FindConfigKey(interval_parameters_obj, 'lubrication_start_machine_count_target')
                        ];
                        return(<DisplayMachineCount 
                            machineCountString={deviceStatus.state_value_2} 
                            machineCountSettingString={machineCountSettingString}/>)
                        //break;
                    case "mode_cycle":
                        let cycleCountSettingString = deviceStatus.config_reported[
                            FindConfigKey(lubrication_parameters_obj, 'lubrication_end_cycle_target')
                        ];
                        return (<DisplayCycleCount 
                            cycleCountString={deviceStatus.state_value_2}
                            cycleCountSettingString={cycleCountSettingString}/>)
                        //break;
                    
                    case "mode_pressure_sensor":
                        let pressureTargetSettingString = deviceStatus.config_reported[
                            FindConfigKey(lubrication_parameters_obj, 'lubrication_end_pressure_analog_target')
                        ];
                        let pressureUnitsSettingString = deviceStatus.config_reported[
                            FindConfigKey(lubrication_parameters_obj, 'lubrication_end_pressure_analog_units')
                        ];
                        return (<DisplayPressureSensor
                            pressureString={deviceStatus.state_value_2}
                            pressureTargetSettingString={pressureTargetSettingString}
                            pressureUnitsSettingString={pressureUnitsSettingString}/>)
                        //break;
                    default:
                        return(<DisplayDisconnected/>);
                }
            }
            //return(<Typography>Placeholder</Typography>);
        }
        else {
            return(<DisplayDisconnected/>);
        }
    }

    const DisplayTimer = ({timeRemainingString, timeSettingString}) => {
        let timeRemaining = parseInt(timeRemainingString);
        let complete = timeSettingString ? 100 - Math.ceil(100*timeRemaining/parseInt(timeSettingString)) : 0;
        return(
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="flex-start" 
                sx={{
                    
            }}>
                <Grid 
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems='center'
                    sx={{
                        backgroundColor: theme.palette.grey.dark,
                        borderRadius: '4px',
                        height: 64,                    
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1)
                    }}>
                        <Grid>
                            <Typography variant='h5'>
                                {t('timeRemaining')}
                            </Typography>
                        </Grid>
                        <GrowDiv/>
                        <Grid>
                            <Typography variant='h5' color={theme.palette.secondary.light}>
                                {timeRemaining < 60 ? t('timeRemainingSecAbbrev').replace('{timeRemaining}', timeRemaining) 
                                    : t('timeRemainingMinAbbrev').replace('{timeRemaining}', Math.ceil(timeRemaining / 60))}
                            </Typography>
                        </Grid>
                </Grid>
                {<SimpleProgressBar completed={complete} />}
            </Grid>
        )
    };

    const DisplayMachineCount = ({machineCountString, machineCountSettingString}) => {
        let machineCount = parseInt(machineCountString);
        let complete = machineCountSettingString ? Math.ceil(100*machineCountString/parseInt(machineCountSettingString)) : 0;
        return(
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="flex-start" 
                sx={{
                    
            }}>
                <Grid 
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems='center'
                    sx={{
                        backgroundColor: theme.palette.grey.dark,
                        borderRadius: '4px',
                        height: 64,                    
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1)
                    }}>
                        <Grid>
                            <Typography variant='h5'>
                                {t('machineCountsRemaining')}
                            </Typography>
                        </Grid>
                        <GrowDiv/>
                        <Grid>
                            <Typography variant='h5' color={theme.palette.secondary.light}>
                                {machineCountSettingString ? "{count} / {total}".replace("{count}", machineCount).replace("{total}", machineCountSettingString)
                                : machineCount}
                            </Typography>
                        </Grid>
                </Grid>
                {<SimpleProgressBar completed={complete} />}
            </Grid>
        )
    }

    const DisplayCycleCount = ({cycleCountString, cycleCountSettingString}) => {
        let cycleCount = parseInt(cycleCountString);
        let complete = cycleCountSettingString ? Math.ceil(100*cycleCountString/parseInt(cycleCountSettingString)) : 0;
        return(
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="flex-start" 
                sx={{
                    
            }}>
                <Grid 
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems='center'
                    sx={{
                        backgroundColor: theme.palette.grey.dark,
                        borderRadius: '4px',
                        height: 64,                    
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1)
                    }}>
                        <Grid>
                            <Typography variant='h5'>
                                {t('cycleCountsRemaining')}
                            </Typography>
                        </Grid>
                        <GrowDiv/>
                        <Grid>
                            <Typography variant='h5' color={theme.palette.secondary.light}>
                                {cycleCountSettingString ? "{count} / {total}".replace("{count}", cycleCount).replace("{total}", cycleCountSettingString)
                                : cycleCount}
                            </Typography>
                        </Grid>
                </Grid>
                {<SimpleProgressBar completed={complete} />}
            </Grid>
        )
    }

    const DisplayPressureSensor = ({pressureString, pressureTargetSettingString, pressureUnitsSettingString}) => {
        const pressure_units = {
            "0" : "pressureUnits_kPa",
            "1": "pressureUnits_percentage",
            "2": "pressureUnits_psi",
            "3": "pressureUnits_bar",
        };
        let pressure = parseInt(pressureString);
        let pressureTarget = Math.round(parseFloat(pressureTargetSettingString));
        let complete = pressureTargetSettingString ? Math.ceil(100*pressure/pressureTarget) : 0;
        let unitsString = pressureUnitsSettingString ? pressure_units[pressureUnitsSettingString] : "";
        return(
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="flex-start" 
                sx={{
                    
            }}>
                <Grid 
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems='center'
                    sx={{
                        backgroundColor: theme.palette.grey.dark,
                        borderRadius: '4px',
                        height: 64,                    
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1)
                    }}>
                        <Grid>
                            <Typography variant='h5'>
                                {t('pressureDetails')}
                            </Typography>
                        </Grid>
                        <GrowDiv/>
                        <Grid>
                            <Typography variant='h5' color={theme.palette.secondary.light}>
                                {pressureTargetSettingString ? 
                                "{pressure} / {target} {units}".replace("{pressure}", pressure).replace("{target}", pressureTarget).replace("{units}",t(unitsString))
                                : "{pressure}".replace("{pressure}",pressure)}
                            </Typography>
                        </Grid>
                </Grid>
                {<SimpleProgressBar completed={complete} />}
            </Grid>
        )
    }

    const DisplayAlarm = () => {
        let alarmText = device_state.name ? device_state.name : "unknown";

        return(
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start" 
                    sx={{
                        backgroundColor: theme.palette.red.dark5,
                        borderRadius: '4px',
                        height: 64,                    
                        padding: theme.spacing(2)
                }}>
                    <Typography variant={"body1"} color={theme.palette.error.main} noWrap>
                        {t(alarmText)}
                    </Typography>
                    
                    <Grid item xs>
                        <IconButton onClick={() => {
                            handleOpenAlarmInfo(deviceStatus.state);
                        }}>
                            <HelpIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
        );
    }

    const DisplayDisconnected = () => {
        let stateName = device_state.name ? device_state.name : "State {id}".replace('{id}', device_state);

        return(
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="flex-start" 
                sx={{
                    backgroundColor: (connection > 0) ? theme.palette.grey.dark : theme.palette.red.dark5,
                    borderRadius: '4px',
                    height: 64,                    
                    padding: theme.spacing(2)
            }}>
                {connection > 0 ? <Typography variant='h5' noWrap>
                    {t(stateName)}
                </Typography>
                :<Typography variant="body1" color={theme.palette.error.main} noWrap>
                    {t('lastConnected').replace("{timestamp}", formattedDate)}
                 </Typography>}

            </Grid>
        );
    };
    const handleOpenAlarmInfo = (stateId) => {
        setOpenAlarmInfo(true);
    };

    const handleCloseAlarmInfo = () => {
        setOpenAlarmInfo(false);
    }

    return (
        <>
        <ListItem key={deviceStatus.uid} 
            sx={{paddingLeft: 
                theme.spacing(1), 
                paddingRight: theme.spacing(1),
                maxWidth: 500,
                minWidth: 350,
        }}>
            <DisplayState />
        </ListItem>
        <InstructionsDialogue 
            title={G3_STATE_ID[deviceStatus.state.toString()].name} 
            instructions={G3_STATE_ID[deviceStatus.state.toString()].instructions}
            dialogueOpen={openAlarmInfo}
            handleClose={handleCloseAlarmInfo}
        />
        </>
    )
};

export default DetailsStatusCard;
