import { createTheme } from '@mui/material/styles';
import { dark } from '@mui/material/styles/createPalette';

export const Theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root:{
            "&.MuiDataGrid-columnsContainer": {
              backgroundColor: '#555555',
              color: '#FFFFFF'
            },
            "&.MuiDataGrid-iconSeparator": {
                //display: 'none',
            },
            "&.MuiDataGrid-sortIcon":{
              color: '#fff'
            } 
          }
        },
        defaultProps: {
          disableColumnMenu: true,
        }
      }
    },
    overrides: {
      MuiInput: {
        input: {
          '&::-ms-reveal': {
            display: 'none'
          },
        },
      },
      MuiDataGrid: {
        root:{
          '& .MuiDataGrid-columnsContainer': {
              backgroundColor: '#555555',
              color: '#FFFFFF'
          },
          '& .MuiDataGrid-iconSeparator': {
              //display: 'none',
          },
          '& .MuiDataGrid-sortIcon':{
            color: '#fff'
          }
        },
      },
    },

    palette: {
      mode: 'dark',
      background: {
        default: "#000000",
        dark: "#4A4A4A",
        light: "#bdbdbd"
      },
      primary: {
        light: '#eeeeee',
        main: '#bdbdbd',
        dark: '#4A4A4A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#1BB5FF',
        main: '#0033AB',
        dark: '#00A5F4',
        contrastText: '#fff',
      },
      toggleblue:{
        main: '#1BB5FF',
        //main: '#0033AB',
        //main: '#00A5F4',
      },
      green:{
        main: '#40E6C3',
      },
      blue:{
        main: '#1BB5FF',
        dark: '#0033AB',
      },
      darkblue:{
        light: '#3498db',
        main: '#2c3e50',
        dark: '#1B3138',
        contrastText: '#fff',
      },
      accentblue:{
        main: '#13C5DB',
      },
      black:{
        main: "#000000",
        contrastText: '#fff',
      },
      //error: {
      //  main: '#e4002b', // color for critically low
      //  dark: '#ff5858', // color for comm errors
      //  none: '#FE8484', // no status
      //},
      error: {
        dark: '#e4002b', // color for critically low
        main: '#ff5858', // color for comm errors
        none: '#FE8484', // no status
      },
      warning: {
        main: '#e87722', // color for low level
      },
      //info: {
      //},
      success: {
        main: '#319b42', // color for OK
      },
      grey: {
        light: '#eeeeee',
        main: '#bdbdbd',
        dark: '#4A4A4A',
        contrastText: '#fff'
      },
      darkGrey: {
        light: '#bdbdbd',
        main: '#4A4A4A',
        dark: '#212121',
        contrastText: '#fff'
      },
      grey52: {
        main: '#526269',
      },
      yellow: {
        main: '#F4A95F',
      },
      red: {
        light: '#ff5858',
        main: '#f00',
        dark: '#955454',
        darkest: '#7c4545',
        dark15: '#6c2020',
        dark10: '#591919',
        dark5: '#3f0f0f'
      },
      cardBackground: {
        main: '#2E3D43',
      },
      chipBackground: {
        main: '#3D5157',
      },

    },
  });

export default Theme;
/*
<color name="colorPrimary">#2c3e50</color>  darkBlue main
  <color name="colorPrimaryDark">#1B3138</color> darBlue dark
  <color name="colorAccent">#3498db</color> darkBlue light
  <color name="GracoWhite">#fff</color>
  <color name="GracoWhite_10">#1AFFFFFF</color>
  <color name="GracoWhite_30">#4CFFFFFF</color>
  <color name="GracoWhite_40">#66FFFFFF</color>
  <color name="GracoWhite_50">#80FFFFFF</color>
  <color name="GracoWhite_60">#99FFFFFF</color>
  <color name="GracoWhite_80">#CCFFFFFF</color>
  <color name="GracoRed">#f00</color>
  <color name="GracoLighterGray">#eeeeee</color>
  <color name="GracoMiddleGray">#bdbdbd</color>
  <color name="GracoDarkGray">#4A4A4A</color>
  <color name="GracoDarkestGray">#212121</color>
  <color name="GracoTextGray">#9e9e9e</color>
  <color name="GracoYellow">#CCF4A95F</color>
  <color name="GracoGray_52">#526269</color>
  <color name="GracoLightGray_ripple">#b3bdcf</color>
  <color name="GracoLightBlack">#99000000</color>
  <color name="GracoDarkBlack">#000000</color>
  <color name="GracoFullRed">#ff5858</color>
  <color name="GracoFullRed_10">#1AFF5858</color>
  <color name="GracoFullRed_20">#33FF5858</color>
  <color name="GracoTextRed">#FE8484</color>
  <color name="GracoBlue">#0033AB</color>  Secondary Main  This is pretty dark
  <color name="GracoLightBlue">#00A5F4</color Secondary Light
>
  <color name="GracoDarkerBlue">#1BB5FF</color> This is lighter than light blue
  <color name="LabelText">#de000000</color>
  <color name="EditTextBorder">#1f000000</color>
  <color name="GracoLoginError">#FFD32F2F</color>
  <color name="GracoDarkPurple">#051c23</color>  This is a grey.
  <color name="GracoDarkPurple_90">#E6051c23</color>
  <color name="GracoActionSheetBackground">#FF384F59</color>
  <color name="GracoLightGray">#e1e1e1</color>
  <color name="GracoLightGray_16">#28E1E1E1</color>
  <color name="GracoLightGray_20">#33E1E1E1</color>
  <color name="GracoCard">#FF2E3D43</color>
  <color name="GracoButtonNotSelected">#202F37</color>
  <color name="SwitchGray">#627881</color>
  <color name="Turquoise">#A2E1FF</color>  This is more of a light blue
  <color name="GracoBlueSelected">#13C5DB</color>  This is more of a turquoise
  <color name="GracoLightPurple">#273C42</color>  This is a grey
  <color name="new_device_separator">#35374a4f</color>
  <color name="chip_background">#3D5157</color>
  */