import React, { useEffect, useState } from 'react';
import useSessionstorage from '@rooks/use-sessionstorage';
import { Link as RouterLink } from 'react-router-dom';
import {
    Breadcrumbs,
    Grid,
    IconButton,
    Link,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { enableBilling } from '../../data';
import Billing from './components/Billing';

import { GrowDiv } from '../../styledComponentsCommon';


export default function AccountBilling({orgInfo}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { value: accountInfo } = useSessionstorage('accountInfo', {});
    
    return (
        <>
        {<Grid container item direction='column'>
            <Toolbar sx={{height: '64px'}}>
                <Tooltip title={t("goHome")}>
                    <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                        <ArrowBack />
                    </IconButton>
                </Tooltip>
                <Typography variant='h5'
                    color='textPrimary'>
                    {t("accountBilling")}
                </Typography>
            </Toolbar>
            <Grid item xs>
            {enableBilling ? <Billing orgInfo={orgInfo}/> 
                :<Typography>Under construction.</Typography>}
            </Grid>
        </Grid>}
        </>
    );
}