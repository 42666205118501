import React, { useState, useRef } from 'react';

import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    InputLabel,
    Grid,
    IconButton,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ThemeContext } from '@emotion/react';
import DataEntryListItemHeader from '../../../../CommonComponents/DataEntryListItemHeader';

const EventsFilter = ({ filtTypes, currentLookbackDays=14, updateTypesFilter=()=>{}, updateLookback=()=>{}}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [openFilterDialogue, setOpenFilterDialogue] = useState(false);
    const [localFilter, setLocalFilter] = useState(filtTypes);
    const [typeChecked, setTypeChecked] = React.useState([1]);
    const [lookbackDays, setLookbackDays] = React.useState(14);
    
    const eventTypes = [
        {key: 0, displayName: 'eventTypeEvent', name: 'event'},
        {key: 1, displayName: 'eventTypeAlarm', name: 'alarm'}, 
        {key: 2, displayName: 'eventTypeAlert', name: 'alert'},
        {key: 3, displayName: 'eventTypeInfo', name: 'systemInfo'},
    ];

    const lookbackOptions = [
        {key: 14, displayName: 'eventLookback14Days', name: 'eventLookback14'},
        {key: 30, displayName: 'eventLookback30Days', name: 'eventLookback30'},
        {key: 60, displayName: 'eventLookback60Days', name: 'eventLookback60'},
        {key: 90, displayName: 'eventLookback90Days', name: 'eventLookback90'},
    ]

    const handle_openFilter = () => {
        let lChecked = [];
        setOpenFilterDialogue(true);
        setLocalFilter(filtTypes);
        setLookbackDays(currentLookbackDays);
        eventTypes.forEach(etype => {
            if (filtTypes.includes(etype.name)){
                lChecked.push(etype.key);
            }
        })
        setTypeChecked(lChecked);
    };

    const handle_closeFilter = () => {
        setOpenFilterDialogue(false);
    };

    const handleFilter = () => {
        var newFilter = [];
        eventTypes.forEach(etype => {
            if (typeChecked.includes(etype.key)){
                newFilter.push(etype.name);
            }
        })
        
        updateTypesFilter(newFilter);
        updateLookback(lookbackDays);
        handle_closeFilter();
    };

    const handleTypeToggle = (value) => () => {
        const currentIndex = typeChecked.indexOf(value);
        const newChecked = [...typeChecked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setTypeChecked(newChecked);
      };
    
    const handleSelectLookback = (event) => {
        let temp = event.target.value;
        setLookbackDays(temp);
    };

    return(
    <>
        <Tooltip title={t("filterButton")}>
            <IconButton sx={{color: theme.palette.secondary.light}} onClick={handle_openFilter}>
                <FilterListIcon />
            </IconButton>
        </Tooltip>
        {openFilterDialogue && <Dialog
            fullWidth={false}
            maxWidth={'xs'}
            disableEscapeKeyDown
            open={openFilterDialogue}
            onClose={handle_closeFilter}>
            <DialogTitle>{t("eventFilter")}</DialogTitle>
            <DialogContent >
                <List>
                    <DataEntryListItemHeader label={"eventFilterTypes"}/>
                    {eventTypes.map((value) => {
                        const labelId = `checkbox-list-secondary-label-${value.key}`;
                        return (
                        <ListItem
                            key={value.key}
                            secondaryAction={
                            <Checkbox
                                edge="end"
                                onChange={handleTypeToggle(value.key)}
                                checked={typeChecked.indexOf(value.key) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                            }
                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemText id={labelId} primary={t(value.displayName)} />
                            </ListItemButton>
                        </ListItem>
                        );
                    })}
                    <DataEntryListItemHeader label={"eventFilterLookback"}/>
                    <ListItem>
                        <Select
                            size="small"
                            sx={{
                                marginRight: theme.spacing(1),

                            }}
                            value={lookbackDays}
                            onChange={handleSelectLookback}
                            >
                            {lookbackOptions.map(lookback => {
                                return(
                                    <MenuItem value={lookback.key}>
                                        <ListItemText>{lookback.key}</ListItemText>
                                    </MenuItem> 
                                );  
                            })} 
                        </Select>
                            <InputLabel id="Select Type" >{t("days")}</InputLabel>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handle_closeFilter} color="primary">
                    {t("cancel")}
                </Button>
                <Button
                    variant="contained" onClick={handleFilter} color="secondary">
                    {t("filter")}
                </Button>
            </DialogActions>
        </Dialog>}
    </>
    );
};

export default EventsFilter;