import React, { useEffect, useState } from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useParams } from 'react-router-dom';
import {
    Box,
    Grid,
    Typography,
    Tab,
    Input,
    FormControl,
    InputLabel,
    Button,
    IconButton,
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';

import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { saveAs } from 'file-saver';
import { useScript } from '../../../hooks/useScript';
import config from '../../../auth-config';
import { EditButton, WidgetTemplate, TableTemplate } from './';
import { billingConfig } from './billingConfig';
import { BillingForm } from './BillingForm';
import { isValidEmail } from '../../../helpers/isValidEmail';
import { billing } from '../../../services/billing';
import { base64ToArrayBuffer } from '../../../helpers/base64ToArrayBuffer';
import { BillingCurrency, BillingPlans, countries } from '../../../data';
import ModalPrompt from '../../../CommonComponents/ModalPrompt';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

const { recurlyData } = config;
const { futureChargesTable, invoicesTable } = billingConfig;


const Billing = ({ orgInfo }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const scriptStatus = useScript(recurlyData.url);
    const [tabValue, setTabValue] = useState("1")
    const [isVisibleEditBilling, setVisibleEditBilling] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [billingData, setBillingData] = useState({
        billing_info: {
            city: '',
            country: '',
            exp_month: '',
            exp_year: '',
            first_name: '',
            last_name: '',
            first_six: '',
            last_four: '',
            street1: '',
            street2: '',
            region: '',
            phone: '',
            postal_code: '',
        },
        email: '',
        subscriptions: [],
        invoices: [],
        cc_emails: [],
    });
    const [orgBilling, setOrgBilling] = useState({
        billing_curr: BillingCurrency[0],
        billing_plan: BillingPlans[0].plan_code
    });
    const { orgName } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const {
        subscriptions,
        invoices,
        cc_emails,
        billing_info,
        email,
    } = billingData;

    const {
        city,
        country,
        exp_month,
        exp_year,
        first_name,
        last_name,
        first_six,
        last_four,
        street1,
        street2,
        region,
        phone,
        postal_code,
    } = billing_info;
    
    const test = false;

    const handleTabChange = (event, newTabIndex) => {
        setTabValue(newTabIndex);
    };
    

    const handleSetBilling = (input_billing) => {
        var i = 0;
        var l_billingInfo = input_billing;

        for (i = 0; i < l_billingInfo.invoices.length; i++) {
            l_billingInfo.invoices[i].total_display = l_billingInfo.invoices[i].total.toFixed(2).toString() + ' ' + l_billingInfo.invoices[i].currency;
        }
        for (i = 0; i < l_billingInfo.subscriptions.length; i++) {
            l_billingInfo.subscriptions[i].estimated_total_display = l_billingInfo.subscriptions[i].estimated_total.toFixed(2).toString() + " " + l_billingInfo.subscriptions[i].currency;
        }

        setBillingData(l_billingInfo);
    };

    useEffect(() => {
        (async () => {
            try {
                const { status, data } = await billing.getBillingData(
                    orgInfo.accountId
                );

                const { error, billing_account, org_billing } = data;
                if (status === 404) {
                    setVisibleEditBilling(true);
                    return setIsLoading(false);
                }

                if (error !== 'OK' && status !== 200) {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }

                handleSetBilling(billing_account);
                setOrgBilling(org_billing);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                if (test){
                    setIsLoading(false);
                    setVisibleEditBilling(true);
                }
            }
        })();
    }, [orgInfo.accountId, test]);

    const handleEditClick = () => setVisibleEditBilling(true);

    const handleHideClick = () => setVisibleEditBilling(false);

    const handleSetNewEmail = ({ target }) => setNewEmail(target.value);

    const handleSubmitNewEmail = async (e) => {
        try {
            e.preventDefault();
            if (newEmail && !isValidEmail(newEmail)) {
                return alert(t("youHaveEnteredAnInvalidEmail"));
            }

            const {
                status,
                data,
            } = await billing.updateEmails(orgInfo.accountId, [
                ...billingData.cc_emails,
                newEmail,
            ]);

            const { error, billing_account } = data;

            if (error !== 'OK' && (status !== 200 || status !== 404)) {
                alert(error);
                throw new Error(error);
            }

            handleSetBilling(billing_account);
            setIsLoading(false);
            setNewEmail('');
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteEmail = async ({ currentTarget }) => {
        try {
            const email = currentTarget.getAttribute('data-email');

            const { status, data } = await billing.updateEmails(
                orgInfo.accountId,
                billingData.cc_emails.filter((el) => el !== email)
            );

            const { error, billing_account } = data;

            if (error !== 'OK' && (status !== 200 || status !== 404)) {
                throw new Error(error);
            }

            handleSetBilling(billing_account);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    };

    const handleDeleteAccount = () => {
        setOpenDeleteConfirm(true);
    };
    const handleCloseDeleteConfirm = () => {
        setOpenDeleteConfirm(false);
    };

    const deleteAccount = async () => {
        try {
            const { status, data } = await billing.deleteAccount(orgInfo.accountId);
            const { error } = data;
            if (error !== 'OK' && (status !== 200 || status !== 404)) {
                throw new Error(error);
            }
            setBillingData({
                billing_info: {
                    city: '',
                    country: '',
                    exp_month: '',
                    exp_year: '',
                    first_name: '',
                    last_name: '',
                    first_six: '',
                    last_four: '',
                    street1: '',
                    street2: '',
                    region: '',
                    phone: '',
                    postal_code: '',
                },
                email: '',
                subscriptions: [],
                invoices: [],
                cc_emails: [],
            });
            alert(t("contactGracoCustomerService"));
        } catch (error) {
            console.error(error);
            alert(error);
        }

    };

    const handleDownloadInvoice = async ({ currentTarget }) => {
        try {
            const invoiceId = currentTarget.getAttribute('data-id');

            const { status, data } = await billing.downloadInvoice(
                orgInfo.accountId,
                invoiceId
            );

            if (status !== 200) {
                throw new Error(t("errorWithDownloading"));
            }

            const pdfFile = new Blob([base64ToArrayBuffer(data)], {
                type: 'application/pdf',
            });

            saveAs(pdfFile, t("invoice") + '_' + invoiceId + '.pdf');
        } catch (error) {
            console.error(error);
            alert(error);
        }
    };

    
    
    function findRegionTranslationKey(country_in, region_in){
        var regionTranlsationKey = undefined;
        const country_item = countries.find(({countryShortCode})=> countryShortCode === country_in );
        var i = 0;
        try{
            for (i=0; i<country_item.regions.length; i++){
                if (country_item.regions[i].shortCode === region_in){
                    regionTranlsationKey = country_item.regions[i].translationKey;
                    break;
                }
            }
        }
        catch (error) {
            //console.error(error);
        }
        return regionTranlsationKey;
    }

    return (
        <>
            {openDeleteConfirm && <ModalPrompt title={t("deleteBillingAccount")}
                content={t("areYouSure")}
                action={t("delete")}
                handleAction={deleteAccount}
                handleCancel={handleCloseDeleteConfirm}
                openModal={openDeleteConfirm} />}
            <Typography sx={{textTransform: 'capitalize', margin: theme.spacing(1), paddingLeft: theme.spacing(1),}} variant="h5">
                {orgName}
            </Typography>
            {isLoading && <Grid item xs container
                      direction="row"
                      justifyContent="center"
                      alignItems="center" >
                    <CircularProgress sx={{margin: theme.spacing(4)}}/>
                  </Grid>}
            {!isLoading && (
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, 
                        borderColor: "divider" }}>
                        <TabList
                            onChange={handleTabChange}
                            justifyContent="center"
                            alignItems="center" 
                            >
                            <Tab label={t("billingSubscriptionsView")} value="1" />
                            <Tab label={t("billingInvormationView")} value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: 1 }}> 
                        <Box>
                            <TableTemplate
                                title={futureChargesTable.title}
                                subtitle={futureChargesTable.subtitle}
                                columnTitles={futureChargesTable.columnTitles}
                                rows={subscriptions}
                            />
                            <TableTemplate
                                title={invoicesTable.title}
                                columnTitles={invoicesTable.columnTitles}
                                rows={invoices}
                                handleCellClick={handleDownloadInvoice}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: 1 }}> 
                        {!isVisibleEditBilling ? (
                            <>
                                <WidgetTemplate
                                    title={
                                        <>
                                            {t("billingInformation")}
                                            {orgInfo.isAccountOwner && (
                                                <EditButton
                                                    clickHandler={
                                                        handleEditClick
                                                    }
                                                    disabled={
                                                        isVisibleEditBilling
                                                    }
                                                />
                                            )}
                                        </>
                                    }>
                                    <p>
                                        {t("creditCard")}: {first_six?.slice(0, 4)}{' '}
                                        {first_six?.slice(4, 6)}.. ....{' '}
                                        {last_four}
                                    </p>
                                    <p>{t("expiration")}: {exp_month + '/' + exp_year}</p>
                                    <p>{t("name") + ": "}{first_name + ' ' + last_name}</p>
                                    <p> {t("preferredCurrency")}: {orgBilling.billing_curr}</p>
                                    <p> {t("billingSubscription")}: {t(orgBilling.billing_plan)}</p>
                                </WidgetTemplate>
                                <WidgetTemplate title={t("billingAddress")}>
                                    <p>{t("email") + ": "}{email}</p>
                                    <p>{t("addressLine1") + ": "}{street1}</p>
                                    <p>{t("addressLine2") + ": "}{street2}</p>
                                    <p>{t("city") + ": "}{city}</p>
                                    <p>{t("country") + ": "}{country}</p>
                                    <p>{t("stateProvinceRegion") + ": "}{t(findRegionTranslationKey(country, region))}</p>
                                    <p>{t("zipCode") + ": "}{postal_code}</p>
                                    <p>{t("billingPhone") + ": "}{phone}</p>
                                </WidgetTemplate>
                                <WidgetTemplate title={t('billingCopyList')}>
                                    <form onSubmit={handleSubmitNewEmail}>
                                        {orgInfo.isAccountOwner && (
                                            <FormControl
                                                size="small"
                                                error={
                                                    newEmail &&
                                                    !isValidEmail(newEmail)
                                                }
                                                sx={{
                                                    position: 'relative',
                                                    minWidth: 120,
                                                    width: '80%',
                                                    marginBottom: theme.spacing(3),
                                                    '& .MuiInputLabel-asterisk': {
                                                        display: 'none',
                                                    },
                                                }}
                                                required>
                                                <InputLabel id="add-email">
                                                    {t("email")}
                                                </InputLabel>
                                                <Input
                                                    type="email"
                                                    labelId="add-email"
                                                    value={newEmail}
                                                    onChange={handleSetNewEmail}
                                                />
                                                <Button
                                                    type="submit"
                                                    sx={{
                                                        position: 'absolute',
                                                        right: '0',
                                                        top: '50%',
                                                        width: '20%',
                                                        transform: 'translate(105%,-33%)',
                                                    }}>
                                                    {"+ " + t("add")}
                                                </Button>
                                            </FormControl>
                                        )}
                                    </form>
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        {!!cc_emails.length &&
                                            cc_emails.map((email) => (
                                                <FormControl
                                                    key={email.slice(0, 4)}
                                                    size="small"
                                                    sx={{
                                                        position: 'relative',
                                                        minWidth: 120,
                                                        width: '80%',
                                                        fontSize: '1rem',
                                                        marginBottom: theme.spacing(2),
                                                    }}>
                                                    <Typography variant="subtitle1">
                                                        {email}
                                                    </Typography>
                                                    {orgInfo.isAccountOwner && (
                                                        <IconButton
                                                            size="small"
                                                            type="submit"
                                                            color="primary"
                                                            data-email={email}
                                                            sx={{
                                                                position: 'absolute',
                                                                right: '0',
                                                                top: '50%',
                                                                transform: 'translate(150%,-55%)',
                                                            }}
                                                            onClick={
                                                                handleDeleteEmail
                                                            }>
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    )}
                                                </FormControl>
                                            ))}
                                    </form>
                                </WidgetTemplate>
                                {billingData.email !== "" && billingData.subscriptions.length === 0 && <Button variant="outlined" onClick={handleDeleteAccount}>{t("deleteAccount_caps")}</Button>}
                            </>
                        ) : (
                            <>
                                {scriptStatus && (
                                    <>
                                        <Typography
                                            sx={{
                                                position: 'relative',
                                                paddingLeft: theme.spacing(2),
                                                paddingBottom: theme.spacing(1),
                                                fontSize: '1rem',
                                                fontWeight: 600,
                                            }}
                                            variant="h6">
                                            {t("billingInformation")}
                                        </Typography>
                                        <RecurlyProvider
                                            publicKey={recurlyData.key}>
                                            <Elements>
                                                <BillingForm
                                                    handleHideEditClick={
                                                        handleHideClick
                                                    }
                                                    setBillingData={
                                                        handleSetBilling
                                                    }
                                                    setVisibleEditBilling={
                                                        setVisibleEditBilling
                                                    }
                                                    setOrgBilling={setOrgBilling}
                                                    isData={!!billingData.billing_info.first_six}
                                                    hasSubscription={billingData.subscriptions.length > 0 ? true : false}
                                                    username={orgInfo.accountName + ":" + orgInfo.accountId}
                                                />
                                            </Elements>
                                        </RecurlyProvider>
                                    </>
                                )}

                            </>
                        )}
                    </TabPanel>
                </TabContext>
            )}
        </>
    );
};

export default Billing;