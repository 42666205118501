
import React, { useRef, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSessionstorage from '@rooks/use-sessionstorage';
import {
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Grid,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import {useTheme} from '@emotion/react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { countries } from "../../data";
import { sanitizeObjectName_40char } from "../../helpers/sanitizeObjectName";

import { accounts } from '../../services/accounts';

const findDefaultCountryByName = (countries, country_name) =>
{
    return countries.find(country => country.countryName === country_name)
}
const default_country_name = "United States";

export default function AccountCreate(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [parameters, setParameters] = useState ({
        accountName: '',
        accountAddressLine: '',
        accountCity: '',
        accountPostal: '',
        accountRegion: '',
        accountCountry: '',
        accountPhone: '',
        accountEmail: '',
        accountContactName: '',
    })
    
    const [accountName, setAccountName] = useState({ name: '' });
    const account_name = useRef("");
    const [country, setCountry] = useState(findDefaultCountryByName(countries, default_country_name).countryName);
    const [region, setRegion] = useState("");
    const [defCheck, setDefCheck] = useState(false);
    const { value: accountInfo } = useSessionstorage('accountInfo', {});

    console.log(parameters);
    console.log(country);
    const handleCheck = (event) => {
        setDefCheck(event.target.checked);
    };

    const fetchAccountInfo = async () => {
        let local_parameters = parameters;
        if (props.accountEdit) {
            local_parameters.accountName = accountInfo.accountName;
            try {
                const { status, error, accountData } = await accounts.getAccountInfo(
                    accountInfo.accountId
                );

                if (status === 404) {
                    return;
                }

                if (error !== 'OK' && status !== 200) {
                    if (error.includes("unable to connect to database")){
                        alert(t("failedToConnectToDatabase"));
                    }
                    //alert(t(error));
                    else {
                        alert(t("sorryUnableToRetrieve"));
                        throw new Error(error);
                      }
                    throw new Error(error);
                }
                if (accountData.default_account) {
                    setDefCheck(true);
                }
                local_parameters.accountAddressLine = accountData.address;
                local_parameters.accountCity = accountData.city;
                local_parameters.accountPostal = accountData.postal;
                local_parameters.accountCountry = accountData.country;
                local_parameters.accountRegion = accountData.state;
                local_parameters.accountContactName = accountData.contact_name;
                local_parameters.accountPhone = accountData.contact_phone;
                local_parameters.accountEmail = accountData.contact_email;
                setParameters(local_parameters);
                setAccountName({name: local_parameters.accountName});
                setCountry(accountData.country);
                setRegion(accountData.state);
            } catch (error) {
                console.error(error);
            }
        }
    };
    React.useEffect(() => {
        fetchAccountInfo();
    }, [props.accountEdit]);

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleChangeRegion = (event) => {
        setRegion(event.target.value);
    };

    function validateForm() {
        let testOptional = false;
        var ret = 1;

        if (handleErrorCheckCountry()) {
            console.log("country is empty");
            alert(t("countryInvalid"));
            ret = 0;
        }

        if (handleErrorCheckRegion()) {
            alert(t("stateRegionInvalid"));
            ret = 0;
        }

        if (handleErrorCheckAccountName()){
            alert(t("accountNameInvalid"));
            ret = 0;
        }

        if (testOptional && parameters.accountName === ""){
            alert(t("accountNameInvalid"));
            ret = 0;
        }
        if (testOptional && parameters.accountContactName === "") {
            alert("Contact Invalid");
            ret = 0;
        }

        if (testOptional && parameters.accountAddressLine === "") {
            alert(t("addressInvalid"));
            ret = 0;
        }

        if (testOptional && parameters.accountCity === "") {
            alert(t("cityInvalid"));
            ret = 0;
        }

        if (testOptional && parameters.accountPostal === "") {
            alert("Zip/Postal Code Invalid");
            ret = 0;
        }

        return ret;
    };

    const handleClick = async () => {
        if (validateForm() === 1) {
            try {
                var isDef = 0;
                    if (defCheck) {
                        isDef = 1;
                    }
                var temp_account_name = accountName.name;
                var body = 
                    {
                        "Name": accountName.name,
                        "ContactName": parameters.accountContactName,
                        "Email": parameters.accountEmail,
                        "ContactPhone": parameters.accountPhone,
                        "AddressLine1": parameters.accountAddressLine,
                        "Country": country,
                        "City": parameters.accountCity,
                        "State": region,
                        "PostalCode": parameters.accountPostal,
                        "isDefault": isDef
                    }
                if (!props.accountEdit) {
                    try {
                        const { status, error, accountDetails } = await accounts.createAccount(
                            body.Name,
                            body.ContactName,
                            body.Email,
                            body.ContactPhone,
                            body.AddressLine1,
                            body.Country,
                            body.City,
                            body.State,
                            body.PostalCode,
                            body.isDefault
                        );
        
                        
        
                        if (status === 404) {
                            return;
                        }
        
                        if (error !== 'OK' && status !== 200) {
                            if(error.includes("unable to connect to database")){
                                alert(t("failedToConnectToDatabase"));
                            }
                            else if(error.includes("Account name already exists")){
                                alert(t("accountNameAlreadyExists"))
                            }
                            //alert(t(error));
                            else {
                                alert(t("sorryUnableToRetrieve"));
                                throw new Error(error);
                              }
                            throw new Error(error);
                        }
        
                        props.fetchAccountInformation(temp_account_name, true); //Fetch information and navigate
                    } catch (error) {
                        console.error(error);
                    }
                    
                } else {
                    try {
                        const { status, error, accountDetails } = await accounts.updateAccount(
                            accountInfo.accountId,
                            body.Name,
                            body.ContactName,
                            body.Email,
                            body.ContactPhone,
                            body.AddressLine1,
                            body.Country,
                            body.City,
                            body.State,
                            body.PostalCode,
                            body.isDefault  
                        );
        
                        if (status === 404) {
                            return;
                        }
        
                        if (error !== 'OK' && status !== 200) {
                            if(error.includes("Unable to connect to database")){
                                alert(t("failedToConnectToDatabase"))
                            }
                            else if(error.includes("Access Denied, no permissions to modify")){
                                alert(t("accessDeniedNoPermissions"));
                            }
                            else if(error.includes("Account name already exists")){
                                alert(t("accountNameAlreadyExists"))
                            }
                            //alert(t(error));
                            else {
                                alert(t("sorryUnableToRetrieve"));
                                throw new Error(error);
                            }
                            throw new Error(error);
                        }
                        props.fetchAccountInformation(temp_account_name, true); //navigate after fetching information
                        
                    } catch (error) {
                        console.error(error);
                    }
                }
            } catch (e) {
                console.log(e);
            };
        }
    };

    const handleErrorCheckCountry = () => {
        let inError = false;
        if (country === "")
        {
            inError = true;
        }
        return inError;
    };

    const handleErrorCheckRegion = () => {
        let inError = false;
        if (region === "")
        {
            inError = true;
        }
        return inError;
    };

    const handleErrorCheckAccountName = () => {
        let inError = false;
        if(!sanitizeObjectName_40char(accountName.name)){
            inError = true;
        }
        if(accountName.name === ""){
            inError = true;
        }
        return inError;
    }

    return (
        <Grid
            container
            alignItems='stretch'
            direction='column'
            justifyContent='flex-start'
            sx={{
                padding: theme.spacing(2),
            }}>
            
            <Grid item xs>
                <Toolbar>
                    <Button color='primary' component={RouterLink} to='/'>
                        {t("cancel")}
                    </Button>
                    {!props.accountEdit ?
                        <Typography align='center' sx={{margin: theme.spacing(1), flex: 1}}>
                            {t("createAccount")}
                        </Typography>
                        : <Typography align='center' sx={{margin: theme.spacing(1), flex: 1}}>
                            {t("editAccount")}
                        </Typography>}
                    <Button variant="contained" color="secondary" onClick={handleClick} 
                        disabled={handleErrorCheckCountry() || handleErrorCheckRegion() || handleErrorCheckAccountName()}>
                        {t("save")}
                    </Button>
                </Toolbar>
            </Grid>

            <Grid item xs container
                alignItems='stretch'
                direction='column'
                justifyContent='flex-start'
                >
                <Grid item xs>
                    <FormGroup>
                        <FormControl
                            sx={{
                                margin:theme.spacing(2)
                            }}>
                            <TextField id='account-name'
                                onChange={(e) => {
                                    if (sanitizeObjectName_40char(e.target.value)){
                                        setAccountName({ name: e.target.value });
                                    }
                                }}
                                value={accountName.name}
                                inputRef={account_name}
                                label={t("accountName")}
                                variant='filled'
                                error={handleErrorCheckAccountName()}
                             />
                        </FormControl>
                        <FormControl 
                            error = {handleErrorCheckCountry()}
                            sx={{
                                margin:theme.spacing(2)
                            }}>
                            <InputLabel id="country-select-label">{t("country")}</InputLabel>
                            <Select
                                labelId="country-select-label"
                                id="country-select"
                                value={country}
                                label={t("country")}
                                onChange={handleChangeCountry} >
                                {countries.map((country) => (
                                    <MenuItem
                                        value={country.countryName}
                                        key={country.countryShortCode}
                                    >
                                        {t(country.translationKey)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl 
                            error = {country && handleErrorCheckRegion()}
                            sx={{
                                margin:theme.spacing(2)
                            }}>
                            <InputLabel id="region-select-label">{t("stateRegionInvalid")}</InputLabel>
                            <Select
                                labelId="region-select-label"
                                id="region-select"
                                value={region}
                                label={t("stateRegionInvalid")}
                                onChange={handleChangeRegion}
                                disabled={!country} >
                                {country
                                    ? countries
                                        .find(({ countryName }) => countryName === country)
                                        .regions.map((region) => (
                                            <MenuItem value={region.name} key={region.shortCode}>
                                                {t(region.translationKey)}
                                            </MenuItem>
                                        )) : []}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Grid> 
                <Grid item xs>
                    <form >
                        <FormGroup 
                            sx={{
                                marginLeft:theme.spacing(2),
                                marginBottom:theme.spacing(2)
                            }}>
                            {(!props.firstAccount) && <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={defCheck}
                                        onChange={handleCheck}
                                        name="defIndicator"
                                        color="primary"
                                        
                                    />
                                }
                                label={t("defaultAccount")}
                            />}
                        </FormGroup>
                    </form>
                </Grid>
            </Grid>

            <Grid item xs>
                <Button variant="contained" color="secondary" onClick={handleClick}
                    disabled={handleErrorCheckCountry() || handleErrorCheckRegion() || handleErrorCheckAccountName()}>
                    {t("save")}
                </Button>

                <Button color='primary' component={RouterLink} to='/'>
                    {t("cancel")}
                </Button>
            </Grid>
        </Grid>
    );
}