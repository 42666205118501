import React, { useState, useRef, useEffect } from 'react';
import Button from "@mui/material/Button";
import {
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Tooltip
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { PersonAdd } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {useTheme} from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { accounts } from '../../services/accounts';
import  { groups_inf } from '../../services/groups_inf';

const AccountInvite = ({ accountInfo, access, groups={}, inviteComplete=()=>{}, includeGroups=true}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // Create state for the invites
  const [inviteOpen, setInviteOpen] = useState(false);
  const [groupPermissionsList, setGroupPermissionsList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupInviteAccess, setGroupInviteAccess] = useState(false);
  const [groupWriteAccess, setGroupWriteAccess] = useState(false);
  const recipient = useRef("");
  const [processingRequest, setProcessingRequest] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if(inviteOpen){
        let group_list = []
        Object.keys(groups).forEach((key, index) => {
            if (groups[key].invite_access || accountInfo.isAccountAdmin) {
                group_list.push(groups[key]);
            }
        });

        setGroupPermissionsList(group_list);
    }
  },[inviteOpen, groups, accountInfo.isAccountAdmin]);

  const handleGroupSelect = (event) => {
    const { target: { value }, } = event;
    
    setSelectedGroups(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let disableWrite = selectedGroups.length ? false : true;
    Object.keys(groups).forEach((key, index) => {
      if (selectedGroups.indexOf(key) > -1){
        if (!groups[key].write_access && !accountInfo.isAccountAdmin) {
            disableWrite = false;
        }
      }
    });
    
    if (!disableWrite) {
      setGroupWriteAccess(false);
    }

  };

  const handleInviteOpen = () => {
    setProcessingRequest(false);
    setInviteOpen(true);
  };

  const handleInviteClose = () => {
    setInviteOpen(false);
  };

  // Functions to handle invitations
  const handleInvite = async (permissions) => {
    const l_recipient = recipient.current.value;
    const l_groupList = selectedGroups;
    const l_groupWriteAccess = groupWriteAccess;
    const l_groupInviteAccess = groupInviteAccess;
    setProcessingRequest(true);
    if (includeGroups){
      inviteUserGroups(l_recipient, l_groupList, l_groupWriteAccess, l_groupInviteAccess)
    }
    else {
      inviteUserAccount(l_recipient, permissions);
    }
  };

  const inviteUserGroups = async (recipient, groupList, writeAccess, inviteAccess) => {
    console.log("Invite to Groups");
    const l_recipient = recipient.replace(" ","").split(",");
    let localGroupList = [];
    for (var i=0; i < l_recipient.length; i++){
      for (let j=0; j < groupList.length; j++)
      {
        localGroupList.push(groups[groupList[j]].group_id);     
      }
      let group_id = localGroupList[0];
      try {
        //throw new Error("test - no call");
        const { status, data } = await groups_inf.inviteUser(
            accountInfo.accountId,
            group_id,
            l_recipient[i],
            writeAccess,
            inviteAccess,
            localGroupList
        );
        const { error } = data;

        if (status === 404) {
            return;
        }

        if (error !== 'OK' && status !== 200) {
            if (error.includes("User already has access to this account")){
                alert(t("userAlreadyHasAccess"));
            }
            else {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
        }
        //Any Action?
      } catch (error) {
          console.error(error);
      }
      // for (var j=0; j < groupList.length; j++){
      //   let group_id = groups[groupList[j]].group_id;
      //   try {
      //       //throw new Error("test - no call");
      //       const { status, data } = await groups_inf.inviteUser(
      //           accountInfo.accountId,
      //           group_id,
      //           l_recipient[i],
      //           writeAccess,
      //           inviteAccess
      //       );
      //       const { error } = data;

      //       if (status === 404) {
      //           return;
      //       }

      //       if (error !== 'OK' && status !== 200) {
      //           if (error.includes("User already has access to this account")){
      //               alert(t("userAlreadyHasAccess"));
      //           }
      //           else {
      //               alert(t("sorryUnableToRetrieve"));
      //               throw new Error(error);
      //           }
      //       }
      //       //Any Action?
      //   } catch (error) {
      //       console.error(error);
      //   }
      // }
    }
    setProcessingRequest(false);
    handleInviteClose();
    inviteComplete();
  }

  const inviteUserAccount = async (recipient, role) => {
    console.log("Invite to Account");
    const l_recipient = recipient.replace(" ","").split(",");
    
    for (var i=0; i < l_recipient.length; i++){
        try {
            //throw new Error("test - no call");
            const { status, data } = await accounts.inviteUser(
                accountInfo.accountId,
                l_recipient[i],
                role
            );
            
            const { error } = data;

            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("User already has access to this account")){
                    alert(t("userAlreadyHasAccess"));
                    throw new Error(error);
                }
                else if(error.includes("Unable to connect to database")){
                  alert(t("failedToConnectToDatabase"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                  alert(t("accessDeniedNoView"));
                }
                else if(error.includes("Access Denied, no permission to modify")){
                  alert(t("accessDeniedNoPermissions"));
                }
                else if(error.includes("access Denied, no permission to invite")){
                  alert(t("accessDeniedToInvite"));
                }
                else if(error.includes("User already assigned to this group")){
                  alert(t("userAlreadyAssignedToGroup"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            //Any Action?
        } catch (error) {
            
            console.error(error);
        }
    }
    setProcessingRequest(false);
    handleInviteClose();
    inviteComplete();
  }

  const handleGroupInviteAccessChange = (event) => {
    setGroupInviteAccess(event.target.checked);
  };

  const handleGroupWriteAccessChange = (event) => {
    setGroupWriteAccess(event.target.checked);
  };
  
  return (
    <>
      {(access) &&
          <Tooltip title={t('invite')}>
            <IconButton
                aria-haspopup='true'
                color='inherit'
                onClick={handleInviteOpen}
                size="large">
              <PersonAdd />
              <Typography sx={{marginLeft: '5px'}}>{t("invite")}</Typography>
            </IconButton>
          </Tooltip>
      }
      {inviteOpen && <Dialog open={inviteOpen} 
          onClose={handleInviteClose} 
          fullScreen={fullScreen}
          aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t("invite")}</DialogTitle>
        <DialogContent>
          <Grid container item xs 
              justifycontent='flex-start'
              alignItems='center'
              direction='column'>
              <Grid item xs>
                {processingRequest ? 
                <CircularProgress/>
                :<DialogContentText>
                  {t("toInviteAUser")}
                </DialogContentText>}
              </Grid>
            
              {includeGroups && <Grid item xs>
                <FormControl sx={{
                        margin: theme.spacing(1),
                        minWidth: 300,
                        marginBottom: 20
                    }}
                    disabled={processingRequest}>
                  <Stack spacing={2}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      inputRef={recipient}
                      label={t("emailAddress")}
                      type="email"
                      fullWidth
                      disabled={processingRequest} />
                    <Typography>{t('selectGroups')}</Typography>
                    <Select
                        labelId="groups-multiple-checkbox-label"
                        id="groups-multiple-checkbox"
                        multiple
                        value={selectedGroups}
                        onChange={handleGroupSelect}
                        renderValue={(selected) => selected.join(', ')}
                        >
                        {groupPermissionsList.map((grp) => (
                            <MenuItem key={grp.group_id.toString()} value={grp.name}>
                                <Checkbox checked={selectedGroups.indexOf(grp.name) > -1} />
                                <ListItemText primary={grp.name === "default" ? t('default'):grp.name} />
                            </MenuItem>
                        ))}
                    </Select>
                    {(selectedGroups.length > 0) && <>
                    <Typography>{t('groupPermissions')}</Typography>   
                    <MenuItem value={t("groupWriteAccess")}>
                        <Checkbox checked={groupWriteAccess} onChange={handleGroupWriteAccessChange} inputProps={{'aria-label': 'controlled'}} />
                        <ListItemText primary={t("groupWriteAccess")} />
                    </MenuItem>
                    <MenuItem value={t("groupInviteAccess")}>
                        <Checkbox checked={groupInviteAccess} onChange={handleGroupInviteAccessChange} inputProps={{'aria-label': 'controlled'}} />
                        <ListItemText primary={t("groupInviteAccess")} />
                    </MenuItem>
                    </>}
                    {fullScreen && includeGroups &&   
                          <Button   disabled={!selectedGroups.length || processingRequest} 
                                    onClick={() => handleInvite("user")} 
                                    variant="contained" 
                                    color="secondary">
                            {t("invite")}
                          </Button>}
                    {fullScreen && !includeGroups && 
                        (accountInfo.isAccountEditor) && <Button 
                              onClick={() => handleInvite("admin")} 
                              disabled={processingRequest || recipient.length === 0}
                              variant="contained" 
                              color="secondary">
                          {t("asAdmin")}
                        </Button>}
                    {fullScreen && !includeGroups && 
                        (accountInfo.isAccountEditor) && <Button 
                              onClick={() => handleInvite("tech")} 
                              disabled={processingRequest || recipient.length === 0}
                              variant="contained" 
                              color="secondary">
                          {t("asTech")}
                        </Button>}
                    {fullScreen && !includeGroups &&
                        <Button onClick={() => handleInvite("user")} 
                            disabled={processingRequest || recipient.length === 0}
                            variant="contained" 
                            color="secondary">
                          {t("asUser")}
                        </Button>}
                    {fullScreen && <Button onClick={handleInviteClose} color="primary">
                        {t("cancel")}
                    </Button>}
                    
                  </Stack>
                </FormControl>
              </Grid>}
            </Grid>
        </DialogContent>
        {!fullScreen && <DialogActions>
          <Button onClick={handleInviteClose} color="primary">
            {t("cancel")}
          </Button>
          {includeGroups ? 
          <>
            <Button   disabled={!selectedGroups.length || processingRequest} 
                      onClick={() => handleInvite("user")} 
                      variant="contained" 
                      color="secondary">
              {t("invite")}
            </Button>
          </>
          :<>
          {(accountInfo.isAccountEditor) && <Button onClick={() => handleInvite("admin")} variant="contained" color="secondary">
            {t("asAdmin")}
          </Button>}
          {(accountInfo.isAccountEditor) && <Button onClick={() => handleInvite("tech")} variant="contained" color="secondary">
            {t("asTech")}
          </Button>}
          {<Button onClick={() => handleInvite("user")} variant="contained" color="secondary">
            {t("asUser")}
          </Button>}
          </>}
          </DialogActions>}
      </Dialog>}
    </>
  );
};

export default AccountInvite;
