/* eslint-disable react-hooks/exhaustive-deps */
import {Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
    Box,
    Grid,
    IconButton,
    Tab,
    Toolbar,
    Tooltip,
    Typography,
  } from "@mui/material";

import {
    ArrowBack,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {useTheme} from '@emotion/react';
import UserInfoContent from './Components/UserInfoContent';
import AccountsTable from './Components/AccountsTable';
import { accounts } from '../services/accounts';
import { TabPanel, TabContext, TabList } from '@mui/lab';

export default function ProfilePage(props) {
    // const { t, i18n } = useTranslation();
    const { t } = useTranslation();
    const theme = useTheme();
    const [account, setAccount] = useState([]);
    const [value, setValue] = React.useState("1");
    // const { value: accountInfo } = useSessionstorage('accountInfo', {});
    const [accountInfo, setAccountInfo] = useState(props.accountInfo);
    const fetchUserData = async () =>{
        let user = await Auth.currentUserPoolUser();
    };

    const fetchAccounts = async (abortController={signal: undefined}) =>{
        try{
            const {status, error, userAccounts} = await accounts.getAccounts(abortController);
            
            if (error !== 'OK' && status !== 200) {
                if (error.includes("unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
            }
            else{
            filterData(userAccounts);
            }
        }
        catch(error){
            console.log(error);
        }
    };

    function userRole(Perms) {
        let editor = Perms[1];
        let owner = Perms[2];
        let tech = Perms[3];
        let role = ""

        if(!editor && !owner && !tech){
            role = 'user';
        }
        if(!editor && !owner && tech){
            role = 'tech';
        }
        if(editor && !owner && tech){
            role = 'admin';
        }
        if(editor && owner && tech){
            role = 'owner';
        }
        return role
    };

    function filterData(accounts){
        let i=0;
        let data=[];
        while(i< accounts.accountsList.length){
            let perm = accounts.accountsNavDict[accounts.accountsList[i]];
            let role = userRole(perm)
            data.push({account:accounts.accountsList[i], role:role, perm:perm, default:accounts.default});
            i++;
        }
        setAccount(data);
    }

    const handleTabChange = (event, newTabIndex) => {
        setValue(newTabIndex);
      };

    useEffect(() => {
        fetchUserData();
        const abortController = new AbortController();
        fetchAccounts(abortController);
        return () => abortController.abort();
    },[accountInfo])

    return (
        <>
        {<Grid container
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
        sx={{padding: theme.spacing(1),}}>
        <Grid item xs>
            <Toolbar>
                <Tooltip title={t("goHome")}>
                    <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                        <ArrowBack />
                    </IconButton>
                </Tooltip>
                <Typography variant='h5'
                    color='textPrimary'>
                    {t("userSettings")}
                </Typography>
            </Toolbar>
        </Grid>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, 
                borderColor: "divider" }}>
            <TabList
                onChange={handleTabChange}
                justifyContent="center"
                alignItems="center" 
                >
                <Tab label={t("userSettings")} value="1" />
                <Tab label={t("accountDetails")} value="2"/>
            </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0 }}> 
                <Grid container item direction='column'>
                    <UserInfoContent/>
                </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
            <Grid container item direction='column'>
                <Box id="AccountBox" sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                    <AccountsTable accountList={account} accountInfo={accountInfo} fetchAccounts={fetchAccounts}/>  
                </Box>
            </Grid>
            </TabPanel>
        </TabContext>
        </Grid>
        }
        </>
    )
    
}
