import { Image, View, Text, useTheme } from "@aws-amplify/ui-react";
import MyLogo from "../Header/assets/Trace_Brand_Footer.png";

export function AuthFooter() {
  const { tokens } = useTheme();

  return (
    <View
      as="footer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
        alt="logo"
        src={MyLogo}
        padding={tokens.space.zero}
      />
      <Text>&copy; All Rights Reserved</Text>
    </View>
  );
}
