import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    FormControl,
    Typography,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';
import { devices_inf } from "../../services/devices_inf";
import { DEVICE_TYPES } from '../../DeviceInfo/deviceTypes';
import { enableBilling, BillingPlans, SubscriptionInfo } from '../../data';
import { billing } from '../../services/billing';
import { accounts } from '../../services/accounts';

const AddDevice = ({ accountInfo, groups, updateDevices=()=>{}, isFAB=true, altTitle=false }) => {
    const theme = useTheme();
    let navigation = useNavigate();
    const { t } = useTranslation();
    const [deviceFamily, setDeviceFamily] = useState();
    const [deviceUID, setDeviceUID] = useState();
    const [targetUID, setTargetUID] = useState();
    const rdeviceUID = useRef();
    const [addDeviceDialogue, setAddDeviceDialogue] = useState(false);
    const [activateDeviceDialogue, setActivateDeviceDialogue] = useState(false);
    const [openEnterSerial, setOpenEnterSerial] = useState(false);
    const [groupPermissionsList, setGroupPermissionsList] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [targetSelectedGroups, setTargetSelectedGroups] = useState([]);    
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [hasBillingInfo, setHasBillingInfo] = useState(false);
    const [loadingBilling, setLoadingBilling] = useState(false);
    const [orgBilling, setOrgBilling] = useState({});
    const [billingData, setBillingData] = useState({});
    const [estCharge, setEstCharge] = useState("0.00");
    const [processingRequest, setProcessingRequest] = useState(false);

    useEffect(() => {
        if(openEnterSerial){
            let group_list = []
            Object.keys(groups).forEach((key, index) => {
                if (groups[key].write_access || accountInfo.isAccountTech) {
                    group_list.push(groups[key]);
                }
            });

            setGroupPermissionsList(group_list);
        }
      },[openEnterSerial, accountInfo.isAccountTech, groups]);

    const fetchAccountHasBilling = async (abortController={signal: undefined}) => {
        if(enableBilling)
        {
            try {
                const { status, error, accountHasBilling } = await accounts.getAccountHasBilling(
                    accountInfo.accountId,
                    abortController
                );
        
                if (status === 404) {
                    console.log(status);
                }
        
                if (error !== 'OK' && status !== 200) {
                    throw new Error(error);
                }
                
                if (accountHasBilling) {
                    setHasBillingInfo(true);
                }
                else {
                    setHasBillingInfo(false);
                }
            } catch (error) {
                console.log("unable to get billing info");
                if (!(error instanceof TypeError)){
                    console.error(error);
                }
            }
        }
        else 
        {
            setHasBillingInfo(true);
        }
        setLoadingBilling(false);
    }
    
    
    const fetchBillingInformation = async (abortController={signal: undefined}) => {
        try {
            const { status, data } = await billing.getBillingData(
                accountInfo.accountId,
                abortController
            );
    
            const { error, billing_account, org_billing } = data;
    
            if (status === 404) {
                // console.log(status);
            }
    
            if (error !== 'OK' && status !== 200) {
                throw new Error(error);
            }
            
            setBillingData(billing_account);
    
            // if (org_billing.billing_curr == null) {
            //     org_billing.billing_curr = BillingCurrency[0];
            // }
            // if (org_billing.billing_plan == null) {
            //     org_billing.billing_plan = BillingPlans[0].plan_code;
            // }
            var l_billingPlan = BillingPlans[0];
            
            for (var i = 0; i < BillingPlans.length; i++) {
                if (BillingPlans[i].label === org_billing.billing_plan) {
                    l_billingPlan = BillingPlans[i];
                    break;
                }
            }
            var newCharge = Math.round(l_billingPlan.unit_cost[org_billing.billing_curr] * 1 * 100) / 100;
            setEstCharge(newCharge.toString());
            
            setOrgBilling(org_billing);
            setLoadingBilling(false);
        } catch (error) {
            console.error(error);
            setLoadingBilling(true);
        }
      };


    const handleOpenAddDevice = async () => {
        setProcessingRequest(true);
        var tDeviceUID = "";
        if (deviceUID) {
            tDeviceUID = deviceUID.replace(/\b0+/g, "");
        }
        let group_id_list = [];
        for(let i = 0; i < selectedGroups.length; i++)
        {
            for (let j = 0; j < groupPermissionsList.length; j++)
            {
                if (selectedGroups[i] === groupPermissionsList[j].name)
                {
                    group_id_list.push(groupPermissionsList[j].group_id);
                    break;
                }
            }
        }
        setTargetSelectedGroups(group_id_list);
        try{
            const { status, error, device_info } = await devices_inf.getDevice(
                tDeviceUID,
                accountInfo.accountId                
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                    alert(t("accessDeniedNoView"));
                }
                else if(error.includes("Device not found")){
                    alert(t("deviceNotFound"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                if (device_info.account_id){
                    alert(t("deviceIsAlreadyAssigned"));
                    throw new Error(t("deviceIsAlreadyAssigned"))
                }
                setDeviceFamily(device_info.type);
                if (tDeviceUID !== "") {
                    handleCloseEnterSerial();
                    //future work Check for type/family
                    setAddDeviceDialogue(true);
                    setTargetUID(tDeviceUID.toUpperCase());
                }
                else {
                    setOpenEnterSerial(true);
                }
            }
        }catch (error) {
            console.error(error);
        }
        setProcessingRequest(false);
    };

    const handleCloseAddDevice = () => {

        try{
            
        } catch (error) {
            console.error(error);
        }
        setAddDeviceDialogue(false);
    };

    const handleOpenActivateDevice = () => {
        setProcessingRequest(false);
        setActivateDeviceDialogue(true);
        if (enableBilling){
            setLoadingBilling(true);
            fetchBillingInformation();
        }
    };
    const handleCloseActivateDevice = () => {
        setActivateDeviceDialogue(false);
        updateDevices();
    };
    const handleOpenEnterSerial = () => {
        setLoadingBilling(true);
        fetchAccountHasBilling();
        setProcessingRequest(false);
        setOpenEnterSerial(true);
    };
    const handleCloseEnterSerial = () => {
        setOpenEnterSerial(false);
        setDeviceUID("");
        setSelectedGroups([]);
    };

    const handleAddDevice = async () => {
        setProcessingRequest(true);
        var l_hasBillingInfo = enableBilling ? (hasBillingInfo ? 1 : 0) : 1;
        
        try{
            console.log("adding device");
            const { status, error } = await devices_inf.addDevices(
                [targetUID],
                accountInfo.accountId,
                targetSelectedGroups,
                l_hasBillingInfo
            );
            if (status === 404) {
                return;
            }
            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else if (error.includes("Device not found")){
                    alert(t("deviceNotFound"));
                    //throw new Error(error)
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            handleOpenActivateDevice();
        }catch (error) {
            console.error(error);
            updateDevices();
        }
        setProcessingRequest(false);
        setAddDeviceDialogue(false);
    };

    const handleUpdateDeviceStatus = async () => {
        setProcessingRequest(true);
        if (enableBilling){
            if (billingData.id)
            {
                const newQuantity = billingData.subscriptions.length > 0 ? billingData.subscriptions[0].quantity + 1 : 1;
                updateSubscription(newQuantity);
            }
            else
            {
                alert("billingInfoRequiredToAddDevices")
            }
            
            //updateSubscription(1);
        } else {
            handleActivateDevice();
        }
    }

    const handleActivateDevice = async () => {
        var l_hasBillingInfo = enableBilling ? (hasBillingInfo ? 1 : 0) : 1;

        setAddDeviceDialogue(false);
        try{
            console.log("activating device");
            const { status, error } = await devices_inf.activateDevices(
                [targetUID],
                accountInfo.accountId,
                l_hasBillingInfo
            );
            if (status === 404) {
                return;
            }
            if (error !== 'OK' && status !== 200) {
                throw new Error(error);
            }
        }catch (error) {
            console.error(error);
        }
        handleCloseActivateDevice();
    };
    
    const handleGroupSelect = (event) => {
        const { target: { value }, } = event;
        setSelectedGroups(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const updateSubscription = async (input_quantity) => {
        var msg = { status: 0, data: {} };
        var quantity = input_quantity;
        
        try {
            if (billingData.subscriptions.length === 0 && input_quantity === 0) {
                throw new Error("No subscription to delete");
            }
            else if (billingData.subscriptions.length === 0 && input_quantity > 0) {
                console.log("New Subscription");

                const plan_code = billing.findSubscriptionPlanCode(orgBilling.billing_plan);
                msg = await billing.newSubscription(
                    accountInfo.accountId,
                    plan_code,
                    orgBilling.billing_curr,
                    quantity,
                    SubscriptionInfo.total_billing_cycles);
            }
            else {
                console.log("Update Subscription");

                var sub = [{}];
                sub[0].quantity = quantity;
                sub[0].id = billingData.subscriptions[0].id;
                msg = await billing.updateSubscriptions(
                    accountInfo.accountId,
                    sub
                );
            }

            const { error, billing_account } = msg.data;

            if (msg.status === 404) {
                console.log(msg.status);
            }

            if (error !== 'OK' && msg.status !== 200) {
                if(error.includes("submitted values match the current subscriptions")){
                    //Still update the device status if the subscription value is correct.
                    handleActivateDevice();
                    throw new Error(error);
                }
                else{
                    alert(error);
                    throw new Error(error);
                }
            }

            setBillingData(billing_account);
            handleActivateDevice();
        } catch (error) {
            console.error(error);
            setProcessingRequest(false);
            //handleCloseStatusChange();
            //No longer prevent activation for billing errors.  Improve customer experience
            //handleActivateDevice();
        }
    };

    const handleNavigateToBilling = () => {
        navigation("/accounts/{accountName}/billing".replace("{accountName", accountInfo.accountName));
    }
      
    return <>
        {accountInfo.isAccountTech === 1 && (isFAB ?
                <Tooltip title={t("addDevice")}>
                    <Fab sx={{
                        position: "fixed",
                        bottom: (theme) => theme.spacing(2),
                        right: (theme) => theme.spacing(2)
                    }} 
                    color="secondary" aria-label="addD" onClick={handleOpenEnterSerial}>
                        <AddIcon />
                    </Fab> 
                </Tooltip>
            : 
            <Tooltip title={t("addDevice")}>
                <IconButton onClick={handleOpenEnterSerial}>
                    <AddIcon/>
                    <Typography sx={{marginLeft: '5px'}}>{altTitle ? t('device') : t("addDeviceButton")}</Typography>
                </IconButton>
            </Tooltip>
            )}
        
        {openEnterSerial && <Dialog
            disableEscapeKeyDown
            open={openEnterSerial}
            onClose={handleCloseEnterSerial}
            fullScreen={fullScreen}>
            <DialogTitle>{t("enterSerialNumber")}</DialogTitle>
            <DialogContent >
                {loadingBilling ? <Grid item container justifyContent="center" alignItems="center" minHeight="100px"> 
                            <CircularProgress/>
                        </Grid>
                : !hasBillingInfo && enableBilling ? 
                    <Stack spacing={2} justifyContent="center">
                        <Typography>
                            {t("addDevicesRequiresBillingInformation")}
                        </Typography>

                        {accountInfo.isAccountOwner ? 
                        <Button onClick={handleNavigateToBilling} variant="contained" color="secondary">
                            {t("goToBilling")}
                        </Button>
                        : <Typography>
                            {t("billingInformationContactOwner")}
                        </Typography>}

                        {fullScreen && 
                            <Button onClick={handleCloseEnterSerial} color="primary">
                                {t("cancel")}
                            </Button>
                        }
                    </Stack>
                : <Stack spacing={2}  sx={{marginTop: theme.spacing(1)}}>
                    {processingRequest ? 
                    <Stack direction="column"
                    justifyContent="center"
                    alignItems="center">
                        <CircularProgress />
                    </Stack>
                    :<FormControl sx={{
                            margin: theme.spacing(2),
                            minWidth: 300,
                            marginBottom: 20,
                        }}>
                            <TextField type="test" label={t("serialNumber")}
                                inputRef={rdeviceUID}
                                value={deviceUID}
                                onChange={(e) => {
                                    const regex = /^([ a-zA-Z0-9]){0,30}$/i;
                                    if (regex.test(e.target.value)) {
                                        setDeviceUID(e.target.value.toUpperCase());
                                    }
                                }
                                }
                                helperText={t("device")} />
                            <Typography>{t('selectGroups')}</Typography>
                            <Select
                                labelId="groups-multiple-checkbox-label"
                                id="groups-multiple-checkbox"
                                multiple
                                value={selectedGroups}
                                onChange={handleGroupSelect}
                                renderValue={(selected) => selected.join(', ')}
                                >
                                {groupPermissionsList.map((grp) => (
                                    <MenuItem key={grp.group_id.toString()} value={grp.name}>
                                        <Checkbox checked={selectedGroups.indexOf(grp.name) > -1} />
                                        <ListItemText primary={grp.name === "default" ? t('default'):grp.name} />
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>}
                        
                        {fullScreen && 
                            <Button variant="contained" 
                                    onClick={handleOpenAddDevice} 
                                    color="secondary" 
                                    disabled={!deviceUID || (selectedGroups.length === 0 ? true: false)}>
                                {t("add")}
                            </Button>
                        }
                        {fullScreen && 
                            <Button onClick={handleCloseEnterSerial} color="primary">
                                {t("cancel")}
                            </Button>
                        
                        }
                    </Stack>}
            </DialogContent>
            {!fullScreen && <DialogActions>
                <Button onClick={handleCloseEnterSerial} color="primary">
                    {t("cancel")}
                </Button>
                <Button variant="contained" 
                        onClick={handleOpenAddDevice} 
                        color="secondary" 
                        disabled={!deviceUID || (selectedGroups.length === 0 ? true: false)}>
                    {t("add")}
                </Button>
            </DialogActions>}
        </Dialog>}
        {addDeviceDialogue && <Dialog
            disableEscapeKeyDown
            open={addDeviceDialogue}
            onClose={handleCloseAddDevice}
            fullScreen={fullScreen}>
            <DialogTitle>{t("addDevice")}</DialogTitle>
            <DialogContent >
                <Grid   container
                       direction='column'
                       justifyContent='center'
                       alignItems='center'
                       spacing={2}
                >
                    <Grid item xs>
                        <Typography gutterBottom variant="body2">
                            {t("addDeviceInfo").replace("{deviceFamily}", DEVICE_TYPES[deviceFamily].name).replace("{deviceId}", targetUID)}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        {false && <Typography variant="body2">
                            {t("noteThisDeviceWillNotTake")}
                        </Typography>}
                    </Grid>
                    {(processingRequest) && <Grid item xs>
                        <CircularProgress/>
                    </Grid>}
                    {fullScreen && 
                        <Button disabled={(!hasBillingInfo && enableBilling) || processingRequest}
                                variant="contained" 
                                onClick={handleAddDevice} 
                                fullWidth 
                                sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                                autoFocus
                                color="secondary">
                            {t("add")}
                        </Button>
                    }
                
                    {fullScreen && 
                        <Button onClick={handleCloseAddDevice} 
                                fullWidth 
                                sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                                autoFocus
                                color="primary">
                            {t("cancel")}
                        </Button>
                    }
                </Grid>
            </DialogContent>
            {!fullScreen && <DialogActions>
                <Button onClick={handleCloseAddDevice} color="primary">
                    {t("cancel")}
                </Button>
                <Button disabled={(!hasBillingInfo && enableBilling) || processingRequest }
                    variant="contained" onClick={handleAddDevice} color="secondary">
                    {t("add")}
                </Button>
            </DialogActions>}
        </Dialog>}
        {activateDeviceDialogue && <Dialog
            disableEscapeKeyDown
            open={activateDeviceDialogue}
            onClose={handleCloseActivateDevice}
            fullScreen={fullScreen}>
            <DialogTitle>{t("activateDevice")}</DialogTitle>
            <DialogContent >
                <Grid item container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item>
                        {accountInfo.isAccountTech ? 
                            <Typography gutterBottom variant="body2">
                                {t("activateDeviceInfo").replace("{deviceFamily}", DEVICE_TYPES[deviceFamily].name).replace("{deviceId}", targetUID)}
                            </Typography>  
                         
                        : <Typography>{t("activateDeviceInstructions")}</Typography>}
                        <div />
                        {false && <Typography variant="body2">
                            {t("noteThisDeviceWillNotTake")}
                        </Typography>}
                    </Grid>
                    {enableBilling && <Grid item>
                        {(loadingBilling || processingRequest) ? <CircularProgress/> 
                            : <Typography variant="body2" gutterBottom >
                                <Box fontWeight="fontWeightBold" m={0}>
                                    {true ? t("estimatedTotalDueNow") : t("estimatedCredit")}: {estCharge} {orgBilling.billing_curr}
                                </Box>
                            </Typography>}
                    </Grid>}
                        {fullScreen && 
                            (accountInfo.isAccountTech ? 
                                <Button disabled={(!hasBillingInfo && enableBilling) || loadingBilling || processingRequest}
                                        fullWidth 
                                        sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                                        autoFocus
                                        variant="contained" 
                                        onClick={handleUpdateDeviceStatus} 
                                        color="secondary">
                                    {t("activate")}
                            </Button>
                        : <Button   disabled={false}
                                    fullWidth 
                                    sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                                    autoFocus
                                    variant={undefined}
                                    onClick={handleCloseActivateDevice} 
                                    color="secondary">
                            {t("ok")}
                        </Button>)
                        }
                        {fullScreen && accountInfo.isAccountTech && 
                            <Button onClick={handleCloseActivateDevice} 
                                    color="primary"
                                    fullWidth 
                                    sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                                    autoFocus
                            >
                                {t("cancel")}
                            </Button>}
                </Grid>
            </DialogContent>
            {!fullScreen && <DialogActions>
                {accountInfo.isAccountTech && <Button onClick={handleCloseActivateDevice} color="primary">
                    {t("cancel")}
                </Button>}
                {accountInfo.isAccountTech ? <Button disabled={(!hasBillingInfo && enableBilling) || loadingBilling || processingRequest}
                    variant="contained" onClick={handleUpdateDeviceStatus} color="secondary">
                    {t("activate")}
                </Button>
                : <Button disabled={false}
                    variant="contained" onClick={handleCloseActivateDevice} color="secondary">
                    {t("ok")}
                </Button>}
            </DialogActions>}
        </Dialog>}
    </>;
}

export default AddDevice;