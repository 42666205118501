/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import {
    IconButton,
    Tooltip,
} from '@mui/material';
import { 
    Edit,
} from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import DataEntryDialogue from './DataEntryDialogue.js';

import { sanitizeObjectName_40char } from '../helpers/sanitizeObjectName.js';


const EditFriendlyName = ({disabled, currentName, onSave=(value)=>{}, useEditIcon=true}) => {
    const { t } = useTranslation();
    const [friendlyName, setFriendlyName] = useState(currentName === null ? undefined : currentName);
    const [openDialogue, setOpenDialogue] = useState(false);

    const handleErrorCheck = (key, value_in) => {
        let value_out=undefined;
        let errorText = ""
        let clear = false;
    
        if(sanitizeObjectName_40char(value_in)){
            value_out = value_in;
        }
        if(value_in.length === 0){
            value_out = value_in;
            clear = true;
            //errorText = t('mustNotBeEmpty');
        }
        return {value: value_out, errorText: errorText, clearValue: clear}
    };

    const handleOpenEditFriendlyName = () => {
        setOpenDialogue(true);
    }

    const handleClose = () => {
        setFriendlyName(currentName);
        setOpenDialogue(false);
    };

    const handleSave = (key, value) => {
        //Send back-end
        onSave(value);
        setOpenDialogue(false);
    };

    return(
        <>
        <Tooltip title={t("editDeviceFriendlyName")}>
            <IconButton disabled={disabled} onClick={handleOpenEditFriendlyName}>
                {useEditIcon ? <Edit/> : <ChevronRightIcon />}
            </IconButton>
        </Tooltip>
        
        <DataEntryDialogue 
            title={t('deviceName')} 
            value={friendlyName} 
            instructions={""} 
            dialogueOpen={openDialogue} 
            handleSave={handleSave} 
            handleCancel={handleClose} 
            errorCheck={handleErrorCheck}/>
        </>
    );
}

export default EditFriendlyName;