//Determine Environment
// NOTE:  Amplify's environment variables and build script must be updated to accomodate using an ENV Variable at build.
//const DEFAULT_LOCAL_BUILD_ENV = "prd";
const DEFAULT_LOCAL_BUILD_ENV = "dev";
const BUILD_ENV = process.env.REACT_APP_BUILD_ENV === "prd" ? "prd" : process.env.REACT_APP_BUILD_ENV === "dev" ? "dev" : DEFAULT_LOCAL_BUILD_ENV;

export const useDev = BUILD_ENV === "dev" ? true : false;

const targetDate = new Date('2024-09-27');
const currentDate = new Date();
export const goLive = currentDate >= targetDate;

// Development API Gateway stage endpoint
export const host_dev = 'https:///jf2564tkfd.execute-api.us-east-2.amazonaws.com/prod/';
// Production API Gateway stage endpoint
export const host_prod = 'https://rv1xa0g5ga.execute-api.us-east-2.amazonaws.com/prod/';
//export const enableBilling = true;
export const host = useDev ? host_dev : host_prod;
export const enableBilling = goLive | useDev;
export const enableFwUpdate = true;

export const logo = undefined;
export const appTitle = 'Graco Trace';

export const ACCESS = { OWNER: 'edit', READ_ONLY: 'view' };
export const STATUSES = {overdue: "greaseOverdue", flow: "dispenseWarning", ok: "OK", lowBattery: "lowBattery", wrongGrease: "wrongGrease"}
export const status_severity = { High: 3, Low: 2, Crit: 5, Comm: 6, Batt: 4, OK: 1, unknown: 0 };
export const products = [{ id: 1, name: 'Product 1' }, { id: 2, name: 'Product 2' },];
export const tags = ['Tag 1', 'Tag 2'];
export const TANK_STATUSES = { LOW: 'Low', CRITICALLY_LOW: 'Critical', COMMUNICATION_ERROR: 'Comm', LOW_BATTERY: 'Batt', HIGH: 'High', OK: 'OK', };
export const units_settings = [{ id: 0, name: "Quarts", label: "unitsQuarts" }, { id: 1, name: "Liters", label: "unitsLiters" }, { id: 2, name: "Pints", label: "unitsPints" }]
export const UNIT_SETTINGS = {
                              cc: { id: 0, name: "cc", label: "unitsCC" },
                              cu_in: {id: 1, name: "cu_in", label: "unitsCubicInches"},
                              oz: { id: 2, name: "oz", label: "unitsOunces" },
                              grams: { id: 3, name: "grams", label: "unitsGrams" },
                            };

export const SubscriptionInfo = {
  plan_code: "traceglccellp1",
  currency: "USD",
  quantity: 1,
  total_billing_cycles: 1,
  //auto_renew: 10,
  //net_terms: 0
};
export const BillingPlans = [{ label: "monthly", plan_code: "traceglccellp1", unit_cost: { "USD": 10, "CAD": 13.6, "EUR": 10, "GBP": 10 } },
  { label: "annually", plan_code: "traceglccellp2", unit_cost: { "USD": 10 * 12, "CAD": 13.6 * 12, "EUR": 10 * 12, "GBP": 10 * 12 } }]
export const BillingCurrency = [
  "USD", 
  // "CAD", 
  // "EUR", 
  // "GBP"
];

export const ActivationStatus = [{ label: 'Disabled', info: 'disabled' },
{ label: 'Active', info: 'active billable' },
{ label: 'Active', info: 'active free' },
{ label: 'In Process', info: 'status change in queue' }];

export const COOKIE_NAMES ={
  mainPageTab: "{accountName}MainPageTab",
};

//Originally used:  "http://www.graco.com/us/en/privacy-policy.html"
//export const privacy_url = "http://www.graco.com/us/en/privacy-policy.html";
export const privacy_url = goLive ? "https://www.graco.com/us/en/privacy-policy/trace-data-protection-notice.html" : "http://www.graco.com/us/en/privacy-policy.html";

export const trace_user_guide = "https://www.graco.com/content/dam/graco/led/education-center-documents/353477/353477EN.pdf";

export const trace_quick_start_guide = "https://www.graco.com/content/dam/graco/led/education-center-documents/353476/353476EN.pdf";

export const g3_max_education_center = goLive ? 'https://www.graco.com/G3Support' : 'https://www.graco.com/trace';

export const trace_support_information = "https://www.graco.com/trace";
export const tech_assistance_email = "ledtech@graco.com";

export const countries = [
    {
        "countryName": "United States",
        "countryShortCode": "US",
        "translationKey": "unitedStates",
        "regions": [
          {
            "name": "Alabama",
            "shortCode": "AL",
            "translationKey": "us_state_name_al"
          },
          {
            "name": "Alaska",
            "shortCode": "AK",
            "translationKey": "us_state_name_ak"
          },
          // {
          //   "name": "American Samoa",
          //   "shortCode": "AS",
          //   "translationKey": "us_state_name_"
          // },
          {
            "name": "Arizona",
            "shortCode": "AZ",
            "translationKey": "us_state_name_az"
          },
          {
            "name": "Arkansas",
            "shortCode": "AR",
            "translationKey": "us_state_name_ar"
          },
          {
            "name": "California",
            "shortCode": "CA",
            "translationKey": "us_state_name_ca"
          },
          {
            "name": "Colorado",
            "shortCode": "CO",
            "translationKey": "us_state_name_co"
          },
          {
            "name": "Connecticut",
            "shortCode": "CT",
            "translationKey": "us_state_name_ct"
          },
          {
            "name": "Delaware",
            "shortCode": "DE",
            "translationKey": "us_state_name_de"
          },
          {
            "name": "District of Columbia",
            "shortCode": "DC",
            "translationKey": "us_state_name_dc"
          },
          // {
          //   "name": "Micronesia",
          //   "shortCode": "FM"
          // },
          {
            "name": "Florida",
            "shortCode": "FL",
            "translationKey": "us_state_name_fl"
          },
          {
            "name": "Georgia",
            "shortCode": "GA",
            "translationKey": "us_state_name_ga"
          },
          // {
          //   "name": "Guam",
          //   "shortCode": "GU"
          // },
          {
            "name": "Hawaii",
            "shortCode": "HI",
            "translationKey": "us_state_name_hi"
          },
          {
            "name": "Idaho",
            "shortCode": "ID",
            "translationKey": "us_state_name_id"
          },
          {
            "name": "Illinois",
            "shortCode": "IL",
            "translationKey": "us_state_name_il"
          },
          {
            "name": "Indiana",
            "shortCode": "IN",
            "translationKey": "us_state_name_in"
          },
          {
            "name": "Iowa",
            "shortCode": "IA",
            "translationKey": "us_state_name_ia"
          },
          {
            "name": "Kansas",
            "shortCode": "KS",
            "translationKey": "us_state_name_ks"
          },
          {
            "name": "Kentucky",
            "shortCode": "KY",
            "translationKey": "us_state_name_ky"
          },
          {
            "name": "Louisiana",
            "shortCode": "LA",
            "translationKey": "us_state_name_la"
          },
          {
            "name": "Maine",
            "shortCode": "ME"
            ,
            "translationKey": "us_state_name_me"
          },
          {
            "name": "Marshall Islands",
            "shortCode": "MH",
            "translationKey": "us_state_name_mislands"
          },
          {
            "name": "Maryland",
            "shortCode": "MD",
            "translationKey": "us_state_name_md"
          },
          {
            "name": "Massachusetts",
            "shortCode": "MA",
            "translationKey": "us_state_name_ma"
          },
          {
            "name": "Michigan",
            "shortCode": "MI",
            "translationKey": "us_state_name_mi"
          },
          {
            "name": "Minnesota",
            "shortCode": "MN",
            "translationKey": "us_state_name_mn"
          },
          {
            "name": "Mississippi",
            "shortCode": "MS",
            "translationKey": "us_state_name_ms"
          },
          {
            "name": "Missouri",
            "shortCode": "MO",
            "translationKey": "us_state_name_mo"
          },
          {
            "name": "Montana",
            "shortCode": "MT",
            "translationKey": "us_state_name_mt"
          },
          {
            "name": "Nebraska",
            "shortCode": "NE",
            "translationKey": "us_state_name_ne"
          },
          {
            "name": "Nevada",
            "shortCode": "NV",
            "translationKey": "us_state_name_nv"
          },
          {
            "name": "New Hampshire",
            "shortCode": "NH",
            "translationKey": "us_state_name_nh"
          },
          {
            "name": "New Jersey",
            "shortCode": "NJ",
            "translationKey": "us_state_name_nj"
          },
          {
            "name": "New Mexico",
            "shortCode": "NM",
            "translationKey": "us_state_name_nm"
          },
          {
            "name": "New York",
            "shortCode": "NY",
            "translationKey": "us_state_name_ny"
          },
          {
            "name": "North Carolina",
            "shortCode": "NC",
            "translationKey": "us_state_name_nc"
          },
          {
            "name": "North Dakota",
            "shortCode": "ND",
            "translationKey": "us_state_name_nd"
          },
          {
            "name": "Northern Mariana Islands",
            "shortCode": "MP",
            "translationKey": "us_state_name_nmislands"
          },
          {
            "name": "Ohio",
            "shortCode": "OH",
            "translationKey": "us_state_name_oh"
          },
          {
            "name": "Oklahoma",
            "shortCode": "OK",
            "translationKey": "us_state_name_ok"
          },
          {
            "name": "Oregon",
            "shortCode": "OR",
            "translationKey": "us_state_name_or"
          },
          // {
          //   "name": "Palau",
          //   "shortCode": "PW"
          // },
          {
            "name": "Pennsylvania",
            "shortCode": "PA",
            "translationKey": "us_state_name_pa"
          },
          // {
          //   "name": "Puerto Rico",
          //   "shortCode": "PR"
          // },
          {
            "name": "Rhode Island",
            "shortCode": "RI",
            "translationKey": "us_state_name_ri"
          },
          {
            "name": "South Carolina",
            "shortCode": "SC",
            "translationKey": "us_state_name_sc"
          },
          {
            "name": "South Dakota",
            "shortCode": "SD",
            "translationKey": "us_state_name_sd"
          },
          {
            "name": "Tennessee",
            "shortCode": "TN",
            "translationKey": "us_state_name_tn"
          },
          {
            "name": "Texas",
            "shortCode": "TX",
            "translationKey": "us_state_name_tx"
          },
          {
            "name": "Utah",
            "shortCode": "UT",
            "translationKey": "us_state_name_ut"
          },
          {
            "name": "Vermont",
            "shortCode": "VT",
            "translationKey": "us_state_name_vt"
          },
          {
            "name": "Virgin Islands",
            "shortCode": "VI",
            "translationKey": "us_state_name_vislands"
          },
          {
            "name": "Virginia",
            "shortCode": "VA",
            "translationKey": "us_state_name_va"
          },
          {
            "name": "Washington",
            "shortCode": "WA",
            "translationKey": "us_state_name_wa"
          },
          {
            "name": "West Virginia",
            "shortCode": "WV",
            "translationKey": "us_state_name_wv"
          },
          {
            "name": "Wisconsin",
            "shortCode": "WI",
            "translationKey": "us_state_name_wi"
          },
          {
            "name": "Wyoming",
            "shortCode": "WY",
            "translationKey": "us_state_name_wy"
          },
          // {
          //   "name": "Armed Forces Americas",
          //   "shortCode": "AA"
          // },
          // {
          //   "name": "Armed Forces Europe, Canada, Africa and Middle East",
          //   "shortCode": "AE"
          // },
          // {
          //   "name": "Armed Forces Pacific",
          //   "shortCode": "AP"
          // }
        ]
      },
  // {
  //     "countryName": "Afghanistan",
  //     "countryShortCode": "AF",
  //     "regions": [
  //       {
  //         "name": "Badakhshan",
  //         "shortCode": "BDS"
  //       },
  //       {
  //         "name": "Badghis",
  //         "shortCode": "BDG"
  //       },
  //       {
  //         "name": "Baghlan",
  //         "shortCode": "BGL"
  //       },
  //       {
  //         "name": "Balkh",
  //         "shortCode": "BAL"
  //       },
  //       {
  //         "name": "Bamyan",
  //         "shortCode": "BAM"
  //       },
  //       {
  //         "name": "Daykundi",
  //         "shortCode": "DAY"
  //       },
  //       {
  //         "name": "Farah",
  //         "shortCode": "FRA"
  //       },
  //       {
  //         "name": "Faryab",
  //         "shortCode": "FYB"
  //       },
  //       {
  //         "name": "Ghazni",
  //         "shortCode": "GHA"
  //       },
  //       {
  //         "name": "Ghor",
  //         "shortCode": "GHO"
  //       },
  //       {
  //         "name": "Helmand",
  //         "shortCode": "HEL"
  //       },
  //       {
  //         "name": "Herat",
  //         "shortCode": "HER"
  //       },
  //       {
  //         "name": "Jowzjan",
  //         "shortCode": "JOW"
  //       },
  //       {
  //         "name": "Kabul",
  //         "shortCode": "KAB"
  //       },
  //       {
  //         "name": "Kandahar",
  //         "shortCode": "KAN"
  //       },
  //       {
  //         "name": "Kapisa",
  //         "shortCode": "KAP"
  //       },
  //       {
  //         "name": "Khost",
  //         "shortCode": "KHO"
  //       },
  //       {
  //         "name": "Kunar",
  //         "shortCode": "KNR"
  //       },
  //       {
  //         "name": "Kunduz",
  //         "shortCode": "KDZ"
  //       },
  //       {
  //         "name": "Laghman",
  //         "shortCode": "LAG"
  //       },
  //       {
  //         "name": "Logar",
  //         "shortCode": "LOW"
  //       },
  //       {
  //         "name": "Maidan Wardak",
  //         "shortCode": "WAR"
  //       },
  //       {
  //         "name": "Nangarhar",
  //         "shortCode": "NAN"
  //       },
  //       {
  //         "name": "Nimruz",
  //         "shortCode": "NIM"
  //       },
  //       {
  //         "name": "Nuristan",
  //         "shortCode": "NUR"
  //       },
  //       {
  //         "name": "Paktia",
  //         "shortCode": "PIA"
  //       },
  //       {
  //         "name": "Paktika",
  //         "shortCode": "PKA"
  //       },
  //       {
  //         "name": "Panjshir",
  //         "shortCode": "PAN"
  //       },
  //       {
  //         "name": "Parwan",
  //         "shortCode": "PAR"
  //       },
  //       {
  //         "name": "Samangan",
  //         "shortCode": "SAM"
  //       },
  //       {
  //         "name": "Sar-e Pol",
  //         "shortCode": "SAR"
  //       },
  //       {
  //         "name": "Takhar",
  //         "shortCode": "TAK"
  //       },
  //       {
  //         "name": "Urozgan",
  //         "shortCode": "ORU"
  //       },
  //       {
  //         "name": "Zabul",
  //         "shortCode": "ZAB"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Åland Islands",
  //     "countryShortCode": "AX",
  //     "regions": [
  //       {
  //         "name": "Brändö",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Eckerö",
  //         "shortCode": "EC"
  //       },
  //       {
  //         "name": "Finström",
  //         "shortCode": "FN"
  //       },
  //       {
  //         "name": "Föglö",
  //         "shortCode": "FG"
  //       },
  //       {
  //         "name": "Geta",
  //         "shortCode": "GT"
  //       },
  //       {
  //         "name": "Hammarland",
  //         "shortCode": "HM"
  //       },
  //       {
  //         "name": "Jomala",
  //         "shortCode": "JM"
  //       },
  //       {
  //         "name": "Kumlinge",
  //         "shortCode": "KM"
  //       },
  //       {
  //         "name": "Kökar",
  //         "shortCode": "KK"
  //       },
  //       {
  //         "name": "Lemland",
  //         "shortCode": "LE"
  //       },
  //       {
  //         "name": "Lumparland",
  //         "shortCode": "LU"
  //       },
  //       {
  //         "name": "Mariehamn",
  //         "shortCode": "MH"
  //       },
  //       {
  //         "name": "Saltvik",
  //         "shortCode": "SV"
  //       },
  //       {
  //         "name": "Sottunga",
  //         "shortCode": "ST"
  //       },
  //       {
  //         "name": "Sund",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Vårdö",
  //         "shortCode": "VR"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Albania",
  //     "countryShortCode": "AL",
  //     "regions": [
  //       {
  //         "name": "Berat",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Dibër",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Durrës",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Elbasan",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Fier",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Gjirokastër",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Korçë",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Kukës",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Lezhë",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Shkodër",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Tirana",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Vlorë",
  //         "shortCode": "12"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Algeria",
  //     "countryShortCode": "DZ",
  //     "regions": [
  //       {
  //         "name": "Adrar",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Aïn Defla",
  //         "shortCode": "44"
  //       },
  //       {
  //         "name": "Aïn Témouchent",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "Algiers",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Annaba",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Batna",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Béchar",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Béjaïa",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Biskra",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Blida",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Bordj Bou Arréridj",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Bouïra",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Boumerdès",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Chlef",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Constantine",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Djelfa",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "El Bayadh",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "El Oued",
  //         "shortCode": "39"
  //       },
  //       {
  //         "name": "El Tarf",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "Ghardaïa",
  //         "shortCode": "47"
  //       },
  //       {
  //         "name": "Guelma",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Illizi",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Jijel",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Khenchela",
  //         "shortCode": "40"
  //       },
  //       {
  //         "name": "Laghouat",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Mascara",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Médéa",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Mila",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Mostaganem",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Msila",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Naâma",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Oran",
  //         "shortCode": "31"
  //       },
  //       {
  //         "name": "Ouargla",
  //         "shortCode": "30"
  //       },
  //       {
  //         "name": "Oum el Bouaghi",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Relizane",
  //         "shortCode": "48"
  //       },
  //       {
  //         "name": "Saïda",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Sétif",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Sidi Bel Abbès",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Skikda",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Souk Ahras",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Tamanghasset",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Tébessa",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Tiaret",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Tindouf",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "Tipaza",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Tissemsilt",
  //         "shortCode": "38"
  //       },
  //       {
  //         "name": "Tizi Ouzou",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Tlemcen",
  //         "shortCode": "13"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "American Samoa",
  //     "countryShortCode": "AS",
  //     "regions": [
  //       {
  //         "name": "Tutuila",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Aunu'u",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Ta'ū",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Ofu‑Olosega",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Rose Atoll",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Swains Island",
  //         "shortCode": "22"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Andorra",
  //     "countryShortCode": "AD",
  //     "regions": [
  //       {
  //         "name": "Andorra la Vella",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Canillo",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Encamp",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Escaldes-Engordany",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "La Massana",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Ordino",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Sant Julià de Lòria",
  //         "shortCode": "06"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Angola",
  //     "countryShortCode": "AO",
  //     "regions": [
  //       {
  //         "name": "Bengo",
  //         "shortCode": "BGO"
  //       },
  //       {
  //         "name": "Benguela",
  //         "shortCode": "BGU"
  //       },
  //       {
  //         "name": "Bié",
  //         "shortCode": "BIE"
  //       },
  //       {
  //         "name": "Cabinda",
  //         "shortCode": "CAB"
  //       },
  //       {
  //         "name": "Cuando Cubango",
  //         "shortCode": "CCU"
  //       },
  //       {
  //         "name": "Cuanza Norte",
  //         "shortCode": "CNO"
  //       },
  //       {
  //         "name": "Cuanza Sul",
  //         "shortCode": "CUS"
  //       },
  //       {
  //         "name": "Cunene",
  //         "shortCode": "CNN"
  //       },
  //       {
  //         "name": "Huambo",
  //         "shortCode": "HUA"
  //       },
  //       {
  //         "name": "Huíla",
  //         "shortCode": "HUI"
  //       },
  //       {
  //         "name": "Luanda",
  //         "shortCode": "LUA"
  //       },
  //       {
  //         "name": "Lunda Norte",
  //         "shortCode": "LNO"
  //       },
  //       {
  //         "name": "Lunda Sul",
  //         "shortCode": "LSU"
  //       },
  //       {
  //         "name": "Malanje",
  //         "shortCode": "MAL"
  //       },
  //       {
  //         "name": "Moxico",
  //         "shortCode": "MOX"
  //       },
  //       {
  //         "name": "Namibe",
  //         "shortCode": "NAM"
  //       },
  //       {
  //         "name": "Uíge",
  //         "shortCode": "UIG"
  //       },
  //       {
  //         "name": "Zaire",
  //         "shortCode": "ZAI"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Anguilla",
  //     "countryShortCode": "AI",
  //     "regions": [
  //       {
  //         "name": "Anguilla",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Anguillita Island",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Blowing Rock",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Cove Cay",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Crocus Cay",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Deadman's Cay",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Dog Island",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "East Cay",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Little Island",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Little Scrub Island",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Mid Cay",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "North Cay",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Prickly Pear Cays",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Rabbit Island",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Sandy Island/Sand Island",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Scilly Cay",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Scrub Island",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Seal Island",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Sombrero/Hat Island",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "South Cay",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "South Wager Island",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "West Cay",
  //         "shortCode": "22"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Antarctica",
  //     "countryShortCode": "AQ",
  //     "regions": [
  //       {
  //         "name": "Antarctica",
  //         "shortCode": "AQ"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Antigua and Barbuda",
  //     "countryShortCode": "AG",
  //     "regions": [
  //       {
  //         "name": "Antigua Island",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Barbuda Island",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Bird Island",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Bishop Island",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Blake Island",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Crump Island",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Dulcina Island",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Exchange Island",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Five Islands",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Great Bird Island",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Green Island",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Guiana Island",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Hawes Island",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Hells Gate Island",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Henry Island",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Johnson Island",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Kid Island",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Lobster Island",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Maiden Island",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Moor Island",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Nanny Island",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Pelican Island",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Prickly Pear Island",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Rabbit Island",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Red Head Island",
  //         "shortCode": "31"
  //       },
  //       {
  //         "name": "Redonda Island",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Sandy Island",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Smith Island",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "The Sisters",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Vernon Island",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Wicked Will Island",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "York Island",
  //         "shortCode": "37"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Argentina",
  //     "countryShortCode": "AR",
  //     "regions": [
  //       {
  //         "name": "Buenos Aires",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Capital Federal",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Catamarca",
  //         "shortCode": "K"
  //       },
  //       {
  //         "name": "Chaco",
  //         "shortCode": "H"
  //       },
  //       {
  //         "name": "Chubut",
  //         "shortCode": "U"
  //       },
  //       {
  //         "name": "Córdoba",
  //         "shortCode": "X"
  //       },
  //       {
  //         "name": "Corrientes",
  //         "shortCode": "W"
  //       },
  //       {
  //         "name": "Entre Ríos",
  //         "shortCode": "E"
  //       },
  //       {
  //         "name": "Formosa",
  //         "shortCode": "P"
  //       },
  //       {
  //         "name": "Jujuy",
  //         "shortCode": "Y"
  //       },
  //       {
  //         "name": "La Pampa",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "La Rioja",
  //         "shortCode": "F"
  //       },
  //       {
  //         "name": "Mendoza",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "Misiones",
  //         "shortCode": "N"
  //       },
  //       {
  //         "name": "Neuquén",
  //         "shortCode": "Q"
  //       },
  //       {
  //         "name": "Río Negro",
  //         "shortCode": "R"
  //       },
  //       {
  //         "name": "Salta",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "San Juan",
  //         "shortCode": "J"
  //       },
  //       {
  //         "name": "San Luis",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "Santa Cruz",
  //         "shortCode": "Z"
  //       },
  //       {
  //         "name": "Santa Fe",
  //         "shortCode": "S"
  //       },
  //       {
  //         "name": "Santiago del Estero",
  //         "shortCode": "G"
  //       },
  //       {
  //         "name": "Tierra del Fuego",
  //         "shortCode": "V"
  //       },
  //       {
  //         "name": "Tucumán",
  //         "shortCode": "T"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Armenia",
  //     "countryShortCode": "AM",
  //     "regions": [
  //       {
  //         "name": "Aragatsotn",
  //         "shortCode": "AG"
  //       },
  //       {
  //         "name": "Ararat",
  //         "shortCode": "AR"
  //       },
  //       {
  //         "name": "Armavir",
  //         "shortCode": "AV"
  //       },
  //       {
  //         "name": "Gegharkunik",
  //         "shortCode": "GR"
  //       },
  //       {
  //         "name": "Kotayk",
  //         "shortCode": "KT"
  //       },
  //       {
  //         "name": "Lori",
  //         "shortCode": "LO"
  //       },
  //       {
  //         "name": "Shirak",
  //         "shortCode": "SH"
  //       },
  //       {
  //         "name": "Syunik",
  //         "shortCode": "SU"
  //       },
  //       {
  //         "name": "Tavush",
  //         "shortCode": "TV"
  //       },
  //       {
  //         "name": "Vayots Dzor",
  //         "shortCode": "VD"
  //       },
  //       {
  //         "name": "Yerevan",
  //         "shortCode": "ER"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Aruba",
  //     "countryShortCode": "AW",
  //     "regions": [
  //       {
  //         "name": "Aruba",
  //         "shortCode": "AW"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Australia",
  //     "countryShortCode": "AU",
  //     "regions": [
  //       {
  //         "name": "Australian Capital Territory",
  //         "shortCode": "ACT"
  //       },
  //       {
  //         "name": "New South Wales",
  //         "shortCode": "NSW"
  //       },
  //       {
  //         "name": "Northern Territory",
  //         "shortCode": "NT"
  //       },
  //       {
  //         "name": "Queensland",
  //         "shortCode": "QLD"
  //       },
  //       {
  //         "name": "South Australia",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Tasmania",
  //         "shortCode": "TAS"
  //       },
  //       {
  //         "name": "Victoria",
  //         "shortCode": "VIC"
  //       },
  //       {
  //         "name": "Western Australia",
  //         "shortCode": "WA"
  //       }
  //     ]
  //   },
    {
      "countryName": "Austria",
      "countryShortCode": "AT",
      "translationKey": "country_name_austria",
      "regions": [
          {
              "name": "Burgenland",
              "translationKey": "austria_region_burgenland",
              "shortCode": "1"
          },
          {
              "name": "Kärnten",
              "translationKey": "austria_region_karnten",
              "shortCode": "2"
          },
          {
              "name": "Niederösterreich",
              "translationKey": "austria_region_niederosterreich",
              "shortCode": "3"
          },
          {
              "name": "Oberösterreich",
              "translationKey": "austria_region_oberosterreich",
              "shortCode": "4"
          },
          {
              "name": "Salzburg",
              "translationKey": "austria_region_salzburg",
              "shortCode": "5"
          },
          {
              "name": "Steiermark",
              "translationKey": "austria_region_steiermark",
              "shortCode": "6"
          },
          {
              "name": "Tirol",
              "translationKey": "austria_region_tirol",
              "shortCode": "7"
          },
          {
              "name": "Vorarlberg",
              "translationKey": "austria_region_vorarlberg",
              "shortCode": "8"
          },
          {
              "name": "Wien",
              "translationKey": "austria_region_wien",
              "shortCode": "9"
          }
      ]
    },
  //   {
  //     "countryName": "Azerbaijan",
  //     "countryShortCode": "AZ",
  //     "regions": [
  //       {
  //         "name": "Abşeron",
  //         "shortCode": "ABS"
  //       },
  //       {
  //         "name": "Ağcabədi",
  //         "shortCode": "AGC"
  //       },
  //       {
  //         "name": "Ağdam",
  //         "shortCode": "AGM"
  //       },
  //       {
  //         "name": "Ağdaş",
  //         "shortCode": "AGS"
  //       },
  //       {
  //         "name": "Ağstafa",
  //         "shortCode": "AGA"
  //       },
  //       {
  //         "name": "Ağsu",
  //         "shortCode": "AGU"
  //       },
  //       {
  //         "name": "Astara",
  //         "shortCode": "AST"
  //       },
  //       {
  //         "name": "Bakı",
  //         "shortCode": "BAK"
  //       },
  //       {
  //         "name": "Babək",
  //         "shortCode": "BAB"
  //       },
  //       {
  //         "name": "Balakən",
  //         "shortCode": "BAL"
  //       },
  //       {
  //         "name": "Bərdə",
  //         "shortCode": "BAR"
  //       },
  //       {
  //         "name": "Beyləqan",
  //         "shortCode": "BEY"
  //       },
  //       {
  //         "name": "Biləsuvar",
  //         "shortCode": "BIL"
  //       },
  //       {
  //         "name": "Cəbrayıl",
  //         "shortCode": "CAB"
  //       },
  //       {
  //         "name": "Cəlilabad",
  //         "shortCode": "CAL"
  //       },
  //       {
  //         "name": "Culfa",
  //         "shortCode": "CUL"
  //       },
  //       {
  //         "name": "Daşkəsən",
  //         "shortCode": "DAS"
  //       },
  //       {
  //         "name": "Füzuli",
  //         "shortCode": "FUZ"
  //       },
  //       {
  //         "name": "Gədəbəy",
  //         "shortCode": "GAD"
  //       },
  //       {
  //         "name": "Goranboy",
  //         "shortCode": "GOR"
  //       },
  //       {
  //         "name": "Göyçay",
  //         "shortCode": "GOY"
  //       },
  //       {
  //         "name": "Göygöl",
  //         "shortCode": "GYG"
  //       },
  //       {
  //         "name": "Hacıqabul",
  //         "shortCode": "HAC"
  //       },
  //       {
  //         "name": "İmişli",
  //         "shortCode": "IMI"
  //       },
  //       {
  //         "name": "İsmayıllı",
  //         "shortCode": "ISM"
  //       },
  //       {
  //         "name": "Kəlbəcər",
  //         "shortCode": "KAL"
  //       },
  //       {
  //         "name": "Kǝngǝrli",
  //         "shortCode": "KAN"
  //       },
  //       {
  //         "name": "Kürdəmir",
  //         "shortCode": "KUR"
  //       },
  //       {
  //         "name": "Laçın",
  //         "shortCode": "LAC"
  //       },
  //       {
  //         "name": "Lənkəran",
  //         "shortCode": "LAN"
  //       },
  //       {
  //         "name": "Lerik",
  //         "shortCode": "LER"
  //       },
  //       {
  //         "name": "Masallı",
  //         "shortCode": "MAS"
  //       },
  //       {
  //         "name": "Neftçala",
  //         "shortCode": "NEF"
  //       },
  //       {
  //         "name": "Oğuz",
  //         "shortCode": "OGU"
  //       },
  //       {
  //         "name": "Ordubad",
  //         "shortCode": "ORD"
  //       },
  //       {
  //         "name": "Qəbələ",
  //         "shortCode": "QAB"
  //       },
  //       {
  //         "name": "Qax",
  //         "shortCode": "QAX"
  //       },
  //       {
  //         "name": "Qazax",
  //         "shortCode": "QAZ"
  //       },
  //       {
  //         "name": "Qobustan",
  //         "shortCode": "QOB"
  //       },
  //       {
  //         "name": "Quba",
  //         "shortCode": "QBA"
  //       },
  //       {
  //         "name": "Qubadli",
  //         "shortCode": "QBI"
  //       },
  //       {
  //         "name": "Qusar",
  //         "shortCode": "QUS"
  //       },
  //       {
  //         "name": "Saatlı",
  //         "shortCode": "SAT"
  //       },
  //       {
  //         "name": "Sabirabad",
  //         "shortCode": "SAB"
  //       },
  //       {
  //         "name": "Şabran",
  //         "shortCode": "SBN"
  //       },
  //       {
  //         "name": "Sədərək",
  //         "shortCode": "SAD"
  //       },
  //       {
  //         "name": "Şahbuz",
  //         "shortCode": "SAH"
  //       },
  //       {
  //         "name": "Şəki",
  //         "shortCode": "SAK"
  //       },
  //       {
  //         "name": "Salyan",
  //         "shortCode": "SAL"
  //       },
  //       {
  //         "name": "Şamaxı",
  //         "shortCode": "SMI"
  //       },
  //       {
  //         "name": "Şəmkir",
  //         "shortCode": "SKR"
  //       },
  //       {
  //         "name": "Samux",
  //         "shortCode": "SMX"
  //       },
  //       {
  //         "name": "Şərur",
  //         "shortCode": "SAR"
  //       },
  //       {
  //         "name": "Siyəzən",
  //         "shortCode": "SIY"
  //       },
  //       {
  //         "name": "Şuşa",
  //         "shortCode": "SUS"
  //       },
  //       {
  //         "name": "Tərtər",
  //         "shortCode": "TAR"
  //       },
  //       {
  //         "name": "Tovuz",
  //         "shortCode": "TOV"
  //       },
  //       {
  //         "name": "Ucar",
  //         "shortCode": "UCA"
  //       },
  //       {
  //         "name": "Xaçmaz",
  //         "shortCode": "XAC"
  //       },
  //       {
  //         "name": "Xızı",
  //         "shortCode": "XIZ"
  //       },
  //       {
  //         "name": "Xocalı",
  //         "shortCode": "XCI"
  //       },
  //       {
  //         "name": "Xocavənd",
  //         "shortCode": "XVD"
  //       },
  //       {
  //         "name": "Yardımlı",
  //         "shortCode": "YAR"
  //       },
  //       {
  //         "name": "Yevlax",
  //         "shortCode": "YEV"
  //       },
  //       {
  //         "name": "Zəngilan",
  //         "shortCode": "ZAN"
  //       },
  //       {
  //         "name": "Zaqatala",
  //         "shortCode": "ZAQ"
  //       },
  //       {
  //         "name": "Zərdab",
  //         "shortCode": "ZAR"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bahamas",
  //     "countryShortCode": "BS",
  //     "regions": [
  //       {
  //         "name": "Acklins Island",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Berry Islands",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Bimini",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Black Point",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Cat Island",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Central Abaco",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Crooked Island and Long Cay",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "East Grand Bahama",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Exuma",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Freeport",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Fresh Creek",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Governor's Harbour",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Green Turtle Cay",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Harbour Island",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "High Rock",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Inagua",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Kemps Bay",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Long Island",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Marsh Harbour",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Mayaguana",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Moore’s Island",
  //         "shortCode": "40"
  //       },
  //       {
  //         "name": "New Providence",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Nichollstown and Berry Islands",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "North Abaco",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "North Andros",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "North Eleuthera",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Ragged Island",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Rock Sound",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "San Salvador and Rum Cay",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Sandy Point",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "South Abaco",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "South Andros",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "South Eleuthera",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "West Grand Bahama",
  //         "shortCode": "39"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bahrain",
  //     "countryShortCode": "BH",
  //     "regions": [
  //       {
  //         "name": "Al Janūbīyah",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Al Manāmah",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Al Muḩarraq",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Al Wusţá",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Ash Shamālīyah",
  //         "shortCode": "17"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bangladesh",
  //     "countryShortCode": "BD",
  //     "regions": [
  //       {
  //         "name": "Barisal",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "Chittagong",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Dhaka",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Khulna",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "Mymensingh",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "Rajshahi",
  //         "shortCode": "E"
  //       },
  //       {
  //         "name": "Rangpur",
  //         "shortCode": "F"
  //       },
  //       {
  //         "name": "Sylhet",
  //         "shortCode": "G"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Barbados",
  //     "countryShortCode": "BB",
  //     "regions": [
  //       {
  //         "name": "Christ Church",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Saint Andrew",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Saint George",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Saint James",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Saint John",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Saint Joseph",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Saint Lucy",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Saint Michael",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Saint Peter",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Saint Philip",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Saint Thomas",
  //         "shortCode": "11"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Belarus",
  //     "countryShortCode": "BY",
  //     "regions": [
  //       {
  //         "name": "Brest voblast",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Gorod Minsk",
  //         "shortCode": "HM"
  //       },
  //       {
  //         "name": "Homiel voblast",
  //         "shortCode": "HO"
  //       },
  //       {
  //         "name": "Hrodna voblast",
  //         "shortCode": "HR"
  //       },
  //       {
  //         "name": "Mahilyow voblast",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Minsk voblast",
  //         "shortCode": "MI"
  //       },
  //       {
  //         "name": "Vitsebsk voblast",
  //         "shortCode": "VI"
  //       }
  //     ]
  //   },
    {
      "countryName": "Belgium",
      "translationKey": "country_name_belgium",
      "countryShortCode": "BE",
      "regions": [
          {
              "name": "Brussels",
              "translationKey": "belgium_region_name_brussels",
              "shortCode": "BRU"
          },
          {
              "name": "Flanders",
              "translationKey": "belgium_region_name_flanders",
              "shortCode": "VLG"
          },
          {
              "name": "Wallonia",
              "translationKey": "belgium_region_name_wallonia",
              "shortCode": "WAL"
          }
      ]
    },
  //   {
  //     "countryName": "Belize",
  //     "countryShortCode": "BZ",
  //     "regions": [
  //       {
  //         "name": "Belize District",
  //         "shortCode": "BZ"
  //       },
  //       {
  //         "name": "Cayo District",
  //         "shortCode": "CY"
  //       },
  //       {
  //         "name": "Corozal District",
  //         "shortCode": "CZL"
  //       },
  //       {
  //         "name": "Orange Walk District",
  //         "shortCode": "OW"
  //       },
  //       {
  //         "name": "Stann Creek District",
  //         "shortCode": "SC"
  //       },
  //       {
  //         "name": "Toledo District",
  //         "shortCode": "TOL"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Benin",
  //     "countryShortCode": "BJ",
  //     "regions": [
  //       {
  //         "name": "Alibori",
  //         "shortCode": "AL"
  //       },
  //       {
  //         "name": "Atakora",
  //         "shortCode": "AK"
  //       },
  //       {
  //         "name": "Atlantique",
  //         "shortCode": "AQ"
  //       },
  //       {
  //         "name": "Borgou",
  //         "shortCode": "BO"
  //       },
  //       {
  //         "name": "Collines Department",
  //         "shortCode": "CO"
  //       },
  //       {
  //         "name": "Donga",
  //         "shortCode": "DO"
  //       },
  //       {
  //         "name": "Kouffo",
  //         "shortCode": "KO"
  //       },
  //       {
  //         "name": "Littoral Department",
  //         "shortCode": "LI"
  //       },
  //       {
  //         "name": "Mono Department",
  //         "shortCode": "MO"
  //       },
  //       {
  //         "name": "Ouémé",
  //         "shortCode": "OU"
  //       },
  //       {
  //         "name": "Plateau",
  //         "shortCode": "PL"
  //       },
  //       {
  //         "name": "Zou",
  //         "shortCode": "ZO"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bermuda",
  //     "countryShortCode": "BM",
  //     "regions": [
  //       {
  //         "name": "City of Hamilton",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Devonshire Parish",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Hamilton Parish",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Paget Parish",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Pembroke Parish",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Sandys Parish",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Smith's Parish",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Southampton Parish",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "St. George's Parish",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Town of St. George",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Warwick Parish",
  //         "shortCode": "11"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bhutan",
  //     "countryShortCode": "BT",
  //     "regions": [
  //       {
  //         "name": "Bumthang",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Chhukha",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Dagana",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Gasa",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Haa",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Lhuntse",
  //         "shortCode": "44"
  //       },
  //       {
  //         "name": "Mongar",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Paro",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Pemagatshel",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Punakha",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Samdrup Jongkhar",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Samtse",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Sarpang",
  //         "shortCode": "31"
  //       },
  //       {
  //         "name": "Thimphu",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Trashigang",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Trashiyangtse",
  //         "shortCode": "TY"
  //       },
  //       {
  //         "name": "Trongsa",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Tsirang",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Wangdue Phodrang",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Zhemgang",
  //         "shortCode": "34"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bolivia",
  //     "countryShortCode": "BO",
  //     "regions": [
  //       {
  //         "name": "Beni",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Chuquisaca",
  //         "shortCode": "H"
  //       },
  //       {
  //         "name": "Cochabamba",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "La Paz",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "Oruro",
  //         "shortCode": "O"
  //       },
  //       {
  //         "name": "Pando",
  //         "shortCode": "N"
  //       },
  //       {
  //         "name": "Potosí",
  //         "shortCode": "P"
  //       },
  //       {
  //         "name": "Santa Cruz",
  //         "shortCode": "S"
  //       },
  //       {
  //         "name": "Tarija",
  //         "shortCode": "T"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bonaire, Sint Eustatius and Saba",
  //     "countryShortCode": "BQ",
  //     "regions": [
  //       {
  //         "name": "Bonaire",
  //         "shortCode": "BO"
  //       },
  //       {
  //         "name": "Saba Isand",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Sint Eustatius",
  //         "shortCode": "SE"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bosnia and Herzegovina",
  //     "countryShortCode": "BA",
  //     "regions": [
  //       {
  //         "name": "Brčko Distrikt",
  //         "shortCode": "BRC"
  //       },
  //       {
  //         "name": "Federacija Bosne i Hercegovine",
  //         "shortCode": "BIH"
  //       },
  //       {
  //         "name": "Republika Srpska",
  //         "shortCode": "SRP"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Botswana",
  //     "countryShortCode": "BW",
  //     "regions": [
  //       {
  //         "name": "Central",
  //         "shortCode": "CE"
  //       },
  //       {
  //         "name": "Ghanzi",
  //         "shortCode": "GH"
  //       },
  //       {
  //         "name": "Kgalagadi",
  //         "shortCode": "KG"
  //       },
  //       {
  //         "name": "Kgatleng",
  //         "shortCode": "KL"
  //       },
  //       {
  //         "name": "Kweneng",
  //         "shortCode": "KW"
  //       },
  //       {
  //         "name": "North West",
  //         "shortCode": "NW"
  //       },
  //       {
  //         "name": "North-East",
  //         "shortCode": "NE"
  //       },
  //       {
  //         "name": "South East",
  //         "shortCode": "SE"
  //       },
  //       {
  //         "name": "Southern",
  //         "shortCode": "SO"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Bouvet Island",
  //     "countryShortCode": "BV",
  //     "regions": [
  //       {
  //         "name": "Bouvet Island",
  //         "shortCode": "BV"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Brazil",
  //     "countryShortCode": "BR",
  //     "regions": [
  //       {
  //         "name": "Acre",
  //         "shortCode": "AC"
  //       },
  //       {
  //         "name": "Alagoas",
  //         "shortCode": "AL"
  //       },
  //       {
  //         "name": "Amapá",
  //         "shortCode": "AP"
  //       },
  //       {
  //         "name": "Amazonas",
  //         "shortCode": "AM"
  //       },
  //       {
  //         "name": "Bahia",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Ceará",
  //         "shortCode": "CE"
  //       },
  //       {
  //         "name": "Distrito Federal",
  //         "shortCode": "DF"
  //       },
  //       {
  //         "name": "Espírito Santo",
  //         "shortCode": "ES"
  //       },
  //       {
  //         "name": "Goiás",
  //         "shortCode": "GO"
  //       },
  //       {
  //         "name": "Maranhão",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Mato Grosso",
  //         "shortCode": "MT"
  //       },
  //       {
  //         "name": "Mato Grosso do Sul",
  //         "shortCode": "MS"
  //       },
  //       {
  //         "name": "Minas Gerais",
  //         "shortCode": "MG"
  //       },
  //       {
  //         "name": "Pará",
  //         "shortCode": "PA"
  //       },
  //       {
  //         "name": "Paraíba",
  //         "shortCode": "PB"
  //       },
  //       {
  //         "name": "Paraná",
  //         "shortCode": "PR"
  //       },
  //       {
  //         "name": "Pernambuco",
  //         "shortCode": "PE"
  //       },
  //       {
  //         "name": "Piauí",
  //         "shortCode": "PI"
  //       },
  //       {
  //         "name": "Rio de Janeiro",
  //         "shortCode": "RJ"
  //       },
  //       {
  //         "name": "Rio Grande do Norte",
  //         "shortCode": "RN"
  //       },
  //       {
  //         "name": "Rio Grande do Sul",
  //         "shortCode": "RS"
  //       },
  //       {
  //         "name": "Rondônia",
  //         "shortCode": "RO"
  //       },
  //       {
  //         "name": "Roraima",
  //         "shortCode": "RR"
  //       },
  //       {
  //         "name": "Santa Catarina",
  //         "shortCode": "SC"
  //       },
  //       {
  //         "name": "São Paulo",
  //         "shortCode": "SP"
  //       },
  //       {
  //         "name": "Sergipe",
  //         "shortCode": "SE"
  //       },
  //       {
  //         "name": "Tocantins",
  //         "shortCode": "TO"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "British Indian Ocean Territory",
  //     "countryShortCode": "IO",
  //     "regions": [
  //       {
  //         "name": "British Indian Ocean Territory",
  //         "shortCode": "IO"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Brunei Darussalam",
  //     "countryShortCode": "BN",
  //     "regions": [
  //       {
  //         "name": "Belait",
  //         "shortCode": "BE"
  //       },
  //       {
  //         "name": "Brunei Muara",
  //         "shortCode": "BM"
  //       },
  //       {
  //         "name": "Temburong",
  //         "shortCode": "TE"
  //       },
  //       {
  //         "name": "Tutong",
  //         "shortCode": "TU"
  //       }
  //     ]
  //   },
  //   {
    {
      "countryName": "Bulgaria",
      "translationKey": "country_name_bulgaria",
      "countryShortCode": "BG",
      "regions": [
          {
              "name": "Blagoevgrad",
              "translationKey": "bulgaria_region_01",
              "shortCode": "01"
          },
          {
              "name": "Burgas",
              "translationKey": "bulgaria_region_02",
              "shortCode": "02"
          },
          {
              "name": "Dobrich",
              "translationKey": "bulgaria_region_08",
              "shortCode": "08"
          },
          {
              "name": "Gabrovo",
              "translationKey": "bulgaria_region_07",
              "shortCode": "07"
          },
          {
              "name": "Jambol",
              "translationKey": "bulgaria_region_28",
              "shortCode": "28"
          },
          {
              "name": "Khaskovo",
              "translationKey": "bulgaria_region_26",
              "shortCode": "26"
          },
          {
              "name": "Kjustendil",
              "translationKey": "bulgaria_region_10",
              "shortCode": "10"
          },
          {
              "name": "Kurdzhali",
              "translationKey": "bulgaria_region_09",
              "shortCode": "09"
          },
          {
              "name": "Lovech",
              "translationKey": "bulgaria_region_11",
              "shortCode": "11"
          },
          {
              "name": "Montana",
              "translationKey": "bulgaria_region_12",
              "shortCode": "12"
          },
          {
              "name": "Pazardzhik",
              "translationKey": "bulgaria_region_13",
              "shortCode": "13"
          },
          {
              "name": "Pernik",
              "translationKey": "bulgaria_region_14",
              "shortCode": "14"
          },
          {
              "name": "Pleven",
              "translationKey": "bulgaria_region_15",
              "shortCode": "15"
          },
          {
              "name": "Plovdiv",
              "translationKey": "bulgaria_region_16",
              "shortCode": "16"
          },
          {
              "name": "Razgrad",
              "translationKey": "bulgaria_region_17",
              "shortCode": "17"
          },
          {
              "name": "Ruse",
              "translationKey": "bulgaria_region_18",
              "shortCode": "18"
          },
          {
              "name": "Shumen",
              "translationKey": "bulgaria_region_27",
              "shortCode": "27"
          },
          {
              "name": "Silistra",
              "translationKey": "bulgaria_region_19",
              "shortCode": "19"
          },
          {
              "name": "Sliven",
              "translationKey": "bulgaria_region_20",
              "shortCode": "20"
          },
          {
              "name": "Smoljan",
              "translationKey": "bulgaria_region_21",
              "shortCode": "21"
          },
          {
              "name": "Sofija",
              "translationKey": "bulgaria_region_23",
              "shortCode": "23"
          },
          {
              "name": "Sofija-Grad",
              "translationKey": "bulgaria_region_22",
              "shortCode": "22"
          },
          {
              "name": "Stara Zagora",
              "translationKey": "bulgaria_region_24",
              "shortCode": "24"
          },
          {
              "name": "Turgovishhe",
              "translationKey": "bulgaria_region_25",
              "shortCode": "25"
          },
          {
              "name": "Varna",
              "translationKey": "bulgaria_region_03",
              "shortCode": "03"
          },
          {
              "name": "Veliko Turnovo",
              "translationKey": "bulgaria_region_04",
              "shortCode": "04"
          },
          {
              "name": "Vidin",
              "translationKey": "bulgaria_region_05",
              "shortCode": "05"
          },
          {
              "name": "Vraca",
              "translationKey": "bulgaria_region_06",
              "shortCode": "06"
          }
      ]
    },
  //   {
  //     "countryName": "Burkina Faso",
  //     "countryShortCode": "BF",
  //     "regions": [
  //       {
  //         "name": "Balé",
  //         "shortCode": "BAL"
  //       },
  //       {
  //         "name": "Bam/Lake Bam",
  //         "shortCode": "BAM"
  //       },
  //       {
  //         "name": "Banwa Province",
  //         "shortCode": "BAN"
  //       },
  //       {
  //         "name": "Bazèga",
  //         "shortCode": "BAZ"
  //       },
  //       {
  //         "name": "Bougouriba",
  //         "shortCode": "BGR"
  //       },
  //       {
  //         "name": "Boulgou Province",
  //         "shortCode": "BLG"
  //       },
  //       {
  //         "name": "Boulkiemdé",
  //         "shortCode": "BLK"
  //       },
  //       {
  //         "name": "Comoé/Komoe",
  //         "shortCode": "COM"
  //       },
  //       {
  //         "name": "Ganzourgou Province",
  //         "shortCode": "GAN"
  //       },
  //       {
  //         "name": "Gnagna",
  //         "shortCode": "GNA"
  //       },
  //       {
  //         "name": "Gourma Province",
  //         "shortCode": "GOU"
  //       },
  //       {
  //         "name": "Houet",
  //         "shortCode": "HOU"
  //       },
  //       {
  //         "name": "Ioba",
  //         "shortCode": "IOB"
  //       },
  //       {
  //         "name": "Kadiogo",
  //         "shortCode": "KAD"
  //       },
  //       {
  //         "name": "Kénédougou",
  //         "shortCode": "KEN"
  //       },
  //       {
  //         "name": "Komondjari",
  //         "shortCode": "KMD"
  //       },
  //       {
  //         "name": "Kompienga",
  //         "shortCode": "KMP"
  //       },
  //       {
  //         "name": "Kossi Province",
  //         "shortCode": "KOS"
  //       },
  //       {
  //         "name": "Koulpélogo",
  //         "shortCode": "KOP"
  //       },
  //       {
  //         "name": "Kouritenga",
  //         "shortCode": "KOT"
  //       },
  //       {
  //         "name": "Kourwéogo",
  //         "shortCode": "KOW"
  //       },
  //       {
  //         "name": "Léraba",
  //         "shortCode": "LER"
  //       },
  //       {
  //         "name": "Loroum",
  //         "shortCode": "LOR"
  //       },
  //       {
  //         "name": "Mouhoun",
  //         "shortCode": "MOU"
  //       },
  //       {
  //         "name": "Namentenga",
  //         "shortCode": "NAM"
  //       },
  //       {
  //         "name": "Naouri/Nahouri",
  //         "shortCode": "NAO"
  //       },
  //       {
  //         "name": "Nayala",
  //         "shortCode": "NAY"
  //       },
  //       {
  //         "name": "Noumbiel",
  //         "shortCode": "NOU"
  //       },
  //       {
  //         "name": "Oubritenga",
  //         "shortCode": "OUB"
  //       },
  //       {
  //         "name": "Oudalan",
  //         "shortCode": "OUD"
  //       },
  //       {
  //         "name": "Passoré",
  //         "shortCode": "PAS"
  //       },
  //       {
  //         "name": "Poni",
  //         "shortCode": "PON"
  //       },
  //       {
  //         "name": "Sanguié",
  //         "shortCode": "SNG"
  //       },
  //       {
  //         "name": "Sanmatenga",
  //         "shortCode": "SMT"
  //       },
  //       {
  //         "name": "Séno",
  //         "shortCode": "SEN"
  //       },
  //       {
  //         "name": "Sissili",
  //         "shortCode": "SIS"
  //       },
  //       {
  //         "name": "Soum",
  //         "shortCode": "SOM"
  //       },
  //       {
  //         "name": "Sourou",
  //         "shortCode": "SOR"
  //       },
  //       {
  //         "name": "Tapoa",
  //         "shortCode": "TAP"
  //       },
  //       {
  //         "name": "Tui/Tuy",
  //         "shortCode": "TUI"
  //       },
  //       {
  //         "name": "Yagha",
  //         "shortCode": "YAG"
  //       },
  //       {
  //         "name": "Yatenga",
  //         "shortCode": "YAT"
  //       },
  //       {
  //         "name": "Ziro",
  //         "shortCode": "ZIR"
  //       },
  //       {
  //         "name": "Zondoma",
  //         "shortCode": "ZON"
  //       },
  //       {
  //         "name": "Zoundwéogo",
  //         "shortCode": "ZOU"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Burundi",
  //     "countryShortCode": "BI",
  //     "regions": [
  //       {
  //         "name": "Bubanza",
  //         "shortCode": "BB"
  //       },
  //       {
  //         "name": "Bujumbura Mairie",
  //         "shortCode": "BM"
  //       },
  //       {
  //         "name": "Bujumbura Rural",
  //         "shortCode": "BL"
  //       },
  //       {
  //         "name": "Bururi",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Cankuzo",
  //         "shortCode": "CA"
  //       },
  //       {
  //         "name": "Cibitoke",
  //         "shortCode": "CI"
  //       },
  //       {
  //         "name": "Gitega",
  //         "shortCode": "GI"
  //       },
  //       {
  //         "name": "Karuzi",
  //         "shortCode": "KR"
  //       },
  //       {
  //         "name": "Kayanza",
  //         "shortCode": "KY"
  //       },
  //       {
  //         "name": "Kirundo",
  //         "shortCode": "KI"
  //       },
  //       {
  //         "name": "Makamba",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Muramvya",
  //         "shortCode": "MU"
  //       },
  //       {
  //         "name": "Muyinga",
  //         "shortCode": "MY"
  //       },
  //       {
  //         "name": "Mwaro",
  //         "shortCode": "MW"
  //       },
  //       {
  //         "name": "Ngozi",
  //         "shortCode": "NG"
  //       },
  //       {
  //         "name": "Rutana",
  //         "shortCode": "RT"
  //       },
  //       {
  //         "name": "Ruyigi",
  //         "shortCode": "RY"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Cambodia",
  //     "countryShortCode": "KH",
  //     "regions": [
  //       {
  //         "name": "Baat Dambang",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Banteay Mean Chey",
  //         "shortCode": "1"
  //       },
  //       {
  //         "name": "Kampong Chaam",
  //         "shortCode": "3"
  //       },
  //       {
  //         "name": "Kampong Chhnang",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Kampong Spueu",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Kampong Thum",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "Kampot",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Kandaal",
  //         "shortCode": "8"
  //       },
  //       {
  //         "name": "Kaoh Kong",
  //         "shortCode": "9"
  //       },
  //       {
  //         "name": "Kracheh",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Krong Kaeb",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Krong Pailin",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Krong Preah Sihanouk",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Mondol Kiri",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Otdar Mean Chey",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Phnom Penh",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Pousaat",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Preah Vihear",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Prey Veaeng",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Rotanah Kiri",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Siem Reab",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Stueng Treng",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Svaay Rieng",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Taakaev",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Tbong Khmum",
  //         "shortCode": "25"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Cameroon",
  //     "countryShortCode": "CM",
  //     "regions": [
  //       {
  //         "name": "Adamaoua",
  //         "shortCode": "AD"
  //       },
  //       {
  //         "name": "Centre",
  //         "shortCode": "CE"
  //       },
  //       {
  //         "name": "Est",
  //         "shortCode": "ES"
  //       },
  //       {
  //         "name": "Extrême-Nord",
  //         "shortCode": "EN"
  //       },
  //       {
  //         "name": "Littoral",
  //         "shortCode": "LT"
  //       },
  //       {
  //         "name": "Nord",
  //         "shortCode": "NO"
  //       },
  //       {
  //         "name": "Nord-Ouest",
  //         "shortCode": "NW"
  //       },
  //       {
  //         "name": "Ouest",
  //         "shortCode": "OU"
  //       },
  //       {
  //         "name": "Sud",
  //         "shortCode": "SU"
  //       },
  //       {
  //         "name": "Sud-Ouest",
  //         "shortCode": "SW"
  //       }
  //     ]
  //   },
  {
    "countryName": "Canada",
    "countryShortCode": "CA",
    "translationKey": "canada",
    "regions": [
      {
        "name": "Alberta",
        "shortCode": "AB",
        "translationKey": "canada_ab"
      },
      {
        "name": "British Columbia",
        "shortCode": "BC",
        "translationKey": "canada_bc"
      },
      {
        "name": "Manitoba",
        "shortCode": "MB",
        "translationKey": "canada_mb"
      },
      {
        "name": "New Brunswick",
        "shortCode": "NB",
        "translationKey": "canada_nb"
      },
      {
        "name": "Newfoundland and Labrador",
        "shortCode": "NL",
        "translationKey": "canada_nl"
      },
      {
        "name": "Northwest Territories",
        "shortCode": "NT",
        "translationKey": "canada_nt"
      },
      {
        "name": "Nova Scotia",
        "shortCode": "NS",
        "translationKey": "canada_ns"
      },
      {
        "name": "Nunavut",
        "shortCode": "NU",
        "translationKey": "canada_nu"
      },
      {
        "name": "Ontario",
        "shortCode": "ON",
        "translationKey": "canada_on"
      },
      {
        "name": "Prince Edward Island",
        "shortCode": "PE",
        "translationKey": "canada_pe"
      },
      {
        "name": "Quebec",
        "shortCode": "QC",
        "translationKey": "canada_qc"
      },
      {
        "name": "Saskatchewan",
        "shortCode": "SK",
        "translationKey": "canada_sk"
      },
      {
        "name": "Yukon",
        "shortCode": "YT",
        "translationKey": "canada_yt"
      }
    ]
  },
  //   {
  //     "countryName": "Cape Verde",
  //     "countryShortCode": "CV",
  //     "regions": [
  //       {
  //         "name": "Boa Vista",
  //         "shortCode": "BV"
  //       },
  //       {
  //         "name": "Brava",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Calheta de São Miguel",
  //         "shortCode": "CS"
  //       },
  //       {
  //         "name": "Maio",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Mosteiros",
  //         "shortCode": "MO"
  //       },
  //       {
  //         "name": "Paúl",
  //         "shortCode": "PA"
  //       },
  //       {
  //         "name": "Porto Novo",
  //         "shortCode": "PN"
  //       },
  //       {
  //         "name": "Praia",
  //         "shortCode": "PR"
  //       },
  //       {
  //         "name": "Ribeira Brava",
  //         "shortCode": "RB"
  //       },
  //       {
  //         "name": "Ribeira Grande",
  //         "shortCode": "RG"
  //       },
  //       {
  //         "name": "Sal",
  //         "shortCode": "SL"
  //       },
  //       {
  //         "name": "Santa Catarina",
  //         "shortCode": "CA"
  //       },
  //       {
  //         "name": "Santa Cruz",
  //         "shortCode": "CR"
  //       },
  //       {
  //         "name": "São Domingos",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "São Filipe",
  //         "shortCode": "SF"
  //       },
  //       {
  //         "name": "São Nicolau",
  //         "shortCode": "SN"
  //       },
  //       {
  //         "name": "São Vicente",
  //         "shortCode": "SV"
  //       },
  //       {
  //         "name": "Tarrafal",
  //         "shortCode": "TA"
  //       },
  //       {
  //         "name": "Tarrafal de São Nicolau",
  //         "shortCode": "TS"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Cayman Islands",
  //     "countryShortCode": "KY",
  //     "regions": [
  //       {
  //         "name": "Creek"
  //       },
  //       {
  //         "name": "Eastern"
  //       },
  //       {
  //         "name": "Midland"
  //       },
  //       {
  //         "name": "South Town"
  //       },
  //       {
  //         "name": "Spot Bay"
  //       },
  //       {
  //         "name": "Stake Bay"
  //       },
  //       {
  //         "name": "West End"
  //       },
  //       {
  //         "name": "Western"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Central African Republic",
  //     "countryShortCode": "CF",
  //     "regions": [
  //       {
  //         "name": "Bamingui-Bangoran",
  //         "shortCode": "BB"
  //       },
  //       {
  //         "name": "Bangui",
  //         "shortCode": "BGF"
  //       },
  //       {
  //         "name": "Basse-Kotto",
  //         "shortCode": "BK"
  //       },
  //       {
  //         "name": "Haute-Kotto",
  //         "shortCode": "HK"
  //       },
  //       {
  //         "name": "Haut-Mbomou",
  //         "shortCode": "HM"
  //       },
  //       {
  //         "name": "Kémo",
  //         "shortCode": "KG"
  //       },
  //       {
  //         "name": "Lobaye",
  //         "shortCode": "LB"
  //       },
  //       {
  //         "name": "Mambéré-Kadéï",
  //         "shortCode": "HS"
  //       },
  //       {
  //         "name": "Mbomou",
  //         "shortCode": "MB"
  //       },
  //       {
  //         "name": "Nana-Grebizi",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Nana-Mambéré",
  //         "shortCode": "NM"
  //       },
  //       {
  //         "name": "Ombella-M'Poko",
  //         "shortCode": "MP"
  //       },
  //       {
  //         "name": "Ouaka",
  //         "shortCode": "UK"
  //       },
  //       {
  //         "name": "Ouham",
  //         "shortCode": "AC"
  //       },
  //       {
  //         "name": "Ouham Péndé",
  //         "shortCode": "OP"
  //       },
  //       {
  //         "name": "Sangha-Mbaéré",
  //         "shortCode": "SE"
  //       },
  //       {
  //         "name": "Vakaga",
  //         "shortCode": "VK"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Chad",
  //     "countryShortCode": "TD",
  //     "regions": [
  //       {
  //         "name": "Bahr el Ghazal",
  //         "shortCode": "BG"
  //       },
  //       {
  //         "name": "Batha",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Borkou",
  //         "shortCode": "BO"
  //       },
  //       {
  //         "name": "Chari-Baguirmi",
  //         "shortCode": "CB"
  //       },
  //       {
  //         "name": "Ennedi-Est",
  //         "shortCode": "EE"
  //       },
  //       {
  //         "name": "Ennedi-Ouest",
  //         "shortCode": "EO"
  //       },
  //       {
  //         "name": "Guéra",
  //         "shortCode": "GR"
  //       },
  //       {
  //         "name": "Hadjer Lamis",
  //         "shortCode": "HL"
  //       },
  //       {
  //         "name": "Kanem",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Lac",
  //         "shortCode": "LC"
  //       },
  //       {
  //         "name": "Logone Occidental",
  //         "shortCode": "LO"
  //       },
  //       {
  //         "name": "Logone Oriental",
  //         "shortCode": "LR"
  //       },
  //       {
  //         "name": "Mondoul",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Mayo-Kébbi-Est",
  //         "shortCode": "ME"
  //       },
  //       {
  //         "name": "Moyen-Chari",
  //         "shortCode": "MC"
  //       },
  //       {
  //         "name": "Ouaddai",
  //         "shortCode": "OD"
  //       },
  //       {
  //         "name": "Salamat",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Sila",
  //         "shortCode": "SI"
  //       },
  //       {
  //         "name": "Tandjilé",
  //         "shortCode": "TA"
  //       },
  //       {
  //         "name": "Tibesti",
  //         "shortCode": "TI"
  //       },
  //       {
  //         "name": "Ville de Ndjamena",
  //         "shortCode": "ND"
  //       },
  //       {
  //         "name": "Wadi Fira",
  //         "shortCode": "WF"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Chile",
  //     "countryShortCode": "CL",
  //     "regions": [
  //       {
  //         "name": "Aisén del General Carlos Ibáñez del Campo",
  //         "shortCode": "AI"
  //       },
  //       {
  //         "name": "Antofagasta",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Araucanía",
  //         "shortCode": "AR"
  //       },
  //       {
  //         "name": "Arica y Parinacota",
  //         "shortCode": "AP"
  //       },
  //       {
  //         "name": "Atacama",
  //         "shortCode": "AT"
  //       },
  //       {
  //         "name": "Bío-Bío",
  //         "shortCode": "BI"
  //       },
  //       {
  //         "name": "Coquimbo",
  //         "shortCode": "CO"
  //       },
  //       {
  //         "name": "Libertador General Bernardo O'Higgins",
  //         "shortCode": "LI"
  //       },
  //       {
  //         "name": "Los Lagos",
  //         "shortCode": "LL"
  //       },
  //       {
  //         "name": "Los Ríos",
  //         "shortCode": "LR"
  //       },
  //       {
  //         "name": "Magallanes y Antartica Chilena",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Marga-Marga",
  //         "shortCode": ""
  //       },
  //       {
  //         "name": "Maule",
  //         "shortCode": "ML"
  //       },
  //       {
  //         "name": "Ñuble",
  //         "shortCode": "NB"
  //       },
  //       {
  //         "name": "Región Metropolitana de Santiago",
  //         "shortCode": "RM"
  //       },
  //       {
  //         "name": "Tarapacá",
  //         "shortCode": "TA"
  //       },
  //       {
  //         "name": "Valparaíso",
  //         "shortCode": "VS"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "China",
  //     "countryShortCode": "CN",
  //     "regions": [
  //       {
  //         "name": "Anhui",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Beijing",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Chongqing",
  //         "shortCode": "50"
  //       },
  //       {
  //         "name": "Fujian",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Gansu",
  //         "shortCode": "62"
  //       },
  //       {
  //         "name": "Guangdong",
  //         "shortCode": "44"
  //       },
  //       {
  //         "name": "Guangxi",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Guizhou",
  //         "shortCode": "52"
  //       },
  //       {
  //         "name": "Hainan",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "Hebei",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Heilongjiang",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Henan",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Hong Kong",
  //         "shortCode": "91"
  //       },
  //       {
  //         "name": "Hubei",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Hunan",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Inner Mongolia",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Jiangsu",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Jiangxi",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "Jilin",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Liaoning",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Macau",
  //         "shortCode": "92"
  //       },
  //       {
  //         "name": "Ningxia",
  //         "shortCode": "64"
  //       },
  //       {
  //         "name": "Qinghai",
  //         "shortCode": "63"
  //       },
  //       {
  //         "name": "Shaanxi",
  //         "shortCode": "61"
  //       },
  //       {
  //         "name": "Shandong",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "Shanghai",
  //         "shortCode": "31"
  //       },
  //       {
  //         "name": "Shanxi",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Sichuan",
  //         "shortCode": "51"
  //       },
  //       {
  //         "name": "Tianjin",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Tibet",
  //         "shortCode": "54"
  //       },
  //       {
  //         "name": "Xinjiang",
  //         "shortCode": "65"
  //       },
  //       {
  //         "name": "Yunnan",
  //         "shortCode": "53"
  //       },
  //       {
  //         "name": "Zhejiang",
  //         "shortCode": "33"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Christmas Island",
  //     "countryShortCode": "CX",
  //     "regions": [
  //       {
  //         "name": "Christmas Island",
  //         "shortCode": "CX"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Cocos (Keeling) Islands",
  //     "countryShortCode": "CC",
  //     "regions": [
  //       {
  //         "name": "Direction Island",
  //         "shortCode": "DI"
  //       },
  //       {
  //         "name": "Home Island",
  //         "shortCode": "HM"
  //       },
  //       {
  //         "name": "Horsburgh Island",
  //         "shortCode": "HR"
  //       },
  //       {
  //         "name": "North Keeling Island",
  //         "shortCode": "NK"
  //       },
  //       {
  //         "name": "South Island",
  //         "shortCode": "SI"
  //       },
  //       {
  //         "name": "West Island",
  //         "shortCode": "WI"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Colombia",
  //     "countryShortCode": "CO",
  //     "regions": [
  //       {
  //         "name": "Amazonas",
  //         "shortCode": "AMA"
  //       },
  //       {
  //         "name": "Antioquia",
  //         "shortCode": "ANT"
  //       },
  //       {
  //         "name": "Arauca",
  //         "shortCode": "ARA"
  //       },
  //       {
  //         "name": "Archipiélago de San Andrés",
  //         "shortCode": "SAP"
  //       },
  //       {
  //         "name": "Atlántico",
  //         "shortCode": "ATL"
  //       },
  //       {
  //         "name": "Bogotá D.C.",
  //         "shortCode": "DC"
  //       },
  //       {
  //         "name": "Bolívar",
  //         "shortCode": "BOL"
  //       },
  //       {
  //         "name": "Boyacá",
  //         "shortCode": "BOY"
  //       },
  //       {
  //         "name": "Caldas",
  //         "shortCode": "CAL"
  //       },
  //       {
  //         "name": "Caquetá",
  //         "shortCode": "CAQ"
  //       },
  //       {
  //         "name": "Casanare",
  //         "shortCode": "CAS"
  //       },
  //       {
  //         "name": "Cauca",
  //         "shortCode": "CAU"
  //       },
  //       {
  //         "name": "Cesar",
  //         "shortCode": "CES"
  //       },
  //       {
  //         "name": "Chocó",
  //         "shortCode": "CHO"
  //       },
  //       {
  //         "name": "Córdoba",
  //         "shortCode": "COR"
  //       },
  //       {
  //         "name": "Cundinamarca",
  //         "shortCode": "CUN"
  //       },
  //       {
  //         "name": "Guainía",
  //         "shortCode": "GUA"
  //       },
  //       {
  //         "name": "Guaviare",
  //         "shortCode": "GUV"
  //       },
  //       {
  //         "name": "Huila",
  //         "shortCode": "HUI"
  //       },
  //       {
  //         "name": "La Guajira",
  //         "shortCode": "LAG"
  //       },
  //       {
  //         "name": "Magdalena",
  //         "shortCode": "MAG"
  //       },
  //       {
  //         "name": "Meta",
  //         "shortCode": "MET"
  //       },
  //       {
  //         "name": "Nariño",
  //         "shortCode": "NAR"
  //       },
  //       {
  //         "name": "Norte de Santander",
  //         "shortCode": "NSA"
  //       },
  //       {
  //         "name": "Putumayo",
  //         "shortCode": "PUT"
  //       },
  //       {
  //         "name": "Quindío",
  //         "shortCode": "QUI"
  //       },
  //       {
  //         "name": "Risaralda",
  //         "shortCode": "RIS"
  //       },
  //       {
  //         "name": "Santander",
  //         "shortCode": "SAN"
  //       },
  //       {
  //         "name": "Sucre",
  //         "shortCode": "SUC"
  //       },
  //       {
  //         "name": "Tolima",
  //         "shortCode": "TOL"
  //       },
  //       {
  //         "name": "Valle del Cauca",
  //         "shortCode": "VAC"
  //       },
  //       {
  //         "name": "Vaupés",
  //         "shortCode": "VAU"
  //       },
  //       {
  //         "name": "Vichada",
  //         "shortCode": "VID"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Comoros",
  //     "countryShortCode": "KM",
  //     "regions": [
  //       {
  //         "name": "Andjazîdja",
  //         "shortCode": "G"
  //       },
  //       {
  //         "name": "Andjouân",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "Moûhîlî",
  //         "shortCode": "M"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Congo, Republic of the (Brazzaville)",
  //     "countryShortCode": "CG",
  //     "regions": [
  //       {
  //         "name": "Bouenza",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Brazzaville",
  //         "shortCode": "BZV"
  //       },
  //       {
  //         "name": "Cuvette",
  //         "shortCode": "8"
  //       },
  //       {
  //         "name": "Cuvette-Ouest",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Kouilou",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Lékoumou",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Likouala",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Niari",
  //         "shortCode": "9"
  //       },
  //       {
  //         "name": "Plateaux",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Pointe-Noire",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Pool",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Sangha",
  //         "shortCode": "13"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Congo, the Democratic Republic of the (Kinshasa)",
  //     "countryShortCode": "CD",
  //     "regions": [
  //       {
  //         "name": "Bandundu",
  //         "shortCode": "BN"
  //       },
  //       {
  //         "name": "Bas-Congo",
  //         "shortCode": "BC"
  //       },
  //       {
  //         "name": "Équateur",
  //         "shortCode": "EQ"
  //       },
  //       {
  //         "name": "Kasaï-Occidental",
  //         "shortCode": "KE"
  //       },
  //       {
  //         "name": "Kasaï-Oriental",
  //         "shortCode": "KW"
  //       },
  //       {
  //         "name": "Katanga",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Kinshasa",
  //         "shortCode": "KN"
  //       },
  //       {
  //         "name": "Maniema",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Nord-Kivu",
  //         "shortCode": "NK"
  //       },
  //       {
  //         "name": "Orientale",
  //         "shortCode": "OR"
  //       },
  //       {
  //         "name": "Sud-Kivu",
  //         "shortCode": "SK"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Cook Islands",
  //     "countryShortCode": "CK",
  //     "regions": [
  //       {
  //         "name": "Aitutaki"
  //       },
  //       {
  //         "name": "Atiu"
  //       },
  //       {
  //         "name": "Avarua"
  //       },
  //       {
  //         "name": "Mangaia"
  //       },
  //       {
  //         "name": "Manihiki"
  //       },
  //       {
  //         "name": "Ma'uke"
  //       },
  //       {
  //         "name": "Mitiaro"
  //       },
  //       {
  //         "name": "Nassau"
  //       },
  //       {
  //         "name": "Palmerston"
  //       },
  //       {
  //         "name": "Penrhyn"
  //       },
  //       {
  //         "name": "Pukapuka"
  //       },
  //       {
  //         "name": "Rakahanga"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Costa Rica",
  //     "countryShortCode": "CR",
  //     "regions": [
  //       {
  //         "name": "Alajuela",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Cartago",
  //         "shortCode": "3"
  //       },
  //       {
  //         "name": "Guanacaste",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Heredia",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Limón",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Puntarenas",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "San José",
  //         "shortCode": "1"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Côte d'Ivoire, Republic of",
  //     "countryShortCode": "CI",
  //     "regions": [
  //       {
  //         "name": "Agnéby",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Bafing",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Bas-Sassandra",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Denguélé",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Dix-Huit Montagnes",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Fromager",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Haut-Sassandra",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Lacs",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Lagunes",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Marahoué",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Moyen-Cavally",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Moyen-Comoé",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "N'zi-Comoé",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Savanes",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Sud-Bandama",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Sud-Comoé",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Vallée du Bandama",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Worodougou",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Zanzan",
  //         "shortCode": "08"
  //       }
  //     ]
  //   },
  {
    "countryName": "Croatia",
    "translationKey": "country_name_croatia",
    "countryShortCode": "HR",
    "regions": [
        {
            "name": "Bjelovarsko-bilogorska županija",
            "translationKey": "croatia_region_name_07",
            "shortCode": "07"
        },
        {
            "name": "Brodsko-posavska županija",
            "translationKey": "croatia_region_name_12",
            "shortCode": "12"
        },
        {
            "name": "Dubrovačko-neretvanska županija",
            "translationKey": "croatia_region_name_19",
            "shortCode": "19"
        },
        {
            "name": "Grad Zagreb",
            "translationKey": "croatia_region_name_21",
            "shortCode": "21"
        },
        {
            "name": "Istarska županija",
            "translationKey": "croatia_region_name_18",
            "shortCode": "18"
        },
        {
            "name": "Karlovačka županija",
            "translationKey": "croatia_region_name_04",
            "shortCode": "04"
        },
        {
            "name": "Koprivničko-križevačka županija",
            "translationKey": "croatia_region_name_06",
            "shortCode": "06"
        },
        {
            "name": "Krapinsko-zagorska županija",
            "translationKey": "croatia_region_name_02",
            "shortCode": "02"
        },
        {
            "name": "Ličko-senjska županija",
            "translationKey": "croatia_region_name_09",
            "shortCode": "09"
        },
        {
            "name": "Međimurska županija",
            "translationKey": "croatia_region_name_20",
            "shortCode": "20"
        },
        {
            "name": "Osječko-baranjska županija",
            "translationKey": "croatia_region_name_14",
            "shortCode": "14"
        },
        {
            "name": "Požeško-slavonska županija",
            "translationKey": "croatia_region_name_11",
            "shortCode": "11"
        },
        {
            "name": "Primorsko-goranska županija",
            "translationKey": "croatia_region_name_08",
            "shortCode": "08"
        },
        {
            "name": "Sisačko-moslavačka županija",
            "translationKey": "croatia_region_name_03",
            "shortCode": "03"
        },
        {
            "name": "Splitsko-dalmatinska županija",
            "translationKey": "croatia_region_name_17",
            "shortCode": "17"
        },
        {
            "name": "Šibensko-kninska županija",
            "translationKey": "croatia_region_name_15",
            "shortCode": "15"
        },
        {
            "name": "Varaždinska županija",
            "translationKey": "croatia_region_name_05",
            "shortCode": "05"
        },
        {
            "name": "Virovitičko-podravska županija",
            "translationKey": "croatia_region_name_10",
            "shortCode": "10"
        },
        {
            "name": "Vukovarsko-srijemska županija",
            "translationKey": "croatia_region_name_16",
            "shortCode": "16"
        },
        {
            "name": "Zadarska županija",
            "translationKey": "croatia_region_name_13",
            "shortCode": "13"
        },
        {
            "name": "Zagrebačka županija",
            "translationKey": "croatia_region_name_01",
            "shortCode": "01"
        }
    ]
  },
  //   {
  //     "countryName": "Cuba",
  //     "countryShortCode": "CU",
  //     "regions": [
  //       {
  //         "name": "Artemisa",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Camagüey",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Ciego de Ávila",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Cienfuegos",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Granma",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Guantánamo",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Holguín",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Isla de la Juventud",
  //         "shortCode": "99"
  //       },
  //       {
  //         "name": "La Habana",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Las Tunas",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Matanzas",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Mayabeque",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Pinar del Río",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Sancti Spíritus",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Santiago de Cuba",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Villa Clara",
  //         "shortCode": "05"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Curaçao",
  //     "countryShortCode": "CW",
  //     "regions": [
  //       {
  //         "name": "Curaçao",
  //         "shortCode": "CW"
  //       }
  //     ]
  //   },
  {
    "countryName": "Republic of Cyprus",
    "translationKey": "country_name_cyprus",
    "countryShortCode": "CY",
    "regions": [
        {
            "name": "Ammochostos",
            "translationKey": "cyprus_region_name_04",
            "shortCode": "04"
        },
        {
            "name": "Keryneia",
            "translationKey": "cyprus_region_name_06",
            "shortCode": "06"
        },
        {
            "name": "Larnaka",
            "translationKey": "cyprus_region_name_03",
            "shortCode": "03"
        },
        {
            "name": "Lefkosia",
            "translationKey": "cyprus_region_name_01",
            "shortCode": "01"
        },
        {
            "name": "Lemesos",
            "translationKey": "cyprus_region_name_02",
            "shortCode": "02"
        },
        {
            "name": "Pafos",
            "translationKey": "cyprus_region_name_05",
            "shortCode": "05"
        }
    ]
},
{
    "countryName": "Czech Republic",
    "translationKey": "country_name_czech",
    "countryShortCode": "CZ",
    "regions": [
        {
            "name": "Hlavní město Praha",
            "translationKey": "czech_region_name_pr",
            "shortCode": "PR"
        },
        {
            "name": "Jihočeský kraj",
            "translationKey": "czech_region_name_jc",
            "shortCode": "JC"
        },
        {
            "name": "Jihomoravský kraj",
            "translationKey": "czech_region_name_jm",
            "shortCode": "JM"
        },
        {
            "name": "Karlovarský kraj",
            "translationKey": "czech_region_name_ka",
            "shortCode": "KA"
        },
        {
            "name": "Královéhradecký kraj",
            "translationKey": "czech_region_name_kr",
            "shortCode": "KR"
        },
        {
            "name": "Liberecký kraj",
            "translationKey": "czech_region_name_li",
            "shortCode": "LI"
        },
        {
            "name": "Moravskoslezský kraj",
            "translationKey": "czech_region_name_mo",
            "shortCode": "MO"
        },
        {
            "name": "Olomoucký kraj",
            "translationKey": "czech_region_name_ol",
            "shortCode": "OL"
        },
        {
            "name": "Pardubický kraj",
            "translationKey": "czech_region_name_pa",
            "shortCode": "PA"
        },
        {
            "name": "Plzeňský kraj",
            "translationKey": "czech_region_name_pl",
            "shortCode": "PL"
        },
        {
            "name": "Středočeský kraj",
            "translationKey": "czech_region_name_st",
            "shortCode": "ST"
        },
        {
            "name": "Ústecký kraj",
            "translationKey": "czech_region_name_us",
            "shortCode": "US"
        },
        {
            "name": "Vysočina",
            "translationKey": "czech_region_name_vy",
            "shortCode": "VY"
        },
        {
            "name": "Zlínský kraj",
            "translationKey": "czech_region_name_zl",
            "shortCode": "ZL"
        }
    ]
},
{
    "countryName": "Denmark",
    "translationKey": "country_name_denmark",
    "countryShortCode": "DK",
    "regions": [
        {
            "name": "Hovedstaden",
            "translationKey": "denmark_region_name_84",
            "shortCode": "84"
        },
        {
            "name": "Kujalleq",
            "translationKey": "denmark_region_name_gl_ku",
            "shortCode": "GL-KU"
        },
        {
            "name": "Midtjylland",
            "translationKey": "denmark_region_name_82",
            "shortCode": "82"
        },
        {
            "name": "Norderøerne",
            "translationKey": "denmark_region_name_fo_01",
            "shortCode": "FO-01"
        },
        {
            "name": "Nordjylland",
            "translationKey": "denmark_region_name_81",
            "shortCode": "81"
        },
        {
            "name": "Østerø",
            "translationKey": "denmark_region_name_fo_06",
            "shortCode": "FO-06"
        },
        {
            "name": "Qaasuitsup",
            "translationKey": "denmark_region_name_gl_qa",
            "shortCode": "GL-QA"
        },
        {
            "name": "Qeqqata",
            "translationKey": "denmark_region_name_gl_qe",
            "shortCode": "GL-QE"
        },
        {
            "name": "Sandø",
            "translationKey": "denmark_region_name_fo_02",
            "shortCode": "FO-02"
        },
        {
            "name": "Sermersooq",
            "translationKey": "denmark_region_name_gl_sm",
            "shortCode": "GL-SM"
        },
        {
            "name": "Sjælland",
            "translationKey": "denmark_region_name_85",
            "shortCode": "85"
        },
        {
            "name": "Strømø",
            "translationKey": "denmark_region_name_f0_03",
            "shortCode": "FO-03"
        },
        {
            "name": "Suderø",
            "translationKey": "denmark_region_name_fo_04",
            "shortCode": "FO-04"
        },
        {
            "name": "Syddanmark",
            "translationKey": "denmark_region_name_83",
            "shortCode": "83"
        },
        {
            "name": "Vågø",
            "translationKey": "denmark_region_name_fo_05",
            "shortCode": "FO-05"
        }
    ]
  },
  //   {
  //     "countryName": "Djibouti",
  //     "countryShortCode": "DJ",
  //     "regions": [
  //       {
  //         "name": "Ali Sabieh",
  //         "shortCode": "AS"
  //       },
  //       {
  //         "name": "Arta",
  //         "shortCode": "AR"
  //       },
  //       {
  //         "name": "Dikhil",
  //         "shortCode": "DI"
  //       },
  //       {
  //         "name": "Obock",
  //         "shortCode": "OB"
  //       },
  //       {
  //         "name": "Tadjourah",
  //         "shortCode": "TA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Dominica",
  //     "countryShortCode": "DM",
  //     "regions": [
  //       {
  //         "name": "Saint Andrew Parish",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Saint David Parish",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Saint George Parish",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Saint John Parish",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Saint Joseph Parish",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Saint Luke Parish",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Saint Mark Parish",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Saint Patrick Parish",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Saint Paul Parish",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Saint Peter Parish",
  //         "shortCode": "11"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Dominican Republic",
  //     "countryShortCode": "DO",
  //     "regions": [
  //       {
  //         "name": "Cibao Central",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Del Valle",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "Distrito Nacional",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Enriquillo",
  //         "shortCode": "38"
  //       },
  //       {
  //         "name": "Norcentral",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Nordeste",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Noroeste",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Norte",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Valdesia",
  //         "shortCode": "42"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Ecuador",
  //     "countryShortCode": "EC",
  //     "regions": [
  //       {
  //         "name": "Azuay",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "Bolívar",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Cañar",
  //         "shortCode": "F"
  //       },
  //       {
  //         "name": "Carchi",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Chimborazo",
  //         "shortCode": "H"
  //       },
  //       {
  //         "name": "Cotopaxi",
  //         "shortCode": "X"
  //       },
  //       {
  //         "name": "El Oro",
  //         "shortCode": "O"
  //       },
  //       {
  //         "name": "Esmeraldas",
  //         "shortCode": "E"
  //       },
  //       {
  //         "name": "Galápagos",
  //         "shortCode": "W"
  //       },
  //       {
  //         "name": "Guayas",
  //         "shortCode": "G"
  //       },
  //       {
  //         "name": "Imbabura",
  //         "shortCode": "I"
  //       },
  //       {
  //         "name": "Loja",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "Los Ríos",
  //         "shortCode": "R"
  //       },
  //       {
  //         "name": "Manabí",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "Morona-Santiago",
  //         "shortCode": "S"
  //       },
  //       {
  //         "name": "Napo",
  //         "shortCode": "N"
  //       },
  //       {
  //         "name": "Orellana",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "Pastaza",
  //         "shortCode": "Y"
  //       },
  //       {
  //         "name": "Pichincha",
  //         "shortCode": "P"
  //       },
  //       {
  //         "name": "Santa Elena",
  //         "shortCode": "SE"
  //       },
  //       {
  //         "name": "Santo Domingo de los Tsáchilas",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Sucumbíos",
  //         "shortCode": "U"
  //       },
  //       {
  //         "name": "Tungurahua",
  //         "shortCode": "T"
  //       },
  //       {
  //         "name": "Zamora-Chinchipe",
  //         "shortCode": "Z"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Egypt",
  //     "countryShortCode": "EG",
  //     "regions": [
  //       {
  //         "name": "Alexandria",
  //         "shortCode": "ALX"
  //       },
  //       {
  //         "name": "Aswan",
  //         "shortCode": "ASN"
  //       },
  //       {
  //         "name": "Asyout",
  //         "shortCode": "AST"
  //       },
  //       {
  //         "name": "Bani Sueif",
  //         "shortCode": "BNS"
  //       },
  //       {
  //         "name": "Beheira",
  //         "shortCode": "BH"
  //       },
  //       {
  //         "name": "Cairo",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Daqahlia",
  //         "shortCode": "DK"
  //       },
  //       {
  //         "name": "Dumiat",
  //         "shortCode": "DT"
  //       },
  //       {
  //         "name": "El Bahr El Ahmar",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "El Ismailia",
  //         "shortCode": "IS"
  //       },
  //       {
  //         "name": "El Suez",
  //         "shortCode": "SUZ"
  //       },
  //       {
  //         "name": "El Wadi El Gedeed",
  //         "shortCode": "WAD"
  //       },
  //       {
  //         "name": "Fayoum",
  //         "shortCode": "FYM"
  //       },
  //       {
  //         "name": "Gharbia",
  //         "shortCode": "GH"
  //       },
  //       {
  //         "name": "Giza",
  //         "shortCode": "GZ"
  //       },
  //       {
  //         "name": "Helwan",
  //         "shortCode": "HU"
  //       },
  //       {
  //         "name": "Kafr El Sheikh",
  //         "shortCode": "KFS"
  //       },
  //       {
  //         "name": "Luxor",
  //         "shortCode": "LX"
  //       },
  //       {
  //         "name": "Matrouh",
  //         "shortCode": "MT"
  //       },
  //       {
  //         "name": "Menia",
  //         "shortCode": "MN"
  //       },
  //       {
  //         "name": "Menofia",
  //         "shortCode": "MNF"
  //       },
  //       {
  //         "name": "North Sinai",
  //         "shortCode": "SIN"
  //       },
  //       {
  //         "name": "Port Said",
  //         "shortCode": "PTS"
  //       },
  //       {
  //         "name": "Qalubia",
  //         "shortCode": "KB"
  //       },
  //       {
  //         "name": "Qena",
  //         "shortCode": "KN"
  //       },
  //       {
  //         "name": "Sharqia",
  //         "shortCode": "SHR"
  //       },
  //       {
  //         "name": "Sixth of October",
  //         "shortCode": "SU"
  //       },
  //       {
  //         "name": "Sohag",
  //         "shortCode": "SHG"
  //       },
  //       {
  //         "name": "South Sinai",
  //         "shortCode": "JS"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "El Salvador",
  //     "countryShortCode": "SV",
  //     "regions": [
  //       {
  //         "name": "Ahuachapán",
  //         "shortCode": "AH"
  //       },
  //       {
  //         "name": "Cabañas",
  //         "shortCode": "CA"
  //       },
  //       {
  //         "name": "Cuscatlán",
  //         "shortCode": "CU"
  //       },
  //       {
  //         "name": "Chalatenango",
  //         "shortCode": "CH"
  //       },
  //       {
  //         "name": "La Libertad",
  //         "shortCode": "LI"
  //       },
  //       {
  //         "name": "La Paz",
  //         "shortCode": "PA"
  //       },
  //       {
  //         "name": "La Unión",
  //         "shortCode": "UN"
  //       },
  //       {
  //         "name": "Morazán",
  //         "shortCode": "MO"
  //       },
  //       {
  //         "name": "San Miguel",
  //         "shortCode": "SM"
  //       },
  //       {
  //         "name": "San Salvador",
  //         "shortCode": "SS"
  //       },
  //       {
  //         "name": "Santa Ana",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "San Vicente",
  //         "shortCode": "SV"
  //       },
  //       {
  //         "name": "Sonsonate",
  //         "shortCode": "SO"
  //       },
  //       {
  //         "name": "Usulután",
  //         "shortCode": "US"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Equatorial Guinea",
  //     "countryShortCode": "GQ",
  //     "regions": [
  //       {
  //         "name": "Annobón",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Bioko Norte",
  //         "shortCode": "BN"
  //       },
  //       {
  //         "name": "Bioko Sur",
  //         "shortCode": "BS"
  //       },
  //       {
  //         "name": "Centro Sur",
  //         "shortCode": "CS"
  //       },
  //       {
  //         "name": "Kié-Ntem",
  //         "shortCode": "KN"
  //       },
  //       {
  //         "name": "Litoral",
  //         "shortCode": "LI"
  //       },
  //       {
  //         "name": "Wele-Nzas",
  //         "shortCode": "WN"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Eritrea",
  //     "countryShortCode": "ER",
  //     "regions": [
  //       {
  //         "name": "Anseba",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Debub",
  //         "shortCode": "DU"
  //       },
  //       {
  //         "name": "Debub-Keih-Bahri",
  //         "shortCode": "DK"
  //       },
  //       {
  //         "name": "Gash-Barka",
  //         "shortCode": "GB"
  //       },
  //       {
  //         "name": "Maekel",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Semien-Keih-Bahri",
  //         "shortCode": "SK"
  //       }
  //     ]
  //   },
  {
    "countryName": "Estonia",
    "translationKey": "country_name_estonia",
    "countryShortCode": "EE",
    "regions": [
        {
            "name": "Harjumaa (Tallinn)",
            "translationKey": "estonia_region_name_37",
            "shortCode": "37"
        },
        {
            "name": "Hiiumaa (Kardla)",
            "translationKey": "estonia_region_name_39",
            "shortCode": "39"
        },
        {
            "name": "Ida-Virumaa (Johvi)",
            "translationKey": "estonia_region_name_44",
            "shortCode": "44"
        },
        {
            "name": "Järvamaa (Paide)",
            "translationKey": "estonia_region_name_41",
            "shortCode": "41"
        },
        {
            "name": "Jõgevamaa (Jogeva)",
            "translationKey": "estonia_region_name_49",
            "shortCode": "49"
        },
        {
            "name": "Läänemaa",
            "translationKey": "estonia_region_name_57",
            "shortCode": "57"
        },
        {
            "name": "Lääne-Virumaa (Rakvere)",
            "translationKey": "estonia_region_name_59",
            "shortCode": "59"
        },
        {
            "name": "Pärnumaa (Parnu)",
            "translationKey": "estonia_region_name_67",
            "shortCode": "67"
        },
        {
            "name": "Põlvamaa (Polva)",
            "translationKey": "estonia_region_name_65",
            "shortCode": "65"
        },
        {
            "name": "Raplamaa (Rapla)",
            "translationKey": "estonia_region_name_70",
            "shortCode": "70"
        },
        {
            "name": "Saaremaa (Kuessaare)",
            "translationKey": "estonia_region_name_74",
            "shortCode": "74"
        },
        {
            "name": "Tartumaa (Tartu)",
            "translationKey": "estonia_region_name_78",
            "shortCode": "78"
        },
        {
            "name": "Valgamaa (Valga)",
            "translationKey": "estonia_region_name_82",
            "shortCode": "82"
        },
        {
            "name": "Viljandimaa (Viljandi)",
            "translationKey": "estonia_region_name_84",
            "shortCode": "84"
        },
        {
            "name": "Võrumaa (Voru)",
            "translationKey": "estonia_region_name_86",
            "shortCode": "86"
        }
    ]
  },
  //   {
  //     "countryName": "Ethiopia",
  //     "countryShortCode": "ET",
  //     "regions": [
  //       {
  //         "name": "Addis Ababa",
  //         "shortCode": "AA"
  //       },
  //       {
  //         "name": "Afar",
  //         "shortCode": "AF"
  //       },
  //       {
  //         "name": "Amhara",
  //         "shortCode": "AM"
  //       },
  //       {
  //         "name": "Benshangul-Gumaz",
  //         "shortCode": "BE"
  //       },
  //       {
  //         "name": "Dire Dawa",
  //         "shortCode": "DD"
  //       },
  //       {
  //         "name": "Gambela",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Harari",
  //         "shortCode": "HA"
  //       },
  //       {
  //         "name": "Oromia",
  //         "shortCode": "OR"
  //       },
  //       {
  //         "name": "Somali",
  //         "shortCode": "SO"
  //       },
  //       {
  //         "name": "Southern Nations Nationalities and People's Region",
  //         "shortCode": "SN"
  //       },
  //       {
  //         "name": "Tigray",
  //         "shortCode": "TI"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Falkland Islands (Islas Malvinas)",
  //     "countryShortCode": "FK",
  //     "regions": [
  //       {
  //         "name": "Falkland Islands (Islas Malvinas)"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Faroe Islands",
  //     "countryShortCode": "FO",
  //     "regions": [
  //       {
  //         "name": "Bordoy"
  //       },
  //       {
  //         "name": "Eysturoy"
  //       },
  //       {
  //         "name": "Mykines"
  //       },
  //       {
  //         "name": "Sandoy"
  //       },
  //       {
  //         "name": "Skuvoy"
  //       },
  //       {
  //         "name": "Streymoy"
  //       },
  //       {
  //         "name": "Suduroy"
  //       },
  //       {
  //         "name": "Tvoroyri"
  //       },
  //       {
  //         "name": "Vagar"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Fiji",
  //     "countryShortCode": "FJ",
  //     "regions": [
  //       {
  //         "name": "Ba",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Bua",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Cakaudrove",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Kadavu",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Lau",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Lomaiviti",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Macuata",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Nadroga and Navosa",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Naitasiri",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Namosi",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Ra",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Rewa",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Rotuma",
  //         "shortCode": "R"
  //       },
  //       {
  //         "name": "Serua",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Tailevu",
  //         "shortCode": "14"
  //       }
  //     ]
  //   },
  {
    "countryName": "Finland",
    "translationKey": "country_name_finland",
    "countryShortCode": "FI",
    "regions": [
        {
            "name": "Ahvenanmaan maakunta",
            "translationKey": "finland_region_name_fi_01",
            "shortCode": "FI-01"
        },
        {
            "name": "Etelä-Karjala",
            "translationKey": "finland_region_name_fi_02",
            "shortCode": "FI-02"
        },
        {
            "name": "Etelä-Pohjanmaa",
            "translationKey": "finland_region_name_fi_03",
            "shortCode": "FI-03"
        },
        {
            "name": "Etelä-Savo",
            "translationKey": "finland_region_name_fi_04",
            "shortCode": "FI-04"
        },
        {
            "name": "Kainuu",
            "translationKey": "finland_region_name_fi_05",
            "shortCode": "FI-05"
        },
        {
            "name": "Kanta-Häme",
            "translationKey": "finland_region_name_fi_06",
            "shortCode": "FI-06"
        },
        {
            "name": "Keski-Pohjanmaa",
            "translationKey": "finland_region_name_fi_07",
            "shortCode": "FI-07"
        },
        {
            "name": "Keski-Suomi",
            "translationKey": "finland_region_name_fi_08",
            "shortCode": "FI-08"
        },
        {
            "name": "Kymenlaakso",
            "translationKey": "finland_region_name_fi_09",
            "shortCode": "FI-09"
        },
        {
            "name": "Lappi",
            "translationKey": "finland_region_name_fi_10",
            "shortCode": "FI-10"
        },
        {
            "name": "Pirkanmaa",
            "translationKey": "finland_region_name_fi_11",
            "shortCode": "FI-11"
        },
        {
            "name": "Pohjanmaa",
            "translationKey": "finland_region_name_fi_12",
            "shortCode": "FI-12"
        },
        {
            "name": "Pohjois-Karjala",
            "translationKey": "finland_region_name_fi_13",
            "shortCode": "FI-13"
        },
        {
            "name": "Pohjois-Pohjanmaa",
            "translationKey": "finland_region_name_fi_14",
            "shortCode": "FI-14"
        },
        {
            "name": "Pohjois-Savo",
            "translationKey": "finland_region_name_fi_15",
            "shortCode": "FI-15"
        },
        {
            "name": "Päijät-Häme",
            "translationKey": "finland_region_name_fi_16",
            "shortCode": "FI-16"
        },
        {
            "name": "Satakunta",
            "translationKey": "finland_region_name_fi_17",
            "shortCode": "FI-17"
        },
        {
            "name": "Uusimaa",
            "translationKey": "finland_region_name_fi_18",
            "shortCode": "FI-18"
        },
        {
            "name": "Varsinais-Suomi",
            "translationKey": "finland_region_name_fi_19",
            "shortCode": "FI-19"
        }
    ]
  },
  {
    "countryName": "France",
    "translationKey": "country_name_france",
    "countryShortCode": "FR",
    "regions": [
        {
            "name": "Auvergne-Rhône-Alpes",
            "translationKey": "france_region_name_ara",
            "shortCode": "ARA"
        },
        {
            "name": "Bourgogne-Franche-Comté",
            "translationKey": "france_region_name_bfc",
            "shortCode": "BFC"
        },
        {
            "name": "Bretagne",
            "translationKey": "france_region_name_bre",
            "shortCode": "BRE"
        },
        {
            "name": "Centre-Val de Loire",
            "translationKey": "france_region_name_cvl",
            "shortCode": "CVL"
        },
        {
            "name": "Corse",
            "translationKey": "france_region_name_cor",
            "shortCode": "COR"
        },
        {
            "name": "Grand Est",
            "translationKey": "france_region_name_ges",
            "shortCode": "GES"
        },
        {
            "name": "Hauts-de-France",
            "translationKey": "france_region_name_hdf",
            "shortCode": "HDF"
        },
        {
            "name": "Île-de-France",
            "translationKey": "france_region_name_idf",
            "shortCode": "IDF"
        },
        {
            "name": "Normandie",
            "translationKey": "france_region_name_nor",
            "shortCode": "NOR"
        },
        {
            "name": "Nouvelle-Aquitaine",
            "translationKey": "france_region_name_naq",
            "shortCode": "NAQ"
        },
        {
            "name": "Occitanie",
            "translationKey": "france_region_name_occ",
            "shortCode": "OCC"
        },
        {
            "name": "Pays de la Loire",
            "translationKey": "france_region_name_pdl",
            "shortCode": "PDL"
        },
        {
            "name": "Provence-Alpes-Cote d'Azur",
            "translationKey": "france_region_name_pac",
            "shortCode": "PAC"
        },
        {
            "name": "Clipperton",
            "translationKey": "france_region_name_cp",
            "shortCode": "CP"
        },
        {
            "name": "Guadeloupe",
            "translationKey": "france_region_name_gp",
            "shortCode": "GP"
        },
        {
            "name": "Guyane",
            "translationKey": "france_region_name_gf",
            "shortCode": "GF"
        },
        {
            "name": "Martinique",
            "translationKey": "france_region_name_mq",
            "shortCode": "MQ"
        },
        {
            "name": "Mayotte",
            "translationKey": "france_region_name_yt",
            "shortCode": "YT"
        },
        {
            "name": "Novelle-Calédonie",
            "translationKey": "france_region_name_nc",
            "shortCode": "NC"
        },
        {
            "name": "Polynésie",
            "translationKey": "france_region_name_pf",
            "shortCode": "PF"
        },
        {
            "name": "Saint-Pierre-et-Miquelon",
            "translationKey": "france_region_name_pm",
            "shortCode": "PM"
        },
        {
            "name": "Saint Barthélemy",
            "translationKey": "france_region_name_bl",
            "shortCode": "BL"
        },
        {
            "name": "Saint Martin",
            "translationKey": "france_region_name_mf",
            "shortCode": "MF"
        },
        {
            "name": "Réunion",
            "translationKey": "france_region_name_re",
            "shortCode": "RE"
        },
        {
            "name": "Terres Australes Françaises",
            "translationKey": "france_region_name_tf",
            "shortCode": "TF"
        },
        {
            "name": "Wallis-et-Futuna",
            "translationKey": "france_region_name_wf",
            "shortCode": "WF"
        }
    ]
  },
  //   {
  //     "countryName": "French Guiana",
  //     "countryShortCode": "GF",
  //     "regions": [
  //       {
  //         "name": "French Guiana"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "French Polynesia",
  //     "countryShortCode": "PF",
  //     "regions": [
  //       {
  //         "name": "Archipel des Marquises"
  //       },
  //       {
  //         "name": "Archipel des Tuamotu"
  //       },
  //       {
  //         "name": "Archipel des Tubuai"
  //       },
  //       {
  //         "name": "Iles du Vent"
  //       },
  //       {
  //         "name": "Iles Sous-le-Vent"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "French Southern and Antarctic Lands",
  //     "countryShortCode": "TF",
  //     "regions": [
  //       {
  //         "name": "Adelie Land"
  //       },
  //       {
  //         "name": "Ile Crozet"
  //       },
  //       {
  //         "name": "Iles Kerguelen"
  //       },
  //       {
  //         "name": "Iles Saint-Paul et Amsterdam"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Gabon",
  //     "countryShortCode": "GA",
  //     "regions": [
  //       {
  //         "name": "Estuaire",
  //         "shortCode": "1"
  //       },
  //       {
  //         "name": "Haut-Ogooué",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Moyen-Ogooué",
  //         "shortCode": "3"
  //       },
  //       {
  //         "name": "Ngounié",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Nyanga",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Ogooué-Ivindo",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "Ogooué-Lolo",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Ogooué-Maritime",
  //         "shortCode": "8"
  //       },
  //       {
  //         "name": "Woleu-Ntem",
  //         "shortCode": "9"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Gambia, The",
  //     "countryShortCode": "GM",
  //     "regions": [
  //       {
  //         "name": "Banjul",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Central River",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "Lower River",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "North Bank",
  //         "shortCode": "N"
  //       },
  //       {
  //         "name": "Upper River",
  //         "shortCode": "U"
  //       },
  //       {
  //         "name": "Western",
  //         "shortCode": "W"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Georgia",
  //     "countryShortCode": "GE",
  //     "regions": [
  //       {
  //         "name": "Abkhazia (Sokhumi)",
  //         "shortCode": "AB"
  //       },
  //       {
  //         "name": "Ajaria (Bat'umi)",
  //         "shortCode": "AJ"
  //       },
  //       {
  //         "name": "Guria",
  //         "shortCode": "GU"
  //       },
  //       {
  //         "name": "Imereti",
  //         "shortCode": "IM"
  //       },
  //       {
  //         "name": "K'akheti",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Kvemo Kartli",
  //         "shortCode": "KK"
  //       },
  //       {
  //         "name": "Mtshkheta-Mtianeti",
  //         "shortCode": "MM"
  //       },
  //       {
  //         "name": "Rach'a-Lexhkumi-KvemoSvaneti",
  //         "shortCode": "RL"
  //       },
  //       {
  //         "name": "Samegrelo-Zemo Svaneti",
  //         "shortCode": "SZ"
  //       },
  //       {
  //         "name": "Samtskhe-Javakheti",
  //         "shortCode": "SJ"
  //       },
  //       {
  //         "name": "Shida Kartli",
  //         "shortCode": "SK"
  //       },
  //       {
  //         "name": "Tbilisi",
  //         "shortCode": "TB"
  //       }
  //     ]
  //   },
  {
    "countryName": "Germany",
    "translationKey": "country_name_germany",
    "countryShortCode": "DE",
    "regions": [
        {
            "name": "Baden-Württemberg",
            "translationKey": "germany_region_name_bw",
            "shortCode": "BW"
        },
        {
            "name": "Bayern",
            "translationKey": "germany_region_name_by",
            "shortCode": "BY"
        },
        {
            "name": "Berlin",
            "translationKey": "germany_region_name_be",
            "shortCode": "BE"
        },
        {
            "name": "Brandenburg",
            "translationKey": "germany_region_name_bb",
            "shortCode": "BB"
        },
        {
            "name": "Bremen",
            "translationKey": "germany_region_name_hb",
            "shortCode": "HB"
        },
        {
            "name": "Hamburg",
            "translationKey": "germany_region_name_hh",
            "shortCode": "HH"
        },
        {
            "name": "Hessen",
            "translationKey": "germany_region_name_he",
            "shortCode": "HE"
        },
        {
            "name": "Mecklenburg-Vorpommern",
            "translationKey": "germany_region_name_mv",
            "shortCode": "MV"
        },
        {
            "name": "Niedersachsen",
            "translationKey": "germany_region_name_ni",
            "shortCode": "NI"
        },
        {
            "name": "Nordrhein-Westfalen",
            "translationKey": "germany_region_name_nw",
            "shortCode": "NW"
        },
        {
            "name": "Rheinland-Pfalz",
            "translationKey": "germany_region_name_rp",
            "shortCode": "RP"
        },
        {
            "name": "Saarland",
            "translationKey": "germany_region_name_sl",
            "shortCode": "SL"
        },
        {
            "name": "Sachsen",
            "translationKey": "germany_region_name_sn",
            "shortCode": "SN"
        },
        {
            "name": "Sachsen-Anhalt",
            "translationKey": "germany_region_name_st",
            "shortCode": "ST"
        },
        {
            "name": "Schleswig-Holstein",
            "translationKey": "germany_region_name_sh",
            "shortCode": "SH"
        },
        {
            "name": "Thüringen",
            "translationKey": "germany_region_name_th",
            "shortCode": "TH"
        }
    ]
  },
  //   {
  //     "countryName": "Ghana",
  //     "countryShortCode": "GH",
  //     "regions": [
  //       {
  //         "name": "Ahafo"
  //       },
  //       {
  //         "name": "Ashanti",
  //         "shortCode": "AH"
  //       },
  //       {
  //         "name": "Bono"
  //       },
  //       {
  //         "name": "Bono East"
  //       },
  //       {
  //         "name": "Central",
  //         "shortCode": "CP"
  //       },
  //       {
  //         "name": "Eastern",
  //         "shortCode": "EP"
  //       },
  //       {
  //         "name": "Greater Accra",
  //         "shortCode": "AA"
  //       },
  //       {
  //         "name": "Northern",
  //         "shortCode": "NP"
  //       },
  //       {
  //         "name": "North East"
  //       },
  //       {
  //         "name": "Oti"
  //       },
  //       {
  //         "name": "Savannah"
  //       },
  //       {
  //         "name": "Upper East",
  //         "shortCode": "UE"
  //       },
  //       {
  //         "name": "Upper West",
  //         "shortCode": "UW"
  //       },
  //       {
  //         "name": "Volta",
  //         "shortCode": "TV"
  //       },
  //       {
  //         "name": "Western",
  //         "shortCode": "WP"
  //       },
  //       {
  //         "name": "Western North"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Gibraltar",
  //     "countryShortCode": "GI",
  //     "regions": [
  //       {
  //         "name": "Gibraltar"
  //       }
  //     ]
  //   },
  {
    "countryName": "Greece",
    "translationKey": "country_name_greece",
    "countryShortCode": "GR",
    "regions": [
        {
            "name": "Anatolikí Makedonía kai Thráki",
            "translationKey": "greece_region_name_a",
            "shortCode": "A"
        },
        {
            "name": "Attikḯ",
            "translationKey": "greece_region_name_i",
            "shortCode": "I"
        },
        {
            "name": "Dytikí Elláda",
            "translationKey": "greece_region_name_g",
            "shortCode": "G"
        },
        {
            "name": "Dytikí Makedonía",
            "translationKey": "greece_region_name_c",
            "shortCode": "C"
        },
        {
            "name": "Ionía Nísia",
            "translationKey": "greece_region_name_f",
            "shortCode": "F"
        },
        {
            "name": "Kentrikí Makedonía",
            "translationKey": "greece_region_name_b",
            "shortCode": "B"
        },
        {
            "name": "Krítí",
            "translationKey": "greece_region_name_m",
            "shortCode": "M"
        },
        {
            "name": "Notío Aigaío",
            "translationKey": "greece_region_name_l",
            "shortCode": "L"
        },
        {
            "name": "Peloponnísos",
            "translationKey": "greece_region_name_j",
            "shortCode": "J"
        },
        {
            "name": "Stereá Elláda",
            "translationKey": "greece_region_name_h",
            "shortCode": "H"
        },
        {
            "name": "Thessalía",
            "translationKey": "greece_region_name_e",
            "shortCode": "E"
        },
        {
            "name": "Voreío Aigaío",
            "translationKey": "greece_region_name_k",
            "shortCode": "K"
        },
        {
            "name": "Ípeiros",
            "translationKey": "greece_region_name_d",
            "shortCode": "D"
        },
        {
            "name": "Ágion Óros",
            "translationKey": "greece_region_name_69",
            "shortCode": "69"
        }
    ]
  },
  //   {
  //     "countryName": "Greenland",
  //     "countryShortCode": "GL",
  //     "regions": [
  //       {
  //         "name": "Kommune Kujalleq",
  //         "shortCode": "KU"
  //       },
  //       {
  //         "name": "Kommuneqarfik Sermersooq",
  //         "shortCode": "SM"
  //       },
  //       {
  //         "name": "Qaasuitsup Kommunia",
  //         "shortCode": "QA"
  //       },
  //       {
  //         "name": "Qeqqata Kommunia",
  //         "shortCode": "QE"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Grenada",
  //     "countryShortCode": "GD",
  //     "regions": [
  //       {
  //         "name": "Saint Andrew",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Saint David",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Saint George",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Saint John",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Saint Mark",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Saint Patrick",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Southern Grenadine Islands",
  //         "shortCode": "10"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Guadeloupe",
  //     "countryShortCode": "GP",
  //     "regions": [
  //       {
  //         "name": "Guadeloupe"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Guam",
  //     "countryShortCode": "GU",
  //     "regions": [
  //       {
  //         "name": "Guam"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Guatemala",
  //     "countryShortCode": "GT",
  //     "regions": [
  //       {
  //         "name": "Alta Verapaz",
  //         "shortCode": "AV"
  //       },
  //       {
  //         "name": "Baja Verapaz",
  //         "shortCode": "BV"
  //       },
  //       {
  //         "name": "Chimaltenango",
  //         "shortCode": "CM"
  //       },
  //       {
  //         "name": "Chiquimula",
  //         "shortCode": "CQ"
  //       },
  //       {
  //         "name": "El Progreso",
  //         "shortCode": "PR"
  //       },
  //       {
  //         "name": "Escuintla",
  //         "shortCode": "ES"
  //       },
  //       {
  //         "name": "Guatemala",
  //         "shortCode": "GU"
  //       },
  //       {
  //         "name": "Huehuetenango",
  //         "shortCode": "HU"
  //       },
  //       {
  //         "name": "Izabal",
  //         "shortCode": "IZ"
  //       },
  //       {
  //         "name": "Jalapa",
  //         "shortCode": "JA"
  //       },
  //       {
  //         "name": "Jutiapa",
  //         "shortCode": "JU"
  //       },
  //       {
  //         "name": "Petén",
  //         "shortCode": "PE"
  //       },
  //       {
  //         "name": "Quetzaltenango",
  //         "shortCode": "QZ"
  //       },
  //       {
  //         "name": "Quiché",
  //         "shortCode": "QC"
  //       },
  //       {
  //         "name": "Retalhuleu",
  //         "shortCode": "Re"
  //       },
  //       {
  //         "name": "Sacatepéquez",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "San Marcos",
  //         "shortCode": "SM"
  //       },
  //       {
  //         "name": "Santa Rosa",
  //         "shortCode": "SR"
  //       },
  //       {
  //         "name": "Sololá",
  //         "shortCode": "SO"
  //       },
  //       {
  //         "name": "Suchitepéquez",
  //         "shortCode": "SU"
  //       },
  //       {
  //         "name": "Totonicapán",
  //         "shortCode": "TO"
  //       },
  //       {
  //         "name": "Zacapa",
  //         "shortCode": "ZA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Guernsey",
  //     "countryShortCode": "GG",
  //     "regions": [
  //       {
  //         "name": "Castel"
  //       },
  //       {
  //         "name": "Forest"
  //       },
  //       {
  //         "name": "St. Andrew"
  //       },
  //       {
  //         "name": "St. Martin"
  //       },
  //       {
  //         "name": "St. Peter Port"
  //       },
  //       {
  //         "name": "St. Pierre du Bois"
  //       },
  //       {
  //         "name": "St. Sampson"
  //       },
  //       {
  //         "name": "St. Saviour"
  //       },
  //       {
  //         "name": "Torteval"
  //       },
  //       {
  //         "name": "Vale"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Guinea",
  //     "countryShortCode": "GN",
  //     "regions": [
  //       {
  //         "name": "Boké",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Conakry",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Faranah",
  //         "shortCode": "F"
  //       },
  //       {
  //         "name": "Kankan",
  //         "shortCode": "K"
  //       },
  //       {
  //         "name": "Kindia",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "Labé",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "Mamou",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "Nzérékoré",
  //         "shortCode": "N"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Guinea-Bissau",
  //     "countryShortCode": "GW",
  //     "regions": [
  //       {
  //         "name": "Bafatá",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Biombo",
  //         "shortCode": "BM"
  //       },
  //       {
  //         "name": "Bissau",
  //         "shortCode": "BS"
  //       },
  //       {
  //         "name": "Bolama-Bijagos",
  //         "shortCode": "BL"
  //       },
  //       {
  //         "name": "Cacheu",
  //         "shortCode": "CA"
  //       },
  //       {
  //         "name": "Gabú",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Oio",
  //         "shortCode": "OI"
  //       },
  //       {
  //         "name": "Quinara",
  //         "shortCode": "QU"
  //       },
  //       {
  //         "name": "Tombali",
  //         "shortCode": "TO"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Guyana",
  //     "countryShortCode": "GY",
  //     "regions": [
  //       {
  //         "name": "Barima-Waini",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Cuyuni-Mazaruni",
  //         "shortCode": "CU"
  //       },
  //       {
  //         "name": "Demerara-Mahaica",
  //         "shortCode": "DE"
  //       },
  //       {
  //         "name": "East Berbice-Corentyne",
  //         "shortCode": "EB"
  //       },
  //       {
  //         "name": "Essequibo Islands-West Demerara",
  //         "shortCode": "ES"
  //       },
  //       {
  //         "name": "Mahaica-Berbice",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Pomeroon-Supenaam",
  //         "shortCode": "PM"
  //       },
  //       {
  //         "name": "Potaro-Siparuni",
  //         "shortCode": "PT"
  //       },
  //       {
  //         "name": "Upper Demerara-Berbice",
  //         "shortCode": "UD"
  //       },
  //       {
  //         "name": "Upper Takutu-Upper Essequibo",
  //         "shortCode": "UT"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Haiti",
  //     "countryShortCode": "HT",
  //     "regions": [
  //       {
  //         "name": "Artibonite",
  //         "shortCode": "AR"
  //       },
  //       {
  //         "name": "Centre",
  //         "shortCode": "CE"
  //       },
  //       {
  //         "name": "Grand'Anse",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Nippes",
  //         "shortCode": "NI"
  //       },
  //       {
  //         "name": "Nord",
  //         "shortCode": "ND"
  //       },
  //       {
  //         "name": "Nord-Est",
  //         "shortCode": "NE"
  //       },
  //       {
  //         "name": "Nord-Ouest",
  //         "shortCode": "NO"
  //       },
  //       {
  //         "name": "Ouest",
  //         "shortCode": "OU"
  //       },
  //       {
  //         "name": "Sud",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Sud-Est",
  //         "shortCode": "SE"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Heard Island and McDonald Islands",
  //     "countryShortCode": "HM",
  //     "regions": [
  //       {
  //         "name": "Heard Island and McDonald Islands"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Holy See (Vatican City)",
  //     "countryShortCode": "VA",
  //     "regions": [
  //       {
  //         "name": "Holy See (Vatican City)",
  //         "shortCode": "01"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Honduras",
  //     "countryShortCode": "HN",
  //     "regions": [
  //       {
  //         "name": "Atlántida",
  //         "shortCode": "AT"
  //       },
  //       {
  //         "name": "Choluteca",
  //         "shortCode": "CH"
  //       },
  //       {
  //         "name": "Colón",
  //         "shortCode": "CL"
  //       },
  //       {
  //         "name": "Comayagua",
  //         "shortCode": "CM"
  //       },
  //       {
  //         "name": "Copán",
  //         "shortCode": "CP"
  //       },
  //       {
  //         "name": "Cortés",
  //         "shortCode": "CR"
  //       },
  //       {
  //         "name": "El Paraíso",
  //         "shortCode": "EP"
  //       },
  //       {
  //         "name": "Francisco Morazan",
  //         "shortCode": "FM"
  //       },
  //       {
  //         "name": "Gracias a Dios",
  //         "shortCode": "GD"
  //       },
  //       {
  //         "name": "Intibucá",
  //         "shortCode": "IN"
  //       },
  //       {
  //         "name": "Islas de la Bahía",
  //         "shortCode": "IB"
  //       },
  //       {
  //         "name": "La Paz",
  //         "shortCode": "LP"
  //       },
  //       {
  //         "name": "Lempira",
  //         "shortCode": "LE"
  //       },
  //       {
  //         "name": "Ocotepeque",
  //         "shortCode": "OC"
  //       },
  //       {
  //         "name": "Olancho",
  //         "shortCode": "OL"
  //       },
  //       {
  //         "name": "Santa Bárbara",
  //         "shortCode": "SB"
  //       },
  //       {
  //         "name": "Valle",
  //         "shortCode": "VA"
  //       },
  //       {
  //         "name": "Yoro",
  //         "shortCode": "YO"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Hong Kong",
  //     "countryShortCode": "HK",
  //     "regions": [
  //       {
  //         "name": "Hong Kong",
  //         "shortCode": "HK"
  //       }
  //     ]
  //   },
  {
    "countryName": "Hungary",
    "translationKey": "country_name_hungary",
    "countryShortCode": "HU",
    "regions": [
        {
            "name": "Bács-Kiskun",
            "translationKey": "hungary_region_name_bk",
            "shortCode": "BK"
        },
        {
            "name": "Baranya",
            "translationKey": "hungary_region_name_ba",
            "shortCode": "BA"
        },
        {
            "name": "Békés",
            "translationKey": "hungary_region_name_be",
            "shortCode": "BE"
        },
        {
            "name": "Békéscsaba",
            "translationKey": "hungary_region_name_bc",
            "shortCode": "BC"
        },
        {
            "name": "Borsod-Abauj-Zemplen",
            "translationKey": "hungary_region_name_bz",
            "shortCode": "BZ"
        },
        {
            "name": "Budapest",
            "translationKey": "hungary_region_name_bu",
            "shortCode": "BU"
        },
        {
            "name": "Csongrád",
            "translationKey": "hungary_region_name_cs",
            "shortCode": "CS"
        },
        {
            "name": "Debrecen",
            "translationKey": "hungary_region_name_de",
            "shortCode": "DE"
        },
        {
            "name": "Dunaújváros",
            "translationKey": "hungary_region_name_du",
            "shortCode": "DU"
        },
        {
            "name": "Eger",
            "translationKey": "hungary_region_name_eg",
            "shortCode": "EG"
        },
        {
            "name": "Érd",
            "translationKey": "hungary_region_name_er",
            "shortCode": "ER"
        },
        {
            "name": "Fejér",
            "translationKey": "hungary_region_name_fe",
            "shortCode": "FE"
        },
        {
            "name": "Győr",
            "translationKey": "hungary_region_name_gy",
            "shortCode": "GY"
        },
        {
            "name": "Győr-Moson-Sopron",
            "translationKey": "hungary_region_name_gs",
            "shortCode": "GS"
        },
        {
            "name": "Hajdú-Bihar",
            "translationKey": "hungary_region_name_hb",
            "shortCode": "HB"
        },
        {
            "name": "Heves",
            "translationKey": "hungary_region_name_he",
            "shortCode": "HE"
        },
        {
            "name": "Hódmezővásárhely",
            "translationKey": "hungary_region_name_hv",
            "shortCode": "HV"
        },
        {
            "name": "Jász-Nagykun-Szolnok",
            "translationKey": "hungary_region_name_n",
            "shortCode": "N"
        },
        {
            "name": "Kaposvár",
            "translationKey": "hungary_region_name_kv",
            "shortCode": "KV"
        },
        {
            "name": "Kecskemét",
            "translationKey": "hungary_region_name_km",
            "shortCode": "KM"
        },
        {
            "name": "Komárom-Esztergom",
            "translationKey": "hungary_region_name_ke",
            "shortCode": "KE"
        },
        {
            "name": "Miskolc",
            "translationKey": "hungary_region_name_mi",
            "shortCode": "MI"
        },
        {
            "name": "Nagykanizsa",
            "translationKey": "hungary_region_name_nk",
            "shortCode": "NK"
        },
        {
            "name": "Nógrád",
            "translationKey": "hungary_region_name_no",
            "shortCode": "NO"
        },
        {
            "name": "Nyíregyháza",
            "translationKey": "hungary_region_name_ny",
            "shortCode": "NY"
        },
        {
            "name": "Pécs",
            "translationKey": "hungary_region_name_ps",
            "shortCode": "PS"
        },
        {
            "name": "Pest",
            "translationKey": "hungary_region_name_pe",
            "shortCode": "PE"
        },
        {
            "name": "Salgótarján",
            "translationKey": "hungary_region_name_st",
            "shortCode": "ST"
        },
        {
            "name": "Somogy",
            "translationKey": "hungary_region_name_so",
            "shortCode": "SO"
        },
        {
            "name": "Sopron",
            "translationKey": "hungary_region_name_sn",
            "shortCode": "SN"
        },
        {
            "name": "Szabolcs-á-Bereg",
            "translationKey": "hungary_region_name_sz",
            "shortCode": "SZ"
        },
        {
            "name": "Szeged",
            "translationKey": "hungary_region_name_sd",
            "shortCode": "SD"
        },
        {
            "name": "Székesfehérvár",
            "translationKey": "hungary_region_name_sf",
            "shortCode": "SF"
        },
        {
            "name": "Szekszárd",
            "translationKey": "hungary_region_name_ss",
            "shortCode": "SS"
        },
        {
            "name": "Szolnok",
            "translationKey": "hungary_region_name_sk",
            "shortCode": "SK"
        },
        {
            "name": "Szombathely",
            "translationKey": "hungary_region_name_sh",
            "shortCode": "SH"
        },
        {
            "name": "Tatabánya",
            "translationKey": "hungary_region_name_tb",
            "shortCode": "TB"
        },
        {
            "name": "Tolna",
            "translationKey": "hungary_region_name_to",
            "shortCode": "TO"
        },
        {
            "name": "Vas",
            "translationKey": "hungary_region_name_va",
            "shortCode": "VA"
        },
        {
            "name": "Veszprém",
            "translationKey": "hungary_region_name_ve",
            "shortCode": "VE"
        },
        {
            "name": "Veszprém (City)",
            "translationKey": "hungary_region_name_vm",
            "shortCode": "VM"
        },
        {
            "name": "Zala",
            "translationKey": "hungary_region_name_za",
            "shortCode": "ZA"
        },
        {
            "name": "Zalaegerszeg",
            "translationKey": "hungary_region_name_ze",
            "shortCode": "ZE"
        }
    ]
  },
  //   {
  //     "countryName": "Iceland",
  //     "countryShortCode": "IS",
  //     "regions": [
  //       {
  //         "name": "Austurland",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Höfuðborgarsvæði utan Reykjavíkur",
  //         "shortCode": "1"
  //       },
  //       {
  //         "name": "Norðurland eystra",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "Norðurland vestra",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Suðurland",
  //         "shortCode": "8"
  //       },
  //       {
  //         "name": "Suðurnes",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Vestfirðir",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Vesturland",
  //         "shortCode": "3"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "India",
  //     "countryShortCode": "IN",
  //     "regions": [
  //       {
  //         "name": "Andaman and Nicobar Islands",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Andhra Pradesh",
  //         "shortCode": "AP"
  //       },
  //       {
  //         "name": "Arunachal Pradesh",
  //         "shortCode": "AR"
  //       },
  //       {
  //         "name": "Assam",
  //         "shortCode": "AS"
  //       },
  //       {
  //         "name": "Bihar",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Chandigarh",
  //         "shortCode": "CH"
  //       },
  //       {
  //         "name": "Chhattisgarh",
  //         "shortCode": "CT"
  //       },
  //       {
  //         "name": "Dadra and Nagar Haveli",
  //         "shortCode": "DN"
  //       },
  //       {
  //         "name": "Daman and Diu",
  //         "shortCode": "DD"
  //       },
  //       {
  //         "name": "Delhi",
  //         "shortCode": "DL"
  //       },
  //       {
  //         "name": "Goa",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Gujarat",
  //         "shortCode": "GJ"
  //       },
  //       {
  //         "name": "Haryana",
  //         "shortCode": "HR"
  //       },
  //       {
  //         "name": "Himachal Pradesh",
  //         "shortCode": "HP"
  //       },
  //       {
  //         "name": "Jammu and Kashmir",
  //         "shortCode": "JK"
  //       },
  //       {
  //         "name": "Jharkhand",
  //         "shortCode": "JH"
  //       },
  //       {
  //         "name": "Karnataka",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Kerala",
  //         "shortCode": "KL"
  //       },
  //       {
  //         "name": "Lakshadweep",
  //         "shortCode": "LD"
  //       },
  //       {
  //         "name": "Madhya Pradesh",
  //         "shortCode": "MP"
  //       },
  //       {
  //         "name": "Maharashtra",
  //         "shortCode": "MH"
  //       },
  //       {
  //         "name": "Manipur",
  //         "shortCode": "MN"
  //       },
  //       {
  //         "name": "Meghalaya",
  //         "shortCode": "ML"
  //       },
  //       {
  //         "name": "Mizoram",
  //         "shortCode": "MZ"
  //       },
  //       {
  //         "name": "Nagaland",
  //         "shortCode": "NL"
  //       },
  //       {
  //         "name": "Odisha",
  //         "shortCode": "OR"
  //       },
  //       {
  //         "name": "Puducherry",
  //         "shortCode": "PY"
  //       },
  //       {
  //         "name": "Punjab",
  //         "shortCode": "PB"
  //       },
  //       {
  //         "name": "Rajasthan",
  //         "shortCode": "RJ"
  //       },
  //       {
  //         "name": "Sikkim",
  //         "shortCode": "WK"
  //       },
  //       {
  //         "name": "Tamil Nadu",
  //         "shortCode": "TN"
  //       },
  //       {
  //         "name": "Telangana",
  //         "shortCode": "TG"
  //       },
  //       {
  //         "name": "Tripura",
  //         "shortCode": "TR"
  //       },
  //       {
  //         "name": "Uttarakhand",
  //         "shortCode": "UT"
  //       },
  //       {
  //         "name": "Uttar Pradesh",
  //         "shortCode": "UP"
  //       },
  //       {
  //         "name": "West Bengal",
  //         "shortCode": "WB"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Indonesia",
  //     "countryShortCode": "ID",
  //     "regions": [
  //       {
  //         "name": "Aceh",
  //         "shortCode": "AC"
  //       },
  //       {
  //         "name": "Bali",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Bangka Belitung",
  //         "shortCode": "BB"
  //       },
  //       {
  //         "name": "Banten",
  //         "shortCode": "BT"
  //       },
  //       {
  //         "name": "Bengkulu",
  //         "shortCode": "BE"
  //       },
  //       {
  //         "name": "Gorontalo",
  //         "shortCode": "GO"
  //       },
  //       {
  //         "name": "Jakarta Raya",
  //         "shortCode": "JK"
  //       },
  //       {
  //         "name": "Jambi",
  //         "shortCode": "JA"
  //       },
  //       {
  //         "name": "Jawa Barat",
  //         "shortCode": "JB"
  //       },
  //       {
  //         "name": "Jawa Tengah",
  //         "shortCode": "JT"
  //       },
  //       {
  //         "name": "Jawa Timur",
  //         "shortCode": "JI"
  //       },
  //       {
  //         "name": "Kalimantan Barat",
  //         "shortCode": "KB"
  //       },
  //       {
  //         "name": "Kalimantan Selatan",
  //         "shortCode": "KS"
  //       },
  //       {
  //         "name": "Kalimantan Tengah",
  //         "shortCode": "KT"
  //       },
  //       {
  //         "name": "Kalimantan Timur",
  //         "shortCode": "KI"
  //       },
  //       {
  //         "name": "Kalimantan Utara",
  //         "shortCode": "KU"
  //       },
  //       {
  //         "name": "Kepulauan Riau",
  //         "shortCode": "KR"
  //       },
  //       {
  //         "name": "Lampung",
  //         "shortCode": "LA"
  //       },
  //       {
  //         "name": "Maluku",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Maluku Utara",
  //         "shortCode": "MU"
  //       },
  //       {
  //         "name": "Nusa Tenggara Barat",
  //         "shortCode": "NB"
  //       },
  //       {
  //         "name": "Nusa Tenggara Timur",
  //         "shortCode": "NT"
  //       },
  //       {
  //         "name": "Papua",
  //         "shortCode": "PA"
  //       },
  //       {
  //         "name": "Papua Barat",
  //         "shortCode": "PB"
  //       },
  //       {
  //         "name": "Riau",
  //         "shortCode": "RI"
  //       },
  //       {
  //         "name": "Sulawesi Selatan",
  //         "shortCode": "SR"
  //       },
  //       {
  //         "name": "Sulawesi Tengah",
  //         "shortCode": "ST"
  //       },
  //       {
  //         "name": "Sulawesi Tenggara",
  //         "shortCode": "SG"
  //       },
  //       {
  //         "name": "Sulawesi Utara",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Sumatera Barat",
  //         "shortCode": "SB"
  //       },
  //       {
  //         "name": "Sumatera Selatan",
  //         "shortCode": "SS"
  //       },
  //       {
  //         "name": "Sumatera Utara",
  //         "shortCode": "SU"
  //       },
  //       {
  //         "name": "Yogyakarta",
  //         "shortCode": "YO"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Iran, Islamic Republic of",
  //     "countryShortCode": "IR",
  //     "regions": [
  //       {
  //         "name": "Alborz",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Ardabīl",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Āz̄arbāyjān-e Gharbī",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Āz̄arbāyjān-e Sharqī",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Būshehr",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Chahār Maḩāl va Bakhtīārī",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Eşfahān",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Fārs",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Gīlān",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Golestān",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Hamadān",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Hormozgān",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Īlām",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Kermān",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Kermānshāh",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Khorāsān-e Jonūbī",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Khorāsān-e Raẕavī",
  //         "shortCode": "30"
  //       },
  //       {
  //         "name": "Khorāsān-e Shomālī",
  //         "shortCode": "61"
  //       },
  //       {
  //         "name": "Khūzestān",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Kohgīlūyeh va Bowyer Aḩmad",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Kordestān",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Lorestān",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Markazi",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Māzandarān",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Qazvīn",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Qom",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Semnān",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Sīstān va Balūchestān",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Tehrān",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Yazd",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Zanjān",
  //         "shortCode": "11"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Iraq",
  //     "countryShortCode": "IQ",
  //     "regions": [
  //       {
  //         "name": "Al Anbār",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Al Başrah",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Al Muthanná",
  //         "shortCode": "MU"
  //       },
  //       {
  //         "name": "Al Qādisīyah",
  //         "shortCode": "QA"
  //       },
  //       {
  //         "name": "An Najaf",
  //         "shortCode": "NA"
  //       },
  //       {
  //         "name": "Arbīl",
  //         "shortCode": "AR"
  //       },
  //       {
  //         "name": "As Sulaymānīyah",
  //         "shortCode": "SU"
  //       },
  //       {
  //         "name": "Bābil",
  //         "shortCode": "BB"
  //       },
  //       {
  //         "name": "Baghdād",
  //         "shortCode": "BG"
  //       },
  //       {
  //         "name": "Dohuk",
  //         "shortCode": "DA"
  //       },
  //       {
  //         "name": "Dhī Qār",
  //         "shortCode": "DQ"
  //       },
  //       {
  //         "name": "Diyālá",
  //         "shortCode": "DI"
  //       },
  //       {
  //         "name": "Karbalā'",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Kirkuk",
  //         "shortCode": "KI"
  //       },
  //       {
  //         "name": "Maysān",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Nīnawá",
  //         "shortCode": "NI"
  //       },
  //       {
  //         "name": "Şalāḩ ad Dīn",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Wāsiţ",
  //         "shortCode": "WA"
  //       }
  //     ]
  //   },
  {
    "countryName": "Ireland",
    "translationKey": "country_name_ireland",
    "countryShortCode": "IE",
    "regions": [
        {
            "name": "Carlow",
            "translationKey": "ireland_region_name_cw",
            "shortCode": "CW"
        },
        {
            "name": "Cavan",
            "translationKey": "ireland_region_name_cn",
            "shortCode": "CN"
        },
        {
            "name": "Clare",
            "translationKey": "ireland_region_name_ce",
            "shortCode": "CE"
        },
        {
            "name": "Cork",
            "translationKey": "ireland_region_name_co",
            "shortCode": "CO"
        },
        {
            "name": "Donegal",
            "translationKey": "ireland_region_name_dl",
            "shortCode": "DL"
        },
        {
            "name": "Dublin",
            "translationKey": "ireland_region_name_d",
            "shortCode": "D"
        },
        {
            "name": "Galway",
            "translationKey": "ireland_region_name_g",
            "shortCode": "G"
        },
        {
            "name": "Kerry",
            "translationKey": "ireland_region_name_ky",
            "shortCode": "KY"
        },
        {
            "name": "Kildare",
            "translationKey": "ireland_region_name_ke",
            "shortCode": "KE"
        },
        {
            "name": "Kilkenny",
            "translationKey": "ireland_region_name_kk",
            "shortCode": "KK"
        },
        {
            "name": "Laois",
            "translationKey": "ireland_region_name_ls",
            "shortCode": "LS"
        },
        {
            "name": "Leitrim",
            "translationKey": "ireland_region_name_lm",
            "shortCode": "LM"
        },
        {
            "name": "Limerick",
            "translationKey": "ireland_region_name_lk",
            "shortCode": "LK"
        },
        {
            "name": "Longford",
            "translationKey": "ireland_region_name_ld",
            "shortCode": "LD"
        },
        {
            "name": "Louth",
            "translationKey": "ireland_region_name_lh",
            "shortCode": "LH"
        },
        {
            "name": "Mayo",
            "translationKey": "ireland_region_name_mo",
            "shortCode": "MO"
        },
        {
            "name": "Meath",
            "translationKey": "ireland_region_name_mh",
            "shortCode": "MH"
        },
        {
            "name": "Monaghan",
            "translationKey": "ireland_region_name_mn",
            "shortCode": "MN"
        },
        {
            "name": "Offaly",
            "translationKey": "ireland_region_name_oy",
            "shortCode": "OY"
        },
        {
            "name": "Roscommon",
            "translationKey": "ireland_region_name_rn",
            "shortCode": "RN"
        },
        {
            "name": "Sligo",
            "translationKey": "ireland_region_name_so",
            "shortCode": "SO"
        },
        {
            "name": "Tipperary",
            "translationKey": "ireland_region_name_ta",
            "shortCode": "TA"
        },
        {
            "name": "Waterford",
            "translationKey": "ireland_region_name_wd",
            "shortCode": "WD"
        },
        {
            "name": "Westmeath",
            "translationKey": "ireland_region_name_wh",
            "shortCode": "WH"
        },
        {
            "name": "Wexford",
            "translationKey": "ireland_region_name_wx",
            "shortCode": "WX"
        },
        {
            "name": "Wicklow",
            "translationKey": "ireland_region_name_ww",
            "shortCode": "WW"
        }
    ]
  },
  //   {
  //     "countryName": "Isle of Man",
  //     "countryShortCode": "IM",
  //     "regions": [
  //       {
  //         "name": "Isle of Man"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Israel",
  //     "countryShortCode": "IL",
  //     "regions": [
  //       {
  //         "name": "HaDarom",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "HaMerkaz",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "HaTsafon",
  //         "shortCode": "Z"
  //       },
  //       {
  //         "name": "H̱efa",
  //         "shortCode": "HA"
  //       },
  //       {
  //         "name": "Tel-Aviv",
  //         "shortCode": "TA"
  //       },
  //       {
  //         "name": "Yerushalayim",
  //         "shortCode": "JM"
  //       }
  //     ]
  //   },
  {
    "countryName": "Italy",
    "translationKey": "country_name_italy",
    "countryShortCode": "IT",
    "regions": [
        {
            "name": "Abruzzo",
            "translationKey": "italy_region_name_65",
            "shortCode": "65"
        },
        {
            "name": "Basilicata",
            "translationKey": "italy_region_name_77",
            "shortCode": "77"
        },
        {
            "name": "Calabria",
            "translationKey": "italy_region_name_78",
            "shortCode": "78"
        },
        {
            "name": "Campania",
            "translationKey": "italy_region_name_72",
            "shortCode": "72"
        },
        {
            "name": "Emilia-Romagna",
            "translationKey": "italy_region_name_45",
            "shortCode": "45"
        },
        {
            "name": "Friuli-Venezia Giulia",
            "translationKey": "italy_region_name_36",
            "shortCode": "36"
        },
        {
            "name": "Lazio",
            "translationKey": "italy_region_name_62",
            "shortCode": "62"
        },
        {
            "name": "Liguria",
            "translationKey": "italy_region_name_42",
            "shortCode": "42"
        },
        {
            "name": "Lombardia",
            "translationKey": "italy_region_name_25",
            "shortCode": "25"
        },
        {
            "name": "Marche",
            "translationKey": "italy_region_name_57",
            "shortCode": "57"
        },
        {
            "name": "Molise",
            "translationKey": "italy_region_name_67",
            "shortCode": "67"
        },
        {
            "name": "Piemonte",
            "translationKey": "italy_region_name_21",
            "shortCode": "21"
        },
        {
            "name": "Puglia",
            "translationKey": "italy_region_name_75",
            "shortCode": "75"
        },
        {
            "name": "Sardegna",
            "translationKey": "italy_region_name_88",
            "shortCode": "88"
        },
        {
            "name": "Sicilia",
            "translationKey": "italy_region_name_82",
            "shortCode": "82"
        },
        {
            "name": "Toscana",
            "translationKey": "italy_region_name_52",
            "shortCode": "52"
        },
        {
            "name": "Trentino-Alto Adige",
            "translationKey": "italy_region_name_32",
            "shortCode": "32"
        },
        {
            "name": "Umbria",
            "translationKey": "italy_region_name_55",
            "shortCode": "55"
        },
        {
            "name": "Valle d'Aosta",
            "translationKey": "italy_region_name_23",
            "shortCode": "23"
        },
        {
            "name": "Veneto",
            "translationKey": "italy_region_name_34",
            "shortCode": "34"
        }
    ]
  },
  //   {
  //     "countryName": "Jamaica",
  //     "countryShortCode": "JM",
  //     "regions": [
  //       {
  //         "name": "Clarendon",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Hanover",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Kingston",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Manchester",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Portland",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Saint Andrew",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Saint Ann",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Saint Catherine",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Saint Elizabeth",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Saint James",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Saint Mary",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Saint Thomas",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Trelawny",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Westmoreland",
  //         "shortCode": "10"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Japan",
  //     "countryShortCode": "JP",
  //     "regions": [
  //       {
  //         "name": "Aichi",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Akita",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Aomori",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Chiba",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Ehime",
  //         "shortCode": "38"
  //       },
  //       {
  //         "name": "Fukui",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Fukuoka",
  //         "shortCode": "40"
  //       },
  //       {
  //         "name": "Fukushima",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Gifu",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Gunma",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Hiroshima",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Hokkaido",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Hyogo",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Ibaraki",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Ishikawa",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Iwate",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Kagawa",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "Kagoshima",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "Kanagawa",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Kochi",
  //         "shortCode": "39"
  //       },
  //       {
  //         "name": "Kumamoto",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Kyoto",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Mie",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Miyagi",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Miyazaki",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Nagano",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Nagasaki",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Nara",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Niigata",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Oita",
  //         "shortCode": "44"
  //       },
  //       {
  //         "name": "Okayama",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Okinawa",
  //         "shortCode": "47"
  //       },
  //       {
  //         "name": "Osaka",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Saga",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Saitama",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Shiga",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Shimane",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Shizuoka",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Tochigi",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Tokushima",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "Tokyo",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Tottori",
  //         "shortCode": "31"
  //       },
  //       {
  //         "name": "Toyama",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Wakayama",
  //         "shortCode": "30"
  //       },
  //       {
  //         "name": "Yamagata",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Yamaguchi",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Yamanashi",
  //         "shortCode": "19"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Jersey",
  //     "countryShortCode": "JE",
  //     "regions": [
  //       {
  //         "name": "Jersey"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Jordan",
  //     "countryShortCode": "JO",
  //     "regions": [
  //       {
  //         "name": "‘Ajlūn",
  //         "shortCode": "AJ"
  //       },
  //       {
  //         "name": "Al 'Aqabah",
  //         "shortCode": "AQ"
  //       },
  //       {
  //         "name": "Al Balqā’",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Al Karak",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Al Mafraq",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Al ‘A̅şimah",
  //         "shortCode": "AM"
  //       },
  //       {
  //         "name": "Aţ Ţafīlah",
  //         "shortCode": "AT"
  //       },
  //       {
  //         "name": "Az Zarqā’",
  //         "shortCode": "AZ"
  //       },
  //       {
  //         "name": "Irbid",
  //         "shortCode": "IR"
  //       },
  //       {
  //         "name": "Jarash",
  //         "shortCode": "JA"
  //       },
  //       {
  //         "name": "Ma‘ān",
  //         "shortCode": "MN"
  //       },
  //       {
  //         "name": "Mādabā",
  //         "shortCode": "MD"
  //       }
  //     ]
  //   },
  {
    "countryName": "Kazakhstan",
    "countryShortCode": "KZ",
    "regions": [
        {
            "name": "Almaty",
            "shortCode": "ALA",
            "translationKey": "Kazakhstan_region_ALA"
        },
        {
            "name": "Aqmola",
            "shortCode": "AKM",
            "translationKey": "Kazakhstan_region_AKM"
        },
        {
            "name": "Aqtobe",
            "shortCode": "AKT",
            "translationKey": "Kazakhstan_region_AKT"
        },
        {
            "name": "Astana",
            "shortCode": "AST",
            "translationKey": "Kazakhstan_region_AST"
        },
        {
            "name": "Atyrau",
            "shortCode": "ATY",
            "translationKey": "Kazakhstan_region_ATY"
        },
        {
            "name": "Batys Qazaqstan",
            "shortCode": "ZAP",
            "translationKey": "Kazakhstan_region_ZAP"
        },
        {
            "name": "Bayqongyr",
            "translationKey": "Kazakhstan_region_Bayqongyr"
        },
        {
            "name": "Mangghystau",
            "shortCode": "MAN",
            "translationKey": "Kazakhstan_region_MAN"
        },
        {
            "name": "Ongtustik Qazaqstan",
            "shortCode": "YUZ",
            "translationKey": "Kazakhstan_region_YUZ"
        },
        {
            "name": "Pavlodar",
            "shortCode": "PAV",
            "translationKey": "Kazakhstan_region_PAV"
        },
        {
            "name": "Qaraghandy",
            "shortCode": "KAR",
            "translationKey": "Kazakhstan_region_KAR"
        },
        {
            "name": "Qostanay",
            "shortCode": "KUS",
            "translationKey": "Kazakhstan_region_KUS"
        },
        {
            "name": "Qyzylorda",
            "shortCode": "KZY",
            "translationKey": "Kazakhstan_region_KZY"
        },
        {
            "name": "Shyghys Qazaqstan",
            "shortCode": "VOS",
            "translationKey": "Kazakhstan_region_VOS"
        },
        {
            "name": "Soltustik Qazaqstan",
            "shortCode": "SEV",
            "translationKey": "Kazakhstan_region_SEV"
        },
        {
            "name": "Zhambyl",
            "shortCode": "ZHA",
            "translationKey": "Kazakhstan_region_ZHA"
        }
    ],
    "translationKey": "country_name_Kazakhstan"
  },
  //   {
  //     "countryName": "Kenya",
  //     "countryShortCode": "KE",
  //     "regions": [
  //       {
  //         "name": "Baringo",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Bomet",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Bungoma",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Busia",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Eleyo/Marakwet",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Embu",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Garissa",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Homa Bay",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Isiolo",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Kajiado",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Kakamega",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Kericho",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Kiambu",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Kilifi",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Kirinyaga",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Kisii",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Kisumu",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Kitui",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Kwale",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Laikipia",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Lamu",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Machakos",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Makueni",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Mandera",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Marsabit",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Meru",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Migori",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Mombasa",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Murang'a",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Nairobi City",
  //         "shortCode": "30"
  //       },
  //       {
  //         "name": "Nakuru",
  //         "shortCode": "31"
  //       },
  //       {
  //         "name": "Nandi",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Narok",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Nyamira",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Nyandarua",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Nyeri",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "Samburu",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "Siaya",
  //         "shortCode": "38"
  //       },
  //       {
  //         "name": "Taita/Taveta",
  //         "shortCode": "39"
  //       },
  //       {
  //         "name": "Tana River",
  //         "shortCode": "40"
  //       },
  //       {
  //         "name": "Tharaka-Nithi",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Trans Nzoia",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Turkana",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Uasin Gishu",
  //         "shortCode": "44"
  //       },
  //       {
  //         "name": "Vihiga",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Wajir",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "West Pokot",
  //         "shortCode": "47"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Kiribati",
  //     "countryShortCode": "KI",
  //     "regions": [
  //       {
  //         "name": "Abaiang"
  //       },
  //       {
  //         "name": "Abemama"
  //       },
  //       {
  //         "name": "Aranuka"
  //       },
  //       {
  //         "name": "Arorae"
  //       },
  //       {
  //         "name": "Banaba"
  //       },
  //       {
  //         "name": "Beru"
  //       },
  //       {
  //         "name": "Butaritari"
  //       },
  //       {
  //         "name": "Central Gilberts"
  //       },
  //       {
  //         "name": "Gilbert Islands",
  //         "shortCode": "G"
  //       },
  //       {
  //         "name": "Kanton"
  //       },
  //       {
  //         "name": "Kiritimati"
  //       },
  //       {
  //         "name": "Kuria"
  //       },
  //       {
  //         "name": "Line Islands",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "Maiana"
  //       },
  //       {
  //         "name": "Makin"
  //       },
  //       {
  //         "name": "Marakei"
  //       },
  //       {
  //         "name": "Nikunau"
  //       },
  //       {
  //         "name": "Nonouti"
  //       },
  //       {
  //         "name": "Northern Gilberts"
  //       },
  //       {
  //         "name": "Onotoa"
  //       },
  //       {
  //         "name": "Phoenix Islands",
  //         "shortCode": "P"
  //       },
  //       {
  //         "name": "Southern Gilberts"
  //       },
  //       {
  //         "name": "Tabiteuea"
  //       },
  //       {
  //         "name": "Tabuaeran"
  //       },
  //       {
  //         "name": "Tamana"
  //       },
  //       {
  //         "name": "Tarawa"
  //       },
  //       {
  //         "name": "Teraina"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Korea, Democratic People's Republic of",
  //     "countryShortCode": "KP",
  //     "regions": [
  //       {
  //         "name": "Chagang-do (Chagang Province)",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Hamgyong-bukto (North Hamgyong Province)",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Hamgyong-namdo (South Hamgyong Province)",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Hwanghae-bukto (North Hwanghae Province)",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Hwanghae-namdo (South Hwanghae Province)",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Kangwon-do (Kangwon Province)",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Nasŏn (Najin-Sŏnbong)",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "P'yongan-bukto (North P'yongan Province)",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "P'yongan-namdo (South P'yongan Province)",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "P'yongyang-si (P'yongyang City)",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Yanggang-do (Yanggang Province)",
  //         "shortCode": "10"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Korea, Republic of",
  //     "countryShortCode": "KR",
  //     "regions": [
  //       {
  //         "name": "Ch'ungch'ongbuk-do",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Ch'ungch'ongnam-do",
  //         "shortCode": "44"
  //       },
  //       {
  //         "name": "Cheju-do",
  //         "shortCode": "49"
  //       },
  //       {
  //         "name": "Chollabuk-do",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Chollanam-do",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "Inch'on-Kwangyokhi",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Kang-won-do",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Kwangju-Kwangyokshi",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Kyonggi-do",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Kyongsangbuk-do",
  //         "shortCode": "47"
  //       },
  //       {
  //         "name": "Kyongsangnam-do",
  //         "shortCode": "48"
  //       },
  //       {
  //         "name": "Pusan-Kwangyokshi",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Seoul-T'ukpyolshi",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Sejong",
  //         "shortCode": "50"
  //       },
  //       {
  //         "name": "Taegu-Kwangyokshi",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Taejon-Kwangyokshi",
  //         "shortCode": "30"
  //       },
  //       {
  //         "name": "Ulsan-Kwangyokshi",
  //         "shortCode": "31"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Kosovo",
  //     "countryShortCode": "XK",
  //     "regions": [
  //       {
  //         "name": "Farizaj",
  //         "shortCode": "FZ"
  //       },
  //       {
  //         "name": "Gjakova",
  //         "shortCode": "GK"
  //       },
  //       {
  //         "name": "Gjilan",
  //         "shortCode": "GL"
  //       },
  //       {
  //         "name": "Mitrovica",
  //         "shortCode": "MI"
  //       },
  //       {
  //         "name": "Peja/Peć",
  //         "shortCode": "PE"
  //       },
  //       {
  //         "name": "Pristina",
  //         "shortCode": "PR"
  //       },
  //       {
  //         "name": "Prizren",
  //         "shortCode": "PZ"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Kuwait",
  //     "countryShortCode": "KW",
  //     "regions": [
  //       {
  //         "name": "Al Aḩmadi",
  //         "shortCode": "AH"
  //       },
  //       {
  //         "name": "Al Farwānīyah",
  //         "shortCode": "FA"
  //       },
  //       {
  //         "name": "Al Jahrā’",
  //         "shortCode": "JA"
  //       },
  //       {
  //         "name": "Al ‘Āşimah",
  //         "shortCode": "KU"
  //       },
  //       {
  //         "name": "Ḩawallī",
  //         "shortCode": "HA"
  //       },
  //       {
  //         "name": "Mubārak al Kabir",
  //         "shortCode": "MU"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Kyrgyzstan",
  //     "countryShortCode": "KG",
  //     "regions": [
  //       {
  //         "name": "Batken Oblasty",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Bishkek Shaary",
  //         "shortCode": "GB"
  //       },
  //       {
  //         "name": "Chuy Oblasty (Bishkek)",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Jalal-Abad Oblasty",
  //         "shortCode": "J"
  //       },
  //       {
  //         "name": "Naryn Oblasty",
  //         "shortCode": "N"
  //       },
  //       {
  //         "name": "Osh Oblasty",
  //         "shortCode": "O"
  //       },
  //       {
  //         "name": "Talas Oblasty",
  //         "shortCode": "T"
  //       },
  //       {
  //         "name": "Ysyk-Kol Oblasty (Karakol)",
  //         "shortCode": "Y"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Laos",
  //     "countryShortCode": "LA",
  //     "regions": [
  //       {
  //         "name": "Attapu",
  //         "shortCode": "AT"
  //       },
  //       {
  //         "name": "Bokèo",
  //         "shortCode": "BK"
  //       },
  //       {
  //         "name": "Bolikhamxai",
  //         "shortCode": "BL"
  //       },
  //       {
  //         "name": "Champasak",
  //         "shortCode": "CH"
  //       },
  //       {
  //         "name": "Houaphan",
  //         "shortCode": "HO"
  //       },
  //       {
  //         "name": "Khammouan",
  //         "shortCode": "KH"
  //       },
  //       {
  //         "name": "Louang Namtha",
  //         "shortCode": "LM"
  //       },
  //       {
  //         "name": "Louangphabang",
  //         "shortCode": "LP"
  //       },
  //       {
  //         "name": "Oudômxai",
  //         "shortCode": "OU"
  //       },
  //       {
  //         "name": "Phôngsali",
  //         "shortCode": "PH"
  //       },
  //       {
  //         "name": "Salavan",
  //         "shortCode": "SL"
  //       },
  //       {
  //         "name": "Savannakhét",
  //         "shortCode": "SV"
  //       },
  //       {
  //         "name": "Vientiane",
  //         "shortCode": "VI"
  //       },
  //       {
  //         "name": "Xaignabouli",
  //         "shortCode": "XA"
  //       },
  //       {
  //         "name": "Xékong",
  //         "shortCode": "XE"
  //       },
  //       {
  //         "name": "Xaisomboun",
  //         "shortCode": "XS"
  //       },
  //       {
  //         "name": "Xiangkhouang",
  //         "shortCode": "XI"
  //       }
  //     ]
  //   },
  {
    "countryName": "Latvia",
    "translationKey": "country_name_latvia",
    "countryShortCode": "LV",
    "regions": [
        {
            "name": "Aglona",
            "translationKey": "latvia_region_name_001",
            "shortCode": "001"
        },
        {
            "name": "Aizkraukle",
            "translationKey": "latvia_region_name_002",
            "shortCode": "002"
        },
        {
            "name": "Aizpute",
            "translationKey": "latvia_region_name_003",
            "shortCode": "003"
        },
        {
            "name": "Aknīste",
            "translationKey": "latvia_region_name_004",
            "shortCode": "004"
        },
        {
            "name": "Aloja",
            "translationKey": "latvia_region_name_005",
            "shortCode": "005"
        },
        {
            "name": "Alsunga",
            "translationKey": "latvia_region_name_006",
            "shortCode": "006"
        },
        {
            "name": "Alūksne",
            "translationKey": "latvia_region_name_007",
            "shortCode": "007"
        },
        {
            "name": "Amata",
            "translationKey": "latvia_region_name_008",
            "shortCode": "008"
        },
        {
            "name": "Ape",
            "translationKey": "latvia_region_name_009",
            "shortCode": "009"
        },
        {
            "name": "Auce",
            "translationKey": "latvia_region_name_010",
            "shortCode": "010"
        },
        {
            "name": "Ādaži",
            "translationKey": "latvia_region_name_011",
            "shortCode": "011"
        },
        {
            "name": "Babīte",
            "translationKey": "latvia_region_name_012",
            "shortCode": "012"
        },
        {
            "name": "Baldone",
            "translationKey": "latvia_region_name_013",
            "shortCode": "013"
        },
        {
            "name": "Baltinava",
            "translationKey": "latvia_region_name_014",
            "shortCode": "014"
        },
        {
            "name": "Balvi",
            "translationKey": "latvia_region_name_015",
            "shortCode": "015"
        },
        {
            "name": "Bauska",
            "translationKey": "latvia_region_name_016",
            "shortCode": "016"
        },
        {
            "name": "Beverīna",
            "translationKey": "latvia_region_name_017",
            "shortCode": "017"
        },
        {
            "name": "Brocēni",
            "translationKey": "latvia_region_name_018",
            "shortCode": "018"
        },
        {
            "name": "Burtnieki",
            "translationKey": "latvia_region_name_019",
            "shortCode": "019"
        },
        {
            "name": "Carnikava",
            "translationKey": "latvia_region_name_020",
            "shortCode": "020"
        },
        {
            "name": "Cesvaine",
            "translationKey": "latvia_region_name_021",
            "shortCode": "021"
        },
        {
            "name": "Cēsis",
            "translationKey": "latvia_region_name_022",
            "shortCode": "022"
        },
        {
            "name": "Cibla",
            "translationKey": "latvia_region_name_023",
            "shortCode": "023"
        },
        {
            "name": "Dagda",
            "translationKey": "latvia_region_name_024",
            "shortCode": "024"
        },
        {
            "name": "Daugavpils",
            "translationKey": "latvia_region_name_025",
            "shortCode": "025"
        },
        {
            "name": "Daugavpils (City)",
            "translationKey": "latvia_region_name_dgv",
            "shortCode": "DGV"
        },
        {
            "name": "Dobele",
            "translationKey": "latvia_region_name_026",
            "shortCode": "026"
        },
        {
            "name": "Dundaga",
            "translationKey": "latvia_region_name_027",
            "shortCode": "027"
        },
        {
            "name": "Durbe",
            "translationKey": "latvia_region_name_028",
            "shortCode": "028"
        },
        {
            "name": "Engure",
            "translationKey": "latvia_region_name_029",
            "shortCode": "029"
        },
        {
            "name": "Ērgļi",
            "translationKey": "latvia_region_name_030",
            "shortCode": "030"
        },
        {
            "name": "Garkalne",
            "translationKey": "latvia_region_name_031",
            "shortCode": "031"
        },
        {
            "name": "Grobiņa",
            "translationKey": "latvia_region_name_032",
            "shortCode": "032"
        },
        {
            "name": "Gulbene",
            "translationKey": "latvia_region_name_033",
            "shortCode": "033"
        },
        {
            "name": "Iecava",
            "translationKey": "latvia_region_name_034",
            "shortCode": "034"
        },
        {
            "name": "Ikšķile",
            "translationKey": "latvia_region_name_035",
            "shortCode": "035"
        },
        {
            "name": "Ilūkste",
            "translationKey": "latvia_region_name_036",
            "shortCode": "036"
        },
        {
            "name": "Inčukalns",
            "translationKey": "latvia_region_name_037",
            "shortCode": "037"
        },
        {
            "name": "Jaunjelgava",
            "translationKey": "latvia_region_name_038",
            "shortCode": "038"
        },
        {
            "name": "Jaunpiebalga",
            "translationKey": "latvia_region_name_039",
            "shortCode": "039"
        },
        {
            "name": "Jaunpils",
            "translationKey": "latvia_region_name_040",
            "shortCode": "040"
        },
        {
            "name": "Jelgava",
            "translationKey": "latvia_region_name_041",
            "shortCode": "041"
        },
        {
            "name": "Jelgava (City)",
            "translationKey": "latvia_region_name_jel",
            "shortCode": "JEL"
        },
        {
            "name": "Jēkabpils",
            "translationKey": "latvia_region_name_042",
            "shortCode": "042"
        },
        {
            "name": "Jēkabpils (City)",
            "translationKey": "latvia_region_name_jkb",
            "shortCode": "JKB"
        },
        {
            "name": "Jūrmala (City)",
            "translationKey": "latvia_region_name_jur",
            "shortCode": "JUR"
        },
        {
            "name": "Kandava",
            "translationKey": "latvia_region_name_043",
            "shortCode": "043"
        },
        {
            "name": "Kārsava",
            "translationKey": "latvia_region_name_044",
            "shortCode": "044"
        },
        {
            "name": "Kocēni",
            "translationKey": "latvia_region_name_045",
            "shortCode": "045"
        },
        {
            "name": "Koknese",
            "translationKey": "latvia_region_name_046",
            "shortCode": "046"
        },
        {
            "name": "Krāslava",
            "translationKey": "latvia_region_name_047",
            "shortCode": "047"
        },
        {
            "name": "Krimulda",
            "translationKey": "latvia_region_name_048",
            "shortCode": "048"
        },
        {
            "name": "Krustpils",
            "translationKey": "latvia_region_name_049",
            "shortCode": "049"
        },
        {
            "name": "Kuldīga",
            "translationKey": "latvia_region_name_050",
            "shortCode": "050"
        },
        {
            "name": "Ķegums",
            "translationKey": "latvia_region_name_051",
            "shortCode": "051"
        },
        {
            "name": "Ķekava",
            "translationKey": "latvia_region_name_052",
            "shortCode": "052"
        },
        {
            "name": "Lielvārde",
            "translationKey": "latvia_region_name_053",
            "shortCode": "053"
        },
        {
            "name": "Liepāja",
            "translationKey": "latvia_region_name_lpx",
            "shortCode": "LPX"
        },
        {
            "name": "Limbaži",
            "translationKey": "latvia_region_name_054",
            "shortCode": "054"
        },
        {
            "name": "Līgatne",
            "translationKey": "latvia_region_name_055",
            "shortCode": "055"
        },
        {
            "name": "Līvāni",
            "translationKey": "latvia_region_name_056",
            "shortCode": "056"
        },
        {
            "name": "Lubāna",
            "translationKey": "latvia_region_name_057",
            "shortCode": "057"
        },
        {
            "name": "Ludza",
            "translationKey": "latvia_region_name_058",
            "shortCode": "058"
        },
        {
            "name": "Madona",
            "translationKey": "latvia_region_name_059",
            "shortCode": "059"
        },
        {
            "name": "Mazsalaca",
            "translationKey": "latvia_region_name_060",
            "shortCode": "060"
        },
        {
            "name": "Mālpils",
            "translationKey": "latvia_region_name_061",
            "shortCode": "061"
        },
        {
            "name": "Mārupe",
            "translationKey": "latvia_region_name_062",
            "shortCode": "062"
        },
        {
            "name": "Mērsrags",
            "translationKey": "latvia_region_name_063",
            "shortCode": "063"
        },
        {
            "name": "Naukšēni",
            "translationKey": "latvia_region_name_064",
            "shortCode": "064"
        },
        {
            "name": "Nereta",
            "translationKey": "latvia_region_name_065",
            "shortCode": "065"
        },
        {
            "name": "Nīca",
            "translationKey": "latvia_region_name_066",
            "shortCode": "066"
        },
        {
            "name": "Ogre",
            "translationKey": "latvia_region_name_067",
            "shortCode": "067"
        },
        {
            "name": "Olaine",
            "translationKey": "latvia_region_name_068",
            "shortCode": "068"
        },
        {
            "name": "Ozolnieki",
            "translationKey": "latvia_region_name_069",
            "shortCode": "069"
        },
        {
            "name": "Pārgauja",
            "translationKey": "latvia_region_name_070",
            "shortCode": "070"
        },
        {
            "name": "Pāvilosta",
            "translationKey": "latvia_region_name_071",
            "shortCode": "071"
        },
        {
            "name": "Pļaviņas",
            "translationKey": "latvia_region_name_072",
            "shortCode": "072"
        },
        {
            "name": "Preiļi",
            "translationKey": "latvia_region_name_073",
            "shortCode": "073"
        },
        {
            "name": "Priekule",
            "translationKey": "latvia_region_name_074",
            "shortCode": "074"
        },
        {
            "name": "Priekuļi",
            "translationKey": "latvia_region_name_075",
            "shortCode": "075"
        },
        {
            "name": "Rauna",
            "translationKey": "latvia_region_name_076",
            "shortCode": "076"
        },
        {
            "name": "Rēzekne",
            "translationKey": "latvia_region_name_077",
            "shortCode": "077"
        },
        {
            "name": "Rēzekne (City)",
            "translationKey": "latvia_region_name_rez",
            "shortCode": "REZ"
        },
        {
            "name": "Riebiņi",
            "translationKey": "latvia_region_name_078",
            "shortCode": "078"
        },
        {
            "name": "Rīga",
            "translationKey": "latvia_region_name_rix",
            "shortCode": "RIX"
        },
        {
            "name": "Roja",
            "translationKey": "latvia_region_name_079",
            "shortCode": "079"
        },
        {
            "name": "Ropaži",
            "translationKey": "latvia_region_name_080",
            "shortCode": "080"
        },
        {
            "name": "Rucava",
            "translationKey": "latvia_region_name_081",
            "shortCode": "081"
        },
        {
            "name": "Rugāji",
            "translationKey": "latvia_region_name_082",
            "shortCode": "082"
        },
        {
            "name": "Rundāle",
            "translationKey": "latvia_region_name_083",
            "shortCode": "083"
        },
        {
            "name": "Rūjiena",
            "translationKey": "latvia_region_name_084",
            "shortCode": "084"
        },
        {
            "name": "Sala",
            "translationKey": "latvia_region_name_085",
            "shortCode": "085"
        },
        {
            "name": "Salacgrīva",
            "translationKey": "latvia_region_name_086",
            "shortCode": "086"
        },
        {
            "name": "Salaspils",
            "translationKey": "latvia_region_name_087",
            "shortCode": "087"
        },
        {
            "name": "Saldus",
            "translationKey": "latvia_region_name_088",
            "shortCode": "088"
        },
        {
            "name": "Saulkrasti",
            "translationKey": "latvia_region_name_089",
            "shortCode": "089"
        },
        {
            "name": "Sēja",
            "translationKey": "latvia_region_name_090",
            "shortCode": "090"
        },
        {
            "name": "Sigulda",
            "translationKey": "latvia_region_name_091",
            "shortCode": "091"
        },
        {
            "name": "Skrīveri",
            "translationKey": "latvia_region_name_092",
            "shortCode": "092"
        },
        {
            "name": "Skrunda",
            "translationKey": "latvia_region_name_093",
            "shortCode": "093"
        },
        {
            "name": "Smiltene",
            "translationKey": "latvia_region_name_094",
            "shortCode": "094"
        },
        {
            "name": "Stopiņi",
            "translationKey": "latvia_region_name_095",
            "shortCode": "095"
        },
        {
            "name": "Strenči",
            "translationKey": "latvia_region_name_096",
            "shortCode": "096"
        },
        {
            "name": "Talsi",
            "translationKey": "latvia_region_name_097",
            "shortCode": "097"
        },
        {
            "name": "Tērvete",
            "translationKey": "latvia_region_name_098",
            "shortCode": "098"
        },
        {
            "name": "Tukums",
            "translationKey": "latvia_region_name_099",
            "shortCode": "099"
        },
        {
            "name": "Vaiņode",
            "translationKey": "latvia_region_name_100",
            "shortCode": "100"
        },
        {
            "name": "Valka",
            "translationKey": "latvia_region_name_101",
            "shortCode": "101"
        },
        {
            "name": "Valmiera",
            "translationKey": "latvia_region_name_vmr",
            "shortCode": "VMR"
        },
        {
            "name": "Varakļāni",
            "translationKey": "latvia_region_name_102",
            "shortCode": "102"
        },
        {
            "name": "Vārkava",
            "translationKey": "latvia_region_name_103",
            "shortCode": "103"
        },
        {
            "name": "Vecpiebalga",
            "translationKey": "latvia_region_name_104",
            "shortCode": "104"
        },
        {
            "name": "Vecumnieki",
            "translationKey": "latvia_region_name_105",
            "shortCode": "105"
        },
        {
            "name": "Ventspils",
            "translationKey": "latvia_region_name_106",
            "shortCode": "106"
        },
        {
            "name": "Ventspils (City)",
            "translationKey": "latvia_region_name_ven",
            "shortCode": "VEN"
        },
        {
            "name": "Viesīte",
            "translationKey": "latvia_region_name_107",
            "shortCode": "107"
        },
        {
            "name": "Viļaka",
            "translationKey": "latvia_region_name_108",
            "shortCode": "108"
        },
        {
            "name": "Viļāni",
            "translationKey": "latvia_region_name_109",
            "shortCode": "109"
        },
        {
            "name": "Zilupe",
            "translationKey": "latvia_region_name_110",
            "shortCode": "110"
        }
    ]
  },
  //   {
  //     "countryName": "Lebanon",
  //     "countryShortCode": "LB",
  //     "regions": [
  //       {
  //         "name": "Aakkâr",
  //         "shortCode": "AK"
  //       },
  //       {
  //         "name": "Baalbelk-Hermel",
  //         "shortCode": "BH"
  //       },
  //       {
  //         "name": "Béqaa",
  //         "shortCode": "BI"
  //       },
  //       {
  //         "name": "Beyrouth",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Liban-Nord",
  //         "shortCode": "AS"
  //       },
  //       {
  //         "name": "Liban-Sud",
  //         "shortCode": "JA"
  //       },
  //       {
  //         "name": "Mont-Liban",
  //         "shortCode": "JL"
  //       },
  //       {
  //         "name": "Nabatîyé",
  //         "shortCode": "NA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Lesotho",
  //     "countryShortCode": "LS",
  //     "regions": [
  //       {
  //         "name": "Berea",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "Butha-Buthe",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Leribe",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Mafeteng",
  //         "shortCode": "E"
  //       },
  //       {
  //         "name": "Maseru",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "Mohales Hoek",
  //         "shortCode": "F"
  //       },
  //       {
  //         "name": "Mokhotlong",
  //         "shortCode": "J"
  //       },
  //       {
  //         "name": "Qacha's Nek",
  //         "shortCode": "H"
  //       },
  //       {
  //         "name": "Quthing",
  //         "shortCode": "G"
  //       },
  //       {
  //         "name": "Thaba-Tseka",
  //         "shortCode": "K"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Liberia",
  //     "countryShortCode": "LR",
  //     "regions": [
  //       {
  //         "name": "Bomi",
  //         "shortCode": "BM"
  //       },
  //       {
  //         "name": "Bong",
  //         "shortCode": "BG"
  //       },
  //       {
  //         "name": "Gbarpolu",
  //         "shortCode": "GP"
  //       },
  //       {
  //         "name": "Grand Bassa",
  //         "shortCode": "GB"
  //       },
  //       {
  //         "name": "Grand Cape Mount",
  //         "shortCode": "CM"
  //       },
  //       {
  //         "name": "Grand Gedeh",
  //         "shortCode": "GG"
  //       },
  //       {
  //         "name": "Grand Kru",
  //         "shortCode": "GK"
  //       },
  //       {
  //         "name": "Lofa",
  //         "shortCode": "LO"
  //       },
  //       {
  //         "name": "Margibi",
  //         "shortCode": "MG"
  //       },
  //       {
  //         "name": "Maryland",
  //         "shortCode": "MY"
  //       },
  //       {
  //         "name": "Montserrado",
  //         "shortCode": "MO"
  //       },
  //       {
  //         "name": "Nimba",
  //         "shortCode": "NI"
  //       },
  //       {
  //         "name": "River Cess",
  //         "shortCode": "RI"
  //       },
  //       {
  //         "name": "River Geee",
  //         "shortCode": "RG"
  //       },
  //       {
  //         "name": "Sinoe",
  //         "shortCode": "SI"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Libya",
  //     "countryShortCode": "LY",
  //     "regions": [
  //       {
  //         "name": "Al Buţnān",
  //         "shortCode": "BU"
  //       },
  //       {
  //         "name": "Al Jabal al Akhḑar",
  //         "shortCode": "JA"
  //       },
  //       {
  //         "name": "Al Jabal al Gharbī",
  //         "shortCode": "JG"
  //       },
  //       {
  //         "name": "Al Jafārah",
  //         "shortCode": "JI"
  //       },
  //       {
  //         "name": "Al Jufrah",
  //         "shortCode": "JU"
  //       },
  //       {
  //         "name": "Al Kufrah",
  //         "shortCode": "FK"
  //       },
  //       {
  //         "name": "Al Marj",
  //         "shortCode": "MJ"
  //       },
  //       {
  //         "name": "Al Marquab",
  //         "shortCode": "MB"
  //       },
  //       {
  //         "name": "Al Wāḩāt",
  //         "shortCode": "WA"
  //       },
  //       {
  //         "name": "An Nuqaţ al Khams",
  //         "shortCode": "NQ"
  //       },
  //       {
  //         "name": "Az Zāwiyah",
  //         "shortCode": "ZA"
  //       },
  //       {
  //         "name": "Banghāzī",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Darnah",
  //         "shortCode": "DR"
  //       },
  //       {
  //         "name": "Ghāt",
  //         "shortCode": "GH"
  //       },
  //       {
  //         "name": "Mişrātah",
  //         "shortCode": "MI"
  //       },
  //       {
  //         "name": "Murzuq",
  //         "shortCode": "MQ"
  //       },
  //       {
  //         "name": "Nālūt",
  //         "shortCode": "NL"
  //       },
  //       {
  //         "name": "Sabhā",
  //         "shortCode": "SB"
  //       },
  //       {
  //         "name": "Surt",
  //         "shortCode": "SR"
  //       },
  //       {
  //         "name": "Ţarābulus",
  //         "shortCode": "TB"
  //       },
  //       {
  //         "name": "Yafran",
  //         "shortCode": "WD"
  //       },
  //       {
  //         "name": "Wādī ash Shāţiʾ",
  //         "shortCode": "WS"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Liechtenstein",
  //     "countryShortCode": "LI",
  //     "regions": [
  //       {
  //         "name": "Balzers",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Eschen",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Gamprin",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Mauren",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Planken",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Ruggell",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Schaan",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Schellenberg",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Triesen",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Triesenberg",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Vaduz",
  //         "shortCode": "11"
  //       }
  //     ]
  //   },
  {
    "countryName": "Lithuania",
    "translationKey": "country_name_lithuania",
    "countryShortCode": "LT",
    "regions": [
        {
            "name": "Alytaus",
            "translationKey": "lithuania_region_name_al",
            "shortCode": "AL"
        },
        {
            "name": "Kauno",
            "translationKey": "lithuania_region_name_ku",
            "shortCode": "KU"
        },
        {
            "name": "Klaipėdos",
            "translationKey": "lithuania_region_name_kl",
            "shortCode": "KL"
        },
        {
            "name": "Marijampolės",
            "translationKey": "lithuania_region_name_mr",
            "shortCode": "MR"
        },
        {
            "name": "Panevėžio",
            "translationKey": "lithuania_region_name_pn",
            "shortCode": "PN"
        },
        {
            "name": "Šiaulių",
            "translationKey": "lithuania_region_name_sa",
            "shortCode": "SA"
        },
        {
            "name": "Tauragės",
            "translationKey": "lithuania_region_name_ta",
            "shortCode": "TA"
        },
        {
            "name": "Telšių",
            "translationKey": "lithuania_region_name_te",
            "shortCode": "TE"
        },
        {
            "name": "Utenos",
            "translationKey": "lithuania_region_name_ut",
            "shortCode": "UT"
        },
        {
            "name": "Vilniaus",
            "translationKey": "lithuania_region_name_vl",
            "shortCode": "VL"
        }
    ]
},
{
    "countryName": "Luxembourg",
    "translationKey": "country_name_luxembourg",
    "countryShortCode": "LU",
    "regions": [
        {
            "name": "Capellen",
            "translationKey": "luxembourg_region_name_ca",
            "shortCode": "CA"
        },
        {
            "name": "Clevaux",
            "translationKey": "luxembourg_region_name_cl",
            "shortCode": "CL"
        },
        {
            "name": "Diekirch",
            "translationKey": "luxembourg_region_name_di",
            "shortCode": "DI"
        },
        {
            "name": "Echternach",
            "translationKey": "luxembourg_region_name_ec",
            "shortCode": "EC"
        },
        {
            "name": "Esch-sur-Alzette",
            "translationKey": "luxembourg_region_name_es",
            "shortCode": "ES"
        },
        {
            "name": "Grevenmacher",
            "translationKey": "luxembourg_region_name_gr",
            "shortCode": "GR"
        },
        {
            "name": "Luxembourg",
            "translationKey": "luxembourg_region_name_lu",
            "shortCode": "LU"
        },
        {
            "name": "Mersch",
            "translationKey": "luxembourg_region_name_me",
            "shortCode": "ME"
        },
        {
            "name": "Redange",
            "translationKey": "luxembourg_region_name_rd",
            "shortCode": "RD"
        },
        {
            "name": "Remich",
            "translationKey": "luxembourg_region_name_rm",
            "shortCode": "RM"
        },
        {
            "name": "Vianden",
            "translationKey": "luxembourg_region_name_vd",
            "shortCode": "VD"
        },
        {
            "name": "Wiltz",
            "translationKey": "luxembourg_region_name_wi",
            "shortCode": "WI"
        }
    ]
  },
  //   {
  //     "countryName": "Macao",
  //     "countryShortCode": "MO",
  //     "regions": [
  //       {
  //         "name": "Macao"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Macedonia, Republic of",
  //     "countryShortCode": "MK",
  //     "regions": [
  //       {
  //         "name": "Aračinovo",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Berovo",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Bitola",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Bogdanci",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Bogovinje",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Bosilovo",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Brvenica",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Centar Župa",
  //         "shortCode": "78"
  //       },
  //       {
  //         "name": "Čaška",
  //         "shortCode": "80"
  //       },
  //       {
  //         "name": "Češinovo-Obleševo",
  //         "shortCode": "81"
  //       },
  //       {
  //         "name": "Čučer Sandevo",
  //         "shortCode": "82"
  //       },
  //       {
  //         "name": "Debar",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Debarca",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Delčevo",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Demir Hisar",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Demir Kapija",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Doran",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Dolneni",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Gevgelija",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Gostivar",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Gradsko",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Ilinden",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Jegunovce",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Karbinci",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "Kavadarci",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "Kičevo",
  //         "shortCode": "40"
  //       },
  //       {
  //         "name": "Kočani",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Konče",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Kratovo",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Kriva Palanka",
  //         "shortCode": "44"
  //       },
  //       {
  //         "name": "Krivogaštani",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Kruševo",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "Kumanovo",
  //         "shortCode": "47"
  //       },
  //       {
  //         "name": "Lipkovo",
  //         "shortCode": "48"
  //       },
  //       {
  //         "name": "Lozovo",
  //         "shortCode": "49"
  //       },
  //       {
  //         "name": "Makedonska Kamenica",
  //         "shortCode": "51"
  //       },
  //       {
  //         "name": "Makedonski Brod",
  //         "shortCode": "52"
  //       },
  //       {
  //         "name": "Mavrovo i Rostuša",
  //         "shortCode": "50"
  //       },
  //       {
  //         "name": "Mogila",
  //         "shortCode": "53"
  //       },
  //       {
  //         "name": "Negotino",
  //         "shortCode": "54"
  //       },
  //       {
  //         "name": "Novaci",
  //         "shortCode": "55"
  //       },
  //       {
  //         "name": "Novo Selo",
  //         "shortCode": "56"
  //       },
  //       {
  //         "name": "Ohrid",
  //         "shortCode": "58"
  //       },
  //       {
  //         "name": "Pehčevo",
  //         "shortCode": "60"
  //       },
  //       {
  //         "name": "Petrovec",
  //         "shortCode": "59"
  //       },
  //       {
  //         "name": "Plasnica",
  //         "shortCode": "61"
  //       },
  //       {
  //         "name": "Prilep",
  //         "shortCode": "62"
  //       },
  //       {
  //         "name": "Probištip",
  //         "shortCode": "63"
  //       },
  //       {
  //         "name": "Radoviš",
  //         "shortCode": ""
  //       },
  //       {
  //         "name": "Rankovce",
  //         "shortCode": "65"
  //       },
  //       {
  //         "name": "Resen",
  //         "shortCode": "66"
  //       },
  //       {
  //         "name": "Rosoman",
  //         "shortCode": "67"
  //       },
  //       {
  //         "name": "Skopje",
  //         "shortCode": "85"
  //       },
  //       {
  //         "name": "Sopište",
  //         "shortCode": "70"
  //       },
  //       {
  //         "name": "Staro Nagoričane",
  //         "shortCode": "71"
  //       },
  //       {
  //         "name": "Struga",
  //         "shortCode": "72"
  //       },
  //       {
  //         "name": "Strumica",
  //         "shortCode": "73"
  //       },
  //       {
  //         "name": "Studeničani",
  //         "shortCode": "74"
  //       },
  //       {
  //         "name": "Sveti Nikole",
  //         "shortCode": "69"
  //       },
  //       {
  //         "name": "Štip",
  //         "shortCode": "83"
  //       },
  //       {
  //         "name": "Tearce",
  //         "shortCode": "75"
  //       },
  //       {
  //         "name": "Tetovo",
  //         "shortCode": "76"
  //       },
  //       {
  //         "name": "Valandovo",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Vasilevo",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Veles",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Vevčani",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Vinica",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Vrapčište",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Zelenikovo",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Zrnovci",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Želino",
  //         "shortCode": "30"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Madagascar",
  //     "countryShortCode": "MG",
  //     "regions": [
  //       {
  //         "name": "Antananarivo",
  //         "shortCode": "T"
  //       },
  //       {
  //         "name": "Antsiranana",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "Fianarantsoa",
  //         "shortCode": "F"
  //       },
  //       {
  //         "name": "Mahajanga",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "Toamasina",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "Toliara",
  //         "shortCode": "U"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Malawi",
  //     "countryShortCode": "MW",
  //     "regions": [
  //       {
  //         "name": "Balaka",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Blantyre",
  //         "shortCode": "BL"
  //       },
  //       {
  //         "name": "Chikwawa",
  //         "shortCode": "CK"
  //       },
  //       {
  //         "name": "Chiradzulu",
  //         "shortCode": "CR"
  //       },
  //       {
  //         "name": "Chitipa",
  //         "shortCode": "CT"
  //       },
  //       {
  //         "name": "Dedza",
  //         "shortCode": "DE"
  //       },
  //       {
  //         "name": "Dowa",
  //         "shortCode": "DO"
  //       },
  //       {
  //         "name": "Karonga",
  //         "shortCode": "KR"
  //       },
  //       {
  //         "name": "Kasungu",
  //         "shortCode": "KS"
  //       },
  //       {
  //         "name": "Likoma",
  //         "shortCode": "LK"
  //       },
  //       {
  //         "name": "Lilongwe",
  //         "shortCode": "LI"
  //       },
  //       {
  //         "name": "Machinga",
  //         "shortCode": "MH"
  //       },
  //       {
  //         "name": "Mangochi",
  //         "shortCode": "MG"
  //       },
  //       {
  //         "name": "Mchinji",
  //         "shortCode": "MC"
  //       },
  //       {
  //         "name": "Mulanje",
  //         "shortCode": "MU"
  //       },
  //       {
  //         "name": "Mwanza",
  //         "shortCode": "MW"
  //       },
  //       {
  //         "name": "Mzimba",
  //         "shortCode": "MZ"
  //       },
  //       {
  //         "name": "Nkhata Bay",
  //         "shortCode": "NE"
  //       },
  //       {
  //         "name": "Nkhotakota",
  //         "shortCode": "NB"
  //       },
  //       {
  //         "name": "Nsanje",
  //         "shortCode": "NS"
  //       },
  //       {
  //         "name": "Ntcheu",
  //         "shortCode": "NU"
  //       },
  //       {
  //         "name": "Ntchisi",
  //         "shortCode": "NI"
  //       },
  //       {
  //         "name": "Phalombe",
  //         "shortCode": "PH"
  //       },
  //       {
  //         "name": "Rumphi",
  //         "shortCode": "RU"
  //       },
  //       {
  //         "name": "Salima",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Thyolo",
  //         "shortCode": "TH"
  //       },
  //       {
  //         "name": "Zomba",
  //         "shortCode": "ZO"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Malaysia",
  //     "countryShortCode": "MY",
  //     "regions": [
  //       {
  //         "name": "Johor",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Kedah",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Kelantan",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Melaka",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Negeri Sembilan",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Pahang",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Perak",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Perlis",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Pulau Pinang",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Sabah",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Sarawak",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Selangor",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Terengganu",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Wilayah Persekutuan (Kuala Lumpur)",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Wilayah Persekutuan (Labuan)",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Wilayah Persekutuan (Putrajaya)",
  //         "shortCode": "16"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Maldives",
  //     "countryShortCode": "MV",
  //     "regions": [
  //       {
  //         "name": "Alifu Alifu",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Alifu Dhaalu",
  //         "shortCode": "00"
  //       },
  //       {
  //         "name": "Baa",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Dhaalu",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Faafu",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Gaafu Alifu",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Gaafu Dhaalu",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Gnaviyani",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Haa Alifu",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Haa Dhaalu",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Kaafu",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Laamu",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Lhaviyani",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Malé",
  //         "shortCode": "MLE"
  //       },
  //       {
  //         "name": "Meemu",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Noonu",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Raa",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Seenu",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Shaviyani",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Thaa",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Vaavu",
  //         "shortCode": "04"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Mali",
  //     "countryShortCode": "ML",
  //     "regions": [
  //       {
  //         "name": "Bamako",
  //         "shortCode": "BKO"
  //       },
  //       {
  //         "name": "Gao",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Kayes",
  //         "shortCode": "1"
  //       },
  //       {
  //         "name": "Kidal",
  //         "shortCode": "8"
  //       },
  //       {
  //         "name": "Koulikoro",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Mopti",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Segou",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Sikasso",
  //         "shortCode": "3"
  //       },
  //       {
  //         "name": "Tombouctou",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "Taoudénit",
  //         "shortCode": "9"
  //       },
  //       {
  //         "name": "Ménaka",
  //         "shortCode": "10"
  //       }
  //     ]
  //   },
  {
    "countryName": "Malta",
    "translationKey": "country_name_malta",
    "countryShortCode": "MT",
    "regions": [
        {
            "name": "Attard",
            "translationKey": "malta_region_name_01",
            "shortCode": "01"
        },
        {
            "name": "Balzan",
            "translationKey": "malta_region_name_02",
            "shortCode": "02"
        },
        {
            "name": "Birgu",
            "translationKey": "malta_region_name_03",
            "shortCode": "03"
        },
        {
            "name": "Birkirkara",
            "translationKey": "malta_region_name_04",
            "shortCode": "04"
        },
        {
            "name": "Birżebbuġa",
            "translationKey": "malta_region_name_05",
            "shortCode": "05"
        },
        {
            "name": "Bormla",
            "translationKey": "malta_region_name_06",
            "shortCode": "06"
        },
        {
            "name": "Dingli",
            "translationKey": "malta_region_name_07",
            "shortCode": "07"
        },
        {
            "name": "Fgura",
            "translationKey": "malta_region_name_08",
            "shortCode": "08"
        },
        {
            "name": "Floriana",
            "translationKey": "malta_region_name_09",
            "shortCode": "09"
        },
        {
            "name": "Fontana",
            "translationKey": "malta_region_name_10",
            "shortCode": "10"
        },
        {
            "name": "Guda",
            "translationKey": "malta_region_name_11",
            "shortCode": "11"
        },
        {
            "name": "Gżira",
            "translationKey": "malta_region_name_12",
            "shortCode": "12"
        },
        {
            "name": "Għajnsielem",
            "translationKey": "malta_region_name_13",
            "shortCode": "13"
        },
        {
            "name": "Għarb",
            "translationKey": "malta_region_name_14",
            "shortCode": "14"
        },
        {
            "name": "Għargħur",
            "translationKey": "malta_region_name_15",
            "shortCode": "15"
        },
        {
            "name": "Għasri",
            "translationKey": "malta_region_name_16",
            "shortCode": "16"
        },
        {
            "name": "Għaxaq",
            "translationKey": "malta_region_name_17",
            "shortCode": "17"
        },
        {
            "name": "Ħamrun",
            "translationKey": "malta_region_name_18",
            "shortCode": "18"
        },
        {
            "name": "Iklin",
            "translationKey": "malta_region_name_19",
            "shortCode": "19"
        },
        {
            "name": "Isla",
            "translationKey": "malta_region_name_20",
            "shortCode": "20"
        },
        {
            "name": "Kalkara",
            "translationKey": "malta_region_name_21",
            "shortCode": "21"
        },
        {
            "name": "Kerċem",
            "translationKey": "malta_region_name_22",
            "shortCode": "22"
        },
        {
            "name": "Kirkop",
            "translationKey": "malta_region_name_23",
            "shortCode": "23"
        },
        {
            "name": "Lija",
            "translationKey": "malta_region_name_24",
            "shortCode": "24"
        },
        {
            "name": "Luqa",
            "translationKey": "malta_region_name_25",
            "shortCode": "25"
        },
        {
            "name": "Marsa",
            "translationKey": "malta_region_name_26",
            "shortCode": "26"
        },
        {
            "name": "Marsaskala",
            "translationKey": "malta_region_name_27",
            "shortCode": "27"
        },
        {
            "name": "Marsaxlokk",
            "translationKey": "malta_region_name_28",
            "shortCode": "28"
        },
        {
            "name": "Mdina",
            "translationKey": "malta_region_name_29",
            "shortCode": "29"
        },
        {
            "name": "Mellieħa",
            "translationKey": "malta_region_name_30",
            "shortCode": "30"
        },
        {
            "name": "Mġarr",
            "translationKey": "malta_region_name_31",
            "shortCode": "31"
        },
        {
            "name": "Mosta",
            "translationKey": "malta_region_name_32",
            "shortCode": "32"
        },
        {
            "name": "Mqabba",
            "translationKey": "malta_region_name_33",
            "shortCode": "33"
        },
        {
            "name": "Msida",
            "translationKey": "malta_region_name_34",
            "shortCode": "34"
        },
        {
            "name": "Mtarfa",
            "translationKey": "malta_region_name_35",
            "shortCode": "35"
        },
        {
            "name": "Munxar",
            "translationKey": "malta_region_name_36",
            "shortCode": "36"
        },
        {
            "name": "Nadur",
            "translationKey": "malta_region_name_37",
            "shortCode": "37"
        },
        {
            "name": "Naxxar",
            "translationKey": "malta_region_name_38",
            "shortCode": "38"
        },
        {
            "name": "Paola",
            "translationKey": "malta_region_name_39",
            "shortCode": "39"
        },
        {
            "name": "Pembroke",
            "translationKey": "malta_region_name_40",
            "shortCode": "40"
        },
        {
            "name": "Pietà",
            "translationKey": "malta_region_name_41",
            "shortCode": "41"
        },
        {
            "name": "Qala",
            "translationKey": "malta_region_name_42",
            "shortCode": "42"
        },
        {
            "name": "Qormi",
            "translationKey": "malta_region_name_43",
            "shortCode": "43"
        },
        {
            "name": "Qrendi",
            "translationKey": "malta_region_name_44",
            "shortCode": "44"
        },
        {
            "name": "Rabat Għawdex",
            "translationKey": "malta_region_name_45",
            "shortCode": "45"
        },
        {
            "name": "Rabat Malta",
            "translationKey": "malta_region_name_46",
            "shortCode": "46"
        },
        {
            "name": "Safi",
            "translationKey": "malta_region_name_47",
            "shortCode": "47"
        },
        {
            "name": "San Ġiljan",
            "translationKey": "malta_region_name_48",
            "shortCode": "48"
        },
        {
            "name": "San Ġwann",
            "translationKey": "malta_region_name_49",
            "shortCode": "49"
        },
        {
            "name": "San Lawrenz",
            "translationKey": "malta_region_name_50",
            "shortCode": "50"
        },
        {
            "name": "San Pawl il-Baħar",
            "translationKey": "malta_region_name_51",
            "shortCode": "51"
        },
        {
            "name": "Sannat",
            "translationKey": "malta_region_name_52",
            "shortCode": "52"
        },
        {
            "name": "Santa Luċija",
            "translationKey": "malta_region_name_53",
            "shortCode": "53"
        },
        {
            "name": "Santa Venera",
            "translationKey": "malta_region_name_54",
            "shortCode": "54"
        },
        {
            "name": "Siġġiewi",
            "translationKey": "malta_region_name_55",
            "shortCode": "55"
        },
        {
            "name": "Sliema",
            "translationKey": "malta_region_name_56",
            "shortCode": "56"
        },
        {
            "name": "Swieqi",
            "translationKey": "malta_region_name_57",
            "shortCode": "57"
        },
        {
            "name": "Tai Xbiex",
            "translationKey": "malta_region_name_58",
            "shortCode": "58"
        },
        {
            "name": "Tarzien",
            "translationKey": "malta_region_name_59",
            "shortCode": "59"
        },
        {
            "name": "Valletta",
            "translationKey": "malta_region_name_60",
            "shortCode": "60"
        },
        {
            "name": "Xagħra",
            "translationKey": "malta_region_name_61",
            "shortCode": "61"
        },
        {
            "name": "Xewkija",
            "translationKey": "malta_region_name_62",
            "shortCode": "62"
        },
        {
            "name": "Xgħajra",
            "translationKey": "malta_region_name_63",
            "shortCode": "63"
        },
        {
            "name": "Żabbar",
            "translationKey": "malta_region_name_64",
            "shortCode": "64"
        },
        {
            "name": "Żebbuġ Għawde",
            "translationKey": "malta_region_name_65",
            "shortCode": "65"
        },
        {
            "name": "Żebbuġ Malta",
            "translationKey": "malta_region_name_66",
            "shortCode": "66"
        },
        {
            "name": "Żejtun",
            "translationKey": "malta_region_name_67",
            "shortCode": "67"
        },
        {
            "name": "Żurrieq",
            "translationKey": "malta_region_name_68",
            "shortCode": "68"
        }
    ]
  },
  //   {
  //     "countryName": "Marshall Islands",
  //     "countryShortCode": "MH",
  //     "regions": [
  //       {
  //         "name": "Ailinglaplap",
  //         "shortCode": "ALL"
  //       },
  //       {
  //         "name": "Ailuk",
  //         "shortCode": "ALK"
  //       },
  //       {
  //         "name": "Arno",
  //         "shortCode": "ARN"
  //       },
  //       {
  //         "name": "Aur",
  //         "shortCode": "AUR"
  //       },
  //       {
  //         "name": "Bikini and Kili",
  //         "shortCode": "KIL"
  //       },
  //       {
  //         "name": "Ebon",
  //         "shortCode": "EBO"
  //       },
  //       {
  //         "name": "Jabat",
  //         "shortCode": "JAB"
  //       },
  //       {
  //         "name": "Jaluit",
  //         "shortCode": "JAL"
  //       },
  //       {
  //         "name": "Kwajalein",
  //         "shortCode": "KWA"
  //       },
  //       {
  //         "name": "Lae",
  //         "shortCode": "LAE"
  //       },
  //       {
  //         "name": "Lib",
  //         "shortCode": "LIB"
  //       },
  //       {
  //         "name": "Likiep",
  //         "shortCode": "LIK"
  //       },
  //       {
  //         "name": "Majuro",
  //         "shortCode": "MAJ"
  //       },
  //       {
  //         "name": "Maloelap",
  //         "shortCode": "MAL"
  //       },
  //       {
  //         "name": "Mejit",
  //         "shortCode": "MEJ"
  //       },
  //       {
  //         "name": "Namdrik",
  //         "shortCode": "NMK"
  //       },
  //       {
  //         "name": "Namu",
  //         "shortCode": "NMU"
  //       },
  //       {
  //         "name": "Rongelap",
  //         "shortCode": "RON"
  //       },
  //       {
  //         "name": "Ujae",
  //         "shortCode": "UJA"
  //       },
  //       {
  //         "name": "Utrik",
  //         "shortCode": "UTI"
  //       },
  //       {
  //         "name": "Wotho",
  //         "shortCode": "WTH"
  //       },
  //       {
  //         "name": "Wotje",
  //         "shortCode": "WTJ"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Martinique",
  //     "countryShortCode": "MQ",
  //     "regions": [
  //       {
  //         "name": "Martinique"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Mauritania",
  //     "countryShortCode": "MR",
  //     "regions": [
  //       {
  //         "name": "Adrar",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Assaba",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Brakna",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Dakhlet Nouadhibou",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Gorgol",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Guidimaka",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Hodh Ech Chargui",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Hodh El Gharbi",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Inchiri",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Nouakchott Nord",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Nouakchott Ouest",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Nouakchott Sud",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Tagant",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Tiris Zemmour",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Trarza",
  //         "shortCode": "06"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Mauritius",
  //     "countryShortCode": "MU",
  //     "regions": [
  //       {
  //         "name": "Agalega Islands",
  //         "shortCode": "AG"
  //       },
  //       {
  //         "name": "Beau Bassin-Rose Hill",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Black River",
  //         "shortCode": "BL"
  //       },
  //       {
  //         "name": "Cargados Carajos Shoals",
  //         "shortCode": "CC"
  //       },
  //       {
  //         "name": "Curepipe",
  //         "shortCode": "CU"
  //       },
  //       {
  //         "name": "Flacq",
  //         "shortCode": "FL"
  //       },
  //       {
  //         "name": "Grand Port",
  //         "shortCode": "GP"
  //       },
  //       {
  //         "name": "Moka",
  //         "shortCode": "MO"
  //       },
  //       {
  //         "name": "Pamplemousses",
  //         "shortCode": "PA"
  //       },
  //       {
  //         "name": "Plaines Wilhems",
  //         "shortCode": "PW"
  //       },
  //       {
  //         "name": "Port Louis (City)",
  //         "shortCode": "PU"
  //       },
  //       {
  //         "name": "Port Louis",
  //         "shortCode": "PL"
  //       },
  //       {
  //         "name": "Riviere du Rempart",
  //         "shortCode": "RR"
  //       },
  //       {
  //         "name": "Rodrigues Island",
  //         "shortCode": "RO"
  //       },
  //       {
  //         "name": "Savanne",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Vacoas-Phoenix",
  //         "shortCode": "CP"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Mayotte",
  //     "countryShortCode": "YT",
  //     "regions": [
  //       {
  //         "name": "Dzaoudzi",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Pamandzi",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Mamoudzou",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Dembeni",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Bandrélé",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Kani-Kéli",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Bouéni",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Chirongui",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Sada",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Ouangani",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Chiconi",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Tsingoni",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "M'Tsangamouji",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Acoua",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Mtsamboro",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Bandraboua",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Koungou",
  //         "shortCode": "17"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Mexico",
  //     "countryShortCode": "MX",
  //     "regions": [
  //       {
  //         "name": "Aguascalientes",
  //         "shortCode": "AGU"
  //       },
  //       {
  //         "name": "Baja California",
  //         "shortCode": "BCN"
  //       },
  //       {
  //         "name": "Baja California Sur",
  //         "shortCode": "BCS"
  //       },
  //       {
  //         "name": "Campeche",
  //         "shortCode": "CAM"
  //       },
  //       {
  //         "name": "Ciudad de México",
  //         "shortCode": "DIF"
  //       },
  //       {
  //         "name": "Chiapas",
  //         "shortCode": "CHP"
  //       },
  //       {
  //         "name": "Chihuahua",
  //         "shortCode": "CHH"
  //       },
  //       {
  //         "name": "Coahuila de Zaragoza",
  //         "shortCode": "COA"
  //       },
  //       {
  //         "name": "Colima",
  //         "shortCode": "COL"
  //       },
  //       {
  //         "name": "Durango",
  //         "shortCode": "DUR"
  //       },
  //       {
  //         "name": "Estado de México",
  //         "shortCode": "MEX"
  //       },
  //       {
  //         "name": "Guanajuato",
  //         "shortCode": "GUA"
  //       },
  //       {
  //         "name": "Guerrero",
  //         "shortCode": "GRO"
  //       },
  //       {
  //         "name": "Hidalgo",
  //         "shortCode": "HID"
  //       },
  //       {
  //         "name": "Jalisco",
  //         "shortCode": "JAL"
  //       },
  //       {
  //         "name": "Michoacán de Ocampo",
  //         "shortCode": "MIC"
  //       },
  //       {
  //         "name": "Morelos",
  //         "shortCode": "MOR"
  //       },
  //       {
  //         "name": "Nayarit",
  //         "shortCode": "NAY"
  //       },
  //       {
  //         "name": "Nuevo León",
  //         "shortCode": "NLE"
  //       },
  //       {
  //         "name": "Oaxaca",
  //         "shortCode": "OAX"
  //       },
  //       {
  //         "name": "Puebla",
  //         "shortCode": "PUE"
  //       },
  //       {
  //         "name": "Querétaro de Arteaga",
  //         "shortCode": "QUE"
  //       },
  //       {
  //         "name": "Quintana Roo",
  //         "shortCode": "ROO"
  //       },
  //       {
  //         "name": "San Luis Potosí",
  //         "shortCode": "SLP"
  //       },
  //       {
  //         "name": "Sinaloa",
  //         "shortCode": "SIN"
  //       },
  //       {
  //         "name": "Sonora",
  //         "shortCode": "SON"
  //       },
  //       {
  //         "name": "Tabasco",
  //         "shortCode": "TAB"
  //       },
  //       {
  //         "name": "Tamaulipas",
  //         "shortCode": "TAM"
  //       },
  //       {
  //         "name": "Tlaxcala",
  //         "shortCode": "TLA"
  //       },
  //       {
  //         "name": "Veracruz",
  //         "shortCode": "VER"
  //       },
  //       {
  //         "name": "Yucatán",
  //         "shortCode": "YUC"
  //       },
  //       {
  //         "name": "Zacatecas",
  //         "shortCode": "ZAC"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Micronesia, Federated States of",
  //     "countryShortCode": "FM",
  //     "regions": [
  //       {
  //         "name": "Chuuk (Truk)",
  //         "shortCode": "TRK"
  //       },
  //       {
  //         "name": "Kosrae",
  //         "shortCode": "KSA"
  //       },
  //       {
  //         "name": "Pohnpei",
  //         "shortCode": "PNI"
  //       },
  //       {
  //         "name": "Yap",
  //         "shortCode": "YAP"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Moldova",
  //     "countryShortCode": "MD",
  //     "regions": [
  //       {
  //         "name": "Aenii Noi",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Basarabeasca",
  //         "shortCode": "BS"
  //       },
  //       {
  //         "name": "Bălți",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Bender",
  //         "shortCode": "BD"
  //       },
  //       {
  //         "name": "Briceni",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Cahul",
  //         "shortCode": "CA"
  //       },
  //       {
  //         "name": "Cantemir",
  //         "shortCode": "CT"
  //       },
  //       {
  //         "name": "Călărași",
  //         "shortCode": "CL"
  //       },
  //       {
  //         "name": "Căușeni",
  //         "shortCode": "CS"
  //       },
  //       {
  //         "name": "Chișinău",
  //         "shortCode": "CU"
  //       },
  //       {
  //         "name": "Cimișlia",
  //         "shortCode": "CM"
  //       },
  //       {
  //         "name": "Criuleni",
  //         "shortCode": "CR"
  //       },
  //       {
  //         "name": "Dondușeni",
  //         "shortCode": "DO"
  //       },
  //       {
  //         "name": "Drochia",
  //         "shortCode": "DR"
  //       },
  //       {
  //         "name": "Dubăsari",
  //         "shortCode": "DU"
  //       },
  //       {
  //         "name": "Edineț",
  //         "shortCode": "ED"
  //       },
  //       {
  //         "name": "Fălești",
  //         "shortCode": "FA"
  //       },
  //       {
  //         "name": "Florești",
  //         "shortCode": "FL"
  //       },
  //       {
  //         "name": "Găgăuzia",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Glodeni",
  //         "shortCode": "GL"
  //       },
  //       {
  //         "name": "Hîncești",
  //         "shortCode": "HI"
  //       },
  //       {
  //         "name": "Ialoveni",
  //         "shortCode": "IA"
  //       },
  //       {
  //         "name": "Leova",
  //         "shortCode": "LE"
  //       },
  //       {
  //         "name": "Nisporeni",
  //         "shortCode": "NI"
  //       },
  //       {
  //         "name": "Ocnița",
  //         "shortCode": "OC"
  //       },
  //       {
  //         "name": "Orhei",
  //         "shortCode": "OR"
  //       },
  //       {
  //         "name": "Rezina",
  //         "shortCode": "RE"
  //       },
  //       {
  //         "name": "Rîșcani",
  //         "shortCode": "RI"
  //       },
  //       {
  //         "name": "Sîngerei",
  //         "shortCode": "SI"
  //       },
  //       {
  //         "name": "Soroca",
  //         "shortCode": "SO"
  //       },
  //       {
  //         "name": "Stînga Nistrului",
  //         "shortCode": "SN"
  //       },
  //       {
  //         "name": "Strășeni",
  //         "shortCode": "ST"
  //       },
  //       {
  //         "name": "Șoldănești",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Ștefan Vodă",
  //         "shortCode": "SV"
  //       },
  //       {
  //         "name": "Taraclia",
  //         "shortCode": "TA"
  //       },
  //       {
  //         "name": "Telenești",
  //         "shortCode": "TE"
  //       },
  //       {
  //         "name": "Ungheni",
  //         "shortCode": "UN"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Monaco",
  //     "countryShortCode": "MC",
  //     "regions": [
  //       {
  //         "name": "Colle",
  //         "shortCode": "CL"
  //       },
  //       {
  //         "name": "Condamine",
  //         "shortCode": "CO"
  //       },
  //       {
  //         "name": "Fontvieille",
  //         "shortCode": "FO"
  //       },
  //       {
  //         "name": "Gare",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Jardin Exotique",
  //         "shortCode": "JE"
  //       },
  //       {
  //         "name": "Larvotto",
  //         "shortCode": "LA"
  //       },
  //       {
  //         "name": "Malbousquet",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Monaco-Ville",
  //         "shortCode": "MO"
  //       },
  //       {
  //         "name": "Moneghetti",
  //         "shortCode": "MG"
  //       },
  //       {
  //         "name": "Monte-Carlo",
  //         "shortCode": "MC"
  //       },
  //       {
  //         "name": "Moulins",
  //         "shortCode": "MU"
  //       },
  //       {
  //         "name": "Port-Hercule",
  //         "shortCode": "PH"
  //       },
  //       {
  //         "name": "Saint-Roman",
  //         "shortCode": "SR"
  //       },
  //       {
  //         "name": "Sainte-Dévote",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Source",
  //         "shortCode": "SO"
  //       },
  //       {
  //         "name": "Spélugues",
  //         "shortCode": "SP"
  //       },
  //       {
  //         "name": "Vallon de la Rousse",
  //         "shortCode": "VR"
  //       }
  //     ]
  //   },
  {
    "countryName": "Mongolia",
    "countryShortCode": "MN",
    "regions": [
        {
            "name": "Arhangay",
            "shortCode": "073",
            "translationKey": "Mongolia_region_073"
        },
        {
            "name": "Bayan-Olgiy",
            "shortCode": "071",
            "translationKey": "Mongolia_region_071"
        },
        {
            "name": "Bayanhongor",
            "shortCode": "069",
            "translationKey": "Mongolia_region_069"
        },
        {
            "name": "Bulgan",
            "shortCode": "067",
            "translationKey": "Mongolia_region_067"
        },
        {
            "name": "Darhan",
            "shortCode": "037",
            "translationKey": "Mongolia_region_037"
        },
        {
            "name": "Dornod",
            "shortCode": "061",
            "translationKey": "Mongolia_region_061"
        },
        {
            "name": "Dornogovi",
            "shortCode": "063",
            "translationKey": "Mongolia_region_063"
        },
        {
            "name": "Dundgovi",
            "shortCode": "059",
            "translationKey": "Mongolia_region_059"
        },
        {
            "name": "Dzavhan",
            "shortCode": "057",
            "translationKey": "Mongolia_region_057"
        },
        {
            "name": "Govi-Altay",
            "shortCode": "065",
            "translationKey": "Mongolia_region_065"
        },
        {
            "name": "Govi-Sumber",
            "shortCode": "064",
            "translationKey": "Mongolia_region_064"
        },
        {
            "name": "Hovd",
            "shortCode": "043",
            "translationKey": "Mongolia_region_043"
        },
        {
            "name": "Hovsgol",
            "shortCode": "041",
            "translationKey": "Mongolia_region_041"
        },
        {
            "name": "Omnogovi",
            "shortCode": "053",
            "translationKey": "Mongolia_region_053"
        },
        {
            "name": "Ovorhangay",
            "shortCode": "055",
            "translationKey": "Mongolia_region_055"
        },
        {
            "name": "Selenge",
            "shortCode": "049",
            "translationKey": "Mongolia_region_049"
        },
        {
            "name": "Suhbaatar",
            "shortCode": "051",
            "translationKey": "Mongolia_region_051"
        },
        {
            "name": "Tov",
            "shortCode": "047",
            "translationKey": "Mongolia_region_047"
        },
        {
            "name": "Ulaanbaatar",
            "shortCode": "1",
            "translationKey": "Mongolia_region_1"
        },
        {
            "name": "Uvs",
            "shortCode": "046",
            "translationKey": "Mongolia_region_046"
        }
    ],
    "translationKey": "country_name_Mongolia"
  },
  //   {
  //     "countryName": "Montenegro",
  //     "countryShortCode": "ME",
  //     "regions": [
  //       {
  //         "name": "Andrijevica",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Bar",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Berane",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Bijelo Polje",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Budva",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Cetinje",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Danilovgrad",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Gusinje",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Herceg Novi",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Kolašin",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Kotor",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Mojkovac",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Nikšić",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Petnica",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Plav",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Plužine",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Pljevlja",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Podgorica",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Rožaje",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Šavnik",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Tivat",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Ulcinj",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Žabljak",
  //         "shortCode": "21"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Montserrat",
  //     "countryShortCode": "MS",
  //     "regions": [
  //       {
  //         "name": "Saint Anthony"
  //       },
  //       {
  //         "name": "Saint Georges"
  //       },
  //       {
  //         "name": "Saint Peter's"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Morocco",
  //     "countryShortCode": "MA",
  //     "regions": [
  //       {
  //         "name": "Tanger-Tétouan-Al Hoceïma",
  //         "code": "MA-01"
  //       },
  //       {
  //         "name": "L'Oriental",
  //         "code": "MA-02"
  //       },
  //       {
  //         "name": "Fès-Meknès",
  //         "code": "MA-03"
  //       },
  //       {
  //         "name": "Rabat-Salé-Kénitra",
  //         "code": "MA-04"
  //       },
  //       {
  //         "name": "Béni Mellal-Khénifra",
  //         "code": "MA-05"
  //       },
  //       {
  //         "name": "Casablanca-Settat",
  //         "code": "MA-06"
  //       },
  //       {
  //         "name": "Marrakech-Safi",
  //         "code": "MA-07"
  //       },
  //       {
  //         "name": "Drâa-Tafilalet",
  //         "code": "MA-08"
  //       },
  //       {
  //         "name": "Souss-Massa",
  //         "code": "MA-09"
  //       },
  //       {
  //         "name": "Guelmim-Oued Noun",
  //         "code": "MA-10"
  //       },
  //       {
  //         "name": "Laâyoune-Sakia El Hamra",
  //         "code": "MA-11"
  //       },
  //       {
  //         "name": "Dakhla-Oued Ed-Dahab",
  //         "code": "MA-12"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Mozambique",
  //     "countryShortCode": "MZ",
  //     "regions": [
  //       {
  //         "name": "Cabo Delgado",
  //         "shortCode": "P"
  //       },
  //       {
  //         "name": "Gaza",
  //         "shortCode": "G"
  //       },
  //       {
  //         "name": "Inhambane",
  //         "shortCode": "I"
  //       },
  //       {
  //         "name": "Manica",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Maputo",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "Maputo (City)",
  //         "shortCode": "MPM"
  //       },
  //       {
  //         "name": "Nampula",
  //         "shortCode": "N"
  //       },
  //       {
  //         "name": "Niassa",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "Sofala",
  //         "shortCode": "S"
  //       },
  //       {
  //         "name": "Tete",
  //         "shortCode": "T"
  //       },
  //       {
  //         "name": "Zambezia",
  //         "shortCode": "Q"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Myanmar",
  //     "countryShortCode": "MM",
  //     "regions": [
  //       {
  //         "name": "Ayeyarwady",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Bago",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Chin",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Kachin",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Kayah",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Kayin",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Magway",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Mandalay",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Mon",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Nay Pyi Taw",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Rakhine",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Sagaing",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Shan",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Tanintharyi",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Yangon",
  //         "shortCode": "06"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Namibia",
  //     "countryShortCode": "NA",
  //     "regions": [
  //       {
  //         "name": "Erongo",
  //         "shortCode": "ER"
  //       },
  //       {
  //         "name": "Hardap",
  //         "shortCode": "HA"
  //       },
  //       {
  //         "name": "Kavango East",
  //         "shortCode": "KE"
  //       },
  //       {
  //         "name": "Kavango West",
  //         "shortCode": "KW"
  //       },
  //       {
  //         "name": "Karas",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Khomas",
  //         "shortCode": "KH"
  //       },
  //       {
  //         "name": "Kunene",
  //         "shortCode": "KU"
  //       },
  //       {
  //         "name": "Ohangwena",
  //         "shortCode": "OW"
  //       },
  //       {
  //         "name": "Omaheke",
  //         "shortCode": "OH"
  //       },
  //       {
  //         "name": "Omusati",
  //         "shortCode": "OS"
  //       },
  //       {
  //         "name": "Oshana",
  //         "shortCode": "ON"
  //       },
  //       {
  //         "name": "Oshikoto",
  //         "shortCode": "OT"
  //       },
  //       {
  //         "name": "Otjozondjupa",
  //         "shortCode": "OD"
  //       },
  //       {
  //         "name": "Zambezi",
  //         "shortCode": "CA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Nauru",
  //     "countryShortCode": "NR",
  //     "regions": [
  //       {
  //         "name": "Aiwo",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Anabar",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Anetan",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Anibare",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Baiti",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Boe",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Buada",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Denigomodu",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Ewa",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Ijuw",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Meneng",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Nibok",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Uaboe",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Yaren",
  //         "shortCode": "14"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Nepal",
  //     "countryShortCode": "NP",
  //     "regions": [
  //       {
  //         "name": "Bagmati",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Bheri",
  //         "shortCode": "BH"
  //       },
  //       {
  //         "name": "Dhawalagiri",
  //         "shortCode": "DH"
  //       },
  //       {
  //         "name": "Gandaki",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Janakpur",
  //         "shortCode": "JA"
  //       },
  //       {
  //         "name": "Karnali",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Kosi",
  //         "shortCode": "KO"
  //       },
  //       {
  //         "name": "Lumbini",
  //         "shortCode": "LU"
  //       },
  //       {
  //         "name": "Mahakali",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Mechi",
  //         "shortCode": "ME"
  //       },
  //       {
  //         "name": "Narayani",
  //         "shortCode": "NA"
  //       },
  //       {
  //         "name": "Rapti",
  //         "shortCode": "RA"
  //       },
  //       {
  //         "name": "Sagarmatha",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Seti",
  //         "shortCode": "SE"
  //       }
  //     ]
  //   },
  {
    "countryName": "Netherlands",
    "translationKey": "country_name_netherlands",
    "countryShortCode": "NL",
    "regions": [
        {
            "name": "Drenthe",
            "translationKey": "netherlands_region_name_dr",
            "shortCode": "DR"
        },
        {
            "name": "Flevoland",
            "translationKey": "netherlands_region_name_fl",
            "shortCode": "FL"
        },
        {
            "name": "Friesland",
            "translationKey": "netherlands_region_name_fr",
            "shortCode": "FR"
        },
        {
            "name": "Gelderland",
            "translationKey": "netherlands_region_name_ge",
            "shortCode": "GE"
        },
        {
            "name": "Groningen",
            "translationKey": "netherlands_region_name_gr",
            "shortCode": "GR"
        },
        {
            "name": "Limburg",
            "translationKey": "netherlands_region_name_li",
            "shortCode": "LI"
        },
        {
            "name": "Noord-Brabant",
            "translationKey": "netherlands_region_name_nb",
            "shortCode": "NB"
        },
        {
            "name": "Noord-Holland",
            "translationKey": "netherlands_region_name_nh",
            "shortCode": "NH"
        },
        {
            "name": "Overijssel",
            "translationKey": "netherlands_region_name_ov",
            "shortCode": "OV"
        },
        {
            "name": "Utrecht",
            "translationKey": "netherlands_region_name_ut",
            "shortCode": "UT"
        },
        {
            "name": "Zeeland",
            "translationKey": "netherlands_region_name_ze",
            "shortCode": "ZE"
        },
        {
            "name": "Zuid-Holland",
            "translationKey": "netherlands_region_name_zh",
            "shortCode": "ZH"
        }
    ]
  },
  //   {
  //     "countryName": "New Caledonia",
  //     "countryShortCode": "NC",
  //     "regions": [
  //       {
  //         "name": "Iles Loyaute"
  //       },
  //       {
  //         "name": "Nord"
  //       },
  //       {
  //         "name": "Sud"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "New Zealand",
  //     "countryShortCode": "NZ",
  //     "regions": [
  //       {
  //         "name": "Auckland",
  //         "shortCode": "AUK"
  //       },
  //       {
  //         "name": "Bay of Plenty",
  //         "shortCode": "BOP"
  //       },
  //       {
  //         "name": "Canterbury",
  //         "shortCode": "CAN"
  //       },
  //       {
  //         "name": "Gisborne",
  //         "shortCode": "GIS"
  //       },
  //       {
  //         "name": "Hawke's Bay",
  //         "shortCode": "HKB"
  //       },
  //       {
  //         "name": "Marlborough",
  //         "shortCode": "MBH"
  //       },
  //       {
  //         "name": "Manawatu-Wanganui",
  //         "shortCode": "MWT"
  //       },
  //       {
  //         "name": "Northland",
  //         "shortCode": "NTL"
  //       },
  //       {
  //         "name": "Nelson",
  //         "shortCode": "NSN"
  //       },
  //       {
  //         "name": "Otago",
  //         "shortCode": "OTA"
  //       },
  //       {
  //         "name": "Southland",
  //         "shortCode": "STL"
  //       },
  //       {
  //         "name": "Taranaki",
  //         "shortCode": "TKI"
  //       },
  //       {
  //         "name": "Tasman",
  //         "shortCode": "TAS"
  //       },
  //       {
  //         "name": "Waikato",
  //         "shortCode": "WKO"
  //       },
  //       {
  //         "name": "Wellington",
  //         "shortCode": "WGN"
  //       },
  //       {
  //         "name": "West Coast",
  //         "shortCode": "WTC"
  //       },
  //       {
  //         "name": "Chatham Islands Territory",
  //         "shortCode": "CIT"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Nicaragua",
  //     "countryShortCode": "NI",
  //     "regions": [
  //       {
  //         "name": "Boaco",
  //         "shortCode": "BO"
  //       },
  //       {
  //         "name": "Carazo",
  //         "shortCode": "CA"
  //       },
  //       {
  //         "name": "Chinandega",
  //         "shortCode": "CI"
  //       },
  //       {
  //         "name": "Chontales",
  //         "shortCode": "CO"
  //       },
  //       {
  //         "name": "Estelí",
  //         "shortCode": "ES"
  //       },
  //       {
  //         "name": "Granada",
  //         "shortCode": "GR"
  //       },
  //       {
  //         "name": "Jinotega",
  //         "shortCode": "JI"
  //       },
  //       {
  //         "name": "León",
  //         "shortCode": "LE"
  //       },
  //       {
  //         "name": "Madriz",
  //         "shortCode": "MD"
  //       },
  //       {
  //         "name": "Managua",
  //         "shortCode": "MN"
  //       },
  //       {
  //         "name": "Masaya",
  //         "shortCode": "MS"
  //       },
  //       {
  //         "name": "Matagalpa",
  //         "shortCode": "MT"
  //       },
  //       {
  //         "name": "Nueva Segovia",
  //         "shortCode": "NS"
  //       },
  //       {
  //         "name": "Río San Juan",
  //         "shortCode": "SJ"
  //       },
  //       {
  //         "name": "Rivas",
  //         "shortCode": "RI"
  //       },
  //       {
  //         "name": "Atlántico Norte",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Atlántico Sur",
  //         "shortCode": "AS"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Niger",
  //     "countryShortCode": "NE",
  //     "regions": [
  //       {
  //         "name": "Agadez",
  //         "shortCode": "1"
  //       },
  //       {
  //         "name": "Diffa",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Dosso",
  //         "shortCode": "3"
  //       },
  //       {
  //         "name": "Maradi",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Niamey",
  //         "shortCode": "8"
  //       },
  //       {
  //         "name": "Tahoua",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Tillabéri",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "Zinder",
  //         "shortCode": "7"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Nigeria",
  //     "countryShortCode": "NG",
  //     "regions": [
  //       {
  //         "name": "Abia",
  //         "shortCode": "AB"
  //       },
  //       {
  //         "name": "Abuja Federal Capital Territory",
  //         "shortCode": "FC"
  //       },
  //       {
  //         "name": "Adamawa",
  //         "shortCode": "AD"
  //       },
  //       {
  //         "name": "Akwa Ibom",
  //         "shortCode": "AK"
  //       },
  //       {
  //         "name": "Anambra",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Bauchi",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Bayelsa",
  //         "shortCode": "BY"
  //       },
  //       {
  //         "name": "Benue",
  //         "shortCode": "BE"
  //       },
  //       {
  //         "name": "Borno",
  //         "shortCode": "BO"
  //       },
  //       {
  //         "name": "Cross River",
  //         "shortCode": "CR"
  //       },
  //       {
  //         "name": "Delta",
  //         "shortCode": "DE"
  //       },
  //       {
  //         "name": "Ebonyi",
  //         "shortCode": "EB"
  //       },
  //       {
  //         "name": "Edo",
  //         "shortCode": "ED"
  //       },
  //       {
  //         "name": "Ekiti",
  //         "shortCode": "EK"
  //       },
  //       {
  //         "name": "Enugu",
  //         "shortCode": "EN"
  //       },
  //       {
  //         "name": "Gombe",
  //         "shortCode": "GO"
  //       },
  //       {
  //         "name": "Imo",
  //         "shortCode": "IM"
  //       },
  //       {
  //         "name": "Jigawa",
  //         "shortCode": "JI"
  //       },
  //       {
  //         "name": "Kaduna",
  //         "shortCode": "KD"
  //       },
  //       {
  //         "name": "Kano",
  //         "shortCode": "KN"
  //       },
  //       {
  //         "name": "Katsina",
  //         "shortCode": "KT"
  //       },
  //       {
  //         "name": "Kebbi",
  //         "shortCode": "KE"
  //       },
  //       {
  //         "name": "Kogi",
  //         "shortCode": "KO"
  //       },
  //       {
  //         "name": "Kwara",
  //         "shortCode": "KW"
  //       },
  //       {
  //         "name": "Lagos",
  //         "shortCode": "LA"
  //       },
  //       {
  //         "name": "Nassarawa",
  //         "shortCode": "NA"
  //       },
  //       {
  //         "name": "Niger",
  //         "shortCode": "NI"
  //       },
  //       {
  //         "name": "Ogun",
  //         "shortCode": "OG"
  //       },
  //       {
  //         "name": "Ondo",
  //         "shortCode": "ON"
  //       },
  //       {
  //         "name": "Osun",
  //         "shortCode": "OS"
  //       },
  //       {
  //         "name": "Oyo",
  //         "shortCode": "OY"
  //       },
  //       {
  //         "name": "Plateau",
  //         "shortCode": "PL"
  //       },
  //       {
  //         "name": "Rivers",
  //         "shortCode": "RI"
  //       },
  //       {
  //         "name": "Sokoto",
  //         "shortCode": "SO"
  //       },
  //       {
  //         "name": "Taraba",
  //         "shortCode": "TA"
  //       },
  //       {
  //         "name": "Yobe",
  //         "shortCode": "YO"
  //       },
  //       {
  //         "name": "Zamfara",
  //         "shortCode": "ZA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Niue",
  //     "countryShortCode": "NU",
  //     "regions": [
  //       {
  //         "name": "Niue"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Norfolk Island",
  //     "countryShortCode": "NF",
  //     "regions": [
  //       {
  //         "name": "Norfolk Island"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Northern Mariana Islands",
  //     "countryShortCode": "MP",
  //     "regions": [
  //       {
  //         "name": "Northern Islands"
  //       },
  //       {
  //         "name": "Rota"
  //       },
  //       {
  //         "name": "Saipan"
  //       },
  //       {
  //         "name": "Tinian"
  //       }
  //     ]
  //   },
  {
    "countryName": "Norway",
    "countryShortCode": "NO",
    "regions": [
        {
            "name": "Akershus",
            "shortCode": "02",
            "translationKey": "Norway_region_02"
        },
        {
            "name": "Aust-Agder",
            "shortCode": "09",
            "translationKey": "Norway_region_09"
        },
        {
            "name": "Buskerud",
            "shortCode": "06",
            "translationKey": "Norway_region_06"
        },
        {
            "name": "Finnmark",
            "shortCode": "20",
            "translationKey": "Norway_region_20"
        },
        {
            "name": "Hedmark",
            "shortCode": "04",
            "translationKey": "Norway_region_04"
        },
        {
            "name": "Hordaland",
            "shortCode": "12",
            "translationKey": "Norway_region_12"
        },
        {
            "name": "Møre og Romsdal",
            "shortCode": "15",
            "translationKey": "Norway_region_15"
        },
        {
            "name": "Nordland",
            "shortCode": "18",
            "translationKey": "Norway_region_18"
        },
        {
            "name": "Nord-Trøndelag",
            "shortCode": "17",
            "translationKey": "Norway_region_17"
        },
        {
            "name": "Oppland",
            "shortCode": "05",
            "translationKey": "Norway_region_05"
        },
        {
            "name": "Oslo",
            "shortCode": "03",
            "translationKey": "Norway_region_03"
        },
        {
            "name": "Rogaland",
            "shortCode": "11",
            "translationKey": "Norway_region_11"
        },
        {
            "name": "Sogn og Fjordane",
            "shortCode": "14",
            "translationKey": "Norway_region_14"
        },
        {
            "name": "Sør-Trøndelag",
            "shortCode": "16",
            "translationKey": "Norway_region_16"
        },
        {
            "name": "Telemark",
            "shortCode": "08",
            "translationKey": "Norway_region_08"
        },
        {
            "name": "Troms",
            "shortCode": "19",
            "translationKey": "Norway_region_19"
        },
        {
            "name": "Vest-Agder",
            "shortCode": "10",
            "translationKey": "Norway_region_10"
        },
        {
            "name": "Vestfold",
            "shortCode": "07",
            "translationKey": "Norway_region_07"
        },
        {
            "name": "Østfold",
            "shortCode": "01",
            "translationKey": "Norway_region_01"
        },
        {
            "name": "Jan Mayen",
            "shortCode": "22",
            "translationKey": "Norway_region_22"
        },
        {
            "name": "Svalbard",
            "shortCode": "21",
            "translationKey": "Norway_region_21"
        }
    ],
    "translationKey": "country_name_Norway"
  },
  //   {
  //     "countryName": "Oman",
  //     "countryShortCode": "OM",
  //     "regions": [
  //       {
  //         "name": "Ad Dakhiliyah",
  //         "shortCode": "DA"
  //       },
  //       {
  //         "name": "Al Buraymi",
  //         "shortCode": "BU"
  //       },
  //       {
  //         "name": "Al Wusta",
  //         "shortCode": "WU"
  //       },
  //       {
  //         "name": "Az Zahirah",
  //         "shortCode": "ZA"
  //       },
  //       {
  //         "name": "Janub al Batinah",
  //         "shortCode": "BS"
  //       },
  //       {
  //         "name": "Janub ash Sharqiyah",
  //         "shortCode": "SS"
  //       },
  //       {
  //         "name": "Masqat",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Musandam",
  //         "shortCode": "MU"
  //       },
  //       {
  //         "name": "Shamal al Batinah",
  //         "shortCode": "BJ"
  //       },
  //       {
  //         "name": "Shamal ash Sharqiyah",
  //         "shortCode": "SJ"
  //       },
  //       {
  //         "name": "Zufar",
  //         "shortCode": "ZU"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Pakistan",
  //     "countryShortCode": "PK",
  //     "regions": [
  //       {
  //         "name": "Āzād Kashmīr",
  //         "shortCode": "JK"
  //       },
  //       {
  //         "name": "Balōchistān",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Gilgit-Baltistān",
  //         "shortCode": "GB"
  //       },
  //       {
  //         "name": "Islāmābād",
  //         "shortCode": "IS"
  //       },
  //       {
  //         "name": "Khaībar Pakhtūnkhwās",
  //         "shortCode": "KP"
  //       },
  //       {
  //         "name": "Punjāb",
  //         "shortCode": "PB"
  //       },
  //       {
  //         "name": "Sindh",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Federally Administered Tribal Areas",
  //         "shortCode": "TA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Palau",
  //     "countryShortCode": "PW",
  //     "regions": [
  //       {
  //         "name": "Aimeliik",
  //         "shortCode": "002"
  //       },
  //       {
  //         "name": "Airai",
  //         "shortCode": "004"
  //       },
  //       {
  //         "name": "Angaur",
  //         "shortCode": "010"
  //       },
  //       {
  //         "name": "Hatobohei",
  //         "shortCode": "050"
  //       },
  //       {
  //         "name": "Kayangel",
  //         "shortCode": "100"
  //       },
  //       {
  //         "name": "Koror",
  //         "shortCode": "150"
  //       },
  //       {
  //         "name": "Melekeok",
  //         "shortCode": "212"
  //       },
  //       {
  //         "name": "Ngaraard",
  //         "shortCode": "214"
  //       },
  //       {
  //         "name": "Ngarchelong",
  //         "shortCode": "218"
  //       },
  //       {
  //         "name": "Ngardmau",
  //         "shortCode": "222"
  //       },
  //       {
  //         "name": "Ngatpang",
  //         "shortCode": "224"
  //       },
  //       {
  //         "name": "Ngchesar",
  //         "shortCode": "226"
  //       },
  //       {
  //         "name": "Ngeremlengui",
  //         "shortCode": "227"
  //       },
  //       {
  //         "name": "Ngiwal",
  //         "shortCode": "228"
  //       },
  //       {
  //         "name": "Peleliu",
  //         "shortCode": "350"
  //       },
  //       {
  //         "name": "Sonsoral",
  //         "shortCode": "370"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Palestine, State of",
  //     "countryShortCode": "PS",
  //     "regions": [
  //       {
  //         "name": "Ak Khalīl",
  //         "shortCode": "HBN"
  //       },
  //       {
  //         "name": "Al Quds",
  //         "shortCode": "JEM"
  //       },
  //       {
  //         "name": "Arīḩā wal Aghwār",
  //         "shortCode": "JRH"
  //       },
  //       {
  //         "name": "Bayt Laḩm",
  //         "shortCode": "BTH"
  //       },
  //       {
  //         "name": "Dayr al Balaḩ",
  //         "shortCode": "DEB"
  //       },
  //       {
  //         "name": "Ghazzah",
  //         "shortCode": "GZA"
  //       },
  //       {
  //         "name": "Janīn",
  //         "shortCode": "JEN"
  //       },
  //       {
  //         "name": "Khān Yūnis",
  //         "shortCode": "KYS"
  //       },
  //       {
  //         "name": "Nāblus",
  //         "shortCode": "NBS"
  //       },
  //       {
  //         "name": "Qalqīyah",
  //         "shortCode": "QQA"
  //       },
  //       {
  //         "name": "Rafaḩ",
  //         "shortCode": "RFH"
  //       },
  //       {
  //         "name": "Rām Allāh wal Bīrah",
  //         "shortCode": "RBH"
  //       },
  //       {
  //         "name": "Salfīt",
  //         "shortCode": "SLT"
  //       },
  //       {
  //         "name": "Shamāl Ghazzah",
  //         "shortCode": "NGZ"
  //       },
  //       {
  //         "name": "Ţūbās",
  //         "shortCode": "TBS"
  //       },
  //       {
  //         "name": "Ţūlkarm",
  //         "shortCode": "TKM"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Panama",
  //     "countryShortCode": "PA",
  //     "regions": [
  //       {
  //         "name": "Bocas del Toro",
  //         "shortCode": "1"
  //       },
  //       {
  //         "name": "Chiriquí",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Coclé",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Colón",
  //         "shortCode": "3"
  //       },
  //       {
  //         "name": "Darién",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Emberá",
  //         "shortCode": "EM"
  //       },
  //       {
  //         "name": "Herrera",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "Kuna Yala",
  //         "shortCode": "KY"
  //       },
  //       {
  //         "name": "Los Santos",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Ngäbe-Buglé",
  //         "shortCode": "NB"
  //       },
  //       {
  //         "name": "Panamá",
  //         "shortCode": "8"
  //       },
  //       {
  //         "name": "Panamá Oeste",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Veraguas",
  //         "shortCode": "9"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Papua New Guinea",
  //     "countryShortCode": "PG",
  //     "regions": [
  //       {
  //         "name": "Bougainville",
  //         "shortCode": "NSB"
  //       },
  //       {
  //         "name": "Central",
  //         "shortCode": "CPM"
  //       },
  //       {
  //         "name": "Chimbu",
  //         "shortCode": "CPK"
  //       },
  //       {
  //         "name": "East New Britain",
  //         "shortCode": "EBR"
  //       },
  //       {
  //         "name": "East Sepik",
  //         "shortCode": "ESW"
  //       },
  //       {
  //         "name": "Eastern Highlands",
  //         "shortCode": "EHG"
  //       },
  //       {
  //         "name": "Enga",
  //         "shortCode": "EPW"
  //       },
  //       {
  //         "name": "Gulf",
  //         "shortCode": "GPK"
  //       },
  //       {
  //         "name": "Hela",
  //         "shortCode": "HLA"
  //       },
  //       {
  //         "name": "Jiwaka",
  //         "shortCode": "JWK"
  //       },
  //       {
  //         "name": "Madang",
  //         "shortCode": "MOM"
  //       },
  //       {
  //         "name": "Manus",
  //         "shortCode": "MRL"
  //       },
  //       {
  //         "name": "Milne Bay",
  //         "shortCode": "MBA"
  //       },
  //       {
  //         "name": "Morobe",
  //         "shortCode": "MPL"
  //       },
  //       {
  //         "name": "Port Moresby",
  //         "shortCode": "NCD"
  //       },
  //       {
  //         "name": "New Ireland",
  //         "shortCode": "NIK"
  //       },
  //       {
  //         "name": "Northern",
  //         "shortCode": "NPP"
  //       },
  //       {
  //         "name": "Southern Highlands",
  //         "shortCode": "SHM"
  //       },
  //       {
  //         "name": "West New Britain",
  //         "shortCode": "WBK"
  //       },
  //       {
  //         "name": "West Sepik",
  //         "shortCode": "SAN"
  //       },
  //       {
  //         "name": "Western",
  //         "shortCode": "WPD"
  //       },
  //       {
  //         "name": "Western Highlands",
  //         "shortCode": "WHM"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Paraguay",
  //     "countryShortCode": "PY",
  //     "regions": [
  //       {
  //         "name": "Alto Paraguay",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Alto Parana",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Amambay",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Asuncion",
  //         "shortCode": "ASU"
  //       },
  //       {
  //         "name": "Caaguazu",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Caazapa",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "Canindeyu",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Central",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Concepcion",
  //         "shortCode": "1"
  //       },
  //       {
  //         "name": "Cordillera",
  //         "shortCode": "3"
  //       },
  //       {
  //         "name": "Boqueron",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Guaira",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Itapua",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Misiones",
  //         "shortCode": "8"
  //       },
  //       {
  //         "name": "Neembucu",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Paraguari",
  //         "shortCode": "9"
  //       },
  //       {
  //         "name": "Presidente Hayes",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "San Pedro",
  //         "shortCode": "2"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Peru",
  //     "countryShortCode": "PE",
  //     "regions": [
  //       {
  //         "name": "Amazonas",
  //         "shortCode": "AMA"
  //       },
  //       {
  //         "name": "Ancash",
  //         "shortCode": "ANC"
  //       },
  //       {
  //         "name": "Apurimac",
  //         "shortCode": "APU"
  //       },
  //       {
  //         "name": "Arequipa",
  //         "shortCode": "ARE"
  //       },
  //       {
  //         "name": "Ayacucho",
  //         "shortCode": "AYA"
  //       },
  //       {
  //         "name": "Cajamarca",
  //         "shortCode": "CAJ"
  //       },
  //       {
  //         "name": "Callao",
  //         "shortCode": "CAL"
  //       },
  //       {
  //         "name": "Cusco",
  //         "shortCode": "CUS"
  //       },
  //       {
  //         "name": "Huancavelica",
  //         "shortCode": "HUV"
  //       },
  //       {
  //         "name": "Huanuco",
  //         "shortCode": "HUC"
  //       },
  //       {
  //         "name": "Ica",
  //         "shortCode": "ICA"
  //       },
  //       {
  //         "name": "Junin",
  //         "shortCode": "JUN"
  //       },
  //       {
  //         "name": "La Libertad",
  //         "shortCode": "LAL"
  //       },
  //       {
  //         "name": "Lambayeque",
  //         "shortCode": "LAM"
  //       },
  //       {
  //         "name": "Lima",
  //         "shortCode": "LIM"
  //       },
  //       {
  //         "name": "Loreto",
  //         "shortCode": "LOR"
  //       },
  //       {
  //         "name": "Madre de Dios",
  //         "shortCode": "MDD"
  //       },
  //       {
  //         "name": "Moquegua",
  //         "shortCode": "MOQ"
  //       },
  //       {
  //         "name": "Municipalidad Metropolitana de Lima",
  //         "shortCode": "LMA"
  //       },
  //       {
  //         "name": "Pasco",
  //         "shortCode": "PAS"
  //       },
  //       {
  //         "name": "Piura",
  //         "shortCode": "PIU"
  //       },
  //       {
  //         "name": "Puno",
  //         "shortCode": "PUN"
  //       },
  //       {
  //         "name": "San Martin",
  //         "shortCode": "SAM"
  //       },
  //       {
  //         "name": "Tacna",
  //         "shortCode": "TAC"
  //       },
  //       {
  //         "name": "Tumbes",
  //         "shortCode": "TUM"
  //       },
  //       {
  //         "name": "Ucayali",
  //         "shortCode": "UCA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Philippines",
  //     "countryShortCode": "PH",
  //     "regions": [
  //       {
  //         "name": "Abra",
  //         "shortCode": "ABR"
  //       },
  //       {
  //         "name": "Agusan del Norte",
  //         "shortCode": "AGN"
  //       },
  //       {
  //         "name": "Agusan del Sur",
  //         "shortCode": "AGS"
  //       },
  //       {
  //         "name": "Aklan",
  //         "shortCode": "AKL"
  //       },
  //       {
  //         "name": "Albay",
  //         "shortCode": "ALB"
  //       },
  //       {
  //         "name": "Antique",
  //         "shortCode": "ANT"
  //       },
  //       {
  //         "name": "Apayao",
  //         "shortCode": "APA"
  //       },
  //       {
  //         "name": "Aurora",
  //         "shortCode": "AUR"
  //       },
  //       {
  //         "name": "Basilan",
  //         "shortCode": "BAS"
  //       },
  //       {
  //         "name": "Bataan",
  //         "shortCode": "BAN"
  //       },
  //       {
  //         "name": "Batanes",
  //         "shortCode": "BTN"
  //       },
  //       {
  //         "name": "Batangas",
  //         "shortCode": "BTG"
  //       },
  //       {
  //         "name": "Benguet",
  //         "shortCode": "BEN"
  //       },
  //       {
  //         "name": "Biliran",
  //         "shortCode": "BIL"
  //       },
  //       {
  //         "name": "Bohol",
  //         "shortCode": "BOH"
  //       },
  //       {
  //         "name": "Bukidnon",
  //         "shortCode": "BUK"
  //       },
  //       {
  //         "name": "Bulacan",
  //         "shortCode": "BUL"
  //       },
  //       {
  //         "name": "Cagayan",
  //         "shortCode": "CAG"
  //       },
  //       {
  //         "name": "Camarines Norte",
  //         "shortCode": "CAN"
  //       },
  //       {
  //         "name": "Camarines Sur",
  //         "shortCode": "CAS"
  //       },
  //       {
  //         "name": "Camiguin",
  //         "shortCode": "CAM"
  //       },
  //       {
  //         "name": "Capiz",
  //         "shortCode": "CAP"
  //       },
  //       {
  //         "name": "Catanduanes",
  //         "shortCode": "CAT"
  //       },
  //       {
  //         "name": "Cavite",
  //         "shortCode": "CAV"
  //       },
  //       {
  //         "name": "Cebu",
  //         "shortCode": "CEB"
  //       },
  //       {
  //         "name": "Compostela",
  //         "shortCode": "COM"
  //       },
  //       {
  //         "name": "Cotabato",
  //         "shortCode": "NCO"
  //       },
  //       {
  //         "name": "Davao del Norte",
  //         "shortCode": "DAV"
  //       },
  //       {
  //         "name": "Davao del Sur",
  //         "shortCode": "DAS"
  //       },
  //       {
  //         "name": "Davao Occidental",
  //         "shortCode": "DVO"
  //       },
  //       {
  //         "name": "Davao Oriental",
  //         "shortCode": "DAO"
  //       },
  //       {
  //         "name": "Dinagat Islands",
  //         "shortCode": "DIN"
  //       },
  //       {
  //         "name": "Eastern Samar",
  //         "shortCode": "EAS"
  //       },
  //       {
  //         "name": "Guimaras",
  //         "shortCode": "GUI"
  //       },
  //       {
  //         "name": "Ifugao",
  //         "shortCode": "IFU"
  //       },
  //       {
  //         "name": "Ilocos Norte",
  //         "shortCode": "ILN"
  //       },
  //       {
  //         "name": "Ilocos Sur",
  //         "shortCode": "ILS"
  //       },
  //       {
  //         "name": "Iloilo",
  //         "shortCode": "ILI"
  //       },
  //       {
  //         "name": "Isabela",
  //         "shortCode": "ISA"
  //       },
  //       {
  //         "name": "Kalinga",
  //         "shortCode": "KAL"
  //       },
  //       {
  //         "name": "La Union",
  //         "shortCode": "LUN"
  //       },
  //       {
  //         "name": "Laguna",
  //         "shortCode": "LAG"
  //       },
  //       {
  //         "name": "Lanao del Norte",
  //         "shortCode": "LAN"
  //       },
  //       {
  //         "name": "Lanao del Sur",
  //         "shortCode": "LAS"
  //       },
  //       {
  //         "name": "Leyte",
  //         "shortCode": "LEY"
  //       },
  //       {
  //         "name": "Maguindanao",
  //         "shortCode": "MAG"
  //       },
  //       {
  //         "name": "Masbate",
  //         "shortCode": "MAS"
  //       },
  //       {
  //         "name": "Metro Manila",
  //         "shortCode": "00"
  //       },
  //       {
  //         "name": "Mindoro Occidental",
  //         "shortCode": "MDC"
  //       },
  //       {
  //         "name": "Mindoro Oriental",
  //         "shortCode": "MDR"
  //       },
  //       {
  //         "name": "Misamis Occidental",
  //         "shortCode": "MSC"
  //       },
  //       {
  //         "name": "Misamis Oriental",
  //         "shortCode": "MSR"
  //       },
  //       {
  //         "name": "Mountain Province",
  //         "shortCode": "MOU"
  //       },
  //       {
  //         "name": "Negros Occidental",
  //         "shortCode": "NEC"
  //       },
  //       {
  //         "name": "Negros Oriental",
  //         "shortCode": "NER"
  //       },
  //       {
  //         "name": "Northern Samar",
  //         "shortCode": "NSA"
  //       },
  //       {
  //         "name": "Nueva Ecija",
  //         "shortCode": "NUE"
  //       },
  //       {
  //         "name": "Nueva Vizcaya",
  //         "shortCode": "NUV"
  //       },
  //       {
  //         "name": "Palawan",
  //         "shortCode": "PLW"
  //       },
  //       {
  //         "name": "Pampanga",
  //         "shortCode": "PAM"
  //       },
  //       {
  //         "name": "Pangasinan",
  //         "shortCode": "PAN"
  //       },
  //       {
  //         "name": "Quezon",
  //         "shortCode": "QUE"
  //       },
  //       {
  //         "name": "Quirino",
  //         "shortCode": "QUI"
  //       },
  //       {
  //         "name": "Rizal",
  //         "shortCode": "RIZ"
  //       },
  //       {
  //         "name": "Romblon",
  //         "shortCode": "ROM"
  //       },
  //       {
  //         "name": "Samar",
  //         "shortCode": "WSA"
  //       },
  //       {
  //         "name": "Sarangani",
  //         "shortCode": "SAR"
  //       },
  //       {
  //         "name": "Siquijor",
  //         "shortCode": "SIG"
  //       },
  //       {
  //         "name": "Sorsogon",
  //         "shortCode": "SOR"
  //       },
  //       {
  //         "name": "Southern Leyte",
  //         "shortCode": "SLE"
  //       },
  //       {
  //         "name": "Sultan Kudarat",
  //         "shortCode": "AUK"
  //       },
  //       {
  //         "name": "Sulu",
  //         "shortCode": "SLU"
  //       },
  //       {
  //         "name": "Surigao del Norte",
  //         "shortCode": "SUN"
  //       },
  //       {
  //         "name": "Surigao del Sur",
  //         "shortCode": "SUR"
  //       },
  //       {
  //         "name": "Tarlac",
  //         "shortCode": "TAR"
  //       },
  //       {
  //         "name": "Tawi-Tawi",
  //         "shortCode": "TAW"
  //       },
  //       {
  //         "name": "Zambales",
  //         "shortCode": "ZMB"
  //       },
  //       {
  //         "name": "Zamboanga del Norte",
  //         "shortCode": "ZAN"
  //       },
  //       {
  //         "name": "Zamboanga del Sur",
  //         "shortCode": "ZAS"
  //       },
  //       {
  //         "name": "Zamboanga Sibugay",
  //         "shortCode": "ZSI"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Pitcairn",
  //     "countryShortCode": "PN",
  //     "regions": [
  //       {
  //         "name": "Pitcairn Islands"
  //       }
  //     ]
  //   },
  {
    "countryName": "Poland",
    "translationKey": "country_name_poland",
    "countryShortCode": "PL",
    "regions": [
        {
            "name": "Dolnośląskie",
            "translationKey": "poland_region_name_ds",
            "shortCode": "DS"
        },
        {
            "name": "Kujawsko-pomorskie",
            "translationKey": "poland_region_name_kp",
            "shortCode": "KP"
        },
        {
            "name": "Łódzkie",
            "translationKey": "poland_region_name_ld",
            "shortCode": "LD"
        },
        {
            "name": "Lubelskie",
            "translationKey": "poland_region_name_lu",
            "shortCode": "LU"
        },
        {
            "name": "Lubuskie",
            "translationKey": "poland_region_name_lb",
            "shortCode": "LB"
        },
        {
            "name": "Małopolskie",
            "translationKey": "poland_region_name_ma",
            "shortCode": "MA"
        },
        {
            "name": "Mazowieckie",
            "translationKey": "poland_region_name_mz",
            "shortCode": "MZ"
        },
        {
            "name": "Opolskie",
            "translationKey": "poland_region_name_op",
            "shortCode": "OP"
        },
        {
            "name": "Podkarpackie",
            "translationKey": "poland_region_name_pk",
            "shortCode": "PK"
        },
        {
            "name": "Podlaskie",
            "translationKey": "poland_region_name_pd",
            "shortCode": "PD"
        },
        {
            "name": "Pomorskie",
            "translationKey": "poland_region_name_pm",
            "shortCode": "PM"
        },
        {
            "name": "Śląskie",
            "translationKey": "poland_region_name_sl",
            "shortCode": "SL"
        },
        {
            "name": "Świętokrzyskie",
            "translationKey": "poland_region_name_sk",
            "shortCode": "SK"
        },
        {
            "name": "Warmińsko-mazurskie",
            "translationKey": "poland_region_name_wn",
            "shortCode": "WN"
        },
        {
            "name": "Wielkopolskie",
            "translationKey": "poland_region_name_wp",
            "shortCode": "WP"
        },
        {
            "name": "Zachodniopomorskie",
            "translationKey": "poland_region_name_zp",
            "shortCode": "ZP"
        }
    ]
},
{
    "countryName": "Portugal",
    "translationKey": "country_name_portugal",
    "countryShortCode": "PT",
    "regions": [
        {
            "name": "Açores",
            "translationKey": "portugal_region_name_20",
            "shortCode": "20"
        },
        {
            "name": "Aveiro",
            "translationKey": "portugal_region_name_01",
            "shortCode": "01"
        },
        {
            "name": "Beja",
            "translationKey": "portugal_region_name_02",
            "shortCode": "02"
        },
        {
            "name": "Braga",
            "translationKey": "portugal_region_name_03",
            "shortCode": "03"
        },
        {
            "name": "Bragança",
            "translationKey": "portugal_region_name_04",
            "shortCode": "04"
        },
        {
            "name": "Castelo Branco",
            "translationKey": "portugal_region_name_05",
            "shortCode": "05"
        },
        {
            "name": "Coimbra",
            "translationKey": "portugal_region_name_06",
            "shortCode": "06"
        },
        {
            "name": "Évora",
            "translationKey": "portugal_region_name_07",
            "shortCode": "07"
        },
        {
            "name": "Faro",
            "translationKey": "portugal_region_name_08",
            "shortCode": "08"
        },
        {
            "name": "Guarda",
            "translationKey": "portugal_region_name_09",
            "shortCode": "09"
        },
        {
            "name": "Leiria",
            "translationKey": "portugal_region_name_10",
            "shortCode": "10"
        },
        {
            "name": "Lisboa",
            "translationKey": "portugal_region_name_11",
            "shortCode": "11"
        },
        {
            "name": "Madeira",
            "translationKey": "portugal_region_name_30",
            "shortCode": "30"
        },
        {
            "name": "Portalegre",
            "translationKey": "portugal_region_name_12",
            "shortCode": "12"
        },
        {
            "name": "Porto",
            "translationKey": "portugal_region_name_13",
            "shortCode": "13"
        },
        {
            "name": "Santarém",
            "translationKey": "portugal_region_name_14",
            "shortCode": "14"
        },
        {
            "name": "Setúbal",
            "translationKey": "portugal_region_name_15",
            "shortCode": "15"
        },
        {
            "name": "Viana do Castelo",
            "translationKey": "portugal_region_name_16",
            "shortCode": "16"
        },
        {
            "name": "Vila Real",
            "translationKey": "portugal_region_name_17",
            "shortCode": "17"
        },
        {
            "name": "Viseu",
            "translationKey": "portugal_region_name_18",
            "shortCode": "18"
        }
    ]
  },
  //   {
  //     "countryName": "Puerto Rico",
  //     "countryShortCode": "PR",
  //     "regions": [
  //       {
  //         "name": "Adjuntas"
  //       },
  //       {
  //         "name": "Aguada"
  //       },
  //       {
  //         "name": "Aguadilla"
  //       },
  //       {
  //         "name": "Aguas Buenas"
  //       },
  //       {
  //         "name": "Aibonito"
  //       },
  //       {
  //         "name": "Anasco"
  //       },
  //       {
  //         "name": "Arecibo"
  //       },
  //       {
  //         "name": "Arroyo"
  //       },
  //       {
  //         "name": "Barceloneta"
  //       },
  //       {
  //         "name": "Barranquitas"
  //       },
  //       {
  //         "name": "Bayamon"
  //       },
  //       {
  //         "name": "Cabo Rojo"
  //       },
  //       {
  //         "name": "Caguas"
  //       },
  //       {
  //         "name": "Camuy"
  //       },
  //       {
  //         "name": "Canovanas"
  //       },
  //       {
  //         "name": "Carolina"
  //       },
  //       {
  //         "name": "Cat"
  //       },
  //       {
  //         "name": "Ceiba"
  //       },
  //       {
  //         "name": "Ciales"
  //       },
  //       {
  //         "name": "Cidra"
  //       },
  //       {
  //         "name": "Coamo"
  //       },
  //       {
  //         "name": "Comerio"
  //       },
  //       {
  //         "name": "Corozal"
  //       },
  //       {
  //         "name": "Culebra"
  //       },
  //       {
  //         "name": "Dorado"
  //       },
  //       {
  //         "name": "Fajardo"
  //       },
  //       {
  //         "name": "Florida"
  //       },
  //       {
  //         "name": "Guanica"
  //       },
  //       {
  //         "name": "Guayama"
  //       },
  //       {
  //         "name": "Guayanilla"
  //       },
  //       {
  //         "name": "Guaynabo"
  //       },
  //       {
  //         "name": "Gurabo"
  //       },
  //       {
  //         "name": "Hatillo"
  //       },
  //       {
  //         "name": "Hormigueros"
  //       },
  //       {
  //         "name": "Humacao"
  //       },
  //       {
  //         "name": "Isabe"
  //       },
  //       {
  //         "name": "Juana Diaz"
  //       },
  //       {
  //         "name": "Juncos"
  //       },
  //       {
  //         "name": "Lajas"
  //       },
  //       {
  //         "name": "Lares"
  //       },
  //       {
  //         "name": "Las Marias"
  //       },
  //       {
  //         "name": "Las oiza"
  //       },
  //       {
  //         "name": "Luquillo"
  //       },
  //       {
  //         "name": "Manati"
  //       },
  //       {
  //         "name": "Maricao"
  //       },
  //       {
  //         "name": "Maunabo"
  //       },
  //       {
  //         "name": "Mayaguez"
  //       },
  //       {
  //         "name": "Moca"
  //       },
  //       {
  //         "name": "Morovis"
  //       },
  //       {
  //         "name": "Naguabo"
  //       },
  //       {
  //         "name": "Naranjito"
  //       },
  //       {
  //         "name": "Orocovis"
  //       },
  //       {
  //         "name": "Patillas"
  //       },
  //       {
  //         "name": "Penuelas"
  //       },
  //       {
  //         "name": "Ponce"
  //       },
  //       {
  //         "name": "Quebradillas"
  //       },
  //       {
  //         "name": "Rincon"
  //       },
  //       {
  //         "name": "Rio Grande"
  //       },
  //       {
  //         "name": "Sabana linas"
  //       },
  //       {
  //         "name": "San German"
  //       },
  //       {
  //         "name": "San Juan"
  //       },
  //       {
  //         "name": "San Lorenzo"
  //       },
  //       {
  //         "name": "San Sebastian"
  //       },
  //       {
  //         "name": "Santa Isabel"
  //       },
  //       {
  //         "name": "Toa Alta"
  //       },
  //       {
  //         "name": "Toa Baja"
  //       },
  //       {
  //         "name": "Trujillo Alto"
  //       },
  //       {
  //         "name": "Utuado"
  //       },
  //       {
  //         "name": "Vega Alta"
  //       },
  //       {
  //         "name": "Vega ues"
  //       },
  //       {
  //         "name": "Villalba"
  //       },
  //       {
  //         "name": "Yabucoa"
  //       },
  //       {
  //         "name": "Yauco"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Qatar",
  //     "countryShortCode": "QA",
  //     "regions": [
  //       {
  //         "name": "Ad Dawḩah",
  //         "shortCode": "DA"
  //       },
  //       {
  //         "name": "Al Khawr wa adh Dhakhīrah",
  //         "shortCode": "KH"
  //       },
  //       {
  //         "name": "Al Wakrah",
  //         "shortCode": "WA"
  //       },
  //       {
  //         "name": "Ar Rayyān",
  //         "shortCode": "RA"
  //       },
  //       {
  //         "name": "Ash Shamāl",
  //         "shortCode": "MS"
  //       },
  //       {
  //         "name": "Az̧ Za̧`āyin",
  //         "shortCode": "ZA"
  //       },
  //       {
  //         "name": "Umm Şalāl",
  //         "shortCode": "US"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Réunion",
  //     "countryShortCode": "RE",
  //     "regions": [
  //       {
  //         "name": "Réunion"
  //       }
  //     ]
  //   },
  {
    "countryName": "Romania",
    "translationKey": "country_name_romania",
    "countryShortCode": "RO",
    "regions": [
        {
            "name": "Alba",
            "translationKey": "romania_region_name_ab",
            "shortCode": "AB"
        },
        {
            "name": "Arad",
            "translationKey": "romania_region_name_ar",
            "shortCode": "AR"
        },
        {
            "name": "Arges",
            "translationKey": "romania_region_name_ag",
            "shortCode": "AG"
        },
        {
            "name": "Bacau",
            "translationKey": "romania_region_name_bc",
            "shortCode": "BC"
        },
        {
            "name": "Bihor",
            "translationKey": "romania_region_name_bh",
            "shortCode": "BH"
        },
        {
            "name": "Bistrita-Nasaud",
            "translationKey": "romania_region_name_bn",
            "shortCode": "BN"
        },
        {
            "name": "Botosani",
            "translationKey": "romania_region_name_bt",
            "shortCode": "BT"
        },
        {
            "name": "Braila",
            "translationKey": "romania_region_name_br",
            "shortCode": "BR"
        },
        {
            "name": "Brasov",
            "translationKey": "romania_region_name_bv",
            "shortCode": "BV"
        },
        {
            "name": "Bucuresti",
            "translationKey": "romania_region_name_b",
            "shortCode": "B"
        },
        {
            "name": "Buzau",
            "translationKey": "romania_region_name_bz",
            "shortCode": "BZ"
        },
        {
            "name": "Calarasi",
            "translationKey": "romania_region_name_cl",
            "shortCode": "CL"
        },
        {
            "name": "Caras-Severin",
            "translationKey": "romania_region_name_cs",
            "shortCode": "CS"
        },
        {
            "name": "Cluj",
            "translationKey": "romania_region_name_cj",
            "shortCode": "CJ"
        },
        {
            "name": "Constanta",
            "translationKey": "romania_region_name_ct",
            "shortCode": "CT"
        },
        {
            "name": "Covasna",
            "translationKey": "romania_region_name_cv",
            "shortCode": "CV"
        },
        {
            "name": "Dambovita",
            "translationKey": "romania_region_name_db",
            "shortCode": "DB"
        },
        {
            "name": "Dolj",
            "translationKey": "romania_region_name_dj",
            "shortCode": "DJ"
        },
        {
            "name": "Galati",
            "translationKey": "romania_region_name_gl",
            "shortCode": "GL"
        },
        {
            "name": "Giurgiu",
            "translationKey": "romania_region_name_gr",
            "shortCode": "GR"
        },
        {
            "name": "Gorj",
            "translationKey": "romania_region_name_gj",
            "shortCode": "GJ"
        },
        {
            "name": "Harghita",
            "translationKey": "romania_region_name_hr",
            "shortCode": "HR"
        },
        {
            "name": "Hunedoara",
            "translationKey": "romania_region_name_hd",
            "shortCode": "HD"
        },
        {
            "name": "Ialomita",
            "translationKey": "romania_region_name_il",
            "shortCode": "IL"
        },
        {
            "name": "Iasi",
            "translationKey": "romania_region_name_is",
            "shortCode": "IS"
        },
        {
            "name": "Maramures",
            "translationKey": "romania_region_name_mm",
            "shortCode": "MM"
        },
        {
            "name": "Mehedinti",
            "translationKey": "romania_region_name_mh",
            "shortCode": "MH"
        },
        {
            "name": "Mures",
            "translationKey": "romania_region_name_ms",
            "shortCode": "MS"
        },
        {
            "name": "Neamt",
            "translationKey": "romania_region_name_nt",
            "shortCode": "NT"
        },
        {
            "name": "Olt",
            "translationKey": "romania_region_name_ot",
            "shortCode": "OT"
        },
        {
            "name": "Prahova",
            "translationKey": "romania_region_name_ph",
            "shortCode": "PH"
        },
        {
            "name": "Salaj",
            "translationKey": "romania_region_name_sj",
            "shortCode": "SJ"
        },
        {
            "name": "Satu Mare",
            "translationKey": "romania_region_name_sm",
            "shortCode": "SM"
        },
        {
            "name": "Sibiu",
            "translationKey": "romania_region_name_sb",
            "shortCode": "SB"
        },
        {
            "name": "Suceava",
            "translationKey": "romania_region_name_sv",
            "shortCode": "SV"
        },
        {
            "name": "Teleorman",
            "translationKey": "romania_region_name_tr",
            "shortCode": "TR"
        },
        {
            "name": "Timis",
            "translationKey": "romania_region_name_tm",
            "shortCode": "TM"
        },
        {
            "name": "Tulcea",
            "translationKey": "romania_region_name_tl",
            "shortCode": "TL"
        },
        {
            "name": "Valcea",
            "translationKey": "romania_region_name_vl",
            "shortCode": "VL"
        },
        {
            "name": "Vaslui",
            "translationKey": "romania_region_name_vs",
            "shortCode": "VS"
        },
        {
            "name": "Vrancea",
            "translationKey": "romania_region_name_vn",
            "shortCode": "VN"
        }
    ]
  },
  //   {
  //     "countryName": "Russian Federation",
  //     "countryShortCode": "RU",
  //     "regions": [
  //       {
  //         "name": "Republic of Adygea",
  //         "shortCode": "AD"
  //       },
  //       {
  //         "name": "Republic of Altai (Gorno-Altaysk)",
  //         "shortCode": "AL"
  //       },
  //       {
  //         "name": "Altai Krai",
  //         "shortCode": "ALT"
  //       },
  //       {
  //         "name": "Amur Oblast",
  //         "shortCode": "AMU"
  //       },
  //       {
  //         "name": "Arkhangelsk Oblast",
  //         "shortCode": "ARK"
  //       },
  //       {
  //         "name": "Astrakhan Oblast",
  //         "shortCode": "AST"
  //       },
  //       {
  //         "name": "Republic of Bashkortostan",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Belgorod Oblast",
  //         "shortCode": "BEL"
  //       },
  //       {
  //         "name": "Bryansk Oblast",
  //         "shortCode": "BRY"
  //       },
  //       {
  //         "name": "Republic of Buryatia",
  //         "shortCode": "BU"
  //       },
  //       {
  //         "name": "Chechen Republic",
  //         "shortCode": "CE"
  //       },
  //       {
  //         "name": "Chelyabinsk Oblast",
  //         "shortCode": "CHE"
  //       },
  //       {
  //         "name": "Chukotka Autonomous Okrug",
  //         "shortCode": "CHU"
  //       },
  //       {
  //         "name": "Chuvash Republic",
  //         "shortCode": "CU"
  //       },
  //       {
  //         "name": "Republic of Dagestan",
  //         "shortCode": "DA"
  //       },
  //       {
  //         "name": "Republic of Ingushetia",
  //         "shortCode": "IN"
  //       },
  //       {
  //         "name": "Irkutsk Oblast",
  //         "shortCode": "IRK"
  //       },
  //       {
  //         "name": "Ivanovo Oblast",
  //         "shortCode": "IVA"
  //       },
  //       {
  //         "name": "Jewish Autonomous Oblast",
  //         "shortCode": "JEW"
  //       },
  //       {
  //         "name": "Kabardino-Balkar Republic",
  //         "shortCode": "KB"
  //       },
  //       {
  //         "name": "Kaliningrad Oblast",
  //         "shortCode": "KLN"
  //       },
  //       {
  //         "name": "Republic of Kalmykia",
  //         "shortCode": "KL"
  //       },
  //       {
  //         "name": "Kaluga Oblast",
  //         "shortCode": "KLU"
  //       },
  //       {
  //         "name": "Kamchatka Krai",
  //         "shortCode": "KAM"
  //       },
  //       {
  //         "name": "Karachay-Cherkess Republic",
  //         "shortCode": "KC"
  //       },
  //       {
  //         "name": "Republic of Karelia",
  //         "shortCode": "KR"
  //       },
  //       {
  //         "name": "Khabarovsk Krai",
  //         "shortCode": "KHA"
  //       },
  //       {
  //         "name": "Republic of Khakassia",
  //         "shortCode": "KK"
  //       },
  //       {
  //         "name": "Khanty-Mansi Autonomous Okrug - Yugra",
  //         "shortCode": "KHM"
  //       },
  //       {
  //         "name": "Kemerovo Oblast",
  //         "shortCode": "KEM"
  //       },
  //       {
  //         "name": "Kirov Oblast",
  //         "shortCode": "KIR"
  //       },
  //       {
  //         "name": "Komi Republic",
  //         "shortCode": "KO"
  //       },
  //       {
  //         "name": "Kostroma Oblast",
  //         "shortCode": "KOS"
  //       },
  //       {
  //         "name": "Krasnodar Krai",
  //         "shortCode": "KDA"
  //       },
  //       {
  //         "name": "Krasnoyarsk Krai",
  //         "shortCode": "KYA"
  //       },
  //       {
  //         "name": "Kurgan Oblast",
  //         "shortCode": "KGN"
  //       },
  //       {
  //         "name": "Kursk Oblast",
  //         "shortCode": "KRS"
  //       },
  //       {
  //         "name": "Leningrad Oblast",
  //         "shortCode": "LEN"
  //       },
  //       {
  //         "name": "Lipetsk Oblast",
  //         "shortCode": "LIP"
  //       },
  //       {
  //         "name": "Magadan Oblast",
  //         "shortCode": "MAG"
  //       },
  //       {
  //         "name": "Mari El Republic",
  //         "shortCode": "ME"
  //       },
  //       {
  //         "name": "Republic of Mordovia",
  //         "shortCode": "MO"
  //       },
  //       {
  //         "name": "Moscow Oblast",
  //         "shortCode": "MOS"
  //       },
  //       {
  //         "name": "Moscow",
  //         "shortCode": "MOW"
  //       },
  //       {
  //         "name": "Murmansk Oblast",
  //         "shortCode": "MU"
  //       },
  //       {
  //         "name": "Nenets Autonomous Okrug",
  //         "shortCode": "NEN"
  //       },
  //       {
  //         "name": "Nizhny Novgorod Oblast",
  //         "shortCode": "NIZ"
  //       },
  //       {
  //         "name": "Novgorod Oblast",
  //         "shortCode": "NGR"
  //       },
  //       {
  //         "name": "Novosibirsk Oblast",
  //         "shortCode": "NVS"
  //       },
  //       {
  //         "name": "Omsk Oblast",
  //         "shortCode": "OMS"
  //       },
  //       {
  //         "name": "Orenburg Oblast",
  //         "shortCode": "ORE"
  //       },
  //       {
  //         "name": "Oryol Oblast",
  //         "shortCode": "ORL"
  //       },
  //       {
  //         "name": "Penza Oblast",
  //         "shortCode": "PNZ"
  //       },
  //       {
  //         "name": "Perm Krai",
  //         "shortCode": "PER"
  //       },
  //       {
  //         "name": "Primorsky Krai",
  //         "shortCode": "PRI"
  //       },
  //       {
  //         "name": "Pskov Oblast",
  //         "shortCode": "PSK"
  //       },
  //       {
  //         "name": "Rostov Oblast",
  //         "shortCode": "ROS"
  //       },
  //       {
  //         "name": "Ryazan Oblast",
  //         "shortCode": "RYA"
  //       },
  //       {
  //         "name": "Saint Petersburg",
  //         "shortCode": "SPE"
  //       },
  //       {
  //         "name": "Sakha (Yakutia) Republic",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Sakhalin Oblast",
  //         "shortCode": "SAK"
  //       },
  //       {
  //         "name": "Samara Oblast",
  //         "shortCode": "SAM"
  //       },
  //       {
  //         "name": "Saratov Oblast",
  //         "shortCode": "SAR"
  //       },
  //       {
  //         "name": "Republic of North Ossetia-Alania",
  //         "shortCode": "NOA"
  //       },
  //       {
  //         "name": "Smolensk Oblast",
  //         "shortCode": "SMO"
  //       },
  //       {
  //         "name": "Stavropol Krai",
  //         "shortCode": "STA"
  //       },
  //       {
  //         "name": "Sverdlovsk Oblast",
  //         "shortCode": "SVE"
  //       },
  //       {
  //         "name": "Tambov Oblast",
  //         "shortCode": "TAM"
  //       },
  //       {
  //         "name": "Republic of Tatarstan",
  //         "shortCode": "TA"
  //       },
  //       {
  //         "name": "Tomsk Oblast",
  //         "shortCode": "TOM"
  //       },
  //       {
  //         "name": "Tuva Republic",
  //         "shortCode": "TU"
  //       },
  //       {
  //         "name": "Tula Oblast",
  //         "shortCode": "TUL"
  //       },
  //       {
  //         "name": "Tver Oblast",
  //         "shortCode": "TVE"
  //       },
  //       {
  //         "name": "Tyumen Oblast",
  //         "shortCode": "TYU"
  //       },
  //       {
  //         "name": "Udmurt Republic",
  //         "shortCode": "UD"
  //       },
  //       {
  //         "name": "Ulyanovsk Oblast",
  //         "shortCode": "ULY"
  //       },
  //       {
  //         "name": "Vladimir Oblast",
  //         "shortCode": "VLA"
  //       },
  //       {
  //         "name": "Volgograd Oblast",
  //         "shortCode": "VGG"
  //       },
  //       {
  //         "name": "Vologda Oblast",
  //         "shortCode": "VLG"
  //       },
  //       {
  //         "name": "Voronezh Oblast",
  //         "shortCode": "VOR"
  //       },
  //       {
  //         "name": "Yamalo-Nenets Autonomous Okrug",
  //         "shortCode": "YAN"
  //       },
  //       {
  //         "name": "Yaroslavl Oblast",
  //         "shortCode": "YAR"
  //       },
  //       {
  //         "name": "Zabaykalsky Krai",
  //         "shortCode": "ZAB"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Rwanda",
  //     "countryShortCode": "RW",
  //     "regions": [
  //       {
  //         "name": "Kigali",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Eastern",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Northern",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Western",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Southern",
  //         "shortCode": "05"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Saint Barthélemy",
  //     "countryShortCode": "BL",
  //     "regions": [
  //       {
  //         "name": "Au Vent",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Sous le Vent",
  //         "shortCode": "01"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Saint Helena, Ascension and Tristan da Cunha",
  //     "countryShortCode": "SH",
  //     "regions": [
  //       {
  //         "name": "Ascension",
  //         "shortCode": "AC"
  //       },
  //       {
  //         "name": "Saint Helena",
  //         "shortCode": "HL"
  //       },
  //       {
  //         "name": "Tristan da Cunha",
  //         "shortCode": "TA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Saint Kitts and Nevis",
  //     "countryShortCode": "KN",
  //     "regions": [
  //       {
  //         "name": "Saint Kitts",
  //         "shortCode": "K"
  //       },
  //       {
  //         "name": "Nevis",
  //         "shortCode": "N"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Saint Lucia",
  //     "countryShortCode": "LC",
  //     "regions": [
  //       {
  //         "name": "Anse-la-Raye",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Canaries",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Castries",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Choiseul",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Dennery",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Gros Islet",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Laborie",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Micoud",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Soufriere",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Vieux Fort",
  //         "shortCode": "11"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Saint Martin",
  //     "countryShortCode": "MF",
  //     "regions": [
  //       {
  //         "name": "Saint Martin"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Saint Pierre and Miquelon",
  //     "countryShortCode": "PM",
  //     "regions": [
  //       {
  //         "name": "Miquelon"
  //       },
  //       {
  //         "name": "Saint Pierre"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Saint Vincent and the Grenadines",
  //     "countryShortCode": "VC",
  //     "regions": [
  //       {
  //         "name": "Charlotte",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Grenadines",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Saint Andrew",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Saint David",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Saint George",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Saint Patrick",
  //         "shortCode": "05"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Samoa",
  //     "countryShortCode": "WS",
  //     "regions": [
  //       {
  //         "name": "A'ana",
  //         "shortCode": "AA"
  //       },
  //       {
  //         "name": "Aiga-i-le-Tai",
  //         "shortCode": "AL"
  //       },
  //       {
  //         "name": "Atua",
  //         "shortCode": "AT"
  //       },
  //       {
  //         "name": "Fa'asaleleaga",
  //         "shortCode": "FA"
  //       },
  //       {
  //         "name": "Gaga'emauga",
  //         "shortCode": "GE"
  //       },
  //       {
  //         "name": "Gagaifomauga",
  //         "shortCode": "GI"
  //       },
  //       {
  //         "name": "Palauli",
  //         "shortCode": "PA"
  //       },
  //       {
  //         "name": "Satupa'itea",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Tuamasaga",
  //         "shortCode": "TU"
  //       },
  //       {
  //         "name": "Va'a-o-Fonoti",
  //         "shortCode": "VF"
  //       },
  //       {
  //         "name": "Vaisigano",
  //         "shortCode": "VS"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "San Marino",
  //     "countryShortCode": "SM",
  //     "regions": [
  //       {
  //         "name": "Acquaviva",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Borgo Maggiore",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Chiesanuova",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Domagnano",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Faetano",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Fiorentino",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Montegiardino",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "San Marino",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Serravalle",
  //         "shortCode": "09"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Sao Tome and Principe",
  //     "countryShortCode": "ST",
  //     "regions": [
  //       {
  //         "name": "Principe",
  //         "shortCode": "P"
  //       },
  //       {
  //         "name": "Sao Tome",
  //         "shortCode": "S"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Saudi Arabia",
  //     "countryShortCode": "SA",
  //     "regions": [
  //       {
  //         "name": "'Asir",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Al Bahah",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Al Hudud ash Shamaliyah",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Al Jawf",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Al Madinah al Munawwarah",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Al Qasim",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Ar Riyad",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Ash Sharqiyah",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Ha'il",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Jazan",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Makkah al Mukarramah",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Najran",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Tabuk",
  //         "shortCode": "07"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Senegal",
  //     "countryShortCode": "SN",
  //     "regions": [
  //       {
  //         "name": "Dakar",
  //         "shortCode": "DK"
  //       },
  //       {
  //         "name": "Diourbel",
  //         "shortCode": "DB"
  //       },
  //       {
  //         "name": "Fatick",
  //         "shortCode": "FK"
  //       },
  //       {
  //         "name": "Kaffrine",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Kaolack",
  //         "shortCode": "KL"
  //       },
  //       {
  //         "name": "Kedougou",
  //         "shortCode": "KE"
  //       },
  //       {
  //         "name": "Kolda",
  //         "shortCode": "KD"
  //       },
  //       {
  //         "name": "Louga",
  //         "shortCode": "LG"
  //       },
  //       {
  //         "name": "Matam",
  //         "shortCode": "MT"
  //       },
  //       {
  //         "name": "Saint-Louis",
  //         "shortCode": "SL"
  //       },
  //       {
  //         "name": "Sedhiou",
  //         "shortCode": "SE"
  //       },
  //       {
  //         "name": "Tambacounda",
  //         "shortCode": "TC"
  //       },
  //       {
  //         "name": "Thies",
  //         "shortCode": "TH"
  //       },
  //       {
  //         "name": "Ziguinchor",
  //         "shortCode": "ZG"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Serbia",
  //     "countryShortCode": "RS",
  //     "regions": [
  //       {
  //         "name": "Beograd (Belgrade)",
  //         "shortCode": "00"
  //       },
  //       {
  //         "name": "Borski",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Braničevski",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Jablanički",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Južnobački",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Južnobanatski",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Kolubarski",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Kosovski",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Kosovsko-Mitrovački",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Kosovsko-Pomoravski",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Mačvanski",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Moravički",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Nišavski",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Pčinjski",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Pećki",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Pirotski",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Podunavski",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Pomoravski",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Prizrenski",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Rasinski",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Raški",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Severnobački",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Severnobanatski",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Srednjebanatski",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Sremski",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Šumadijski",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Toplički",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Zaječarski",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Zapadnobački",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Zlatiborski",
  //         "shortCode": "16"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Seychelles",
  //     "countryShortCode": "SC",
  //     "regions": [
  //       {
  //         "name": "Anse aux Pins",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Anse Boileau",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Anse Etoile",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Anse Royale",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Anu Cap",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Baie Lazare",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Baie Sainte Anne",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Beau Vallon",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Bel Air",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Bel Ombre",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Cascade",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Glacis",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Grand'Anse Mahe",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Grand'Anse Praslin",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "La Digue",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "La Riviere Anglaise",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Les Mamelles",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Mont Buxton",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Mont Fleuri",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Plaisance",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Pointe La Rue",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Port Glaud",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Roche Caiman",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Saint Louis",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Takamaka",
  //         "shortCode": "23"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Sierra Leone",
  //     "countryShortCode": "SL",
  //     "regions": [
  //       {
  //         "name": "Eastern",
  //         "shortCode": "E"
  //       },
  //       {
  //         "name": "Northern",
  //         "shortCode": "N"
  //       },
  //       {
  //         "name": "Southern",
  //         "shortCode": "S"
  //       },
  //       {
  //         "name": "Western",
  //         "shortCode": "W"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Singapore",
  //     "countryShortCode": "SG",
  //     "regions": [
  //       {
  //         "name": "Central Singapore",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "North East",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "North West",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "South East",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "South West",
  //         "shortCode": "05"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Sint Maarten (Dutch part)",
  //     "countryShortCode": "SX",
  //     "regions": [
  //       {
  //         "name": "Sint Maarten"
  //       }
  //     ]
  //   },
  {
    "countryName": "Slovakia",
    "translationKey": "country_name_slovakia",
    "countryShortCode": "SK",
    "regions": [
        {
            "name": "Banskobystricky",
            "translationKey": "slovakia_region_name_bc",
            "shortCode": "BC"
        },
        {
            "name": "Bratislavsky",
            "translationKey": "slovakia_region_name_bl",
            "shortCode": "BL"
        },
        {
            "name": "Kosicky",
            "translationKey": "slovakia_region_name_ki",
            "shortCode": "KI"
        },
        {
            "name": "Nitriansky",
            "translationKey": "slovakia_region_name_ni",
            "shortCode": "NI"
        },
        {
            "name": "Presovsky",
            "translationKey": "slovakia_region_name_pv",
            "shortCode": "PV"
        },
        {
            "name": "Trenciansky",
            "translationKey": "slovakia_region_name_tc",
            "shortCode": "TC"
        },
        {
            "name": "Trnavsky",
            "translationKey": "slovakia_region_name_ta",
            "shortCode": "TA"
        },
        {
            "name": "Zilinsky",
            "translationKey": "slovakia_region_name_zi",
            "shortCode": "ZI"
        }
    ]
},
{
    "countryName": "Slovenia",
    "translationKey": "country_name_slovenia",
    "countryShortCode": "SI",
    "regions": [
        {
            "name": "Ajdovscina",
            "translationKey": "slovenia_region_name_001",
            "shortCode": "001"
        },
        {
            "name": "Apace",
            "translationKey": "slovenia_region_name_195",
            "shortCode": "195"
        },
        {
            "name": "Beltinci",
            "translationKey": "slovenia_region_name_002",
            "shortCode": "002"
        },
        {
            "name": "Benedikt",
            "translationKey": "slovenia_region_name_148",
            "shortCode": "148"
        },
        {
            "name": "Bistrica ob Sotli",
            "translationKey": "slovenia_region_name_149",
            "shortCode": "149"
        },
        {
            "name": "Bled",
            "translationKey": "slovenia_region_name_003",
            "shortCode": "003"
        },
        {
            "name": "Bloke",
            "translationKey": "slovenia_region_name_150",
            "shortCode": "150"
        },
        {
            "name": "Bohinj",
            "translationKey": "slovenia_region_name_004",
            "shortCode": "004"
        },
        {
            "name": "Borovnica",
            "translationKey": "slovenia_region_name_005",
            "shortCode": "005"
        },
        {
            "name": "Bovec",
            "translationKey": "slovenia_region_name_006",
            "shortCode": "006"
        },
        {
            "name": "Braslovce",
            "translationKey": "slovenia_region_name_151",
            "shortCode": "151"
        },
        {
            "name": "Brda",
            "translationKey": "slovenia_region_name_007",
            "shortCode": "007"
        },
        {
            "name": "Brezice",
            "translationKey": "slovenia_region_name_009",
            "shortCode": "009"
        },
        {
            "name": "Brezovica",
            "translationKey": "slovenia_region_name_008",
            "shortCode": "008"
        },
        {
            "name": "Cankova",
            "translationKey": "slovenia_region_name_152",
            "shortCode": "152"
        },
        {
            "name": "Celje",
            "translationKey": "slovenia_region_name_011",
            "shortCode": "011"
        },
        {
            "name": "Cerklje na Gorenjskem",
            "translationKey": "slovenia_region_name_012",
            "shortCode": "012"
        },
        {
            "name": "Cerknica",
            "translationKey": "slovenia_region_name_013",
            "shortCode": "013"
        },
        {
            "name": "Cerkno",
            "translationKey": "slovenia_region_name_014",
            "shortCode": "014"
        },
        {
            "name": "Cerkvenjak",
            "translationKey": "slovenia_region_name_153",
            "shortCode": "153"
        },
        {
            "name": "Cirkulane",
            "translationKey": "slovenia_region_name_196",
            "shortCode": "196"
        },
        {
            "name": "Crensovci",
            "translationKey": "slovenia_region_name_015",
            "shortCode": "015"
        },
        {
            "name": "Crna na Koroskem",
            "translationKey": "slovenia_region_name_016",
            "shortCode": "016"
        },
        {
            "name": "Crnomelj",
            "translationKey": "slovenia_region_name_017",
            "shortCode": "017"
        },
        {
            "name": "Destrnik",
            "translationKey": "slovenia_region_name_018",
            "shortCode": "018"
        },
        {
            "name": "Divaca",
            "translationKey": "slovenia_region_name_019",
            "shortCode": "019"
        },
        {
            "name": "Dobje",
            "translationKey": "slovenia_region_name_154",
            "shortCode": "154"
        },
        {
            "name": "Dobrepolje",
            "translationKey": "slovenia_region_name_020",
            "shortCode": "020"
        },
        {
            "name": "Dobrna",
            "translationKey": "slovenia_region_name_155",
            "shortCode": "155"
        },
        {
            "name": "Dobrova-Polhov Gradec",
            "translationKey": "slovenia_region_name_021",
            "shortCode": "021"
        },
        {
            "name": "Dobrovnik",
            "translationKey": "slovenia_region_name_156",
            "shortCode": "156"
        },
        {
            "name": "Dol pri Ljubljani",
            "translationKey": "slovenia_region_name_022",
            "shortCode": "022"
        },
        {
            "name": "Dolenjske Toplice",
            "translationKey": "slovenia_region_name_157",
            "shortCode": "157"
        },
        {
            "name": "Domzale",
            "translationKey": "slovenia_region_name_023",
            "shortCode": "023"
        },
        {
            "name": "Dornava",
            "translationKey": "slovenia_region_name_024",
            "shortCode": "024"
        },
        {
            "name": "Dravograd",
            "translationKey": "slovenia_region_name_025",
            "shortCode": "025"
        },
        {
            "name": "Duplek",
            "translationKey": "slovenia_region_name_026",
            "shortCode": "026"
        },
        {
            "name": "Gorenja Vas-Poljane",
            "translationKey": "slovenia_region_name_027",
            "shortCode": "027"
        },
        {
            "name": "Gorisnica",
            "translationKey": "slovenia_region_name_028",
            "shortCode": "028"
        },
        {
            "name": "Gorje",
            "translationKey": "slovenia_region_name_207",
            "shortCode": "207"
        },
        {
            "name": "Gornja Radgona",
            "translationKey": "slovenia_region_name_029",
            "shortCode": "029"
        },
        {
            "name": "Gornji Grad",
            "translationKey": "slovenia_region_name_030",
            "shortCode": "030"
        },
        {
            "name": "Gornji Petrovci",
            "translationKey": "slovenia_region_name_031",
            "shortCode": "031"
        },
        {
            "name": "Grad",
            "translationKey": "slovenia_region_name_158",
            "shortCode": "158"
        },
        {
            "name": "Grosuplje",
            "translationKey": "slovenia_region_name_032",
            "shortCode": "032"
        },
        {
            "name": "Hajdina",
            "translationKey": "slovenia_region_name_159",
            "shortCode": "159"
        },
        {
            "name": "Hoce-Slivnica",
            "translationKey": "slovenia_region_name_160",
            "shortCode": ""
        },
        {
            "name": "Hodos",
            "translationKey": "slovenia_region_name_161",
            "shortCode": "161"
        },
        {
            "name": "Horjul",
            "translationKey": "slovenia_region_name_162",
            "shortCode": "162"
        },
        {
            "name": "Hrastnik",
            "translationKey": "slovenia_region_name_034",
            "shortCode": "034"
        },
        {
            "name": "Hrpelje-Kozina",
            "translationKey": "slovenia_region_name_035",
            "shortCode": "035"
        },
        {
            "name": "Idrija",
            "translationKey": "slovenia_region_name_036",
            "shortCode": "036"
        },
        {
            "name": "Ig",
            "translationKey": "slovenia_region_name_037",
            "shortCode": "037"
        },
        {
            "name": "Ilirska Bistrica",
            "translationKey": "slovenia_region_name_038",
            "shortCode": "038"
        },
        {
            "name": "Ivancna Gorica",
            "translationKey": "slovenia_region_name_039",
            "shortCode": "039"
        },
        {
            "name": "Izola",
            "translationKey": "slovenia_region_name_040",
            "shortCode": "040"
        },
        {
            "name": "Jesenice",
            "translationKey": "slovenia_region_name_041",
            "shortCode": "041"
        },
        {
            "name": "Jursinci",
            "translationKey": "slovenia_region_name_042",
            "shortCode": "042"
        },
        {
            "name": "Kamnik",
            "translationKey": "slovenia_region_name_043",
            "shortCode": "043"
        },
        {
            "name": "Kanal",
            "translationKey": "slovenia_region_name_044",
            "shortCode": "044"
        },
        {
            "name": "Kidricevo",
            "translationKey": "slovenia_region_name_045",
            "shortCode": "045"
        },
        {
            "name": "Kobarid",
            "translationKey": "slovenia_region_name_046",
            "shortCode": "046"
        },
        {
            "name": "Kobilje",
            "translationKey": "slovenia_region_name_047",
            "shortCode": "047"
        },
        {
            "name": "Kocevje",
            "translationKey": "slovenia_region_name_048",
            "shortCode": "048"
        },
        {
            "name": "Komen",
            "translationKey": "slovenia_region_name_049",
            "shortCode": "049"
        },
        {
            "name": "Komenda",
            "translationKey": "slovenia_region_name_164",
            "shortCode": "164"
        },
        {
            "name": "Koper",
            "translationKey": "slovenia_region_name_050",
            "shortCode": "050"
        },
        {
            "name": "Kodanjevica na Krki",
            "translationKey": "slovenia_region_name_197",
            "shortCode": "197"
        },
        {
            "name": "Kostel",
            "translationKey": "slovenia_region_name_165",
            "shortCode": "165"
        },
        {
            "name": "Kozje",
            "translationKey": "slovenia_region_name_051",
            "shortCode": "051"
        },
        {
            "name": "Kranj",
            "translationKey": "slovenia_region_name_052",
            "shortCode": "052"
        },
        {
            "name": "Kranjska Gora",
            "translationKey": "slovenia_region_name_053",
            "shortCode": "053"
        },
        {
            "name": "Krizevci",
            "translationKey": "slovenia_region_name_166",
            "shortCode": "166"
        },
        {
            "name": "Krsko",
            "translationKey": "slovenia_region_name_054",
            "shortCode": "054"
        },
        {
            "name": "Kungota",
            "translationKey": "slovenia_region_name_055",
            "shortCode": "055"
        },
        {
            "name": "Kuzma",
            "translationKey": "slovenia_region_name_056",
            "shortCode": "056"
        },
        {
            "name": "Lasko",
            "translationKey": "slovenia_region_name_057",
            "shortCode": "057"
        },
        {
            "name": "Lenart",
            "translationKey": "slovenia_region_name_058",
            "shortCode": "058"
        },
        {
            "name": "Lendava",
            "translationKey": "slovenia_region_name_059",
            "shortCode": "059"
        },
        {
            "name": "Litija",
            "translationKey": "slovenia_region_name_060",
            "shortCode": "060"
        },
        {
            "name": "Ljubljana",
            "translationKey": "slovenia_region_name_061",
            "shortCode": "061"
        },
        {
            "name": "Ljubno",
            "translationKey": "slovenia_region_name_062",
            "shortCode": "062"
        },
        {
            "name": "Ljutomer",
            "translationKey": "slovenia_region_name_063",
            "shortCode": "063"
        },
        {
            "name": "Log-Dragomer",
            "translationKey": "slovenia_region_name_208",
            "shortCode": "208"
        },
        {
            "name": "Logatec",
            "translationKey": "slovenia_region_name_064",
            "shortCode": "064"
        },
        {
            "name": "Loska Dolina",
            "translationKey": "slovenia_region_name_065",
            "shortCode": "065"
        },
        {
            "name": "Loski Potok",
            "translationKey": "slovenia_region_name_066",
            "shortCode": "066"
        },
        {
            "name": "Lovrenc na Pohorju",
            "translationKey": "slovenia_region_name_167",
            "shortCode": "167"
        },
        {
            "name": "Lukovica",
            "translationKey": "slovenia_region_name_068",
            "shortCode": "068"
        },
        {
            "name": "Luce",
            "translationKey": "slovenia_region_name_067",
            "shortCode": "067"
        },
        {
            "name": "Majsperk",
            "translationKey": "slovenia_region_name_069",
            "shortCode": "069"
        },
        {
            "name": "Makole",
            "translationKey": "slovenia_region_name_198",
            "shortCode": "198"
        },
        {
            "name": "Maribor",
            "translationKey": "slovenia_region_name_070",
            "shortCode": "070"
        },
        {
            "name": "Markovci",
            "translationKey": "slovenia_region_name_168",
            "shortCode": "168"
        },
        {
            "name": "Medvode",
            "translationKey": "slovenia_region_name_071",
            "shortCode": "071"
        },
        {
            "name": "Menges",
            "translationKey": "slovenia_region_name_072",
            "shortCode": "072"
        },
        {
            "name": "Metlika",
            "translationKey": "slovenia_region_name_073",
            "shortCode": "073"
        },
        {
            "name": "Mezica",
            "translationKey": "slovenia_region_name_074",
            "shortCode": "074"
        },
        {
            "name": "Miklavz na Dravskem Polju",
            "translationKey": "slovenia_region_name_169",
            "shortCode": "169"
        },
        {
            "name": "Miren-Kostanjevica",
            "translationKey": "slovenia_region_name_075",
            "shortCode": "075"
        },
        {
            "name": "Mirna",
            "translationKey": "slovenia_region_name_212",
            "shortCode": "212"
        },
        {
            "name": "Mirna Pec",
            "translationKey": "slovenia_region_name_170",
            "shortCode": "170"
        },
        {
            "name": "Mislinja",
            "translationKey": "slovenia_region_name_076",
            "shortCode": "076"
        },
        {
            "name": "Mokronog-Trebelno",
            "translationKey": "slovenia_region_name_199",
            "shortCode": "199"
        },
        {
            "name": "Moravce",
            "translationKey": "slovenia_region_name_077",
            "shortCode": "077"
        },
        {
            "name": "Moravske Toplice",
            "translationKey": "slovenia_region_name_078",
            "shortCode": "078"
        },
        {
            "name": "Mozirje",
            "translationKey": "slovenia_region_name_079",
            "shortCode": "079"
        },
        {
            "name": "Murska Sobota",
            "translationKey": "slovenia_region_name_080",
            "shortCode": "080"
        },
        {
            "name": "Naklo",
            "translationKey": "slovenia_region_name_082",
            "shortCode": "082"
        },
        {
            "name": "Nazarje",
            "translationKey": "slovenia_region_name_083",
            "shortCode": "083"
        },
        {
            "name": "Nova Gorica",
            "translationKey": "slovenia_region_name_084",
            "shortCode": "084"
        },
        {
            "name": "Novo Mesto",
            "translationKey": "slovenia_region_name_085",
            "shortCode": "085"
        },
        {
            "name": "Odranci",
            "translationKey": "slovenia_region_name_086",
            "shortCode": "086"
        },
        {
            "name": "Ormoz",
            "translationKey": "slovenia_region_name_087",
            "shortCode": "087"
        },
        {
            "name": "Osilnica",
            "translationKey": "slovenia_region_name_088",
            "shortCode": "088"
        },
        {
            "name": "Pesnica",
            "translationKey": "slovenia_region_name_089",
            "shortCode": "089"
        },
        {
            "name": "Piran",
            "translationKey": "slovenia_region_name_090",
            "shortCode": "090"
        },
        {
            "name": "Pivka",
            "translationKey": "slovenia_region_name_091",
            "shortCode": "091"
        },
        {
            "name": "Podcetrtek",
            "translationKey": "slovenia_region_name_092",
            "shortCode": "092"
        },
        {
            "name": "Podlehnik",
            "translationKey": "slovenia_region_name_172",
            "shortCode": "172"
        },
        {
            "name": "Podvelka",
            "translationKey": "slovenia_region_name_093",
            "shortCode": "093"
        },
        {
            "name": "Poljcane",
            "translationKey": "slovenia_region_name_200",
            "shortCode": "200"
        },
        {
            "name": "Postojna",
            "translationKey": "slovenia_region_name_094",
            "shortCode": "094"
        },
        {
            "name": "Prebold",
            "translationKey": "slovenia_region_name_174",
            "shortCode": "174"
        },
        {
            "name": "Preddvor",
            "translationKey": "slovenia_region_name_095",
            "shortCode": "095"
        },
        {
            "name": "Prevalje",
            "translationKey": "slovenia_region_name_175",
            "shortCode": "175"
        },
        {
            "name": "Ptuj",
            "translationKey": "slovenia_region_name_096",
            "shortCode": "096"
        },
        {
            "name": "Race-Fram",
            "translationKey": "slovenia_region_name_098",
            "shortCode": "098"
        },
        {
            "name": "Radece",
            "translationKey": "slovenia_region_name_099",
            "shortCode": "099"
        },
        {
            "name": "Radenci",
            "translationKey": "slovenia_region_name_100",
            "shortCode": "100"
        },
        {
            "name": "Radlje ob Dravi",
            "translationKey": "slovenia_region_name_101",
            "shortCode": "101"
        },
        {
            "name": "Radovljica",
            "translationKey": "slovenia_region_name_102",
            "shortCode": "102"
        },
        {
            "name": "Ravne na Koroskem",
            "translationKey": "slovenia_region_name_103",
            "shortCode": "103"
        },
        {
            "name": "Razkrizje",
            "translationKey": "slovenia_region_name_176",
            "shortCode": "176"
        },
        {
            "name": "Recica ob Savinji",
            "translationKey": "slovenia_region_name_209",
            "shortCode": "209"
        },
        {
            "name": "Rence-Vogrsko",
            "translationKey": "slovenia_region_name_201",
            "shortCode": "201"
        },
        {
            "name": "Ribnica",
            "translationKey": "slovenia_region_name_104",
            "shortCode": "104"
        },
        {
            "name": "Ribnica na Poboriu",
            "translationKey": "slovenia_region_name_177",
            "shortCode": "177"
        },
        {
            "name": "Rogaska Slatina",
            "translationKey": "slovenia_region_name_106",
            "shortCode": "106"
        },
        {
            "name": "Rogasovci",
            "translationKey": "slovenia_region_name_105",
            "shortCode": "105"
        },
        {
            "name": "Rogatec",
            "translationKey": "slovenia_region_name_107",
            "shortCode": "107"
        },
        {
            "name": "Ruse",
            "translationKey": "slovenia_region_name_108",
            "shortCode": "108"
        },
        {
            "name": "Salovci",
            "translationKey": "slovenia_region_name_033",
            "shortCode": "033"
        },
        {
            "name": "Selnica ob Dravi",
            "translationKey": "slovenia_region_name_178",
            "shortCode": "178"
        },
        {
            "name": "Semic",
            "translationKey": "slovenia_region_name_109",
            "shortCode": "109"
        },
        {
            "name": "Sempeter-Vrtojba",
            "translationKey": "slovenia_region_name_183",
            "shortCode": "183"
        },
        {
            "name": "Sencur",
            "translationKey": "slovenia_region_name_117",
            "shortCode": "117"
        },
        {
            "name": "Sentilj",
            "translationKey": "slovenia_region_name_118",
            "shortCode": "118"
        },
        {
            "name": "Sentjernej",
            "translationKey": "slovenia_region_name_119",
            "shortCode": "119"
        },
        {
            "name": "Sentjur",
            "translationKey": "slovenia_region_name_120",
            "shortCode": "120"
        },
        {
            "name": "Sentrupert",
            "translationKey": "slovenia_region_name_211",
            "shortCode": "211"
        },
        {
            "name": "Sevnica",
            "translationKey": "slovenia_region_name_110",
            "shortCode": "110"
        },
        {
            "name": "Sezana",
            "translationKey": "slovenia_region_name_111",
            "shortCode": "111"
        },
        {
            "name": "Skocjan",
            "translationKey": "slovenia_region_name_121",
            "shortCode": "121"
        },
        {
            "name": "Skofja Loka",
            "translationKey": "slovenia_region_name_122",
            "shortCode": "122"
        },
        {
            "name": "Skofljica",
            "translationKey": "slovenia_region_name_123",
            "shortCode": "123"
        },
        {
            "name": "Slovenj Gradec",
            "translationKey": "slovenia_region_name_112",
            "shortCode": "112"
        },
        {
            "name": "Slovenska Bistrica",
            "translationKey": "slovenia_region_name_113",
            "shortCode": "113"
        },
        {
            "name": "Slovenske Konjice",
            "translationKey": "slovenia_region_name_114",
            "shortCode": "114"
        },
        {
            "name": "Smarje pri elsah",
            "translationKey": "slovenia_region_name_124",
            "shortCode": "124"
        },
        {
            "name": "Smarjeske Toplice",
            "translationKey": "slovenia_region_name_206",
            "shortCode": "206"
        },
        {
            "name": "Smartno ob Paki",
            "translationKey": "slovenia_region_name_125",
            "shortCode": "125"
        },
        {
            "name": "Smartno pri Litiji",
            "translationKey": "slovenia_region_name_194",
            "shortCode": "194"
        },
        {
            "name": "Sodrazica",
            "translationKey": "slovenia_region_name_179",
            "shortCode": "179"
        },
        {
            "name": "Solcava",
            "translationKey": "slovenia_region_name_180",
            "shortCode": "180"
        },
        {
            "name": "Sostanj",
            "translationKey": "slovenia_region_name_126",
            "shortCode": "126"
        },
        {
            "name": "Sredisce ob Dravi",
            "translationKey": "slovenia_region_name_202",
            "shortCode": "202"
        },
        {
            "name": "Starse",
            "translationKey": "slovenia_region_name_115",
            "shortCode": "115"
        },
        {
            "name": "Store",
            "translationKey": "slovenia_region_name_127",
            "shortCode": "127"
        },
        {
            "name": "Straza",
            "translationKey": "slovenia_region_name_203",
            "shortCode": "203"
        },
        {
            "name": "Sveta Ana",
            "translationKey": "slovenia_region_name_181",
            "shortCode": "181"
        },
        {
            "name": "Sveta Trojica v Slovenskih Goricah",
            "translationKey": "slovenia_region_name_204",
            "shortCode": "204"
        },
        {
            "name": "Sveta Andraz v Slovenskih Goricah",
            "translationKey": "slovenia_region_name_182",
            "shortCode": "182"
        },
        {
            "name": "Sveti Jurij",
            "translationKey": "slovenia_region_name_116",
            "shortCode": "116"
        },
        {
            "name": "Sveti Jurij v Slovenskih Goricah",
            "translationKey": "slovenia_region_name_210",
            "shortCode": "210"
        },
        {
            "name": "Sveti Tomaz",
            "translationKey": "slovenia_region_name_205",
            "shortCode": "205"
        },
        {
            "name": "Tabor",
            "translationKey": "slovenia_region_name_184",
            "shortCode": "184"
        },
        {
            "name": "Tisina",
            "translationKey": "slovenia_region_name_010",
            "shortCode": "010"
        },
        {
            "name": "Tolmin",
            "translationKey": "slovenia_region_name_128",
            "shortCode": "128"
        },
        {
            "name": "Trbovlje",
            "translationKey": "slovenia_region_name_129",
            "shortCode": "129"
        },
        {
            "name": "Trebnje",
            "translationKey": "slovenia_region_name_130",
            "shortCode": "130"
        },
        {
            "name": "Trnovska Vas",
            "translationKey": "slovenia_region_name_185",
            "shortCode": "185"
        },
        {
            "name": "Trzin",
            "translationKey": "slovenia_region_name_186",
            "shortCode": "186"
        },
        {
            "name": "Trzic",
            "translationKey": "slovenia_region_name_131",
            "shortCode": "131"
        },
        {
            "name": "Turnisce",
            "translationKey": "slovenia_region_name_132",
            "shortCode": "132"
        },
        {
            "name": "Velenje",
            "translationKey": "slovenia_region_name_133",
            "shortCode": "133"
        },
        {
            "name": "Velika Polana",
            "translationKey": "slovenia_region_name_187",
            "shortCode": "187"
        },
        {
            "name": "Velike Lasce",
            "translationKey": "slovenia_region_name_134",
            "shortCode": "134"
        },
        {
            "name": "Verzej",
            "translationKey": "slovenia_region_name_188",
            "shortCode": "188"
        },
        {
            "name": "Videm",
            "translationKey": "slovenia_region_name_135",
            "shortCode": "135"
        },
        {
            "name": "Vipava",
            "translationKey": "slovenia_region_name_136",
            "shortCode": "136"
        },
        {
            "name": "Vitanje",
            "translationKey": "slovenia_region_name_137",
            "shortCode": "137"
        },
        {
            "name": "Vodice",
            "translationKey": "slovenia_region_name_138",
            "shortCode": "138"
        },
        {
            "name": "Vojnik",
            "translationKey": "slovenia_region_name_139",
            "shortCode": "139"
        },
        {
            "name": "Vransko",
            "translationKey": "slovenia_region_name_189",
            "shortCode": "189"
        },
        {
            "name": "Vrhnika",
            "translationKey": "slovenia_region_name_140",
            "shortCode": "140"
        },
        {
            "name": "Vuzenica",
            "translationKey": "slovenia_region_name_141",
            "shortCode": "141"
        },
        {
            "name": "Zagorje ob Savi",
            "translationKey": "slovenia_region_name_142",
            "shortCode": "142"
        },
        {
            "name": "Zavrc",
            "translationKey": "slovenia_region_name_143",
            "shortCode": "143"
        },
        {
            "name": "Zrece",
            "translationKey": "slovenia_region_name_144",
            "shortCode": "144"
        },
        {
            "name": "Zalec",
            "translationKey": "slovenia_region_name_190",
            "shortCode": "190"
        },
        {
            "name": "Zelezniki",
            "translationKey": "slovenia_region_name_146",
            "shortCode": "146"
        },
        {
            "name": "Zetale",
            "translationKey": "slovenia_region_name_191",
            "shortCode": "191"
        },
        {
            "name": "Ziri",
            "translationKey": "slovenia_region_name_147",
            "shortCode": "147"
        },
        {
            "name": "Zirovnica",
            "translationKey": "slovenia_region_name_192",
            "shortCode": "192"
        },
        {
            "name": "Zuzemberk",
            "translationKey": "slovenia_region_name_193",
            "shortCode": "193"
        }
    ]
  },
  //   {
  //     "countryName": "Solomon Islands",
  //     "countryShortCode": "SB",
  //     "regions": [
  //       {
  //         "name": "Central",
  //         "shortCode": "CE"
  //       },
  //       {
  //         "name": "Choiseul",
  //         "shortCode": "CH"
  //       },
  //       {
  //         "name": "Guadalcanal",
  //         "shortCode": "GU"
  //       },
  //       {
  //         "name": "Honiara",
  //         "shortCode": "CT"
  //       },
  //       {
  //         "name": "Isabel",
  //         "shortCode": "IS"
  //       },
  //       {
  //         "name": "Makira-Ulawa",
  //         "shortCode": "MK"
  //       },
  //       {
  //         "name": "Malaita",
  //         "shortCode": "ML"
  //       },
  //       {
  //         "name": "Rennell and Bellona",
  //         "shortCode": "RB"
  //       },
  //       {
  //         "name": "Temotu",
  //         "shortCode": "TE"
  //       },
  //       {
  //         "name": "Western",
  //         "shortCode": "WE"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Somalia",
  //     "countryShortCode": "SO",
  //     "regions": [
  //       {
  //         "name": "Awdal",
  //         "shortCode": "AW"
  //       },
  //       {
  //         "name": "Bakool",
  //         "shortCode": "BK"
  //       },
  //       {
  //         "name": "Banaadir",
  //         "shortCode": "BN"
  //       },
  //       {
  //         "name": "Bari",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Bay",
  //         "shortCode": "BY"
  //       },
  //       {
  //         "name": "Galguduud",
  //         "shortCode": "GA"
  //       },
  //       {
  //         "name": "Gedo",
  //         "shortCode": "GE"
  //       },
  //       {
  //         "name": "Hiiraan",
  //         "shortCode": "HI"
  //       },
  //       {
  //         "name": "Jubbada Dhexe",
  //         "shortCode": "JD"
  //       },
  //       {
  //         "name": "Jubbada Hoose",
  //         "shortCode": "JH"
  //       },
  //       {
  //         "name": "Mudug",
  //         "shortCode": "MU"
  //       },
  //       {
  //         "name": "Nugaal",
  //         "shortCode": "NU"
  //       },
  //       {
  //         "name": "Sanaag",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Shabeellaha Dhexe",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Shabeellaha Hoose",
  //         "shortCode": "SH"
  //       },
  //       {
  //         "name": "Sool",
  //         "shortCode": "SO"
  //       },
  //       {
  //         "name": "Togdheer",
  //         "shortCode": "TO"
  //       },
  //       {
  //         "name": "Woqooyi Galbeed",
  //         "shortCode": "WO"
  //       }
  //     ]
  //   },
  {
    "countryName": "South Africa",
    "countryShortCode": "ZA",
    "regions": [
        {
            "name": "Eastern Cape",
            "shortCode": "EC",
            "translationKey": "South Africa_region_EC"
        },
        {
            "name": "Free State",
            "shortCode": "FS",
            "translationKey": "South Africa_region_FS"
        },
        {
            "name": "Gauteng",
            "shortCode": "GT",
            "translationKey": "South Africa_region_GT"
        },
        {
            "name": "KwaZulu-Natal",
            "shortCode": "NL",
            "translationKey": "South Africa_region_NL"
        },
        {
            "name": "Limpopo",
            "shortCode": "LP",
            "translationKey": "South Africa_region_LP"
        },
        {
            "name": "Mpumalanga",
            "shortCode": "MP",
            "translationKey": "South Africa_region_MP"
        },
        {
            "name": "Northern Cape",
            "shortCode": "NC",
            "translationKey": "South Africa_region_NC"
        },
        {
            "name": "North West",
            "shortCode": "NW",
            "translationKey": "South Africa_region_NW"
        },
        {
            "name": "Western Cape",
            "shortCode": "WC",
            "translationKey": "South Africa_region_WC"
        }
    ],
    "translationKey": "country_name_South Africa"
  },
  //   {
  //     "countryName": "South Georgia and South Sandwich Islands",
  //     "countryShortCode": "GS",
  //     "regions": [
  //       {
  //         "name": "Bird Island"
  //       },
  //       {
  //         "name": "Bristol Island"
  //       },
  //       {
  //         "name": "Clerke Rocks"
  //       },
  //       {
  //         "name": "Montagu Island"
  //       },
  //       {
  //         "name": "Saunders Island"
  //       },
  //       {
  //         "name": "South Georgia"
  //       },
  //       {
  //         "name": "Southern Thule"
  //       },
  //       {
  //         "name": "Traversay Islands"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "South Sudan",
  //     "countryShortCode": "SS",
  //     "regions": [
  //       {
  //         "name": "Central Equatoria",
  //         "shortCode": "CE"
  //       },
  //       {
  //         "name": "Eastern Equatoria",
  //         "shortCode": "EE"
  //       },
  //       {
  //         "name": "Jonglei",
  //         "shortCode": "JG"
  //       },
  //       {
  //         "name": "Lakes",
  //         "shortCode": "LK"
  //       },
  //       {
  //         "name": "Northern Bahr el Ghazal",
  //         "shortCode": "BN"
  //       },
  //       {
  //         "name": "Unity",
  //         "shortCode": "UY"
  //       },
  //       {
  //         "name": "Upper Nile",
  //         "shortCode": "NU"
  //       },
  //       {
  //         "name": "Warrap",
  //         "shortCode": "WR"
  //       },
  //       {
  //         "name": "Western Bahr el Ghazal",
  //         "shortCode": "BW"
  //       },
  //       {
  //         "name": "Western Equatoria",
  //         "shortCode": "EW"
  //       }
  //     ]
  //   },
  {
    "countryName": "Spain",
    "translationKey": "country_name_spain",
    "countryShortCode": "ES",
    "regions": [
        {
            "name": "Albacete",
            "translationKey": "spain_region_name_ab",
            "shortCode": "AB"
        },
        {
            "name": "Alicante",
            "translationKey": "spain_region_name_a",
            "shortCode": "A"
        },
        {
            "name": "Almería",
            "translationKey": "spain_region_name_an",
            "shortCode": "AN"
        },
        {
            "name": "Araba/Álava",
            "translationKey": "spain_region_name_vi",
            "shortCode": "VI"
        },
        {
            "name": "Asturias",
            "translationKey": "spain_region_name_o",
            "shortCode": "O"
        },
        {
            "name": "Ávila",
            "translationKey": "spain_region_name_av",
            "shortCode": "AV"
        },
        {
            "name": "Badajoz",
            "translationKey": "spain_region_name_ba",
            "shortCode": "BA"
        },
        {
            "name": "Barcelona",
            "translationKey": "spain_region_name_b",
            "shortCode": "B"
        },
        {
            "name": "Bizkaia",
            "translationKey": "spain_region_name_bi",
            "shortCode": "BI"
        },
        {
            "name": "Burgos",
            "translationKey": "spain_region_name_bu",
            "shortCode": "BU"
        },
        {
            "name": "Cáceres",
            "translationKey": "spain_region_name_cc",
            "shortCode": "CC"
        },
        {
            "name": "Cádiz",
            "translationKey": "spain_region_name_ca",
            "shortCode": "CA"
        },
        {
            "name": "Cantabria",
            "translationKey": "spain_region_name_s",
            "shortCode": "S"
        },
        {
            "name": "Castellón/Castelló",
            "translationKey": "spain_region_name_cs",
            "shortCode": "CS"
        },
        {
            "name": "Ceuta",
            "translationKey": "spain_region_name_ce",
            "shortCode": "CE"
        },
        {
            "name": "Ciudad Real",
            "translationKey": "spain_region_name_cr",
            "shortCode": "CR"
        },
        {
            "name": "Córdoba",
            "translationKey": "spain_region_name_co",
            "shortCode": "CO"
        },
        {
            "name": "A Coruña",
            "translationKey": "spain_region_name_c",
            "shortCode": "C"
        },
        {
            "name": "Cuenca",
            "translationKey": "spain_region_name_cu",
            "shortCode": "CU"
        },
        {
            "name": "Gipuzkoa",
            "translationKey": "spain_region_name_ss",
            "shortCode": "SS"
        },
        {
            "name": "Girona",
            "translationKey": "spain_region_name_gi",
            "shortCode": "GI"
        },
        {
            "name": "Granada",
            "translationKey": "spain_region_name_gr",
            "shortCode": "GR"
        },
        {
            "name": "Guadalajara",
            "translationKey": "spain_region_name_gu",
            "shortCode": "GU"
        },
        {
            "name": "Huelva",
            "translationKey": "spain_region_name_h",
            "shortCode": "H"
        },
        {
            "name": "Huesca",
            "translationKey": "spain_region_name_hu",
            "shortCode": "HU"
        },
        {
            "name": "Illes Balears",
            "translationKey": "spain_region_name_pm",
            "shortCode": "PM"
        },
        {
            "name": "Jaén",
            "translationKey": "spain_region_name_j",
            "shortCode": "J"
        },
        {
            "name": "León",
            "translationKey": "spain_region_name_le",
            "shortCode": "LE"
        },
        {
            "name": "Lleida",
            "translationKey": "spain_region_name_l",
            "shortCode": "L"
        },
        {
            "name": "Lugo",
            "translationKey": "spain_region_name_lu",
            "shortCode": "LU"
        },
        {
            "name": "Madrid",
            "translationKey": "spain_region_name_m,",
            "shortCode": "M"
        },
        {
            "name": "Málaga",
            "translationKey": "spain_region_name_ma",
            "shortCode": "MA"
        },
        {
            "name": "Melilla",
            "translationKey": "spain_region_name_ml",
            "shortCode": "ML"
        },
        {
            "name": "Murcia",
            "translationKey": "spain_region_name_mu",
            "shortCode": "MU"
        },
        {
            "name": "Navarra/Nafarroa",
            "translationKey": "spain_region_name_na",
            "shortCode": "NA"
        },
        {
            "name": "Ourense",
            "translationKey": "spain_region_name_or",
            "shortCode": "OR"
        },
        {
            "name": "Palencia",
            "translationKey": "spain_region_name_p",
            "shortCode": "P"
        },
        {
            "name": "Las Palmas",
            "translationKey": "spain_region_name_gc",
            "shortCode": "GC"
        },
        {
            "name": "Pontevedra",
            "translationKey": "spain_region_name_po",
            "shortCode": "PO"
        },
        {
            "name": "La Rioja",
            "translationKey": "spain_region_name_lo",
            "shortCode": "LO"
        },
        {
            "name": "Salamanca",
            "translationKey": "spain_region_name_sa",
            "shortCode": "SA"
        },
        {
            "name": "Santa Cruz de Tenerife",
            "translationKey": "spain_region_name_tf",
            "shortCode": "TF"
        },
        {
            "name": "Segovia",
            "translationKey": "spain_region_name_sg",
            "shortCode": "SG"
        },
        {
            "name": "Sevilla",
            "translationKey": "spain_region_name_se",
            "shortCode": "SE"
        },
        {
            "name": "Soria",
            "translationKey": "spain_region_name_so",
            "shortCode": "SO"
        },
        {
            "name": "Tarragona",
            "translationKey": "spain_region_name_t",
            "shortCode": "T"
        },
        {
            "name": "Teruel",
            "translationKey": "spain_region_name_te",
            "shortCode": "TE"
        },
        {
            "name": "Toledo",
            "translationKey": "spain_region_name_to",
            "shortCode": "TO"
        },
        {
            "name": "Valencia/València",
            "translationKey": "spain_region_name_v",
            "shortCode": "V"
        },
        {
            "name": "Valladolid",
            "translationKey": "spain_region_name_va",
            "shortCode": "VA"
        },
        {
            "name": "Zamora",
            "translationKey": "spain_region_name_za",
            "shortCode": "ZA"
        },
        {
            "name": "Zaragoza",
            "translationKey": "spain_region_name_z",
            "shortCode": "Z"
        }
    ]
  },
  //   {
  //     "countryName": "Sri Lanka",
  //     "countryShortCode": "LK",
  //     "regions": [
  //       {
  //         "name": "Basnahira",
  //         "shortCode": "1"
  //       },
  //       {
  //         "name": "Dakunu",
  //         "shortCode": "3"
  //       },
  //       {
  //         "name": "Madhyama",
  //         "shortCode": "2"
  //       },
  //       {
  //         "name": "Naegenahira",
  //         "shortCode": "5"
  //       },
  //       {
  //         "name": "Sabaragamuwa",
  //         "shortCode": "9"
  //       },
  //       {
  //         "name": "Uturu",
  //         "shortCode": "4"
  //       },
  //       {
  //         "name": "Uturumaeda",
  //         "shortCode": "7"
  //       },
  //       {
  //         "name": "Vayamba",
  //         "shortCode": "6"
  //       },
  //       {
  //         "name": "Uva",
  //         "shortCode": "8"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Sudan",
  //     "countryShortCode": "SD",
  //     "regions": [
  //       {
  //         "name": "Al Bahr al Ahmar",
  //         "shortCode": "RS"
  //       },
  //       {
  //         "name": "Al Jazirah",
  //         "shortCode": "GZ"
  //       },
  //       {
  //         "name": "Al Khartum",
  //         "shortCode": "KH"
  //       },
  //       {
  //         "name": "Al Qadarif",
  //         "shortCode": "GD"
  //       },
  //       {
  //         "name": "An Nil al Abyad",
  //         "shortCode": "NW"
  //       },
  //       {
  //         "name": "An Nil al Azraq",
  //         "shortCode": "NB"
  //       },
  //       {
  //         "name": "Ash Shamaliyah",
  //         "shortCode": "NO"
  //       },
  //       {
  //         "name": "Gharb Darfur",
  //         "shortCode": "DW"
  //       },
  //       {
  //         "name": "Gharb Kurdufan",
  //         "shortCode": "GK"
  //       },
  //       {
  //         "name": "Janub Darfur",
  //         "shortCode": "DS"
  //       },
  //       {
  //         "name": "Janub Kurdufan",
  //         "shortCode": "KS"
  //       },
  //       {
  //         "name": "Kassala",
  //         "shortCode": "KA"
  //       },
  //       {
  //         "name": "Nahr an Nil",
  //         "shortCode": "NR"
  //       },
  //       {
  //         "name": "Shamal Darfur",
  //         "shortCode": "DN"
  //       },
  //       {
  //         "name": "Sharq Darfur",
  //         "shortCode": "DE"
  //       },
  //       {
  //         "name": "Shiamal Kurdufan",
  //         "shortCode": "KN"
  //       },
  //       {
  //         "name": "Sinnar",
  //         "shortCode": "SI"
  //       },
  //       {
  //         "name": "Wasat Darfur Zalinjay",
  //         "shortCode": "DC"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Suriname",
  //     "countryShortCode": "SR",
  //     "regions": [
  //       {
  //         "name": "Brokopondo",
  //         "shortCode": "BR"
  //       },
  //       {
  //         "name": "Commewijne",
  //         "shortCode": "CM"
  //       },
  //       {
  //         "name": "Coronie",
  //         "shortCode": "CR"
  //       },
  //       {
  //         "name": "Marowijne",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Nickerie",
  //         "shortCode": "NI"
  //       },
  //       {
  //         "name": "Para",
  //         "shortCode": "PR"
  //       },
  //       {
  //         "name": "Paramaribo",
  //         "shortCode": "PM"
  //       },
  //       {
  //         "name": "Saramacca",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Sipaliwini",
  //         "shortCode": "SI"
  //       },
  //       {
  //         "name": "Wanica",
  //         "shortCode": "WA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Swaziland",
  //     "countryShortCode": "SZ",
  //     "regions": [
  //       {
  //         "name": "Hhohho",
  //         "shortCode": "HH"
  //       },
  //       {
  //         "name": "Lubombo",
  //         "shortCode": "LU"
  //       },
  //       {
  //         "name": "Manzini",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Shiselweni",
  //         "shortCode": "SH"
  //       }
  //     ]
  //   },
  {
    "countryName": "Sweden",
    "translationKey": "country_name_sweden",
    "countryShortCode": "SE",
    "regions": [
        {
            "name": "Blekinge",
            "translationKey": "sweden_region_name_k",
            "shortCode": "K"
        },
        {
            "name": "Dalarna",
            "translationKey": "sweden_region_name_w",
            "shortCode": "W"
        },
        {
            "name": "Gävleborg",
            "translationKey": "sweden_region_name_x",
            "shortCode": "X"
        },
        {
            "name": "Gotland",
            "translationKey": "sweden_region_name_i",
            "shortCode": "I"
        },
        {
            "name": "Halland",
            "translationKey": "sweden_region_name_n",
            "shortCode": "N"
        },
        {
            "name": "Jämtland",
            "translationKey": "sweden_region_name_z",
            "shortCode": "Z"
        },
        {
            "name": "Jönköping",
            "translationKey": "sweden_region_name_f",
            "shortCode": "F"
        },
        {
            "name": "Kalmar",
            "translationKey": "sweden_region_name_h",
            "shortCode": "H"
        },
        {
            "name": "Kronoberg",
            "translationKey": "sweden_region_name_g",
            "shortCode": "G"
        },
        {
            "name": "Norrbotten",
            "translationKey": "sweden_region_name_bd",
            "shortCode": "BD"
        },
        {
            "name": "Örebro",
            "translationKey": "sweden_region_name_t",
            "shortCode": "T"
        },
        {
            "name": "Östergötland",
            "translationKey": "sweden_region_name_e",
            "shortCode": "E"
        },
        {
            "name": "Skåne",
            "translationKey": "sweden_region_name_m",
            "shortCode": "M"
        },
        {
            "name": "Södermanland",
            "translationKey": "sweden_region_name_d",
            "shortCode": "D"
        },
        {
            "name": "Stockholm",
            "translationKey": "sweden_region_name_ab",
            "shortCode": "AB"
        },
        {
            "name": "Uppsala",
            "translationKey": "sweden_region_name_c",
            "shortCode": "C"
        },
        {
            "name": "Värmland",
            "translationKey": "sweden_region_name_s",
            "shortCode": "S"
        },
        {
            "name": "Västerbotten",
            "translationKey": "sweden_region_name_ac",
            "shortCode": "AC"
        },
        {
            "name": "Västernorrland",
            "translationKey": "sweden_region_name_y",
            "shortCode": "Y"
        },
        {
            "name": "Västmanland",
            "translationKey": "sweden_region_name_u",
            "shortCode": "U"
        },
        {
            "name": "Västra Götaland",
            "translationKey": "sweden_region_name_o",
            "shortCode": "O"
        }
    ]
},
{
    "countryName": "Switzerland",
    "countryShortCode": "CH",
    "regions": [
        {
            "name": "Aargau",
            "shortCode": "AG",
            "translationKey": "Switzerland_region_AG"
        },
        {
            "name": "Appenzell Ausserrhoden",
            "shortCode": "AR",
            "translationKey": "Switzerland_region_AR"
        },
        {
            "name": "Appenzell Innerhoden",
            "shortCode": "AI",
            "translationKey": "Switzerland_region_AI"
        },
        {
            "name": "Basel-Landschaft",
            "shortCode": "BL",
            "translationKey": "Switzerland_region_BL"
        },
        {
            "name": "Basel-Stadt",
            "shortCode": "BS",
            "translationKey": "Switzerland_region_BS"
        },
        {
            "name": "Bern",
            "shortCode": "BE",
            "translationKey": "Switzerland_region_BE"
        },
        {
            "name": "Fribourg",
            "shortCode": "FR",
            "translationKey": "Switzerland_region_FR"
        },
        {
            "name": "Genève",
            "shortCode": "GE",
            "translationKey": "Switzerland_region_GE"
        },
        {
            "name": "Glarus",
            "shortCode": "GL",
            "translationKey": "Switzerland_region_GL"
        },
        {
            "name": "Graubünden",
            "shortCode": "GR",
            "translationKey": "Switzerland_region_GR"
        },
        {
            "name": "Jura",
            "shortCode": "JU",
            "translationKey": "Switzerland_region_JU"
        },
        {
            "name": "Luzern",
            "shortCode": "LU",
            "translationKey": "Switzerland_region_LU"
        },
        {
            "name": "Neuchâtel",
            "shortCode": "NE",
            "translationKey": "Switzerland_region_NE"
        },
        {
            "name": "Nidwalden",
            "shortCode": "NW",
            "translationKey": "Switzerland_region_NW"
        },
        {
            "name": "Obwalden",
            "shortCode": "OW",
            "translationKey": "Switzerland_region_OW"
        },
        {
            "name": "Sankt Gallen",
            "shortCode": "SG",
            "translationKey": "Switzerland_region_SG"
        },
        {
            "name": "Schaffhausen",
            "shortCode": "SH",
            "translationKey": "Switzerland_region_SH"
        },
        {
            "name": "Schwyz",
            "shortCode": "SZ",
            "translationKey": "Switzerland_region_SZ"
        },
        {
            "name": "Solothurn",
            "shortCode": "SO",
            "translationKey": "Switzerland_region_SO"
        },
        {
            "name": "Thurgau",
            "shortCode": "TG",
            "translationKey": "Switzerland_region_TG"
        },
        {
            "name": "Ticino",
            "shortCode": "TI",
            "translationKey": "Switzerland_region_TI"
        },
        {
            "name": "Uri",
            "shortCode": "UR",
            "translationKey": "Switzerland_region_UR"
        },
        {
            "name": "Valais",
            "shortCode": "VS",
            "translationKey": "Switzerland_region_VS"
        },
        {
            "name": "Vaud",
            "shortCode": "VD",
            "translationKey": "Switzerland_region_VD"
        },
        {
            "name": "Zug",
            "shortCode": "ZG",
            "translationKey": "Switzerland_region_ZG"
        },
        {
            "name": "Zürich",
            "shortCode": "ZH",
            "translationKey": "Switzerland_region_ZH"
        }
    ],
    "translationKey": "country_name_Switzerland"
  },
  //   {
  //     "countryName": "Syrian Arab Republic",
  //     "countryShortCode": "SY",
  //     "regions": [
  //       {
  //         "name": "Al Hasakah",
  //         "shortCode": "HA"
  //       },
  //       {
  //         "name": "Al Ladhiqiyah",
  //         "shortCode": "LA"
  //       },
  //       {
  //         "name": "Al Qunaytirah",
  //         "shortCode": "QU"
  //       },
  //       {
  //         "name": "Ar Raqqah",
  //         "shortCode": "RA"
  //       },
  //       {
  //         "name": "As Suwayda'",
  //         "shortCode": "SU"
  //       },
  //       {
  //         "name": "Dar'a",
  //         "shortCode": "DR"
  //       },
  //       {
  //         "name": "Dayr az Zawr",
  //         "shortCode": "DY"
  //       },
  //       {
  //         "name": "Dimashq",
  //         "shortCode": "DI"
  //       },
  //       {
  //         "name": "Halab",
  //         "shortCode": "HL"
  //       },
  //       {
  //         "name": "Hamah",
  //         "shortCode": "HM"
  //       },
  //       {
  //         "name": "Hims",
  //         "shortCode": "HI"
  //       },
  //       {
  //         "name": "Idlib",
  //         "shortCode": "ID"
  //       },
  //       {
  //         "name": "Rif Dimashq",
  //         "shortCode": "RD"
  //       },
  //       {
  //         "name": "Tartus",
  //         "shortCode": "TA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Taiwan",
  //     "countryShortCode": "TW",
  //     "regions": [
  //       {
  //         "name": "Chang-hua",
  //         "shortCode": "CHA"
  //       },
  //       {
  //         "name": "Chia-i",
  //         "shortCode": "CYQ"
  //       },
  //       {
  //         "name": "Hsin-chu",
  //         "shortCode": "HSQ"
  //       },
  //       {
  //         "name": "Hua-lien",
  //         "shortCode": "HUA"
  //       },
  //       {
  //         "name": "Kao-hsiung",
  //         "shortCode": "KHH"
  //       },
  //       {
  //         "name": "Keelung",
  //         "shortCode": "KEE"
  //       },
  //       {
  //         "name": "Kinmen",
  //         "shortCode": "KIN"
  //       },
  //       {
  //         "name": "Lienchiang",
  //         "shortCode": "LIE"
  //       },
  //       {
  //         "name": "Miao-li",
  //         "shortCode": "MIA"
  //       },
  //       {
  //         "name": "Nan-t'ou",
  //         "shortCode": "NAN"
  //       },
  //       {
  //         "name": "P'eng-hu",
  //         "shortCode": "PEN"
  //       },
  //       {
  //         "name": "New Taipei",
  //         "shortCode": "NWT"
  //       },
  //       {
  //         "name": "P'ing-tung",
  //         "shortCode": "PING"
  //       },
  //       {
  //         "name": "T'ai-chung",
  //         "shortCode": "TXG"
  //       },
  //       {
  //         "name": "T'ai-nan",
  //         "shortCode": "TNN"
  //       },
  //       {
  //         "name": "T'ai-pei",
  //         "shortCode": "TPE"
  //       },
  //       {
  //         "name": "T'ai-tung",
  //         "shortCode": "TTT"
  //       },
  //       {
  //         "name": "T'ao-yuan",
  //         "shortCode": "TAO"
  //       },
  //       {
  //         "name": "Yi-lan",
  //         "shortCode": "ILA"
  //       },
  //       {
  //         "name": "Yun-lin",
  //         "shortCode": "YUN"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Tajikistan",
  //     "countryShortCode": "TJ",
  //     "regions": [
  //       {
  //         "name": "Dushanbe",
  //         "shortCode": "DU"
  //       },
  //       {
  //         "name": "Kŭhistoni Badakhshon",
  //         "shortCode": "GB"
  //       },
  //       {
  //         "name": "Khatlon",
  //         "shortCode": "KT"
  //       },
  //       {
  //         "name": "Sughd",
  //         "shortCode": "SU"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Tanzania, United Republic of",
  //     "countryShortCode": "TZ",
  //     "regions": [
  //       {
  //         "name": "Arusha",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Coast",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Dar es Salaam",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Dodoma",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Iringa",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Kagera",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Kigoma",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Kilimanjaro",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Lindi",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Manyara",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Mara",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Mbeya",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Morogoro",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Mtwara",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Mwanza",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Pemba North",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Pemba South",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Rukwa",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Ruvuma",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Shinyanga",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Singida",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Tabora",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Tanga",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Zanzibar North",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Zanzibar Central/South",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Zanzibar Urban/West",
  //         "shortCode": "15"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Thailand",
  //     "countryShortCode": "TH",
  //     "regions": [
  //       {
  //         "name": "Amnat Charoen",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "Ang Thong",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Bueng Kan",
  //         "shortCode": "38"
  //       },
  //       {
  //         "name": "Buri Ram",
  //         "shortCode": "31"
  //       },
  //       {
  //         "name": "Chachoengsao",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Chai Nat",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Chaiyaphum",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "Chanthaburi",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Chiang Mai",
  //         "shortCode": "50"
  //       },
  //       {
  //         "name": "Chiang Rai",
  //         "shortCode": "57"
  //       },
  //       {
  //         "name": "Chon Buri",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Chumphon",
  //         "shortCode": "86"
  //       },
  //       {
  //         "name": "Kalasin",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "Kamphaeng Phet",
  //         "shortCode": "62"
  //       },
  //       {
  //         "name": "Kanchanaburi",
  //         "shortCode": "71"
  //       },
  //       {
  //         "name": "Khon Kaen",
  //         "shortCode": "40"
  //       },
  //       {
  //         "name": "Krabi",
  //         "shortCode": "81"
  //       },
  //       {
  //         "name": "Krung Thep Mahanakhon (Bangkok)",
  //         "shortCode": "10"
  //       },
  //       {
  //         "name": "Lampang",
  //         "shortCode": "52"
  //       },
  //       {
  //         "name": "Lamphun",
  //         "shortCode": "51"
  //       },
  //       {
  //         "name": "Loei",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Lop Buri",
  //         "shortCode": "16"
  //       },
  //       {
  //         "name": "Mae Hong Son",
  //         "shortCode": "58"
  //       },
  //       {
  //         "name": "Maha Sarakham",
  //         "shortCode": "44"
  //       },
  //       {
  //         "name": "Mukdahan",
  //         "shortCode": "49"
  //       },
  //       {
  //         "name": "Nakhon Nayok",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Nakhon Phathom",
  //         "shortCode": "73"
  //       },
  //       {
  //         "name": "Nakhon Phanom",
  //         "shortCode": "48"
  //       },
  //       {
  //         "name": "Nakhon Ratchasima",
  //         "shortCode": "30"
  //       },
  //       {
  //         "name": "Nakhon Sawan",
  //         "shortCode": "60"
  //       },
  //       {
  //         "name": "Nakhon Si Thammarat",
  //         "shortCode": "80"
  //       },
  //       {
  //         "name": "Nan",
  //         "shortCode": "55"
  //       },
  //       {
  //         "name": "Narathiwat",
  //         "shortCode": "96"
  //       },
  //       {
  //         "name": "Nong Bua Lam Phu",
  //         "shortCode": "39"
  //       },
  //       {
  //         "name": "Nong Khai",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Nonthaburi",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Pathum Thani",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Pattani",
  //         "shortCode": "94"
  //       },
  //       {
  //         "name": "Phangnga",
  //         "shortCode": "82"
  //       },
  //       {
  //         "name": "Phatthalung",
  //         "shortCode": "93"
  //       },
  //       {
  //         "name": "Phayao",
  //         "shortCode": "56"
  //       },
  //       {
  //         "name": "Phetchabun",
  //         "shortCode": "67"
  //       },
  //       {
  //         "name": "Phetchaburi",
  //         "shortCode": "76"
  //       },
  //       {
  //         "name": "Phichit",
  //         "shortCode": "66"
  //       },
  //       {
  //         "name": "Phitsanulok",
  //         "shortCode": "65"
  //       },
  //       {
  //         "name": "Phra Nakhon Si Ayutthaya",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Phrae",
  //         "shortCode": "54"
  //       },
  //       {
  //         "name": "Phuket",
  //         "shortCode": "83"
  //       },
  //       {
  //         "name": "Prachin Buri",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Prachuap Khiri Khan",
  //         "shortCode": "77"
  //       },
  //       {
  //         "name": "Ranong",
  //         "shortCode": "85"
  //       },
  //       {
  //         "name": "Ratchaburi",
  //         "shortCode": "70"
  //       },
  //       {
  //         "name": "Rayong",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Roi Et",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Sa Kaeo",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Sakon Nakhon",
  //         "shortCode": "47"
  //       },
  //       {
  //         "name": "Samut Prakan",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Samut Sakhon",
  //         "shortCode": "74"
  //       },
  //       {
  //         "name": "Samut Songkhram",
  //         "shortCode": "75"
  //       },
  //       {
  //         "name": "Saraburi",
  //         "shortCode": "19"
  //       },
  //       {
  //         "name": "Satun",
  //         "shortCode": "91"
  //       },
  //       {
  //         "name": "Sing Buri",
  //         "shortCode": "17"
  //       },
  //       {
  //         "name": "Si Sa ket",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Songkhla",
  //         "shortCode": "90"
  //       },
  //       {
  //         "name": "Sukhothai",
  //         "shortCode": "64"
  //       },
  //       {
  //         "name": "Suphan Buri",
  //         "shortCode": "72"
  //       },
  //       {
  //         "name": "Surat Thani",
  //         "shortCode": "84"
  //       },
  //       {
  //         "name": "Surin",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Tak",
  //         "shortCode": "63"
  //       },
  //       {
  //         "name": "Trang",
  //         "shortCode": "92"
  //       },
  //       {
  //         "name": "Trat",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Ubon Ratchathani",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Udon Thani",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Uthai Thani",
  //         "shortCode": "61"
  //       },
  //       {
  //         "name": "Uttaradit",
  //         "shortCode": "53"
  //       },
  //       {
  //         "name": "Yala",
  //         "shortCode": "95"
  //       },
  //       {
  //         "name": "Yasothon",
  //         "shortCode": "35"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Timor-Leste",
  //     "countryShortCode": "TL",
  //     "regions": [
  //       {
  //         "name": "Aileu",
  //         "shortCode": "AL"
  //       },
  //       {
  //         "name": "Ainaro",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Baucau",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Bobonaro",
  //         "shortCode": "BO"
  //       },
  //       {
  //         "name": "Cova Lima",
  //         "shortCode": "CO"
  //       },
  //       {
  //         "name": "Dili",
  //         "shortCode": "DI"
  //       },
  //       {
  //         "name": "Ermera",
  //         "shortCode": "ER"
  //       },
  //       {
  //         "name": "Lautem",
  //         "shortCode": "LA"
  //       },
  //       {
  //         "name": "Liquica",
  //         "shortCode": "LI"
  //       },
  //       {
  //         "name": "Manatuto",
  //         "shortCode": "MT"
  //       },
  //       {
  //         "name": "Manufahi",
  //         "shortCode": "MF"
  //       },
  //       {
  //         "name": "Oecussi",
  //         "shortCode": "OE"
  //       },
  //       {
  //         "name": "Viqueque",
  //         "shortCode": "VI"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Togo",
  //     "countryShortCode": "TG",
  //     "regions": [
  //       {
  //         "name": "Centre",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Kara",
  //         "shortCode": "K"
  //       },
  //       {
  //         "name": "Maritime",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "Plateaux",
  //         "shortCode": "P"
  //       },
  //       {
  //         "name": "Savannes",
  //         "shortCode": "S"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Tokelau",
  //     "countryShortCode": "TK",
  //     "regions": [
  //       {
  //         "name": "Atafu"
  //       },
  //       {
  //         "name": "Fakaofo"
  //       },
  //       {
  //         "name": "Nukunonu"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Tonga",
  //     "countryShortCode": "TO",
  //     "regions": [
  //       {
  //         "name": "'Eua",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Ha'apai",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Niuas",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Tongatapu",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Vava'u",
  //         "shortCode": "05"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Trinidad and Tobago",
  //     "countryShortCode": "TT",
  //     "regions": [
  //       {
  //         "name": "Arima",
  //         "shortCode": "ARI"
  //       },
  //       {
  //         "name": "Chaguanas",
  //         "shortCode": "CHA"
  //       },
  //       {
  //         "name": "Couva-Tabaquite-Talparo",
  //         "shortCode": "CTT"
  //       },
  //       {
  //         "name": "Diefo Martin",
  //         "shortCode": "DMN"
  //       },
  //       {
  //         "name": "Mayaro-Rio Claro",
  //         "shortCode": "MRC"
  //       },
  //       {
  //         "name": "Penal-Debe",
  //         "shortCode": "PED"
  //       },
  //       {
  //         "name": "Point Fortin",
  //         "shortCode": "PTF"
  //       },
  //       {
  //         "name": "Port-of-Spain",
  //         "shortCode": "POS"
  //       },
  //       {
  //         "name": "Princes Town",
  //         "shortCode": "PRT"
  //       },
  //       {
  //         "name": "San Fernando",
  //         "shortCode": "SFO"
  //       },
  //       {
  //         "name": "San Juan-Laventille",
  //         "shortCode": "SJL"
  //       },
  //       {
  //         "name": "Sangre Grande",
  //         "shortCode": "SGE"
  //       },
  //       {
  //         "name": "Siparia",
  //         "shortCode": "SIP"
  //       },
  //       {
  //         "name": "Tobago",
  //         "shortCode": "TOB"
  //       },
  //       {
  //         "name": "Tunapuna-Piarco",
  //         "shortCode": "TUP"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Tunisia",
  //     "countryShortCode": "TN",
  //     "regions": [
  //       {
  //         "name": "Ariana",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Beja",
  //         "shortCode": "31"
  //       },
  //       {
  //         "name": "Ben Arous",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Bizerte",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Gabes",
  //         "shortCode": "81"
  //       },
  //       {
  //         "name": "Gafsa",
  //         "shortCode": "71"
  //       },
  //       {
  //         "name": "Jendouba",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Kairouan",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Kasserine",
  //         "shortCode": "42"
  //       },
  //       {
  //         "name": "Kebili",
  //         "shortCode": "73"
  //       },
  //       {
  //         "name": "Kef",
  //         "shortCode": "33"
  //       },
  //       {
  //         "name": "Mahdia",
  //         "shortCode": "53"
  //       },
  //       {
  //         "name": "Medenine",
  //         "shortCode": "82"
  //       },
  //       {
  //         "name": "Monastir",
  //         "shortCode": "52"
  //       },
  //       {
  //         "name": "Nabeul",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Sfax",
  //         "shortCode": "61"
  //       },
  //       {
  //         "name": "Sidi Bouzid",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Siliana",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Sousse",
  //         "shortCode": "51"
  //       },
  //       {
  //         "name": "Tataouine",
  //         "shortCode": "83"
  //       },
  //       {
  //         "name": "Tozeur",
  //         "shortCode": "72"
  //       },
  //       {
  //         "name": "Tunis",
  //         "shortCode": "11"
  //       },
  //       {
  //         "name": "Zaghouan",
  //         "shortCode": "22"
  //       }
  //     ]
  //   },
  {
    "countryName": "Turkey",
    "countryShortCode": "TR",
    "regions": [
        {
            "name": "Adana",
            "shortCode": "01",
            "translationKey": "Turkey_region_01"
        },
        {
            "name": "Adiyaman",
            "shortCode": "02",
            "translationKey": "Turkey_region_02"
        },
        {
            "name": "Afyonkarahisar",
            "shortCode": "03",
            "translationKey": "Turkey_region_03"
        },
        {
            "name": "Agri",
            "shortCode": "04",
            "translationKey": "Turkey_region_04"
        },
        {
            "name": "Aksaray",
            "shortCode": "68",
            "translationKey": "Turkey_region_68"
        },
        {
            "name": "Amasya",
            "shortCode": "05",
            "translationKey": "Turkey_region_05"
        },
        {
            "name": "Ankara",
            "shortCode": "06",
            "translationKey": "Turkey_region_06"
        },
        {
            "name": "Antalya",
            "shortCode": "07",
            "translationKey": "Turkey_region_07"
        },
        {
            "name": "Ardahan",
            "shortCode": "75",
            "translationKey": "Turkey_region_75"
        },
        {
            "name": "Artvin",
            "shortCode": "08",
            "translationKey": "Turkey_region_08"
        },
        {
            "name": "Aydin",
            "shortCode": "09",
            "translationKey": "Turkey_region_09"
        },
        {
            "name": "Balikesir",
            "shortCode": "10",
            "translationKey": "Turkey_region_10"
        },
        {
            "name": "Bartin",
            "shortCode": "74",
            "translationKey": "Turkey_region_74"
        },
        {
            "name": "Batman",
            "shortCode": "72",
            "translationKey": "Turkey_region_72"
        },
        {
            "name": "Bayburt",
            "shortCode": "69",
            "translationKey": "Turkey_region_69"
        },
        {
            "name": "Bilecik",
            "shortCode": "11",
            "translationKey": "Turkey_region_11"
        },
        {
            "name": "Bingol",
            "shortCode": "12",
            "translationKey": "Turkey_region_12"
        },
        {
            "name": "Bitlis",
            "shortCode": "13",
            "translationKey": "Turkey_region_13"
        },
        {
            "name": "Bolu",
            "shortCode": "14",
            "translationKey": "Turkey_region_14"
        },
        {
            "name": "Burdur",
            "shortCode": "15",
            "translationKey": "Turkey_region_15"
        },
        {
            "name": "Bursa",
            "shortCode": "16",
            "translationKey": "Turkey_region_16"
        },
        {
            "name": "Canakkale",
            "shortCode": "17",
            "translationKey": "Turkey_region_17"
        },
        {
            "name": "Cankiri",
            "shortCode": "18",
            "translationKey": "Turkey_region_18"
        },
        {
            "name": "Corum",
            "shortCode": "19",
            "translationKey": "Turkey_region_19"
        },
        {
            "name": "Denizli",
            "shortCode": "20",
            "translationKey": "Turkey_region_20"
        },
        {
            "name": "Diyarbakir",
            "shortCode": "21",
            "translationKey": "Turkey_region_21"
        },
        {
            "name": "Duzce",
            "shortCode": "81",
            "translationKey": "Turkey_region_81"
        },
        {
            "name": "Edirne",
            "shortCode": "22",
            "translationKey": "Turkey_region_22"
        },
        {
            "name": "Elazig",
            "shortCode": "23",
            "translationKey": "Turkey_region_23"
        },
        {
            "name": "Erzincan",
            "shortCode": "24",
            "translationKey": "Turkey_region_24"
        },
        {
            "name": "Erzurum",
            "shortCode": "25",
            "translationKey": "Turkey_region_25"
        },
        {
            "name": "Eskisehir",
            "shortCode": "26",
            "translationKey": "Turkey_region_26"
        },
        {
            "name": "Gaziantep",
            "shortCode": "27",
            "translationKey": "Turkey_region_27"
        },
        {
            "name": "Giresun",
            "shortCode": "28",
            "translationKey": "Turkey_region_28"
        },
        {
            "name": "Gumushane",
            "shortCode": "29",
            "translationKey": "Turkey_region_29"
        },
        {
            "name": "Hakkari",
            "shortCode": "30",
            "translationKey": "Turkey_region_30"
        },
        {
            "name": "Hatay",
            "shortCode": "31",
            "translationKey": "Turkey_region_31"
        },
        {
            "name": "Igdir",
            "shortCode": "76",
            "translationKey": "Turkey_region_76"
        },
        {
            "name": "Isparta",
            "shortCode": "32",
            "translationKey": "Turkey_region_32"
        },
        {
            "name": "Istanbul",
            "shortCode": "34",
            "translationKey": "Turkey_region_34"
        },
        {
            "name": "Izmir",
            "shortCode": "35",
            "translationKey": "Turkey_region_35"
        },
        {
            "name": "Kahramanmaras",
            "shortCode": "46",
            "translationKey": "Turkey_region_46"
        },
        {
            "name": "Karabuk",
            "shortCode": "78",
            "translationKey": "Turkey_region_78"
        },
        {
            "name": "Karaman",
            "shortCode": "70",
            "translationKey": "Turkey_region_70"
        },
        {
            "name": "Kars",
            "shortCode": "36",
            "translationKey": "Turkey_region_36"
        },
        {
            "name": "Kastamonu",
            "shortCode": "37",
            "translationKey": "Turkey_region_37"
        },
        {
            "name": "Kayseri",
            "shortCode": "38",
            "translationKey": "Turkey_region_38"
        },
        {
            "name": "Kilis",
            "shortCode": "79",
            "translationKey": "Turkey_region_79"
        },
        {
            "name": "Kirikkale",
            "shortCode": "71",
            "translationKey": "Turkey_region_71"
        },
        {
            "name": "Kirklareli",
            "shortCode": "39",
            "translationKey": "Turkey_region_39"
        },
        {
            "name": "Kirsehir",
            "shortCode": "40",
            "translationKey": "Turkey_region_40"
        },
        {
            "name": "Kocaeli",
            "shortCode": "41",
            "translationKey": "Turkey_region_41"
        },
        {
            "name": "Konya",
            "shortCode": "42",
            "translationKey": "Turkey_region_42"
        },
        {
            "name": "Kutahya",
            "shortCode": "43",
            "translationKey": "Turkey_region_43"
        },
        {
            "name": "Malatya",
            "shortCode": "44",
            "translationKey": "Turkey_region_44"
        },
        {
            "name": "Manisa",
            "shortCode": "45",
            "translationKey": "Turkey_region_45"
        },
        {
            "name": "Mardin",
            "shortCode": "47",
            "translationKey": "Turkey_region_47"
        },
        {
            "name": "Mersin",
            "shortCode": "33",
            "translationKey": "Turkey_region_33"
        },
        {
            "name": "Mugla",
            "shortCode": "48",
            "translationKey": "Turkey_region_48"
        },
        {
            "name": "Mus",
            "shortCode": "49",
            "translationKey": "Turkey_region_49"
        },
        {
            "name": "Nevsehir",
            "shortCode": "50",
            "translationKey": "Turkey_region_50"
        },
        {
            "name": "Nigde",
            "shortCode": "51",
            "translationKey": "Turkey_region_51"
        },
        {
            "name": "Ordu",
            "shortCode": "52",
            "translationKey": "Turkey_region_52"
        },
        {
            "name": "Osmaniye",
            "shortCode": "80",
            "translationKey": "Turkey_region_80"
        },
        {
            "name": "Rize",
            "shortCode": "53",
            "translationKey": "Turkey_region_53"
        },
        {
            "name": "Sakarya",
            "shortCode": "54",
            "translationKey": "Turkey_region_54"
        },
        {
            "name": "Samsun",
            "shortCode": "55",
            "translationKey": "Turkey_region_55"
        },
        {
            "name": "Sanliurfa",
            "shortCode": "63",
            "translationKey": "Turkey_region_63"
        },
        {
            "name": "Siirt",
            "shortCode": "56",
            "translationKey": "Turkey_region_56"
        },
        {
            "name": "Sinop",
            "shortCode": "57",
            "translationKey": "Turkey_region_57"
        },
        {
            "name": "Sirnak",
            "shortCode": "73",
            "translationKey": "Turkey_region_73"
        },
        {
            "name": "Sivas",
            "shortCode": "58",
            "translationKey": "Turkey_region_58"
        },
        {
            "name": "Tekirdag",
            "shortCode": "59",
            "translationKey": "Turkey_region_59"
        },
        {
            "name": "Tokat",
            "shortCode": "60",
            "translationKey": "Turkey_region_60"
        },
        {
            "name": "Trabzon",
            "shortCode": "61",
            "translationKey": "Turkey_region_61"
        },
        {
            "name": "Tunceli",
            "shortCode": "62",
            "translationKey": "Turkey_region_62"
        },
        {
            "name": "Usak",
            "shortCode": "64",
            "translationKey": "Turkey_region_64"
        },
        {
            "name": "Van",
            "shortCode": "65",
            "translationKey": "Turkey_region_65"
        },
        {
            "name": "Yalova",
            "shortCode": "77",
            "translationKey": "Turkey_region_77"
        },
        {
            "name": "Yozgat",
            "shortCode": "66",
            "translationKey": "Turkey_region_66"
        },
        {
            "name": "Zonguldak",
            "shortCode": "67",
            "translationKey": "Turkey_region_67"
        }
    ],
    "translationKey": "country_name_Turkey"
  },
  //   {
  //     "countryName": "Turkmenistan",
  //     "countryShortCode": "TM",
  //     "regions": [
  //       {
  //         "name": "Ahal",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "Asgabat",
  //         "shortCode": "S"
  //       },
  //       {
  //         "name": "Balkan",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Dashoguz",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "Lebap",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "Mary",
  //         "shortCode": "M"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Turks and Caicos Islands",
  //     "countryShortCode": "TC",
  //     "regions": [
  //       {
  //         "name": "Turks and Caicos Islands"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Tuvalu",
  //     "countryShortCode": "TV",
  //     "regions": [
  //       {
  //         "name": "Funafuti",
  //         "shortCode": "FUN"
  //       },
  //       {
  //         "name": "Nanumanga",
  //         "shortCode": "NMG"
  //       },
  //       {
  //         "name": "Nanumea",
  //         "shortCode": "NMA"
  //       },
  //       {
  //         "name": "Niutao",
  //         "shortCode": "NIT"
  //       },
  //       {
  //         "name": "Nui",
  //         "shortCode": "NUI"
  //       },
  //       {
  //         "name": "Nukufetau",
  //         "shortCode": "NKF"
  //       },
  //       {
  //         "name": "Nukulaelae",
  //         "shortCode": "NKL"
  //       },
  //       {
  //         "name": "Vaitupu",
  //         "shortCode": "VAU"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Uganda",
  //     "countryShortCode": "UG",
  //     "regions": [
  //       {
  //         "name": "Abim",
  //         "shortCode": "317"
  //       },
  //       {
  //         "name": "Adjumani",
  //         "shortCode": "301"
  //       },
  //       {
  //         "name": "Amolatar",
  //         "shortCode": "314"
  //       },
  //       {
  //         "name": "Amuria",
  //         "shortCode": "216"
  //       },
  //       {
  //         "name": "Amuru",
  //         "shortCode": "319"
  //       },
  //       {
  //         "name": "Apac",
  //         "shortCode": "302"
  //       },
  //       {
  //         "name": "Arua",
  //         "shortCode": "303"
  //       },
  //       {
  //         "name": "Budaka",
  //         "shortCode": "217"
  //       },
  //       {
  //         "name": "Bududa",
  //         "shortCode": "223"
  //       },
  //       {
  //         "name": "Bugiri",
  //         "shortCode": "201"
  //       },
  //       {
  //         "name": "Bukedea",
  //         "shortCode": "224"
  //       },
  //       {
  //         "name": "Bukwa",
  //         "shortCode": "218"
  //       },
  //       {
  //         "name": "Buliisa",
  //         "shortCode": "419"
  //       },
  //       {
  //         "name": "Bundibugyo",
  //         "shortCode": "401"
  //       },
  //       {
  //         "name": "Bushenyi",
  //         "shortCode": "402"
  //       },
  //       {
  //         "name": "Busia",
  //         "shortCode": "202"
  //       },
  //       {
  //         "name": "Butaleja",
  //         "shortCode": "219"
  //       },
  //       {
  //         "name": "Dokolo",
  //         "shortCode": "318"
  //       },
  //       {
  //         "name": "Gulu",
  //         "shortCode": "304"
  //       },
  //       {
  //         "name": "Hoima",
  //         "shortCode": "403"
  //       },
  //       {
  //         "name": "Ibanda",
  //         "shortCode": "416"
  //       },
  //       {
  //         "name": "Iganga",
  //         "shortCode": "203"
  //       },
  //       {
  //         "name": "Isingiro",
  //         "shortCode": "417"
  //       },
  //       {
  //         "name": "Jinja",
  //         "shortCode": "204"
  //       },
  //       {
  //         "name": "Kaabong",
  //         "shortCode": "315"
  //       },
  //       {
  //         "name": "Kabale",
  //         "shortCode": "404"
  //       },
  //       {
  //         "name": "Kabarole",
  //         "shortCode": "405"
  //       },
  //       {
  //         "name": "Kaberamaido",
  //         "shortCode": "213"
  //       },
  //       {
  //         "name": "Kalangala",
  //         "shortCode": "101"
  //       },
  //       {
  //         "name": "Kaliro",
  //         "shortCode": "220"
  //       },
  //       {
  //         "name": "Kampala",
  //         "shortCode": "102"
  //       },
  //       {
  //         "name": "Kamuli",
  //         "shortCode": "205"
  //       },
  //       {
  //         "name": "Kamwenge",
  //         "shortCode": "413"
  //       },
  //       {
  //         "name": "Kanungu",
  //         "shortCode": "414"
  //       },
  //       {
  //         "name": "Kapchorwa",
  //         "shortCode": "206"
  //       },
  //       {
  //         "name": "Kasese",
  //         "shortCode": "406"
  //       },
  //       {
  //         "name": "Katakwi",
  //         "shortCode": "207"
  //       },
  //       {
  //         "name": "Kayunga",
  //         "shortCode": "112"
  //       },
  //       {
  //         "name": "Kibaale",
  //         "shortCode": "407"
  //       },
  //       {
  //         "name": "Kiboga",
  //         "shortCode": "103"
  //       },
  //       {
  //         "name": "Kiruhura",
  //         "shortCode": "418"
  //       },
  //       {
  //         "name": "Kisoro",
  //         "shortCode": "408"
  //       },
  //       {
  //         "name": "Kitgum",
  //         "shortCode": "305"
  //       },
  //       {
  //         "name": "Koboko",
  //         "shortCode": "316"
  //       },
  //       {
  //         "name": "Kotido",
  //         "shortCode": "306"
  //       },
  //       {
  //         "name": "Kumi",
  //         "shortCode": "208"
  //       },
  //       {
  //         "name": "Kyenjojo",
  //         "shortCode": "415"
  //       },
  //       {
  //         "name": "Lira",
  //         "shortCode": "307"
  //       },
  //       {
  //         "name": "Luwero",
  //         "shortCode": "104"
  //       },
  //       {
  //         "name": "Lyantonde",
  //         "shortCode": "116"
  //       },
  //       {
  //         "name": "Manafwa",
  //         "shortCode": "221"
  //       },
  //       {
  //         "name": "Maracha",
  //         "shortCode": "320"
  //       },
  //       {
  //         "name": "Masaka",
  //         "shortCode": "105"
  //       },
  //       {
  //         "name": "Masindi",
  //         "shortCode": "409"
  //       },
  //       {
  //         "name": "Mayuge",
  //         "shortCode": "214"
  //       },
  //       {
  //         "name": "Mbale",
  //         "shortCode": "209"
  //       },
  //       {
  //         "name": "Mbarara",
  //         "shortCode": "410"
  //       },
  //       {
  //         "name": "Mityana",
  //         "shortCode": "114"
  //       },
  //       {
  //         "name": "Moroto",
  //         "shortCode": "308"
  //       },
  //       {
  //         "name": "Moyo",
  //         "shortCode": "309"
  //       },
  //       {
  //         "name": "Mpigi",
  //         "shortCode": "106"
  //       },
  //       {
  //         "name": "Mubende",
  //         "shortCode": "107"
  //       },
  //       {
  //         "name": "Mukono",
  //         "shortCode": "108"
  //       },
  //       {
  //         "name": "Nakapiripirit",
  //         "shortCode": "311"
  //       },
  //       {
  //         "name": "Nakaseke",
  //         "shortCode": "115"
  //       },
  //       {
  //         "name": "Nakasongola",
  //         "shortCode": "109"
  //       },
  //       {
  //         "name": "Namutumba",
  //         "shortCode": "222"
  //       },
  //       {
  //         "name": "Nebbi",
  //         "shortCode": "310"
  //       },
  //       {
  //         "name": "Ntungamo",
  //         "shortCode": "411"
  //       },
  //       {
  //         "name": "Oyam",
  //         "shortCode": "321"
  //       },
  //       {
  //         "name": "Pader",
  //         "shortCode": "312"
  //       },
  //       {
  //         "name": "Pallisa",
  //         "shortCode": "210"
  //       },
  //       {
  //         "name": "Rakai",
  //         "shortCode": "110"
  //       },
  //       {
  //         "name": "Rukungiri",
  //         "shortCode": "412"
  //       },
  //       {
  //         "name": "Sembabule",
  //         "shortCode": "111"
  //       },
  //       {
  //         "name": "Sironko",
  //         "shortCode": "215"
  //       },
  //       {
  //         "name": "Soroti",
  //         "shortCode": "211"
  //       },
  //       {
  //         "name": "Tororo",
  //         "shortCode": "212"
  //       },
  //       {
  //         "name": "Wakiso",
  //         "shortCode": "113"
  //       },
  //       {
  //         "name": "Yumbe",
  //         "shortCode": "313"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Ukraine",
  //     "countryShortCode": "UA",
  //     "regions": [
  //       {
  //         "name": "Cherkasy",
  //         "shortCode": "71"
  //       },
  //       {
  //         "name": "Chernihiv",
  //         "shortCode": "74"
  //       },
  //       {
  //         "name": "Chernivtsi",
  //         "shortCode": "77"
  //       },
  //       {
  //         "name": "Dnipropetrovsk",
  //         "shortCode": "12"
  //       },
  //       {
  //         "name": "Donetsk",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Ivano-Frankivsk",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Kharkiv",
  //         "shortCode": "63"
  //       },
  //       {
  //         "name": "Kherson",
  //         "shortCode": "65"
  //       },
  //       {
  //         "name": "Khmelnytskyi",
  //         "shortCode": "68"
  //       },
  //       {
  //         "name": "Kiev",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Kirovohrad",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Luhansk",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Lviv",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "Mykolaiv",
  //         "shortCode": "48"
  //       },
  //       {
  //         "name": "Odessa",
  //         "shortCode": "51"
  //       },
  //       {
  //         "name": "Poltava",
  //         "shortCode": "53"
  //       },
  //       {
  //         "name": "Rivne",
  //         "shortCode": "56"
  //       },
  //       {
  //         "name": "Sumy",
  //         "shortCode": "59"
  //       },
  //       {
  //         "name": "Ternopil",
  //         "shortCode": "61"
  //       },
  //       {
  //         "name": "Vinnytsia",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Volyn",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Zakarpattia",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Zaporizhia",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Zhytomyr",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Avtonomna Respublika Krym",
  //         "shortCode": "43"
  //       },
  //       {
  //         "name": "Kyïv",
  //         "shortCode": "30"
  //       },
  //       {
  //         "name": "Sevastopol",
  //         "shortCode": "40"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "United Arab Emirates",
  //     "countryShortCode": "AE",
  //     "regions": [
  //       {
  //         "name": "Abu Dhabi",
  //         "shortCode": "AZ"
  //       },
  //       {
  //         "name": "Ajman",
  //         "shortCode": "AJ"
  //       },
  //       {
  //         "name": "Dubai",
  //         "shortCode": "DU"
  //       },
  //       {
  //         "name": "Fujairah",
  //         "shortCode": "FU"
  //       },
  //       {
  //         "name": "Ras al Khaimah",
  //         "shortCode": "RK"
  //       },
  //       {
  //         "name": "Sharjah",
  //         "shortCode": "SH"
  //       },
  //       {
  //         "name": "Umm Al Quwain",
  //         "shortCode": "UQ"
  //       }
  //     ]
  //   },
  {
    "countryName": "United Kingdom",
    "translationKey": "country_name_uk",
    "countryShortCode": "GB",
    "regions": [
      {
        "name": "Aberdeen City",
        "translationKey": "uk_region_name_abe",
        "shortCode": "ABE"
      },
      {
        "name": "Aberdeenshire",
        "translationKey": "uk_region_name_abd",
        "shortCode": "ABD"
      },
      {
        "name": "Angus",
        "translationKey": "uk_region_name_ans",
        "shortCode": "ANS"
      },
      {
        "name": "Antrim and Newtownabbey",
        "translationKey": "uk_region_name_ann",
        "shortCode": "ANN"
      },
      {
        "name": "Ards and North Down",
        "translationKey": "uk_region_name_and",
        "shortCode": "AND"
      },
      {
        "name": "Argyll and Bute",
        "translationKey": "uk_region_name_agb",
        "shortCode": "AGB"
      },
      {
        "name": "Armagh, Banbridge and Craigavon",
        "translationKey": "uk_region_name_abc",
        "shortCode": "ABC"
      },
      {
        "name": "Barking and Dagenham",
        "translationKey": "uk_region_name_bdg",
        "shortCode": "BDG"
      },
      {
        "name": "Barnet",
        "translationKey": "uk_region_name_bne",
        "shortCode": "BNE"
      },
      {
        "name": "Barnsley",
        "translationKey": "uk_region_name_bns",
        "shortCode": "BNS"
      },
      {
        "name": "Bath and North East Somerset",
        "translationKey": "uk_region_name_bas",
        "shortCode": "BAS"
      },
      {
        "name": "Bedford",
        "translationKey": "uk_region_name_bdf",
        "shortCode": "BDF"
      },
      {
        "name": "Belfast",
        "translationKey": "uk_region_name_bfs",
        "shortCode": "BFS"
      },
      {
        "name": "Bexley",
        "translationKey": "uk_region_name_bex",
        "shortCode": "BEX"
      },
      {
        "name": "Birmingham",
        "translationKey": "uk_region_name_bir",
        "shortCode": "BIR"
      },
      {
        "name": "Blackburn with Darwen",
        "translationKey": "uk_region_name_bbd",
        "shortCode": "BBD"
      },
      {
        "name": "Blackpool",
        "translationKey": "uk_region_name_bpl",
        "shortCode": "BPL"
      },
      {
        "name": "Blaenau Gwent",
        "translationKey": "uk_region_name_bgw",
        "shortCode": "BGW"
      },
      {
        "name": "Bolton",
        "translationKey": "uk_region_name_bol",
        "shortCode": "BOL"
      },
      {
        "name": "Bournemouth",
        "translationKey": "uk_region_name_bmh",
        "shortCode": "BMH"
      },
      {
        "name": "Bracknell Forest",
        "translationKey": "uk_region_name_brc",
        "shortCode": "BRC"
      },
      {
        "name": "Bradford",
        "translationKey": "uk_region_name_brd",
        "shortCode": "BRD"
      },
      {
        "name": "Brent",
        "translationKey": "uk_region_name_ben",
        "shortCode": "BEN"
      },
      {
        "name": "Bridgend",
        "translationKey": "uk_region_name_bge",
        "shortCode": "BGE"
      },
      {
        "name": "Brighton and Hove",
        "translationKey": "uk_region_name_bnh",
        "shortCode": "BNH"
      },
      {
        "name": "Bristol, City of",
        "translationKey": "uk_region_name_bst",
        "shortCode": "BST"
      },
      {
        "name": "Bromley",
        "translationKey": "uk_region_name_bry",
        "shortCode": "BRY"
      },
      {
        "name": "Buckinghamshire",
        "translationKey": "uk_region_name_bkm",
        "shortCode": "BKM"
      },
      {
        "name": "Bury",
        "translationKey": "uk_region_name_bur",
        "shortCode": "BUR"
      },
      {
        "name": "Caerphilly",
        "translationKey": "uk_region_name_cay",
        "shortCode": "CAY"
      },
      {
        "name": "Calderdale",
        "translationKey": "uk_region_name_cld",
        "shortCode": "CLD"
      },
      {
        "name": "Cambridgeshire",
        "translationKey": "uk_region_name_cam",
        "shortCode": "CAM"
      },
      {
        "name": "Camden",
        "translationKey": "uk_region_name_cmd",
        "shortCode": "CMD"
      },
      {
        "name": "Cardiff",
        "translationKey": "uk_region_name_crf",
        "shortCode": "CRF"
      },
      {
        "name": "Carmarthenshire",
        "translationKey": "uk_region_name_cmn",
        "shortCode": "CMN"
      },
      {
        "name": "Causeway Coast and Glens",
        "translationKey": "uk_region_name_ccg",
        "shortCode": "CCG"
      },
      {
        "name": "Central Bedfordshire",
        "translationKey": "uk_region_name_cbf",
        "shortCode": "CBF"
      },
      {
        "name": "Ceredigion",
        "translationKey": "uk_region_name_cgn",
        "shortCode": "CGN"
      },
      {
        "name": "Cheshire East",
        "translationKey": "uk_region_name_che",
        "shortCode": "CHE"
      },
      {
        "name": "Cheshire West and Chester",
        "translationKey": "uk_region_name_chw",
        "shortCode": "CHW"
      },
      {
        "name": "Clackmannanshire",
        "translationKey": "uk_region_name_clk",
        "shortCode": "CLK"
      },
      {
        "name": "Conwy",
        "translationKey": "uk_region_name_cwy",
        "shortCode": "CWY"
      },
      {
        "name": "Cornwall",
        "translationKey": "uk_region_name_con",
        "shortCode": "CON"
      },
      {
        "name": "Coventry",
        "translationKey": "uk_region_name_cov",
        "shortCode": "COV"
      },
      {
        "name": "Croydon",
        "translationKey": "uk_region_name_cry",
        "shortCode": "CRY"
      },
      {
        "name": "Cumbria",
        "translationKey": "uk_region_name_cma",
        "shortCode": "CMA"
      },
      {
        "name": "Darlington",
        "translationKey": "uk_region_name_dal",
        "shortCode": "DAL"
      },
      {
        "name": "Denbighshire",
        "translationKey": "uk_region_name_den",
        "shortCode": "DEN"
      },
      {
        "name": "Derby",
        "translationKey": "uk_region_name_der",
        "shortCode": "DER"
      },
      {
        "name": "Derbyshire",
        "translationKey": "uk_region_name_dby",
        "shortCode": "DBY"
      },
      {
        "name": "Derry and Strabane",
        "translationKey": "uk_region_name_drs",
        "shortCode": "DRS"
      },
      {
        "name": "Devon",
        "translationKey": "uk_region_name_dev",
        "shortCode": "DEV"
      },
      {
        "name": "Doncaster",
        "translationKey": "uk_region_name_dnc",
        "shortCode": "DNC"
      },
      {
        "name": "Dorset",
        "translationKey": "uk_region_name_dor",
        "shortCode": "DOR"
      },
      {
        "name": "Dudley",
        "translationKey": "uk_region_name_dud",
        "shortCode": "DUD"
      },
      {
        "name": "Dumfries and Galloway",
        "translationKey": "uk_region_name_dgy",
        "shortCode": "DGY"
      },
      {
        "name": "Dundee City",
        "translationKey": "uk_region_name_dnd",
        "shortCode": "DND"
      },
      {
        "name": "Durham County",
        "translationKey": "uk_region_name_dur",
        "shortCode": "DUR"
      },
      {
        "name": "Ealing",
        "translationKey": "uk_region_name_eal",
        "shortCode": "EAL"
      },
      {
        "name": "East Ayrshire",
        "translationKey": "uk_region_name_eay",
        "shortCode": "EAY"
      },
      {
        "name": "East Dunbartonshire",
        "translationKey": "uk_region_name_edu",
        "shortCode": "EDU"
      },
      {
        "name": "East Lothian",
        "translationKey": "uk_region_name_eln",
        "shortCode": "ELN"
      },
      {
        "name": "East Renfrewshire",
        "translationKey": "uk_region_name_erw",
        "shortCode": "ERW"
      },
      {
        "name": "East Riding of Yorkshire",
        "translationKey": "uk_region_name_wry",
        "shortCode": "ERY"
      },
      {
        "name": "East Sussex",
        "translationKey": "uk_region_name_esx",
        "shortCode": "ESX"
      },
      {
        "name": "Edinburgh, City of",
        "translationKey": "uk_region_name_edh",
        "shortCode": "EDH"
      },
      {
        "name": "Eilean Siar",
        "translationKey": "uk_region_name_els",
        "shortCode": "ELS"
      },
      {
        "name": "Enfield",
        "translationKey": "uk_region_name_enf",
        "shortCode": "ENF"
      },
      {
        "name": "Essex",
        "translationKey": "uk_region_name_ess",
        "shortCode": "ESS"
      },
      {
        "name": "Falkirk",
        "translationKey": "uk_region_name_fal",
        "shortCode": "FAL"
      },
      {
        "name": "Fermanagh and Omagh",
        "translationKey": "uk_region_name_fmo",
        "shortCode": "FMO"
      },
      {
        "name": "Fife",
        "translationKey": "uk_region_name_fif",
        "shortCode": "FIF"
      },
      {
        "name": "Flintshire",
        "translationKey": "uk_region_name_fln",
        "shortCode": "FLN"
      },
      {
        "name": "Gateshead",
        "translationKey": "uk_region_name_gat",
        "shortCode": "GAT"
      },
      {
        "name": "Glasgow City",
        "translationKey": "uk_region_name_glg",
        "shortCode": "GLG"
      },
      {
        "name": "Gloucestershire",
        "translationKey": "uk_region_name_gls",
        "shortCode": "GLS"
      },
      {
        "name": "Greenwich",
        "translationKey": "uk_region_name_gre",
        "shortCode": "GRE"
      },
      {
        "name": "Gwynedd",
        "translationKey": "uk_region_name_gwn",
        "shortCode": "GWN"
      },
      {
        "name": "Hackney",
        "translationKey": "uk_region_name_hck",
        "shortCode": "HCK"
      },
      {
        "name": "Halton",
        "translationKey": "uk_region_name_hal",
        "shortCode": "HAL"
      },
      {
        "name": "Hammersmith and Fulham",
        "translationKey": "uk_region_name_hmf",
        "shortCode": "HMF"
      },
      {
        "name": "Hampshire",
        "translationKey": "uk_region_name_ham",
        "shortCode": "HAM"
      },
      {
        "name": "Haringey",
        "translationKey": "uk_region_name_hry",
        "shortCode": "HRY"
      },
      {
        "name": "Harrow",
        "translationKey": "uk_region_name_hrw",
        "shortCode": "HRW"
      },
      {
        "name": "Hartlepool",
        "translationKey": "uk_region_name_hpl",
        "shortCode": "HPL"
      },
      {
        "name": "Havering",
        "translationKey": "uk_region_name_hav",
        "shortCode": "HAV"
      },
      {
        "name": "Herefordshire",
        "translationKey": "uk_region_name_hef",
        "shortCode": "HEF"
      },
      {
        "name": "Hertfordshire",
        "translationKey": "uk_region_name_hrt",
        "shortCode": "HRT"
      },
      {
        "name": "Highland",
        "translationKey": "uk_region_name_hld",
        "shortCode": "HLD"
      },
      {
        "name": "Hillingdon",
        "translationKey": "uk_region_name_hil",
        "shortCode": "HIL"
      },
      {
        "name": "Hounslow",
        "translationKey": "uk_region_name_hns",
        "shortCode": "HNS"
      },
      {
        "name": "Inverclyde",
        "translationKey": "uk_region_name_ivc",
        "shortCode": "IVC"
      },
      {
        "name": "Isle of Anglesey",
        "translationKey": "uk_region_name_agy",
        "shortCode": "AGY"
      },
      {
        "name": "Isle of Wight",
        "translationKey": "uk_region_name_iow",
        "shortCode": "IOW"
      },
      {
        "name": "Isles of Scilly",
        "translationKey": "uk_region_name_ios",
        "shortCode": "IOS"
      },
      {
        "name": "Islington",
        "translationKey": "uk_region_name_isl",
        "shortCode": "ISL"
      },
      {
        "name": "Kensington and Chelsea",
        "translationKey": "uk_region_name_kec",
        "shortCode": "KEC"
      },
      {
        "name": "Kent",
        "translationKey": "uk_region_name_ken",
        "shortCode": "KEN"
      },
      {
        "name": "Kingston upon Hull",
        "translationKey": "uk_region_name_khl",
        "shortCode": "KHL"
      },
      {
        "name": "Kingston upon Thames",
        "translationKey": "uk_region_name_ktt",
        "shortCode": "KTT"
      },
      {
        "name": "Kirklees",
        "translationKey": "uk_region_name_kir",
        "shortCode": "KIR"
      },
      {
        "name": "Knowsley",
        "translationKey": "uk_region_name_kwl",
        "shortCode": "KWL"
      },
      {
        "name": "Lambeth",
        "translationKey": "uk_region_name_lbh",
        "shortCode": "LBH"
      },
      {
        "name": "Lancashire",
        "translationKey": "uk_region_name_lan",
        "shortCode": "LAN"
      },
      {
        "name": "Leeds",
        "translationKey": "uk_region_name_lds",
        "shortCode": "LDS"
      },
      {
        "name": "Leicester",
        "translationKey": "uk_region_name_lce",
        "shortCode": "LCE"
      },
      {
        "name": "Leicestershire",
        "translationKey": "uk_region_name_lec",
        "shortCode": "LEC"
      },
      {
        "name": "Lewisham",
        "translationKey": "uk_region_name_lew",
        "shortCode": "LEW"
      },
      {
        "name": "Lincolnshire",
        "translationKey": "uk_region_name_lin",
        "shortCode": "LIN"
      },
      {
        "name": "Lisburn and Castlereagh",
        "translationKey": "uk_region_name_lbc",
        "shortCode": "LBC"
      },
      {
        "name": "Liverpool",
        "translationKey": "uk_region_name_liv",
        "shortCode": "LIV"
      },
      {
        "name": "London, City of",
        "translationKey": "uk_region_name_lnd",
        "shortCode": "LND"
      },
      {
        "name": "Luton",
        "translationKey": "uk_region_name_lut",
        "shortCode": "LUT"
      },
      {
        "name": "Manchester",
        "translationKey": "uk_region_name_man",
        "shortCode": "MAN"
      },
      {
        "name": "Medway",
        "translationKey": "uk_region_name_mdw",
        "shortCode": "MDW"
      },
      {
        "name": "Merthyr Tydfil",
        "translationKey": "uk_region_name_mty",
        "shortCode": "MTY"
      },
      {
        "name": "Merton",
        "translationKey": "uk_region_name_mrt",
        "shortCode": "MRT"
      },
      {
        "name": "Mid and East Antrim",
        "translationKey": "uk_region_name_mea",
        "shortCode": "MEA"
      },
      {
        "name": "Mid Ulster",
        "translationKey": "uk_region_name_mul",
        "shortCode": "MUL"
      },
      {
        "name": "Middlesbrough",
        "translationKey": "uk_region_name_mdb",
        "shortCode": "MDB"
      },
      {
        "name": "Midlothian",
        "translationKey": "uk_region_name_mln",
        "shortCode": "MLN"
      },
      {
        "name": "Milton Keynes",
        "translationKey": "uk_region_name_mik",
        "shortCode": "MIK"
      },
      {
        "name": "Monmouthshire",
        "translationKey": "uk_region_name_mon",
        "shortCode": "MON"
      },
      {
        "name": "Moray",
        "translationKey": "uk_region_name_mry",
        "shortCode": "MRY"
      },
      {
        "name": "Neath Port Talbot",
        "translationKey": "uk_region_name_ntl",
        "shortCode": "NTL"
      },
      {
        "name": "Newcastle upon Tyne",
        "translationKey": "uk_region_name_net",
        "shortCode": "NET"
      },
      {
        "name": "Newham",
        "translationKey": "uk_region_name_nwm",
        "shortCode": "NWM"
      },
      {
        "name": "Newport",
        "translationKey": "uk_region_name_nwp",
        "shortCode": "NWP"
      },
      {
        "name": "Newry, Mourne and Down",
        "translationKey": "uk_region_name_nmd",
        "shortCode": "NMD"
      },
      {
        "name": "Norfolk",
        "translationKey": "uk_region_name_nfk",
        "shortCode": "NFK"
      },
      {
        "name": "North Ayrshire",
        "translationKey": "uk_region_name_nay",
        "shortCode": "NAY"
      },
      {
        "name": "North East Lincolnshire",
        "translationKey": "uk_region_name_nel",
        "shortCode": "NEL"
      },
      {
        "name": "North Lanarkshire",
        "translationKey": "uk_region_name_nlk",
        "shortCode": "NLK"
      },
      {
        "name": "North Lincolnshire",
        "translationKey": "uk_region_name_nln",
        "shortCode": "NLN"
      },
      {
        "name": "North Somerset",
        "translationKey": "uk_region_name_nsm",
        "shortCode": "NSM"
      },
      {
        "name": "North Tyneside",
        "translationKey": "uk_region_name_nty",
        "shortCode": "NTY"
      },
      {
        "name": "North Yorkshire",
        "translationKey": "uk_region_name_nyk",
        "shortCode": "NYK"
      },
      {
        "name": "Northamptonshire",
        "translationKey": "uk_region_name_nth",
        "shortCode": "NTH"
      },
      {
        "name": "Northern Ireland",
        "translationKey": "uk_region_name_nir",
        "shortCode": "NIR"
      },
      {
        "name": "Northumberland",
        "translationKey": "uk_region_name_nbl",
        "shortCode": "NBL"
      },
      {
        "name": "Nottingham",
        "translationKey": "uk_region_name_ngm",
        "shortCode": "NGM"
      },
      {
        "name": "Nottinghamshire",
        "translationKey": "uk_region_name_ntt",
        "shortCode": "NTT"
      },
      {
        "name": "Oldham",
        "translationKey": "uk_region_name_old",
        "shortCode": "OLD"
      },
      {
        "name": "Orkney Islands",
        "translationKey": "uk_region_name_ork",
        "shortCode": "ORK"
      },
      {
        "name": "Oxfordshire",
        "translationKey": "uk_region_name_oxf",
        "shortCode": "OXF"
      },
      {
        "name": "Pembrokeshire",
        "translationKey": "uk_region_name_pem",
        "shortCode": "PEM"
      },
      {
        "name": "Perth and Kinross",
        "translationKey": "uk_region_name_pkn",
        "shortCode": "PKN"
      },
      {
        "name": "Peterborough",
        "translationKey": "uk_region_name_pte",
        "shortCode": "PTE"
      },
      {
        "name": "Plymouth",
        "translationKey": "uk_region_name_ply",
        "shortCode": "PLY"
      },
      {
        "name": "Poole",
        "translationKey": "uk_region_name_pol",
        "shortCode": "POL"
      },
      {
        "name": "Portsmouth",
        "translationKey": "uk_region_name_por",
        "shortCode": "POR"
      },
      {
        "name": "Powys",
        "translationKey": "uk_region_name_pow",
        "shortCode": "POW"
      },
      {
        "name": "Reading",
        "translationKey": "uk_region_name_rdg",
        "shortCode": "RDG"
      },
      {
        "name": "Redbridge",
        "translationKey": "uk_region_name_rdb",
        "shortCode": "RDB"
      },
      {
        "name": "Redcar and Cleveland",
        "translationKey": "uk_region_name_rcc",
        "shortCode": "RCC"
      },
      {
        "name": "Renfrewshire",
        "translationKey": "uk_region_name_rfw",
        "shortCode": "RFW"
      },
      {
        "name": "Rhondda, Cynon, Taff",
        "translationKey": "uk_region_name_rct",
        "shortCode": "RCT"
      },
      {
        "name": "Richmond upon Thames",
        "translationKey": "uk_region_name_ric",
        "shortCode": "RIC"
      },
      {
        "name": "Rochdale",
        "translationKey": "uk_region_name_rch",
        "shortCode": "RCH"
      },
      {
        "name": "Rotherham",
        "translationKey": "uk_region_name_rot",
        "shortCode": "ROT"
      },
      {
        "name": "Rutland",
        "translationKey": "uk_region_name_rut",
        "shortCode": "RUT"
      },
      {
        "name": "St. Helens",
        "translationKey": "uk_region_name_shn",
        "shortCode": "SHN"
      },
      {
        "name": "Salford",
        "translationKey": "uk_region_name_slf",
        "shortCode": "SLF"
      },
      {
        "name": "Sandwell",
        "translationKey": "uk_region_name_saw",
        "shortCode": "SAW"
      },
      {
        "name": "Scottish Borders, The",
        "translationKey": "uk_region_name_scb",
        "shortCode": "SCB"
      },
      {
        "name": "Sefton",
        "translationKey": "uk_region_name_sft",
        "shortCode": "SFT"
      },
      {
        "name": "Sheffield",
        "translationKey": "uk_region_name_shf",
        "shortCode": "SHF"
      },
      {
        "name": "Shetland Islands",
        "translationKey": "uk_region_name_zet",
        "shortCode": "ZET"
      },
      {
        "name": "Shropshire",
        "translationKey": "uk_region_name_shr",
        "shortCode": "SHR"
      },
      {
        "name": "Slough",
        "translationKey": "uk_region_name_slg",
        "shortCode": "SLG"
      },
      {
        "name": "Solihull",
        "translationKey": "uk_region_name_sol",
        "shortCode": "SOL"
      },
      {
        "name": "Somerset",
        "translationKey": "uk_region_name_som",
        "shortCode": "SOM"
      },
      {
        "name": "South Ayrshire",
        "translationKey": "uk_region_name_say",
        "shortCode": "SAY"
      },
      {
        "name": "South Gloucestershire",
        "translationKey": "uk_region_name_sgc",
        "shortCode": "SGC"
      },
      {
        "name": "South Lanarkshire",
        "translationKey": "uk_region_name_slk",
        "shortCode": "SLK"
      },
      {
        "name": "South Tyneside",
        "translationKey": "uk_region_name_sty",
        "shortCode": "STY"
      },
      {
        "name": "Southampton",
        "translationKey": "uk_region_name_sth",
        "shortCode": "STH"
      },
      {
        "name": "Southend-on-Sea",
        "translationKey": "uk_region_name_sos",
        "shortCode": "SOS"
      },
      {
        "name": "Southwark",
        "translationKey": "uk_region_name_swk",
        "shortCode": "SWK"
      },
      {
        "name": "Staffordshire",
        "translationKey": "uk_region_name_sts",
        "shortCode": "STS"
      },
      {
        "name": "Stirling",
        "translationKey": "uk_region_name_stg",
        "shortCode": "STG"
      },
      {
        "name": "Stockport",
        "translationKey": "uk_region_name_skp",
        "shortCode": "SKP"
      },
      {
        "name": "Stockton-on-Tees",
        "translationKey": "uk_region_name_stt",
        "shortCode": "STT"
      },
      {
        "name": "Stoke-on-Trent",
        "translationKey": "uk_region_name_ste",
        "shortCode": "STE"
      },
      {
        "name": "Suffolk",
        "translationKey": "uk_region_name_sfk",
        "shortCode": "SFK"
      },
      {
        "name": "Sunderland",
        "translationKey": "uk_region_name_snd",
        "shortCode": "SND"
      },
      {
        "name": "Surrey",
        "translationKey": "uk_region_name_sry",
        "shortCode": "SRY"
      },
      {
        "name": "Sutton",
        "translationKey": "uk_region_name_stn",
        "shortCode": "STN"
      },
      {
        "name": "Swansea",
        "translationKey": "uk_region_name_swa",
        "shortCode": "SWA"
      },
      {
        "name": "Swindon",
        "translationKey": "uk_region_name_swd",
        "shortCode": "SWD"
      },
      {
        "name": "Tameside",
        "translationKey": "uk_region_name_tam",
        "shortCode": "TAM"
      },
      {
        "name": "Telford and Wrekin",
        "translationKey": "uk_region_name_tfw",
        "shortCode": "TFW"
      },
      {
        "name": "Thurrock",
        "translationKey": "uk_region_name_thr",
        "shortCode": "THR"
      },
      {
        "name": "Torbay",
        "translationKey": "uk_region_name_tob",
        "shortCode": "TOB"
      },
      {
        "name": "Torfaen",
        "translationKey": "uk_region_name_tof",
        "shortCode": "TOF"
      },
      {
        "name": "Tower Hamlets",
        "translationKey": "uk_region_name_twh",
        "shortCode": "TWH"
      },
      {
        "name": "Trafford",
        "translationKey": "uk_region_name_trf",
        "shortCode": "TRF"
      },
      {
        "name": "Vale of Glamorgan, The",
        "translationKey": "uk_region_name_vgl",
        "shortCode": "VGL"
      },
      {
        "name": "Wakefield",
        "translationKey": "uk_region_name_wkf",
        "shortCode": "WKF"
      },
      {
        "name": "Walsall",
        "translationKey": "uk_region_name_wll",
        "shortCode": "WLL"
      },
      {
        "name": "Waltham Forest",
        "translationKey": "uk_region_name_wft",
        "shortCode": "WFT"
      },
      {
        "name": "Wandsworth",
        "translationKey": "uk_region_name_wns",
        "shortCode": "WND"
      },
      {
        "name": "Warrington",
        "translationKey": "uk_region_name_wrt",
        "shortCode": "WRT"
      },
      {
        "name": "Warwickshire",
        "translationKey": "uk_region_name_war",
        "shortCode": "WAR"
      },
      {
        "name": "West Berkshire",
        "translationKey": "uk_region_name_wbk",
        "shortCode": "WBK"
      },
      {
        "name": "West Dunbartonshire",
        "translationKey": "uk_region_name_wdu",
        "shortCode": "WDU"
      },
      {
        "name": "West Lothian",
        "translationKey": "uk_region_name_wln",
        "shortCode": "WLN"
      },
      {
        "name": "West Sussex",
        "translationKey": "uk_region_name_wsx",
        "shortCode": "WSX"
      },
      {
        "name": "Westminster",
        "translationKey": "uk_region_name_wsm",
        "shortCode": "WSM"
      },
      {
        "name": "Wigan",
        "translationKey": "uk_region_name_wgn",
        "shortCode": "WGN"
      },
      {
        "name": "Wiltshire",
        "translationKey": "uk_region_name_wil",
        "shortCode": "WIL"
      },
      {
        "name": "Windsor and Maidenhead",
        "translationKey": "uk_region_name_wnm",
        "shortCode": "WNM"
      },
      {
        "name": "Wirral",
        "translationKey": "uk_region_name_wrl",
        "shortCode": "WRL"
      },
      {
        "name": "Wokingham",
        "translationKey": "uk_region_name_wok",
        "shortCode": "WOK"
      },
      {
        "name": "Wolverhampton",
        "translationKey": "uk_region_name_wlv",
        "shortCode": "WLV"
      },
      {
        "name": "Worcestershire",
        "translationKey": "uk_region_name_wor",
        "shortCode": "WOR"
      },
      {
        "name": "Wrexham",
        "translationKey": "uk_region_name_wrx",
        "shortCode": "WRX"
      },
      {
        "name": "York",
        "translationKey": "uk_region_name_yor",
        "shortCode": "YOR"
      }
    ]
  },
  
  //   {
  //     "countryName": "United States Minor Outlying Islands",
  //     "countryShortCode": "UM",
  //     "regions": [
  //       {
  //         "name": "Baker Island",
  //         "shortCode": "81"
  //       },
  //       {
  //         "name": "Howland Island",
  //         "shortCode": "84"
  //       },
  //       {
  //         "name": "Jarvis Island",
  //         "shortCode": "86"
  //       },
  //       {
  //         "name": "Johnston Atoll",
  //         "shortCode": "67"
  //       },
  //       {
  //         "name": "Kingman Reef",
  //         "shortCode": "89"
  //       },
  //       {
  //         "name": "Midway Islands",
  //         "shortCode": "71"
  //       },
  //       {
  //         "name": "Navassa Island",
  //         "shortCode": "76"
  //       },
  //       {
  //         "name": "Palmyra Atoll",
  //         "shortCode": "95"
  //       },
  //       {
  //         "name": "Wake Island",
  //         "shortCode": "79"
  //       },
  //       {
  //         "name": "Bajo Nuevo Bank",
  //         "shortCode": "BN"
  //       },
  //       {
  //         "name": "Serranilla Bank",
  //         "shortCode": "SB"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Uruguay",
  //     "countryShortCode": "UY",
  //     "regions": [
  //       {
  //         "name": "Artigas",
  //         "shortCode": "AR"
  //       },
  //       {
  //         "name": "Canelones",
  //         "shortCode": "CA"
  //       },
  //       {
  //         "name": "Cerro Largo",
  //         "shortCode": "CL"
  //       },
  //       {
  //         "name": "Colonia",
  //         "shortCode": "CO"
  //       },
  //       {
  //         "name": "Durazno",
  //         "shortCode": "DU"
  //       },
  //       {
  //         "name": "Flores",
  //         "shortCode": "FS"
  //       },
  //       {
  //         "name": "Florida",
  //         "shortCode": "FD"
  //       },
  //       {
  //         "name": "Lavalleja",
  //         "shortCode": "LA"
  //       },
  //       {
  //         "name": "Maldonado",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Montevideo",
  //         "shortCode": "MO"
  //       },
  //       {
  //         "name": "Paysandú",
  //         "shortCode": "PA"
  //       },
  //       {
  //         "name": "Río Negro",
  //         "shortCode": "RN"
  //       },
  //       {
  //         "name": "Rivera",
  //         "shortCode": "RV"
  //       },
  //       {
  //         "name": "Rocha",
  //         "shortCode": "RO"
  //       },
  //       {
  //         "name": "Salto",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "San José",
  //         "shortCode": "SJ"
  //       },
  //       {
  //         "name": "Soriano",
  //         "shortCode": "SO"
  //       },
  //       {
  //         "name": "Tacuarembó",
  //         "shortCode": "TA"
  //       },
  //       {
  //         "name": "Treinta y Tres",
  //         "shortCode": "TT"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Uzbekistan",
  //     "countryShortCode": "UZ",
  //     "regions": [
  //       {
  //         "name": "Toshkent shahri",
  //         "shortCode": "TK"
  //       },
  //       {
  //         "name": "Andijon",
  //         "shortCode": "AN"
  //       },
  //       {
  //         "name": "Buxoro",
  //         "shortCode": "BU"
  //       },
  //       {
  //         "name": "Farg‘ona",
  //         "shortCode": "FA"
  //       },
  //       {
  //         "name": "Jizzax",
  //         "shortCode": "JI"
  //       },
  //       {
  //         "name": "Namangan",
  //         "shortCode": "NG"
  //       },
  //       {
  //         "name": "Navoiy",
  //         "shortCode": "NW"
  //       },
  //       {
  //         "name": "Qashqadaryo (Qarshi)",
  //         "shortCode": "QA"
  //       },
  //       {
  //         "name": "Samarqand",
  //         "shortCode": "SA"
  //       },
  //       {
  //         "name": "Sirdaryo (Guliston)",
  //         "shortCode": "SI"
  //       },
  //       {
  //         "name": "Surxondaryo (Termiz)",
  //         "shortCode": "SU"
  //       },
  //       {
  //         "name": "Toshkent wiloyati",
  //         "shortCode": "TO"
  //       },
  //       {
  //         "name": "Xorazm (Urganch)",
  //         "shortCode": "XO"
  //       },
  //       {
  //         "name": "Qoraqalpog‘iston Respublikasi (Nukus)",
  //         "shortCode": "QR"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Vanuatu",
  //     "countryShortCode": "VU",
  //     "regions": [
  //       {
  //         "name": "Malampa",
  //         "shortCode": "MAP"
  //       },
  //       {
  //         "name": "Pénama",
  //         "shortCode": "PAM"
  //       },
  //       {
  //         "name": "Sanma",
  //         "shortCode": "SAM"
  //       },
  //       {
  //         "name": "Shéfa",
  //         "shortCode": "SEE"
  //       },
  //       {
  //         "name": "Taféa",
  //         "shortCode": "TAE"
  //       },
  //       {
  //         "name": "Torba",
  //         "shortCode": "TOB"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Venezuela, Bolivarian Republic of",
  //     "countryShortCode": "VE",
  //     "regions": [
  //       {
  //         "name": "Dependencias Federales",
  //         "shortCode": "W"
  //       },
  //       {
  //         "name": "Distrito Federal",
  //         "shortCode": "A"
  //       },
  //       {
  //         "name": "Amazonas",
  //         "shortCode": "Z"
  //       },
  //       {
  //         "name": "Anzoátegui",
  //         "shortCode": "B"
  //       },
  //       {
  //         "name": "Apure",
  //         "shortCode": "C"
  //       },
  //       {
  //         "name": "Aragua",
  //         "shortCode": "D"
  //       },
  //       {
  //         "name": "Barinas",
  //         "shortCode": "E"
  //       },
  //       {
  //         "name": "Bolívar",
  //         "shortCode": "F"
  //       },
  //       {
  //         "name": "Carabobo",
  //         "shortCode": "G"
  //       },
  //       {
  //         "name": "Cojedes",
  //         "shortCode": "H"
  //       },
  //       {
  //         "name": "Delta Amacuro",
  //         "shortCode": "Y"
  //       },
  //       {
  //         "name": "Falcón",
  //         "shortCode": "I"
  //       },
  //       {
  //         "name": "Guárico",
  //         "shortCode": "J"
  //       },
  //       {
  //         "name": "Lara",
  //         "shortCode": "K"
  //       },
  //       {
  //         "name": "Mérida",
  //         "shortCode": "L"
  //       },
  //       {
  //         "name": "Miranda",
  //         "shortCode": "M"
  //       },
  //       {
  //         "name": "Monagas",
  //         "shortCode": "N"
  //       },
  //       {
  //         "name": "Nueva Esparta",
  //         "shortCode": "O"
  //       },
  //       {
  //         "name": "Portuguesa",
  //         "shortCode": "P"
  //       },
  //       {
  //         "name": "Sucre",
  //         "shortCode": "R"
  //       },
  //       {
  //         "name": "Táchira",
  //         "shortCode": "S"
  //       },
  //       {
  //         "name": "Trujillo",
  //         "shortCode": "T"
  //       },
  //       {
  //         "name": "Vargas",
  //         "shortCode": "X"
  //       },
  //       {
  //         "name": "Yaracuy",
  //         "shortCode": "U"
  //       },
  //       {
  //         "name": "Zulia",
  //         "shortCode": "V"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Vietnam",
  //     "countryShortCode": "VN",
  //     "regions": [
  //       {
  //         "name": "Đồng Nai",
  //         "shortCode": "39"
  //       },
  //       {
  //         "name": "Đồng Tháp",
  //         "shortCode": "45"
  //       },
  //       {
  //         "name": "Gia Lai",
  //         "shortCode": "30"
  //       },
  //       {
  //         "name": "Hà Giang",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Hà Nam",
  //         "shortCode": "63"
  //       },
  //       {
  //         "name": "Hà Tây",
  //         "shortCode": "15"
  //       },
  //       {
  //         "name": "Hà Tĩnh",
  //         "shortCode": "23"
  //       },
  //       {
  //         "name": "Hải Dương",
  //         "shortCode": "61"
  //       },
  //       {
  //         "name": "Hậu Giang",
  //         "shortCode": "73"
  //       },
  //       {
  //         "name": "Hòa Bình",
  //         "shortCode": "14"
  //       },
  //       {
  //         "name": "Hưng Yên",
  //         "shortCode": "66"
  //       },
  //       {
  //         "name": "Khánh Hòa",
  //         "shortCode": "34"
  //       },
  //       {
  //         "name": "Kiên Giang",
  //         "shortCode": "47"
  //       },
  //       {
  //         "name": "Kon Tum",
  //         "shortCode": "28"
  //       },
  //       {
  //         "name": "Lai Châu",
  //         "shortCode": "01"
  //       },
  //       {
  //         "name": "Lâm Đồng",
  //         "shortCode": "35"
  //       },
  //       {
  //         "name": "Lạng Sơn",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Lào Cai",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Long An",
  //         "shortCode": "41"
  //       },
  //       {
  //         "name": "Nam Định",
  //         "shortCode": "67"
  //       },
  //       {
  //         "name": "Nghệ An",
  //         "shortCode": "22"
  //       },
  //       {
  //         "name": "Ninh Bình",
  //         "shortCode": "18"
  //       },
  //       {
  //         "name": "Ninh Thuận",
  //         "shortCode": "36"
  //       },
  //       {
  //         "name": "Phú Thọ",
  //         "shortCode": "68"
  //       },
  //       {
  //         "name": "Phú Yên",
  //         "shortCode": "32"
  //       },
  //       {
  //         "name": "Quảng Bình",
  //         "shortCode": "24"
  //       },
  //       {
  //         "name": "Quảng Nam",
  //         "shortCode": "27"
  //       },
  //       {
  //         "name": "Quảng Ngãi",
  //         "shortCode": "29"
  //       },
  //       {
  //         "name": "Quảng Ninh",
  //         "shortCode": "13"
  //       },
  //       {
  //         "name": "Quảng Trị",
  //         "shortCode": "25"
  //       },
  //       {
  //         "name": "Sóc Trăng",
  //         "shortCode": "52"
  //       },
  //       {
  //         "name": "Sơn La",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "Tây Ninh",
  //         "shortCode": "37"
  //       },
  //       {
  //         "name": "Thái Bình",
  //         "shortCode": "20"
  //       },
  //       {
  //         "name": "Thái Nguyên",
  //         "shortCode": "69"
  //       },
  //       {
  //         "name": "Thanh Hóa",
  //         "shortCode": "21"
  //       },
  //       {
  //         "name": "Thừa Thiên–Huế",
  //         "shortCode": "26"
  //       },
  //       {
  //         "name": "Tiền Giang",
  //         "shortCode": "46"
  //       },
  //       {
  //         "name": "Trà Vinh",
  //         "shortCode": "51"
  //       },
  //       {
  //         "name": "Tuyên Quang",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Vĩnh Long",
  //         "shortCode": "49"
  //       },
  //       {
  //         "name": "Vĩnh Phúc",
  //         "shortCode": "70"
  //       },
  //       {
  //         "name": "Yên Bái",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Cần Thơ",
  //         "shortCode": "CT"
  //       },
  //       {
  //         "name": "Đà Nẵng",
  //         "shortCode": "DN"
  //       },
  //       {
  //         "name": "Hà Nội",
  //         "shortCode": "HN"
  //       },
  //       {
  //         "name": "Hải Phòng",
  //         "shortCode": "HP"
  //       },
  //       {
  //         "name": "Hồ Chí Minh (Sài Gòn)",
  //         "shortCode": "SG"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Virgin Islands, British",
  //     "countryShortCode": "VG",
  //     "regions": [
  //       {
  //         "name": "Anegada",
  //         "shortCode": "ANG"
  //       },
  //       {
  //         "name": "Jost Van Dyke",
  //         "shortCode": "JVD"
  //       },
  //       {
  //         "name": "Tortola",
  //         "shortCode": "TTA"
  //       },
  //       {
  //         "name": "Virgin Gorda",
  //         "shortCode": "VGD"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Virgin Islands, U.S.",
  //     "countryShortCode": "VI",
  //     "regions": [
  //       {
  //         "name": "St. Thomas",
  //         "shortCode": "STH"
  //       },
  //       {
  //         "name": "St. John",
  //         "shortCode": "SJO"
  //       },
  //       {
  //         "name": "St. Croix",
  //         "shortCode": "SCR"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Wallis and Futuna",
  //     "countryShortCode": "WF",
  //     "regions": [
  //       {
  //         "name": "Alo",
  //         "shortCode": "ALO"
  //       },
  //       {
  //         "name": "Sigave",
  //         "shortCode": "SIG"
  //       },
  //       {
  //         "name": "Wallis",
  //         "shortCode": "WAL"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Western Sahara",
  //     "countryShortCode": "EH",
  //     "regions": [
  //       {
  //         "name": "Es Smara",
  //         "shortCode": "ESM"
  //       },
  //       {
  //         "name": "Boujdour",
  //         "shortCode": "BOD"
  //       },
  //       {
  //         "name": "Laâyoune",
  //         "shortCode": "LAA"
  //       },
  //       {
  //         "name": "Aousserd",
  //         "shortCode": "AOU"
  //       },
  //       {
  //         "name": "Oued ed Dahab",
  //         "shortCode": "OUD"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Yemen",
  //     "countryShortCode": "YE",
  //     "regions": [
  //       {
  //         "name": "Abyān",
  //         "shortCode": "AB"
  //       },
  //       {
  //         "name": "'Adan",
  //         "shortCode": "AD"
  //       },
  //       {
  //         "name": "Aḑ Ḑāli'",
  //         "shortCode": "DA"
  //       },
  //       {
  //         "name": "Al Bayḑā'",
  //         "shortCode": "BA"
  //       },
  //       {
  //         "name": "Al Ḩudaydah",
  //         "shortCode": "HU"
  //       },
  //       {
  //         "name": "Al Jawf",
  //         "shortCode": "JA"
  //       },
  //       {
  //         "name": "Al Mahrah",
  //         "shortCode": "MR"
  //       },
  //       {
  //         "name": "Al Maḩwīt",
  //         "shortCode": "MW"
  //       },
  //       {
  //         "name": "'Amrān",
  //         "shortCode": "AM"
  //       },
  //       {
  //         "name": "Dhamār",
  //         "shortCode": "DH"
  //       },
  //       {
  //         "name": "Ḩaḑramawt",
  //         "shortCode": "HD"
  //       },
  //       {
  //         "name": "Ḩajjah",
  //         "shortCode": "HJ"
  //       },
  //       {
  //         "name": "Ibb",
  //         "shortCode": "IB"
  //       },
  //       {
  //         "name": "Laḩij",
  //         "shortCode": "LA"
  //       },
  //       {
  //         "name": "Ma'rib",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Raymah",
  //         "shortCode": "RA"
  //       },
  //       {
  //         "name": "Şā‘dah",
  //         "shortCode": "SD"
  //       },
  //       {
  //         "name": "Şan‘ā'",
  //         "shortCode": "SN"
  //       },
  //       {
  //         "name": "Shabwah",
  //         "shortCode": "SH"
  //       },
  //       {
  //         "name": "Tā‘izz",
  //         "shortCode": "TA"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Zambia",
  //     "countryShortCode": "ZM",
  //     "regions": [
  //       {
  //         "name": "Central",
  //         "shortCode": "02"
  //       },
  //       {
  //         "name": "Copperbelt",
  //         "shortCode": "08"
  //       },
  //       {
  //         "name": "Eastern",
  //         "shortCode": "03"
  //       },
  //       {
  //         "name": "Luapula",
  //         "shortCode": "04"
  //       },
  //       {
  //         "name": "Lusaka",
  //         "shortCode": "09"
  //       },
  //       {
  //         "name": "Northern",
  //         "shortCode": "05"
  //       },
  //       {
  //         "name": "North-Western",
  //         "shortCode": "06"
  //       },
  //       {
  //         "name": "Southern",
  //         "shortCode": "07"
  //       },
  //       {
  //         "name": "Western",
  //         "shortCode": "01"
  //       }
  //     ]
  //   },
  //   {
  //     "countryName": "Zimbabwe",
  //     "countryShortCode": "ZW",
  //     "regions": [
  //       {
  //         "name": "Bulawayo",
  //         "shortCode": "BU"
  //       },
  //       {
  //         "name": "Harare",
  //         "shortCode": "HA"
  //       },
  //       {
  //         "name": "Manicaland",
  //         "shortCode": "MA"
  //       },
  //       {
  //         "name": "Mashonaland Central",
  //         "shortCode": "MC"
  //       },
  //       {
  //         "name": "Mashonaland East",
  //         "shortCode": "ME"
  //       },
  //       {
  //         "name": "Mashonaland West",
  //         "shortCode": "MW"
  //       },
  //       {
  //         "name": "Masvingo",
  //         "shortCode": "MV"
  //       },
  //       {
  //         "name": "Matabeleland North",
  //         "shortCode": "MN"
  //       },
  //       {
  //         "name": "Matabeleland South",
  //         "shortCode": "MS"
  //       },
  //       {
  //         "name": "Midlands",
  //         "shortCode": "MI"
  //       }
  //     ]
  //   }
];

