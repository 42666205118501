import React, { useState, useRef , useEffect} from 'react';
import { Auth } from 'aws-amplify';
import {
    useNavigate,
    Link,
  } from 'react-router-dom'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    FormControlLabel,
    Grid,
    ListItem,
    ListItemButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { accounts } from '../../services/accounts';
import ModalPrompt from '../../CommonComponents/ModalPrompt';

const AccountsTable = ({ accountList, accountInfo, fetchAccounts}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [defaultDialogue, setdefaultDialogue] = useState(false);
    const [leaveAccountDialog, setLeaveAccountDialog] = useState(false);
    const [selected, setSelected] = useState();
    const [accountData, setAccoundData] = useState();

    function openLeaveAccount(account){
        setAccoundData(account);
        setLeaveAccountDialog(true);
    }
    function closeLeaveAccount(){
        setLeaveAccountDialog(false);
    }

    const fetchAccountData = async (account) => {
        try{
            const {status, error, accountData} = await accounts.getAccountInfo(account.perm[0]);
            if (error !== 'OK' && status !== 200) {
                if (error.includes("unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));  
                }
                //alert(t(error));
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                setAccoundData(accountData);
                setDefaultAccount(accountData)
            }

        }
        catch(error){
            console.log(error);
        }

    };

    const setDefaultAccount = async (accountData) => {
        let accountId = accountData.id;
        let name = accountData.name;
        let contactName = accountData.contact_name;
        let email = accountData.email;
        let contactPhone = accountData.contact_phone;
        let addressLine = accountData.address;
        let country = accountData.country;
        let city = accountData.city;
        let region = accountData.state;
        let postalCode = accountData.postal;
        let isDefault = 1;

        try{
            const {status, error, details} = await accounts.updateAccount(accountId, name, contactName, email, contactPhone, addressLine, country, city, region, postalCode, isDefault);

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"))                
                }
                else if(error.includes("Access Denied, no permissions to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else if(error.includes("Account name alreadery exists")){
                    alert(t("accountNameAlreadyExists"))                
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
                //alert(t(error));
            }
            else{
                fetchAccounts();
            }

        }
        catch(error){
            console.log(error)
        }

    };

    const leaveAccount = async () => {
        let user = await Auth.currentUserInfo();
        let account_id = accountData.perm[0];
        try{
            const {status, data} = await accounts.leaveAccount(account_id);
            const { error } = data;
            if (error !== 'OK' && status !== 200) {
                if (error.includes("unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else if (error.includes("only member")){
                    alert(t("failedLeaveAccountOnlyMember"));
                }
                else if (error.includes("at least one owner")){
                    alert(t("failedLeaveAtLeastOneOwner"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
            }
        }catch(error){
            console.log(error);
            closeLeaveAccount();
        }
        closeLeaveAccount();
        fetchAccounts();
    };

    return(
        <>
        <TableContainer component={Paper} sx={{ minWidth: 200 }}>
            <Table aria-label="simple table">
                <TableHead >
                    <TableRow >
                        <TableCell align="left"><Typography color={theme.palette.blue.main} fontSize={13}>{t("accountName")}</Typography></TableCell>
                        <TableCell align="left"><Typography color={theme.palette.blue.main} fontSize={13}>{t("accountUserRole")}</Typography></TableCell>
                        <TableCell align="left"><Typography color={theme.palette.blue.main} fontSize={13}>{t("default")}</Typography></TableCell>
                        <TableCell align="left"><Typography color={theme.palette.blue.main} fontSize={13}>{t("leaveQuestion")}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accountList.map((account) =>(
                        <TableRow key={account.account} sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
                        <TableCell align="left" > {t(account.account)}</TableCell>
                            <TableCell align="left" > {t(account.role)}</TableCell>
                            <TableCell aligh="left"> <Checkbox color="blue" checked={account.account == account.default.accountName} onChange={ e=> {fetchAccountData(account)}} /></TableCell>
                            <TableCell align="middle"><IconButton
                                                                edge="start"
                                                                color='primary'
                                                                disabled={account.account == account.default.accountName}
                                                                onClick={e=> {openLeaveAccount(account)}}
                                                                >
                                                                    <ExitToAppIcon/>
                                                    </IconButton> 
                            </TableCell>
                        </TableRow>
                    ))}
                    
                </TableBody>
            </Table>
        </TableContainer>
        {leaveAccountDialog && <ModalPrompt
            title={''}
            content={t('leaveAccountDialog')}
            action={t('leave')}
            handleAction={leaveAccount} 
            handleCancel={closeLeaveAccount} 
            openModal={leaveAccountDialog}
            fullScreen={fullScreen}
        />}
        </>
    )
}

export default AccountsTable