export function checkConfigurationMismatch(reported, desired){
    //Expect Dictionaries
    const ignoreKeys = [
        "28", // Device Name
        "125", // HW Configuration
        "111", // Relay out active high, not controlled by cloud
        "121", // Vent Valve Enabled, not controlled by cloud
        "122", // Vent Valve Normally Close, not controlled by cloud
        "127", // LCD Display Backlight Setting, not controlled by cloud
        "102", //?  Deprecated, not controlled by cloud 
        "103", //?  Deprecated, not controlled by cloud
    ];
    let mismatchList = [];
    Object.keys(reported).forEach((key)=> {
        if (!ignoreKeys.includes(key)){
            if (reported[key] !== desired[key]){
                mismatchList.push({
                    id: key,
                    reported: reported[key],
                    desired: desired[key]
                })
            }
        }
    })
    return mismatchList;
}