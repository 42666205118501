/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
    Divider,
    Grid,
} from '@mui/material';

import DataEntryListItemHeader from '../../../CommonComponents/DataEntryListItemHeader.js';
import ToggleListItem from '../../../CommonComponents/ToggleListItem.js';
import GenerateConfigItems from './GenerateConfigItems.jsx';
import { low_level_parameter_obj } from "../../../DeviceInfo/g3_Config";
import { hw_config_obj } from "../../../DeviceInfo/g3_Config";


// Device Page
export default function LowLevelSettings({editing, currentSettings, hwConfig, updateSettings=()=>{}}) {
    const [editParam, setEditParam] = useState(false); //Save, used to trigger state change.
    const [openParams, setOpenParams] = useState ({
        "105": false,
        "106": false,
        "104": false,
        "9": false,
        "5": false,
        "102": false,
        "30": false,
        "6": false,
        "39": false,
        "40": false,
    });

    const low_level_source_list = [
        {key: 1,id: 1, name: 'low level paddle', displayLabel: 'lowLevelPaddle'},
        {key: 2,id: 2, name: 'low level sensor', displayLabel: 'lowLevelSensor'},
        //{key: 3,id: 3, name: 'low level switch', displayLabel: 'lowLevelSwitch'}, //Pseudo Digital input
        {key: 4,id: 4, name: 'low level float', displayLabel: 'lowLevelFloat'},
    ];

    const low_level_switch_action_list = [
        {key: 0, id: 0, name: "alarm", displayLabel: 'lowLevelSwitchActionAlarm'},
        {key: 1, id: 1, name: "alert", displayLabel: 'lowLevelSwitchActionAlert'},
    ];

    const lubrication_low_level_analog_types = [
        //{key: 1, id: 1, name: "analog_0V_5V", displayLabel: "0V - 5V"},
        {key: 2, id: 2, name: "analog_0V_10V", displayLabel: "0V - 10V"},
        //{key: 6, id: 6, name: "analog_1V_5V", displayLabel: "1V - 5V"},
        {key: 7, id: 7, name: "analog_0d5V_4d5V", displayLabel: "0.5V - 4.5V"},
    ];

    const GenerateLocalLevelSettingList = () => {
        var local_settings_list = [];
        if (hwConfig.includes(hw_config_obj['low_level_digital_input'].id)){
            local_settings_list.push(low_level_source_list[0]);
            local_settings_list.push(low_level_source_list[2]);
            //local_settings_list.push(low_level_source_list[3]); //Pseudo Digital Swtich
        }
        if (hwConfig.includes(hw_config_obj['low_level_analog_input'].id)){
            local_settings_list.push(low_level_source_list[1]);
        }
        
        return local_settings_list;
    };

    const DisplayLowLevelSource = () => {
        const parameter_list = [
            {
                key: "lowLevelDetectionSource",
                'id': 'low_level_detection_source_id_?', 
                'config_id': "lowLevelDetectionSource",
                'display_name': "lowLevelSource",
                'type': "list",
                'list': GenerateLocalLevelSettingList()
            },
        ];

        const parameter_obj= {
             "lowLevelDetectionSource": parameter_list[0],
        }

        var localSetting = 2;
        if (currentSettings["104"] === "4"){
            localSetting = 1;
        } else if (currentSettings["104"] === "5"){
            localSetting = 3;
        } else if (currentSettings["104"] === "9"){
            localSetting = 4;
        }
        
        return(
            <Grid item xs>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={parameter_obj}
                    currentSettings={{"lowLevelDetectionSource": localSetting.toString()}} 
                    openParams={openParams}
                    handleUpdateParams={handleSourceUpdate}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
                {localSetting === 1 ? <DisplayLowLevelPaddle/>
                 :localSetting === 3 ? <DisplayLowLevelSwitch/>
                 :localSetting === 4 ? <DisplayLowLevelFloat/>
                 :<DisplayLowLevelSensor/>}
                
            </Grid>
        );
    };

    const DisplayLowLevelFloat = () => {

        

        return(
            <Grid item xs>
                {true && <DisplayLowLevelFloat2/>}
                {false && <DisplayLowLevelFloatAlert/>}
                {false && <DisplayLowLevelFloatAlarm/>}
            </Grid>
        );
    }
    const DisplayLowLevelFloat2 = () => {
        const parameter_list = [{
                key: "lowLevelFloatType",
                'id': 'low_level_float_type_id_?', 
                'config_id': "lowLevelFloatType",
                'display_name': "lowLevelFloatType",
                'type': "list",
                'list': low_level_switch_action_list
            }];
        const local_parameter_obj = {
            "lowLevelFloatType": parameter_list[0]
        }
        let local_currentSettings = {"lowLevelFloatType":currentSettings["129"] === "0" ? 1 : 0};
        return(
            <Grid>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={local_parameter_obj}
                    currentSettings={local_currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
            </Grid>            
            );
        };

    const DisplayLowLevelFloatAlarm = () => {
        const control_parameter = {
                key: "lowLevelFloatAlarmEnable",
                'id': 'low_level_float_alarm_enable_id_?', 
                'config_id': "lowLevelFloatAlarmEnable",
                'display_name': "lowLevelFloatAlarmEnable",
                'type': "toggle",
            };
        const parameter_list = [
            {   
                key: "129",
                'id': 'low_level_float_alarm_threshold_id_129', 
                'config_id': "129",
                'display_name': "lowLevelFloatAlertThreshold",
                'type': "number",
            },
        ];
        let toggleValue = parseInt(currentSettings[parameter_list[0].config_id]) ? true : false;
        return(
            <Grid>
                <ToggleListItem key={control_parameter.id}
                    label={control_parameter.display_name} 
                    param_id={control_parameter.config_id}
                    value={toggleValue} 
                    handleClick={handleToggle} 
                    clickDisabled={!editing}
                />
                {toggleValue && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={low_level_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>            
        );
    };

    const DisplayLowLevelFloatAlert = () => {
        const control_parameter = {
            key: "lowLevelFloatAlertEnable",
            'id': 'low_level_float_alert_enable_id_?', 
            'config_id': "lowLevelFloatAlertEnable",
            'display_name': "lowLevelFloatAlertEnable",
            'type': "toggle",
        };
        const parameter_list = [
            {
                key: "130",
                'id': 'low_level_float_alert_threshold_id_130', 
                'config_id': "130",
                'type': "number",
            },
        ];
        let toggleValue = parseInt(currentSettings[parameter_list[0].config_id]) ? true : false;
        return(
            <Grid>
                <ToggleListItem key={control_parameter.id}
                    label={control_parameter.display_name} 
                    param_id={control_parameter.config_id}
                    value={toggleValue} 
                    handleClick={handleToggle} 
                    clickDisabled={!editing}
                />
                {toggleValue && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={low_level_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>            
        );
    };

    const DisplayLowLevelSwitch = () => {
        const parameter_list = [
            {
                key: "40",
                'id': 'low_level_switch_action_id_40', 
                'config_id': "40",
                'display_name': "lowLevelSwitchAction",
                'type': "list",
                'list': low_level_switch_action_list
            },
        ];
        return(
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={low_level_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
        );
    };

    const DisplayLowLevelSensor = () => {
        const parameter_list = [
            {
                key: "104",
                'id': 'input1_type_id_104', 
                'config_id': "104",
                'type': "list",
                'list': lubrication_low_level_analog_types
            },
        ];
        return(
            <Grid>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={low_level_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
                {true && <DisplayLowLevelSensorAlert/>}
                {true && <DisplayLowLevelSensorAlarm/>}
            </Grid>
        );
    };

    const DisplayLowLevelSensorAlert = () => {
        const control_parameter = {
                key: "lowLevelSensorAlertEnable",
                'id': 'low_level_sensor_alert_enable_id_?', 
                'config_id': "lowLevelSensorAlertEnable",
                'display_name': "lowLevelSensorAlertEnable",
                'type': "toggle",
            };
        const parameter_list = [
            {
                key: "5",
                'id': 'low_level_sensor_alert_threshold_id_5', 
                'config_id': "5",
                'display_name': "lowLevelSensorAlertThreshold",
                'type': "number",
                'fieldLabel': 'sensorThresholdLabel',
            },
        ];
        let toggleValue = parseInt(currentSettings[parameter_list[0].config_id]) ? true : false;
        // Dynamically Adjust Range based on Alert/Alarm Relationship.
        let localParameterObject = JSON.parse(JSON.stringify(low_level_parameter_obj));
        localParameterObject["5"].range[0] = parseFloat(currentSettings["9"]) + 1;
        
        return(
            <Grid>
                <ToggleListItem key={control_parameter.id}
                    label={control_parameter.display_name} 
                    param_id={control_parameter.config_id}
                    value={toggleValue} 
                    handleClick={handleToggle} 
                    clickDisabled={!editing}
                />
                {toggleValue && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={localParameterObject}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>            
        );
    };

    const DisplayLowLevelSensorAlarm = () => {
        const control_parameter = {
                key: "lowLevelSensorAlarmEnable",
                'id': 'low_level_sensor_alarm_enable_id_?', 
                'config_id': "lowLevelSensorAlarmEnable",
                'display_name': "lowLevelSensorAlarmEnable",
                'type': "toggle",
            };
        const parameter_list = [
            {
                key: "9",
                'id': 'low_level_sensor_alarm_threshold_id_9', 
                'config_id': "9",
                'display_name': "lowLevelSensorAlertThreshold",
                'type': "number",
                'fieldLabel': 'sensorThresholdLabel',
            },
        ];
        let toggleValue = parseInt(currentSettings[parameter_list[0].config_id]) ? true : false;
        // Dynamically Adjust Range based on Alert/Alarm Relationship.
        let localParameterObject = JSON.parse(JSON.stringify(low_level_parameter_obj));
        localParameterObject["9"].range[1] = parseFloat(currentSettings["5"]) - 1.0;
        
        return(
            <Grid>
                <ToggleListItem key={control_parameter.id}
                    label={control_parameter.display_name} 
                    param_id={control_parameter.config_id}
                    value={toggleValue} 
                    handleClick={handleToggle} 
                    clickDisabled={!editing}
                />
                {toggleValue && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={localParameterObject}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>            
        );
    };

    const DisplayLowLevelPaddle = () => {
        const parameter_list = [
            {
                'id': 'low_level_paddle_alarm_autoclear_id_39', 
                'config_id': "39",
                'display_name': "lowLevelPaddleAlertThreshold",
                'type': "toggle",
            },
        ];
        return(
            <Grid item xs >
                <DisplayLowLevelPaddleAlert/>
                <DisplayLowLevelPaddleAlarm/>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={low_level_parameter_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />

            </Grid>
        );
    };

    const DisplayLowLevelPaddleAlarm = () => {
        const control_parameter = {
                key: "lowLevelSensorAlarmEnable",
                'id': 'low_level_paddle_alarm_enable_id_?', 
                'config_id': "lowLevelPaddleAlarmEnable",
                'display_name': "lowLevelPaddleAlarmEnable",
                'type': "toggle",
            };
        const parameter_list = [
            {   
                key: "30",
                'id': 'low_level_paddle_alarm_threshold_id_30', 
                'config_id': "30",
                'type': "number",
                'fieldLabel': 'paddleThresholdLabel'
            },
        ];
        let toggleValue = parseInt(currentSettings[parameter_list[0].config_id]) ? true : false;
        // Dynamically Adjust Range based on Alert/Alarm Relationship.
        let localParameterObject = JSON.parse(JSON.stringify(low_level_parameter_obj));
        localParameterObject["30"].range[0] = parseInt(currentSettings["6"]) + 1;
        return(
            <Grid>
                <ToggleListItem key={control_parameter.id}
                    label={control_parameter.display_name} 
                    param_id={control_parameter.config_id}
                    value={toggleValue} 
                    handleClick={handleToggle} 
                    clickDisabled={!editing}
                />
                {toggleValue && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={localParameterObject}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>            
        );
    };

    const DisplayLowLevelPaddleAlert = () => {
        const control_parameter = {
            key: "lowLevelPaddleAlertEnable",
            'id': 'low_level_paddle_alert_enable_id_?', 
            'config_id': "lowLevelPaddleAlertEnable",
            'display_name': "lowLevelPaddleAlertEnable",
            'type': "toggle",
        };
        const parameter_list = [
            {
                key: "6",
                'id': 'low_level_paddle_alert_threshold_id_6', 
                'config_id': "6",
                'type': "number",
                'fieldLabel': 'paddleThresholdLabel',
            },
        ];
        let toggleValue = parseInt(currentSettings[parameter_list[0].config_id]) ? true : false;
        // Dynamically Adjust Range based on Alert/Alarm Relationship.
        let localParameterObject = JSON.parse(JSON.stringify(low_level_parameter_obj));
        localParameterObject["6"].range[1] = parseInt(currentSettings["30"]) - 1;
        return(
            <Grid>
                <ToggleListItem key={control_parameter.id}
                    label={control_parameter.display_name} 
                    param_id={control_parameter.config_id}
                    value={toggleValue} 
                    handleClick={handleToggle} 
                    clickDisabled={!editing}
                />
                {toggleValue && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={localParameterObject}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />}
            </Grid>            
        );
    };

    const handleSourceUpdate = (key_in, value) => {
        if (key_in === "lowLevelDetectionSource"){
            let localSource = low_level_source_list[0];
            for (let i=0; i<low_level_source_list.length; i++){
                if (value === low_level_source_list[i].key){
                    localSource = low_level_source_list[i];
                    break;
                }
            }
            if(localSource.displayLabel === "lowLevelPaddle"){ //Update Input Type
                handleUpdateParams("104", 4);
            }else if (localSource.displayLabel === "lowLevelSensor"){
                handleUpdateParams("104", 7);
            }else if (localSource.displayLabel === "lowLevelSwitch"){
                handleUpdateParams("104", 5);
            }else if (localSource.displayLabel === "lowLevelFloat"){
                handleUpdateParams("104", 9);
            }
        }
    };

    const handleToggle = (key_in) => {
        if (key_in === "lowLevelSensorAlarmEnable"){
            if(parseInt(currentSettings["9"])){ //Alarm Threshold ID 9
                handleUpdateParams("9",0);
            }
            else{
                handleUpdateParams("9",10);
            }
        }
        else if (key_in === "lowLevelSensorAlertEnable"){
            if(parseInt(currentSettings["5"])){ //Alert Threshold ID 5
                handleUpdateParams("5",0);
            }
            else{
                handleUpdateParams("5", 25);
            }
        } else if (key_in === "lowLevelPaddleAlarmEnable"){
            if(parseInt(currentSettings["30"])){ //Alarm Threshold ID 30
                handleUpdateParams("30",0);
            }
            else{
                handleUpdateParams("30",80);
            }
        }
        else if (key_in === "lowLevelPaddleAlertEnable"){
            if(parseInt(currentSettings["6"])){ //Alert Threshold ID 6
                handleUpdateParams("6",0);
            }
            else{
                handleUpdateParams("6", 10);
            }
        }
        else if (key_in === "lowLevelFloatAlarmEnable"){
            if(parseInt(currentSettings["129"])){ //Alarm Threshold ID 129
                handleUpdateParams("129",0);
            }
            else{
                handleUpdateParams("129", 10);
            }
        }
        else if (key_in === "lowLevelFloatAlertEnable"){
            if(parseInt(currentSettings["130"])){ //Alarm Threshold ID 129
                handleUpdateParams("130",0);
            }
            else{
                handleUpdateParams("130", 10);
            }
        }
        else {
            if (parseInt(currentSettings[key_in])) {
                handleUpdateParams(key_in,0);
            }
            else{
                handleUpdateParams(key_in,1);
            }
        }
    };

    const handleOpenParam = (key_in) => {
        let l_openParams = openParams;
        l_openParams[key_in] = true;
        setOpenParams(l_openParams);
        setEditParam(true);
    };
    const handleCloseParams = () => {
        let l_openParams = openParams;
        Object.keys(l_openParams).forEach(key => {
            l_openParams[key] = false;
        })
        setOpenParams(l_openParams);
        setEditParam(false);
    };
    const handleUpdateParams = (key_in, value_in)  => {
        var temp_params = Object.assign({}, currentSettings);
        if (temp_params[key_in] !== String(value_in)){
            if (key_in === "lowLevelFloatType"){
                if (value_in === 0){ // Alarm
                    temp_params["129"] = 5;
                    temp_params["130"] = 0;
                }
                if (value_in === 1) {
                    temp_params["129"] = 0;
                    temp_params["130"] = 5;
                }
            }
            else {
                temp_params[key_in] = String(value_in);
                // Check For Source Change from Sensor <-> Switch
                if (parseInt(key_in) === 102){ //Detection Source
                    if (parseInt(value_in) === 2){ //If Sensor
                        temp_params["104"] = 7; //0.5V - 4.5V
                    } else {
                        temp_params["104"] = 5; //Pseudo Digital
                    }
                }
            }
            updateSettings(temp_params);
        }
        
        handleCloseParams();
    };

    const handleErrorCheckParams = (key_in, value_in) => {
        let value_out=undefined;
        let errorText = ""
        value_out = value_in;
        return {value: value_out, errorText: errorText}
    };

    //Header consumes 64px of viewable area
    return (
        <Grid container item direction='column'>
            <Divider/>
                <DataEntryListItemHeader label={"settingLowLevelTitle"}/>
            <Divider/>
            <DisplayLowLevelSource/>
        </Grid>
    )
}
