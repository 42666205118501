import { Auth } from 'aws-amplify';

export const checkAuthorizationException = async (error, navigation=undefined) => {
    if (error.code === 'NotAuthroizedException')
    {
        console.error('User is not authorized');
        await handleLogout(navigation);
    }
}

export const handleLogout = async (navigation) => {
    try {
        await Auth.signOut();
        window.location.assign('/');
        if (navigation)
        {
            navigation('/');
        }
    }
    catch {
        console.log("Unable to sign out")
    }
}