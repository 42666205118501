import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { 
    privacy_url, 
    trace_user_guide,
    trace_quick_start_guide,
    g3_max_education_center,
    trace_support_information,
    tech_assistance_email,
    goLive
 } from '../data';
 //!! CRITICAL NOTE:  Server must be setup to allow file type .pdf for rewrites and redirects, otherwise pdf will not load.
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import TermsOfService from '../CommonComponents/TermsOfService';

export default function HelpPage() {
    const theme = useTheme()
    const [openTerms, setOpenTerms] = React.useState(false);
    const { t } = useTranslation();

    const handleCloseTerms = () => {
        setOpenTerms(false);
    };

    const handleOpenTerms = () => {
        setOpenTerms(true);
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const linkStyle = {
        color: theme.palette.secondary.light, // Light blue for the default state
        textDecoration: 'none',
      };
    
      const hoverStyle = {
        color: theme.palette.toggleblue.main, // Lighter blue for hover state
      };

    return (
        <Grid container
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            >
            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("goHome")}>
                        <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <Typography variant='h5'
                        color='textPrimary'>
                        {t("help")}
                    </Typography>
                </Toolbar>
            </Grid>
            <Grid item xs><Divider />
                <TableContainer component={Paper} sx={{backgroundColor: theme.palette.darkGrey.dark,}}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {(true) && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <Button onClick={()=>openInNewTab(trace_quick_start_guide)} color='secondary' variant="contained" style={{ 'float': 'center', 'font-size': 12 }}>
                                        {t("gracoTraceQuickStartGuide")}
                                    </Button>
                                </TableCell>
                            </TableRow>}
                            {(true) && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <Button onClick={()=>openInNewTab(trace_user_guide)} color='secondary' variant="contained" style={{ 'float': 'center', 'font-size': 12 }}>
                                        {t("gracoTraceUserGuide")}
                                    </Button>
                                </TableCell>
                            </TableRow>}
                            {false && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <Button onClick={()=>openInNewTab(trace_support_information)} color='secondary' variant="contained" style={{ 'float': 'center', 'font-size': 12 }}>
                                        {t("gracoTraceEducationCenter")}
                                    </Button>
                                </TableCell>
                            </TableRow>}
                            {true && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <Button onClick={()=>openInNewTab(g3_max_education_center)} color='secondary' variant="contained" style={{ 'float': 'center', 'font-size': 12 }}>
                                        {t("gracoG3MaxEducationCenter")}
                                    </Button>
                                </TableCell>
                            </TableRow>}
                            {true && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <Button onClick={handleOpenTerms} variant="outlined" style={{ 'float': 'center', 'font-size': 12 }}>
                                    {t("termsAndConditions")}
                                    </Button>
                                </TableCell>
                            </TableRow>}
                            {true && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <Button onClick={()=>openInNewTab(privacy_url)} variant="outlined" style={{ 'float': 'center', 'font-size': 12 }}>
                                        {t("privacy")}
                                    </Button>
                                </TableCell>
                            </TableRow>}
                            {false && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <a href={privacy_url} 
                                        style={linkStyle}
                                        onMouseEnter={(e) => e.target.style.color = hoverStyle.color}
                                        onMouseLeave={(e) => e.target.style.color = linkStyle.color}
                                        target="_blank" rel="noopener noreferrer">{t("privacy")}</a>
                                </TableCell>
                            </TableRow>}
                            {goLive && <TableRow>
                                <TableCell colSpan={2} align="center" style={{ borderBottom: "none" }}>
                                    {t("forHelpEmail_colon")} <a href="mailto:ledtech@graco.com" 
                                        style={linkStyle}
                                        onMouseEnter={(e) => e.target.style.color = hoverStyle.color}
                                        onMouseLeave={(e) => e.target.style.color = linkStyle.color}
                                        target="_top">{tech_assistance_email}</a>
                                </TableCell>
                            </TableRow>}
                            {goLive && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    {t("techAssistancePhone")}
                                </TableCell>
                            </TableRow>}
                            <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <a href={"http://www.graco.com"} 
                                        style={linkStyle}
                                        onMouseEnter={(e) => e.target.style.color = hoverStyle.color}
                                        onMouseLeave={(e) => e.target.style.color = linkStyle.color}
                                        target="_blank"
                                        rel="noopener noreferrer">www.graco.com</a>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {openTerms && <TermsOfService openTerms={openTerms} handleCloseTerms={handleCloseTerms} reviewOnly={true}/>}
        </Grid>
    );
}
