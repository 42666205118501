/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import useSessionstorage from '@rooks/use-sessionstorage';
import Cookies from 'universal-cookie';
import {
    Divider,
    Grid,
} from '@mui/material';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';


import { pin_code_parameters_obj } from '../../../DeviceInfo/g3_Config.js';
import { hw_config_obj } from '../../../DeviceInfo/g3_Config.js';
import DataEntryListItemHeader from '../../../CommonComponents/DataEntryListItemHeader.js';
import GenerateConfigItems from './GenerateConfigItems.jsx';

// Device Page
export default function PinCodeSettings({editing, currentSettings, hwConfig, updateSettings=()=>{}}) {
    const params = useParams();
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    const { value: accountInfo } = useSessionstorage('accountInfo', {});
    const [editParam, setEditParam] = useState(false);
    const [openParams, setOpenParams] = useState ({
        "26": false,
        "27": false
    });

    const handleToggle = (key_in) => {
        if (parseInt(currentSettings[key_in])) {
            handleUpdateParams(key_in,0);
        }
        else{
            handleUpdateParams(key_in,1);
        }
    };

    const handleOpenParam = (key_in) => {
        let l_openParams = openParams;
        l_openParams[key_in] = true;
        setOpenParams(l_openParams);
        setEditParam(true);
    };
    const handleCloseParams = () => {
        let l_openParams = openParams;
        Object.keys(l_openParams).forEach(key => {
            l_openParams[key] = false;
        })
        setOpenParams(l_openParams);
        setEditParam(false);
    };
    const handleUpdateParams = (key_in, value_in)  => {
        
        var temp_params = Object.assign({}, currentSettings);
        if (temp_params[key_in] !== String(value_in)){
            temp_params[key_in] = String(value_in);
            updateSettings(temp_params);
        }
        handleCloseParams();
    };

    const handleErrorCheckParams = (key_in, value_in) => {
        let value_out=undefined;
        let errorText = ""
        value_out = value_in;
        return {value: value_out, errorText: errorText}
    };


    const DisplayPinCode = () => {
        const parameter_list = [
            // {
            //     key: "26",
            //     "id":"pin_code_enable_26",
            //     "config_id":"26",
            //     'type': "toggle",
            //  },
             {
                key: "27",
                "id":"pin_code_value_27",
                "config_id":"27",
                'type': "number",
                "digits": 4
             },
        ]
        return (
            <Grid>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={pin_code_parameters_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdatePin}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={2}
                />
            </Grid>
        )
    };

    const handleUpdatePin = (key_in, value) => {
        if (key_in === "27") {
            var localVal = parseInt(value).toString().padStart(4, '0')
            handleUpdateParams(key_in, localVal);
        }
    }

    //Header consumes 64px of viewable area
    return (
        <Grid container item direction='column'>
            <Divider/>
                <DataEntryListItemHeader label={"settingPinCodeTitle"}/>
            <Divider/>
            <DisplayPinCode/>
        </Grid>
    )
}
