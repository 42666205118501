import { G3_STATE_ID } from '../DeviceInfo/g3_Info.js';


export function updateDeviceStatusMqtt(currentStatus, newStatus){
    var status_dict = {}
    var uid ="";
    var index;
    var newData = {};
    let tempData = currentStatus;
    const topic = newStatus[Object.getOwnPropertySymbols(newStatus)[0]]
    if (topic.includes('event') || topic.includes('status') || topic.includes('latest')){
      uid = topic.split('/')[3];
    }
    status_dict = convertMqttToStatus(newStatus, currentStatus);
    for (let i=0; i < tempData.length; i++){
      if (tempData[i].uid === uid) {
        Object.keys(status_dict).forEach((key) => {
          if (key !== 'last_connected' && key !== 'server_time_iso'){
            tempData[i][key] = status_dict[key];
          }
        })
        if(tempData[i]['connection'] === 1) {
          tempData[i]['last_connected'] = status_dict['last_connected'];
          tempData[i]['server_time_iso'] = status_dict['server_time_iso'];
        }
        newData = tempData[i];
        index = i;
        break;
      }
    };
    return {index, newData};
  }

  export function updateDeviceConnectionMqtt(currentStatus, msg){
    //var tempData = JSON.parse(JSON.stringify(currentStatus));
    let tempData = currentStatus
    var uid="";
    var index;
    var newData = {};

    const topic = msg[Object.getOwnPropertySymbols(msg)[0]];
    uid = topic.split('/')[3];
    for (let i=0; i < tempData.length; i++){
      if (tempData[i].uid === uid){
        tempData[i]['connection']=msg.connection;
        newData = tempData[i];
        index = i;
        break;
      }
    }
    return {index, newData};
  }

  function convertMqttToStatus(data_in){
    var status_dict = {}
    status_dict['last_connected'] = data_in.client_received_at ? data_in.client_received_at.toISOString() : undefined;
    status_dict['server_time_iso'] = data_in.client_received_at ? data_in.client_received_at.toISOString() : undefined;
  
    status_dict["rssi"] = String(data_in.rsi ? data_in.rsi : 0)
    status_dict["voltage"] = String(data_in.v ? data_in.v : 0);
    status_dict["level"] = String(data_in.lv ? data_in.lv : 0);
    status_dict["temperature"] = data_in.t;
    
    status_dict["state"] = data_in.st ? data_in.st : 0;
    status_dict["state_value_1"] = data_in.sval1 ? data_in.sval1 : 0;
    status_dict["state_value_2"] = data_in.sval2 ? data_in.sval2 : 0;
    status_dict["in_alarm"] = G3_STATE_ID[status_dict["state"]].isAlarm ? G3_STATE_ID[status_dict["state"]].isAlarm : 0;

    let alerts_list = data_in.alt ? data_in.alt : [];
    status_dict["alerts"] = alerts_list.toString();
    status_dict["alerts_list"] = alerts_list;
    status_dict['in_alert'] = 0
    if (alerts_list.length){
        status_dict['in_alert'] = 1;
    }
    status_dict["event"] = data_in.evt ? data_in.evt : 0;
    status_dict["event_value"] = String(data_in.evl ? data_in.evl : 0);
    return status_dict;
  };