import React, { useState } from 'react';
import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Paper,
    Typography
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TouchAppIcon from '@mui/icons-material/TouchApp';

import { useTranslation } from 'react-i18next';

import { 
    advanced_parameter_obj,
    interval_parameters_obj,
    low_level_parameter_obj,
    lubrication_parameters_obj,
    outputs_parameter_obj,
    pin_code_parameters_obj
     } from '../DeviceInfo/g3_Config';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
[`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
},
[`&.${tableCellClasses.body}`]: {
    fontSize: 12,
},
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ConfigurationMismatchChip = ({ mismatchList, fullScreen=false, deviceType=2}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };
  
  const handleClick= ()=>{
    setOpen(true);
  };

  function DisplayMismatch({mismatch_list, type=2}){
    var t_config = {};
    if (type === 1 || type === 2){
        Object.keys(pin_code_parameters_obj).forEach(key => {
          t_config[key] = pin_code_parameters_obj[key];
          t_config[key]['context'] = 'settingPinCodeTitle';
        });
        Object.keys(interval_parameters_obj).forEach(key => {
            t_config[key] = interval_parameters_obj[key];
            t_config[key]['context'] = 'settingLubricationIntervalTitle';
        });
        Object.keys(lubrication_parameters_obj).forEach(key => {
            t_config[key] = lubrication_parameters_obj[key];
            t_config[key]['context'] = 'settingLubricationEndTitle';
        });
        Object.keys(low_level_parameter_obj).forEach(key => {
            t_config[key] = low_level_parameter_obj[key];
            t_config[key]['context'] = 'settingLowLevelTitle';
        });
        Object.keys(outputs_parameter_obj).forEach(key => {
            t_config[key] = outputs_parameter_obj[key];
            if(t_config[key].display_name.includes("relayOut")){
              t_config[key]['context'] = 'settingsOutputsRelay';
            }
            if(t_config[key].display_name.includes("signalOut")){
              t_config[key]['context'] = 'settingsOutputsSignal';
            }
            if(t_config[key].display_name.includes("ventValve")){
              t_config[key]['context'] = 'settingsOutputsVent';
            }
        });
        Object.keys(advanced_parameter_obj).forEach(key => {
            t_config[key] = advanced_parameter_obj[key];
            t_config[key]['context'] = 'advancedOptions';
        });
        t_config['28'] = {display_name: 'deviceName'};
        t_config['28']['context'] = 'device';
    }
    var rows = [];
    for (const item of mismatch_list) {
        let temp = item;
        temp['displayName'] = t_config[item['id']]?.display_name;
        temp['context'] = t_config[item['id']]?.context;
        rows.push(temp);
    }
    
    return(
        <TableContainer component={Paper} >
          <Table aria-label="customized table">
            {<TableHead>
              <TableRow>
                <StyledTableCell>{t('configMismatchTableContext')}</StyledTableCell>
                <StyledTableCell>{t('configMismatchTableParam')}</StyledTableCell>
                <StyledTableCell align="right">{t('configMismatchReported')}</StyledTableCell>
                <StyledTableCell align="right">{t('configMismatchDesired')}</StyledTableCell>
              </TableRow>
            </TableHead>}
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.display_name}>
                  <StyledTableCell component="th" scope="row">
                    {t(row.context ? row.context : "unknown")}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {t(row.displayName ? row.displayName : "ID: "+row.id)}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.reported}</StyledTableCell>
                  <StyledTableCell align="right">{row.desired}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    )
  }

  return (
    <>
    <Chip icon={<TouchAppIcon />} color="error" label={t("configurationMismatch")} onClick={handleClick} />

    <Dialog disableEscapeKeyDown open={open} onClose={handleCancel} fullScreen={fullScreen}>
      <DialogTitle>{t('configurationMismatch')}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>{t("configMismatchTroubleshootNote")}</Typography>
          
        <DisplayMismatch mismatch_list={mismatchList} type={deviceType}/>
        {fullScreen && 
            <Button fullWidth sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                autoFocus
                color={!true ? "inherit" : "secondary"} variant={!true ? undefined : "contained"}
                onClick={handleCancel}
            >
                {t('close')}
            </Button>
        }
      </DialogContent>
      {!fullScreen && <DialogActions>
        <Button onClick={handleCancel} color={!true ? "inherit" : "secondary"} variant={!true ? undefined : "contained"}>
          {t("close")}
        </Button>
      </DialogActions>}
    </Dialog>
    </>
  );
};

export default ConfigurationMismatchChip;
