export default function useFormatDateIsoString(date, locale = 'en-US', hour12 = true) {
  try {
    let datestring = date.includes('Z') ? date : date+'Z';
    let utc_date = new Date(datestring)
    return Intl.DateTimeFormat(locale, {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: hour12
    }).format(Date.parse(utc_date));
  } catch (error) {
    // console.error(error);
  }
}

export function FormatDateIsoString(date, locale = 'en-US', hour12 = true) {
  try {
    let datestring = date.includes('Z') ? date : date+'Z';
    let utc_date = new Date(datestring)
    return Intl.DateTimeFormat(locale, {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: hour12
    }).format(Date.parse(utc_date));
  } catch (error) {
    // console.error(error);
  }
}
