import React, { useState, useEffect } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';

//import WiFiActivationCodeImg from '../../../OrgPage/OrgBilling/Components/Assets/WiFi_ActivationCode_ti40040.png';
import { useTranslation } from 'react-i18next';
import { groups_inf } from '../../services/groups_inf';

const AddUser = ({ accountInfo, group, fetchGroup=()=>{}, isFAB=true }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    //const [curUsers, setUsers] = useState([]);
    const [userList, setUsersList] = useState([]);
    const [users, setUsers] = useState([]);
    const [write, setWrite] = useState(0);
    const [invite, setInvite] = useState(0);
    const [groupID, setGroupID] = useState();
    const [groupName, setGroupName] = useState();
    const [openEnterName, setOpenEnterName] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        filterGroup(group);
        const abortController = new AbortController();
        fetchGroup(abortController);
        return () => abortController.abort();
      },[openEnterName]);

    function filterGroup(groupData){
        setGroupID(groupData.id)
        setGroupName(groupData.name)
    };

    const handleOpenEnterName = () => {
        setOpenEnterName(true);
        fetchUsers();
    };

    const handleCloseEnterName = () => {
        setOpenEnterName(false);
        setUsers([]);
        setWrite(0);
        setInvite(0);
    };

    const fetchUsers = async () => {
        try {
            const { status, error, accountGroups, accountUsers } = await groups_inf.getGroups(
                accountInfo.accountId
            );
            
            if (status === 404) {
                return;
            }
    
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"))            
                }
                else if(error.includes("Access Denied, no permissions to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
              console.log('Group Fetched');
            }

            let users = [];
            let data = [];
            for (const [index, val] of Object.entries(accountGroups)){
                if(accountGroups[index].id === groupID){
                    let k=0;
                    while(k<accountGroups[index].users.length){
                        users.push(accountGroups[index].users[k].user_id);
                        k++;
                    }
                }
            }

            for (const [key1, value1] of Object.entries(accountUsers)) {
                let i=0;
                let temp;
                let contained = false;
                temp = value1.user_id;
                while(i<users.length){
                    if(users[i].toLowerCase() === temp.toLowerCase()){
                        contained=true;
                    }
                    i++;
                }
                if(!contained){
                    data.push(temp);
                }
            }
            
            setUsersList(data);
    
        } catch (error) {
            console.log('Failed to Fetch group')
            console.error(error);
        }
      };

      const handleSelectUser = (event) => {
        setUsers(event.target.value)
      };

      const handleWrite = (event) => {
        setWrite(event.target.checked)
      };
      const handleInvite = (event) => {
        setInvite(event.target.checked);
      };

    const updateGroup = async () => {
        let newUserData = [];
        
        let i=0;
        while(i<users.length){
            let j=0;
            while(j<userList.length){
                if(userList[j] === users[i]){
                    newUserData.push({user_id: users[i], read_access: 1, write_access: write, invite_access: invite});
                }
                j++;
            }
            i++;
        }

        try{
            const { status, error } = await groups_inf.updateGroup(
                accountInfo.accountId, groupID, groupName, newUserData, [], []
            );
            if (status === 404) {
                return;
            }
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"))                
                }
                else if(error.includes("Access Denied, no permissions to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else if(error.includes("Group name alreadery exists")){
                    alert(t("groupNameAlreadyExists"))                
                }
                //alert(t(error));
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
            }
            console.log('done updating group'); 
        }catch (error) {
            console.error(error);
        }

        fetchGroup();
        handleCloseEnterName();
    };


      return <>
        {accountInfo.isAccountEditor === 1 && (isFAB ?
          <Fab sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2)
          }} 
          color="secondary" aria-label="addD" onClick={handleOpenEnterName}>
              <AddIcon />
          </Fab> 
          : 
          <Tooltip title={t("addUser")}>
            <IconButton onClick={handleOpenEnterName}>
              <AddIcon/>
              <Typography sx={{marginLeft: '5px'}}>{t("user")}</Typography>
            </IconButton>
          </Tooltip>
        )}
        {openEnterName && <Dialog
            disableEscapeKeyDown
            open={openEnterName}
            onClose={handleCloseEnterName}
            fullScreen={fullScreen}>
            
            <DialogTitle>{t("userDetails")}</DialogTitle>
            <DialogContent >
            <Grid container item xs 
                    justifycontent='flex-start'
                    alignItems='center'
                    direction='column'>
                    <Grid item xs>
                        <FormControl sx={{
                            margin: theme.spacing(1),
                            minWidth: 300,
                            width: 300,
                        }}>
                            <Stack spacing={2}>
                                <InputLabel id="select User">{t("User")}</InputLabel>
                                <Select
                                    value={users ? users : " "}
                                    renderValue={(selected) => selected.join(', ')}
                                    multiple
                                    onChange={handleSelectUser}
                                    >
                                    {userList.map(user => {
                                        return(
                                            <MenuItem value={user}>
                                                <Checkbox checked={users.indexOf(user) > -1}/>
                                                <ListItemText>{user}</ListItemText>
                                            </MenuItem> 
                                        );  
                                    })} 
                                </Select>
                                <Typography>{t("setPermissions")}</Typography>
                                <Grid direction="column" container spacing={2}>
                                    <FormControlLabel
                                        label={t("groupWriteAccess")}
                                        control={
                                            <Checkbox
                                                //checked={write}
                                                checked={write}
                                                onChange={handleWrite}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={t("groupInviteAccess")}
                                        control={
                                            <Checkbox
                                                //checked={write}
                                                checked={invite}
                                                onChange={handleInvite}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                    />
                                </Grid>
                            </Stack>
                        </FormControl>
                    </Grid>
                    {true && <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth autoFocus variant='contained' color='secondary' onClick={updateGroup} >
                            {t('update')}
                        </Button>
                    </Grid>}
                    {true && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth 
                            autoFocus
                            color='inherit'
                            onClick={handleCloseEnterName}
                        >
                            {t('cancel')}
                        </Button>
                    </Grid>}
                </Grid>
                {false && <DialogActions>
                    <Button onClick={handleCloseEnterName} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button variant="contained" onClick={updateGroup} color="secondary">
                            {t("update")}
                    </Button>
                </DialogActions>}
            </DialogContent>

            </Dialog>
        
        }

        </>
    };
export default AddUser;