/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import useSessionstorage from '@rooks/use-sessionstorage';
import {
    Box,
    Chip,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';

import SyncIcon from '@mui/icons-material/Sync';
import DownloadIcon from '@mui/icons-material/Download';

import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import { CSVLink } from "react-csv";

import {G3_EVENT_ID} from '../../../../DeviceInfo/g3_Info';
import { GrowDiv } from '../../../../styledComponentsCommon';
import {FormatDateIsoString} from '../../../../helpers/useFormatDateIsoString';
import EventsFilter from './EventsFilter';

export default function G3EventsView({currentEvents, deviceInfo, lookBackDays=14, fetchEvents=()=>{}}) {
    const { t } = useTranslation();
    const theme=useTheme();
    const { value: accountInfo } = useSessionstorage('accountInfo', {});
    const [typesFilter, setTypesFilter] = useState(['alarm', 'alert']);
    const [displayData, setDisplayData] = useState(currentEvents);
    
    const [csvFileName, setCSVFileName] = useState('reports.csv');
    const [csvReportData, setCSVReportData] = useState([]);
    const csvLink = useRef();
    const csvHeaders = [
        { label: t("timestamp"), key: 'server_time' },
        { label: t("eventType"), key: 'type' },
        { label: t("event"), key: 'evt' },
        { label: t("eventDetails"), key: 'evl' },
    ];
    
    const handle_fetchEvents = () => {
        fetchEvents(lookBackDays);
    }

    useEffect(() => {
      const abortController = new AbortController();
      
      filterDisplayData(currentEvents, typesFilter);
      
      return () => abortController.abort();
    },[typesFilter, currentEvents]);

    const filterDisplayData = (events, filterTypes) => {
      var localEvents = [];
      events.forEach(e => {
        if (typeof e.evt === 'string'){
          if(filterTypes !== undefined){
            if (filterTypes.length === 0 || filterTypes.includes(G3_EVENT_ID[e.evt]?.type)){
              localEvents.push(e);
            }
          } else {
            localEvents.push(e);
          }
        }
      });
      let filenamePrefix = deviceInfo.friendly_name ? deviceInfo.friendly_name : deviceInfo.uid;
      createCSVFileName(filenamePrefix);
      createCSVData(events);
      setDisplayData(localEvents);
    };

    const updateTypesFilter = (newFilter) => {
      setTypesFilter(newFilter);
    };

    const handleDeleteFilterChip = (chipToDelete) => () => {
      var filtTypes = [];
      filtTypes = typesFilter.filter(e => e !== chipToDelete);
      updateTypesFilter(filtTypes);
    }

    const DisplayEventsList = ({events, device}) => {
      function validateList(list){
        var newList = [];
        list.forEach(e => {
          if (typeof e.evt === 'string'){
            newList.push(e);  
          }
        });
        return newList;
      }
      const listlist = validateList(events);
      const invertEmphasis = false;
      
      return(
        <List>
          {listlist.map((item) => (
            <ListItem 
              key={[item.evt, item.server_time].join("-")}
              sx={{paddingLeft: 
                theme.spacing(1), 
                paddingRight: theme.spacing(1),
                maxWidth: 600,
                minWidth: 350,
              }}>
                <Grid container 
                    direction='row' 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    sx={{
                        backgroundColor: theme.palette.grey.dark,
                        borderRadius: '4px',
                        //maxWidth: 550,
                }}>
                  <ListItemText  primaryTypographyProps={{
                      //fontWeight: invertEmphasis ? undefined : "bold",
                      color: invertEmphasis ? theme.palette.primary.main : undefined,
                      margin: theme.spacing(1),
                      }}
                      secondaryTypographyProps={{
                        //fontWeight: invertEmphasis ? undefined : "bold",
                        margin: theme.spacing(1),
                      }}
                    primary={item.evl ? (t(G3_EVENT_ID[item.evt]?.displayName))+", "+ item.evl
                            : (t(G3_EVENT_ID[item.evt]?.displayName))}
                    secondary={FormatDateIsoString(item.server_time)}
                  />
                  <GrowDiv/>
                  <Typography sx ={{margin: theme.spacing(1),
                            marginRight: theme.spacing(4)}}
                    color={G3_EVENT_ID[item.evt]?.type === 'alarm' ? 'error'
                      : G3_EVENT_ID[item.evt]?.type === 'alert' ? theme.palette.warning.main
                      : G3_EVENT_ID[item.evt]?.type === 'systemInfo' ? theme.palette.warning.main
                      : theme.palette.secondary.light}>
                      {t(G3_EVENT_ID[item.evt]?.type)}
                    </Typography>                
                </Grid>
            </ListItem>
          ))}
        </List>
      );
    }

    const DisplayFilterTypesChips = ({filtTypes=[]}) => {
      if (filtTypes.length === 0){
        console.log("chip error");
        return(<></>)
      }
      return(
        <Stack 
            direction='row' 
            spacing={1} 
            sx={{margin: theme.spacing(1)}}>
          {filtTypes.map(type => {
            return(<Chip key={type} label={t(type)} size='small' onDelete={handleDeleteFilterChip(type)} />);
          })}
        </Stack>
      );
    }

    const updateLookback = (days) => {
      var lDays = days;
      if(lDays !== lookBackDays)
      {
        fetchEvents(lDays);
      }
    };

    function convertUTCDateToLocalDate(date) {
      var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      var month = '' + (newDate.getMonth() + 1),
          day = '' + newDate.getDate(),
          year = newDate.getFullYear(),
          hour = newDate.getHours(),
          minute = newDate.getMinutes(),
          second = newDate.getSeconds();

      if (month.length < 2)
          month = '0' + month;

      if (day.length < 2)
          day = '0' + day;

      if (minute < 10)
          minute = '0' + minute;

      if (hour < 10)
          hour = '0' + hour;

      var prettiestStr = month + '/' + day + '/' + year + ' ' + hour + ':' + minute;
      return prettiestStr;
    };

    function convertDatetoCSVFormat(inputTimeStamp) {
      //Excel based on 1990.  Java based on 1970.
      //ASSUME ISO String Input
      let ret = inputTimeStamp;
      
      ret = inputTimeStamp.slice(0, 19).replace('T', ' ').replace(/-/g, '/');
      ret = convertUTCDateToLocalDate(new Date(ret));

      return ret;
    }

    function createCSVFileName(fileNamePrefix) {
      let d = new Date();
      let dformat = `${d.getFullYear()}${("0" + (d.getMonth() + 1)).slice(-2)}${("0" + d.getDate()).slice(-2)}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

      setCSVFileName(fileNamePrefix + '_' + t('events') + '_' + dformat + '.csv');
    };
    
    function createCSVData(data_in) {
      var i;
      var k;
      // Create Heading Block
      var csvData = [[t("accountName"), accountInfo.accountName]];
      csvData.push([t("deviceName"), deviceInfo.friendly_name ? deviceInfo.friendly_name : '']);
      csvData.push([t("deviceUid"), '\'' + deviceInfo.uid])

      let d = new Date();
      csvData.push([t("dateCreated"), convertDatetoCSVFormat(d.toISOString())]);

      csvData.push(['']);
      var tempRow = [];
      // Create Table Header
      for (i = 0; i < csvHeaders.length; i++) {
          tempRow.push(csvHeaders[i].label);
      }
      tempRow.push(t("eventInfo"));
      csvData.push(tempRow);
      //Create Table Data
      if (data_in.length > 0) {
          for (k = 0; k < data_in.length; k++) {
              tempRow = []
              let item = data_in[k];
              for (i = 0; i < csvHeaders.length; i++) {
                  if (data_in[k][csvHeaders[i].key] === null || data_in[k][csvHeaders[i].key] === undefined) {
                    if (csvHeaders[i].key === "type"){
                      tempRow.push(t(G3_EVENT_ID[item.evt]?.type));
                    } else{
                      tempRow.push("");
                    }
                  }
                  else {
                      if (csvHeaders[i].key === "server_time"){
                          //Convert ISO to datetime
                          tempRow.push(convertDatetoCSVFormat(item.server_time));
                      } else if (csvHeaders[i].key === "evt"){
                          let tempDisplayNameList = [];
                          tempDisplayNameList = t(G3_EVENT_ID[item.evt]?.displayName).split(",");
                          if(G3_EVENT_ID[item.evt] === undefined){
                            //console.log(item);
                          }
                          for(let z=0; z < tempDisplayNameList.length; z++){
                            tempRow.push(tempDisplayNameList[z]);
                          }
                          //tempRow.push(t(G3_EVENT_ID[item.evt].displayName));
                      } else if (csvHeaders[i].key === "evl"){
                        if (item.evl)  {
                          tempRow.push(t(item.evl));
                        } else{
                          tempRow.push('');
                        }
                      }
                      else{
                          tempRow.push(data_in[k][csvHeaders[i].key]);
                      }
                  }
              }
              csvData.push(tempRow);
          }
      }
      setCSVReportData(csvData);
    };

    const downloadCSVReport = (event) => {
      console.log("Downloading CSV file...");
      csvLink.current.link.click();
    };
    
    return (
        <Grid container item direction='column'>
          <CSVLink
                data={csvReportData}
                filename={csvFileName}
                className='hidden'
                ref={csvLink}
                target='_blank' />
          <Grid>
            <Typography>
              <Box sx={{ 
                    fontSize: 12,
                    fontStyle: 'italic', m: 1 }}>
                {t('eventLagInfo')}
                </Box>
              </Typography>
          </Grid>
          <Divider/>
          <Grid item xs container direction='row-reverse' >
            <EventsFilter filtTypes={typesFilter} currentLookbackDays={lookBackDays} updateTypesFilter={updateTypesFilter} updateLookback={updateLookback}/>
            <Tooltip title={t("download")}>
              <IconButton sx={{color: theme.palette.secondary.light}} onClick={downloadCSVReport}>
                <DownloadIcon/>
              </IconButton>
            </Tooltip>
            <IconButton size='small' disabled={deviceInfo.connection > 0 ? false : true} sx={{color: theme.palette.secondary.light}} onClick={handle_fetchEvents}>
                <SyncIcon/>
            </IconButton>
            <Stack 
              direction='row' 
              spacing={1} 
              sx={{margin: theme.spacing(1)}}>
                <Chip label={lookBackDays + " " + t('days')} size='small'/>
            </Stack>
            <DisplayFilterTypesChips filtTypes={typesFilter}/>
          </Grid>
          <Divider/>
          <Grid item xs>
            {displayData.length > 0 ? 
              <DisplayEventsList events={displayData} device={deviceInfo}/>
              : <Typography sx={{margin: theme.spacing(2)}}>{t('eventsNoData')}</Typography>}
          </Grid>
        </Grid>
    )
}
