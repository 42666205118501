import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    InputLabel,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    Edit
} from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';
import users_inf from '../../services/users_inf';
import ChangePasswordButton from './ChangePasswordButton';

const InfoTypography = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(3),
    //marginTop: theme.spacing(2),
}));

const TitleGrid = styled(Grid)(({ theme }) => ({
    width: "200px",
}));
const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: "400px",
}));


export default function UserInfocontent(props){
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [editActive, setEditActive] = useState(false);
    const [userInfoFetched, setUserInfoFetched] = useState(false);
    const [userInfo, setUserInfo] = useState(undefined);
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [preferredUnits, setPreferredUnits] = useState('uscs');
    // const [oldGivenName, setOldGivenName] = useState('');
    // const [oldFamilyName, setOldFamilyName] = useState('');
    // const [oldPhoneNumber, setOldPhoneNumber] = useState('');
    const [landDialog, setLandDialog] = useState(false);
    const [language, setSelectedLanguage] = useState(i18n.language);
    const [dayLightSaving, setDayLightSaving] = useState();
    const [DLSEnabled, setDLSEnabled] = useState();
    const [formError, setFormError] = useState('none');
    const langs = {"en":{displayLabel: "English"}, "fr": {displayLabel: "Français"}, "es":{displayLabel: "Español"}, "de":{displayLabel: "Deutsch"}};
    const langlist = [{key: 'en', displayLabel: "English"}, {key: 'fr', displayLabel: "Français"}, {key: 'es', displayLabel: "Español"}, {key: 'de', displayLabel: "Deutsch"}];
    const handleClickEdit = () => {
        setFormError('none');
        if (editActive) {
            setEditActive(false)
        } else {
            setEditActive(true)
        };
    };
    
    const handleClickSubmit = async () => {
        setUserInfoFetched(false);
        if (givenName === '') {
            setFormError(t("mustIncludeAFirstName"));
            return;
        }

        if (familyName === '') {
            setFormError(t("mustIncludeALastName"));
            return;
        }

        else {
            setFormError('none');
        }

        try {
            let user = await Auth.currentAuthenticatedUser();
            var newNum = ''
            if(phoneNumber){
                if(!phoneNumber.includes('+') && phoneNumber !== ''){
                    newNum = '+' + phoneNumber;
                }
                else{
                    newNum = phoneNumber;
                }
            }

            await Auth.updateUserAttributes(user, {
                given_name: givenName,
                family_name: familyName,
                phone_number: (phoneNumber !== '' ? newNum : phoneNumber),
            });
            
            const {status, error} = await users_inf.editUserData(
                userInfo.email, 
                givenName, 
                familyName,
                dayLightSaving, 
                newNum, 
                language, 
                preferredUnits
            )
            if (status === 404) {
                return;
            }
    
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
              throw new Error(error);
            }
            navigate(0);
        } catch (error) {
            console.log(error.message);
            setFormError(error.message);
        }
    };

    const fetchUserSettings = async (abortController={signal: undefined}) => {
        try {
            const{status, error, userSettings} = await users_inf.getUserData(
                userInfo.email,
                abortController
            );
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                //alert(t(error));
            throw new Error(error);
            }
            if(userSettings["consider_daylight"] === '1'){
                setDLSEnabled("dayLightSavingEnabled");
                setDayLightSaving(1);
            }
            else{
                setDLSEnabled("dayLightSavingDisabled");
                setDayLightSaving(0);
            }
            if(userSettings["language"]){
                setSelectedLanguage(userSettings['language']);
            } 
            if(userSettings["preferred_units"]){
                setPreferredUnits(userSettings['preferred_units']);
            }
        }
        catch (error) {
            setFormError(error.message);
        }
    };
    
    async function fetchUserInfo(abortController={signal: undefined}) {
        try {
            let user = await Auth.currentUserInfo();
            setUserInfo(user.attributes);
        } catch (error) {
            console.log(error);
            setFormError(error.message);
        }
    };

    function closeLangDialog(){
        setSelectedLanguage('');
        setLandDialog(false);
    }

    const handleLanguageChange = () => {
        if(language === "English"){
            i18n.changeLanguage('en');
        }
        else if(language === "French"){
            i18n.changeLanguage('fr');
        }
        else if(language === "Spanish"){
            i18n.changeLanguage('es');
        }
        closeLangDialog();
    }

    const handleSelectLang = (event) => {
        setSelectedLanguage(event.target.value);
    }
    const handleCheck = (event) => {
        if (event.target.checked){
            setDayLightSaving(1);
        }
        else {
            setDayLightSaving(0);
        }
    };

    const handleChangeUnits = (event) => {
        if (event.target.value === "metric"){
          setPreferredUnits("metric");
        } else{
          setPreferredUnits("uscs");
        }
      };
  
    const UnitSelector = () => {
        return (
            <FormControl size="small">
            <InputLabel id="unit-selector-label">{t('units')}</InputLabel>
            <Select
                labelId="unit-selector-label"
                id="unit-selector"
                value={preferredUnits === "metric" ? "metric" : "uscs"}
                onChange={handleChangeUnits}
                label={t("units")}
            >
                <MenuItem value="uscs">{t('unitsUSCS')}</MenuItem>
                <MenuItem value="metric">{t('unitsMetric')}</MenuItem>
            </Select>
            </FormControl>
        );
    };

    React.useEffect(() => {
        fetchUserInfo();
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        fetchUserSettings(abortController);
        return () => abortController.abort();
    },[userInfo]);
    
    React.useEffect(() => {
        if (userInfo !== undefined) {
            setGivenName(userInfo.given_name);
            setFamilyName(userInfo.family_name);
            setPhoneNumber(userInfo.phone_number);
            if (userInfo.preferred_units)
            {
                setPreferredUnits(userInfo.perferred_units);
            }

            setUserInfoFetched(true)
        }
    }, [userInfo]);

    return (
        <>
        {!userInfoFetched ? <Grid item xs container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                 >
                                    <CircularProgress sx={{margin: theme.spacing(4)}}/>
                                </Grid>
        : <Grid container item xs
            direction='column'
            alignItems='flex-start'
            >
            <Grid item xs >
                <Typography variant="h6" component="span">{t("userProfile")}
                {editActive
                        ? null
                        : <Tooltip title={t("editProfile")}>
                            <IconButton color='primary' onClick={handleClickEdit} size="large">
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    }
                </Typography> 
                
            
                {!editActive && <Grid item xs 
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start" 
                        rowSpacing={1}
                        >
                    <Grid item container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <TitleGrid item xs>
                            <InfoTypography>
                                {t('email')}
                            </InfoTypography>
                        </TitleGrid>
                        <Grid item xs>
                            <InfoTypography>{userInfo.email}</InfoTypography>
                        </Grid>    
                    </Grid>
                    <Grid item container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <TitleGrid item xs>
                            <InfoTypography>
                                {t('password')}
                            </InfoTypography>
                        </TitleGrid>
                        <Grid item xs>
                            <ChangePasswordButton setFormError={setFormError}/>
                        </Grid>    
                    </Grid>
                    <Grid item container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <TitleGrid item xs>
                            <InfoTypography>
                                {t('firstName')}
                            </InfoTypography>
                        </TitleGrid>
                        <Grid item xs>
                            <InfoTypography>{userInfo.given_name}</InfoTypography>
                        </Grid>    
                    </Grid>
                    <Grid item container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <TitleGrid item xs>
                            <InfoTypography>
                                {t('lastName')}
                            </InfoTypography>
                        </TitleGrid>
                        <Grid item xs>
                            <InfoTypography>{userInfo.family_name}</InfoTypography>
                        </Grid>    
                    </Grid>
                    <Grid item container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <TitleGrid item xs>
                            <InfoTypography>
                                {t('phoneNumber')}
                            </InfoTypography>
                        </TitleGrid>
                        <Grid item xs>
                            <InfoTypography>{userInfo.phone_number}</InfoTypography>
                        </Grid>    
                    </Grid>
                    <Grid item container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <TitleGrid item xs>
                            <InfoTypography>
                                {t('appLanguage')}
                            </InfoTypography>
                        </TitleGrid>
                        <Grid item xs>
                            <InfoTypography>{langs[language].displayLabel}</InfoTypography>
                        </Grid>    
                    </Grid>
                    <Grid item container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <TitleGrid item xs>
                            <InfoTypography>
                                {t('defaultUnits')}
                            </InfoTypography>
                        </TitleGrid>
                        <Grid item xs>
                            <InfoTypography>{preferredUnits === "metric" ? t("unitsMetric") : t("unitsUSCS")}</InfoTypography>
                        </Grid>    
                    </Grid>
                    <Grid item container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <TitleGrid item xs>
                            <InfoTypography>
                                {t('dayLightSaving')}
                            </InfoTypography>
                        </TitleGrid>
                        <Grid item xs>
                            <InfoTypography>{t(DLSEnabled)}</InfoTypography>
                        </Grid>    
                    </Grid>
                </Grid>}
                {editActive && <Grid paddingTop={1} container direction='column' item xs>
                    <StyledFormControl >
                        <TextField sx={{paddingBottom: 2}} disabled label={t("email")}
                            value={userInfo.email}
                            onChange={()=>{}}/>
                        <TextField sx={{paddingBottom: 2}} label={t("firstName")}
                            value={givenName}
                            onChange={(event) => {setGivenName(event.target.value)}}
                            />
                        <TextField sx={{paddingBottom: 2}} label={t("lastName")}
                            value={familyName}
                            onChange={(event) => {setFamilyName(event.target.value)}}
                            />
                        <Box sx={{m: 1}}>
                            <PhoneInput
                                country={'us'}
                                dropdownStyle={{color:'black'}}
                                placeholder={t("phoneNumber")}
                                value={phoneNumber}
                                onChange={phoneNumber => setPhoneNumber(phoneNumber)}/>
                        </Box>
                        <Box sx={{m: 1}}>
                            <FormControl>
                                <InputLabel id="language-select-audtowidth-label">{t('language')}</InputLabel>
                                <Select 
                                    labelId="language-select-autowidth-label"
                                    label={t('language')}
                                    //size="small"
                                    value={language}
                                    autoWidth
                                    onChange={handleSelectLang}
                                    >
                                        {langlist.map(lang => {
                                            return(
                                                <MenuItem value={lang.key}>
                                                    {lang.displayLabel}
                                                </MenuItem> 
                                            );  
                                        })} 
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{m: 1}} >
                            <FormControl>
                                <UnitSelector/>
                            </FormControl>
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={dayLightSaving === 1}
                                    onChange={handleCheck}
                                    name="defIndicator"
                                    color="primary"
                                    />}
                            label = {t("considerDaylightSavings")}
                            />
                        {formError !== 'none'
                                ? <Typography component="span" color='error'>{formError}</Typography>
                                : null}
                    <Button
                        sx={{margin:theme.spacing(1)}}
                        color="secondary"
                        size="small"
                        variant="contained"
                        onClick={handleClickSubmit}>
                        {t("save")}
                    </Button>
                    <Button 
                        sx={{margin:theme.spacing(1)}}
                        color="primary"
                        size="small"
                        onClick={handleClickEdit}>
                        {t("cancel")}
                    </Button>
                    </StyledFormControl>
                </Grid>}
            </Grid>
        </Grid>}
        {landDialog && <Dialog
            disableEscapeKeyDown
            open={landDialog}
            onClose={closeLangDialog}
            fullScreen={fullScreen}>
                <DialogContent>
                    <Typography>{t("changeLanguageDialog")}</Typography>
                    <Grid item xs>
                            <FormControlLabel sx={{ m: 1 }} 
                                control={
                                    <Select 
                                        size="small"
                                        labelId='select Language'
                                        value={language}
                                        onChange={handleSelectLang}
                                        >
                                            {langs.map(lang => {
                                                return(
                                                    <MenuItem value={lang}>
                                                        <li>{lang}</li>
                                                    </MenuItem> 
                                                );  
                                            })} 
                                    </Select>
                                }>
                            </FormControlLabel>
                        </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={closeLangDialog} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleLanguageChange} color="primary" disabled={!language}>
                            {t("confirmLanguage")}
                    </Button>
                </DialogActions>
            </Dialog>}
        </>
    );
}