
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import {
    Alert,
    Button,
    InputAdornment,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    TextField
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

export default function ChangePasswordButton(){
    const { t } = useTranslation();
    const theme = useTheme();
    
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [openChangePassword, setOpenChangePassword] = useState(false);
    
    const toggleShowPassword = (field) => {
        if (field === 'old') setShowOldPassword(!showOldPassword);
        else if (field === 'new') setShowNewPassword(!showNewPassword);
        else if (field === 'confirm') setShowConfirmNewPassword(!showConfirmNewPassword);
    };
    
    const handleOpenChangePw = () => {
        setOpenChangePassword(true);
    };

    const handleCloseChangePw = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setErrMessage('');
        setOpenChangePassword(false);
    };

    const handleClickSubmitChangePWord = async (event) => {
        event.preventDefault()

        if (newPassword !== confirmNewPassword) {
            setErrMessage('passwordMismatch');
            return;
        }

        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, oldPassword, newPassword);
            setErrMessage('pwChangeSuccess');
            setTimeout(() => handleCloseChangePw(), 1000);
        } catch (error) {
            console.log(error);
            if (error.name === 'LimitExceededException')
            {
                setErrMessage('pwChangeErrorLimitExceeded');
            } else if (error.name === 'NotAuthorizedException')
            {
                setErrMessage('pwChangeErrorNotAuthorized');
            } else
            {
                setErrMessage('pwChangeError');
            }
        }

    };
    return(
        <Grid item xs>
            <Button 
                sx={{
                    width: '30ch',
                }}
                color="secondary"
                size="small"
                variant="contained"
                onClick={handleOpenChangePw}>
                {t("changePassword")}
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={openChangePassword}
                onClose={handleCloseChangePw}
                TransitionComponent={Transition}
                BackdropProps={{style: {backgroundColor: 'black'}}}
                fullWidth={!fullScreen}
            >
            <DialogTitle>{t('changePassword')}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleClickSubmitChangePWord}>
                    <TextField
                        label={t("currentPassword_ast")}
                        type={showOldPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => toggleShowPassword('old')}
                                  edge="end"
                                >
                                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label={t("newPassword_ast")}
                        type={showNewPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        autoComplete="new-password"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => toggleShowPassword('new')}
                                  edge="end"
                                >
                                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label={t("newPasswordConfirm_ast")}
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                        autoComplete="new-password"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => toggleShowPassword('confirm')}
                                  edge="end"
                                >
                                  {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                        }}
                    />
                    {errMessage === 'pwChangeSuccess' ? <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{ mt: 2 }}>{t(errMessage)}</Alert>
                        : errMessage && <Alert severity="error" sx={{ mt: 2 }}>{t(errMessage)}</Alert>}
                    <Grid container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            padding: theme.spacing(2),
                        }}
                    >
                        <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                            <Button 
                                type='submit' 
                                fullWidth 
                                autoFocus 
                                variant='contained' 
                                color='secondary' 
                                onClick={handleClickSubmitChangePWord}>
                                    {t('submit')}
                            </Button>
                        </Grid>
                        <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                            <Button fullWidth 
                                autoFocus
                                color='inherit'
                                onClick={handleCloseChangePw}
                            >
                                {t('cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            </Dialog>
        </Grid>
    );
}