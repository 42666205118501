import React, { useEffect, useState, useRef} from 'react';
import {
    useNavigate,
  } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import LubricationIntervalSettings from '../../MainPage/DevicePage/Components/LubricationIntervalSettings';
import { 
    advanced_parameter_obj,
    interval_parameters_obj,
    low_level_parameter_obj,
    lubrication_parameters_obj,
    outputs_parameter_obj,
    config_obj_type,
    hw_config_obj
     } from '../../DeviceInfo/g3_Config.js';
import {DEVICE_TYPES} from '../../DeviceInfo/deviceTypes.js';
import configurations_inf from '../../services/configurations_inf';
import LubricationEndSettings from '../../MainPage/DevicePage/Components/LubricationEndSettings';
import LowLevelSettings from '../../MainPage/DevicePage/Components/LowLevelSettings.jsx';
import OutputSettings from '../../MainPage/DevicePage/Components/OutputSettings.jsx';
import AdvancedSettings from '../../MainPage/DevicePage/Components/AdvancedSettings.jsx';

import { sanitizeObjectNameNotUrl_64char, sanitizeObjectName_30char } from '../../helpers/sanitizeObjectName.js';

function generateDeviceTypes()
{
    let types = [];
    Object.keys(DEVICE_TYPES).forEach(key =>{
        types.push({name: DEVICE_TYPES[key].name, id: key, key: DEVICE_TYPES[key].name+'type_key'})
    })
    return types;
}

export default function NewTemplatePage(accountInfo) {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    const [templateName, setName] = useState("");
    const rTemplateName = useRef();
    const [desc, setDescription] = useState("");
    const rDescription = useRef();
    //const [types, setTypes] = useState(["G3 FAKE", "G3 MAX", "G3 MAX FT","G3 MAX Pressure"]); //Current types, add more later
    const [types, setTypes] = useState(generateDeviceTypes());
    const [hw_config, set_hw_config] = useState(config_obj_type[2]);
    //const [selectedType, setSelectedType] = useState('2');
    const [selectedTypeId, setSelectedTypeId] = useState('2');
    const [editing, setEditing] = useState(false);
    const [configData, setConfigData]= useState();
    const [reRender, setreRender] =useState(false);
    //Type Checks
    const [display, setDisplay] = useState(false);
    
    useEffect(() =>{
        getDeviceTypes();
    },[accountInfo]);

    const setDefaults = (hw_config) => {
        function defaults_by_hw_config(param_obj, hwConfig){
            let useDisabledDefault = false;
            let output = String(param_obj.default);
            Object.keys(hw_config_obj).forEach(key => {
                    if(hw_config_obj[key].affected_config_id.includes(String(param_obj.id))){
                        if (!hwConfig.includes(hw_config_obj[key].id)){
                            useDisabledDefault = true;
                        }
                    }
            });
            if (useDisabledDefault){
                output = String(param_obj.disabled_default);
            }
            return (output);
        }
        let data = {};
        let t_config = {};
        // Object.keys(pin_code_parameters_obj).forEach(key => {
        //     t_config[key] = defaults_by_hw_config(pin_code_parameters_obj[key], hw_config);
        // });
        Object.keys(interval_parameters_obj).forEach(key => {
                t_config[key] = defaults_by_hw_config(interval_parameters_obj[key], hw_config);
        });
        Object.keys(lubrication_parameters_obj).forEach(key => {
                t_config[key] = defaults_by_hw_config(lubrication_parameters_obj[key], hw_config);
        });
        Object.keys(low_level_parameter_obj).forEach(key => {
                t_config[key] =  defaults_by_hw_config(low_level_parameter_obj[key], hw_config);
        });
        Object.keys(outputs_parameter_obj).forEach(key => {
                t_config[key] = defaults_by_hw_config(outputs_parameter_obj[key], hw_config);
        });
        Object.keys(advanced_parameter_obj).forEach(key => {
                t_config[key] = defaults_by_hw_config(advanced_parameter_obj[key], hw_config);
        });
        data.config = t_config;
        setConfigData(data);

        //Makes G3 MAX default in load only checks this once
        // if(Object.keys(selectedType).length === 0){
        //     for(let i=0; i<types.length; i++){
        //         if(types[i].id === '2'){
        //             setSelectedType(types[i])
        //             //setSelectedTypeId(types[i].id)
        //         }
        //     }
        // }
    };

    function getDeviceTypes(){
        set_hw_config(config_obj_type[selectedTypeId]);
        setDefaults(config_obj_type[selectedTypeId]);
        setDisplay(true);
        handleStartEdit();
    };

    const handleSelectType = (event) => {
        let temp = event.target.value;
        let temp_type_id = 2;
        let temp_hw_config = config_obj_type[temp_type_id];
        
        for (let i = 0; i < types.length; i++)
        {
            if (types[i].id === temp)
            {
                setSelectedTypeId(types[i].id)
                temp_type_id = types[i].id;
                break;
            }
        }
        temp_hw_config = config_obj_type[temp_type_id];
        set_hw_config(config_obj_type[temp_type_id]);
        setDefaults(temp_hw_config);
        setDisplay(true);
        
        handleStartEdit();
    };

      const handleStartEdit = () => {
        setEditing(true);
    };

    const cancelEdit = () => {
        setEditing(false);
    };

    function handleSave(){
        if(!templateName){
            alert(t("templateNameIncomplete"));
        }
        if(selectedTypeId === null || selectedTypeId === " "){
            alert(t("tempateTypeNotSelected"));
        }
        else{
            createTemplate();
        }
    }

    const createTemplate = async() =>{
        try{
                const { status, error } = await configurations_inf.createTemplate(
                    accountInfo.accountInfo.accountId, templateName, desc, selectedTypeId, configData["config"]
                );
                
                if (status === 404) {
                    return;
                }
                if (error !== 'OK' || status !== 200) {
                    if(error.includes("Unable to connect to database")){
                        alert(t("failedToConnectToDatabase"));                
                    }
                    if(error.includes("Template name already exists")){
                        alert(t("nameAlreadyExists"));
                    }
                    if(error.includes("Failed to create template")){
                        alert(t("templateCreateFail"));
                    }
                }
                else{
                    navigation(-1);
                }     
            }catch (error) {
                console.log(error);
                console.error(error);
            }
    }

    function handleCancel() {
        navigation(-1);
    }

    const handleUpdatConfig = (newSettings) => {
        let data = JSON.parse(JSON.stringify(configData));
        Object.keys(newSettings).forEach(key => {
            data.config[key] = String(newSettings[key]);
        });
        setConfigData(data);
        setreRender(!reRender);
    };
    
    return (
        <>
        <Grid container item direction='column'>
            <Grid height='64px' item xs>
                <Toolbar>
                    {editing ? <Button onClick={handleCancel}>
                        {t("cancel")}
                    </Button>
                    : <IconButton
                        edge='start'
                        color='inherit'
                        onClick={() => { navigation(-1); } }
                        aria-label='close'
                    >
                        <ArrowBack />
                    </IconButton>}
                    <Box flexGrow={1} />
                    {editing ? <Button 
                        disabled={templateName.length > 0 ? false : true}
                        onClick={handleSave} 
                        sx={{ color: theme.palette.primary.contrastText }}>
                            {t("save")}
                    </Button>: <Typography></Typography>}
                </Toolbar> 
            <Grid container direction="column" sx={{padding: theme.spacing(1)}}>
            <Box id="contentBox" height="calc(100vh - 128px - 10px)" overflow='auto'>
                <Grid container direction="row" sx={{padding: theme.spacing(1)}}>
                    <FormControl>
                        <Stack spacing={2}>
                            <TextField type="test" label={t("name")}
                                error={templateName.length > 0 ? false : true}
                                inputRef={rTemplateName}
                                value={templateName}
                                onChange={(e) => {
                                    if (sanitizeObjectName_30char(e.target.value)) {
                                        setName(e.target.value);
                                    }
                                }
                                }/>
                        </Stack>
                    </FormControl>
                </Grid>
                <Grid container direction="row" sx={{padding: theme.spacing(1)}}>
                    <FormControl> 
                        <Stack spacing={2}>
                            <TextField type="test" label={t("templateDescription")}
                                multiline={true}
                                rows={2}
                                style={{ width: "calc(100vh-500px)", height:"calc(100vh-75px)" }}
                                inputRef={rDescription}
                                value={desc}
                                onChange={(e) => {
                                    if(sanitizeObjectNameNotUrl_64char(e.target.value)){
                                        setDescription(e.target.value);
                                    }
                                }
                                }/>
                        </Stack>
                    </FormControl>
                </Grid>
                <Grid container direction="row" sx={{padding: theme.spacing(1)}}>
                    <FormControl sx={{
                        minWidth: 200,
                    }}> 
                        <Stack spacing={2}>   
                            <Select
                                sx={{padding: theme.spacing(1)}}
                                value={selectedTypeId}
                                onChange={handleSelectType}
                                >
                                {types.map(type => {
                                    return(
                                        <MenuItem value={type.id}>
                                            <ListItemText>{type.name}</ListItemText>
                                        </MenuItem> 
                                    );  
                                })} 
                            </Select>
                            <InputLabel id="Select Type" >{t("templateType")}</InputLabel>
                        </Stack>
                    </FormControl>
                </Grid>
                    {editing ? <Grid sx={{
                        minWidth: 300,
                        }}
                        item 
                        container 
                        direction='column'>
                        {display &&  <Grid container 
                                direction="column"
                                sx={{padding: theme.spacing(1)}}>
                                <LubricationIntervalSettings editing={editing} currentSettings={configData.config} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
                                <LubricationEndSettings editing={editing} currentSettings={configData.config} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
                                <LowLevelSettings editing={editing} currentSettings={configData.config} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
                                <OutputSettings editing={editing} currentSettings={configData.config} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
                                <AdvancedSettings editing={editing} currentSettings={configData.config} hwConfig={hw_config} updateSettings={handleUpdatConfig}/>
                            </Grid>}
                        </Grid>: 
                    <Typography sx={{marginLeft: theme.spacing(2)}}>{t("selectAType")}</Typography>}
                </Box>
            </Grid>
            </Grid>
        </Grid>
        </>
        )
}
