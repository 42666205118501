import { DEVICE_TYPES, lubrication_low_level_analog_types } from "../DeviceInfo/deviceTypes";
import * as g3 from "../DeviceInfo/g3_Config";

export const isLevelAnalog = (deviceType, config) => {
    var isAnalog = false;
    var lowLevelParameters;
    var targetParameterName;
    if (DEVICE_TYPES[deviceType.toString()].name.includes('G3')){
        lowLevelParameters = g3.low_level_parameter_obj;
        targetParameterName = "input1_type";
    }
    if (config !== undefined){
        var low_level_analog_types_list = [];
        
        for (let i=0; i<lubrication_low_level_analog_types.length; i++){
            low_level_analog_types_list.push((lubrication_low_level_analog_types[i].key).toString())
        }
        Object.keys(lowLevelParameters).forEach((key) => {
            if (lowLevelParameters[key].name === targetParameterName){
                if (low_level_analog_types_list.includes(config[key.toString()])){
                    isAnalog = true;
                }
            }
        })
    }
    return isAnalog;
}