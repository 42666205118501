import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'; // React 18
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';
import App from './App.jsx';




// Start of the app. Browser router allows navigation by url.

//React 17
/*
const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  rootElement
);
*/
//React 18

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
        <App />
  </React.StrictMode>
);


// Service worker can be registered to allow faster loading and offline content
serviceWorker.unregister();
