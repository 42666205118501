import React, { useState } from 'react';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import {useTheme} from '@emotion/react';
import { Typography, IconButton } from "@mui/material";
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const InstructionsDialogue = ({ title, instructions, dialogueOpen, handleClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [displayText, setDisplayText] = useState([t(instructions)]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const close = () => {
    handleClose();
  };
  React.useEffect(() => { 
    setDisplayText(t(instructions).split("\n"));
  }, [dialogueOpen, instructions]);
  
  return (
    <Dialog
        fullScreen={fullScreen}
        open={dialogueOpen ? true : false}
        onClose={close}
        TransitionComponent={Transition}
        BackdropProps={{style: {backgroundColor: 'black'}}}
        fullWidth={!fullScreen}
      >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={close}
                    aria-label='close'
                    >
                        <ArrowBackIcon/>
                </IconButton>
                <Typography align="center" sx={{ m1: 2, flex: 1}} component="div">
                    {t(title)}
                </Typography>
            </Toolbar>
        </AppBar>
        <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                padding: theme.spacing(2),
            }}
        >
            <Grid item xs >
                {displayText.map((infoText) => {
                    return(
                        <Typography sx={{marginTop: theme.spacing(1)}}>
                            {t(infoText)}
                        </Typography>
                    )
                })}
                {/* <Typography sx={{marginTop: theme.spacing(1)}}>
                    {t(instructions)}
                </Typography> */}
            </Grid>
            
            {true && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                <Button fullWidth 
                    autoFocus
                    color='inherit'
                    onClick={close}
                >
                    {t('close')}
                </Button>
            </Grid>}
        </Grid>
    </Dialog>
  );
};

export default InstructionsDialogue;
