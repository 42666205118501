import React, { useState, useRef } from 'react';
import {useTheme} from '@emotion/react';
import { 
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Switch,
    Typography, 
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { StyledSwitch } from '../styledComponentsCommon';

const ToggleListItem = ({ label, param_id, value, handleClick=(key)=>{}, clickDisabled=false, invertEmphasis=false }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <ListItem divider key={label} disablePadding>
        <ListItemText  primaryTypographyProps={{
                marginLeft: theme.spacing(2),
                fontWeight: invertEmphasis ? undefined : "bold",
                color: invertEmphasis ? theme.palette.primary.main : undefined,
            }}
            primary={t(label)}
        />
        <ListItemIcon>
            <Typography sx={{
                    fontWeight: invertEmphasis ? "bold" : undefined,
                    color: invertEmphasis ? undefined : theme.palette.primary.main
                    }}>
                {value}
            </Typography>
            <StyledSwitch 
                disabled={clickDisabled} 
                onClick={() => {handleClick(param_id ? param_id : label)}} 
                checked={value} sx={{marginLeft: theme.spacing(1)}}/>
        </ListItemIcon>
    </ListItem>
  );
};

export default ToggleListItem;
