import React from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
    Box,
    ClickAwayListener,
    Divider,
    Drawer,
    Grid,
    Grow,
    Hidden,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem, 
    MenuList,
    Paper,
    Popper,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';

import {useTheme} from '@emotion/react';
import {
    AccountCircle,
    ArrowDropDown,
    Edit,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import GroupsIcon from '@mui/icons-material/Groups';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HelpIcon from '@mui/icons-material/Help';
import ListIcon from '@mui/icons-material/List';
import OpacityIcon from '@mui/icons-material/Opacity';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ReportIcon from '@mui/icons-material/Report';
import ReceiptIcon from '@mui/icons-material/Receipt';
import logoPulse from './assets/Graco_Logo_Horiz_White.png';
import logoPulseMobile from './assets/Graco_Logo_Horiz_White.png';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import { GrowDiv } from '../styledComponentsCommon';
import BrandingIcon from './assets/BrandingIcon';
import GracoIcon from './assets/GracoIcon';
import {enableBilling} from '../data';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const logo = logoPulse;
const logoMobile = logoPulseMobile;

const menuListNav = {
    'myDevices': {id:'myDevices', nav:"/"},
    //'deviceConfigurations': {id:'deviceConfigurations', nav:"/accounts/{accountid}/configurations"},
    'configurations': {id: 'configurations', nav:'configurations'},
    'groups': {id:'groups', nav:"/groups"},
    //'userSettings': {id:'userSettings', nav:"/profile"},
    'accountManagement': {id:'accountManagement', nav:"accounts/{accountid}/manage/"},
    'accountBilling': {id: 'accountBilling', nav:"accounts/{accountid}/billing"},
    'userSettings':{id: 'userSettings', nav:"profile/"},
    'help':{id:'help', nav:"help/"},
    'accountNotifications':{id: 'accountNotifications', nav:"notifications/" }
}

export default function Header({ accountInfo, accounts, accountNavigationHandler }) {
    const navigation = useNavigate();
    const theme = useTheme();
    const [accountOpen, setAccountOpen] = React.useState(false);
    const [accountAnchor, setAccountAnchor] = React.useState(null);
    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const [settingsAnchor, setSettingsAnchor] = React.useState(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const { t, i18n } = useTranslation();
    

    const handleAccountOpen = (event) => {
        setAccountAnchor(event.currentTarget);
        setAccountOpen(true);
    };

    const handleAccountClose = () => {
        setAccountOpen(false);
    };

    const handleAccountClick = (selected) => {
        handleAccountClose();
        accountNavigationHandler(selected);
    };

    const handleNavigation = (event, id) => {
        if (menuListNav[id]){
            let url = menuListNav[id].nav;
            url = url.replace("{accountid}", accountInfo.accountName);
            navigation(url);
        }
        setOpenDrawer(false);
    };

    const handleSettingsClose = () => {
        setSettingsOpen(false);
    };

    const handleSignOut = () => {
        Auth.signOut();
        navigation('/');
        setOpenDrawer(false);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawer(open);
    }

    return (
        <div>
            <React.Fragment key='left'>
                <Drawer
                    PaperProps={{
                        sx: {
                          backgroundColor: theme.palette.secondary.main,
                        }
                    }}
                    anchor='left'
                    open={openDrawer}
                    onClose={toggleDrawer(false)}
                >
                     <Box
                        sx={{ 
                            width: 250,
                        }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem key={'myDevices'} disablePadding>
                                <ListItemButton id={'myDevices'} onClick={(event) => {handleNavigation(event,'myDevices')}}>
                                    <ListItemIcon>
                                         
                                    </ListItemIcon>
                                    <ListItemText primary={t('myDevices')}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            {accountInfo.accountName !=='' && accountInfo.isAccountTech ? <ListItem key={'confiigurations'} disablePadding>
                                <ListItemButton id={'configurations'} onClick={(event)=>{handleNavigation(event,'configurations')}}>
                                    <ListItemIcon>
                                        <SettingsApplicationsIcon fontSize="small" /> 
                                    </ListItemIcon>
                                    <ListItemText primary={t('accountConfigurations')}/>
                                </ListItemButton>
                            </ListItem> : null}
                            {accountInfo.accountName !=='' && accountInfo.isAccountEditor ? <ListItem key={'groups'} disablePadding>
                                <ListItemButton onClick={(event) => {handleNavigation(event,'groups')}}>
                                    <ListItemIcon>
                                        <GroupsIcon fontSize="small" /> 
                                    </ListItemIcon>
                                    <ListItemText primary={t('groups')}/>
                                </ListItemButton>
                            </ListItem> : null}
                            {accountInfo.accountName !=='' && accountInfo.isAccountEditor ? <ListItem key={'accountManagement'} disablePadding>
                                <ListItemButton id={'accountManagement'} onClick={(event)=>{handleNavigation(event,'accountManagement')}}>
                                    <ListItemIcon>
                                        <SettingsApplicationsIcon fontSize="small" /> 
                                    </ListItemIcon>
                                    <ListItemText primary={t('accountManagement')}/>
                                </ListItemButton>
                            </ListItem> : null}
                            {accountInfo.isAccountOwner && (enableBilling) ? <ListItem key={'accountBilling'} disablePadding>
                                <ListItemButton id={'accountBilling'} onClick={(event)=>{handleNavigation(event,'accountBilling')}}>
                                    <ListItemIcon>
                                        <ReceiptIcon fontSize="small" /> 
                                    </ListItemIcon>
                                    <ListItemText primary={t('accountBilling')}/>
                                </ListItemButton>
                            </ListItem> : null}
                        </List>
                        {accountInfo.accountName !=='' && (accountInfo.isAccountTech || accountInfo.isAccountEditor || accountInfo.isOwner) ? <Divider /> : null}
                        <List>
                            <ListItem key={'userSettings'} disablePadding>
                                <ListItemButton onClick={(event) => {handleNavigation(event,'userSettings')}}>
                                    <ListItemIcon>
                                        <AccountCircle fontSize="small" /> 
                                    </ListItemIcon>
                                    <ListItemText primary={t('userSettings')}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={'accountNotifications'} disablePadding>
                                <ListItemButton onClick={(event) => {handleNavigation(event,'accountNotifications')}}>
                                    <ListItemIcon>
                                        <ReportIcon fontSize="small" /> 
                                    </ListItemIcon>
                                    <ListItemText primary={t('accountNotifications')}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={'help'} disablePadding>
                                <ListItemButton onClick={(event) => {handleNavigation(event,'help')}}>
                                    <ListItemIcon>
                                        <HelpIcon fontSize="small" /> 
                                    </ListItemIcon>
                                    <ListItemText primary={t('help')}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={'logOut'} disablePadding>
                                <ListItemButton onClick={handleSignOut}>
                                    <ListItemIcon>
                                        <ExitToAppIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('logOut')}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Divider />
                    </Box>
                    <GrowDiv />
                    {/* <BrandingIcon sx={{ fontSize: 230, marginBottm: '50px'}}/> */}
                </Drawer>
            </React.Fragment>
            <Box height="64px" display="flex" flexDirection="column" sx={{backgroundColor: theme.palette.secondary.main}}>
                <Toolbar >
                    <Tooltip title={t("settings")}>
                        <IconButton
                            edge='end'
                            aria-controls={settingsOpen ? 'settings-list-grow' : undefined}
                            ria-haspopup='true'
                            onClick={toggleDrawer(openDrawer ? false : true)}
                            color='inherit'
                            size="large">
                            <Grid paddingRight={1} justifyContent= "space-between">
                                <GracoIcon/>
                            </Grid> 
                            <Grid paddingLeft={1} justifyContent= "space-between">
                                <MenuIcon/>
                            </Grid> 
                        </IconButton>      
                    </Tooltip>
                    <GrowDiv />
                    <Hidden lgUp>
                        <Tooltip title={t("selectAccount")}>
                            <IconButton
                                aria-controls={accountOpen ? 'org-list-grow' : undefined}
                                edge='start'
                                aria-haspopup='true'
                                color='inherit'
                                onClick={handleAccountOpen}
                                size="small">
                                <Typography variant={accountInfo.accountName.length <= 12 ? 'h6' : 'body2'} noWrap>
                                {/* {accountInfo.accountName.length <= 12 ? accountInfo.accountName: (accountInfo.accountName.substr(0,12) + "...")}  */}
                                    {accountInfo.accountName}
                                </Typography>
                                <ArrowDropDown />
                            </IconButton>
                        </Tooltip>
                    </Hidden>
                    <Hidden lgDown>
                        <Tooltip title={t("selectAccount")}>
                            <IconButton
                                aria-controls={accountOpen ? 'org-list-grow' : undefined}
                                edge='start'
                                aria-haspopup='true'
                                color='inherit'
                                onClick={handleAccountOpen}
                                size="large">
                                <Typography  variant={accountInfo.accountName.length <= 12 ? 'h6' : 'body2'} noWrap>
                                    {/* {accountInfo.accountName.length <= 12 ? accountInfo.accountName: (accountInfo.accountName.substr(0,12) + "...")}  */}
                                    {accountInfo.accountName}
                                </Typography>
                                <ArrowDropDown />
                            </IconButton>
                        </Tooltip>
                    </Hidden>

                    <Popper open={accountOpen}
                        anchorEl={accountAnchor}
                        placement='bottom-start'
                        transition
                        sx={{zIndex: 1200}}
                        >

                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleAccountClose}>
                                        <MenuList autoFocusItem={accountOpen} id='org-list-grow'>
                                            {(accountInfo.isAccountEditor && accountInfo.accountName !== "") ? <MenuItem 
                                                onClick={() => { setAccountOpen(false) }} 
                                                component={RouterLink} 
                                                to={{ pathname: '/accounts/{accountName}/edit'.replace("{accountName}",accountInfo.accountName), 
                                                    state: { accountInfo: { accountInfo }, accountEdit: true } }}>
                                                <ListItemIcon style={{ minWidth: '30px' }}>
                                                    <Edit />
                                                </ListItemIcon>
                                                {accountInfo.accountName}
                                            </MenuItem>: null}
                                            {accountInfo.isAccountEditor ? <Divider /> : null}
                                            {accounts !== undefined
                                                ? accounts.map((name, index) =>
                                                    <MenuItem key={index} value={name} onClick={() => handleAccountClick(accounts[index])}>
                                                        {name}
                                                    </MenuItem>)
                                                : null}
                                            <Divider />
                                            <MenuItem component={RouterLink} to='/accounts/new' onClick={() => { setAccountOpen(false) }} >
                                                + {t("createNewAccount")}
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>)}
                    </Popper>

                    <Popper open={settingsOpen}
                        anchorEl={settingsAnchor}
                        placement='bottom-end'
                        transition>
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleSettingsClose}>
                                        <MenuList autoFocusItem={settingsOpen} id='settings-list-grow'>
                                            <Hidden mdUp>
                                                {accountInfo.isAccountEditor && <MenuItem component={RouterLink}
                                                    to={{
                                                        pathname: '/accounts/' + accountInfo.accountName + '/details',
                                                        state: {
                                                            accountInfo: { accountInfo },
                                                            accountEdit: true
                                                        }
                                                    }} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <DashboardIcon />
                                                    </ListItemIcon>
                                                    {t("dashboard")}
                                                </MenuItem>}
                                                <Divider />
                                            </Hidden>
                                            {false
                                                ? <MenuItem component={RouterLink} to={'/accounts/'.concat(accountInfo.accountName)} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <SettingsApplicationsIcon />
                                                    </ListItemIcon>
                                                    {t("settings")}
                                                </MenuItem>
                                                : null}
                                            <MenuItem component={RouterLink} to='/profile' onClick={handleSettingsClose}>
                                                <ListItemIcon>
                                                    <AccountCircle fontSize="small" />
                                                </ListItemIcon>
                                                {t("userSettings")}
                                            </MenuItem>
                                            <Divider />
                                            {accountInfo.isAccountEditor
                                                ? <MenuItem component={RouterLink} to={'/accounts/'.concat(accountInfo.accountName).concat('/Manage')} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <AccountTreeIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    {t("permissionManagement")}
                                                </MenuItem>
                                                : null}
                                            {accountInfo.isAccountOwner && enableBilling
                                                ? <MenuItem component={RouterLink} to={'/accounts/'.concat(accountInfo.accountName).concat('/Billing')} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <ListIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    {t("deviceBilling")}
                                                </MenuItem>
                                                : null}
                                            {accountInfo.isAccountEditor
                                                ? <MenuItem component={RouterLink} to={'/accounts/'.concat(accountInfo.accountName).concat('/Fluids')} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <OpacityIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    {t("fluids")}
                                                </MenuItem>
                                                : null}

                                            <MenuItem component={RouterLink} to='/help' onClick={handleSettingsClose}>
                                                <ListItemIcon>
                                                    <HelpIcon fontSize="small" />
                                                </ListItemIcon>
                                                {t("help")}
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem component={Link} href='/' onClick={handleSignOut}>
                                                <ListItemIcon>
                                                    <ExitToAppIcon fontSize="small" />
                                                </ListItemIcon>
                                                {t("logOut")}
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>)}
                    </Popper>
                </Toolbar>
            </Box>
        </div>
    );
}
