import {Auth} from 'aws-amplify';
import {host} from '../data.js'

import { BillingPlans } from '../data.js';

const LOCATION_ERROR_MESSAGE =
    'Error in billing information service! Provide, please, a data to the service!';

let urlConfig = {
    api: 'accounts/{accountid}/billing',
    apiInvoices: 'accounts/{accountid}/invoices/{invoiceId}/pdf',
    apiPreview: 'accounts/{accountid}/billing/preview',
    //proxyPort: 5000
}

export const billing = {
    createAccount: async ({orgId, tokenId, email, currency, plan_label, username}) => {
        try {
            if (!orgId || !tokenId || !email || !currency || !username) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.api.replace('{accountid}', orgId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    billing_params: {
                        email,
                        username,
                        billing_info: {
                            token_id: tokenId,
                        },
                    },
                    billing_currency: currency,
                    billing_plan: plan_label
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateAccount: async ({orgId, tokenId, email, currency, plan_label, username}) => {
        try {
            if (!orgId || !tokenId || !email ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            var l_plan = plan_label;
            if (plan_label === undefined){
                l_plan = 0;
            }
            var l_currency = currency;
            if (currency === undefined){
                l_currency = 0;
            }
            
            const url =
                host + urlConfig.api.replace('{accountid}', orgId);
            const user = await Auth.currentAuthenticatedUser();
            var testbody = {body: {
                billing_params: {
                    email,
                    billing_info: {
                        token_id: tokenId,
                    },
                },
                billing_currency: l_currency,
                billing_plan: l_plan
            }}
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    billing_params: {
                        email,
                        billing_info: {
                            token_id: tokenId,
                        },
                    },
                    billing_currency: l_currency,
                    billing_plan: l_plan
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteAccount: async (orgId) => {
        try {
            if (!orgId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountid}', orgId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getBillingData: async (orgId, abortController={signal: undefined}) => {
        try {
            if (!orgId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountid}', orgId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    newSubscription: async (
        orgId,
        plan_code,
        currency,
        quantity,
        total_billing_cycles,
    ) => {
        try {
            if (!orgId || !plan_code || !currency ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            const url =
                host + urlConfig.api.replace('{accountid}', orgId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    billing_params: {
                        subscriptions: [
                            {
                                plan_code,
                                currency,
                                quantity,
                                total_billing_cycles,
                            },
                        ],
                    },
                }),
            });

            const data = await response.json();
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateSubscriptions: async (orgId, subscriptions) => {
        try {
            if (!orgId || !Array.isArray(subscriptions)) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountid}', orgId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    billing_params: {
                        subscriptions,
                    },
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateEmails: async (orgId, cc_emails) => {
        try {
            if (!orgId || !Array.isArray(cc_emails)) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountid}', orgId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    billing_params: {
                        cc_emails,
                    },
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    downloadInvoice: async (orgId, invoiceId) => {
        try {
            if (!orgId || !invoiceId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host +
                urlConfig.apiInvoices
                    .replace('{accountid}', orgId)
                    .replace('{invoiceId}', invoiceId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });
            
            const data = await response.text();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    previewSubscription: async (orgId, subscriptions) => {
        try {
            if (!orgId || !Array.isArray(subscriptions)) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiPreview.replace('{accountid}', orgId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    billing_params: {
                        subscriptions,
                    },
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    //Other Supporting Functions
    findSubscriptionPlanCode: (subscriptionInfo) => {
        var plan_code = BillingPlans[0].plan_code;
        
        for (var i = 0; i < BillingPlans.length; i++) {
            if (BillingPlans[i].label === subscriptionInfo) {
                plan_code = BillingPlans[i].plan_code;
                break;
            }
        }

        return plan_code;
    }
};
