import React, { useState, useRef , useEffect} from 'react';
import { Auth } from 'aws-amplify';
import {
    useNavigate,
    Link,
  } from 'react-router-dom'
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    FormGroup,
    FormControlLabel,
    Grid,
    ListItem,
    ListItemButton,
    MenuItem,
    Select,
    Switch,
    Toolbar,
    Tooltip,
    ToggleButton,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { accounts } from '../../services/accounts';
import ModalPrompt from '../../CommonComponents/ModalPrompt';
import SaveIcon from '@mui/icons-material/Save';
import { GrowDiv } from '../../styledComponentsCommon';
import { StyledSwitch } from '../../styledComponentsCommon';
import users_inf from '../../services/users_inf';

const StatusReportSettingsCard = ({ account, accountInfo, number, fetchAccounts}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [accountName, setAccountName] = useState(account.name);
    const [targetAccountId, setTargetAccountId] = useState(account.id);
    const [reportText, setReportText] = useState(0);
    const [reportEmail, setReportEmail] = useState(0);
    const [oldReportText, setOldReportText] = useState(0);
    const [oldReportEmail, setOldReportEmail] = useState(0);
    const [dayList, setDayList] = useState(["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"])
    const [timeList, setTimeList] = useState(["1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", 
                                                "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00"])
    const [day, setDay] = useState();
    const [dayId, setDayId] = useState();
    const [oldDayId, setOldDayId] = useState();
    const [time, setTime] = useState();
    const [displayTime, setDisplayTime] = useState();
    const [oldTime, setOldTime] = useState();
    const [offset, setOffset] = useState();
    const [save, setSave] = useState(false);
    const [saveDialog, setSaveDialog] = useState(false);

    function filterAccount(account){
        setTargetAccountId(account.account_id);
        setAccountName(account.name);
        setReportEmail(account.notify_by_email);
        setOldReportEmail(account.notify_by_email);

        setReportText(account.notify_by_text);
        setOldReportText(account.notify_by_text);
        
        setTime(account.time);
        setOldTime(account.time);
        setOffset(account.timezone_offset)
        setDisplayTime((parseInt(account.time)).toString() + ":00");

        setDayId(account.day);
        setOldDayId(account.day);
        setDay(dayList[account.day]);
        }

    const changeReportEmail = (event) =>{
        if(event.target.checked){
          setReportEmail(1); 
        }
        else{
            setReportEmail(0);
        }
        setSave(true);
    }
    const changeReportText = (event) =>{
        if(event.target.checked){
            setReportText(1); 
        }
        else{
            setReportText(0);
        }
        setSave(true);
    }

    const handleDaySelect = (event) =>{
        let temp = event.target.value;
        // Day is set 0-6
        if(temp == "Mon"){
            setDayId(0);
        }
        if(temp == "Tue"){
            setDayId(1);
        }
        if(temp == "Wed"){
            setDayId(2);
        }
        if(temp == "Thur"){
            setDayId(3);
        }
        if(temp == "Fri"){
            setDayId(4);
        }
        if(temp == "Sat"){
            setDayId(5);
        }
        if(temp == "Sun"){
            setDayId(6);
        }
        setDay(temp);
        setSave(true);
    }
    const handleTimeSelect = (event) =>{
        let temp = event.target.value;
        setDisplayTime(temp);
        let newTime = temp.replace(":00","")
        setTime(parseInt(newTime)); //Time is set 0-23
        setSave(true);
    }

    function closeSaveDialog(){
        setSave(false);
    }
    function cancelSaveDialog(){
        setReportEmail(oldReportEmail);
        setReportText(oldReportText);
        setDayId(oldDayId);
        setDay(dayList[oldDayId]);
        setTime(oldTime);
        setDisplayTime((parseInt(oldTime)).toString() + ":00");
        setSave(false);
    }


    const updateSettings = async () =>{
        // Get timezone offset //
        const d = new Date();
        let diff = d.getTimezoneOffset() / -60;
        const user = await Auth.currentAuthenticatedUser();
        const {status, error} = await users_inf.updateUserStatusReports(
            user, targetAccountId, reportEmail, reportText,
            dayId, time, diff
        )
        if (error !== 'OK' && status !== 200) {
            if (error.includes("unable to connect to database")){
                alert(t("failedToConnectToDatabase"));
            }
            //alert(t(error));
            throw new Error(error);
        }
        else{
            fetchAccounts();
        }
        
        setSave(false);
    }

    useEffect(() => {
        filterAccount(account)
        //const abortController = new AbortController();
        //fetchNotificationData(abortController);
        //return () => abortController.abort();
    },[accountInfo])

    return(
        <>
        <ListItem key={account} 
                sx={{paddingLeft: 
                    theme.spacing(1), 
                    paddingRight: theme.spacing(1),
                    width:300,
                }}>
            <Grid container 
                direction='column' 
                justifyContent="flex-start" 
                alignItems="flex-start" 
                sx={{
                    backgroundColor: theme.palette.grey.dark,
                    borderRadius: '4px',
                    padding: theme.spacing(1)
                    //maxWidth: 550,
                }}>
                    <Grid item xs>
                        <Typography noWrap gutterBottom variant="body1" fontWeight="bold">{t(accountName)}</Typography>                
                    </Grid>
                    <Grid item container 
                        columnSpacing={2}
                        direction="row"
                        justifyContent="center" 
                        alignItems="center" >
                        <Grid item xs>
                            <Grid item container 
                                direction="column"
                                justifyContent="center" 
                                alignItems="center">
                                <Grid item xs>
                                    <Typography> {t("notifyEmail")}</Typography>
                                </Grid>
                                <Grid item xs >
                                    <FormControlLabel control={<StyledSwitch checked={reportEmail ? true : false} onChange={changeReportEmail} />}/> 
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid item container direction="column"
                                justifyContent="center" 
                                alignItems="center">
                                <Grid item xs>
                                    <Typography> {t("notfiyText")}</Typography>
                                </Grid>
                                <Grid item xs >
                                    <FormControlLabel disabled={!number} control={<StyledSwitch checked={reportText ? true : false} onChange={changeReportText} />}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid item container direction="row"
                                    justifyContent="center" 
                                    alignItems="center"
                                    sx={{marginLeft: theme.spacing(1)}}>
                        <Grid item xs>
                            <Typography>{t("dayOfWeek")}</Typography>
                            <FormControlLabel sx={{ m: 1 }} 
                                control={
                                    <Select 
                                        size="small"
                                        labelId='day role'
                                        value={day ? day: " "}
                                        onChange={handleDaySelect}>
                                            {dayList.map(day => {
                                                return(
                                                    <MenuItem value={day}>
                                                        <li>{day}</li>
                                                    </MenuItem> 
                                                );  
                                            })} 
                                    </Select>
                                }>
                            </FormControlLabel>
                        </Grid>
                        <Grid item xs>
                            <Typography>{t("timeOfDay")}</Typography>
                            <FormControlLabel sx={{ m: 1 }} 
                                control={
                                    <Select 
                                        size="small"
                                        labelId='time role'
                                        value={displayTime ? displayTime: " "}
                                        onChange={handleTimeSelect}>
                                            {timeList.map(time => {
                                                return(
                                                    <MenuItem value={time}>
                                                        <li>{time}</li>
                                                    </MenuItem> 
                                                );  
                                            })} 
                                    </Select>
                                }>
                            </FormControlLabel>
                        </Grid>
                    </Grid>
                    {save && <Grid item container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems='center'
                            sx={{
                                    paddingBottom: theme.spacing(1)
                                }}>
                        <Grid item xs>
                            <Tooltip title={t("notificationsSave")}>
                                <Button 
                                    variant='contained'
                                    color='secondary'
                                    onClick={updateSettings}>
                                    <Typography>{t("notificationsSave")}</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs>
                            <Tooltip title={t("notificationsCancel")}>
                                <Button 
                                    variant='outlined'
                                    color='primary'
                                    onClick={cancelSaveDialog}>
                                    <Typography>{t("notificationsCancel")}</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>}
            </Grid> 
        </ListItem>
        
        </>
    )
}

export default StatusReportSettingsCard;