import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';


const EditButton = React.memo(({ clickHandler, ...props }) => {
    
    return (
        <IconButton
            sx={{
                position: 'absolute',
                padding: '6px',
                transform: 'translate(25%, -3px)',
            }}
            color="primary"
            onClick={clickHandler}
            {...props}
        >
            <EditIcon sx={{fontSize: '1.1rem'}} />
        </IconButton>
    );
});

export default EditButton;