import React, { useState, useEffect} from 'react';
import {
    Button,
    IconButton,
    FormControlLabel,
    Grid,
    ListItem,
    Switch,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { groups_inf } from '../../services/groups_inf';
import ModalPrompt from '../../CommonComponents/ModalPrompt';


const GroupUserCard = ({user, group, accountInfo, fetchGroup=()=>{} }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [read, setRead] = useState(user.read_access);
    const [write, setWrite] = useState(user.write_access);
    const [invite, setInvite] = useState(user.invite_access);
    const [oldWrite, setOldWrite] = useState(user.write_access);
    const [oldInvite, setOldInvite] = useState(user.invite_access);
    const [updateUser, setUpdateUser] = useState([]);
    const [editDialogue, seteditDialogue] = useState(false);
    const [deleteDialogue, setdeleteDialogue] = useState(false);
    const [update, setUpdate] = useState(false);

    const opendelDialog = () => {
        setdeleteDialogue(true);
    };

    const closedelDialog = () => {
        setdeleteDialogue(false);
        setUpdateUser([]);
    };

    const openEditDialog = () => {
        seteditDialogue(true);
    };

    const closeEditDialog = () => {
        seteditDialogue(false);
    };

    const handleWrite = (event) => {
        if(event.target.checked){
            setWrite(1);
        }
        else{
            setWrite(0);
        }
        if(event.target.checked === oldWrite && invite === oldInvite){
            closeEditDialog()
        }
        else{
            openEditDialog(); 
        }
        //handleUpdateUser();
      };
      
    const handleInvite = (event) => {
        if(event.target.checked){
            setInvite(1);
        }
        else{
            setInvite(0)
        }
        // setInvite(event.target.checked);
        if(event.target.checked === oldInvite && write === oldWrite){
            closeEditDialog()
        }
        else{
            openEditDialog(); 
        }
        //handleUpdateUser();
    };

    const cancelUpdateDialog = async () => {
        console.log('cancelUpdateDialog');
        fetchGroup();
        setUpdate(false);
        closeEditDialog();
    }

    const updateGroup = async () => {
        console.log('updateGroup');
        let updateUser = [];
        updateUser.push({user_id: user.user_id, read_access: read, write_access: write, invite_access: invite});
        let id = group.id;
        let name = group.name;

        try{
        const { status, error } = await groups_inf.updateGroup(
            accountInfo.accountId, id, name, updateUser, [], []
        );
        if (status === 404) {
            return;
        }
        if (error !== 'OK' && status !== 200) {
            if(error.includes("Unable to connect to database")){
                alert(t("failedToConnectToDatabase"))                
            }
            else if(error.includes("Access Denied, no permissions to modify")){
                alert(t("accessDeniedNoPermissions"));
            }
            else if(error.includes("Group name alreadery exists")){
                alert(t("groupNameAlreadyExists"))                
            }
            //alert(t(error));
            else {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            //alert(t(error));
            throw new Error(error);
        }
        console.log('done updating group'); 

        }catch (error) {
            console.error(error);
        }
        fetchGroup();
        setUpdate(!update);
        closeEditDialog();
    };

    const removeUser = async () => {
        console.log('removeUser');
        let updateUser = [];
        updateUser.push({user_id: user.user_id, read_access: 0, write_access: 0, invite_access: 0});
        let id = group.id;
        let name = group.name;

        try{
        const { status, error } = await groups_inf.updateGroup(
            accountInfo.accountId, id, name, updateUser, [], []
        );
        if (status === 404) {
            return;
        }
        if (error !== 'OK' && status !== 200) {
            if(error.includes("Unable to connect to database")){
                alert(t("failedToConnectToDatabase"))                
            }
            else if(error.includes("Access Denied, no permissions to modify")){
                alert(t("accessDeniedNoPermissions"));
            }
            else if(error.includes("Group name alreadery exists")){
                alert(t("groupNameAlreadyExists"))                
            }
            //alert(t(error));
            else {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            //alert(t(error));
            throw new Error(error);
        }
        console.log('done updating group'); 

        }catch (error) {
            console.error(error);
        }
        fetchGroup();
        setUpdate(!update)
        closedelDialog();
    };
    
    useEffect(() => {
        setRead(user.read_access);
        setWrite(user.write_access);
        setOldWrite(user.write_access);
        setInvite(user.invite_access);
        setOldInvite(user.invite_access);
    },[user]);

return <>{ <ListItem key={user.user_id} 
sx={{paddingLeft: 
    theme.spacing(1), 
    paddingRight: theme.spacing(1),
    maxWidth: 500,
    minWidth: 250,
}}>
<Grid container 
            direction='row' 
            justifyContent="flex-start" 
            alignItems="center" 
            sx={{
                backgroundColor: theme.palette.grey.dark,
                borderRadius: '4px',
                maxWidth: 500,
                minWidth: 250,
        }}>  
        <Grid item xs direction="column" container justifyContent="flex-start" alignItems="flex-start"  
            sx={{
                //padding:theme.spacing(1)
                margin: theme.spacing(1)
            }}
        >
            {user.given_name && 
                <Typography noWrap gutterBottom variant="body1" fontWeight="bold" >
                    {"{given_name} {family_name}".replace("{given_name}",user.given_name).replace("{family_name}",user.family_name)}
                </Typography>
            }
            <Typography noWrap gutterBottom variant="body1" fontWeight="bold" >
                {user.user_id}
            </Typography>
            <Toolbar>
                <FormControlLabel
                    label={t("groupWriteAccess")}
                    control={
                        <Switch
                        color='blue'
                        checked={write}
                        onChange={handleWrite}
                        />
                    }
                />
                <FormControlLabel
                    label={t("groupInviteAccess")}
                    control={
                        <Switch
                        color='blue'
                        checked={invite}
                        onChange={handleInvite}
                        />
                    }
                />
                <Tooltip title={t("removeUserFromGroup")}>
                <IconButton
                    edge="start"
                    color='primary'
                    onClick={opendelDialog}
                    >
                    <RemoveCircleIcon/>
                </IconButton>    
                </Tooltip>
                
            </Toolbar>
            {editDialogue && <Grid item xs container
                        direction="row"
                        justifyContent="space-around"
                        alignItems='center'
                        sx={{
                                paddingBottom: theme.spacing(1)
                            }}>
                    <Grid item xs>
                        <Tooltip title={t("save")}>
                            <Button 
                                variant='contained'
                                color='secondary'
                                onClick={updateGroup}>
                                <Typography>{t("save")}</Typography>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Tooltip title={t("cancel")}>
                            <Button 
                                variant='outlined'
                                color='primary'
                                onClick={cancelUpdateDialog}>
                                <Typography>{t("cancel")}</Typography>
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>}
        </Grid>
</Grid>
</ListItem> 

}
{deleteDialogue && <ModalPrompt
        title={t('user') + ' ' + user.user_id}
        content={t('confirmDeleteUser')}
        action={t('removeUserFromGroup')}
        handleAction={removeUser} 
        handleCancel={closedelDialog} 
        openModal={deleteDialogue}
        useConfirm={false}
        fullScreen={fullScreen}
        reverseEmphasis={true}
      />}
{/* {editDialogue && <ModalPrompt
        title={user.user_id}
        content={t('confirmEditUser')}
        action={t('ok')}
        handleAction={updateGroup} 
        handleCancel={closeEditDialog} 
        openModal={editDialogue}
        fullScreen={fullScreen}
      />} */}
</>


};

export default GroupUserCard;