/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useParams
} from 'react-router-dom';
import useSessionstorage from '@rooks/use-sessionstorage';
import Cookies from 'universal-cookie';
import {
    Divider,
    Grid,
    List,
    ListItem,
    Typography
} from '@mui/material';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import DataEntryListItemHeader from '../../../CommonComponents/DataEntryListItemHeader.js';
import GenerateConfigItems from './GenerateConfigItems.jsx';
import { lubrication_parameters_obj } from "../../../DeviceInfo/g3_Config";
import { hw_config_obj } from "../../../DeviceInfo/g3_Config";

import { secondsToTime } from '../../../helpers/secondsToTime';
import { timeToTimeString } from '../../../helpers/timeToTimeString.js';

// Device Page
export default function LubricationEndSettings({editing, currentSettings, hwConfig, updateSettings=()=>{}}) {
    const params = useParams();
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    const { value: accountInfo } = useSessionstorage('accountInfo', {});
    const [editParam, setEditParam] = useState(false);
    const [openParams, setOpenParams] = useState ({
        "108": false,
        "109": false,
        "107": false,
        "16": false,
        "29": false,
        "21": false,
        "100": false,
        "17": false,
        "101": false,
        "20": false,
        "35": false,
    });

    const lubrication_end_pressure_input_types = [
        {key: 5, id: 5, name: "pressure switch", displayLabel: "pressureSwitch"},
        {key: 7, id: 7, name: "pressure sensor", displayLabel: "pressureSensor"},
    ];

    const lubrication_settings_list = [
        {key: 2, id: 2, name: "timer", displayLabel: "lubricationEndTimer"},
        {key: 0, id: 0, name: "cycle", displayLabel: "lubricationEndCycle"},
        {key: 1, id: 1, name: "pressure", displayLabel: "lubricationEndPressure"},
        
    ];

    const lubrication_end_pressure_units = [
        //{key: 0, id: 0, name: "kpa", displayLabel: "pressureUnits_kPa"}, //CI:  kPa not supported by current G3 Gen 2 FW
        {key: 1, id: 1, name: "percentage", displayLabel: "pressureUnits_percentage"},
        {key: 2, id: 2, name: "psi", displayLabel: "pressureUnits_psi"},
        {key: 3, id: 3, name: "bar", displayLabel: "pressureUnits_bar"},
    ];

    const lubrication_pressure_analog_types = [
        {key: 5, id: 5, name: "switch", displayLabel: "pressureSwitch"},
        {key: 1, id: 1, name: "analog_0V_5V", displayLabel: "0V - 5V"},
        {key: 2, id: 2, name: "analog_0V_10V", displayLabel: "0V - 10V"},
        {key: 6, id: 6, name: "analog_1V_5V", displayLabel: "1V - 5V"},
        {key: 7, id: 7, name: "analog_0d5V_4d5V", displayLabel: "0.5V - 4.5V"},
    ];

    const GenerateLocalLubricationSettingList = () => {
        var local_settings_list = [];
        local_settings_list.push(lubrication_settings_list[0]);
        if (hwConfig.includes(hw_config_obj['cycle_input'].id)){
            local_settings_list.push(lubrication_settings_list[1]);
        }
        if (hwConfig.includes(hw_config_obj['pressure_sensor_input'].id)){
            local_settings_list.push(lubrication_settings_list[2]);
        }
        return local_settings_list;
    };

    const DisplayLubricationMode = () => {
        const parameter_list = [
            {
                key: "21",
                'id': 'lubrication_end_mode_id_21',
                'config_id': "21",
                'type': "list",
                'list': GenerateLocalLubricationSettingList()
            }
        ];
        return(
            <GenerateConfigItems 
                editing={editing}
                parameter_list={parameter_list}
                parameter_obj={lubrication_parameters_obj}
                currentSettings={currentSettings} 
                openParams={openParams}
                handleUpdateParams={handleUpdateParams}
                handleOpenParam={handleOpenParam}
                handleCloseParams={handleCloseParams}
                handleToggle={handleToggle}
                handleErrorCheckParams={handleErrorCheckParams}
                hour_length={0}
            />
        )
    }

    const DisplayLubricationEndTimer = () => {
        const parameter_list = [
            {
                key: "20",
                'id': 'lubrication_end_timer_target_id_20', 
                'config_id': "20",
                'type': "time",
            },
        ];
        return(
            <GenerateConfigItems 
                editing={editing}
                parameter_list={parameter_list}
                parameter_obj={lubrication_parameters_obj}
                currentSettings={currentSettings} 
                openParams={openParams}
                handleUpdateParams={handleUpdateParams}
                handleOpenParam={handleOpenParam}
                handleCloseParams={handleCloseParams}
                handleToggle={handleToggle}
                handleErrorCheckParams={handleErrorCheckParams}
                hour_length={0}
            />
        );
    };
    const DisplayLubricationEndCycle = () => {
        const parameter_list = [
            {   
                key: "16",
                'id': 'lubrication_end_cycle_target_id_16', 
                'config_id': "16",
                'type': "number",
                "digits": 3
            },
            {
                key: "29",
                'id': 'lubrication_end_cycle_timeout_id_29', 
                'config_id': "29",
                'type': "time",
            },
        ];
        
        return(
            <GenerateConfigItems 
                editing={editing}
                parameter_list={parameter_list}
                parameter_obj={lubrication_parameters_obj}
                currentSettings={currentSettings} 
                openParams={openParams}
                handleUpdateParams={handleUpdateParams}
                handleOpenParam={handleOpenParam}
                handleCloseParams={handleCloseParams}
                handleToggle={handleToggle}
                handleErrorCheckParams={handleErrorCheckParams}
                hour_length={0}
            />
        );
    };

    const DisplayLubricationEndPressure = () => {
        const parameter_list = [
            {
                key: "107",
                'id': 'input2_type_id_107', 
                'config_id': "107",
                'type': "list",
                'list': lubrication_pressure_analog_types,
            }
        ];
        
        return (
            <Grid item xs>
                <List>
                    <ListItem>
                        <Typography>{t(currentSettings[parameter_list[0].config_id] === "5" ? "pressureSwitchLabel" : "pressureSensorLabel" )}</Typography>
                    </ListItem>
                </List>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={lubrication_parameters_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={0}
                />
                
                {currentSettings['107'] === "5" ? <DisplayLubricationEndPressureSwitch/> :
                    <DisplayLubricationEndPressureSensor/>}
            </Grid>
        )
    };

    const DisplayLubricationEndPressureSwitch = () => {
        const parameter_list = [
            {
                key: 19,
                'id': 'lubrication_end_pressure_timeout_id_19', 
                'config_id': "19",
                'type': "time",
            },
        ];
        return(
            <GenerateConfigItems 
                editing={editing}
                parameter_list={parameter_list}
                parameter_obj={lubrication_parameters_obj}
                currentSettings={currentSettings} 
                openParams={openParams}
                handleUpdateParams={handleUpdateParams}
                handleOpenParam={handleOpenParam}
                handleCloseParams={handleCloseParams}
                handleToggle={handleToggle}
                handleErrorCheckParams={handleErrorCheckParams}
                hour_length={0}
            />
        );
    };

    const DisplayLubricationEndPressureSensor = () => {
        const parameter_list = [
            {
                key: "100",
                'id': 'lubrication_end_pressure_full_scale_id_100', 
                'config_id': "100",
                'type': "number",
            },
            {
                key: "101",
                'id': 'lubrication_end_pressure_units_id_101', 
                'config_id': "101",
                'type': "list",
                'list': lubrication_end_pressure_units
            },
            {
                key: "17",
                'id': 'lubrication_end_pressure_threshold_id_17', 
                'config_id': "17",
                'type': "number",
            },
            {
                key: "19",
                'id': 'lubrication_end_pressure_timeout_id_19', 
                'config_id': "19",
                'type': "time",
            },
        ];
        return (
            <GenerateConfigItems 
                editing={editing}
                parameter_list={parameter_list}
                parameter_obj={lubrication_parameters_obj}
                currentSettings={currentSettings} 
                openParams={openParams}
                handleUpdateParams={handleUpdateParams}
                handleOpenParam={handleOpenParam}
                handleCloseParams={handleCloseParams}
                handleToggle={handleToggle}
                handleErrorCheckParams={handleErrorCheckParams}
                hour_length={0}
            />
        );
    };
    
    const handleToggle = (key_in) => {
        if (parseInt(currentSettings[key_in])) {
            handleUpdateParams(key_in,0);
        }
        else{
            handleUpdateParams(key_in,1);
        }
    };

    const handleOpenParam = (key_in) => {
        let l_openParams = openParams;
        l_openParams[key_in] = true;
        setOpenParams(l_openParams);
        setEditParam(true);
    };
    const handleCloseParams = () => {
        let l_openParams = openParams;
        Object.keys(l_openParams).forEach(key => {
            l_openParams[key] = false;
        })
        setOpenParams(l_openParams);
        setEditParam(false);
    };
    const handleUpdateParams = (key_in, value_in)  => {
        
        var temp_params = Object.assign({}, currentSettings);
        if (temp_params[key_in] !== String(value_in)){
            temp_params[key_in] = String(value_in);
            if(key_in === "21"){ //Lubrication End Mode
                let currentTimeoutSetting = currentSettings["20"];
                let key_id_timeout_id = "20"; 
                if(parseInt(value_in) === 0){ //Cycle
                    currentTimeoutSetting = parseInt(currentSettings["29"]);
                    key_id_timeout_id = "29";
                } else if (parseInt(value_in) === 1){ //Pressure
                    currentTimeoutSetting = parseInt(currentSettings["19"]);
                    key_id_timeout_id = "19";
                } else if (parseInt(value_in) === 2){ //Timer
                    currentTimeoutSetting = parseInt(currentSettings["20"]);
                    key_id_timeout_id = "20";
                }
                let maxValue = Math.floor(parseInt(currentSettings["13"])/3);
                                
                if(currentTimeoutSetting > maxValue){
                    temp_params[key_id_timeout_id] = String(maxValue);
                    alert(t("timeoutAdjustedForDutyCycle").replace("{duty}","33"));
                }
            }
            updateSettings(temp_params);
        }
        handleCloseParams();
    };

    const handleErrorCheckParams = (key_in, value_in) => {
        let value_out=undefined;
        let errorText = ""
        value_out = value_in;

        if (currentSettings["14"] === "1"){ //Interval Timer Mode
            //Check for Duty Cycle Requirement
            let maxValue = Math.floor(parseInt(currentSettings["13"])/3);
            if(key_in === "29" || key_in === "19" || key_in ==="20"){ //Cycle, Pressure, Timer
                if(value_in > maxValue){
                    errorText="dutyCycleErrorMaxValue";
                }
            } 
            const maxiumumTime = secondsToTime(maxValue);
            const maximumTimeString = timeToTimeString(maxiumumTime.hours, maxiumumTime.minutes, maxiumumTime.seconds, 0);
            errorText = t(errorText).replace("{duty}","33").replace("{val}", maximumTimeString);
        }

        return {value: value_out, errorText: errorText}
    };


    //Header consumes 64px of viewable area
    return (
        <Grid container item direction='column'>
            <Divider/>
                <DataEntryListItemHeader label={"settingLubricationEndTitle"}/>
            <Divider/>
            <DisplayLubricationMode/>
            {currentSettings["21"] === "0" && <DisplayLubricationEndCycle/>}
            {currentSettings["21"] === "1" && <DisplayLubricationEndPressure/>}
            {currentSettings["21"] === "2" && <DisplayLubricationEndTimer/>}
        </Grid>
    )
}
