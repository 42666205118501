import React, { useState, useRef , useEffect} from 'react';
import {
    useNavigate,
    Link,
  } from 'react-router-dom'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    IconButton,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemButton,
    MenuItem,
    Select,
    Switch,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Edit, ErrorOutline, Height } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

import { GrowDiv } from '../../styledComponentsCommon';
import { AsyncStorage } from '@aws-amplify/core';
import ModalPrompt from '../../CommonComponents/ModalPrompt';
import * as g3 from '../../DeviceInfo/g3_Config';
import {lubrication_settings_list} from '../../MainPage/DevicePage/Components/LubricationIntervalSettings';
import {DEVICE_TYPES} from '../../DeviceInfo/deviceTypes'
import { CONFIG_TYPES } from '../../DeviceInfo/deviceTypes';
import configurations_inf from '../../services/configurations_inf';
import InfoListItem from '../../CommonComponents/InfoListItem';

const TemplateCard = ({template,  fetchTemplates, deleteTemplate, accountInfo}) =>{
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [type, setType] = useState();
    const [intervalMode, setIntervalMode] = useState();
    const [intervalCount, setIntervalCount] = useState();
    const [LubeMode, setLubMode] = useState();
    const [deleteDialogue, setDeleteDialogue] = useState(false);

    useEffect(() => {
        filterData(template);
      },[template]);

    const handleSelection = ()=>{
    let url = "/configurations/{templateName}".replace("{templateName}",template.name);
    navigate(url, {state: {template}});
    };

   

    function handleOpenDelete(){
        setDeleteDialogue(true);
    }
    function handleCloseDelete(){
        setDeleteDialogue(false);
    }

    function handledelete(){
        deleteTemplate(template.id);
    }


    function filterData(template){
        setName(template.name);
        let temp1 = template.desc
        if(temp1.length >32){
            temp1 = temp1.substr(0,29) + "...";
        }
        setDescription(temp1);
        let temp = template.type;
        for (const [key, value] of Object.entries(DEVICE_TYPES)) {
            if(key == temp){
                setType(value.name)
            }
        }

        let temp2 = template.param[14];
        if(temp2 == 0){
            setIntervalMode('Machine Count');
            setIntervalCount(template.param[11]);
        }
        else if(temp2 == 1){
            setIntervalMode('Timer');
            var hhmmssFormat = new Date(template.param[13] * 1000).toISOString().substr(11, 8);
            setIntervalCount(hhmmssFormat);
            // setIntervalCount(new Date(template.param[13] * 1000).toISOString().substring(11, 16));
        }

        let temp3 = template.param[21];
        if(temp3 == 0){
            setLubMode('Cycle');
        }
        else if(temp3 == 1){
            setLubMode('Pressure');
        }
        else if(temp3 == 2){
            setLubMode('Timer');
        }

    };

    return <>{
      <ListItem key={template.name} 
            //justifyContent="flex-start" 
            alignItems="center" 
            sx={{
                paddingLeft: theme.spacing(1), 
                paddingRight: theme.spacing(1),
                maxWidth: 600,
                minWidth: 200,
            }}>
            <ListItemButton onClick={handleSelection} 
                sx={{ 
                    maxWidth: 500, 
                    minWidth: 250,
                    width: '50%',
                    paddingLeft: theme.spacing(0), 
                    paddingRight: theme.spacing(0) 
            }}>
            <Grid container 
                direction='row' 
                justifyContent="flex-start" 
                alignItems="center" 
                sx={{
                    margin: theme.spacing(0),
                    backgroundColor: theme.palette.grey.dark,
                    borderRadius: '4px',
                    //maxWidth: 550,
            }}>  
                <Grid item xs>
                    <Typography sx={{
                            paddingLeft:theme.spacing(1),
                            marginTop: theme.spacing(1)
                        }} 
                        noWrap gutterBottom variant="body1" fontWeight="bold">
                        {t(name)}
                    </Typography>
                    <Grid container justify="space-between" > 
                        <Typography sx={{paddingLeft:theme.spacing(1)}} noWrap variant="body1">
                            {t(description)}
                        </Typography>
                    </Grid>
                    <Grid container direction="column" justify="space-between"> 
                        <List sx={{width: '100%'}}>
                            <InfoListItem label={t('end')} value={t(LubeMode)}/>
                            <InfoListItem label={t('interval')} value={t(intervalMode)}/>
                            <InfoListItem label={t('templateType')} value={t(type)}/>
                        </List>
                    </Grid>
                </Grid> 
            </Grid> 
          </ListItemButton> 
          <Tooltip title={t("removeTemplate")}>  
            <IconButton
                color='primary'
                size='large'
                onClick={handleOpenDelete}
                >
                <DeleteIcon/>
            </IconButton>
        </Tooltip>       
      </ListItem>
  }
  {deleteDialogue && <ModalPrompt
        content={t('confirmDeleteTemplate')}
        action={t('delete')}
        handleAction={handledelete} 
        handleCancel={handleCloseDelete} 
        openModal={deleteDialogue}
        useConfirm={true}
        fullScreen={fullScreen}
      />}
  </>
};


export default TemplateCard