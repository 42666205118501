import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router';
import {
    Button,
    CircularProgress,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { users_inf } from '../services/users_inf';
import DOMPurify from 'dompurify';


/* 
*   EXPORTS FROM .docx EXPECTED TO BE UTF-8
*   In Word go to Options -> Advanced -> Web Options -> Encoding -> Set to UTF-8
*   Review generated HTML for unwanted styling such as style: color=black.
*   Test/Preview information before releasing.
*   REQUIRES AMPLIFY CONFIGURED TO REDIRECT .htm FILES
*/
const ToU_filePath_en = "/documents/ToU/TRACE_ToU_Agreement_en.htm";
const ToU_filePath_fr = "/documents/ToU/TRACE_ToU_Agreement_fr.htm";
const ToU_filePath_es = "/documents/ToU/TRACE_ToU_Agreement_es.htm";
const ToU_filePath_de = "/documents/ToU/TRACE_ToU_Agreement_de.htm";


const TermsOfService = ({ openTerms, handleCloseTerms, reviewOnly=false }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [thinking, setThinking] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [selectedLang, setSelectedLang] = useState("en");
    const [htmlFilePath, setHtmlFilePath] = useState(ToU_filePath_en);
    const [htmlContent, setHtmlContent] = useState('');
    
    const langlist = [
        {key: 'en', displayLabel: "English"}, 
        // {key: 'fr', displayLabel: "Français"}, 
        // {key: 'es', displayLabel: "Español"}, 
        // {key: 'de', displayLabel: "Deutsch"}
    ];
    
    useEffect(() => {
        const loadHtml = async () => {
          try {
            const response = await fetch(htmlFilePath);
            const text = await response.text();
            // Sanitize the HTML content
            const sanitizedHtml = DOMPurify.sanitize(text);
            setHtmlContent(sanitizedHtml);
          } catch (error) {
            console.error('Error loading HTML file:', error);
          }
        };
    
        if (htmlFilePath) {
          loadHtml();
        }
      }, [htmlFilePath]);


    const handleAccept = async () => {
        // Update database with user agreement information
        setThinking(true);
        try {
            //let user = await Auth.currentUserInfo();
            const{status, error, userSettings} = await users_inf.updateUserTerms();
            if (status === 404) {
                return;
            }
      
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                //alert(t(error));
                throw new Error(error);
            }
            navigate(0);
          }
          catch (error) {
            console.log(error.message);
            alert(error.message);
          }
          setThinking(false);
    }

    const handleSelectLang = (event) => {
        let newlySelected = event.target.value;
        setSelectedLang(newlySelected);
        if(newlySelected === "fr")
        {
            setHtmlFilePath(ToU_filePath_fr);
        } 
        else if(newlySelected === "es")
        {
            setHtmlFilePath(ToU_filePath_es);
        } 
        else if(newlySelected === 'de')
        {
            setHtmlFilePath(ToU_filePath_de);
        } 
        else
        {
           setHtmlFilePath(ToU_filePath_en);
        }
    }

    const LangSelector = () => {
        return (
          <FormControl sx={{margin: 1, maxWidth: 150}} size="small">
            {true && <InputLabel id="lang-selector-label">{t('language')}</InputLabel>}
            <Select
              labelId="lang-selector-label"
              id="lang-selector"
              label={t("language")}
              value={selectedLang}
              onChange={handleSelectLang}
            >
              {langlist.map(lang => {
                                        return(
                                            <MenuItem value={lang.key}>
                                                {lang.displayLabel}
                                            </MenuItem> 
                                        );  
                                    }
                            )
                } 
            </Select>
          </FormControl>
        );
      };

    return (
        <Dialog fullScreen={true} disableEscapeKeyDown open={openTerms} onClose={handleCloseTerms}>
            <DialogTitle>{t("traceTermsOfUse")}</DialogTitle>
            <Divider/>
            {langlist.length > 1 && <LangSelector/>}
            <DialogContent>
                {true && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
            </DialogContent>
            <Divider/>
            <DialogActions style={{ justifyContent: 'flex-start' }}>
                {thinking ? 
                    <CircularProgress sx={{margin: theme.spacing(4)}}/>
                    :reviewOnly ? <Button onClick={handleCloseTerms} color="primary">
                            {t("close")}
                        </Button>
                        : <Button variant='contained' onClick={handleAccept} color="secondary">
                            {t("acceptTerms")}
                        </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default TermsOfService;