import React, {
  useState,
  useEffect,
  Fragment,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const SessionTimeout = ({ signedIn, maxTimeoutMinutes, warningTimeoutMinutes }) => {
  let navigation = useNavigate();
  const [events, setEvents] = useState(['click', 'load', 'scroll']);
  const [warningMinutes, setWarningMinutes] = useState(maxTimeoutMinutes - warningTimeoutMinutes);
  let minutes;
  const [isOpen, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  let idleInterval = 1000 * 60 * 1;  //1 minute
  let idleEvent;

  const handleClose = () => {
    setOpen(false);
  };

  const logOut = () => {
    setOpen(false);
    window.location.assign('/');
    Auth.signOut();
    navigation('/');
  };

  function checkTimer() {
    if (signedIn) {
      var elapsedMinutes = minutes + 1;
      minutes = elapsedMinutes;

      if (elapsedMinutes >= maxTimeoutMinutes) {
        logOut();
      } else if (elapsedMinutes >= (maxTimeoutMinutes - warningTimeoutMinutes)) {
        setWarningMinutes(maxTimeoutMinutes - elapsedMinutes);
        setOpen(true);
      }

      if (elapsedMinutes < maxTimeoutMinutes) {
        idleEvent = setTimeout(() => checkTimer(), idleInterval);
      }
    } else {
      minutes = 0;
      setOpen(false);
      setWarningMinutes(maxTimeoutMinutes - warningTimeoutMinutes);
    }
  };

  const sessionTimeout = () => {
    if (!!idleEvent) {
      clearTimeout(idleEvent);
      minutes = 0;
      setWarningMinutes(maxTimeoutMinutes - warningTimeoutMinutes);
    }

    if (signedIn) {
      idleEvent = setTimeout(() => checkTimer(), idleInterval);
    }
  };

  useEffect(() => {
    if (signedIn) {
      events.forEach((event) => {
        window.addEventListener(event, sessionTimeout);
      });
    } else {
      setOpen(false);
    }
  }, [signedIn]);

  if (!isOpen) {
    return null;
  }

  // change fragment to modal and handleclose func to close
  return (
    <Fragment>
      {isOpen && <Dialog disableEscapeKeyDown open={isOpen} onClose={handleClose}>
        <DialogContent>
          <Typography>
            {t("youWillBeAutomatically")} {warningMinutes} {t("minutes")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="secondary">
            {t("stayLoggedIn")}
          </Button>
        </DialogActions>
      </Dialog>}
    </Fragment>
  );
};

export default SessionTimeout;