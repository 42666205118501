/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState} from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
    Badge,
    Box,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { 
    ArrowBack,
} from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpIcon from '@mui/icons-material/Help';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';
//import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import { G3_ALERT_ID, G3_STATE_ID } from '../../DeviceInfo/g3_Info.js';
import {DEVICE_TYPES} from '../../DeviceInfo/deviceTypes.js';

import InfoListItem from '../../CommonComponents/InfoListItem.js';
import SignalStrength from '../../CommonComponents/SignalStrength.js';
import DetailsStatusCard from './DetailsStatusCard.js';
import { tankBackgroundColors } from '../../styledComponentsCommon';
import devices_inf from '../../services/devices_inf.js';
import TankStatusImage from './TankStatusImage.js';
import DetailsActionButtons from './DetailsActionButtons.js';
import DataEntryListItem from '../../CommonComponents/DataEntryListItem.js';
import EditFriendlyName from '../../CommonComponents/EditFriendlyName.js';
import LubeOnIcon from './assets/LubeOnIcon';
import LubeOffIcon from './assets/LubeOffIcon';
import MqttDevice from '../../services/mqtt_device.js';
import { updateDeviceStatusMqtt } from '../../helpers/updateDeviceStatusMqtt.js';
import { lubrication_parameters_obj, interval_parameters_obj } from '../../DeviceInfo/g3_Config.js';
import { checkConfigurationMismatch } from '../../helpers/checkConfigurationMismatch.js';
import ConfigurationMismatchChip from '../../CommonComponents/ConfigurationMismatchChip.js';
import { isLevelAnalog } from '../../helpers/isLevelAnalog.js';
import InstructionsDialogue from './InstructionsDialogue.js';
import { enableFwUpdate } from '../../data.js';
import FwUpdateButtonListItem from './FwUpdateButtonListItem.js';
import ModalPrompt from '../../CommonComponents/ModalPrompt.js';


// Device Page
export default function DeviceDetails({accountInfo}) {
    const params = useParams();
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const FW_UPDATE_POLL_RATE = 30*1000;
    //const { value: accountInfo } = useSessionstorage('accountInfo', {});
    //const [accountInfo, setAccountInfo] = useState(account);
    const [dataReady, setDataReady] = useState(false);
    const [initialFetch, setInitialFetch] = useState(false);
    //const [deviceInfo, setDeviceInfo] = useState({});
    const [groupList, setGroupList] = useState([])
    const [devicePermissions, setDevicePermissions] = useState({});
    const [deviceInfo, setDeviceInfo] = useState({});
    const [displayData, setDisplayData] = useState({permissions:{write_access: false}, groupList:[]});
    const [deviceUid, setDeviceUid] = useState([]);
    const [deviceState, setDeviceState] = useState(0);
    const [deviceConnection, setDeviceConnection] = useState(0);
    const [disableActionButton, setDisableActionButton] = useState(false);
    const [configurationMismatch, setconfigurationMistmatch] = useState([]);

    const [openAlertInfo, setOpenAlertInfo] = useState(false);
    const [alertInfoId, setAlertInfoId] = useState(0);

    const [fwUpdateState, setFwUpdateState] = useState(0);
    const [fwUpdateProgress, setFwUpdateProgress] = useState(0);
    //const [lastFwUpdateState, setLastFwUpdateState] = useState();
    const [fwUpdateInfoFetch, setFwUpdateInfoFetch] = useState(0);
    const [openClearAlarmsPrompt, setOpenClearAlarmsPrompt] = useState(0);

    useEffect(() => {
        const abortController = new AbortController();
        fetchDevice(abortController);

        return () => abortController.abort();
    },[]);

    useEffect(() => {
        let l_dataSet = deviceInfo;
        l_dataSet.groupList = groupList;
        l_dataSet.devicePermissions = devicePermissions;
        handle_filterData(l_dataSet);
    },[deviceInfo, devicePermissions])
    //},[deviceInfo, devicePermissions, groupList])

    let bgColors = tankBackgroundColors(theme);

    const handleMqqtMessage = (msg) => {
        let tempData = updateDeviceStatusMqtt([JSON.parse(JSON.stringify(deviceInfo))], msg);
        setDeviceInfo(tempData.newData);
      };
    const handleMqqtDisconnect = (msg) => {
        if (msg.connection){
            setDeviceConnection(1);
        } else{
            setDeviceConnection(0);
        }
    }

    const handle_ManualRunAction = async (action) => {
        switch(action) {
            case "start":
                devices_inf.deviceStartLubrication(accountInfo.accountId, params.deviceId)
                break;
            case "stop":
                devices_inf.deviceStopLubrication(accountInfo.accountId, params.deviceId);
                break;
            case "clear_alarms":
                //devices_inf.deviceClearAlarms(accountInfo.accountId, params.deviceId);
                openClearAlarmPrompt();
                break;
            default:
                break;
        }
        setDisableActionButton(true);
        setTimeout(() => setDisableActionButton(false), 10000)
    }

    const closeClearAlarmPrompt = () => {
        setOpenClearAlarmsPrompt(false);
    }

    const openClearAlarmPrompt = () => {
        setOpenClearAlarmsPrompt(true);
    }

    const handle_ConfirmClearAlarms = async (action) => {
        devices_inf.deviceClearAlarms(accountInfo.accountId, params.deviceId);
        closeClearAlarmPrompt();
    }

    const fetchDevice = async (abortController={signal: undefined}) => {
        try{
            let deviceDetails = {groupList:[], permissions:{}};
            const { status, error, deviceDetailsPermissions, deviceDetailsInfo, groupsDetailsList } = await devices_inf.getDeviceDetails(
                accountInfo.accountId, params.deviceId,
                abortController
            );
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                    alert(t("accessDeniedNoView"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                deviceDetails = deviceDetailsInfo;
                deviceDetails.groupList = groupsDetailsList;
                deviceDetails.permissions = deviceDetailsPermissions;
                if (deviceDetailsInfo.activation === 0){
                    alert(t("statusCardDeviceInactive"))
                    navigation("/");
                }
                if (deviceDetailsInfo.connection > 0){
                    setDeviceConnection(1);
                }
                else{
                    setDeviceConnection(0);
                }
                setDeviceUid([deviceDetails.uid]);
                setDeviceInfo(deviceDetailsInfo);
                setDevicePermissions(deviceDetailsPermissions);
                setGroupList(groupsDetailsList);
                
                updateFwUpdateInformation(deviceDetailsInfo);
            }
        } catch(error){
            console.error(error);
        }
        setInitialFetch(true);        
    };

    const fetchDeviceUpdateInfo = async (abortController={signal: undefined}) => {
        try{
            const { status, error, deviceDetailsInfo } = await devices_inf.getDeviceInfo(
                accountInfo.accountId, params.deviceId,
                abortController
            );
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                    alert(t("accessDeniedNoView"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else
            {
                var formattedDate = new Date(deviceDetailsInfo.last_fw_update_state.includes('Z') ? deviceDetailsInfo.last_fw_update_state : deviceDetailsInfo.last_fw_update_state+'Z')
                var nowTime = new Date();
                let bCompleted = false;
                
                if((nowTime.getTime() - formattedDate.getTime()) / 1000 > 30*60)
                {
                    //l_fwUpdateState = 0;
                    //Update completed or cancelled
                    bCompleted = true
                } else 
                {
                    if (deviceDetailsInfo.fw_update_state)
                    {
                        updateFwUpdateInformation(deviceDetailsInfo);
                    }
                    else 
                    {
                        //Update Completed or Cancelled.
                        bCompleted = true
                    }
                }
                if (bCompleted){
                    fetchDevice();
                }
            }
        } catch(error){
            console.error(error);
        }
        //setInitialFetch(true);        
    };

    function findOperationmodesLubrication(config_data){
        const lubrication_settings_list = [
            {key: 2, id: 2, name: "timer", displayLabel: "lubricationEndTimer"},
            {key: 0, id: 0, name: "cycle", displayLabel: "lubricationEndCycle"},
            {key: 1, id: 1, name: "pressure", displayLabel: "lubricationEndPressure"},
            
        ];
        var endOnParameter = '';
        Object.keys(lubrication_parameters_obj).forEach((key) => {
            if (lubrication_parameters_obj[key].name.includes("lubrication_end_mode")){
                if (config_data !== undefined)
                {
                    let id1 = config_data[key];
                    lubrication_settings_list.forEach((item) => {
                        if (item.id.toString() === id1){
                            endOnParameter = item.displayLabel;
                        }
                    })
                }
            }
        });
        return (endOnParameter);
    }

    function findOperationmodesInterval(config_data){
        const interval_settings_list = [
            {key: 0, id: 0, name: "machine count", displayLabel: "intervalMachineCount"},
            {key: 1, id: 1, name: "timer", displayLabel: "intervalTimer"},
        ];
        var intervalParameter = '';
        Object.keys(interval_parameters_obj).forEach((key) => {
            if (interval_parameters_obj[key].name.includes("lubrication_start_mode")){
                if (config_data !== undefined)
                {
                    let id2 = config_data[key];
                    interval_settings_list.forEach((item) => {
                        if (item.id.toString() === id2){
                            intervalParameter = item.displayLabel;
                        }
                    })
                }
            }
        });
        return (intervalParameter);
    }

    const handle_filterData = (dataSet) => {
        if (initialFetch){
            let l_displayData = {};
            var currentColor;
            l_displayData = {...dataSet};
            
            l_displayData.device_state = G3_STATE_ID[dataSet.state ? dataSet.state : 0];
            let device_state = G3_STATE_ID[dataSet.state ? dataSet.state : 0];
            l_displayData.lubricating = device_state.state === "state_running" ? true : false;
            if (dataSet.config){
                l_displayData.config = dataSet.config;
            }
            l_displayData.endOn = findOperationmodesLubrication(l_displayData.config_reported);
            l_displayData.interval = findOperationmodesInterval(l_displayData.config_reported);

            currentColor = bgColors.OK;  
            switch (l_displayData.device_state.state) {
                case "state_running":
                case "state_idle":
                    if (dataSet.in_alert){
                        for (let i=0; i<dataSet.alerts_list.length; i++){
                            if (G3_ALERT_ID[dataSet.alerts_list[i]]?.name === "alert_level"){
                                currentColor = bgColors.LOW_LEVEL;
                                break;
                            }
                        }
                    }
                    break;
                case "state_alarm":
                    if (G3_STATE_ID[dataSet.state.toString()]['name'] === "alarm_lowLevel"){
                        currentColor = bgColors.ERROR;
                    }
                    break;
                default:
                    currentColor = bgColors.ERROR;
                    break;
            }
            l_displayData.currentColor=currentColor;
            l_displayData.tankLevel = dataSet.level;
            l_displayData.levelHeight = dataSet.level+"%";
            l_displayData.isAnalogLevel = isLevelAnalog(dataSet.type, dataSet.config_reported);
            setDisplayData(l_displayData);
            setDeviceState(l_displayData.state);
            
            if (dataSet.groupList !== undefined){
                setGroupList(dataSet.groupList);
            }
            if (dataSet.config.cm === 1 || dataSet.config.cm === 1){
                setconfigurationMistmatch(checkConfigurationMismatch(dataSet.config_reported, dataSet.config));
            }
            setDataReady(true);
        }
    }

    const updateFwUpdateInformation = (dataSet) => {
        var formattedDate = new Date(dataSet.last_fw_update_state.includes('Z') ? dataSet.last_fw_update_state : dataSet.last_fw_update_state+'Z')
        var nowTime = new Date();
        let timeSinceLastUpdate = (nowTime.getTime() - formattedDate.getTime()) / 1000;
        
        if((nowTime.getTime() - formattedDate.getTime()) / 1000 > 30*60){
            setFwUpdateState(0);
        } else {
            if (dataSet.fw_update_state){
                if (fwUpdateInfoFetch)
                {
                    clearTimeout(fwUpdateInfoFetch);
                }
                setFwUpdateInfoFetch(setTimeout(()=> fetchDeviceUpdateInfo(), FW_UPDATE_POLL_RATE));
            }
            setFwUpdateState(dataSet.fw_update_state ? dataSet.fw_update_state : 0);
        }
        
        let progressOffset = Math.trunc((100/(25*60)) * timeSinceLastUpdate); //Expect 100% in about 25 minutes worst case
        setFwUpdateProgress(dataSet.fw_update_progress ? 
                dataSet.fw_update_progress + progressOffset < 99 ? 
                    dataSet.fw_update_progress + progressOffset 
                    : dataSet.fw_update_progress 
                : 0);
        
    }

    const handleUpdateFriendlyName = async (newName) => {
        try{
            const friendlyName = newName;
            
            const { status, error } = await devices_inf.updateDeviceName(
                accountInfo.accountId, params.deviceId, friendlyName
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectTodatabase"));
                }
                else if(error.includes("Access Denied, no permission to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                    alert(t("accessDeniedNoView"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                fetchDevice();
            }
        } catch(error){
            console.error(error);
        }
    };

    const handleStatusIcons = () => {
        let lubricating = displayData.device_state.state === "state_running" ? true : false;
        return (
            <>
            {displayData.in_alarm ? <NotificationsIcon sx={{marginRight: theme.spacing(1)}} color="error"/>
            : displayData.in_alert ? 
                <Badge sx={{marginRight: theme.spacing(1)}} 
                    badgeContent={displayData.alerts_list.length} 
                    color="primary">
                        <NotificationsIcon color="warning" />
                </Badge>
            : null}
            <Tooltip title={(parseFloat(displayData.rssi)/31*100 | 0).toString() + "%"}>
                <SignalStrength rssi={displayData.rssi} connection={deviceConnection}/>
            </Tooltip>
            {lubricating ? <LubeOnIcon sx={{marginLeft:theme.spacing(1), color: theme.palette.blue.main}}/> 
                        : <LubeOffIcon sx={{marginLeft:theme.spacing(1), color: theme.palette.grey52.main}}/>}
            </>
        );
    };

    const handleViewConfiguration = () => {
        console.log("Navigate to Config Page");
        let url = "/devices/{deviceId}/config".replace("{deviceId}", displayData.uid);
        navigation(url);
    };

    const handleViewDiagnostics = () => {
        console.log("Navigate to Diagnostics Page");
        let url = "/devices/{deviceId}/diagnostics".replace("{deviceId}", displayData.uid);
        navigation(url);
    };

    const handleOpenAlertInfo = (alertId) => {
        setOpenAlertInfo(true);
        setAlertInfoId(alertId);
    }

    const handleCloseAlertInfo = () => {
        setOpenAlertInfo(false);
        setAlertInfoId(0);
    }

    const DisplayAlertsChips = ({alertsList, mismatchList, deviceType}) => {
        let alertChips = []
        var alertItem;
        for (alertItem of alertsList){
            let temp = {};
            temp.key = G3_ALERT_ID[alertItem.toString()]?.name;
            temp.displayName = G3_ALERT_ID[alertItem.toString()]?.displayName;
            temp.name = temp.key;
            temp.id = alertItem;
            alertChips.push(temp);
        }
        return(
            <>
                {( mismatchList.length > 0 ) ? 
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, margin: theme.spacing(1) }}>
                    <ConfigurationMismatchChip mismatchList={mismatchList} deviceType={deviceType}/>
                </Box>
                : null}
                {( alertsList.length > 0 ) ? 
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, margin: theme.spacing(1) }}>
                        {alertChips.map((chipItem) => {
                            return(<Chip key={chipItem.key} 
                                label={t(chipItem.displayName)} 
                                color="warning" 
                                size='small,'
                                onDelete={()=>{
                                    handleOpenAlertInfo(chipItem.id);
                                }}
                                deleteIcon={<HelpIcon />}/> )
                        })}
                    </Box>
                : null}
            </>
        )
    }

    function CircularProgressWithLabel(props) {
        return (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(props.value)}%`}
              </Typography>
            </Box>
          </Box>
        );
      }

    const FwUpdateStatus = () => {
        return(
            <ListItem sx={{
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                    backgroundColor: theme.palette.warning.main,
                    borderRadius: '4px',
                }}>
                    <ListItemText
                        primary = {t("fwUpdateInProgress")}
                        secondary = {t("fwUpdateInProgressInfo")}
                    />
                    <ListItemIcon >
                        <CircularProgressWithLabel value={fwUpdateProgress}/>
                    </ListItemIcon>
            </ListItem>
        );
    }

    const handle_fwUpdateRequested = () => {
        setDataReady(false);
        setTimeout(()=>{setDataReady(true)}, 15000);
        setFwUpdateInfoFetch(setTimeout(()=>{fetchDeviceUpdateInfo()}, 10000));
    }
    
    //Header consumes 64px of viewable area
    return (
        <>
        <Grid container item direction='column'>
            <Grid sx={{height: '64px'}} item xs>
                <Toolbar sx={{height: '64px'}}>
                    <Tooltip title={t("goBack")}>
                        <IconButton
                                edge='start'
                                color='inherit'
                                onClick={()=>{navigation(-1);}}
                                aria-label='close'
                                >
                                    <ArrowBack/>
                        </IconButton>
                    </Tooltip>
                    <Box flexGrow={1}/>
                    <Typography>{displayData.friendly_name ? displayData.friendly_name : params.deviceId}</Typography>
                    <Box flexGrow={1}/>
                    {false && <EditFriendlyName 
                        disabled={!displayData.permissions.write_access} 
                        currentName={displayData.friendly_name} 
                        onSave={handleUpdateFriendlyName}/>}
                    {/* <AddGroup disabled={!displayData.permissions.write_access} accountInfo={accountInfo} device={displayData.uid} groups={groupList}/> */}
                </Toolbar>
            </Grid>
            <Divider/>
            <Box id="contentBox" height="calc(100vh - 129px)">
                
                {dataReady ? <Grid sx={{
                    minWidth: 350,
                    maxWidth: 500,
                }}
                item 
                container 
                direction='column'>
                    <MqttDevice 
                        accountId={accountInfo.accountId} 
                        deviceList={deviceUid} 
                        updateInfo={handleMqqtMessage} 
                        updateConnection={handleMqqtDisconnect} 
                        scanData={true} />
                    <Grid container 
                        direction='row' 
                        justifyContent="flex-start" 
                        alignItems="center" 
                        sx={{padding: theme.spacing(1)}}
                    >
                        {<TankStatusImage 
                            theme={theme}
                            levelHeight={displayData.levelHeight}
                            tankLevel={displayData.tankLevel}
                            currentColor={displayData.currentColor}
                            device_state={displayData.device_state}
                            analog_level={displayData.isAnalogLevel}
                            inAlert={displayData.alerts_list.includes(0)}
                            inAlarm={G3_STATE_ID[displayData.state.toString()]['name'] === "alarm_lowLevel" ? 1 : 0}
                            size={'large'} />}
                        
                        <Grid id="device-info" height='200px' item xs container 
                            direction='column' 
                            justifyContent="space-evenly" 
                            alignItems="flex-start" >
                            <List sx={{width: '100%'}}>
                                <InfoListItem label={DEVICE_TYPES[displayData.type ? displayData.type : 0].name} value={""} icon={handleStatusIcons()}/>
                                <InfoListItem label={displayData.config.cm ? t('cloudManagedConfig') : t("locallyManagedConfig")} value={""}/>
                                {displayData.endOn ? <InfoListItem label={t('end')} value={t(displayData.endOn)}/> : 
                                    <InfoListItem label={t('locallyManagedConfig')} value={""} divider={false}/>}
                                {displayData.interval ? <InfoListItem label={t('interval')} value={t(displayData.interval)}/> :
                                    <ListItem divider/>}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item xs >
                        <List>
                            <DetailsStatusCard uid={displayData.uid}
                                    connection={deviceConnection}
                                    last_connected={displayData.last_connected}
                                    state={deviceState}
                                    state_value_1={displayData.state_value_1}
                                    state_value_2={displayData.state_value_2}
                                    deviceStatus={displayData}/>
                            {(displayData.in_alert === 1 || configurationMismatch.length > 0) && <ListItem>
                                <DisplayAlertsChips alertsList={displayData.alerts_list} mismatchList={configurationMismatch} deviceType={displayData.type}/>
                            </ListItem>}
                            <ListItem divider>
                                <DetailsActionButtons connection={deviceConnection} 
                                    device_state={displayData.device_state}
                                    manualRunAction={handle_ManualRunAction}
                                    disableButton={displayData.device_state ? (displayData.device_state.state === "state_config" ? true : disableActionButton) : true}
                                    handleDiagnostics={handleViewDiagnostics}
                                    handleConfiguration={handleViewConfiguration}
                                    hideClearAlarms={devicePermissions.write_access === 1 || accountInfo.isAccountTech === 1 ? false : true}/>
                            </ListItem>
                            {false && <Tooltip title={t("setDeviceConfiguration")}>
                                <DataEntryListItem label={t('deviceConfiguration')} 
                                    invertEmphasis={false}
                                    value={''} 
                                    handleClick={handleViewConfiguration}/>
                            </Tooltip>}
                            <InfoListItem label={t('deviceUID')} value={displayData.uid} divider={false}/>   
                            <InfoListItem label={t('fwVersion')} value={(displayData.firmware.toUpperCase()).replaceAll("-",".")} divider={false}/>
                            {enableFwUpdate && (fwUpdateState ? <FwUpdateStatus/>
                            : <FwUpdateButtonListItem 
                                accountInfo={accountInfo}
                                deviceInfo = {displayData}
                                deviceConnection = {deviceConnection}
                                handle_updateRequested = {handle_fwUpdateRequested}
                            />)}
                        </List>
                    </Grid>
                </Grid>
                : <Grid item xs container
                        direction="row"
                        justifyContent="center"
                        alignItems="center" >
                    <CircularProgress sx={{margin: theme.spacing(4)}}/>
                    </Grid>}
            </Box>
            <InstructionsDialogue 
                title={G3_ALERT_ID[alertInfoId.toString()]?.displayName} 
                instructions={G3_ALERT_ID[alertInfoId.toString()]?.instructions}
                dialogueOpen={openAlertInfo}
                handleClose={handleCloseAlertInfo}/>
        </Grid>
        {openClearAlarmsPrompt && <ModalPrompt
            title={t('detailsClearAlarm')}
            content={t('clearAlarmDialog')}
            action={t('confirm')}
            handleAction={handle_ConfirmClearAlarms} 
            handleCancel={closeClearAlarmPrompt} 
            openModal={openClearAlarmPrompt}
            fullScreen={fullScreen}
        />}
        </>
    )
}
