import React, { useState, useRef , useEffect} from 'react';
import {
    useNavigate,
    Link,
  } from 'react-router-dom'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    FormControlLabel,
    Grid,
    ListItem,
    ListItemButton,
    Tooltip,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ModalPrompt from '../../CommonComponents/ModalPrompt';
import { GrowDiv } from '../../styledComponentsCommon';
import { groups_inf } from '../../services/groups_inf';
import devices_inf from '../../services/devices_inf.js';
import {DEVICE_TYPES} from '../../DeviceInfo/deviceTypes';


const GroupDeviceCard = ({device, group, accountInfo, fetchGroup}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [deleteDialogue, setdeleteDialogue]= useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [displayName, setDisplayName] = useState();
    const [displayUID, setUID] = useState();
    const [active, setActive] = useState();
    const [type, setType] = useState();
    const [cardWidth, setCardWidth] = useState(350);
    let rCard = useRef();
    const opendelDialog = () => {
        setdeleteDialogue(true);
    };
    const closedelDialog = () => {
        setdeleteDialogue(false);
    };

    const updateGroup = async () => {       
        let id = group.id;
        let name = group.name;
        let RemoveDevices = [];

        RemoveDevices.push(device.uid);
        try{
            const { status, error } = await groups_inf.updateGroup(
                accountInfo.accountId, id, name, [], [], RemoveDevices
            );
            if (status === 404) {
                return;
            }
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"))                
                }
                else if(error.includes("Access Denied, no permissions to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else if(error.includes("Group name alreadery exists")){
                    alert(t("groupNameAlreadyExists"))                
                }
                //alert(t(error));
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
            }
            console.log('done updating group'); 

        }catch (error) {
            console.error(error);
        }
        fetchGroup();
        closedelDialog();
    };

    useEffect(() => {
        // getDeviceDetails();
        filterData(device);
      },[accountInfo, device]);
    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            setCardWidth(entries[0].contentRect.width)
        })
        observer.observe(rCard.current)
        return () => rCard.current && observer.unobserve(rCard.current)
    }, [])

    function filterData(device) {
        setUID(device.uid);
        setDisplayName(device.friendly_name);
        if(device.activation_status){
            setActive(t("deviceActive"));
          }
        else{
            setActive(t("deviceNotActive"));
        }
        let temp = device.type;
        for (const [key, value] of Object.entries(DEVICE_TYPES)) {
            if(key == temp){
                setType(value.name)
            }
        }
    }

    const renderDeviceName = () => {
        let name = displayName ? displayName : device.uid;
        if (cardWidth < 375)
        {
            if (name.length > 20) 
            {
                name = name.substr(0,20) + "...";
            }   
        }
        else if (cardWidth < 450)
        {
            if (name.length > 30) 
            {
                name = name.substr(0,30) + "...";
            }  
        }
        return name;
    }

return <>
    {<ListItem ref={rCard} key={device.uid} 
        sx={{paddingLeft: 
            theme.spacing(1), 
            paddingRight: theme.spacing(1),
            maxWidth: 500,
            minWidth: 250,
        }}>
            <Grid container 
                direction='row' 
                justifyContent="flex-start" 
                alignItems="center" 
                sx={{
                    backgroundColor: theme.palette.grey.dark,
                    borderRadius: '4px',
                    maxWidth: 500,
                    minWidth: 250,
                }}>  
                <Grid item xs sx={{paddingLeft:theme.spacing(1)}} direction="column" container justifyContent="flex-start" >
                    <Typography noWrap gutterBottom variant="body1" component="span" >
                        {renderDeviceName()}
                    </Typography>
                    <Grid item xs direction="row" container justifyContent="flex-start">
                        <Grid item xs direction="column" container justifyContent="flex-start" >
                            <Typography noWrap gutterBottom variant="body1" component="span" >
                                {t("deviceUid")}{t(displayUID)}
                            </Typography>
                        </Grid>
                        <Grid item xs direction="column" container justifyContent="flex-start" >
                            <Typography noWrap gutterBottom variant="body1" component="span" >
                                {t("activeStatus")} {t(active)}
                            </Typography>
                        </Grid>                          
                    </Grid>
                    <Typography noWrap gutterBottom variant="body1" component="span" >
                            {t("deviceType")} {t(type)}
                    </Typography> 
                </Grid>
                <Tooltip title={t("removeDeviceFromAccount")}>
                    <IconButton
                        edge="start"
                        color='primary'
                        onClick={opendelDialog}
                        >
                        <RemoveCircleIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>
    </ListItem>
}
{deleteDialogue && <ModalPrompt
        title={t(device.uid)}
        content={t('confirmDeleteDevice')}
        action={t('removeDeviceFromGroup')}
        handleAction={updateGroup} 
        handleCancel={closedelDialog} 
        openModal={deleteDialogue}
        useConfirm={false}
        fullScreen={fullScreen}
        reverseEmphasis={true}
      />}
{false && <Dialog
    disableEscapeKeyDown
    open={deleteDialogue}
    onClose={closedelDialog}
    fullScreen={fullScreen}>
    <DialogTitle>{t(device.uid)}</DialogTitle>
    <DialogContent >
        <Typography>{t("confirmDeleteDevice")}</Typography>
    </DialogContent>
    <DialogActions>
        <Button variant="contained" onClick={closedelDialog} color="primary">
            {t("cancel")}
        </Button>
        <Button  onClick={updateGroup} color="primary">
                {t("removeDeviceFromGroup")}
        </Button>
    </DialogActions>
    </Dialog>
}
</>
};

export default GroupDeviceCard;