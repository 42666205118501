import React, { useState, useEffect} from 'react';

import {
    IconButton,
    FormControlLabel,
    Grid,
    ListItem,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@emotion/react';
import { useTranslation } from 'react-i18next';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ModalPrompt from '../../../CommonComponents/ModalPrompt';

const UserCard = ({data, user, accountInfo, setUserRole, removeUser, fetchAccountInfo }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [role, setRole] = useState(user.access_type);
    const roleList =['owner', 'admin', 'tech', 'user'];
    const [removeDialog, setRemoveDialog] = useState(false);

    const checkCanEdit = () => {
        let output = true;
        if (user.access_type === "owner" && data.access_type !== "owner"){
            output = false;
        }
        if (user.user_id === data.user_id){
            output = false;
        }
        return output
    }

    useEffect(()=>{
        setRole(user.access_type);
    }, [user])

    const handleChangeRole = (event) => {
        setUserRole(user.user_id, event.target.value);
        //setRole(event.target.value);
        fetchAccountInfo();
    };

    function openRemoveDialog(){
        setRemoveDialog(true);
    };

    function closeRemoveDialog(){
        setRemoveDialog(false);
    }

    const handleRemoveUser = async () => {
        removeUser(user.user_id);
        closeRemoveDialog();
    };

    return(<>{
        <ListItem key={user.user_id} 
        sx={{paddingLeft: 
            theme.spacing(1), 
            paddingRight: theme.spacing(1),
            maxWidth: 500,
            minWidth: 250,
        }}>
            <Grid container 
                sx={{
                    backgroundColor: theme.palette.grey.dark,
                    borderRadius: '4px',
                    maxWidth: 500,
                    minWidth: 250,
                }}>  
                <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs 
                        sx={{
                            padding: theme.spacing(1)
                        }}  >
                        {user.given_name && <Typography variant="body1">
                                {"{given_name} {family_name}".replace("{given_name}", user.given_name).replace("{family_name}", user.family_name)}
                            </Typography>}
                        <Typography noWrap gutterBottom variant="body1">
                            {user.user_id}
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs>
                            <FormControlLabel sx={{ m: 1 }} 
                                control={
                                    <Select 
                                        size="small"
                                        labelId='select role'
                                        value={role}
                                        defaultValue={user.access_type}
                                        onChange={handleChangeRole}
                                        disabled={!checkCanEdit()}>
                                            {roleList.map(role => {
                                                return(
                                                    <MenuItem value={role}>
                                                        <li>{t(role)}</li>
                                                    </MenuItem> 
                                                );  
                                            })} 
                                    </Select>
                                }>
                            </FormControlLabel>
                        </Grid>
                        <Grid item xs>
                            <Tooltip title={t("removeUserFromAccount")}>
                            <IconButton
                                edge="start"
                                color='primary'
                                onClick={openRemoveDialog}
                                disabled={!checkCanEdit()}
                                >
                                    <RemoveCircleIcon/>
                                </IconButton>  
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    }
    {removeDialog && <ModalPrompt
            title={''}
            content={t('removeUserDialog')}
            action={t('removeUserFromAccount')}
            handleAction={handleRemoveUser} 
            handleCancel={closeRemoveDialog} 
            openModal={removeDialog}
            fullScreen={fullScreen}
            useConfirm={false}
        />}
    </>
    )
};

export default UserCard;