/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import useSessionstorage from '@rooks/use-sessionstorage';
import Cookies from 'universal-cookie';
import {
    Collapse,
    Grid,
    List,
} from '@mui/material';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';


import { advanced_parameter_obj } from '../../../DeviceInfo/g3_Config.js';
import { hw_config_obj } from '../../../DeviceInfo/g3_Config.js';
import DataEntryListItemHeader from '../../../CommonComponents/DataEntryListItemHeader.js';
import GenerateConfigItems from './GenerateConfigItems.jsx';

// Device Page
export default function AdvancedSettings({editing, currentSettings, hwConfig, updateSettings=()=>{}}) {
    const params = useParams();
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    const { value: accountInfo } = useSessionstorage('accountInfo', {});
    const [editParam, setEditParam] = useState(false);
    const [openAdvanced, setOpenAdvanced] = useState(false);
    const [openParams, setOpenParams] = useState ({
        "43": false,
    });

    const handleToggle = (key_in) => {
        if (parseInt(currentSettings[key_in])) {
            handleUpdateParams(key_in,0);
        }
        else{
            handleUpdateParams(key_in,1);
        }
    };

    const handleOpenParam = (key_in) => {
        let l_openParams = openParams;
        l_openParams[key_in] = true;
        setOpenParams(l_openParams);
        setEditParam(true);
    };
    const handleCloseParams = () => {
        let l_openParams = openParams;
        Object.keys(l_openParams).forEach(key => {
            l_openParams[key] = false;
        })
        setOpenParams(l_openParams);
        setEditParam(false);
    };
    const handleUpdateParams = (key_in, value_in)  => {
        
        var temp_params = Object.assign({}, currentSettings);
        if (temp_params[key_in] !== String(value_in)){
            temp_params[key_in] = String(value_in);
            updateSettings(temp_params);
        }
        handleCloseParams();
    };

    const handleErrorCheckParams = (key_in, value_in) => {
        let value_out=undefined;
        let errorText = ""
        value_out = value_in;
        return {value: value_out, errorText: errorText}
    };

    const handleAdvancedClick = () => {
        const newState = !openAdvanced;
        setOpenAdvanced(newState);
    }

    const DisplayAdvanced = () => {
        const parameter_list = [
            {
                key: "23",
                "id":"startup_prelube_enable_id_23",
                "config_id":"23",
                'type': "toggle",
             },
             {
                key: "24",
                "id":"startup_delay_enable_id_24",
                "config_id":"24",
                'type': "toggle",
             },
             {
                key: "25",
                "id":'startup_delay_setting_id_25',
                'config_id': "25",
                "type":"time",
             },
             {
                key: "43",
                'id': 'lubrication_end_retry_id_43', 
                'config_id': "43",
                'type': "number",
                "digits": 3
            },
        ]
        return (
            <Grid>
                <List>
                    <DataEntryListItemHeader 
                        label={t('advancedOptions')}
                        collapsableState={openAdvanced}
                        collapsable={true}
                        onClick={handleAdvancedClick}
                    />
                    <Collapse in={openAdvanced} unmountOnExit>
                        <GenerateConfigItems 
                            editing={editing}
                            parameter_list={parameter_list}
                            parameter_obj={advanced_parameter_obj}
                            currentSettings={currentSettings} 
                            openParams={openParams}
                            handleUpdateParams={handleUpdateParams}
                            handleOpenParam={handleOpenParam}
                            handleCloseParams={handleCloseParams}
                            handleToggle={handleToggle}
                            handleErrorCheckParams={handleErrorCheckParams}
                            hour_length={0}
                        />
                    </Collapse>
                </List>
            </Grid>
        )
    };

    //Header consumes 64px of viewable area
    return (
        <Grid container item direction='column'>
            <DisplayAdvanced/>
        </Grid>
    )
}
