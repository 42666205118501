import React from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import TankImage from "./assets/img/barrel-empty-large.png";
import TankImageTall from "./assets/img/barrel-empty-large-tall.png";


const TankStatusImage = ({theme, levelHeight, tankLevel, currentColor, 
                            device_state, analog_level=true, deviceActive=true,
                            inAlarm=false, inAlert=false, size='small'}) => {
    let local_levelHeight = deviceActive ? analog_level ? 
                                levelHeight 
                                : inAlarm ? "5%" 
                                    : inAlert ? "15%"
                                    :"100%"
                            : "100%"
    
    return(
        
        <Box id="tank-gauge" 
                sx={{
                    display: 'flex',
                    width: size === "large" ? '75px' : '50px',
                    height: size === 'large' ? '140px' : '70px',
                    //position: 'relative',
                    margin: '5px auto',
                    marginRight: theme.spacing(2)
                }}>
                <div id="empty-barrel" 
                    style={{
                        position: 'absolute',
                        //height: '100%',
                        //width: '100%',
                        //background: 'no-repeat center',
                        //backgroundSize: '50px 140px',
                        zIndex: 4,
                        }}>
                    <img src={size === 'large' ? TankImageTall : TankImage} style={{ width: size==='large' ? '75px' : '50px' }}/>
                </div>
                <Box id='tank-val' sx={{ 
                                display: 'flex',
                                width: size === 'large' ? '66px' : '42px',
                                height: size === 'large' ? '130px' : '65px',
                                margin: size === 'large' ? '3px 5px 8px' : '0px 4px 8px',
                                position: 'absolute',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 4
                            }}>
                        {( ((device_state.state === "state_running" || device_state.state === "state_idle") 
                            || !inAlarm) && deviceActive)
                                ? analog_level 
                                    ? <Typography component='span' variant="body2">
                                        {tankLevel}%
                                    </Typography>
                                : inAlert 
                                    ?<ErrorOutline 
                                        color='warning'
                                        fontSize={size === 'large' ? 'large' : 'medium'}/>
                                    :<Typography variant="body2" component="div"></Typography>
                            : <Typography variant="body2" component="div">
                                    <ErrorOutline 
                                    color={deviceActive ? 
                                            analog_level ? 
                                                (inAlarm ? 
                                                    (tankLevel > 25 ? undefined : 'error') 
                                                    : inAlarm ? (tankLevel > 25 ? undefined : 'warning') 
                                                                : undefined)
                                                : inAlarm ? 'error' : 'warning'
                                            : 'error'
                                            }
                                    fontSize={size === 'large' ? 'large' : 'medium'}/>
                                </Typography>
                        }
                </Box>
                <Box id="fluid" 
                    style={{
                        position: 'relative',
                        width: size === 'large' ? '66px' : '42px',
                        height: size === 'large' ? '130px' : '65px',
                        margin: size === 'large' ? '3px 5px 8px' : '0px 4px 8px',
                        zIndex: 1
                    }}>
                    
                    <div id='level' style={{
                        'backgroundColor': currentColor, 
                        'height': local_levelHeight, 
                        position: 'absolute',
                        width: '100%',
                        maxHeight: '100%',
                        borderRadius: size === 'large' ? '2px' : '2px',
                        bottom: 0,
                        transition: 'height 2s ease-out'}}/>
                    <div id='bottom' style={{
                        'backgroundColor': currentColor,
                        position: 'absolute',
                        width: '100%',
                        height: size === 'large' ? '12px' : '12px',
                        borderRadius: size === 'large' ? '120px / 30px' : '240px / 30px',
                        bottom: size === 'large' ? '-4px' : '-4px',
                        zIndex: 1}}/>
                </Box>
            </Box>
    );
};

export default TankStatusImage;