/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import useSessionstorage from '@rooks/use-sessionstorage';
import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Typography
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import { secondsToTime } from '../../../../helpers/secondsToTime';
import { timeToTimeString } from '../../../../helpers/timeToTimeString';
import { celsiusToFahrenheit } from '../../../../helpers/convertUnits.js';
import {g3_metrics, g3_meta_metrics} from '../g3_metrics.js';
import {FormatDateIsoString} from '../../../../helpers/useFormatDateIsoString';
import MetricsHistogram from './MetricsHistogram';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function G3MetricsView({currentMetrics, deviceInfo, syncMetrics=()=>{}, clearMetrics=()=>{}}) {
    const { t } = useTranslation();
    const theme=useTheme();
    const { value: accountInfo } = useSessionstorage('accountInfo', {});
    const { value: userPreferredUnits } = useSessionstorage('preferredUnits', undefined)
    const [histDataSelect, setHistDataSelect] = useState("user");
    const [unitsMetric, setUnitsMetric] = useState(userPreferredUnits === 'metric' ? true : false);
    const handleHistDataChange = (event) => {
      setHistDataSelect(event.target.value);
    };

    const handle_SyncMetrics = () => {
      syncMetrics();
    };

    const handle_ClearMetrics = () => {
      clearMetrics();
    };

    const generateTemperatureBins = (input_bins) => {
      var output_bins = {};
      for (const [key, value] of Object.entries(input_bins)){
        if(unitsMetric){
          output_bins[key] = value;
        } else{
          output_bins[key] = celsiusToFahrenheit(parseInt(value)).toFixed(0);
        }
      }
      return output_bins;
    }

    const createRowData = (metrics, metricsList) => {
      var rowData = []
      
      metricsList.forEach(item => {
          let row = {}
          row['name'] = item.displayName;
          if (item.type === 'percentage'){
            row['user'] = metrics[item.metric_id].user.param + ' %';
            row['life'] = metrics[item.metric_id].life.param + ' %';
          }
          else if (item.type === 'time'){
            let temp_user = secondsToTime(parseInt(metrics[item.metric_id].user.param));
            let temp_life = secondsToTime(parseInt(metrics[item.metric_id].life.param));
            row['user'] = timeToTimeString(temp_user.hours, temp_user.minutes, temp_user.seconds);
            row['life'] = timeToTimeString(temp_life.hours, temp_life.minutes, temp_life.seconds);
          }
          else if (item.type === 'temperature'){
            if(unitsMetric){
              row['user'] = metrics[item.metric_id].user.param;
              row['life'] = metrics[item.metric_id].life.param;
            } else{
              row['user'] = parseFloat(celsiusToFahrenheit(metrics[item.metric_id].user.param).toFixed(1));
              row['life'] = parseFloat(celsiusToFahrenheit(metrics[item.metric_id].life.param).toFixed(1));
            }
          }
          else {
            row['user'] = metrics[item.metric_id].user.param;
            row['life'] = metrics[item.metric_id].life.param;
          }
          rowData.push(row);
      })
      return rowData;
    }

    const createTemperatureHistogramRowData = (metrics_user, metrics_life, binNames) => {
      var rowData = [];
      let bin_keys = Object.keys(binNames);
      let bin_keys_integers = bin_keys.map(Number);
      bin_keys_integers.sort((a,b) => a-b);
      let maxIndex = bin_keys_integers[bin_keys_integers.length-1];
      if (metrics_user === undefined || metrics_life === undefined){
        console.log("Invalid Histogram data (temp)");
      }
      else{
        for (let i = 0; i < bin_keys_integers.length; i++){
            let data={};
            let temp='';
            let temp1 = '';
            if (bin_keys_integers[i] === 0) {
                temp = unitsMetric ? "{bin}\u00b0C".replace("{bin}", binNames[bin_keys_integers[i].toString()])
                  : "{bin}\u00b0F".replace("{bin}", celsiusToFahrenheit(binNames[bin_keys_integers[i].toString()]).toFixed(0));
                data.name = t("metricsTemperatureBin0Label").replace("{bin}", temp);
            } else if (bin_keys_integers[i] === maxIndex) {  
                temp = unitsMetric ? "{bin}\u00b0C".replace("{bin}", binNames[bin_keys_integers[i-1].toString()])
                  : "{bin}\u00b0F".replace("{bin}", celsiusToFahrenheit(binNames[bin_keys_integers[i-1].toString()]).toFixed(0));
                data.name = t("metricsTemperatureBinMaxLabel").replace("{bin}", temp);
            } else{
                temp = unitsMetric ? "{temp}\u00b0C".replace("{temp}", binNames[bin_keys_integers[i-1].toString()])
                  : "{temp}\u00b0F".replace("{temp}", celsiusToFahrenheit(binNames[bin_keys_integers[i-1].toString()]).toFixed(0));
                temp1 = unitsMetric ? "{temp}\u00b0C".replace("{temp}", binNames[bin_keys_integers[i].toString()])
                  : "{temp}\u00b0F".replace("{temp}", celsiusToFahrenheit(binNames[bin_keys_integers[i].toString()]).toFixed(0));
                data.name = t("metricsTemperatureBinLabel").replace("{lower}", temp).replace(
                    "{upper}", temp1
                );
            }
            data['user'] = metrics_user[bin_keys_integers[i]];
            data['life'] = metrics_life[bin_keys_integers[i]];
            rowData.push(data);
        }
      }
      return rowData;
    };

    function GeneratePumpCurrentBins (binNames) {
      let local_binNames = {};
      let max_index = Object.keys(binNames).length - 1;
      
      for (let i = 0; i < Object.keys(binNames).length; i++)
      {
        local_binNames[i] = binNames[i];
      }
      local_binNames[max_index + 1] = "20";
      return local_binNames;
    }
    
    const createPumpCurrentHistogramRowData = (metrics_user, metrics_life, binNames) => {
      //Current bins assume it is known that 0A is the lowest.  Correct bins names, by adding 0A
      let local_binNames = GeneratePumpCurrentBins(binNames);
      var rowData = [];
      let bin_keys = Object.keys(local_binNames);
      let bin_keys_integers = bin_keys.map(Number);
      bin_keys_integers.sort((a,b) => a-b);
      let maxIndex = bin_keys_integers[bin_keys_integers.length-1];
      if (metrics_user === undefined || metrics_life === undefined){
        console.log("Invalid Histogram data (current)");
      }
      else{
        //Last Bin to be ignored.
        for (let i = 0; i < bin_keys_integers.length; i++){
            let data={};
            let temp='';
            let temp1 = '';
            if (bin_keys_integers[i] === 0) {
                temp = "{bin}A".replace("{bin}", local_binNames[bin_keys_integers[i].toString()]);
                data.name = t("metricsPumpCurrentBin0Label").replace("{bin}", temp);
            } else if (bin_keys_integers[i] === (maxIndex)) {  
                temp = "{bin}A".replace("{bin}", local_binNames[bin_keys_integers[i-1].toString()]);
                data.name = t("metricsPumpCurrentBinMaxLabel").replace("{bin}", temp);
            } else{
                temp = "{temp}A".replace("{temp}", local_binNames[bin_keys_integers[i-1].toString()]);
                temp1 = "{temp}A".replace("{temp}", local_binNames[bin_keys_integers[i].toString()]);
                data.name = t("metricsPumpCurrentBinLabel").replace("{lower}", temp).replace(
                    "{upper}", temp1
                );
            }
            data['user'] = metrics_user[bin_keys_integers[i]];
            data['life'] = metrics_life[bin_keys_integers[i]];
            rowData.push(data);
        }
      }
      return rowData;
    };

    const DisplayMetricsTable = ({metrics, metricsList, allMetrics}) => {
      //For Histogram Data, form metrics like {user: {}, life: {}}
      var rows = [];
      let temp = [];
      
      rows = createRowData(metrics, metricsList);
      try{
        if (Object.keys(allMetrics.bins_t)){
          temp = createTemperatureHistogramRowData(allMetrics.user_t, allMetrics.life_t, allMetrics.bins_t);
          rows = [...rows, ...temp];
        }
      } catch (error){
        console.log("Unable to create Temperature histogram data.")
      }
      try{
        if (Object.keys(allMetrics.bins_pi)){
          temp = createPumpCurrentHistogramRowData(allMetrics.user_pi, allMetrics.life_pi, allMetrics.bins_pi);
          rows = [...rows, ...temp];
        }
      } catch(error){
        console.log("Unable to create Pump Current histogram data.")
      }
      return (<MetricsTable rows={rows}/>)
    }

    const MetricsTable = ({rows, includeHeader=true}) => {
      return(
        <TableContainer component={Paper} >
          <Table aria-label="customized table">
            {includeHeader && <TableHead>
              <TableRow>
                <StyledTableCell>{t('metricTableMetric')}</StyledTableCell>
                <StyledTableCell align="right">{t('metricUser')}</StyledTableCell>
                <StyledTableCell align="right">{t('metricLife')}</StyledTableCell>
              </TableRow>
            </TableHead>}
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {t(row.name)}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.user}</StyledTableCell>
                  <StyledTableCell align="right">{row.life}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    const createMetaList = (metrics, device, meta_list) => {
      var listData = [];
      meta_list.forEach(item => {
        let data = {};
        var config_time_epoch = 0;
        if (item.type === 'config_time'){
          Object.keys(device.config_reported_meta).forEach((key, index) => {
            if (device.config_reported_meta[key].timestamp > config_time_epoch){
              config_time_epoch = device.config_reported_meta[key].timestamp;
            }
            let d = new Date(0);
            d.setUTCSeconds(config_time_epoch);
            data.name = item.displayName;
            data.value = d.toLocaleString();
          });

        } else if (item.type === 'date_time'){
          data.name = item.displayName;
          if (metrics.metrics[item.metric_id]){
            data.value = FormatDateIsoString(metrics.metrics[item.metric_id].user.param);
          }
          else{
            data.value = FormatDateIsoString(metrics.meta[item.metric_id]);
          }
        }
        else {
          data.name = item.displayName;
          item.value = metrics.metrics[item.metric_id].user.param;
        }
        listData.push(data);
      })
      return listData;
    }
    const DisplayMetricsMeta = ({metrics, device, meta_list}) => {
      const listlist = createMetaList(metrics, device, meta_list);
      const invertEmphasis = false;
      
      return(
        <List sx={{
            maxWidth: 600,
            minWidth: 350,
          }}>
          {listlist.map((item) => (
            <ListItem 
              sx={{paddingLeft: theme.spacing(0), 
                paddingRight: theme.spacing(0),
                maxWidth: 600,
                minWidth: 350,
              }}>
                <Grid container 
                    direction='row' 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    sx={{
                        backgroundColor: theme.palette.grey.dark,
                        borderRadius: '4px',
                        //maxWidth: 550,
                }}>
                  <ListItemText  primaryTypographyProps={{
                      //fontWeight: invertEmphasis ? undefined : "bold",
                      color: invertEmphasis ? theme.palette.primary.main : undefined,
                      margin: theme.spacing(1),
                  }}
                    primary={t(item.name)}
                  />
                  <ListItemIcon>
                    <Typography sx={{
                            color: invertEmphasis ? undefined : theme.palette.primary.main,
                            margin: theme.spacing(1),
                            }}>
                        {item.value}
                    </Typography>
                  </ListItemIcon>
                </Grid>
                
              
            </ListItem>
          ))}
        </List>
      );
    }

    const handleChangeUnits = (event) => {
      if (event.target.value === "metric"){
        setUnitsMetric(true);
      } else{
        setUnitsMetric(false);
      }
    };

    const UnitSelector = () => {
      return (
        <FormControl size="small">
          <InputLabel id="unit-selector-label">{t('units')}</InputLabel>
          <Select
            labelId="unit-selector-label"
            id="unit-selector"
            value={unitsMetric ? "metric" : "uscs"}
            onChange={handleChangeUnits}
            label={t("units")}
          >
            <MenuItem value="uscs">{t('unitsUSCS')}</MenuItem>
            <MenuItem value="metric">{t('unitsMetric')}</MenuItem>
          </Select>
        </FormControl>
      );
    };
    
    return (
        <Grid item xs container direction='column'>
          <Grid item xs container direction='row-reverse' >
            <IconButton size='small' disabled={deviceInfo.connection > 0 ? false : true} sx={{color: theme.palette.secondary.light}} onClick={handle_SyncMetrics}>
                <SyncIcon/>
                {t("metricsSync").toUpperCase()}
            </IconButton>
            {accountInfo.isAccountTech ? <IconButton disabled={deviceInfo.connection > 0 ? false : true} size='small' onClick={handle_ClearMetrics}>
                <DeleteSweepIcon/>
                {t("metricsResetUserData").toUpperCase()}
            </IconButton> : <></>}
            <UnitSelector/>
          </Grid>
          <Divider/>
          <Box height="calc(100vh - 128px - 64px - 2px - 49px - 58px)" overflow='auto' sx={{ width: '100%' }}>
            {currentMetrics.meta ? <Grid item xs container direction='column'>
              <Grid item xs container alignContent='center' justifyContent='center' direction='row'>
                {currentMetrics.bins_pi && currentMetrics.bins_t && <FormControl variant='outlined'>
                  <RadioGroup
                    row
                    value={histDataSelect}
                    onChange={handleHistDataChange}
                    aria-labelledby="histogram-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel value={"user"} 
                        control=
                          {<Radio sx={{
                                    color: theme.palette.primary.main,
                                    '&.Mui-checked': {
                                      color: theme.palette.secondary.dark,
                                    },
                                  }}
                            />} 
                        label={t('userMetrics')} />
                    <FormControlLabel value={"life"} 
                      control={
                        <Radio sx={{
                                  color: theme.palette.primary.main,
                                  '&.Mui-checked': {
                                    color: theme.palette.green.main,
                                  },
                                }}
                        />}  
                      label={t('lifeMetrics')} />
                    <FormControlLabel value={"all"} control={
                        <Radio sx={{
                                  color: theme.palette.primary.main,
                                    '&.Mui-checked': {
                                      color: theme.palette.secondary.dark,
                                      },
                                }}
                        />} 
                        label={t('allMetrics')} />
                  </RadioGroup>
                </FormControl>}
                <MetricsHistogram dataSelect={histDataSelect}
                  title={'pumpCurrentHistogramTitle'} 
                  yLabel={"pumpCurrentYLabel"}
                  userMetricsData={currentMetrics.user_pi}
                  lifeMetricsData={currentMetrics.life_pi} 
                  binNames={GeneratePumpCurrentBins(currentMetrics.bins_pi !== undefined ? currentMetrics.bins_pi : {})}/>
                <MetricsHistogram dataSelect={histDataSelect}
                  title={unitsMetric ? 'temperatureHistogramTitleC' : 'temperatureHistogramTitleF'} 
                  yLabel={"temperatureYLabel"}
                  userMetricsData={currentMetrics.user_t}
                  lifeMetricsData={currentMetrics.life_t} 
                  binNames={generateTemperatureBins(currentMetrics.bins_t !== undefined ? currentMetrics.bins_t : {})}/>

              
                <DisplayMetricsMeta metrics={currentMetrics} device={deviceInfo} meta_list={g3_meta_metrics}/>
                <DisplayMetricsTable metrics={currentMetrics.metrics} metricsList={g3_metrics} allMetrics={currentMetrics}/>
              </Grid>
            </Grid>
            : <Grid item xs container>
                <Typography>{t('noData')}</Typography>
              </Grid>}
          </Box>
        </Grid>
    )
}
