/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography
} from '@mui/material';
import { 
    Edit,
    ArrowBack,
} from '@mui/icons-material';

import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import { GrowDiv } from '../../../styledComponentsCommon.js';
import devices_inf from '../../../services/devices_inf.js';
import EditFriendlyName from '../../../CommonComponents/EditFriendlyName.js';
import LubricationIntervalSettings from '../Components/LubricationIntervalSettings.jsx';
import { 
    pin_code_parameters_obj,
    advanced_parameter_obj,
    interval_parameters_obj,
    low_level_parameter_obj,
    lubrication_parameters_obj,
    outputs_parameter_obj,
    hw_config_obj
     } from '../../../DeviceInfo/g3_Config.js';
import PinCodeSettings from '../Components/PinCodeSettings.jsx';
import LubricationEndSettings from '../Components/LubricationEndSettings.jsx';
import LowLevelSettings from '../Components/LowLevelSettings.jsx';
import OutputSettings from '../Components/OutputSettings.jsx';
import AdvancedSettings from '../Components/AdvancedSettings.jsx';
import ToggleListItem from '../../../CommonComponents/ToggleListItem.js';
import configurations_inf from '../../../services/configurations_inf';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setEnablePinCode } from '../../../helpers/setEnablePinCode.js';


// Device Page
export default function DeviceConfigPage(accountinfo) {
    const params = useParams();
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const invertEmphasis=true;
    //const { value: accountInfo } = useSessionstorage('accountInfo', {});
    const [accountInfo, setAccountInfo] = useState(accountinfo.accountInfo);
    const [dataReady, setDataReady] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [templateNames, setTemplateNames] = useState([]);
    const [selectedTemplate, setSeletectTemplate] = useState({});
    const [loadTemplate, setLoadTemplate] = useState(false);
    const [displayData, setDisplayData] = useState({permissions:{}, groupList:[]});
    const [editing, setEditing] = useState(false);
    const [cloudManagedChecked, setCloudManagedChecked] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [saving, setSaving] = useState(false);
    
    
    const fetchDevice = async () => {
        console.log("FetchDevice");
        try{
            let deviceDetails = {groupList:[], permissions:{}};
            const { status, error, deviceDetailsPermissions, deviceDetailsInfo, groupsDetailsList } = await devices_inf.getDeviceDetails(
                accountInfo.accountId, params.deviceId
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                    alert(t("accessDeniedNoView"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                deviceDetails = deviceDetailsInfo;
                deviceDetails.groupList = groupsDetailsList;
                deviceDetails.permissions = deviceDetailsPermissions;
                handle_filterData(deviceDetails);
            }
        } catch(error){
            console.error(error);
            setDataReady(true);
        }
        
    };

    const fetchTemplates = async () => {
        try{
            const {status, error, templates} = await configurations_inf.getTemplates(
                accountInfo.accountId,
            );
            
            if (status === 404) {
                return;
            }
    
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
              //alert(t(error));
              throw new Error(error);
            }
            else{
                //setTemplates(templates);
                filterTemplates(templates);
            }
        }catch(error){
            console.log(error);
        }
    };

    function filterTemplates(template_list){
        let temp = []
        let localTemplates = []
        let addTemplate = true;
        
        for (let i=0; i < template_list.length; i++){
            addTemplate = true;
            if(template_list[i].type === displayData.type){
                let required_hw_id = template_list[i].param.required_hw_id ? template_list[i].param['required_hw_id'] : "";
                required_hw_id = required_hw_id.split(",");
                for (let j = 0; j < required_hw_id.length; j++){
                    if(!displayData.hw_config.includes(parseInt(required_hw_id[j]))){
                        addTemplate = false;
                        break;
                    }
                }
                if (addTemplate){
                    localTemplates.push(template_list[i]);
                    temp.push(template_list[i].name);
                }
            }
        }
        setTemplateNames(temp);
        setTemplates(localTemplates);
    };

    function defaults_by_hw_config(param_obj, hwConfig){
            let useDisabledDefault = false;
            let output = String(param_obj.default);
            Object.keys(hw_config_obj).forEach(key => {
                    if(hw_config_obj[key].affected_config_id.includes(String(param_obj.id))){
                        if (!hwConfig.includes(hw_config_obj[key].id)){
                            useDisabledDefault = true;
                        }
                    }
            });
            if (useDisabledDefault){
                output = String(param_obj.disabled_default);
            }
            return (output);
        }
            
    const handle_filterData = (dataSet) => {
        let data = dataSet;
        if(!dataSet.config_id){
            if (true){
                //console.log("No Configuration Found, set defaults");
                let t_config = dataSet.config ? dataSet.config : dataSet.config_reported ? dataSet.config_reported : {};
                Object.keys(interval_parameters_obj).forEach(key => {
                    if(!dataSet.config[key]){
                        t_config[key] = dataSet.config_reported[key] ? dataSet.config_reported[key] : defaults_by_hw_config(interval_parameters_obj[key], dataSet.hw_config);
                    } else{
                        if(interval_parameters_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(dataSet.config[key]));
                        } else{
                            t_config[key] = dataSet.config[key];
                        }
                    }
                });
                Object.keys(lubrication_parameters_obj).forEach(key => {
                    if(!dataSet.config[key]){
                        t_config[key] = dataSet.config_reported[key] ? dataSet.config_reported[key] : defaults_by_hw_config(lubrication_parameters_obj[key], dataSet.hw_config);
                    } else{
                        if(lubrication_parameters_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(dataSet.config[key]));
                        } else{
                            t_config[key] = dataSet.config[key];
                        }
                    }
                });
                Object.keys(low_level_parameter_obj).forEach(key => {
                    if(!dataSet.config[key]){
                        t_config[key] = dataSet.config_reported[key] ? dataSet.config_reported[key] : defaults_by_hw_config(low_level_parameter_obj[key], dataSet.hw_config);
                    } else{
                        if(low_level_parameter_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(dataSet.config[key]));
                        } else{
                            t_config[key] = dataSet.config[key];
                        }
                    }
                });
                Object.keys(outputs_parameter_obj).forEach(key => {
                    if(!dataSet.config[key]){
                        t_config[key] = dataSet.config_reported[key] ? dataSet.config_reported[key] : defaults_by_hw_config(outputs_parameter_obj[key], dataSet.hw_config);
                    } else{
                        if(outputs_parameter_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(dataSet.config[key]));
                        } else{
                            t_config[key] = dataSet.config[key];
                        }
                    }
                });
                Object.keys(advanced_parameter_obj).forEach(key => {
                    if(!dataSet.config[key]){
                        t_config[key] = dataSet.config_reported[key] ? dataSet.config_reported[key] : defaults_by_hw_config(advanced_parameter_obj[key], dataSet.hw_config);
                    } else{
                        if(advanced_parameter_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(dataSet.config[key]));
                        } else{
                            t_config[key] = dataSet.config[key];
                        }
                    }
                });
                Object.keys(pin_code_parameters_obj).forEach(key => {
                    if(!dataSet.config[key]){
                        t_config[key] = dataSet.config_reported[key] ? dataSet.config_reported[key] : defaults_by_hw_config(pin_code_parameters_obj[key], dataSet.hw_config);
                    } else{
                        if(pin_code_parameters_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(dataSet.config[key]));
                        } else{
                            t_config[key] = dataSet.config[key];
                        }
                    }
                });
                data.config=t_config;
            }
            if(!dataSet.config.cm){
                setCloudManagedChecked(false);
            }
            else{
                setCloudManagedChecked(true);
            }
            
            setDisplayData(data);
            if(data.permissions.write_access || accountInfo.isAccountTech){
                setCanEdit(true);
            }
            setDataReady(true); 
        }
    }
        
    const handleSelectTemplate = (event) => {
        let data = displayData;
        let temp = event;
        let template = {}
        for (const [key, value] of Object.entries(templates)) {            
            if(value.name === temp){
                template = value;
                setSeletectTemplate(value);
            }
        }
        // let t_config = {}
        //Determine Configuration Tags to ignore based on hardware id.
        let ignore_config_id = [];
        Object.keys(hw_config_obj).forEach(key => {
            if (!displayData.hw_config.includes(hw_config_obj[key].id)){
                ignore_config_id = ignore_config_id.concat(hw_config_obj[key].affected_config_id);
            }
        });
        let t_config = displayData.config ? displayData.config : displayData.config_reported ? displayData.config_reported : {};
        if(!data.config_id){
            Object.keys(interval_parameters_obj).forEach(key => {
                if(ignore_config_id.includes(key)){
                    t_config[key] = defaults_by_hw_config(interval_parameters_obj[key], displayData.hw_config);
                }
                else{
                    if(!template.param[key]){
                        t_config[key] = template.param[key] ? template.param[key] :  defaults_by_hw_config(interval_parameters_obj[key], displayData.hw_config);
                    }else{
                        if(interval_parameters_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(template.param[key]));
                        } else{
                            t_config[key] = template.param[key];
                        }
                    }
                }
            });
            Object.keys(lubrication_parameters_obj).forEach(key => {
                if(ignore_config_id.includes(key)){
                    t_config[key] = defaults_by_hw_config(lubrication_parameters_obj[key], displayData.hw_config);
                }
                else{
                    if(!template.param[key]){
                        t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(lubrication_parameters_obj[key], displayData.hw_config);
                    }else{
                        if(lubrication_parameters_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(template.param[key]));
                        } else{
                            t_config[key] = template.param[key];
                        }
                    }                
                }
            });
            Object.keys(low_level_parameter_obj).forEach(key => {
                if(ignore_config_id.includes(key)){
                    t_config[key] = defaults_by_hw_config(low_level_parameter_obj[key], displayData.hw_config);
                }
                else{
                    if(!template.param[key]){
                        t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(low_level_parameter_obj[key], displayData.hw_config);
                    }else{
                        if(low_level_parameter_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(template.param[key]));
                        } else{
                            t_config[key] = template.param[key];
                        }
                    }
                }
            });

            Object.keys(outputs_parameter_obj).forEach(key => {
                if(ignore_config_id.includes(key)){
                    t_config[key] = defaults_by_hw_config(outputs_parameter_obj[key], displayData.hw_config);
                }
                else{
                    if(!template.param[key]){
                        t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(outputs_parameter_obj[key], displayData.hw_config);
                    } else{
                        if(outputs_parameter_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(template.param[key]));
                        } else{
                            t_config[key] = template.param[key];
                        }
                    }                
                }
            });
            Object.keys(advanced_parameter_obj).forEach(key => {
                if(ignore_config_id.includes(key)){
                    t_config[key] = defaults_by_hw_config(advanced_parameter_obj[key], displayData.hw_config);
                }
                else{
                    if(!template.param[key]){
                        t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(advanced_parameter_obj[key], displayData.hw_config);
                    } else{
                        if(advanced_parameter_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(template.param[key]));
                        } else{
                            t_config[key] = template.param[key];
                        }
                    }                
                }
            });
            Object.keys(pin_code_parameters_obj).forEach(key => {
                if(ignore_config_id.includes(key)){
                    t_config[key] = defaults_by_hw_config(pin_code_parameters_obj[key], displayData.hw_config);
                }
                else{
                    if(!template.param[key]){
                        t_config[key] = template.param[key] ? template.param[key] : defaults_by_hw_config(pin_code_parameters_obj[key], displayData.hw_config);
                    } else{
                        if(pin_code_parameters_obj[key].type === "float"){
                            t_config[key] = String(parseFloat(template.param[key]));
                        } else{
                            t_config[key] = template.param[key];
                        }
                    }
                }
            });
        }
            data.config=t_config;
            // data.config_id = template.id
            setDisplayData(data);
            handleCloseLoadTemplate();
    };

    useEffect(() => {
        // Fetch Data
        fetchDevice();
    },[]);

    const handleUpdateFriendlyName = async (newName) => {
        try{
            const friendlyName = newName;
            
            const { status, error } = await devices_inf.updateDeviceName(
                accountInfo.accountId, params.deviceId, friendlyName
            );
            
            if (status === 404) {
                return;
            }
            console.log(error);
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectTodatabase"));
                }
                else if(error.includes("Access Denied, no permission to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                    alert(t("accessDeniedNoView"));
                }
                else if(error.includes("already in use")){
                    alert(t("duplicateNameFound") + "\n" + friendlyName);
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //throw new Error(error);
            }
            else{
                fetchDevice();
            }
        } catch(error){
            console.error(error);
        }
    };

    const handleStartEdit = () => {
        fetchTemplates();
        setEditing(true);
    };

    const cancelEdit = () => {
        setEditing(false);
        setSeletectTemplate({});
        fetchDevice();
    };

    const updateDeviceConfig = async (settingsIn) => {
        try{

            const { status, error, deviceDetailsInfo } = await devices_inf.updateDeviceConfiguration(
                accountInfo.accountId, 
                params.deviceId,
                settingsIn,
            );  
            
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                console.log(error);
            }
            else{
                fetchDevice();
                setEditing(false);
                console.log("update complete");
            }
        } catch(error){
            console.error(error);
        }
    }

    const handleSave = () => {
        let currentSettings = JSON.parse(JSON.stringify(displayData.config));
        const AllSettings = {...interval_parameters_obj, ...lubrication_parameters_obj, ...low_level_parameter_obj, 
            ...outputs_parameter_obj, ...advanced_parameter_obj, ...pin_code_parameters_obj};
        //Error Check Params
        if (cloudManagedChecked){
            currentSettings['cm'] = 1;
            currentSettings = setEnablePinCode(displayData.type, currentSettings);
        }
        else {
            currentSettings['cm'] = 0;
        }
        //Iterate through Keys and correctly format floats as %9.3
        Object.keys(AllSettings).forEach(key => {
            if(currentSettings[key]){
                if(AllSettings[key].type === "float"){
                    currentSettings[key] = parseFloat(currentSettings[key]).toFixed(3).padStart(9,'0');
                } 
            }
        });
        updateDeviceConfig(currentSettings);
        setSaving(true);
        setTimeout(() => {setSaving(false)}, 5000);
    };

    const handleToggleCloudManaged = () => {
        if(editing){
            setCloudManagedChecked(!cloudManagedChecked);
        }
    }

    const handleUpdatConfig = (newSettings) => {
        let data = displayData;
        Object.keys(newSettings).forEach(key => {
            data.config[key] = String(newSettings[key]);
        });
        setDisplayData(data);
        setReRender(!reRender);
    };

    function handleLoadTemplate(){
        setLoadTemplate(true);
    };

    function handleCloseLoadTemplate(){
        setLoadTemplate(false);
        setSeletectTemplate({});
    }


    //Header consumes 64px of viewable area
    return (
        <>
        <Grid container item direction='column'>
            <Grid height='64px' item xs>
                <Toolbar sx={{height: '64px'}}>
                    {editing ? <Button onClick={cancelEdit}>
                        {t("cancel")}
                    </Button>
                    : <IconButton
                            edge='start'
                            color='inherit'
                            onClick={()=>{navigation(-1);}}
                            aria-label='close'
                            >
                                <ArrowBack/>
                    </IconButton>}
                    <Box flexGrow={1}/>
                    {saving ? <CircularProgress/> 
                    : editing  ?
                        <Button onClick={handleSave} sx={{color:theme.palette.primary.contrastText}}>
                            {t("save")}
                        </Button>
                        : <IconButton disabled={!canEdit} onClick={handleStartEdit}>
                            <Edit/>
                        </IconButton>}
                </Toolbar>
            </Grid>
            <Box id="contentBox" height="calc(100vh - 128px)" overflow='auto'>
                {dataReady ? <Grid sx={{
                    minWidth: 350,
                    maxWidth: 500,
                }}
                item 
                container 
                direction='column'>              
                    <Divider/>
                    <Toolbar>
                        <Typography>{t('deviceName')}</Typography>
                        <GrowDiv/>
                        <Typography>{displayData.friendly_name ? displayData.friendly_name : params.deviceId}</Typography>
                        <GrowDiv/>
                        <EditFriendlyName disabled={!editing} currentName={displayData.friendly_name} onSave={handleUpdateFriendlyName} useEditIcon={false}/>
                    </Toolbar>
                    <Divider/>
                    <List 
                        sx={{
                        width: '100%',
                        maxWidth: 500
                    }}>
                        <ToggleListItem label={t("configCloudManaged")} 
                            param_id={'cm'} 
                            value={cloudManagedChecked}
                            handleClick={handleToggleCloudManaged}
                            clickDisabled={!editing}/>
                    </List>
                    {cloudManagedChecked &&  <Grid container 
                        direction="column"
                        
                        sx={{padding: theme.spacing(1)}}>
                            {editing && <Button variant="contained" 
                                
                                onClick={handleLoadTemplate} 
                                sx={{color:theme.palette.primary.contrastText, marginBottom:1}}    
                                color="secondary">
                                {t("loadConfig")}
                            </Button>}
                        <PinCodeSettings editing={editing} currentSettings={displayData.config} hwConfig={displayData.hw_config} updateSettings={handleUpdatConfig}/>
                        <LubricationIntervalSettings editing={editing} currentSettings={displayData.config} hwConfig={displayData.hw_config} updateSettings={handleUpdatConfig}/>
                        <LubricationEndSettings editing={editing} currentSettings={displayData.config} hwConfig={displayData.hw_config} updateSettings={handleUpdatConfig}/>
                        <LowLevelSettings editing={editing} currentSettings={displayData.config} hwConfig={displayData.hw_config} updateSettings={handleUpdatConfig}/>
                        <OutputSettings editing={editing} currentSettings={displayData.config} hwConfig={displayData.hw_config} updateSettings={handleUpdatConfig}/>
                        <AdvancedSettings editing={editing} currentSettings={displayData.config} hwConfig={displayData.hw_config} updateSettings={handleUpdatConfig}/>
                    </Grid>}
                </Grid>
                : <Grid container 
                    direction='row' 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    sx={{padding: theme.spacing(1)}}
                >
                    <Grid item xs>
                        <CircularProgress sx={{margin:theme.spacing(4)}}/>
                    </Grid>
                </Grid>}
            </Box>
        </Grid>
        {loadTemplate &&  <Dialog
            disableEscapeKeyDown
            open={loadTemplate}
            onClose={handleCloseLoadTemplate}
            fullScreen={fullScreen}>
                {templates.length>0 ? <DialogContent>
                    <Grid container item xs 
                            justifyContent='flex-start'
                            alignItems='center'
                            direction='column'>
                        <Grid item xs>
                            <Typography>{t("selectLoadConfig")}</Typography>  
                        </Grid>
                        <Grid item xs>
                            <List>
                                <ListItem key={displayData.id+'-divider'} divider disablePadding/>
                                {templateNames.map((template) => (
                                    <ListItem divider key={template} disablePadding>
                                    <ListItemButton 
                                        disabled={!editing} 
                                        onClick={() => {handleSelectTemplate(template)}}
                                    >
                                        <ListItemText  primaryTypographyProps={{
                                                fontWeight: invertEmphasis ? undefined : "bold",
                                                color: invertEmphasis ? theme.palette.primary.main : undefined,
                                            }}
                                            primary={t(template)}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </DialogContent>: <DialogContent>
                    <Typography align="center">{t("noTempaltes")}</Typography>
                    </DialogContent>}
                    {fullScreen && <DialogActions>
                        <Button fullWidth 
                            autoFocus
                            color='inherit'
                            onClick={handleCloseLoadTemplate}
                        >
                            {t('cancel')}
                        </Button>
                    </DialogActions>}
                {!fullScreen && <DialogActions>
                    <Button onClick={handleCloseLoadTemplate} color="primary">
                        {t("cancel")}
                    </Button>
                </DialogActions>}
            </Dialog>}
        </>
    )
}
