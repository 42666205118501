import { Fragment } from 'react';
import { Routes, Route, useLocation} from 'react-router-dom';
import useSessionstorage from '@rooks/use-sessionstorage';

import DeviceDetails from '../Components/DeviceDetails.js';
import DeviceConfigPage from './DeviceConfigPage/DeviceConfigPage.jsx';
import DeviceDiagnosticsPage from './DeviceDiagnostics/DeviceDiagnosticsPage.jsx';

const PageRouter = (props) => {
    let data = useLocation();
    return (
      <Routes>
        <Route exact path=':deviceId/diagnostics' element={<DeviceDiagnosticsPage accountInfo={props.accountInfo}/>} />
        <Route exact path=':deviceId/config' element={<DeviceConfigPage accountInfo={props.accountInfo}/>} />
        <Route exact path=':deviceId/' element={<DeviceDetails accountInfo={props.accountInfo}/>} />
      </Routes>
    );
  }

// Device Page
export default function DevicePage({accountInfo}) {
    return (
        <Fragment>
            <PageRouter accountInfo={accountInfo} />
        </Fragment>
    )
}
