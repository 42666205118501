import React from 'react';

import { useTranslation } from 'react-i18next';

import DataEntryListItem from '../../../CommonComponents/DataEntryListItem.js';
import DataEntryDialogueTime from '../../../CommonComponents/DataEntryDialogueTime.js';
import DataEntryDialogueList from '../../../CommonComponents/DataEntryDialogueList.js';
import DataEntryDialogueNumber from '../../../CommonComponents/DataEntryDialogueNumber.js';
import ToggleListItem from '../../../CommonComponents/ToggleListItem.js';
import { secondsToTime } from '../../../helpers/secondsToTime.js';
import { timeToTimeString } from '../../../helpers/timeToTimeString.js';

const paramToTimeString = (param, hour_length=4) => {
    let time = secondsToTime(parseInt(param));
    let timeString = timeToTimeString(time.hours, time.minutes, time.seconds, hour_length);
    return(timeString);
}

export default function GenerateConfigItems ({editing=false, parameter_list=[], parameter_obj={}, currentSettings={}, openParams={},
                handleUpdateParams=()=>{}, handleOpenParam=()=>{}, handleCloseParams=()=>{}, handleToggle=()=>{}, handleErrorCheckParams=()=>{},
                hour_length=4}) 
{
    const { t } = useTranslation();
    let name_obj = {}
    parameter_list.forEach((item) => {
        name_obj[item.config_id]={};
        if (item.type === "list"){
            item.list.forEach((itemitem) => {
                name_obj[item.config_id][itemitem.id]=itemitem.displayLabel;
            });
        }
    });
    
    return(
        <>
        {parameter_list.map(({key, config_id, type, list}) => (
            type === "toggle" ? 
            <ToggleListItem key={key}
                label={parameter_obj[config_id].display_name} 
                param_id={config_id}
                value={parseInt(currentSettings[config_id]) ? true : false} 
                handleClick={handleToggle} 
                clickDisabled={!editing}
            />
            : <DataEntryListItem key={key}
                    label={parameter_obj[config_id].display_name} 
                    config_id={config_id}
                    value={type === "time" ? paramToTimeString(currentSettings[config_id], hour_length)
                        : type === "list" ? t(name_obj[config_id][currentSettings[config_id]])
                        : currentSettings[config_id]} 
                    handleClick={handleOpenParam}
                    clickDisabled={!editing}/>
        ))}
        {parameter_list.map((param) => (
            param.type === "list" ?
                <DataEntryDialogueList  
                    key={'dataEntryDialogueList-'+param.key}
                    title={parameter_obj[param.config_id].display_name} 
                    param_id={param.config_id} 
                    instructions={param.instructions} 
                    dialogueOpen={openParams[param.config_id]} 
                    handleSave={handleUpdateParams} handleCancel={handleCloseParams} 
                    editing={editing}
                    listIn={param.list}/>
            : param.type === "number" ? 
                <DataEntryDialogueNumber 
                    key={'dataEntryDialogueNumber-'+param.key}
                    title={parameter_obj[param.config_id].display_name}
                    param_id={param.config_id}
                    value={currentSettings[param.config_id]}
                    instructions={param.instructions}
                    dialogueOpen={openParams[param.config_id]} 
                    handleSave={handleUpdateParams}
                    handleCancel={handleCloseParams}
                    maxDigits={param.digits}
                    valueRange={parameter_obj[param.config_id].range}
                    fieldLabel={param.fieldLabel}
                    />
            : param.type === "time" ?
                    <DataEntryDialogueTime 
                        key={'DataEntryDialogueTime-'+param.key}
                        title={parameter_obj[param.config_id].display_name}
                        param_id={param.config_id}
                        value={currentSettings[param.config_id]}
                        instructions={param.instructions}
                        dialogueOpen={openParams[param.config_id]} 
                        timeRange={parameter_obj[param.config_id].range}
                        handleSave={handleUpdateParams}
                        handleCancel={handleCloseParams}
                        errorCheck= {handleErrorCheckParams}
                        fourDigitHour={hour_length === 4 ? true : false}
                        showHour={hour_length > 0 ? true : false}
                    />
                : null
        ))}
    </>
    );
}