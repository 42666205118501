import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GracoIcon = (props) => {
    return(
        <SvgIcon {...props}>
            <svg fill="currentColor" width="26px" height="26px" id="Layer_1" viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg">
                <g id="Symbols" stroke="none" fill="none">
                    <g id="Logos/Graco_White" fill="#FFFFFF">
                        <g id="graco-logo_color">
                            <polygon id="Shape" points="7.22343096 0 0 12.5020921 7.22343096 25.0041841 14.4468619 25.0041841 7.22343096 12.5020921 14.4468619 0"></polygon>
                            <polygon id="Shape" points="14.4468619 0 20.8368201 10.2794979 28.060251 10.2794979 21.6702929 0"></polygon>
                            <polygon id="Shape" points="17.0861925 12.5020921 17.0861925 16.0443515 19.5866109 16.0443515 14.4468619 25.0041841 21.6702929 25.0041841 28.8937238 12.5020921"></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
}

export default GracoIcon;