export const G3_STATE_ID = {
    0:{
        'name':'unknown',
        'state':'state_unknown',
        'mode':'mode_none',
        'isAlarm':0    
    },  
    1:{
        'name':'idle_Timer',
        'state':'state_idle',
        'mode':'mode_timer',
        'isAlarm':0    
    },
    2:{
        'name':'idle_MachineCount',
        'state':'state_idle',
        'mode':'mode_machineCount',
        'isAlarm':0
    },
    3:{
        'name':'running_cycle',
        'state':'state_running',
        'mode':'mode_cycle',
        'isAlarm':0
    },
    4:{
        'name':'running_pressureSwitch',
        'state':'state_running',
        'mode':'mode_pressure_switch',
        'isAlarm':0
    },
    5:{
        'name':'running_pressureSensor',
        'state':'state_running',
        'mode':'mode_pressure_sensor',
        'isAlarm':0
    },
    6:{
        'name':'running_timer',
        'state':'state_running',
        'mode':'mode_timer',
        'isAlarm':0
    },
    7:{
        'name':'alarm_lowLevel',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_lowLevelInstructions'
    },
    8:{
        'name':'alarm_lowPressure',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_lowPressureInstructions'
    },
    9:{
        'name':'alarm_notVented',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_notVentedInstructions'
    },
    10:{
        'name':'alarm_CycleTimeout',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_CycleTimeoutInstructions'
    },
    11:{
        'name':'alarm_timerTimeout',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_timerTimeoutInstructions'
    },
    12:{
        'name':'alarm_machineCount',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_machineCountInstructions'
    },
    13:{
        'name':'alarm_solenoidFault',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_solenoidFaultInstructions'
    },
    14:{
        'name':'alarm_outputShort1',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_outputShort1Instructions'
    },
    15:{
        'name':'alarm_outputShort2',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_outputShort2Instructions'
    },
    16:{
        'name':'alarm_outputShort3',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_outputShort3Instructions'
    },
    17:{
        'name':'alarm_outputShort4',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_outputShort4Instructions'
    },
    18:{
        'name':'alarm_sensorFault1',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_sensorFault1Instructions'
    },
    19:{
        'name':'alarm_sensorFault2',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_sensorFault2Instructions'
    },
    20:{
        'name':'systemTest',
        'state':'state_test',
        'isAlarm':0
    },
    21:{
        'name':'inMenu',
        'state':'state_config',
        'isAlarm':0
    },
    22:{
        'name':'alarm_lowVoltage',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_lowVoltageInstructions'
    },
    23:{
        'name':'alarm_overCurrentMotor',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_overCurrentMotorInstructions'
    },
    24:{
        'name':'alarm_powerBrownout',
        'state':'state_alarm',
        'isAlarm':1,
        'instructions': 'alarm_powerBrownoutInstructions'
    },
    25:{
        'name':'startupDelay',
        'state':'state_idle',
        'mode':'mode_startup_delay',
        'isAlarm':0
    }
}

export const G3_ALERT_ID = {
    0:{
        'name': 'alert_none',
        'displayName': 'alertNone',
        'state': 'state_alert',
        'isAlert': 0,
        'instructions': 'alertNoneInstructions'
    },
    1:{
        'name': 'alert_lubrication_retry_cycle',
        'displayName': 'alertLubricationRetry',
        'state': 'state_alert',
        'isAlert': 1,
        'instructions': 'alertLubricationRetryInstructions'
    },
    2:{
        'name': 'alert_level',
        'displayName': 'alertLevel',
        'state': 'state_alert',
        'isAlert': 1,
        'instructions': 'alertLevelInstructions'
    },
    3:{
        'name': 'alert_machine_count_timeout',
        'displayName': 'alertMachineCount',
        'state': 'state_alert',
        'isAlert': 1,
        'instructions': 'alertMachineCountInstructions'
    },
    4:{
        'name': 'alert_lubrication_retry_pressure',
        'displayName': 'alertLubricationRetry',
        'state': 'state_alert',
        'isAlert': 1,
        'instructions': 'alertLubricationRetryInstructions'
    },
    10:{
        'name': 'alert_low_voltage',
        'displayName': 'alertLowVoltage',
        'state': 'state_alert',
        'isAlert': 1,
        'instructions': 'alertLowVoltageInstructions'
    },
    12:{
        'name': 'alert_temperature',
        'displayName': 'alertTemperature',
        'state': 'state_alert',
        'isAlert': 1,
        'instructions': 'alertTemperatureInstructions'
    },
    13:{
        'name': 'alert_motor_overcurrent',
        'displayName': 'alertMotorOvercurrent',
        'state': 'state_alert',
        'isAlert': 1,
        'instructions': 'alertMotorOvercurrentInstructions'
    },
    14:{
        'name': 'alert_motor_high_current',
        'displayName': 'alertMotorHighCurrent',
        'state': 'state_alert',
        'isAlert': 1,
        'instructions': 'alertMotorHighCurrentInstructions'
    },
}

export const G3_EVENT_ID = {
    1:{'displayName':'eventAlarmCycle', 'description':'Alarm, Cycle','eventVal1':'cycleCount','type': 'alarm'},
    201:{'displayName':'eventAlarmClearedCycle', 'description':'Alarm Cleared, Cycle','eventVal1':'Manual','type': 'alarm'},
    2:{'displayName':'eventNotUsed','description':'Not Used','eventVal1':'','type': 'alarm'},
    202:{'displayName':'eventNotUsed', 'description':'Not Used','eventVal1':'','type': 'alarm'},
    3:{'displayName':'eventAlarmLowLevel', 'description':'Alarm, Low Level','eventVal1':'','type': 'alarm'},
    203:{'displayName':'eventAlarmClearedLowLevel', 'description':'Alarm Cleared, Low Level','eventVal1':'method','type': 'alarm'},
    4:{'displayName':'eventAlertLowLevel', 'description':'Warning, Low Level','eventVal1':'','type': 'alert'},
    304:{'displayName':'eventAlertClearedLowLevel', 'description':'Alert Cleared, Low Level','eventVal1':'levelValue','type': 'alert'},
    5:{'displayName':'eventAlarmMachineCount', 'description':'Alarm, Machine Count','eventVal1':'machineCount','type': 'alarm'},
    205:{'displayName':'eventAlarmClearedMachineCount', 'description':'Alarm Cleared, Machine Count','eventVal1':'Manual','type': 'alarm'},
    6:{'displayName':'eventAlarmLowPressure', 'description':'Alarm, Low Pressure','eventVal1':'pressureValue','type': 'alarm'},
    206:{'displayName':'eventAlarmClearedPressure', 'description':'Alarm Cleared, Pressure','eventVal1':'Manual','type': 'alarm'},
    7:{'displayName':'eventNotVented', 'description':'Not Vented','eventVal1':'pressureValue','type': 'alarm'},
    207:{'displayName':'eventNotVentedCleared', 'description':'Not Vented Cleared','eventVal1':'','type': 'alarm'},
    8:{'displayName':'eventAlarmSensor1Fault', 'description':'Alarm, Sensor Fault, Pressure','eventVal1':'','type': 'alarm'},
    208:{'displayName':'eventAlarmClearedSensor1Fault', 'description':'Alarm Cleared, Sensor Fault, Pressure','eventVal1':'','type': 'alarm'},
    9:{'displayName':'eventAlarmSensor2Fault', 'description':'Alarm, Sensor Fault, Level','eventVal1':'','type': 'alarm'},
    209:{'displayName':'eventAlarmClearedSensor2Fault', 'description':'Alarm Cleared, Sensor Fault, Level','eventVal1':'','type': 'alarm'},
    10:{'displayName':'eventAlarmOutputShortOutput2', 'description':'Alarm, Output Short, Output 2','eventVal1':'','type': 'alarm'},
    210:{'displayName':'eventAlarmClearedOutputShortOutput2', 'description':'Alarm Cleared, Output Short, Output 2','eventVal1':'','type': 'alarm'},
    11:{'displayName':'eventAlarmOutputShortOutputMotor', 'description':'Alarm, Output Short, Motor','eventVal1':'','type': 'alarm'},
    211:{'displayName':'eventAlarmClearedOutputShortOutputMotor', 'description':'Alarm Cleared, Output Short, Motor','eventVal1':'','type': 'alarm'},
    12:{'displayName':'eventAlarmOutputShortOutputSensorVoltage', 'description':'Alarm, Output Short, Sensor Voltage','eventVal1':'','type': 'alarm'},
    212:{'displayName':'eventAlarmClearedOutputShortOutputSensorVoltage', 'description':'Alarm Cleared, Output Short, Sensor Voltage','eventVal1':'','type': 'alarm'},
    13:{'displayName':'eventAlarmOutputShortOutputSensorVoltage2', 'description':'Alarm, Output Short, Sensor Voltage 2','eventVal1':'','type': 'alarm'},
    213:{'displayName':'eventAlarmClearedOutputShortOutputSensorVoltage2', 'description':'Alarm Cleared, Output Short, Sensor Voltage 2','eventVal1':'','type': 'alarm'},
    14:{'displayName':'eventCommBoardDetected', 'description':'Comm. Board Detected','eventVal1':'','type': 'event'},
    15:{'displayName':'eventConfigurationChange', 'description':'Configuration Changed, Local','eventVal1':'','type': 'event'},
    16:{'displayName':'eventFwUpdateFailed', 'description':'FW Update, Failed','eventVal1':'','type': 'alarm'},
    17:{'displayName':'eventFwUpdateStarted', 'description':'FW Update, Started','eventVal1':'','type': 'event'},
    18:{'displayName':'eventFwUpdateTransferComplete', 'description':'FW Update, Transfer Complete','eventVal1':'','type': 'event'},
    19:{'displayName':'eventPowerOn', 'description':'Power On','eventVal1':'','type': 'event'},
    20:{'displayName':'eventLubricationOff', 'description':'Lubrication, OFF','eventVal1':'mode','type': 'event'},
    21:{'displayName':'eventLubricationOn', 'description':'Lubrication, ON','eventVal1':'mode','type': 'event'},
    22:{'displayName':'eventSystemTestAborted', 'description':'System Test, Aborted','eventVal1':'','type': 'event'},
    23:{'displayName':'eventSystemTestDone', 'description':'System Test, Done','eventVal1':'','type': 'event'},
    24:{'displayName':'eventSystemTestStarted', 'description':'System Test, Started','eventVal1':'','type': 'event'},
    25:{'displayName':'eventPinCodeEntryFail', 'description':'PIN Code Entry, Fail','eventVal1':'attemptedPIN','type': 'event'},
    26:{'displayName':'eventPinCodeEntrySuccess', 'description':'PIN Code Entry, Success','eventVal1':'','type': 'event'},
    27:{'displayName':'eventConfigurationChangedRemote', 'description':'Configuration Changed, Remote','eventVal1':'','type': 'event'},
    28:{'displayName':'eventLubricationCancelled', 'description':'Lubrication, Canceled','eventVal1':'','type': 'event'},
    29:{'displayName':'eventManualRunLocal', 'description':'Manual Run, Local','eventVal1':'','type': 'event'},
    30:{'displayName':'eventManualRunRemote', 'description':'Manual Run, Remote','eventVal1':'','type': 'event'},
    31:{'displayName':'eventMetricsReset', 'description':'Metrics Reset','eventVal1':'','type': 'event'},
    32:{'displayName':'eventFatalErrorReset', 'description':'Fatal Error Reset','eventVal1':'errorDescription','type': 'alarm'},
    33:{'displayName':'eventCommBoardError', 'description':'Comm Board Error','eventVal1':'','type': 'event'},
    34:{'displayName':'eventWarningCycle', 'description':'Alert, Cycle Retry','eventVal1':'cycleCount','type': 'alert'},
    334:{'displayName':'eventWarningClearedCycle', 'description':'Alert Cleared, Cycle Retry','eventVal1':'','type': 'alert'},
    35:{'displayName':'eventWarningPressure', 'description':'Alert, Pressure Retry','eventVal1':'pressureValue','type': 'alert'},
    335:{'displayName':'eventWarningClearedPressure', 'description':'Alert Cleared, Pressure Retry','type': 'alert'},
    36:{'displayName':'eventAlarmInputVoltage', 'description':'Alarm, Input Voltage','eventVal1':'measuredVoltage','type': 'alarm'},
    236:{'displayName':'eventAlarmClearedInputVoltage', 'description':'Alarm Cleared, Input Voltage','eventVal1':'measuredVoltage','type': 'alarm'},
    37:{'displayName':'eventAlertInputVoltage', 'description':'Alert, Input Voltage','eventVal1':'measuredVoltage','type': 'alert'},
    337:{'displayName':'eventAlertClearedInputVoltage', 'description':'Alert Cleared, Input Voltage','eventVal1':'measuredVoltage','type': 'alert'},
    38:{'displayName':'eventAlertTemperature', 'description':'Alert, Temperature','eventVal1':'measuredTemperature','type': 'alert'},
    338:{'displayName':'eventAlertClearedTemperature', 'description':'Alert Cleared, Temperature','eventVal1':'measuredTemperature','type': 'alert'},
    39:{'displayName':'eventAlarmOvercurrentMotor', 'description':'Alarm, Motor Overcurrent','eventVal1':'motorCurrent','type': 'alarm'},
    239:{'displayName':'eventAlarmClearedOvercurrentMotor', 'description':'Alarm Cleared, Motor Overcurrent','eventVal1':'','type': 'alarm'},
    40:{'displayName':'eventAlertOvercurrentMotor', 'description':'Alert, Over Current, Motor','eventVal1':'motorCurrent','type': 'alert'},
    240:{'displayName':'eventAlertClearedOvercurrentMotor', 'description':'Alert Cleared, Over Current, Motor','eventVal1':'motorCurrent','type': 'alert'},
    41:{'displayName':'eventAlarmLowLevelAutoRetry', 'description':'Alarm, Low Level Auto-Retry','eventVal1':'Auto','type': 'alarm'}, // Not actually used
    241:{'displayName':'eventAlarmClearedLowLevelAutoRetry', 'description':'Alarm Cleared, Low Level Auto-Retry','eventVal1':'Auto','type': 'alarm'},
    42:{'displayName':'eventAlarmOutputShortOutputVentValve', 'description':'Alarm, Output Short, Vent Valve','eventVal1':'','type': 'alarm'},
    242:{'displayName':'eventAlarmClearedOutputShortOutputVentValve', 'description':'Alarm Cleared, Output Short, Vent Valve','eventVal1':'','type': 'alarm'},
    43:{'displayName':'eventAlarmOutputShortOutputUSBVoltage', 'description':'Alarm, Output Short, USB Voltage','eventVal1':'','type': 'alarm'},
    243:{'displayName':'eventAlarmClearedOutputShortOutputUSBVoltage', 'description':'Alarm Cleared, Output Short, USB Voltage','eventVal1':'','type': 'alarm'},
    44:{'displayName':'eventAlarmUSBCommError', 'description':'Alarm, USB, Comm Error','eventVal1':'','type': 'alarm'},
    244:{'displayName':'eventAlarmClearedUSBCommError', 'description':'Alarm Cleared, USB, Comm Error','eventVal1':'','type': 'alarm'},
    45:{'displayName': 'eventAlertMotorHighCurrent', 'description':'Alert, Motor High Current', 'eventVal1':'', 'type':'alert'},
    245:{'displayName': 'eventAlertClearedMotorHighCurrent', 'description':'Alert Cleared, Motor High Current','eventVal1':'', 'type': 'alert'},
    46:{'displayName': 'eventAlarmPowerBrownout', 'description':'Alarm, Power Brownout', 'eventVal1':'', 'type': 'alarm'},
    246:{'displayName': 'eventAlarmClearedPowerBrownout', 'description':'Alarm Cleared, Power Brownout', 'eventVal1':'', 'type': 'alarm'},
    47:{'displayName': 'eventEnteringIdle', 'description':'Lubrication, Entering Idle', 'eventVal1':'', 'type': 'event'},

    101:{'displayName':'eventDeviceDisconnect', 'description':'Device Disconnected','eventVal1':'','type': 'systemInfo'},
    102:{'displayName':'eventDeviceConnect', 'description':'Device Connected','eventVal1':'','type': 'systemInfo'},
}