import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    IconButton,
} from '@mui/material';
import { format } from 'date-fns';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));


const TableTemplate = ({
    title,
    subtitle,
    columnTitles,
    rows,
    handleCellClick,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const formattedRows = rows.length
        ? rows
        : new Array(columnTitles.length).fill('-');
    function tableDateFormat(inputDate){
        var outputDate = "";
        try{
            outputDate = format(new Date(inputDate), 'MM/dd/yyyy');
        } catch {
            console.log("DateFormatError : {date}".replace("{date}", inputDate));
            var tempDate = inputDate;
            if(tempDate.includes("+")){
                //tempDate = tempDate.substring(0, tempDate.indexOf('+')).replace(" ","T")+"Z";
                tempDate = tempDate.substring(0, tempDate.indexOf('+')).replace(" ","T");
                //tempDate = tempDate.substring(0, tempDate.indexOf('+'));
            }
            outputDate = format(new Date(tempDate), 'MM/dd/yyyy');
        }
        return outputDate;
    }
    
    return (
        <>
            <Typography sx={{
                display: 'inline-block',
                position: 'relative',
                paddingLeft: theme.spacing(2),
                paddingBottom: theme.spacing(1),
                fontSize: '1rem',
                fontWeight: 600,
                }} 
                variant="h6"
            >
                    {t(title)}
            </Typography>
            {subtitle && (
                <Typography sx={{display: 'block', fontSize: '0.8rem',}} variant="subtitle1">
                    {t(subtitle)}
                </Typography>
            )}
            <TableContainer sx={{marginBottom: theme.spacing(3), '&:last-child': {marginBottom: 0,},}} component={Paper}>
                <Table size="small" aria-label="user permissions table">
                    <TableHead>
                        <TableRow>
                            {!!Object.values(columnTitles).length &&
                                Object.values(columnTitles).map((el) => (
                                    <StyledTableCell key={el.slice(0, 2)}>
                                        {t(el)}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formattedRows.map((row) => {
                            if (row === '-') {
                                return (
                                    <StyledTableRow>
                                        {Object.keys(columnTitles).map((el, index) => (
                                            <TableCell key={index}>{row}</TableCell>
                                        ))}
                                    </StyledTableRow>
                                );
                            }

                            return (
                                <StyledTableRow key={row.id}>
                                    {Object.keys(columnTitles).map(
                                        (el, index) => {
                                            if (
                                                el === 'id' &&
                                                typeof handleCellClick ===
                                                'function'
                                            ) {
                                                return (
                                                    <TableCell key={index}>
                                                        <IconButton
                                                            data-id={row[el]}
                                                            onClick={
                                                                handleCellClick
                                                            }>
                                                            <PictureAsPdfIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                );
                                            }

                                            if (
                                                el === 'next_invoice_date' ||
                                                el === 'closed_at' ||
                                                el === 'due_at'
                                            ) {
                                                return (
                                                    <TableCell key={index}>
                                                        {row[el] === null ? "" :
                                                            tableDateFormat(row[el])}
                                                    </TableCell>
                                                );
                                            }

                                            return (
                                                <TableCell key={index}>
                                                    {t(row[el])}
                                                </TableCell>
                                            );
                                        }
                                    )}
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default TableTemplate;