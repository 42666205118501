export const hw_config_obj = {
   "power_ac": {id: 0, affected_config_id:[]},
   "cycle_input": {id: 1, affected_config_id:[]},
   "machine_count_input": {id: 2, affected_config_id:[]},
   "low_level_digital_input": {id: 3, affected_config_id:[]}, //"40", "130"
   "low_level_analog_input": {id: 4, affected_config_id:["104"]},
   "pressure_sensor_input": {id: 5, affected_config_id:[]},
   "vent_valve_output": {id: 6, affected_config_id:["121"]},
   "alarm_relay_output": {id: 7, affected_config_id:["110", "112"]},
   "signal_output_1": {id: 8, affected_config_id:["114"]},
   "signal_output_2": {id: 9, affected_config_id:["118", "120"]},
   "motor_voltage_12v": {id: 10, affected_config_id:[]},
   "hf_motor": {id: 11, affected_config_id:[]},
}

//NOTE: add new config obj for each device type
export const config_obj_type = {
   1: [1,2,3,4,5,6,7,8,9], //G3 Test
   2: [0,1,2,3,4,5,6,7,8,9] //G3 MAX
}

export const pin_code_parameters_obj = {
   "26":{
      "name":"pin_code_enable",
      "default":1,
      "id":26,
      "range":[0,1],
      "type":"enable",
      "display_name": "pinCodeEnable"
   },
   "27":{
      "name":"pin_code_value",
      "default":"0000",
      "id":27,
      "range":['0000',9999],
      "type":"number",
      "display_name": "pinCodeValue"
   }
}

export const interval_parameters_obj = {
    "14":{
        "name":"lubrication_start_mode",
        "default":1,
        "id":14,
        "range":[],
        "type": "lubrication_start_mode",
        'display_name': "intervalMode",
     },
    "12":{
        "name":"lubrication_start_machine_count_action",
        "default":0,
        "id":12,
        "range":[],
        "type": "machine_count_action",
        "display_name": "intervalMachineCountAction"
     },
     "11":{
        "name":"lubrication_start_machine_count_target",
        "default":20,
        "id":11,
        "range":[1, 9999],
        "type": "integer",
        "display_name": "intervalMachineCountTarget"
     },
     "31":{
        "name":"lubrication_start_machine_count_timeout",
        "default":3600,
        "id":31,
        "range":[60, 35999999],
        "type": "integer",
        "display_name": "intervalMachineCountTimeout",
     },
     "13":{
        "name":"lubrication_start_time_target",
        "default":3600,
        "id":13,
        "range":[60, 35999999],
        "type": "integer",
        "display_name": "intervalTimerTarget"
     },
};

export const lubrication_parameters_obj = {
   "108":{
      "name":"input2_custom_offset",
      "default":"0.0",
      "id":108,
      "range":[0.0, 10.0],
      "type":"float",
      "display_name": "voltageOffset"
   },
   "109":{
      "name":"input2_custom_span",
      "default":"5.0",
      "id":109,
      "range":[0.0, 10.0],
      "type":"float",
      "display_name": "voltageSpan"
   },
   "107":{
      "name":"input2_type",
      "default":5,
      "id":107,
      "range":[],
      "type":"input_analog",
      "display_name": "inputType2"
   },
   "16":{
      "name":"lubrication_end_cycle_target",
      "default":1,
      "id":16,
      "range":[1, 99],
      "type":"integer",
      "display_name": "lubricationEndCycleTarget"
   },
   "29":{
      "name":"lubrication_end_cycle_timeout",
      "default":300,
      "id":29,
      "range":[10, 1800],
      "type":"integer",
      "display_name": "lubricationEndCycleTimeout"
   },
   "21":{
      "name":"lubrication_end_mode",
      "default":2,
      "id":21,
      "range":[],
      "type":"lubrication_end_mode",
      "display_name": "lubricationEndMode"
   },
   "100":{
      "name":"lubrication_end_pressure_analog_fullscale",
      "default":"5000",
      "id":100,
      "range":[0, 10000],
      "type":"float",
      "display_name": "lubricationEndPressureFullscale"
   },
   "17":{
      "name":"lubrication_end_pressure_analog_target",
      "default":"3000",
      "id":17,
      "range":[0, 5000],
      "type":"float",
      "display_name": "lubricationEndPressureTarget"
   },
   "101":{
      "name":"lubrication_end_pressure_analog_units",
      "default":2,
      "id":101,
      "range":[],
      "type":"pressure_units",
      "display_name": "lubricationEndPressureUnits"
   },
   "19":{
      "name":"lubrication_end_pressure_timeout_target",
      "default":300,
      "id":19,
      "range":[10, 1800],
      "type":"integer",
      "display_name": "lubricationEndPressureTimeout"
   },
   "20":{
      "name":"lubrication_end_timer_target",
      "default":300,
      "id":20,
      "range":[10, 1800],
      "type":"integer",
      "display_name": "lubricationEndTimerTarget"
   },
};

export const low_level_parameter_obj = {
   "105":{
      "name":"input1_custom_offset",
      "default":"0.0",
      "id":105,
      "range":[0.0, 10.0],
      "type":"float",
      "display_name": "voltageOffset"
   },
   "106":{
      "name":"input1_custom_span",
      "default":"5.0",
      "id":106,
      "range":[0.0, 10.0],
      "type":"float",
      "display_name": "voltageSpan"

   },
   "104":{
      "name":"input1_type",
      "default":7,
      "disabled_default": 4,
      "id":104,
      "range":[],
      "type":"input_analog",
      "display_name": "inputType1"
   },
   "9":{
      "name":"low_level_analog_alarm_threshold",
      "default":"5",
      "id":9,
      "range":[1.0, 100.0],
      "type":"float",
      "display_name": "lowLevelAlarmThreshold"
   },
   "5":{
      "name":"low_level_analog_alert_threshold",
      "default":"24",
      "id":5,
      "range":[1.0, 100.0],
      "type":"float",
      "display_name": "lowLevelAlertThreshold"
   },
   // "103":{
   //    "name":"low_level_analog_type",
   //    "default":0,
   //    "id":103,
   //    "range":[],
   //    "type":"input_analog",
   //    "display_name": "lowLevelAnalogType"
   // },
   // "102":{
   //    "name":"low_level_detection_source",
   //    "default":2,
   //    "id":102,
   //    "range":[],
   //    "type": "low_level_source",
   //    "display_name": "lowLevelSource"
   // },
   "30":{
      "name":"low_level_paddle_alarm_threshold",
      "default":80,
      "id":30,
      "range":[1, 99],
      "type":"integer",
      "display_name": "lowLevelPaddleAlarmThreshold"
   },
   "6":{
      "name":"low_level_paddle_alert_threshold",
      "default":10,
      "id":6,
      "range":[1, 99],
      "type":"integer",
      "display_name": "lowLevelPaddleAlertThreshold"
   },
   "39":{
      "name":"low_level_paddle_auto_clear_enable",
      "default":1,
      "id":39,
      "range":[0,1],
      "type":"enable",
      "display_name": "lowLevelPaddleAutoClear"
   },
   "40":{
      "name":"low_level_switch_action",
      "disabled_default": 0,
      "default":1,
      "id":40,
      "range":[],
      "type":"switch_action",
      "display_name": "lowLevelSwitchAction"
   },
   "129":{
      "name":"low_level_float_alarm_threshold",
      //"default":180,
      "default":0,
      "id":129,
      "range":[0, 999],
      "type":"integer",
      "display_name": "lowLevelFloatAlarmThreshold"
   },
   "130":{
      "name":"low_level_float_alert_threshold",
      //"default":10,
      "default":5,
      "id":130,
      "range":[0, 999],
      "type":"integer",
      "display_name": "lowLevelFloatAlertThreshold"
   },
};


export const outputs_parameter_obj = {
   // "111":{
   //    "name":"relay_out_active_high",
   //    "default":1,
   //    "id":111,
   //    "range":[0, 1],
   //    "type": "enable",
   //    "display_name": "relayOutputActiveHigh"
   // },
   "112":{
      "name":"relay_out_pulsed",
      "default":1,
      "disabled_default": 1,
      "id":112,
      "range":[0, 1],
      "type": "enable",
      "display_name": "relayOutputPulsed"

   },
   "110":{
      "name":"relay_out_type",
      "default":3,
      "disabled_default": 0,
      "id":110,
      "range": [],
      "type": "relay_out_type",
      "display_name": "relayOutputType"
   },
   "115":{
      "name":"signal_out1_active_high",
      "default":0,
      "id":115,
      "range":[0, 1],
      "type": "enable",
      "display_name": "signalOut1ActiveHigh"
   },
   "116":{
      "name":"signal_out1_pulsed",
      "default":0,
      "id":116,
      "range":[0, 1],
      "type": "enable",
      "display_name": "signalOut1Pulsed"
   },
   "113":{
      "name":"signal_out1_source",
      "default":0,
      "id":113,
      "range":[],
      "type": "signal_out_source",
      "display_name": "signalOut1Source"
   },
   "114":{
      "name":"signal_out1_type",
      "default":1,
      "disabled_default": 0,
      "id":114,
      "range":[],
      "type": "signal_out_type",
      "display_name": "signalOut1Type"
   },
   "119":{
      "name":"signal_out2_active_high",
      "default":0,
      "id":119,
      "range":[0, 1],
      "type": "enable",
      "display_name": "signalOut2ActiveHigh"
   },
   "120":{
      "name":"signal_out2_pulsed",
      "default":1,
      "disabled_default": 0,
      "id":120,
      "range":[0, 1],
      "type": "enable",
      "display_name": "signalOut2Pulsed"
   },
   "117":{
      "name":"signal_out2_source",
      "default":1,
      "id":117,
      "range":[],
      "type": "signal_out_source",
      "display_name": "signalOut2Source"
   },
   "118":{
      "name":"signal_out2_type",
      "default":3,
      "disabled_default": 0,
      "id":118,
      "range":[],
      "type": "signal_out_type",
      "display_name": "signalOut2Type"
   },
   "123":{
      "name":"vent_valve_dwell_time",
      "default":0,
      "id":123,
      "range":[0, 59],
      "type": "integer",
      "display_name": "ventValveDwellTime"
   },
   // "121":{
   //    "name":"vent_valve_enable",
   //    "default":1,
   //    "disabled_default":0,
   //    "id":121,
   //    "range":[0, 1],
   //    "type": "enable",
   //    "display_name": "ventValveEnable"
   // },
   // "122":{
   //    "name":"vent_valve_normally_closed",
   //    "default":0,
   //    "id":122,
   //    "range":[0, 1],
   //    "type": "enable",
   //    "display_name": "ventValveNormallyClosed"
   // }
};

export const advanced_parameter_obj = {
   "23":{
      "name":"startup_prelube_enable",
      "default":0,
      "id":23,
      "range":[0, 1],
      "type":"enable",
      "display_name": "startupPrelubeEnable"
   },
   "24":{
      "name":"startup_delay_enable",
      "default":0,
      "id":24,
      "range":[0, 1],
      "type":"enable",
      "display_name": "startupDelayEnable"
   },
   "25":{
      "name":"startup_delay_setting",
      "default":60,
      "id":25,
      "range":[0, 3599],
      "type":"integer",
      "display_name": "startupDelaySetting"
   },
   "43":{
      "name":"lubrication_end_retry",
      "default":0,
      "id":43,
      "range":[0, 20],
      "type":"integer",
      "display_name": "lubricationEndRetry"
   },
};