import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    FormControl,
    Tooltip,
    Typography,
} from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';

//import WiFiActivationCodeImg from '../../../OrgPage/OrgBilling/Components/Assets/WiFi_ActivationCode_ti40040.png';
import { useTranslation } from 'react-i18next';
import { groups_inf } from '../../services/groups_inf';
import { sanitizeObjectName_30char } from '../../helpers/sanitizeObjectName';

const AddGroup = ({ accountInfo, fetchGroups, isFAB=true }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [groupName, setGroupName] = useState("");
    const [groupID, setGroupID] = useState();
    const [openEnterName, setOpenEnterName] = useState(false);
    const rgroupName = useRef();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if(openEnterName){
            let people_list = []
            let temp_obj = {}
        }
      },[openEnterName]);


    const createGroup = async (groupName) => {
        try{
            const { status, error } = await groups_inf.createGroup(
                groupName, accountInfo.accountId, 
            );
            if (status === 404) {
                return;
            }
            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));                
                }
                else if(error.includes("Access Denied, no permissions to modify")){
                    alert(t("accessDeniedNoPermissions"));
                }
                else if(error.includes("Group name already exists")){
                    alert(t("groupNameAlreadyExists"))                
                }
                //alert(t(error));
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                //alert(t(error));
                throw new Error(error);
            }
            console.log('done adding group');
    
            }catch (error) {
                console.error(error);
            }
        // setUpdate(true);
        fetchGroups();
      };
    const handleOpenEnterName = () => {
        setOpenEnterName(true);
    };
    const handleCloseEnterName = () => {
        setOpenEnterName(false);
        setGroupName("");
    };
    const handleAddGroup = async () => {
        console.log('start adding group');
        createGroup(groupName);
        handleCloseEnterName();
    };

    return <>
        {accountInfo.isAccountEditor === 1 && (isFAB ?
            <Fab sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2)
            }} 
            color="secondary" aria-label="addD" onClick={handleOpenEnterName}>
                <AddIcon />
            </Fab> 
            : 
            <Tooltip title={t("addGroup")}>
                <IconButton onClick={handleOpenEnterName}>
                    <AddIcon/>
                    <Typography sx={{marginLeft: '5px'}}>{t("group")}</Typography>
                </IconButton>
            </Tooltip>
            )}
        
        {openEnterName && <Dialog
            disableEscapeKeyDown
            open={openEnterName}
            onClose={handleCloseEnterName}
            fullScreen={fullScreen}>
            <DialogTitle>{t("enterGroupName")}</DialogTitle>
            <DialogContent >
                <Grid container item xs 
                    justifycontent='flex-start'
                    alignItems='center'
                    direction='column'>
                    <Grid item xs>
                        <FormControl sx={{
                            margin: theme.spacing(1),
                            minWidth: 300,
                            marginBottom: 20
                        }}>
                            <Stack spacing={2}>
                                <TextField type="test" label={t("name")}
                                    inputRef={rgroupName}
                                    value={groupName}
                                    onChange={(e) => {
                                        if(sanitizeObjectName_30char(e.target.value)){
                                            setGroupName(e.target.value);
                                        }
                                    }
                                    }
                                    helperText={t("group")} />
                            </Stack>
                        </FormControl>
                    </Grid>
                    {true && <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth autoFocus variant='contained' color='secondary' onClick={handleAddGroup} >
                            {t('add')}
                        </Button>
                    </Grid>}
                    {true && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth 
                            autoFocus
                            color='inherit'
                            onClick={handleCloseEnterName}
                        >
                            {t('cancel')}
                        </Button>
                    </Grid>}
                </Grid>
            </DialogContent>
            {false && <DialogActions>
                <Button onClick={handleCloseEnterName} color="primary">
                    {t("cancel")}
                </Button>
                <Button variant="contained" onClick={handleAddGroup} color="secondary" disabled={!groupName}>
                    {t("add")}
                </Button>
            </DialogActions>}
        </Dialog>}
    </>;
}

export default AddGroup;