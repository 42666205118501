/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  userLocation,
  useParams,
  useLocation
} from 'react-router-dom';
import useSessionstorage from '@rooks/use-sessionstorage';
import Cookies from 'universal-cookie';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  FormControl,
  Typography,
} from "@mui/material";
import { TabPanel, TabContext, TabList } from '@mui/lab';
import Fab from '@mui/material/Fab';
import { 
  Edit,
  ArrowBack,
  Settings,
} from '@mui/icons-material';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { isValidEmail } from '../../helpers/isValidEmail';
import useMediaQuery from '@mui/material/useMediaQuery';

//Graco Components
import { GrowDiv } from '../../styledComponentsCommon.js';
import { groups_inf } from '../../services/groups_inf';
// import EditFriendlyName from '../../CommonComponents/EditFriendlyName.js';
import GroupUserCard from './GroupUserCard';
import GroupDeviceCard from './GroupDeviceCard';
import AddUser from './AddUser';
import AddDeviceToGroup from './AddDeviceToGroup.js';
import devices_inf from '../../services/devices_inf.js';
import EditIcon from '@mui/icons-material/Edit';
import { sanitizeObjectName_30char } from '../../helpers/sanitizeObjectName.js';

// Main page.
export default function GroupDetails({accountInfo}) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const theme=useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const params = useParams();
  const [groupData, setGroupData] = useState();
  const [dataReady, setDataReady] = useState(false);
  const [update, setUpdate] = useState(false);
  const [groupName, setGroupName] = useState(t("loadingGroup"));
  const [userList, setUserList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [value, setValue] = React.useState("1");
  const [editDialogue, seteditDialogue] = useState(false);
  const [newName, setNewName] = useState("");
  const [canWrite, setCanWrite] = useState();
  const location = useLocation();
  const group = location.state;
  
  const fetchGroup = async (abortController={signal: undefined}) => {
    try {
        const { status, error, accountGroups , accountUsers, accountDevices, groupInfo} = await groups_inf.getGroups(
            accountInfo.accountId,
            abortController
        );
        if (status === 404) {
            return;
        }

        if (error !== 'OK' && status !== 200) {
          if(error.includes("Unable to connect to database")){
            alert(t("failedToConnectToDatabase"))            
          }
          else if(error.includes("Access Denied, no permissions to modify")){
            alert(t("accessDeniedNoPermissions"));
          }
          //alert(t(error));
          else {
            alert(t("sorryUnableToRetrieve"));
            throw new Error(error);
          }
          //alert(t(error));
          throw new Error(error);
        }
        else{
          filterGroup(accountGroups, accountDevices);
        }

    } catch (error) {
        console.log('Failed to Fetch group')
        console.error(error);
        // setDataReady(true);
    }
    setUpdate(!update);
  };


  const editGroup = async (groupId, newName) => {
    try{
        const { status, error } = await groups_inf.updateGroup(
            accountInfo.accountId, groupId, newName, [], [], []
        );
        if (status === 404) {
            return;
        }
        if (error !== 'OK' && status !== 200) {
          if(error.includes("Unable to connect to database")){
            alert(t("failedToConnectToDatabase"))                
          }
          else if(error.includes("Access Denied, no permissions to modify")){
            alert(t("accessDeniedNoPermissions"));
          } 
          else if(error.includes("Group name alreadery exists")){
              alert(t("groupNameAlreadyExists"))                
          }
          //alert(t(error));
          else {
            alert(t("sorryUnableToRetrieve"));
            throw new Error(error);
          }
          //alert(t(error));
          throw new Error(error);
        }
        console.log('done updating group'); 

        }catch (error) {
            console.error(error);
        }
    setUpdate(!update);
    fetchGroup();
  };

  const openEditDialog = () => {
      seteditDialogue(true);
      setNewName("");
  };

  const closeEditDialog = () => {
      seteditDialogue(false);
      fetchGroup();
  };

  const handleEditGroup = async () => {
    let temp;
    for (const [key,value] of Object.entries(group)){
      temp = group[key].id;
    }
    editGroup(temp, newName);
    closeEditDialog();
  };
  
  function filterGroup(accountGroups, accountDevices){
    let temp = {};
    for (const [key, value] of Object.entries(accountGroups)){
      if(value.id === group.groupStatus.id){
        temp = {"name": null, "id": null, "Devices": [], "Users": []}
        temp.name=key;
        temp.id = value.id
        temp.Devices = value.devices;
        temp.Users = value.users;
      }
    }
    let groupDevices = []
    for(let i=0; i< accountDevices.length; i++){
      for (let j=0; j<temp.Devices.length; j++){
        if(accountDevices[i].uid === temp.Devices[j].uid){
          groupDevices.push(accountDevices[i]);
        }
      }
    }
    setGroupData(temp)
    setGroupName(temp.name);
    setUserList(temp.Users);
    //setDeviceList(temp.Devices);
    setDeviceList(groupDevices);
    setDataReady(true);
  };


  const handleTabChange = (event, newTabIndex) => {
    setValue(newTabIndex);
  };

  const writePermission = async () => {
    //
    setCanWrite(accountInfo.isAccountEditor);
  };

  // Hook to Fetch Groups when accountInfo changes and page load
  useEffect(() => {
    writePermission();
    const abortController = new AbortController();
    fetchGroup(abortController);
    return () => abortController.abort();
  },[accountInfo]);

  //Header consumes 64px of viewable area
  return (
    <>
    <Grid container item direction='column'>
      <Grid height='64px' item xs>
        <Toolbar>
          <Tooltip title={t("back")}>
            <IconButton
                  edge='start'
                  color='inherit'
                  onClick={()=>{navigation(-1);}}
                  aria-label='close'
                  >
                      <ArrowBack/>
          </IconButton>
          </Tooltip>
          {groupName.length < 20 ? <Typography fontWeight="bold"> {groupName == "default" ? <h2>{t(groupName)}</h2>: <h2>{groupName}</h2>} </Typography> : 
         <Typography fontWeight="bold"> {groupName == "default" ? <h3>{t(groupName)}</h3>: <h3>{groupName}</h3>}</Typography>}
          {groupName != "default" ?<Tooltip title={t("editGroupName")}>
            <IconButton
                color='primary'
                size='large'
                onClick={openEditDialog}
                disabled={!canWrite}
                >
                <EditIcon/>
            </IconButton>
          </Tooltip>:<Box flexGrow={1}/>}  
        </Toolbar>
      </Grid>
      </Grid>
      {dataReady ?
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, 
            borderColor: "divider" }}>
          <TabList
              onChange={handleTabChange}
              justifyContent="center"
              alignItems="center" 
              >
              <Tab label={t("Users")} value="1" />
              <Tab label={t("Devices")} value="2"/>
              <GrowDiv/>
              <Box flexGrow={10}/>
              {!fullScreen && (value === "1" ? <AddUser accountInfo={accountInfo} group={groupData} fetchGroup={fetchGroup} isFAB={false}/> 
            : <AddDeviceToGroup accountInfo={accountInfo} group={groupData} fetchGroup={fetchGroup} isFAB={false}/>) }
              <GrowDiv/>
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}> 
          {dataReady? 
          <Grid container item xs='auto' direction='column'>
            <Box id="UserBox" height="calc(100vh - 128px - 50px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                      {userList.length ? <List sx={{padding: theme.spacing(0), maxWidth: 450 }} > {userList.map((user) => (
                                <GroupUserCard user={user} group={groupData} accountInfo={accountInfo} fetchGroup={fetchGroup}/> 
                            ))} 
                          </List>:
                        <Grid item xs container
                        direction="row"
                        justifyContent="center"
                        alignItems="center" >
                      <Typography>{t('groupNoUsersFound')}</Typography>
                    </Grid>
                    }
            </Box>
            {fullScreen && <AddUser accountInfo={accountInfo} group={groupData} fetchGroup={fetchGroup}/> }
          </Grid>:
            <Grid item xs container
            direction="row"
            justifyContent="center"
            alignItems="center" >
              <CircularProgress sx={{margin: theme.spacing(4)}}/>
          </Grid>
          }
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}> 
          {dataReady? 
          <Grid container item xs='auto' direction='column'>
            <Box id="DeviceBox" height="calc(100vh - 128px - 49px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                      {deviceList.length ? <List sx={{padding: theme.spacing(0), maxWidth: 500, }} > {deviceList.map((dev) => (
                                <GroupDeviceCard device={dev}  group={groupData} accountInfo={accountInfo} fetchGroup={fetchGroup}/> 
                            ))}  
                          </List>:
                          //  <Typography>{t("noDevices")}</Typography>
                          <Grid item xs container
                          direction="row"
                          justifyContent="center"
                          alignItems="center" >
                       <Typography>{t('groupNoDevicesFound')}</Typography>
                      </Grid>}
            </Box>
          {fullScreen && <AddDeviceToGroup accountInfo={accountInfo} group={groupData} fetchGroup={fetchGroup}/>}
          </Grid>:
            // <Typography>{t('groupNoDevicesFound')}</Typography>
            <Grid item xs container
                        direction="row"
                        justifyContent="center"
                        alignItems="center" >
                      <CircularProgress sx={{margin: theme.spacing(4)}}/>
                    </Grid>
          }
        </TabPanel>
      </TabContext>:
      // <Typography>{t('loadingGroups')}</Typography>
        <Grid item xs container
          direction="row"
          justifyContent="center"
          alignItems="center" >
        <CircularProgress sx={{margin: theme.spacing(4)}}/>
      </Grid>
    }
    {editDialogue && <Dialog
    disableEscapeKeyDown
    open={editDialogue}
    onClose={closeEditDialog}
    fullScreen={fullScreen}>
        <DialogTitle>{t(groupName)}</DialogTitle>
        <DialogContent>
          <Grid container item xs 
              justifycontent='flex-start'
              alignItems='center'
              direction='column'>
              <Grid item xs>
                <Typography>{t("editGroup")}</Typography> 
              </Grid>
              <Grid item xs>
                      
                <FormControl 
                    sx={{
                        margin: theme.spacing(1),
                        minWidth: 300,
                        marginBottom: 20
                        }}
                    >
                    <Stack spacing={2}>
                        <TextField type="test" label={t("name")}
                            value={newName}
                            onChange={(e) => {
                                if (sanitizeObjectName_30char(e.target.value)){
                                    setNewName(e.target.value);
                                }
                            }} />
                    </Stack>
                </FormControl>
              </Grid>
              {fullScreen && <Grid item xs sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}}>
                        <Button fullWidth autoFocus variant='contained' color='secondary' 
                          disabled={!newName}
                          onClick={handleEditGroup} >
                            {t('editGroupName')}
                        </Button>
                    </Grid>}
              {fullScreen && <Grid item xs sx={{margin: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}>
                  <Button fullWidth 
                      autoFocus
                      color='inherit'
                      onClick={closeEditDialog}
                  >
                      {t('cancel')}
                  </Button>
              </Grid>}
          </Grid>
        </DialogContent>
        {!fullScreen && <DialogActions>
            <Button onClick={closeEditDialog} color="primary">
                {t("cancel")}
            </Button>
            <Button variant="contained" onClick={handleEditGroup} color="secondary" disabled={!newName}>
                    {t("editGroupName")}
            </Button>
        </DialogActions>}
    </Dialog>
    }   
    </>
  )
}
