import { useTheme } from '@emotion/react';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';

const SignalStrength = ({rssi, connection}) => {
    const theme = useTheme();
    const rssi_indicator = parseFloat(rssi)/31;
    const iconColor = theme.palette.blue.main;    
    return(
        (rssi_indicator < 0.1 || !connection) ? <SignalCellularConnectedNoInternet0BarIcon sx={{color: iconColor}}/>
            : (rssi_indicator < 0.25) ? <SignalCellular1BarIcon sx={{color: iconColor}}/>
            : (rssi_indicator < 0.50) ? <SignalCellular2BarIcon sx={{color: iconColor}}/>
            : (rssi_indicator < 0.75) ? <SignalCellular3BarIcon sx={{color: iconColor}}/>
            : <SignalCellular4BarIcon sx={{color: iconColor}}/>
    );
};

export default SignalStrength;