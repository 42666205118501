import { DEVICE_TYPES } from "../DeviceInfo/deviceTypes";

import * as g3 from "../DeviceInfo/g3_Config";

export const setEnablePinCode = (deviceType, config) => {
    var lConfig = config;
    var pinParameters;
    var targetParameterName;
    if (DEVICE_TYPES[deviceType.toString()].name.includes('G3')){
        pinParameters = g3.pin_code_parameters_obj;
        targetParameterName = "pin_code_enable";
    }
    if (config !== undefined){
        Object.keys(pinParameters).forEach((key) => {
            if (pinParameters[key].name === targetParameterName){
                lConfig[key]="1";
            }
        })
    }
    return lConfig;
}