import { Image, View, useTheme } from "@aws-amplify/ui-react";
import MyLogo from "../Header/assets/Trace_Brand_Final.png";

export function AuthHeader() {
  const { tokens } = useTheme();
  return (
    <View
      as="header"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '400px',
        overflow: 'hidden',  // Ensure no overflow beyond the max height
      }}
    >
      <Image
        alt="logo"
        src={MyLogo}
        padding={tokens.space.zero}
      />
    </View>
  );
}
