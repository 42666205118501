import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useSessionstorage from '@rooks/use-sessionstorage';
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
// Graco dependencies
import AccountNoAccess from '../AccountPage/AccountNoAccess/AccountNoAccess';
import GroupList from './Components/GroupList';
import GroupDetails from './Components/GroupDetails';

// Page routing for org management specific pages
const PageRouter = (props) => {
  let data = useLocation();
  return (
    <Routes>
      <Route exact path=':groupName/' element={<GroupDetails accountInfo={props.accountInfo}/>} />
      <Route path='*' element={<GroupList accountInfo={props.accountInfo}/>} />
    </Routes>
  );
}

// Org page component checks for editor privileges and routes to the appropriate page
export default function GroupPage(accountInfo) {
  //const { value: accountInfo } = useSessionstorage('accountInfo', {});
  let hasAccess;
  for (const [key,val] of Object.entries(accountInfo)){
    hasAccess = accountInfo[key].isAccountEditor;
  }
  return (
    <Fragment>
      {hasAccess ? <PageRouter accountInfo={accountInfo.accountInfo}/> : <AccountNoAccess />}
    </Fragment>
  )
}
