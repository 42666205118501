/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Tabs,
    Tab,
    Toolbar,
    Typography
} from '@mui/material';
import { 
    ArrowBack,
} from '@mui/icons-material';

import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import { GrowDiv } from '../../../styledComponentsCommon.js';
import devices_inf from '../../../services/devices_inf.js';
import G3MetricsView from './components/G3MetricsView.jsx';
import G3EventsView from './components/G3EventsView.jsx';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

// Diagnostics Page
export default function DeviceDiagnosticsPage({accountInfo}) {
    const params = useParams();
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    //const { value: accountInfo } = useSessionstorage('accountInfo', {});
    //const [accountInfo, setAccountInfo] = useState(accountInfo);
    const [dataReady, setDataReady] = useState(false);
    const [eventDataReady, setEventDataReady] = useState(false);
    const [deviceInformation, setDeviceInformation] = useState({});
    const [deviceMetrics, setDeviceMetrics] = useState({});
    const [currentDeviceEvents, setCurrentDeviceEvents] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [lookBackDays, setLookBackDays] = React.useState(14);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    }

    const handle_fetchEvents = (lookback_days=14) => {
        setEventDataReady(false);
        fetchEvents(lookback_days);
    }

    const handle_syncMetrics = async () => {
        try{
            const { status, error } = await devices_inf.updateDeviceMetrics(
                accountInfo.accountId, params.deviceId
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                setTimeout(function(){fetchMetrics();},5000);
                setDataReady(false);
            }
        } catch(error){
            console.error(error);
            setTimeout(function(){setDataReady(true);;},5000);
        }
    }

    const handle_resetMetrics = async () => {
        try{
            const { status, error } = await devices_inf.resetDeviceMetrics(
                accountInfo.accountId, params.deviceId
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                setTimeout(function(){handle_syncMetrics();},5000);
                setDataReady(false);
            }
        } catch(error){
            console.error(error);
            //setTimeout(function(){setDataReady(true);;},5000);
        }
    }

    const fetchMetrics = async (abortController={signal: undefined}) => {
        console.log("FetchMetrics");
        try{
            const { status, error, deviceInfoMetrics, metricsInfo } = await devices_inf.getDeviceMetrics(
                accountInfo.accountId, params.deviceId, abortController
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else if(error.includes("Access Denied, no permissions to view")){
                    alert(t("accessDeniedNoView"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                handle_filterData(deviceInfoMetrics, metricsInfo);
            }
        } catch(error){
            console.error(error);
            setTimeout(function(){setDataReady(true);;},5000);
        }
        
    };

    const fetchEvents = async (lookback_days=14, abortController={signal: undefined}) => {
        console.log("FetchEvents");
        setLookBackDays(lookback_days);
        try{
            const { status, error, deviceInfoEvents, deviceEvents } = await devices_inf.getDeviceEvents(
                accountInfo.accountId, params.deviceId, lookback_days, abortController
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if(error.includes("Unable to connect to database")){
                    alert(t("failedToConnectToDatabase"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
                throw new Error(error);
            }
            else{
                setCurrentDeviceEvents(deviceEvents);
                setEventDataReady(true);
            }
        } catch(error){
            console.error(error);
            setEventDataReady(true);
        }
        
    };

    const handle_filterData = (deviceInfo, metricsInfo) => {
        setDeviceInformation(deviceInfo);
        setDeviceMetrics(metricsInfo);
        setDataReady(true);
    }

    useEffect(() => {
        const abortController = new AbortController();
        fetchMetrics(abortController);
        fetchEvents(lookBackDays, abortController);
        return () => abortController.abort();
    },[]);

    
    return (
        <>
        <Grid container item direction='column'>
            <Grid height='64px' item xs>
                <Toolbar sx={{height: '64px'}}>
                    <IconButton
                            edge='start'
                            color='inherit'
                            onClick={()=>{navigation(-1);}}
                            aria-label='close'
                            >
                                <ArrowBack/>
                    </IconButton>
                    <Box flexGrow={1}/>
                    <Typography>{t("diagnosticsTitle")}</Typography>
                    <Box flexGrow={1}/>
                </Toolbar>
            </Grid>
            <Box id="contentBox" height="calc(100vh - 128px)">
                <Grid sx={{
                    minWidth: 350,
                    maxWidth: 600,
                }}
                item 
                container 
                direction='column'>
                    
                    <Divider/>
                    <Toolbar sx={{height: '64px'}}>
                        <Typography>{t('deviceName')}</Typography>
                        <GrowDiv/>
                        <Typography>{deviceInformation.friendly_name ? deviceInformation.friendly_name : params.deviceId}</Typography>
                        <GrowDiv/>
                    </Toolbar>
                    <Divider/>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '49px' }}>
                        <Tabs value={tabValue} onChange={handleChangeTab} aria-label="diagnostics-tab">
                        <Tab label={t("metricsTabValue")} {...a11yProps(0)} />
                        <Tab label={t("eventsTabValue")} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <Box height="calc(100vh - 128px - 64px - 2px - 49px)" overflow='auto' sx={{ width: '100%' }}>
                        <TabPanel value={tabValue} index={0} sx={{ p: 0 }}>
                            {dataReady ?  
                                <G3MetricsView  currentMetrics={deviceMetrics} deviceInfo={deviceInformation}
                                    syncMetrics={handle_syncMetrics} clearMetrics={handle_resetMetrics}/>
                            : <Grid item xs container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" >
                                    <CircularProgress sx={{margin: theme.spacing(4)}} />
                            </Grid>}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} sx={{ p: 0 }}>
                            {eventDataReady ? <G3EventsView 
                                currentEvents={currentDeviceEvents} 
                                lookBackDays={lookBackDays}
                                deviceInfo={deviceInformation}
                                fetchEvents={handle_fetchEvents} />
                            : <Grid item xs container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" >
                                <CircularProgress sx={{margin: theme.spacing(4)}} />
                            </Grid>
                            }
                        </TabPanel>
                    </Box>
                </Grid>
            </Box>
        </Grid>
        </>
    )
}
