import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,
    Typography,
} from "@mui/material";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';


import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';
import { devices_inf } from "../../../services/devices_inf";
import { billing } from '../../../services/billing';
import { DEVICE_TYPES } from '../../../DeviceInfo/deviceTypes';
import { enableBilling } from '../../../data';
import { BillingPlans, SubscriptionInfo } from '../../../data';

const ChangeDeviceStatusButton = ({ accountInfo, device_info, updateDevices=()=>{}, 
                    billingData={}, setBillingData=()=>{}, orgBilling={}, getSubscriptionCount=()=>{return 0}, altTitle=false }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [changeDeviceStatusDialogue, setChangeDeviceStatusDialogue] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [hasBillingInfo, setHasBillingInfo] = useState(false);
    const [loadingBilling, setLoadingBilling] = useState(false);
    const [estCharge, setEstCharge] = useState("0.00");
    const [estNextInvoice, setEstNextInvoice] = useState("0.00");
    const [estNewQuantity, setEstNewQuantity] = useState(0);
    const [processingRequest, setProcessingRequest] = useState(false);

    
    useEffect(() => {
        const abortController = new AbortController();
        
        if(enableBilling)
        {
            console.log("using billing");
            if (billingData?.billing_info?.id !== undefined) {
                setHasBillingInfo(true);
            }
            else {
                setHasBillingInfo(!enableBilling);
            }
        }
        else
        {
            console.log("billing disabled");
            setHasBillingInfo(true);
        }
        return () => abortController.abort();
    },[billingData])

    const fetchBillingPreview = async () => {
        var msg = { status: 0, data: {} };
        var newStatus = device_info.activation_status === 1 ? "INACTIVE" : "ACTIVE";
        try {
            if (!enableBilling){
                throw new Error("billing disabled");
            }
            if (billingData.subscriptions.length === 0) {
                console.log("No Subscription, cannot preview");
            } else {
                console.log("Update Subscription");
                var sub = [{}];
                sub[0].quantity = newStatus === "ACTIVE" ? billingData.subscriptions[0].quantity + 1 : billingData.subscriptions[0].quantity - 1;
                sub[0].id = billingData.subscriptions[0].id;
                msg = await billing.previewSubscription(
                    accountInfo.accountId,
                    sub
                );
            }
            const { error, billing_preview } = msg.data;

            if (msg.status === 404) {
                console.log(msg.status);
            }

            if (error !== 'OK' && msg.status !== 200) {
                throw new Error(error);
            }

            setEstCharge(billing_preview[0].estimated_total);
        } catch (error) {
            setEstCharge(0);
            console.error(error);
        }
        setLoadingBilling(false);
    }

    const handleOpenChangeDeviceStatus = () => {
        setChangeDeviceStatusDialogue(true);
        if(enableBilling){
            var newStatus = device_info.activation_status === 1 ? "INACTIVE" : "ACTIVE";
            var newCharge = 0
            var nextInvoice = 0;
            var newQty = 1;
            var l_billingPlan = BillingPlans[0];
            var l_estNewQty = newQty;
            
            for (var i = 0; i < BillingPlans.length; i++) {
                if (BillingPlans[i].label === orgBilling.billing_plan) {
                    l_billingPlan = BillingPlans[i];
                    break;
                }
            }
            
            if (billingData?.subscriptions?.length === 0) {
                newCharge = Math.round(l_billingPlan.unit_cost[orgBilling.billing_curr] * newQty * 100) / 100;
                nextInvoice = newCharge;
                if (billingData.subscriptions.length > 0) {
                    nextInvoice = Math.round(l_billingPlan.unit_cost[orgBilling.billing_curr] * (billingData.subscriptions[0].quantity + newQty) * 100) / 100;
                }
                setEstCharge(newCharge.toString());
            } else {
                //var l_billingPlan = BillingPlans[0];
                setLoadingBilling(true);
            
                newQty = newStatus === "ACTIVE" ? 1 : -1;
                if (newStatus === "ACTIVE") {
                    fetchBillingPreview();
                }
                else {
                    fetchBillingPreview();
                }
                l_estNewQty = billingData.subscriptions[0].quantity + newQty;
                nextInvoice = Math.round(l_billingPlan.unit_cost[orgBilling.billing_curr] * (billingData.subscriptions[0].quantity + newQty) * 100) / 100;
            }
            setEstNewQuantity(l_estNewQty);
            setEstNextInvoice(nextInvoice.toString());
        }
    };

    const handleCloseChangeDeviceStatus = () => {
        setChangeDeviceStatusDialogue(false);
    };

    const handleUpdateDeviceStatus = () => {
        //Determine Quantity
        setProcessingRequest(true);
        let quantity = getSubscriptionCount();
        if (device_info.activation_status === 1)
        {
            quantity = quantity - 1; //Deactivate
        }
        else
        {
            quantity = quantity + 1; //Activate
        }
        console.log("New Quantity {n}".replace("{n}", quantity.toString()))
        if (enableBilling){
            updateSubscription(quantity);
        } 
        else
        {
            updateDeviceStatus();
        }
        
    }

    const updateDeviceStatus = async () => {
        var l_hasBillingInfo = enableBilling ? (hasBillingInfo ? 1 : 0) : 1;

        try{
            console.log(device_info.activation_status === 0 ? "activating device" : "deactivating device");
            if (device_info.activation_status === 1) {
                const { status, error } = await devices_inf.deactivateDevices(
                    [device_info.uid],
                    accountInfo.accountId,
                    l_hasBillingInfo
                );
                if (status === 404) {
                    return;
                }
                if (error !== 'OK' && status !== 200) {
                    throw new Error(error);
                }
            }
            else{
                const { status, error } = await devices_inf.activateDevices(
                    [device_info.uid],
                    accountInfo.accountId,
                    l_hasBillingInfo
                );
                if (status === 404) {
                    return;
                }
                if (error !== 'OK' && status !== 200) {
                    throw new Error(error);
                }
            }
            
            handleCloseChangeDeviceStatus();
        }catch (error) {
            console.error(error);
        }
        setProcessingRequest(false);
        updateDevices();
    }


    const updateSubscription = async (input_quantity) => {
        var msg = { status: 0, data: {} };
        var quantity = input_quantity;
        //var newStatus = device_info.activation_status === 1 ? "INACTIVE": "ACTIVE";
        
        try {
            if (billingData.subscriptions.length === 0 && input_quantity === 0) {
                throw new Error("No subscription to delete");
            }
            else if (billingData.subscriptions.length === 0 && input_quantity > 0) {
                console.log("New Subscription");

                const plan_code = billing.findSubscriptionPlanCode(orgBilling.billing_plan);
                msg = await billing.newSubscription(
                    accountInfo.accountId,
                    plan_code,
                    orgBilling.billing_curr,
                    quantity,
                    SubscriptionInfo.total_billing_cycles);
            }
            else {
                console.log("Update Subscription");

                var sub = [{}];
                sub[0].quantity = quantity;
                sub[0].id = billingData.subscriptions[0].id;
                msg = await billing.updateSubscriptions(
                    accountInfo.accountId,
                    sub
                );
            }

            const { error, billing_account } = msg.data;

            if (msg.status === 404) {
                console.log(msg.status);
            }

            if (error !== 'OK' && msg.status !== 200) {
                if(error.includes("submitted values match the current subscriptions")){
                    //Still update the device status if the subscription value is correct.
                    updateDeviceStatus();
                    throw new Error(error);
                }
                else{
                    alert(error);
                    throw new Error(error);
                }
            }

            setBillingData(billing_account);
            updateDeviceStatus();
        } catch (error) {
            console.error(error);
            setProcessingRequest(false);
            //handleCloseStatusChange();
            //No longer prevent activation for billing errors.  Improve customer experience
            //updateDeviceStatus();
        }
    };

    // function findSubscriptionPlanCode(subscriptionInfo) {
    //     var plan_code = BillingPlans[0].plan_code;
    //     for (var i = 0; i < BillingPlans.length; i++) {
    //         if (BillingPlans[i].label === subscriptionInfo) {
    //             plan_code = BillingPlans[i].plan_code;
    //             break;
    //         }
    //     }
    //     return plan_code;
    // }
    

    // const handleNavigateToBilling = () => {
    //     navigate("accounts/{accountName}/billing".replace("{accountName", accountInfo.accountName));
    // }
      
    return <>
        {accountInfo.isAccountTech === 1 &&  (
            <Tooltip title={device_info.activation_status === 0 ? t("activateDevice") : t("deactivateDevice")}>
                <Button 
                    sx={{
                        margin:theme.spacing(1),
                    }}
                    variant={device_info.activation_status === 0 ? 'contained' : 'outlined'}
                    color={device_info.activation_status === 0 ? "secondary" : undefined}
                    onClick={handleOpenChangeDeviceStatus}>
                        {device_info.activation_status === 0 ? 
                            <><CloudQueueIcon/>
                            <Typography sx={{marginLeft: '5px'}}>{altTitle ? t('device') : t("activateDeviceButton")}</Typography>
                            </>
                            :<><CloudOffIcon/>
                            <Typography sx={{marginLeft: '5px'}}>{altTitle ? t('device') : t("deactivateDeviceButton")}</Typography></>}
                </Button>
            </Tooltip>
            )}
        
        
        {changeDeviceStatusDialogue && <Dialog
            disableEscapeKeyDown
            open={changeDeviceStatusDialogue}
            onClose={handleCloseChangeDeviceStatus}
            fullScreen={fullScreen}>
            <DialogTitle>{device_info.activation_status === 0 ? t("activateDevice") : t("deactivateDevice")}</DialogTitle>
            <DialogContent >
                <Grid item container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item>
                        {device_info.activation_status === 0 ?
                            accountInfo.isAccountTech ? 
                                <Typography gutterBottom variant="body2">
                                    {t("activateDeviceInfo").replace("{deviceFamily}", DEVICE_TYPES[device_info.type].name).replace("{deviceId}", device_info.uid)}
                                </Typography>
                                : <Typography>{t("activateDeviceInstructions")}</Typography>
                            :accountInfo.isAccountTech ? 
                                <Typography gutterBottom variant="body2">
                                    {t("deactivateDeviceInfo").replace("{deviceFamily}", DEVICE_TYPES[device_info.type].name).replace("{deviceId}", device_info.uid)}
                                </Typography>
                                : <Typography>{t("deactivateDeviceInstructions")}</Typography>
                        }
                        
                    </Grid>
                    {(enableBilling && (processingRequest || loadingBilling)) && <Grid item sx={{spacing: theme.spacing(1)}}>
                        <CircularProgress
                                    sx={{
                                        
                                    }}/>
                    </Grid>}
                    {!processingRequest && !loadingBilling && <Grid item sx={{spacing: theme.spacing(1)}}>
                        {enableBilling && <Typography variant="body2" gutterBottom >
                            <Box fontWeight="fontWeightBold" m={0}>
                                {device_info.activation_status !== 1 ? t("estimatedTotalDueNow") : t("estimatedCredit")}: {estCharge} {orgBilling.billing_curr}
                            </Box>
                        </Typography>}
                        
                        {enableBilling && <Typography variant="body2" gutterBottom >
                            <Box fontWeight="fontWeightBold" m={0}>
                                {t("estimatedTotalExplanation",{device_count: estNewQuantity.toString()})}
                            </Box>
                        </Typography>}

                        {enableBilling && <Typography variant="body2" gutterBottom >
                            <Box fontWeight="fontWeightBold" m={0}>
                                {t("estimatedTotaleDueNext",{billing_plan: t(orgBilling.billing_plan)})} {estNextInvoice} {orgBilling.billing_curr}
                            </Box>
                        </Typography>}

                        {enableBilling && <Typography variant="body2" gutterBottom >
                            {t("additonalTaxesFeesCredits")}
                        </Typography>}
                    </Grid>}
                        {fullScreen && 
                            (accountInfo.isAccountTech ? 
                                <Button disabled={(!hasBillingInfo || processingRequest) && enableBilling}
                                        fullWidth 
                                        sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                                        autoFocus
                                        variant="contained" 
                                        onClick={handleUpdateDeviceStatus} 
                                        color="secondary">
                                    {device_info.activation_status === 0 ? t("activate") : t("deactivate")}
                                </Button>
                        : <Button   disabled={false}
                                    fullWidth 
                                    sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                                    autoFocus
                                    variant={undefined}
                                    onClick={handleCloseChangeDeviceStatus} 
                                    color="secondary">
                            {t("ok")}
                        </Button>)
                        }
                        {fullScreen && accountInfo.isAccountTech && 
                            <Button onClick={handleCloseChangeDeviceStatus} 
                                    color="primary"
                                    fullWidth 
                                    sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                                    autoFocus
                            >
                                {t("cancel")}
                            </Button>}
                </Grid>
            </DialogContent>
            {!fullScreen && <DialogActions>
                {accountInfo.isAccountTech && <Button onClick={handleCloseChangeDeviceStatus} color="primary">
                    {t("cancel")}
                </Button>}
                {accountInfo.isAccountTech ? <Box sx={{ m: 1, position: 'relative' }}>
                    <Button disabled={(!hasBillingInfo || processingRequest) && enableBilling}
                        variant="contained" onClick={handleUpdateDeviceStatus} color="secondary">
                        {device_info.activation_status === 0 ? t("activate") : t("deactivate")}
                    </Button>
                    {processingRequest && (
                        <CircularProgress
                        size={24}
                        sx={{
                            //color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                        />
                    )}
                  </Box>
                : <Button disabled={false}
                    variant="contained" onClick={handleCloseChangeDeviceStatus} color="secondary">
                    {t("ok")}
                </Button>}
            </DialogActions>}
        </Dialog>}
    </>;
}

export default ChangeDeviceStatusButton;