import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';


const WidgetTemplate = ({ title, children }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <Typography  
                sx={{
                    display: 'inline-block',
                    position: 'relative',
                    paddingLeft: theme.spacing(2),
                    paddingBottom: theme.spacing(1),
                    fontSize: '1rem',
                    fontWeight: 600,
                }}
                variant="h6">
                    {title}
            </Typography>
            <TableContainer
                sx={{
                    marginBottom: theme.spacing(2),
                    '&:last-child': {
                        marginBottom: 0,
                    },
                }}
                component={Paper}
            >
                <Table size="small" aria-label="user permissions table">
                    <TableBody>
                        <TableRow>
                            <TableCell>{children}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default WidgetTemplate;