import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';

import { useTranslation } from 'react-i18next';

import { devices_inf } from "../../services/devices_inf.js";

const FwUpdateButtonListItem = ({ accountInfo, deviceInfo, deviceConnection = 0, handle_updateRequested = ()=>{} }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [openUpdateFw, setOpenUpdateFw] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [targetFwVersion, setTargetFwVersion] = useState();
       
    useEffect(() => {
        setTargetFwVersion(deviceInfo.update_fw_comm ? "C"+ deviceInfo.update_fw_comm : "M"+ deviceInfo.update_fw_main);  
    },[deviceInfo]);
    
    const handleUpdateFirmware = async () => {
        try{
            if (targetFwVersion.includes("C"))
            {
                console.log("request comm update");
                const { status, error } = await devices_inf.deviceUpdateFwComm (
                    accountInfo.accountId,
                    deviceInfo.uid,
                );
                if (status === 404) {
                    return;
                }
                if (error !== 'OK' && status !== 200) {
                    throw new Error(error);
                }
            }
            else if (targetFwVersion.includes("M"))
            {
                console.log("request main update");
                const { status, error } = await devices_inf.deviceUpdateFwMain (
                    accountInfo.accountId,
                    deviceInfo.uid,
                );
                if (status === 404) {
                    return;
                }
                if (error !== 'OK' && status !== 200) {
                    throw new Error(error);
                }
            }
            else 
            {
                console.error("Firmware Type Not Recognized");
            }
            handle_updateRequested();
            handle_closeUpdateFw();
        }catch (error) {
            console.error(error);
        }
    };

    const handle_openUpdateFw = () => {
        setOpenUpdateFw(true);
    }
    const handle_closeUpdateFw = () => {
        setOpenUpdateFw(false);
    }

    const UpdateIconButton = () => {
        return (
            <ListItem>
                <Tooltip title={t("fwUpdate")}>
                    <ListItemButton
                        size='large' 
                        disabled={!deviceConnection} 
                        onClick={handle_openUpdateFw}
                        sx={{
                            marginTop: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                            backgroundColor: theme.palette.grey.dark,
                            borderRadius: '4px',
                        }}
                    >
                        <ListItemText sx={{color: 'warning'}}
                            primary = {t("newFirmwareAvailable").replace("{version}", targetFwVersion)}
                        />

                        <ListItemIcon>
                            <UpgradeIcon fontSize='large' color="warning" />
                        </ListItemIcon>
                    </ListItemButton>
                </Tooltip>
            </ListItem>
        );
    }
    
    return <>
        {accountInfo.isAccountTech === 1 && (deviceInfo.update_fw_comm || deviceInfo.update_fw_main) && 
                <UpdateIconButton/>
        }   
        
        {openUpdateFw && <Dialog
            disableEscapeKeyDown
            open={openUpdateFw}
            onClose={handle_closeUpdateFw}
            fullScreen={fullScreen}>
            <DialogTitle>{t("fwUpdate")}</DialogTitle>
            <DialogContent >
                <Grid item 
                    container 
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={theme.spacing(2)}

                >
                    <Grid item xs>
                        <Typography gutterBottom variant="h6">
                            {targetFwVersion.includes("C") ? t("updateFwInfoComm") 
                            : targetFwVersion.includes("M") ? t("updateFwInfoMain") 
                                : t("updateFwInfoUnknown")}
                        </Typography>
                    </Grid>
                    <Grid item container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs>
                            <Typography gutterBottom variant="body2">
                                {targetFwVersion.includes("C") ? t("updateFwInfoCommDuration") 
                                : targetFwVersion.includes("M") ? t("updateFwInfoMainDuration") 
                                    : t("")}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography gutterBottom variant="body2">
                                {t("fwUpdateTarget").replace("{version}", targetFwVersion)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="body1" color="error">
                            {t("fwUpdateInfoWarning")}
                        </Typography>
                    </Grid>
                
                {fullScreen &&
                    <Button fullWidth sx={{marginTop: theme.spacing(3), width: fullScreen ? '75%' : '50%'}} 
                        disabled={!(targetFwVersion.includes("C") || targetFwVersion.includes("M")) || deviceConnection < 1}
                        onClick={handleUpdateFirmware} 
                        color={"secondary"} variant={"contained"}>
                        {t("confirm")}
                    </Button>
                }
                {fullScreen && 
                    <Button fullWidth sx={{marginTop: theme.spacing(2), width: fullScreen ? '75%' : '50%'}}
                        autoFocus
                        color={"inherit"} variant={undefined}
                        onClick={handle_closeUpdateFw}
                    >
                        {t('cancel')}
                    </Button>
                }
                </Grid>
            </DialogContent>
            {!fullScreen && <DialogActions>
                <Button onClick={handle_closeUpdateFw} color="primary">
                    {t("cancel")}
                </Button>
                <Button 
                    disabled={!(targetFwVersion.includes("C") || targetFwVersion.includes("M")) || deviceConnection < 1}
                    variant="contained" 
                    onClick={handleUpdateFirmware} 
                    color="secondary">
                        {t("confirm")}
                </Button>
            </DialogActions>}
        </Dialog>}
    </>;
}

export default FwUpdateButtonListItem;