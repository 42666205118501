import React, { useState, useRef } from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Stack from '@mui/material/Stack';
import { useTheme } from '@emotion/react';
import { Typography, TextField } from "@mui/material";
import { useTranslation } from 'react-i18next';

import { sanitizeObjectName_40char } from '../helpers/sanitizeObjectName';


const ModalPrompt = ({ title, content, action, handleAction, handleCancel, 
    openModal, useConfirm = false, fullScreen=false, disableAction=false, reverseEmphasis=false }) => {
  const { t } = useTranslation();
  const theme=useTheme();
  const [open, setOpen] = useState(openModal ? true : false);
  const [deleteConfirm, setDeleteConfirm] = useState("");
  const rdeleteConfirm = useRef("");
  const [invalidConfirm, setInvalidConfirm] = useState(false);
  
  const checkDeleteConfirm = () => {
    let result = true
    if (deleteConfirm === t("delete_caps")){
      result = (false);
    }
    return result;
  }
  const handle = () => {
    if (!useConfirm || (useConfirm && !checkDeleteConfirm())) {
      setOpen(false);
      handleAction();
    }
    else {
      setInvalidConfirm(true);
    }
  };
  const cancel = () => {
    setOpen(false);
    handleCancel();
  };
  return (
    <Dialog disableEscapeKeyDown open={open} onClose={cancel} fullScreen={fullScreen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography sx={{margin: theme.spacing(1)}} variant="body2">
            {content}
          </Typography>
          {useConfirm && <TextField sx={{margin: theme.spacing(1)}}
            label={t("deleteConfirm1_Type") + ' ' + t("delete_caps") + ' ' + t("deleteConfirm2_toConfirm")}
            inputRef={rdeleteConfirm}
            value={deleteConfirm}
            onChange={(e) => {
              if (sanitizeObjectName_40char(e.target.value)) {
                setDeleteConfirm(e.target.value);
              }
            }}
            error={invalidConfirm}
            helperText={invalidConfirm ? t("deleteConfirm_error") : ""}
          />}
          {fullScreen &&
            <Button fullWidth sx={{marginTop: theme.spacing(3), width: fullScreen ? '100%' : '50%'}} 
              disabled={(checkDeleteConfirm() && useConfirm) || disableAction} 
              onClick={handle} 
              color={reverseEmphasis ? "inherit" : "secondary"} variant={reverseEmphasis ? undefined : "contained"}>
                {action}
            </Button>
          }
          {fullScreen && 
              <Button fullWidth sx={{marginTop: theme.spacing(2), width: fullScreen ? '100%' : '50%'}}
                  autoFocus
                  color={!reverseEmphasis ? "inherit" : "secondary"} variant={!reverseEmphasis ? undefined : "contained"}
                  onClick={cancel}
              >
                  {t('cancel')}
              </Button>
          }
        </Stack>
      </DialogContent>
      {!fullScreen && <DialogActions>
        <Button onClick={cancel} color={!reverseEmphasis ? "inherit" : "secondary"} variant={!reverseEmphasis ? undefined : "contained"}>
          {t("cancel")}
        </Button>
        <Button disabled={(checkDeleteConfirm() && useConfirm) || disableAction} 
          variant={reverseEmphasis ? undefined : "contained"} 
          onClick={handle} 
          color={reverseEmphasis ? "inherit" : "secondary"}>
            {action}
        </Button>
      </DialogActions>}
    </Dialog>
  );
};

export default ModalPrompt;
