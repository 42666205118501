/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import {
//   useNavigate,
//} from 'react-router-dom';
import {
    Box,
    CircularProgress,
    Chip,
    Grid,
    List,
    Toolbar,
    Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';


import { useTheme } from '@emotion/react';
//import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import AccountInvite from './Components/AccountInvite.js';
import devices_inf from '../services/devices_inf.js';

import AddDevice from "./Components/AddDevice";
import StatusCard from './Components/StatusCard';

import MqttDevice from '../services/mqtt_device.js';
import { updateDeviceStatusMqtt, updateDeviceConnectionMqtt } from '../helpers/updateDeviceStatusMqtt.js';
import DeviceFilter from '../MainPage/Components/DeviceFilter.js';
import ReportsButton from './Components/ReportsButton.js';

// Main page.
export default function MainPage({accountInfo}) {
  const { t } = useTranslation();
  //const navigation = useNavigate();
  const theme=useTheme();
  const [userInfo, setUserInfo] = useState({
      groupList: [],
      deviceList: [],
      groupInfo: {}
  });
  const [dataReady, setDataReady] = useState(false);
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState([]);
  const [groupInviteAccess, setGroupInviteAccess] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  
  const handleUpdateDevices = () => {
    setDataReady(false);
    fetchMyDevices();
  }


  const fetchMyDevices = async (abortController={signal: undefined}) => {
    try{
      const { status, error, myDeviceList, myDeviceInfo, myGroupsList, myGroupsInfo } = await devices_inf.getMyDevices(
        accountInfo.accountId,
        abortController
      );
      
      if (status === 404) {
          return;
      }

      if (error !== 'OK' && status !== 200) {
        if(error.includes("Unable to connect to database")){
          alert(t("failedToConnectToDatabase"));
        }
        else if(error.includes("Access Denied, no permissions to view")){
          alert(t("accessDeniedNoView"));
        }
        else {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
        }
        throw new Error(error);
      }
      else{
        const myInfo = {
          groupList: myGroupsList,
          deviceList: myDeviceList,
          groupInfo: myGroupsInfo
        };
        setGroupInviteAccess(findGroupInviteAccess(myGroupsInfo))
        setUserInfo(myInfo);
        setDeviceInfo(myDeviceInfo);
      }
    } catch(error){
      console.error(error);
    }
    setInitialFetchComplete(true);
  };

  const handleUpdateFilter = (newFilter) => {
    filterData(deviceInfo, userInfo, newFilter);
  };

  function filterData(dataSet, myUserInfo, newFilter=undefined){
    function checkStatus(data, filter){
      let use = false;
      if (filter.length){
        if (filter.includes("OK")){
          if(!data.in_alarm && !data.in_alert && data.connection > 0){
            use = true;
          }
        }
        if (filter.includes("alarm")){
          if (data.in_alarm){
            use = true;
          }
        }
        if (filter.includes("alert")){
          if(data.in_alert){
            use = true;
          }
        }
        if (filter.includes("disconnected")){
          if (data.connection < 1){
            use = true;
          }
        }
      }else{
        use = true;
      }
      return use;
    }
    if(initialFetchComplete){
      var l_filter = newFilter;
      var l_device_list_groups = [];
      
      var l_device_list = [];
      if (newFilter){
        setGroupFilter(newFilter.groups);
        setStatusFilter(newFilter.status);
        l_filter = newFilter;
      } else{
        l_filter = {groups: groupFilter, status: statusFilter};
      }
      var l_dataSet = JSON.parse(JSON.stringify(dataSet));
      var output = [];
      
      if (l_filter.groups.length){
        if (l_filter.groups.length){
          //Create valid Device List
          Object.keys(myUserInfo.groupInfo).forEach((groupname)=>{
            if (l_filter.groups.includes(groupname)){
              l_device_list = [...l_device_list, ...myUserInfo.groupInfo[groupname].devices]
            }
          })
        }
        l_device_list_groups = [...new Set(l_device_list)];
      }
      l_dataSet.forEach((dev, i) => {
        if (l_device_list_groups.length){
          if (l_device_list_groups.includes(dev.uid)){
            if (checkStatus(dev, l_filter.status)){
              output.push(dev);
            }
          }
        }
        else{
          if (checkStatus(dev, l_filter.status)){
            output.push(dev);
          }
        }
      })
      
      output.sort((a, b)=> {
        if (a.activation === b.activation){
          if (a.in_alarm === b.in_alarm){
            if(a.alerts_list.length === b.alerts_list.length){
              if (a.friendly_name !== undefined && a.friendly_name !== null){
                if (b.friendly_name !== undefined && b.friendly_name !== null){
                  return a.friendly_name.toUpperCase() < b.friendly_name.toUpperCase() ? -1 : 1;
                }
                else{
                  return -1;
                }
              } else{
                if (b.friendly_name === undefined || b.friendly_name === null){
                  return a.uid > b.uid ? -1 : 1;
                }
                else {
                  return 1;
                }
              }
            } else {
              return a.alerts_list.length > b.alerts_list.length ? -1 : 1;
            }
          } else {
            return a.in_alarm > b.in_alarm ? -1 : 1;
          }
        } else {
          return a.activation > b.activation ? -1 : 1;
        }
      })
      setDisplayData(output);
      setDataReady(true);
    }
  };

  const setNewDeviceData = (val, idx) => {
    setDeviceInfo(deviceInfo => deviceInfo.map(
      (orig, i) => i === idx ? val : orig
    ));
  };

  const handleMqqtMessage = (msg) => {
    let tempData = updateDeviceStatusMqtt(deviceInfo, msg);
    setNewDeviceData(tempData.newData, tempData.index);   
  };
  
  const handleMqttDisconnect = (msg) => {
    let tempData = updateDeviceConnectionMqtt(deviceInfo, msg);
    setNewDeviceData(tempData.newData, tempData.index);
  };

  const handleMqttUnsubscribe = () => {
    //Placeholder
  }

  function findGroupInviteAccess(groupsInfo){
    let groups = groupsInfo;
    let invite_access = false;
    Object.keys(groups).forEach((key, index) => {
      if (groups[key].invite_access) {
          invite_access=true;
      }
    });
    return invite_access
  }

  useEffect(( )=> {
    filterData(deviceInfo, userInfo);
  },[deviceInfo, userInfo]);
  
  useEffect(() => {
    const abortController = new AbortController();
    // Fetch Data
    fetchMyDevices(abortController);

    return () => abortController.abort();
  },[accountInfo]);

  const handleDeleteGroupFilter = () => {
    let newFilter = {status: statusFilter, groups: []};
    handleUpdateFilter(newFilter);
  }
  const handleDeleteFilterChip = (chipToDelete) => () => {
    var filtTypes = [];
    if (chipToDelete === "groupsFilterChip"){
      handleDeleteGroupFilter();
    }
    else{
      filtTypes = statusFilter.filter(e => e !== chipToDelete);
      let newFilter = {status: filtTypes, groups: groupFilter}
      handleUpdateFilter(newFilter);
    }
  }
  const DisplayFilterTypesChips = ({filtTypes=[], filtGroups=[]}) => {
    let lFilter = [...filtTypes];
    if (filtGroups.length){
      lFilter = [...['groupsFilterChip'], ...lFilter];
    }
    if (lFilter.length === 0){
      return(<></>)
    }
    return(
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, margin: theme.spacing(1) }}>
        {lFilter.map(type => {
          return(
              <Chip key={type} label={t(type)} size='small' onDelete={handleDeleteFilterChip(type)} />
          );
        })}
      </Box>
    );
  }
  
  const hideToolbarAdd = useMediaQuery(theme.breakpoints.down('md'))
  const hideFab = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
    {dataReady ? <Grid container item direction='column'>
      <MqttDevice accountId={accountInfo.accountId}
                  deviceList={userInfo.deviceList}
                  updateInfo={handleMqqtMessage} 
                  updateConnection={handleMqttDisconnect} 
                  cleanupInfo={handleMqttUnsubscribe}
                  scanData={false}/>
    
        <Grid height='64px' item xs>
          <Toolbar sx={{height: "64px"}}>
            <Box flexGrow={1}/>
            {accountInfo.isAccountTech ? hideToolbarAdd ? null 
              : <AddDevice 
                  accountInfo={accountInfo} 
                  updateDevices={handleUpdateDevices} 
                  groups={userInfo.groupInfo} 
                  isFAB={false}/>: null}
            <AccountInvite  accountInfo={accountInfo} 
                            groups={userInfo.groupInfo}
                            access={groupInviteAccess}
                            
                            />
            {false && <ReportsButton accountInfo={accountInfo}/>}
            <DeviceFilter groups={userInfo.groupInfo} filtGroups={groupFilter} filtStatus={statusFilter} updateFilter={handleUpdateFilter}/>
          </Toolbar>
        </Grid>
        <Box id="contentBox" height="calc(100vh - 128px)" overflow='auto' sx={{backgroundColor:theme.palette.darkGrey.dark}}>
                  <DisplayFilterTypesChips filtTypes={statusFilter} filtGroups={groupFilter}/> 
                  {displayData.length ? <List sx={{padding: theme.spacing(0), maxWidth: 600, }} > {displayData.map((dev) => (
                            <StatusCard key={dev.uid} deviceStatus={dev} accountInfo={accountInfo}/> 
                        ))} 
                      </List>
                    : <Typography align="center">{t("noDevices")}</Typography>}
              {accountInfo.isAccountTech ? hideFab ? null : <AddDevice accountInfo={accountInfo} updateDevices={handleUpdateDevices} groups={userInfo.groupInfo}/>: null}
        </Box>
    </Grid> :
      <Grid item xs container
          direction="row"
          justifyContent="center"
          alignItems="center" >
        <CircularProgress sx={{margin: theme.spacing(4)}} />
      </Grid>
                  }
    </>
  )
}
