import React, { useState, useRef, useEffect } from 'react';
import {
    useNavigate,
    Link,
  } from 'react-router-dom'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    FormControl,
    Tooltip,
    Typography,
} from "@mui/material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@emotion/react';

//import WiFiActivationCodeImg from '../../../OrgPage/OrgBilling/Components/Assets/WiFi_ActivationCode_ti40040.png';
import { useTranslation } from 'react-i18next';


const AddTemplate = ({accountInfo, isFAB=true}) =>{
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClick = ()=>{
        let url = "/configurations/new";
        navigate(url);
    }

    return ( <>
        {(isFAB ?<Fab sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2)
            }} 
            color="secondary" aria-label="addD" onClick={handleClick}>
                <AddIcon />
        </Fab>: 
            <Tooltip title={t("addTemplate")}>
                <IconButton onClick={handleClick}>
                    <AddIcon/>
                    <Typography sx={{marginLeft: '5px'}}>{t("configurationTemplate")}</Typography>
                </IconButton>
            </Tooltip>)} 
    </>)
}

export default AddTemplate;