/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useParams
} from 'react-router-dom';
import useSessionstorage from '@rooks/use-sessionstorage';
import Cookies from 'universal-cookie';
import {
    Divider,
    Grid,
    List,
} from '@mui/material';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import DataEntryListItemHeader from '../../../CommonComponents/DataEntryListItemHeader.js';
import DataEntryListItem from '../../../CommonComponents/DataEntryListItem.js';
import DataEntryDialogueList from '../../../CommonComponents/DataEntryDialogueList.js';
import { interval_parameters_obj } from "../../../DeviceInfo/g3_Config";
import { hw_config_obj } from "../../../DeviceInfo/g3_Config";
import ToggleListItem from '../../../CommonComponents/ToggleListItem.js';
import GenerateConfigItems from './GenerateConfigItems.jsx';

import { secondsToTime } from '../../../helpers/secondsToTime';
import { timeToTimeString } from '../../../helpers/timeToTimeString.js';

// Device Page
export default function LubricationIntervalSettings({editing, currentSettings, hwConfig, updateSettings=()=>{}}) {
    const params = useParams();
    const { t } = useTranslation();
    const navigation = useNavigate();
    const theme=useTheme();
    const { value: accountInfo } = useSessionstorage('accountInfo', {});
    const [editParam, setEditParam] = useState(false);
    const [openParams, setOpenParams] = useState ({
        "11": false,
        "12": false,
        "13": false,
        "14": false,
        "31": false,
    });
    
    const interval_settings_list = [
        {key: 0, id: 0, name: "machine count", displayLabel: "intervalMachineCount"},
        {key: 1, id: 1, name: "timer", displayLabel: "intervalTimer"},
    ];
    const interval_settings_machine_count_action_list = [
        {key: 1, id: 1, name: "alarm", displayLabel: "intervalMachineCountActionAlarm"},
        {key: 0, id: 0, name: "start", displayLabel: "intervalMachineCountActionStart"},
    ];

    const handleToggle = (key_in) => {
        if (key_in === "Control_id_1"){
            if(parseInt(currentSettings["31"])){
                handleUpdateParams("31",0);
            }
            else{
                handleUpdateParams("31",30*60);
            }
        }
    };

    const handleOpenParam = (key_in) => {
        let l_openParams = openParams;
        l_openParams[key_in] = true;
        setOpenParams(l_openParams);
        setEditParam(true);
    };
    const handleCloseParams = () => {
        let l_openParams = openParams;
        Object.keys(l_openParams).forEach(key => {
            l_openParams[key] = false;
        })
        setOpenParams(l_openParams);
        setEditParam(false);
    };
    const handleUpdateParams = (key_in, value_in)  => {
        
        var temp_params = Object.assign({}, currentSettings);
        if (temp_params[key_in] !== String(value_in)){
            temp_params[key_in] = String(value_in);
            if(key_in === "14"){ //Interval Mode
                let minValue = 30;
                if (parseInt(value_in) == 1){ //Timer
                    if(currentSettings["21"] === "0"){ //Cycle
                        minValue = Math.floor(parseInt(currentSettings["29"])*3);
                    } else if (currentSettings["21"] === "1"){ //Pressure
                        minValue = Math.floor(parseInt(currentSettings["19"])*3);
                    } else if (currentSettings["21"] === "2"){ //Timer
                        minValue = Math.floor(parseInt(currentSettings["20"])*3);
                    }
                }
                if(currentSettings["13"] < minValue){//Interval Timer
                    temp_params["13"] = minValue;
                    alert(t("intervalAdjustedForDutyCycle").replace("{duty}","33"));
                }
            }
            updateSettings(temp_params);
        }
        handleCloseParams();
    };

    const handleErrorCheckParams = (key_in, value_in) => {
        let value_out=undefined;
        let errorText = ""
        value_out = value_in;
        
        if (key_in ===  "13"){ //Interval Timer Timeout
            //Check for Duty Cycle Requirement
            let minValue = 30;
            if(currentSettings["21"] === "0"){ //Cycle
                minValue = Math.floor(parseInt(currentSettings["29"])*3);
                if(value_in < minValue){
                    errorText="dutyCycleErrorMinValue";
                }
            } else if (currentSettings["21"] === "1"){ //Pressure
                minValue = Math.floor(parseInt(currentSettings["19"])*3);
                if(value_in < minValue){
                    errorText="dutyCycleErrorMinValue";
                }
            } else if (currentSettings["21"] === "2"){ //Timer
                minValue = Math.floor(parseInt(currentSettings["20"])*3);
                if(value_in < minValue){
                    errorText="dutyCycleErrorMinValue";
                }
            }
            const minimumTime = secondsToTime(minValue);
            const minimumTimeString = timeToTimeString(minimumTime.hours, minimumTime.minutes, minimumTime.seconds, 4);
            errorText = t(errorText).replace("{duty}","33").replace("{val}",minimumTimeString);
        }

        return {value: value_out, errorText: errorText}
    };

    const DisplayLubricationIntervalTimer = () => {
        const parameter_list = [
            {
                key: "13",
                'id': 'lubrication_interval_timer_target_id_13', 
                'config_id': "13",
                'type': "time",
            },
        ];
                
        return(
            <GenerateConfigItems 
                editing={editing}
                parameter_list={parameter_list}
                parameter_obj={interval_parameters_obj}
                currentSettings={currentSettings} 
                openParams={openParams}
                handleUpdateParams={handleUpdateParams}
                handleOpenParam={handleOpenParam}
                handleCloseParams={handleCloseParams}
                handleToggle={handleToggle}
                handleErrorCheckParams={handleErrorCheckParams}
                hour_length={4}
            />
        );
    };

    const DisplayLubricationMachineCount = () => {
        const parameter_list = [
            {
                key: "11",
                'id': 'lubrication_interval_machinecount_target_id_11', 
                'config_id': "11",
                'type': "number",
            },
        ];
        
        return(
            <Grid>
                <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={interval_parameters_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={4}
                />
                <DisplayLubricationMachineCountTimeout/>
            </Grid>
        );
    };

    const DisplayLubricationMachineCountTimeout = () => {
        const control_parameter = {
            key: "Control_id_1",
            'id': 'lubrication_interval_machinecount_timeout_enable', 
            'config_id': "Control_id_1",
            'type': "toggle",
            'display_name': "intervalTimeEnable"
        };
        const parameter_list = [
            {
                key:"31",
                'id': 'lubrication_interval_machinecount_timeout_target_id_31', 
                'config_id': "31",
                'instructions': "",
                'type': "time",
                'range': 5
            }, 
            {
                key: "12",
                'id': 'lubrication_interval_machinecount_timeout_action_id_12', 
                'config_id': "12",
                'instructions': "",
                'type': "list",
                'list': interval_settings_machine_count_action_list,
            }, 
        ];
        let toggleValue = parseInt(currentSettings[parameter_list[0].config_id]) ? true : false;
        return(
            <Grid item xs>
                <ToggleListItem key={control_parameter.id}
                    label={control_parameter.display_name} 
                    param_id={control_parameter.config_id}
                    value={toggleValue} 
                    handleClick={handleToggle} 
                    clickDisabled={!editing}
                />
                {toggleValue && <GenerateConfigItems 
                    editing={editing}
                    parameter_list={parameter_list}
                    parameter_obj={interval_parameters_obj}
                    currentSettings={currentSettings} 
                    openParams={openParams}
                    handleUpdateParams={handleUpdateParams}
                    handleOpenParam={handleOpenParam}
                    handleCloseParams={handleCloseParams}
                    handleToggle={handleToggle}
                    handleErrorCheckParams={handleErrorCheckParams}
                    hour_length={4}
                />}
            </Grid>
        );
    };

    const GenerateLocalIntervalSettingList = () => {
        var local_settings_list = interval_settings_list;
        if (!hwConfig.includes(hw_config_obj['machine_count_input'].id)){
            local_settings_list.shift();
        }
        return local_settings_list;
    };

    
    return (
        <Grid container item direction='column'>
            <Divider/>
                <DataEntryListItemHeader label={"settingLubricationIntervalTitle"}/>
            <Divider/>
            <List>
                <DataEntryListItem clickDisabled={!editing} label={interval_parameters_obj["14"].display_name} value={t(interval_settings_list[parseInt(currentSettings["14"])].displayLabel)} handleClick={handleOpenParam}/>
            </List>
            <DataEntryDialogueList  
                title={interval_parameters_obj["14"].display_name} param_id={"14"} instructions={""} 
                dialogueOpen={openParams[interval_parameters_obj["14"].display_name]} 
                handleSave={handleUpdateParams} handleCancel={handleCloseParams} 
                editing={editing}
                listIn={GenerateLocalIntervalSettingList()}/>
            {currentSettings["14"] === "0" && <DisplayLubricationMachineCount/>}
            {currentSettings["14"] === "1" && <DisplayLubricationIntervalTimer/>}
        </Grid>
    )
}
