import { Link as RouterLink } from 'react-router-dom';
import {
    Divider,
    Grid,
    IconButton,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


export default function AccountNoAccess() {
    const { t } = useTranslation();
    //const classes = useStyles();
    console.log("No Account Access")
    return (
        <Grid
            container
            alignItems='stretch'
            direction='column'
            justifyContent='flex-start'
            >
            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("goHome")}>
                        <IconButton color='primary' component={RouterLink} to='/' size="large">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <Typography variant='h5'
                        color='textPrimary'>
                        Account Settings
                    </Typography>
                </Toolbar>
            </Grid>
            <Divider />
            <Grid item xs>
                <Typography variant='h2'
                    color='error'
                    >
                    403 Forbidden
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography variant='h4'
                    color='error'
                    >
                    You do not have access to this resource.
                    If you require access, contact your administrator
                    and request Account Editor permissions.
                </Typography>
            </Grid>
        </Grid>
    );
}
