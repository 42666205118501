import React, { Fragment } from 'react';
import useSessionstorage from '@rooks/use-sessionstorage';
import { Link as RouterLink } from 'react-router-dom';
import {
    Route,
    Routes,
    useNavigate,
    useLocation,
    useParams
  } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { GrowDiv } from '../styledComponentsCommon';

import AccountNoAccess from '../AccountPage/AccountNoAccess/AccountNoAccess';
import TemplateList from './Components/TemplateList';
import NewTemplatePage from './Components/NewTemplatePage';
import TemplateDetails from './Components/TemplateDetails';


// Page routing for org management specific pages
const PageRouter = (props) => {
  let data = useLocation();
  return (
    <Routes>
      <Route path='*' element={<TemplateList accountInfo={props.accountInfo.accountInfo}/>} />
      <Route exact path=':templateName/' element={<TemplateDetails accountInfo={props.accountInfo}/>} />
      <Route path='/new/' element={<NewTemplatePage accountInfo={props.accountInfo.accountInfo}/>} />
    </Routes>
  );
}
  
// config page
export default function ConfigPage(accountInfo) {
  return (
    <Fragment>
      <PageRouter accountInfo={accountInfo}/>
    </Fragment>
  )
}