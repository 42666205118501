import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useSessionstorage from '@rooks/use-sessionstorage';
// Graco dependencies
import AccountManage from './AccountManage/AccountManage.jsx';
import AccountCreate from './AccountCreate/AccountCreate.jsx';
import AccountNoAccess from './AccountNoAccess/AccountNoAccess.jsx';
import NoAccountPage from './NoAccountPage/NoAccountPage.jsx';
import AccountBilling from './AccountBilling/AccountBilling.jsx';
import GroupPage from '../GroupPage/GroupPage.jsx';

// Page routing for org management specific pages
const PageRouter = (props) => {
  let data = useLocation();
  return (
    <Routes>
      {/* <Route exact path=':accountName/groups/' element={<GroupPage accountInfo={props.accountInfo}/>} /> */}
      <Route exact path=':accountName/billing/' element={<AccountBilling orgInfo={props.accountInfo}/>} />
      <Route exact path=':accountName/edit/' element={<AccountCreate firstAccount={false} accountEdit={true} 
                                                        fetchAccountInformation={props.fetchAccountInformation}/>} />
      <Route exact path=':accountName/manage/' element={<AccountManage accountInfo={props.accountInfo} fetchAccountInformation={props.fetchAccountInformation} />} />
      <Route path='/*' element={<NoAccountPage/>} />
      <Route path='*' element={<NoAccountPage orgInfo={props.orgInfo} />} />
    </Routes>
  );
}

// Org page component checks for editor privileges and routes to the appropriate page
export default function AccountPage({ accountInfo, fetchAccountInformation }) {
  //const { value: accountInfo } = useSessionstorage('accountInfo', {});
  return (
    <Fragment>
      {accountInfo.isAccountEditor ? <PageRouter accountInfo={accountInfo} fetchAccountInformation={fetchAccountInformation} /> : <AccountNoAccess />}
    </Fragment>
  )
}
