import {Auth} from 'aws-amplify';
import {host} from '../data.js'
import { isValidEmail } from '../helpers/isValidEmail.js';

const LOCATION_ERROR_MESSAGE =
    'Error in account information service! Provide, correct data to the service!';

let urlConfig = {
    apiCreate: 'accounts/{accountId}/groups',
    apiGet: 'accounts/{accountId}/groups/',
    api: 'accounts/{accountId}/groups/{groupId}',
    apiInvite: 'accounts/{accountId}/groups/{groupId}/invite',
    //proxyPort: 5000
}

export const groups_inf = {
    createGroup: async (name, accountId) => {
        try {
            if (!name || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiCreate.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    Name: name
                }),
            });
            
            let output = await response.json();
            
            if (output.error.includes("name already exists")){
                output.error="accountNameAlreadyExists"
            }
            
            const data = output;
            const { error, accountGroups, accountUsers, accountDevices } = data;
            return {status: response.status, error, accountGroups, accountUsers, accountDevices};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateGroup: async (accountId, groupId, name, userList=[], addDeviceList=[], removeDeviceList=[]) => {
        try {
            if (!accountId || !groupId || !name ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            // let localParams = {
            //     Name: name,
            //     UserList: userList,
            //     AddDeviceList: addDeviceList,
            //     RemoveDeviceList: removeDeviceList,
            // }

            const url =
                host + urlConfig.api.replace('{accountId}', accountId).replace('{groupId}',groupId);
            const user = await Auth.currentAuthenticatedUser();
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    Name: name,
                    UserList: userList,
                    AddDeviceList: addDeviceList,
                    RemoveDeviceList: removeDeviceList,
                }),
            });

            let output = await response.json();
            if (output.error.includes("name already exists")){
                output.error="accountNameAlreadyExists"
            }
            
            const data = output;
            const { error, accountGroups, accountUsers, accountDevices } = data;
            return {status: response.status, error, accountGroups, accountUsers, accountDevices};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteGroup: async (accountId, groupId) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountId}', accountId).replace('{groupId}',groupId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            let data = await response.json();

            if (data.error.includes("Unable to delete")){
                data.error="groupNotEmpty";
            }

            const { error, accountGroups, accountUsers, accountDevices } = data;
            return {status: response.status, error, accountGroups, accountUsers, accountDevices};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getGroups: async (accountId, abortController={signal:undefined}) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url = host + urlConfig.apiGet.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                signal: abortController.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, accountGroups , accountUsers, accountDevices, groupInfo} = data;
            return {status: response.status,
                    error, 
                    accountGroups,
                    accountUsers,
                    accountDevices,
                    groupInfo};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    inviteUser: async (accountId, groupId, userEmail, write_access, invite_access, groupList=[] ) => {
        try {
            if (!accountId || !groupId || !userEmail ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            if (!isValidEmail(userEmail)){
                console.log("Invalid Email: {email}".replace("{email}", userEmail));
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            let localParams = {
                Email: userEmail,
                group_write_access: write_access,
                group_invite_access: invite_access,
                group_list: groupList
            }

            const url =
                host + urlConfig.apiInvite.replace('{accountId}', accountId).replace('{groupId}',groupId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify(localParams),
            });

            let output = await response.json();
            
            if (output.error.includes("name already exists")){
                output.error="accountNameAlreadyExists"
            }
            
            const data = output;
            const { error, accountGroups, accountUsers,accountDevices } = data;
            return {status: response.status, error, accountGroups, accountUsers, accountDevices};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
};
