import React, { useState, useRef } from 'react';
import {
    useNavigate,
  } from 'react-router-dom'
import {
    Badge,
    Grid,
    ListItem,
    ListItemButton,
    Typography,
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';

import {useTheme,} from '@emotion/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

//import TankImage from "./assets/img/tank_al.png";
import SignalStrength from "../../CommonComponents/SignalStrength";
import { G3_STATE_ID } from '../../DeviceInfo/g3_Info';
import {DEVICE_TYPES} from '../../DeviceInfo/deviceTypes';
import { G3_ALERT_ID } from '../../DeviceInfo/g3_Info';
import { GrowDiv } from '../../styledComponentsCommon';
import { tankBackgroundColors } from '../../styledComponentsCommon';
import LubeOnIcon from './assets/LubeOnIcon';
import LubeOffIcon from './assets/LubeOffIcon';
import TankStatusImage from './TankStatusImage';

import {FormatDateIsoString} from '../../helpers/useFormatDateIsoString';
import useFormatDateIsoString from '../../helpers/useFormatDateIsoString';
//import { isLevelAnalog } from '../../helpers/isLevelAnalog';
import { hw_config_obj as g3_how_config_obj } from '../../DeviceInfo/g3_Config';

const StatusCard = ({ deviceStatus, accountInfo }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    let rCard = useRef();
    const [cardWidth, setCardWidth] = useState(350);
  
    var formattedDate = useFormatDateIsoString(deviceStatus.last_connected);
    let bgColors = tankBackgroundColors(theme);
    React.useEffect(() => { 
        formattedDate = FormatDateIsoString(deviceStatus.last_connected);
    }, [deviceStatus]);

    let currentColor;
    let device_state = G3_STATE_ID[deviceStatus.state];
    let lubricating = device_state.state === "state_running" ? true : false;
    
    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            setCardWidth(entries[0].contentRect.width)
        })
        observer.observe(rCard.current)
        return () => rCard.current && observer.unobserve(rCard.current)
    }, [])
    currentColor = bgColors.OK;
    if (deviceStatus.activation === 0)
    {
        currentColor = bgColors.INACTIVE;
    }
    else
    {
        switch (device_state.state) {
            case "state_running":
            case "state_idle":
                if (deviceStatus.in_alert){
                    for (let i=0; i<deviceStatus.alerts_list.length; i++){
                        if (G3_ALERT_ID[deviceStatus.alerts_list[i]]?.name === "alert_level"){
                            currentColor = bgColors.LOW_LEVEL;
                            break;
                        }
                    }
                }
                break;
            case "state_alarm":
                if (G3_STATE_ID[deviceStatus.state.toString()]['name'] === "alarm_lowLevel"){
                    currentColor = bgColors.ERROR;
                }
                break;
            default:
                currentColor = bgColors.ERROR;
                break;
        }
    }
    const tankLevel = deviceStatus.level;
    const levelHeight = deviceStatus.level+"%";

    const handleSelection = ()=>{
        if (deviceStatus.last_connected === "never"){
            alert(t('deviceNeverConnectedAlert'));
        } else{
            let url = "/devices/{deviceUID}".replace("{deviceUID}",deviceStatus.uid);
            navigate(url);
        }
    }

    const renderDeviceName = () => {
        let name = deviceStatus.friendly_name ? deviceStatus.friendly_name : deviceStatus.uid;
        if (cardWidth < 400)
        {
            if (name.length > 20) 
            {
                name = name.substr(0,20) + "...";
            }   
        }
        else if (cardWidth < 490)
        {
            if (name.length > 30) 
            {
                name = name.substr(0,30) + "...";
            }  
        }
        return name;
    }

    const handleInactiveSelection = () => {

    }

    const checkForAnalogLevel = () => {
        let device = DEVICE_TYPES[deviceStatus.type];
        let usingAnalogLevelSensor = false;
        let id = 0;
        if (device.name === "G3 MAX"){
            id = g3_how_config_obj["low_level_analog_input"].id;
            usingAnalogLevelSensor = deviceStatus.hw_config.includes(id);
        }
        
        return usingAnalogLevelSensor;
    }

    return (
        <ListItem ref={rCard} key={deviceStatus.uid} 
            sx={{paddingLeft: 
                theme.spacing(1), 
                paddingRight: theme.spacing(1),
                maxWidth: 600,
                minWidth: 330,
        }}>
            <ListItemButton key={deviceStatus.uid+"-button"} onClick={deviceStatus.activation === 1 ? handleSelection : handleInactiveSelection} 
                sx={{ 
                    maxWidth: 600, 
                    paddingLeft: theme.spacing(0), 
                    paddingRight: theme.spacing(0) 
            }}>
                <Grid container 
                    direction='row' 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    sx={{
                        backgroundColor: theme.palette.grey.dark,
                        borderRadius: '4px',
                        //maxWidth: 550,
                }}>
                    <TankStatusImage 
                            theme={theme}
                            levelHeight={levelHeight}
                            tankLevel={tankLevel}
                            currentColor={currentColor}
                            device_state={device_state}
                            analog_level={checkForAnalogLevel()}
                            inAlert={deviceStatus.alerts_list.includes(0)}
                            inAlarm={G3_STATE_ID[deviceStatus.state.toString()]['name'] === "alarm_lowLevel" ? 1 : 0}
                            deviceActive={deviceStatus.activation === 1 ? true : false}
                            size={'small'}  />
                    <Grid item xs >
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            >
                                <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                >
                                    <Grid item xs>
                                        <Typography noWrap gutterBottom variant="body1" component="span">
                                            {
                                                renderDeviceName()
                                            }
                                        </Typography>
                                    </Grid>
                                    <GrowDiv/>
                                    <Grid item xs container direction="row" justifyContent="flex-end" alignItems="center" wrap="nowrap">
                                        <GrowDiv/>
                                        <Grid item xs>
                                            {deviceStatus.activation === 1 ? 
                                                deviceStatus.in_alarm ? <NotificationsIcon color="error"/>
                                                : deviceStatus.in_alert ? 
                                                    <Badge badgeContent={deviceStatus.alerts_list.length} color="primary">
                                                        <NotificationsIcon color="warning" />
                                                    </Badge>
                                                : null
                                            : null}
                                        </Grid>
                                        <Grid item xs>
                                            <SignalStrength rssi={deviceStatus.rssi} connection={deviceStatus.connection > 0 && deviceStatus.activation === 1 ? 1 : 0}/>
                                        </Grid>
                                        <Grid item xs>
                                            {lubricating && deviceStatus.activation === 1 ? <LubeOnIcon fontSize="medium" sx={{color: theme.palette.blue.main}}/> 
                                            : <LubeOffIcon fontSize="medium" sx={{color: theme.palette.grey52.main}}/>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs>
                                    <Typography color={theme.palette.grey.main} noWrap variant="body2">
                                        {DEVICE_TYPES[deviceStatus.type] === undefined ? "unknown" : DEVICE_TYPES[deviceStatus.type].name}
                                    </Typography>
                                </Grid>
                                <GrowDiv/>
                                <Grid item xs>
                                    <Typography sx={{marginRight: theme.spacing(1)}}color={theme.palette.grey.main} variant="body2" component="p">
                                        {(deviceStatus.firmware.toUpperCase()).replaceAll("-",".")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {(deviceStatus.activation === 1 && deviceStatus.in_alarm && deviceStatus.connection > 0) ? 
                                <Typography variant="body2" color={theme.palette.error.main} noWrap>
                                    {t(G3_STATE_ID[deviceStatus.state].name ? G3_STATE_ID[deviceStatus.state].name : 'unknown')}
                                </Typography> 
                                : null}
                            {(!deviceStatus.in_alarm || deviceStatus.connection < 1 ) ? 
                                <Typography variant="body2" color={deviceStatus.activation === 1 && deviceStatus.connection > 0 ? theme.palette.grey.main : theme.palette.error.main} noWrap>
                                    {deviceStatus.activation === 0 ? 
                                        t('statusCardDeviceInactive')
                                        :deviceStatus.connection > 0 ? 
                                            t('connected') 
                                                : t('lastConnected').replace("{timestamp}", 
                                                deviceStatus.last_connected === "never" ? t("neverConnected") : formattedDate)}
                                </Typography>
                                : null}
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemButton>
        </ListItem>
    )
};

export default StatusCard;
